import React from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { deleteLimit, updateLimit } from "../../../api/referencePointsAPI";
import { BasicLimitFields } from "./LimitFields";
import { getErrorText } from "../../../utils/errors";
import useAnalytes from "../useAnalytes";
import useGeneralControls from "../GeneralControls/useGeneralControls";
import HomeForm from "../../Common/Home/HomeForm";
import LoadingSpinner from "../../Common/LoadingSpinner";

const EditLimit = ({ preloadedBasicState, onEditComplete, onEditCancelled, history }) => {
    const { id: outfallId, path: outfallPath } = getURLParentObject("o") || {};
    const { id: limitId } = getURLParentObject("l") || {};
    const { analytes, error: analyteError, loading: analyteLoading } = useAnalytes();
    const { generalControls, loading: generalControlLoading } = useGeneralControls();

    const onSubmit = (basic) => updateLimit(outfallId, limitId, basic).then(onEditComplete);

    const onDelete = () => deleteLimit(outfallId, limitId).then(() => history.push(outfallPath));

    if (analyteError) {
        return getErrorText(analyteError);
    }

    if (analyteLoading || generalControlLoading) {
        return <LoadingSpinner />;
    }

    const basicFields = BasicLimitFields.map((f) =>
        f.id === "analyte"
            ? { ...f, options: analytes }
            : f.id === "general_control_uuid"
            ? { ...f, options: generalControls }
            : f
    );

    return (
        <HomeForm
            basicFields={basicFields}
            onSubmit={onSubmit}
            onCancel={onEditCancelled}
            onDelete={onDelete}
            preloadedBasicState={preloadedBasicState}
        />
    );
};

export default withRouter(EditLimit);
