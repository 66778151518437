import React, { useState, useEffect } from 'react';
import Table from '../Table';
import { getComplianceReport, getComplianceReportDetails, listComplianceRules } from '../../api/complianceAPI';
import { getFormFields } from '../../api/formsAPI';

const ComplianceReportIssues = (props) => {
    const [report, setReport] = useState({});
    const [issues, setIssues] = useState(null);

    useEffect(() => {
        getTestReportComplianceIssues(props.uuid);
    }, []);

    const getTestReportComplianceIssues = (uuid) => {
        let reportIssues = [];
        if (uuid !== undefined && uuid !== '') {
            getComplianceReport(uuid).then(report => {
                setReport(report);
                Promise.all([getComplianceReportDetails(uuid), getFormFields(report.form_uuid), listComplianceRules()])
                    .then(([reportDetails, formFields, compRules]) => {
                        // two lookup maps converting our API results to uuid -> thing.  We could just loop over the
                        // results each time but this makes it easier
                        let detailsLookup = {};
                        reportDetails.compliance_report_details.forEach((detail) => {
                            detailsLookup[detail.form_field_uuid] = detail;
                        });
                        let rulesLookup = {};
                        compRules.rules.forEach((rule) => {
                            rulesLookup[rule.compliance_rule_uuid] = rule;
                        })

                        // for each form field in this report, if it has a compliance issue in the details lookup put
                        // the field type (because our rules reference type) and details in the list
                        formFields.form_fields.forEach((field) => {
                            if (detailsLookup[field.form_field_uuid] && detailsLookup[field.form_field_uuid].compliance_issue !== null) {
                                reportIssues.push({ label: field.form_field_type, issue: detailsLookup[field.form_field_uuid].compliance_issue });
                            }
                        });

                        // for any report issues that were overridden by a subsequent rule, we want to convert the uuid
                        // in the message to something more useful to users, so that will be the form field type since
                        // that is what we display on the table.
                        reportIssues.forEach((issue) => {
                            if (issue.issue.startsWith("Overridden by rule(s)")) {
                                // the message is always the same so we know what parts we can pull out, we want to grab
                                // the rule uuid and the rest of the message to then rebuild with our form field type
                                let ruleUUID = issue.issue.substring(22, 58);
                                let rest = issue.issue.substring(59);

                                if (rulesLookup[ruleUUID]) {
                                    issue.issue = "Overridden by " + rulesLookup[ruleUUID].form_field_type + " " + rest;
                                }
                            }
                        });

                        setIssues(reportIssues);
                    });
            });
        }
    }

    const displayIssues = () => {
        return (
            <div className="mainTableContainer">
                <Table
                    tableData={issues}
                    tableType='Compliance Issues'
                    tableSize='small'
                    dataSource='list'
                    clickable={false}
                    columns={[
                        { dataName: "label", displayName: "Field" },
                        { dataName: "issue", displayName: "Issue" }
                    ]}
                    openFlyout={noop}
                />
            </div>
        )
    }

    // this is just a no op function that is used so the "open flyout" function of clicking on a table doesn't cause
    // a js error
    const noop = () => { }

    if (issues !== null) {
        if (report.status === 'in_progress') {
            return (
                <div className="flyoutContentContainer">
                    Report is still being entered, automatic compliance checks have not run
                </div>
            );
        } else if (issues.length === 0) {
            return (
                <div className="flyoutContentContainer">
                    Automatic compliance checks found no issues
                </div>
            );
        } else {
            return (
                <div className="flyoutContentContainer">
                    {displayIssues()}
                </div>
            );
        }
    }

    return (<div className="flyoutContentContainer"></div>);
};

export default ComplianceReportIssues;