import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import AddSamplingSpecification from "../SamplingConfigs/AddSamplingSpecification";
import Button from "../../Common/Button";
import { AddRounded } from "@mui/icons-material";
import { getReportSync } from "../../../api/reportingAPI";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-material.css";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { transformReportToGridData, createColumnDefsFromReport } from "../../../utils/agGridUtils";

const SamplingConfig = ({ config, sampleReportConfigId, onSpecificationsChange, readOnly }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [tableRefreshKey, setTableRefreshKey] = useState(0);
    // state for AG Grid data
    const [specsData, setSpecsData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    // state to hold row data for editing
    const [editingSpec, setEditingSpec] = useState(null);
    const [specifications, setSpecifications] = useState([]);

    // When specifications change from the grid data, update the parent
    useEffect(() => {
        if (onSpecificationsChange) {
            // Create updatedConfig that includes the current specifications array
            const updatedConfig = {
                ...config,
                specifications: specifications,
            };

            // Notify parent about the changed specifications
            onSpecificationsChange(config.id, updatedConfig);

            // Update 'srcSamplingConfigs' in session storage for the current config
            const savedConfigs = sessionStorage.getItem("srcSamplingConfigs");
            if (savedConfigs) {
                const configs = JSON.parse(savedConfigs);
                const updatedConfigs = configs.map((c) => (c.id === config.id ? updatedConfig : c));
                sessionStorage.setItem("srcSamplingConfigs", JSON.stringify(updatedConfigs));

                // Also update complete state in storage if it exists
                const savedComplete = sessionStorage.getItem("srcCompleteState");
                if (savedComplete) {
                    const completeState = JSON.parse(savedComplete);
                    completeState.samplingConfigs = updatedConfigs;
                    sessionStorage.setItem("srcCompleteState", JSON.stringify(completeState));
                }
            }
        }
    }, [specifications]);

    const IconCellRenderer = (params) => {
        const handleClick = (e) => {
            e.stopPropagation();
            console.log("IconCellRenderer clicked for row:", params.data);
            setEditingSpec(params.data);
            setShowDialog(true);
        };

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    cursor: "pointer",
                }}
                onClick={handleClick}
            >
                <PostAddIcon sx={{ fontSize: 22 }} />
            </div>
        );
    };

    const fetchSpecifications = () => {
        if (!config?.id) {
            console.warn("No config ID provided, skipping spec fetch");
            return;
        }

        getReportSync("52aeec22-77fa-45fa-9c15-3d497a4bd096", { inputs: { "Sampling Config ID": config.id } }, false)
            .then((response) => {
                if (response?.data?.rows && response?.data?.outputs) {
                    // transform data for AG Grid
                    const rowData = transformReportToGridData(response.data);
                    // Store each row as a separate specification
                    setSpecifications(rowData);

                    let newColumnDefs = createColumnDefsFromReport(response.data.outputs);

                    // Only add the edit icon column if not readOnly
                    if (!readOnly) {
                        newColumnDefs.push({
                            headerName: "",
                            field: "iconColumn",
                            pinned: "right",
                            cellRenderer: IconCellRenderer,
                            width: 50,
                            suppressMenu: true,
                            sortable: false,
                            filter: false,
                            suppressSizeToFit: true,
                            cellStyle: { padding: 0 },
                            headerClass: "no-select-header",
                            suppressHeaderKeyboardEvent: () => true,
                            suppressColumnsToolPanel: true,
                            suppressMovable: true,
                            lockPosition: true,
                            suppressAutoSize: true,
                        });
                    }

                    setSpecsData(rowData);
                    setColumnDefs(newColumnDefs);
                }
            })
            .catch((error) => {
                console.error("Error fetching sampling specs:", error);
            });
    };

    useEffect(() => {
        fetchSpecifications();
    }, [tableRefreshKey]);

    const handleSpecCreated = () => {
        setTableRefreshKey((prevKey) => prevKey + 1);
    };

    const gridOptions = {
        defaultColDef: {
            sortable: true,
            filter: false,
            resizable: true,
            suppressMenu: true,
        },
        autoSizeStrategy: {
            type: "fitCellContents",
            skipHeader: false,
        },
        rowStyle: { cursor: "default" },
        suppressCellFocus: true,
        suppressRowClickSelection: true,
        rowHoverClass: "ag-row-hover",
        domLayout: "autoHeight",
        suppressHorizontalScroll: false,
        onGridReady: (params) => {
            params.api.sizeColumnsToFit();
        },
        onCellValueChanged: (params) => {
            const updatedRows = [];
            params.api.forEachNode((node) => updatedRows.push(node.data));
            setSpecifications(updatedRows);
        },
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "2rem",
                }}
            >
                <h4>Sampling Schedule</h4>
                <span>Sampling Schedule: {config.sampleSchedule.frequency}</span>
                <div>
                    <span>Administrative Period: {config.adminPeriod || "-"}</span>
                </div>
                <div>
                    <span>Sampling Period: {config.samplingPeriod || "-"}</span>
                </div>
            </div>
            <Divider />

            <div style={{ marginTop: "1rem" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <h4>Sampling Specifications</h4>
                    {!readOnly && (
                        <Button
                            onClick={() => {
                                // For a new specification, clear editingSpec
                                setEditingSpec(null);
                                setShowDialog(true);
                            }}
                            color="tertiary"
                        >
                            <AddRounded /> Add New Specification
                        </Button>
                    )}
                </div>
                <div
                    className="ag-theme-material"
                    style={{
                        width: "100%",
                        marginTop: "1rem",
                    }}
                >
                    <AgGridReact rowData={specsData} columnDefs={columnDefs} {...gridOptions} />
                </div>
            </div>

            {showDialog && (
                <AddSamplingSpecification
                    setShowDialog={setShowDialog}
                    showDialog={showDialog}
                    samplingConfigId={config.id}
                    sampleReportConfigId={sampleReportConfigId}
                    onSpecCreated={handleSpecCreated}
                    editingSpec={editingSpec}
                />
            )}
        </div>
    );
};

export default SamplingConfig;
