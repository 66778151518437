import { listReports } from '../../api/reportingAPI';
import React from 'react';

export const getReportsList = async (reportType) => {
	let list = [];
	const reportsList = await listReports();

	reportsList.forEach(report => {
		if (report.type === reportType) {
			let showToUser = true;
			report.inputs.forEach(input => {
				if (input.required && !input.default) {
					showToUser = false;
				}
			});
			if (showToUser) {
				list.push(report)
			}
		}
	})
	return list;
}

export const filterCounter = (properties, reportData) => {
	let count = 0;
	if (properties.reportingParams && reportData) {
		for (const value in properties.reportingParams.inputs) {
			if (properties.reportingParams.inputs[value] !== '') {
				for (const value2 of reportData.inputs) {
					if (value2.name.toLowerCase() === value) {
						if (properties.reportingParams.inputs[value].toString() !== value2.default) {
							count = count + 1;
						}
					}
				}
			}
		}
	}
	return count;
}

export const getReportsOptions = (reportsList, widgetType, properties) => {
	let options = []
	if (reportsList) {
		reportsList.sort((a, b) => (a.name > b.name) ? 1 : -1)
		reportsList.forEach((report, idx) => {
			if (report.type == widgetType) {
				options.push(<option selected={properties.report_uuid === report.report_uuid ? true : false} value={idx} key={idx}>{report.name}</option>)
			}
		})
	}
	return options;
}