import React, { useState, useEffect } from 'react';
import { withFormik, Form, Field } from 'formik';
import MessageBox from '../MessageBox';
import Table from '../Table';
import * as Yup from 'yup';
import LoaderOverlay from '../LoaderOverlay';

const FormLayout = ({
	errors,
	touched,
	isSubmitting,
	resetForm,
	setFieldValue,
	apiErrors,
	messageOptions,
	getReportStatus,
	getReportCompliance,
	getReportValue,
	flyoutType,
	report,
	complianceReport,
	complianceReportDetails,
	formFields,
	saveNotes,
	notes,
	reportErrors,
	values
}) => {
	const [editMode, setEditMode] = useState(false);

	useEffect(() => {
		if (notes.length > 0) {
			setEditMode(true);
		} else if (notes === '') {
			setEditMode(false);
		}
	}, [notes]);

	const cancelAction = () => {
		resetForm();
		setEditMode(false);
	};

	const getError = (fieldname) => {
		if (apiErrors && apiErrors[fieldname]) {
			return apiErrors[fieldname];
		} else if (touched[fieldname] && errors[fieldname]) {
			return errors[fieldname];
		}
	};

	const handleFormChange = (e) => {
		// this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
		// might be set so we can check it again
		if (apiErrors && apiErrors[e.target.name]) {
			delete apiErrors[e.target.name];
		}
		if (e) {
			saveNotes(e.target.value);
		}
	};

	const getFormErrors = () => {
		let errorList = [];
		messageOptions = {};
		['notes'].forEach((field) => {
			let err = getError(field);
			if (err) {
				errorList.push(err);
			}
		});

		if (errorList.length !== 0) {
			messageOptions = {
				type: "error",
				message: "Please correct the following errors",
				list: errorList,
			};
			document.getElementById('testreportform').scrollIntoView();
		} else if (reportErrors !== null && typeof reportErrors === "object") {
			messageOptions = reportErrors;
		}

		return messageOptions;
	};

	const handleSaveNotes = () => {
		saveNotes(values.notes, 'submit');
		setEditMode(false);
	};

	const getFormattedFieldDelta = () => {
		let tableData = [];
		complianceReport.field_deltas.forEach((delta) => {
			if (formFields[delta.form_field_uuid] && complianceReportDetails[delta.form_field_uuid]) {
				tableData.push({
					label: formFields[delta.form_field_uuid].label,
					value: complianceReportDetails[delta.form_field_uuid].value,
					expected_value: delta.expected_value
				})
			}
		});
		tableData.sort(function (a, b) {
			if (a.label < b.label) {
				return -1;
			} else if (a.label > b.label) {
				return 1;
			}
			return 0;
		})

		return tableData;
	}

	// build and return our table of reasons why the report wasn't auto accepted
	const getAutoAcceptExceptions = () => {
		let tableData = [];

		// test for these so they end up in table data in alphabetical or some other sensical order
		// the comments exception is something like comment_FIELDLABEL_not_empty
		let commentNotEmpty = false;
		for (const [key, value] of Object.entries(complianceReport.auto_accept_exceptions)) {
			if (key.startsWith("comments_") && key.endsWith("_not_empty")) {
				commentNotEmpty = true;
			}
		}
		if (commentNotEmpty || complianceReport.auto_accept_exceptions["report_notes_not_empty"] || complianceReport.auto_accept_exceptions["report_comments_not_empty"]) {
			tableData.push({ "reason": "Comments or Notes field not empty" });
		}
		if (complianceReport.auto_accept_exceptions["report_has_field_deltas"]) {
			tableData.push({ "reason": "Discrepencies Found" });
		}
		if (complianceReport.auto_accept_exceptions["property_needs_review"]) {
			tableData.push({ "reason": "Location needs reviewed" });
		}
		if (complianceReport.auto_accept_exceptions["equipment_needs_review"]) {
			tableData.push({ "reason": "Assembly needs reviewed" });
		}
		if (complianceReport.auto_accept_exceptions["property_inactive"]) {
			tableData.push({ "reason": "Location is inactive" });
		}
		if (complianceReport.auto_accept_exceptions["equipment_inactive"]) {
			tableData.push({ "reason": "Assembly is inactive" });
		}
		if (complianceReport.auto_accept_exceptions["internal_error"]) {
			tableData.push({ "reason": "Internal issue, please try manually" });
		}
		if (complianceReport.auto_accept_exceptions["equipment_rules_failed"]) {
			// like below this could be more specific too if we had a specific form type anywhere
			tableData.push({ "reason": "Assembly skipped due to targeting rules" });
		}
		if (complianceReport.auto_accept_exceptions["property_rules_failed"]) {
			tableData.push({ "reason": "Location skipped due to targeting rules" });
		}
		if (complianceReport.auto_accept_exceptions["compliance_failed"]) {
			// Note, Bobbi wanted this to be more specific but right now we don't have a great way of seeing which
			// report type we are dealing with other than the flyouttype which could change to be generic
			tableData.push({ "reason": "Report failed compliance checks" });
		}
		if (complianceReport.auto_accept_exceptions["service_provider_not_enabled"]) {
			tableData.push({ "reason": "Service Provider Company not enabled for auto accept" });
		}
		if (complianceReport.auto_accept_exceptions["user_not_enabled"]) {
			tableData.push({ "reason": "User not enabled for auto accept" });
		}

		if (tableData.length == 0) {
			// generic message since we had a reason we aren't translating
			tableData.push({ "reason": "Manual accept required" });
		}


		return (
			<div className="mainTableContainer">
				<Table
					tableData={tableData}
					tableType='Auto Accept Blocked Reasons'
					tableSize='smallFlex'
					dataSource='list'
					clickable={false}
					columns={[
						{ dataName: "reason", displayName: "Auto Accept Blocked" },
					]}
					openFlyout={() => { }}
				/>
			</div>
		);
	}

	return (
		<Form className="userDetailsForm" id="testreportForm">
			<MessageBox options={getFormErrors()} />
			{(complianceReport?.field_deltas.length > 0) && (
				complianceReportDetails !== null && formFields !== null ? (
					<React.Fragment>
						<div className="mainTableContainer">
							<label>Reported Value Discrepancies</label>
							<Table
								tableData={getFormattedFieldDelta()}
								tableType='Value Discrepancies'
								tableSize='smallFlex'
								dataSource='list'
								clickable={false}
								columns={[
									{ dataName: "label", displayName: "Field" },
									{ dataName: "value", displayName: "Reported Value" },
									{ dataName: "expected_value", displayName: "Expected Value" }
								]}
								openFlyout={() => { }}
							/>
						</div>
					</React.Fragment>
				) : <LoaderOverlay />
			)}
			<div className="detailsFieldset">
				<div className="inputField medium">
					<label htmlFor="name">Status</label>
					<input disabled type="text" name="status" className="inputField__input inputField__input-first" readOnly={true} value={getReportStatus()} />
				</div>
				<div className="inputField medium">
					<label htmlFor="name">Compliant</label>
					<input disabled type="text" name="compliant" className="inputField__input inputField__input-first" readOnly={true} value={getReportCompliance()} />
				</div>
				{flyoutType === "testReports" && (
					<React.Fragment>
						<div className="inputField medium">
							<label htmlFor="name">Serial Number</label>
							<input disabled type="text" name="serial_number" className="inputField__input inputField__input-first" readOnly={true} value={getReportValue(report, report.rows[0], "Serial Number")} />
						</div>
						<div className="inputField medium">
							<label htmlFor="name">Assembly Type</label>
							<input disabled type="text" name="type" className="inputField__input inputField__input-first" readOnly={true} value={getReportValue(report, report.rows[0], "Type")} />
						</div>
						<div className="inputField medium">
							<label htmlFor="name">Make</label>
							<input disabled type="text" name="make" className="inputField__input inputField__input-first" readOnly={true} value={getReportValue(report, report.rows[0], "Make")} />
						</div>
						<div className="inputField medium">
							<label htmlFor="name">Model</label>
							<input disabled type="text" name="model" className="inputField__input inputField__input-first" readOnly={true} value={getReportValue(report, report.rows[0], "Model")} />
						</div>
					</React.Fragment>
				)}
				<div className={'inputField long ' + ((getError('notes')) ? 'formRed' : '')}>
					<label htmlFor="notes">Notes</label>
					<Field component="textarea" rows="2" name="notes" autoComplete="off" className="inputField__input inputField__input-first"
						onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
					/>
				</div>
			</div>
			{(complianceReport && Object.keys(complianceReport.auto_accept_exceptions).length > 0 && complianceReport.status === "submitted") &&
				getAutoAcceptExceptions()
			}
			{
				editMode && (
					<React.Fragment>
						<div className="formButtonsBank">
							<button className="medButtonPrimary" disabled={isSubmitting} type="button" onClick={() => handleSaveNotes()}>Save Notes</button>
							<button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
						</div>
					</React.Fragment>
				)
			}
		</Form>
	);
};

const TestReportDetailsForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,
	enableReinitialize: true,
	mapPropsToValues(props) {
		let values = {
			notes: props.getReportValue(props.report, props.report.rows[0], 'Notes'),
		};

		return values;
	},

	validationSchema: Yup.object().shape({
		notes: Yup.string()
	}),

	handleSubmit(values, { props, resetForm, setSubmitting }) {

		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
		}, 3000);
	}

})(FormLayout);

export default TestReportDetailsForm;
