import {
  GET_PROPERTIES_REPORT_REQUEST,
  GET_PROPERTIES_REPORT_SUCCESS,
  GET_PROPERTIES_REPORT_FAIL
} from '../actions/propertiesActions';

const initialState = {
  data: null,
  nextPage: null,
  loading: false,
  error: null
};

export default function propertiesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROPERTIES_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_PROPERTIES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        nextPage: action.payload.nextPage
      };
    case GET_PROPERTIES_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
