/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withFormik, Form, Field } from 'formik';
import MessageBox from '../MessageBox';
import StateProvSelectField from '../Forms/StateProvSelectField';
import * as Yup from 'yup';

const FormLayout = ({
	errors,
	touched,
	isSubmitting,
	close,
	deleteAddress,
	setFieldValue,
	apiErrors,
	messageOptions,
	values
}) => {
	// set our current country code to either whatever the current address is, or our org's country
	const [country, setCountry] = useState(values['country_code'] ? values['country_code'] : SwiftComply.organization.country_code.toLocaleUpperCase());

	const cancelAction = () => {
		close();
	};

	const getError = (fieldname) => {
		if (apiErrors && apiErrors[fieldname]) {
			return apiErrors[fieldname];
		} else if (touched[fieldname] && errors[fieldname]) {
			return errors[fieldname];
		}
	}

	const handleFormChange = (e) => {
		if (e.target.type === 'checkbox') {
			setFieldValue(e.target.name, e.target.checked);
		}
		// this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
		// might be set so we can check it again
		if (apiErrors && apiErrors[e.target.name]) {
			delete apiErrors[e.target.name];
		}
	}

	const getFormErrors = () => {
		let errorList = [];
		messageOptions = {};
		['address1', 'address2', 'city', 'state_prov', 'postal_code'].forEach((field) => {
			let err = getError(field);
			if (err) {
				errorList.push(err);
			}
		});

		if (errorList.length !== 0) {
			messageOptions = {
				type: "error",
				message: "Please correct the following errors",
				list: errorList,
			};
			document.getElementById('contactAddressForm').scrollIntoView();
		}

		return messageOptions;
	}

	return (
		<Form className="userDetailsForm" id="contactAddressForm">
			<MessageBox options={getFormErrors()} />
			<fieldset>
				<div className="detailsFieldset">
					<div className={'inputField long ' + ((getError('address1')) ? 'formRed' : '')}>
						<label htmlFor="address1">Address *</label>
						<Field type="text" name="address1" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('address2')) ? 'formRed' : '')}>
						<Field type="text" name="address2" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('city')) ? 'formRed' : '')}>
						<label htmlFor="city">City *</label>
						<Field type="text" name="city" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField small ' + ((getError('state_prov')) ? 'formRed' : '')}>
						<label htmlFor="state_prov">State / Prov *</label>
						<StateProvSelectField
							fieldName="state_prov"
							countryCode={country}
							handleChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField small ' + ((getError('postal_code')) ? 'formRed' : '')}>
						<label htmlFor="postal_code">Postal Code *</label>
						<Field type="text" name="postal_code" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField small ' + ((getError('country_code')) ? 'formRed' : '')}>
						<label htmlFor="country_code">Country *</label>
						<Field component="select" name="country_code" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => {
								setFieldValue(e.target.name, e.target.value);
								setFieldValue("state_prov", ""); // if they change the country then the state prov won't be valid
								handleFormChange(e);
								setCountry(e.target.value);
							}}
						>
							<option value=""></option>
							<option value="CA">CA</option>
							<option value="HK">HK</option>
							<option value="US">US</option>
						</Field>
					</div>
					<div className='inputField long'>
						<label htmlFor="primary_address">Primary Address?</label>
						<Field type="checkbox" name="primary_address" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							checked={values['primary_address']}
						/>
					</div>
				</div>
			</fieldset>
			<div className="formButtonsBank">
				<button className="medButtonPrimary" disabled={isSubmitting} type="submit">Save</button>
				<button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
			</div>
		</Form>
	);
};

const ContactAddressForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,
	enableReinitialize: true,
	mapPropsToValues(props) {
		return {
			address1: (props.data.address1 ? props.data.address1 : ''),
			address2: (props.data.address2 ? props.data.address2 : ''),
			city: (props.data.city ? props.data.city : ''),
			state_prov: (props.data.state_prov ? props.data.state_prov : ''),
			postal_code: (props.data.postal_code ? props.data.postal_code : ''),
			country_code: (props.data.country_code ? props.data.country_code : SwiftComply.organization.country_code.toLocaleUpperCase()),
			primary_address: (props.data.primary_address && props.data.primary_address != "false" ? true : false)
		};
	},

	validationSchema: Yup.object().shape({
		address1: Yup.string().required('Address is required'),
		address2: Yup.string(),
		city: Yup.string().required('City is required'),
		state_prov: Yup.string().required('State / Prov is required'),
		postal_code: Yup.string().required('Zip Code is required')
	}),

	handleSubmit(values, { props, resetForm, setSubmitting }) {
		props.saveAddress({
			...values
		});
		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
		}, 3000);
	}
})(FormLayout);

export default ContactAddressForm;