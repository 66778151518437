import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getURLModule, getURLParentObject, getURLBaseSection } from "../../../utils/url";
import {
    getProperty,
    getPropertyCompliance,
    getPropertyDetails,
    getPropertySRFields,
} from "../../../api/propertiesAPI";
import { getReportSync } from "../../../api/reportingAPI";
import { getForm, getFormFields } from "../../../api/formsAPI";
import { BasicPropertyFields } from "./BasicPropertyFields";
import { stateReportFieldsByState } from "../../../utils/stateReportFields";
import { detailsReportMapping } from "../../../utils/reporting";
import { reportInputToFilterField } from "../../../utils/forms";
import EditProperty from "./EditProperty";
import Page from "../Page";
import Card from "../Card";
import Tabs from "../Tabs";
import LoadingSpinner from "../LoadingSpinner";
import SummaryGrid from "../Forms/SummaryGrid";
import PropertyContacts from "./PropertyContacts";
import PropertyOutfalls from "../../Pretreatment/Outfalls/PropertyOutfalls";
import AdvancedReportTable from "../../Common/ReportTable/AdvancedReportTable";

// TODO: Replace these with report tables or SummaryGrid components
// Temporary debug info display box
const Infobox = ({ title, obj }) => (
    <div style={{ display: "block", margin: "10px", width: "90%" }}>
        <h3 style={{ marginBottom: "0" }}>{title}</h3>
        <pre style={{ border: "1px solid black", padding: "10px", marginTop: "0" }}>{JSON.stringify(obj, null, 2)}</pre>
    </div>
);

const ViewProperty = () => {
    const { module, slug } = getURLModule();
    const baseSection = getURLBaseSection().section;
    const { id: propertyId } = getURLParentObject("iu") || {};
    const { organization } = useSelector((state) => state.swiftComply);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [basicFields, setBasicFields] = useState({});
    const [detailsFields, setDetailsFields] = useState({});
    const [detailsFieldsLabels, setDetailsFieldsLabels] = useState({});
    const [srFields, setSrFields] = useState([]);
    const [compliance, setCompliance] = useState(null);
    const [complianceHistory, setComplianceHistory] = useState(null);
    const [equipment, setEquipment] = useState(null);
    const [communications, setCommunications] = useState(null);
    const [surveys, setSurveys] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const refreshProperty = () => {
        setLoading(true);
        setError(null);

        getProperty(propertyId)
            .then((data) => {
                setIsActive(data.active);
                setBasicFields(BasicPropertyFields.reduce((prev, curr) => ({ ...prev, [curr.id]: data[curr.id] }), {}));
            })
            .then(() => getPropertyDetails(propertyId))
            .then((response) =>
                setDetailsFields(
                    response.details?.reduce((prev, curr) => ({ ...prev, [curr.form_field_uuid]: curr.value }), {}) ||
                        {}
                )
            )
            .then(() => getPropertySRFields(propertyId))
            .then((data) => setSrFields(JSON.parse(data.sr_fields || "{}")))
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const onEditComplete = () => {
        setEditMode(false);
        refreshProperty();
    };

    useEffect(() => {
        if (!propertyId) {
            return;
        }

        const doErrorAllow404 = (e) => {
            if (e.response?.status !== 404) {
                setError(e);
            }
        };

        getForm(detailsReportMapping.property)
            .then((data) => (data?.form_uuid ? getFormFields(data.form_uuid) : { form_fields: [] }))
            .then((fields) =>
                setDetailsFieldsLabels(
                    fields.form_fields?.reduce(
                        (prev, curr) => ({ ...prev, [curr.form_field_uuid]: reportInputToFilterField(curr) }),
                        {}
                    )
                )
            )
            .catch(setError);

        refreshProperty();

        if (module === "backflow") {
            getReportSync("c57c22af-6a1d-443d-a933-39a8de938a10", {
                inputs: { "Property UUID": propertyId },
                count: 200,
            })
                .then((data) => setCommunications(data.data?.rows))
                .catch(setError);

            getPropertyCompliance(propertyId, "Survey")
                .then((data) => setCompliance(data))
                .catch(doErrorAllow404);

            getReportSync("fb9121ec-4796-41f5-9a34-20121f1691a8", {
                inputs: { "property uuid": propertyId },
                count: 200,
            })
                .then((data) => setComplianceHistory(data.data?.rows))
                .catch(setError);

            getReportSync("b54a82b3-ae76-4c06-94cb-8098bdbf4e56", {
                search: { "property uuid": propertyId },
                inputs: { active: "null" },
                count: 200,
            })
                .then((data) => setEquipment(data.data?.rows))
                .catch(doErrorAllow404);

            getReportSync("4d7171d2-e261-4067-8698-cc02779d9fe8", {
                inputs: { "property uuid": propertyId },
                count: 200,
            })
                .then((data) => setSurveys(data.data?.rows))
                .catch(setError);
        }
    }, [propertyId]);

    if (!error && editMode) {
        return (
            <EditProperty
                preloadedBasicState={basicFields}
                preloadedDetailsState={detailsFields}
                preloadedSrState={srFields}
                onEditComplete={onEditComplete}
                onEditCancelled={() => setEditMode(false)}
            />
        );
    }

    const fields = [
        { id: "address", label: "Address" },
        BasicPropertyFields.find((f) => f.id === "property_id"),
        BasicPropertyFields.find((f) => f.id === "phone"),
        BasicPropertyFields.find((f) => f.id === "notes"),
        ...Object.entries(detailsFieldsLabels).map((f) => ({ ...f[1], id: f[0] })),
        ...(stateReportFieldsByState[module]?.properties?.[organization.state_prov] ?? []).map((field) => ({
            ...reportInputToFilterField(field),
        })),
    ];

    const values = {
        ...basicFields,
        address: [
            [basicFields.address1, basicFields.address2].filter(Boolean).join(" "),
            basicFields.city,
            basicFields.state_prov,
            basicFields.postal_code,
            basicFields.country_code,
        ]
            .filter(Boolean)
            .join(", "),
        ...detailsFields,
        ...srFields,
    };

    return (
        <Page error={error}>
            <Tabs
                tabs={[
                    {
                        label: "Overview",
                        Content: (
                            <>
                                <Card
                                    title={
                                        basicFields.name ||
                                        `Unnamed ${module === "backflow" ? "Location" : "Industrial User"}`
                                    }
                                    onEdit={() => setEditMode(true)}
                                >
                                    {loading ? <LoadingSpinner /> : <SummaryGrid fields={fields} formState={values} />}
                                </Card>
                                <PropertyContacts propertyUUID={propertyId} />
                                {module === "pretreatment" && (
                                    <>
                                        <PropertyOutfalls propertyUUID={propertyId} />
                                    </>
                                )}
                                {module === "backflow" && (
                                    <>
                                        <Infobox title="Communications" obj={communications} />
                                        <Infobox title="Equipment" obj={equipment} />
                                        <Infobox title="Surveys" obj={surveys} />
                                        <Infobox title="Compliance" obj={compliance} />
                                        <Infobox title="Compliance History" obj={complianceHistory} />
                                    </>
                                )}
                            </>
                        ),
                    },
                    {
                        label: "Sample Reports",
                        Content: (
                            <Card title="Sample Reports">
                                <AdvancedReportTable
                                    reportUUID="f72b5a28-0924-4255-a0fd-eb23c1b136d7"
                                    inputs={{ "property uuid": propertyId }}
                                    rowLinks={(row) => `/${slug}/iu/${propertyId}/sr/${row["Sample Report ID"]}`}
                                />
                            </Card>
                        ),
                    },
                ]}
            />
        </Page>
    );
};

export default ViewProperty;
