import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box} from '@mui/material';
import styles from "../../styles/common/matieralUI/tabs.module.css";

/**
 * TabPanel: Renders the content of a tab panel
 * @param {React.ReactNode} children - The content to be displayed within the tab panel
 * @param {number} value - The current tab index that is active
 * @param {number} index - The index of this specific TabPanel
 * @param {Object} [other] - Additional props passed to the container div
 * @returns {JSX.Element} The rendered tab panel component
 *
 * BasicTabs: A component that renders a set of tabs and their corresponding panels.
 * @param {Array<{label: string, Content: React.ReactNode}>} tabs - An array of objects representing each tab. Each object should contain a 'label' for the tab and 'content' to be rendered in the tab panel
 */

const TabPanel = ({ children, value, index, ...other}) => {

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BasicTabs = ({ tabs }) => {
    const [value, setValue] = useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <>
        <Box className={styles.wrapper}>
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ className: styles.indicator }}
          >
            {tabs.map(({ label }, i) => (
              <Tab label={<span className={styles.label}>{label}</span>} key={i} />
            ))}
          </Tabs>
        </Box>
        {tabs.map(({ Content }, i) => (
          <TabPanel value={value} index={i} key={i}>
            {Content}
          </TabPanel>
        ))}
      </>
    );
  }

  export default BasicTabs;