/* eslint-disable react/prop-types */
import React from 'react';
import dataAnalysisBlue from '../../../images/dataAnalysisBlue.svg';
import orgIconLarge from '../../../images/orgIconLarge.svg';

class DataAnalysisDashboard extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="componentContainer">
				<div className="componentHeader">
					<img src={dataAnalysisBlue} alt='' />
					<h3>Data Analysis</h3>
				</div>
				<img className="orgIconLarge" src={orgIconLarge} alt=''></img>
			</div>
		);
	}
}

export default DataAnalysisDashboard;