import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ReactTable, { ReactTableDefaults } from './External/ReactTable';


import { ModalDuplicateContext } from '../contexts/ModalDuplicateContext';

const ModalDuplicateCheck = () => {
    const listContents = (dupes, fields, url) => {
        if (dupes === undefined) {
            return null;
        }

        let header = [<th key="radio"></th>, <th key="name"></th>];
        let columns = [
            {
                show: true,
                Header: "",
                width: 25,
                Cell: ({ original }) => (
                    <input type="radio" name="duplicate-modal-selection" value={original.uuid} />
                )
            },
            {
                show: true,
                accessor: 'value',
                Header: "",
                Cell: ({ original }) => (
                    <div style={{ textAlign: 'center' }}>
                        {(url !== null) ?
                            <a href={url + '/' + original.uuid} target='_blank'>
                                {original.value} {(!original.active) ? "(inactive)" : ""}
                            </a>
                            :
                            original.value
                        }
                    </div>
                )
            }
        ];

        fields.forEach((field) => {
            let fieldName = (field instanceof Object) ? field.name : field;
            let dataName = (field instanceof Object) ? field.data : field;

            header.push(<th key={fieldName}>{fieldName}</th>);
            columns.push({
                show: true,
                id: fieldName,
                accessor: row => row.additional_fields[dataName],
                Header: () => (<div style={{ textAlign: 'center' }}>{fieldName}</div>),
                Cell: ({ original }) => (
                    <div style={{ textAlign: 'center' }}>
                        {(url !== null) ?
                            <a href={url + '/' + original.uuid} target='_blank'>
                                {original.additional_fields[dataName]}
                            </a>
                            :
                            original.additional_fields[dataName]
                        }
                    </div>
                )
            });
        });

        return <ReactTable
            data={dupes}
            columns={columns}
            resizable={false}
            sortable={false}
            minRows={0}
            showPagination={false}
            getProps={
                (state, rowInfo, column, instance) => {
                    return {
                        style: {
                            height: 'auto'
                        },
                    };
                }
            }
        />;
    }

    return (
        <ModalDuplicateContext.Consumer>
            {(props) => {
                if (props.open) {
                    return (
                        <Dialog open={props.open}>
                            <DialogContent>
                                We have found possible duplicates for the data you have entered.
                                If you would like to use one of these entries, please select it.  If you want to save
                                the data as entered, click save.  To change the data, click close.
                            </DialogContent>
                            <DialogContent>
                                {listContents(props.dupes, props.fields, props.url)}
                            </DialogContent>
                            <DialogActions>
                                <button className="smallButtonPrimary" onClick={() => {
                                    document.getElementsByName("duplicate-modal-selection").forEach((input, idx) => {
                                        if (input.checked) {
                                            props.useSelected(input.value);
                                            return;
                                        }
                                    });
                                }}>
                                    use selected
                                </button>
                                {props.saveCallback !== null && (
                                    <button className="smallButtonPrimary" onClick={props.save}>save</button>
                                )}
                                <button className="smallButtonSecondary" onClick={props.close}>close</button>
                            </DialogActions>
                        </Dialog>
                    )
                } else {
                    return null;
                }
            }}
        </ModalDuplicateContext.Consumer >
    );
}

export default ModalDuplicateCheck;
