import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Form from "../../Common/Forms/Form";
import Button from "../../Common/Button";
import Typography from "@mui/material/Typography";
import { createSamplingConfig, updateSamplingConfig } from "../../../api/analytesAPI";
import ToolTip from "../../Common/ToolTip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const EditConfigurationDialog = ({
    open,
    onClose,
    initialConfig,
    title = "Edit Configuration",
    sampleReportConfigId,
    samplingConfigId,
}) => {
    const parsePeriodString = (periodString) => {
        if (!periodString) {
            return { quantity: "", unit: "" };
        }

        const parts = periodString.split(" ");
        if (parts.length === 2) {
            return { quantity: parts[0], unit: parts[1] };
        }

        return { quantity: parts[0], unit: "" };
    };

    const [validationErrors, setValidationErrors] = useState([]);
    const [formState, setFormState] = useState({
        name: "",
        sampling_interval: "",
        sampling_frequency: "",
        administrative_period: "",
        administrative_period_unit: "days",
        sampling_period: "",
        sampling_period_unit: "days",
        sampling_count: "",
        enable_stop_after: false,
    });

    useEffect(() => {
        if (!open) {
            // Reset form if dialog is closing
            setFormState({
                name: "",
                sampling_interval: "",
                sampling_frequency: "",
                administrative_period: "",
                administrative_period_unit: "",
                sampling_period: "",
                sampling_period_unit: "",
                sampling_count: "",
                enable_stop_after: false,
            });
            setValidationErrors([]);
            return;
        }

        if (initialConfig) {
            const { quantity: adminQuantity, unit: adminUnit } = parsePeriodString(initialConfig.adminPeriod);
            const { quantity: samplingQuantity, unit: samplingUnit } = parsePeriodString(initialConfig.samplingPeriod);

            const discoveredFormState = {
                name: initialConfig.name || "",
                sampling_interval: initialConfig.sampleSchedule?.interval || "",
                sampling_frequency: initialConfig.sampleSchedule?.frequency || "",
                administrative_period: adminQuantity,
                // Notice we keep whatever parsePeriodString returned for unit -
                // if it's empty, we let validation catch it
                administrative_period_unit: adminUnit,
                sampling_period: samplingQuantity,
                sampling_period_unit: samplingUnit,
                sampling_count: initialConfig.sampling_count || "",
                enable_stop_after: !!initialConfig.sampling_count,
            };

            setFormState(discoveredFormState);
        } else {
            setFormState({
                name: "",
                sampling_interval: "",
                sampling_frequency: "",
                administrative_period: "",
                administrative_period_unit: "",
                sampling_period: "",
                sampling_period_unit: "",
                sampling_count: "",
                enable_stop_after: false,
            });
        }
    }, [initialConfig, open]);

    const handleFormStateChange = (updater, fieldId) => {
        const newState = typeof updater === "function" ? updater(formState) : updater;
        if (JSON.stringify(newState) !== JSON.stringify(formState)) {
            setFormState((prevState) => ({
                ...prevState,
                ...newState,
            }));
        }
    };

    const validateForm = () => {
        const errors = [];

        // Required field validation
        if (!formState.name?.trim()) {
            errors.push({
                id: "name",
                error: "Name is required",
            });
        }

        if (!formState.sampling_frequency) {
            errors.push({
                id: "sampling_frequency",
                error: "Sampling frequency is required",
            });
        }

        if (!formState.sampling_interval) {
            errors.push({
                id: "sampling_interval",
                error: "Sampling interval is required",
            });
        }

        setValidationErrors(errors);
        return errors.length === 0;
    };

    const handleSave = () => {
        if (!validateForm()) {
            return;
        }

        const submissionData = {
            sample_report_config_id: parseInt(sampleReportConfigId),
            name: formState.name?.trim(),
            sampling_interval: formState.sampling_interval,
            sampling_frequency: formState.sampling_frequency,
            administrative_period: formState.administrative_period
                ? `${formState.administrative_period} ${formState.administrative_period_unit}`
                : undefined,
            sampling_period: formState.sampling_period
                ? `${formState.sampling_period} ${formState.sampling_period_unit}`
                : undefined,
            ...(formState.enable_stop_after &&
                formState.sampling_count && {
                    sampling_count: parseInt(formState.sampling_count),
                }),
        };

        // Filter out any undefined or null values
        const cleanedData = Object.fromEntries(Object.entries(submissionData).filter(([_, value]) => value != null));

        if (initialConfig) {
            updateSamplingConfig(samplingConfigId, cleanedData)
                .then(() => {
                    setValidationErrors([]);
                    onClose();
                })
                .catch((error) => {
                    console.error("Error updating configuration:", error);
                    setValidationErrors(
                        error.response?.data?.Details?.map((detail) => ({
                            id: detail.field,
                            error: detail.description,
                        })) || []
                    );
                });
        } else {
            createSamplingConfig(cleanedData)
                .then(() => {
                    setValidationErrors([]);
                    onClose();
                })
                .catch((error) => {
                    console.error("Error creating configuration:", error);
                    setValidationErrors(
                        error.response?.data?.Details?.map((detail) => ({
                            id: detail.field,
                            error: detail.description,
                        })) || []
                    );
                });
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth sx={{ padding: "1rem" }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Form
                    fields={[
                        {
                            id: "name",
                            label: "Configuration Name",
                            noClear: true,
                            required: true,
                        },
                    ]}
                    formState={formState}
                    onFormStateChange={(updater) => handleFormStateChange(updater, "name")}
                    validationErrors={validationErrors}
                    onValidationErrorsChange={setValidationErrors}
                />

                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "1.6rem",
                        marginTop: "2rem",
                        marginBottom: "1rem",
                    }}
                >
                    Sampling Schedule
                </Typography>

                <div
                    style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                    }}
                >
                    <span style={{ fontSize: "1.4rem", color: "#506788" }}>Repeat Every:</span>
                    <div
                        style={{
                            display: "flex",
                            gap: "2rem",
                            flex: "1",
                        }}
                    >
                        <div style={{ flex: "0 1 auto", maxWidth: "12rem" }}>
                            <Form
                                fields={[
                                    {
                                        id: "sampling_interval",
                                        label: "Interval",
                                        dataType: "numeric",
                                        noClear: true,
                                        required: true,
                                    },
                                ]}
                                formState={formState}
                                onFormStateChange={(updater) => handleFormStateChange(updater, "sampling_interval")}
                                validationErrors={validationErrors}
                                onValidationErrorsChange={setValidationErrors}
                            />
                        </div>
                        <div style={{ flex: "1" }}>
                            <Form
                                fields={[
                                    {
                                        id: "sampling_frequency",
                                        label: "Frequency",
                                        inputType: "select",
                                        noClear: true,
                                        options: [
                                            { label: "Daily", value: "daily" },
                                            {
                                                label: "Weekly",
                                                value: "weekly",
                                            },
                                            {
                                                label: "Monthly",
                                                value: "monthly",
                                            },
                                            {
                                                label: "Annually",
                                                value: "annually",
                                            },
                                        ],
                                        required: true,
                                    },
                                ]}
                                formState={formState}
                                onFormStateChange={(updater) => handleFormStateChange(updater, "sampling_frequency")}
                                validationErrors={validationErrors}
                                onValidationErrorsChange={setValidationErrors}
                            />
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                    }}
                >
                    <Form
                        fields={[
                            {
                                id: "enable_stop_after",
                                inputType: "checkbox",
                                label: "",
                            },
                        ]}
                        formState={formState}
                        onFormStateChange={(updater) => handleFormStateChange(updater, "enable_stop_after")}
                        validationErrors={validationErrors}
                        onValidationErrorsChange={setValidationErrors}
                    />
                    <span style={{ fontSize: "1.4rem", margin: "2px 1rem 0 -2.5rem", color: "#506788" }}>After:</span>
                    <div style={{ flex: 1 }}>
                        <Form
                            fields={[
                                {
                                    id: "sampling_count",
                                    label: "# of occurrences",
                                    dataType: "numeric",
                                    disabled: !formState.enable_stop_after,
                                    noClear: true,
                                },
                            ]}
                            formState={formState}
                            onFormStateChange={(updater) => handleFormStateChange(updater, "sampling_count")}
                            validationErrors={validationErrors}
                            onValidationErrorsChange={setValidationErrors}
                        />
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            gap: "2rem",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <ToolTip
                                title="At the end of the Sample Period the user has the Administrative Period to prepare the report prior to the report due date"
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            textAlign: "center",
                                        },
                                    },
                                }}
                            >
                                <InfoOutlinedIcon fontSize="large" sx={{ color: "#506788" }} />
                            </ToolTip>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Form
                                fields={[
                                    {
                                        id: "administrative_period",
                                        label: "Administrative Period",
                                        dataType: "numeric",
                                        noClear: true,
                                    },
                                ]}
                                formState={formState}
                                onFormStateChange={(updater) => handleFormStateChange(updater, "administrative_period")}
                                validationErrors={validationErrors}
                                onValidationErrorsChange={setValidationErrors}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Form
                                fields={[
                                    {
                                        id: "administrative_period_unit",
                                        label: "Units",
                                        inputType: "select",
                                        noClear: true,
                                        options: [
                                            { label: "days", value: "days" },
                                            { label: "weeks", value: "weeks" },
                                            {
                                                label: "months",
                                                value: "months",
                                            },
                                        ],
                                    },
                                ]}
                                formState={formState}
                                onFormStateChange={(updater) =>
                                    handleFormStateChange(updater, "administrative_period_unit")
                                }
                                validationErrors={validationErrors}
                                onValidationErrorsChange={setValidationErrors}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            gap: "2rem",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <ToolTip
                                title="All sampling must occur within the Sampling Period"
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            textAlign: "center",
                                        },
                                    },
                                }}
                            >
                                <InfoOutlinedIcon fontSize="large" sx={{ color: "#506788" }} />
                            </ToolTip>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Form
                                fields={[
                                    {
                                        id: "sampling_period",
                                        label: "Sample Period",
                                        dataType: "numeric",
                                        noClear: true,
                                    },
                                ]}
                                formState={formState}
                                onFormStateChange={(updater) => handleFormStateChange(updater, "sampling_period")}
                                validationErrors={validationErrors}
                                onValidationErrorsChange={setValidationErrors}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Form
                                fields={[
                                    {
                                        id: "sampling_period_unit",
                                        label: "Units",
                                        inputType: "select",
                                        noClear: true,
                                        options: [
                                            { label: "days", value: "days" },
                                            { label: "weeks", value: "weeks" },
                                            {
                                                label: "months",
                                                value: "months",
                                            },
                                        ],
                                    },
                                ]}
                                formState={formState}
                                onFormStateChange={(updater) => handleFormStateChange(updater, "sampling_period_unit")}
                                validationErrors={validationErrors}
                                onValidationErrorsChange={setValidationErrors}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={handleSave}>Save Configuration</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditConfigurationDialog;
