import React from "react";
import styles from "../../styles/common/buttons.module.css";
import transitions from "../../styles/common/transitions.module.css";

/**
 * Styled button
 *
 * @param {string} [className] - CSS class name, if specified, will override all other color/size options
 * @param {"primary"|"secondary"|"tertiary"|"warning"} [color] - Color scheme of the button, default is "secondary"
 * @param {"large"|"normal"|"small"} [size] - Size of button, default is "normal"
 * @param {React.ReactNode} [children] - Content displayed on the button
 * @param props - All other props are passed to the plain HTML <button> component
 */
const Button = ({ color, size, className, children, ...props }) => {
    const colorClasses = {
        primary: styles.primary,
        tertiary: styles.tertiary,
        warning: styles.warning,
    };

    const sizeClasses = {
        large: styles.large,
        small: styles.small,
    };

    return (
        <button
            type="button"
            className={
                className ??
                [styles.button, transitions.medium, colorClasses[color], sizeClasses[size]].filter(Boolean).join(" ")
            }
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;
