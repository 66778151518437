/* eslint-disable */
import React from "react";
import { NavLink } from "react-router-dom";
import Fieldset from "../TestReports/Fieldset";
import SurveyorInfo from "./SurveyorInfo";
import SurveyLocationInfo from "./SurveyLocationInfo";
import { getForm, getFieldsets, getFormFields } from "../../api/formsAPI";
import { listRulesets, evaluateRuleset } from "../../api/formRulesAPI";
import BallotIcon from "@mui/icons-material/Ballot";
import {
    createComplianceReport,
    updateComplianceReport,
    createComplianceReportDetail,
    getComplianceReport,
    getComplianceReportDetails,
    submitComplianceReport,
} from "../../api/complianceAPI";
import { createProperty, getProperty } from "../../api/propertiesAPI";
import { getServiceProvider, getServiceProviderUserInfoReport } from "../../api/serviceProvidersAPI";
import { updateURL, parseQueryStringParams } from "../../utils/url";
import { getReportValue } from "../../utils/reporting";
import validator from "validator";
import { getOwnUser, getUsersReport } from "../../api/usersAPI";
import { checkDuplicates } from "../../api/duplicatesAPI";
import { getOrganizationSetting } from "../../api/organizationAPI";

class Survey extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            formFields: [],
            fieldsets: [],
            reportedOnField: null,
            renderedFieldset: [],
            activeFieldset: 0,
            progress: -1,
            overallProgress: 0,
            steps: 0,
            reportPhase: "locationAssemblyInfo",
            submitted: false,
            reportCreated: false,
            newLocation: false,
            locationInfo: null,
            serviceProviderInfo: null,
            reporterInfo: null,
            complianceReportInfo: {},
            pendingComplianceReportDetails: {}, // Answers we can't save yet if we don't have a report
            complianceReportDetails: {},
            rulesets: null,
            lastFieldsetIndex: 0,
            messageOptions: {},
            surveyDoneByProvider: false,
        };

        let params = parseQueryStringParams(this.props.location.search);
        // to get here currently you need to go to /surveys/newSurvey and then you can either send a
        // location uuid along if this is really new, or report uuid if you are picking up where you left off
        if (params.report && validator.isUUID(params.report)) {
            this.state.complianceReportInfo = { compliance_report_uuid: params.report };
            // this phase isn't anything, its more just to not show the SP table while we wait for data to load
            this.state.reportPhase = "loadingForm";
        } else if (params.location && validator.isUUID(params.location)) {
            this.state.locationInfo = { property_uuid: params.location };
            this.state.reportPhase = "serviceProviderInfo";
        }

        // JS is extremely dumb, and just doing one of these number checks won't guarantee you that the thing passed in
        // was a number.  ParseInt will parse things like 123lkdjfkj to 123, and Number will return 0 on empty string
        // but by their powers combined, they are Captain ThisIsTheDumbestLanguageEver
        if (
            params.step &&
            !isNaN(Number.parseInt(params.step, 10)) &&
            !isNaN(Number(params.step)) &&
            params.step >= 1
        ) {
            // this is used to index arrays so starts at 0, but we show to the users as 1,2,3,...
            this.state.progress = Number.parseInt(params.step, 10) - 1;
        }
    }

    componentDidMount() {
        getOwnUser().then((user) => {
            getOrganizationSetting("compliance.surveys.reported_by_service_provider").then((response) => {
                let surveyDoneByProvider = false;
                if (response === "true") {
                    surveyDoneByProvider = true;
                }
                this.setState({ user, surveyDoneByProvider });

                if (this.state.complianceReportInfo.compliance_report_uuid) {
                    this.getTestReportFormData();
                }
            });
        });
        // we pull it in the getTestReportFormData too but if we don't here, we can't populate the header when they
        // are choosing a service provider
        if (this.state.locationInfo && this.state.locationInfo.property_uuid) {
            getProperty(this.state.locationInfo.property_uuid).then((response) => {
                this.setState({ locationInfo: response });
            });
        }
    }

    // Calling the api to get the backflow assembly test form UUID,
    // then using that to get the fieldsets for that form and storing it in state
    getTestReportFormData = async () => {
        if (this.state.complianceReportInfo.compliance_report_uuid) {
            let reportData = await getComplianceReport(this.state.complianceReportInfo.compliance_report_uuid);
            let formFieldsets = await getFieldsets(reportData.form_uuid);
            let formFields = await getFormFields(reportData.form_uuid);
            let detailsData = await getComplianceReportDetails(this.state.complianceReportInfo.compliance_report_uuid);
            let locationInfo = await getProperty(reportData.source_uuid);

            let serviceProviderInfo = null;
            let reporterInfo = null;
            if (reportData.service_provider_uuid) {
                serviceProviderInfo = await getServiceProvider(reportData.service_provider_uuid);
            }
            if (reportData.reported_by) {
                reporterInfo = await this.getReporterInfo(
                    reportData.reported_by.user_uuid,
                    reportData.service_provider_uuid,
                    true
                );
            }

            let complianceReportDetails = {};
            detailsData.compliance_report_details.forEach((detail) => {
                complianceReportDetails[detail.form_field_uuid] = detail.value;
            });

            // figure out where they last left off by looking for details in each fieldset, if they saved them, they
            // would have clicked next so move them to the next step
            let reportedOnField = null;
            formFields.form_fields.forEach((field) => {
                if (field.form_field_type === "Test Date") {
                    reportedOnField = field.form_field_uuid;
                }
            });

            this.setState(
                () => ({
                    steps: formFieldsets.form_fieldsets.length,
                    submitted: reportData.status === "in_progress" ? false : true,
                    reportCreated: true,
                    complianceReportInfo: reportData,
                    complianceReportDetails: complianceReportDetails,
                    locationInfo: locationInfo,
                    fieldsets: formFieldsets.form_fieldsets,
                    formFields: formFields.form_fields,
                    reportedOnField: reportedOnField,
                    serviceProviderInfo: serviceProviderInfo,
                    reporterInfo: reporterInfo,
                }),
                () => {
                    // do these two first as it will build the list of what fieldsets are actually active, and then set
                    // the users progress
                    this.getRulesetList(() => {
                        this.evaluateFieldsets(() => {
                            this.setActiveFieldset(this.state.progress);
                            // you have to do this here and rerender since the activeFieldSet call does important work
                            this.setState({ reportPhase: "form" });
                        });
                    });
                }
            );
        } else {
            let form = await getForm("Backflow Survey");
            let formFieldsets = await getFieldsets(form.form_uuid);
            let formFields = await getFormFields(form.form_uuid);

            let locationInfo = this.state.locationInfo;
            if (this.state.locationInfo !== null && this.state.locationInfo.property_uuid) {
                locationInfo = await getProperty(this.state.locationInfo.property_uuid);
            }

            let reportedOnField = null;
            formFields.form_fields.forEach((field) => {
                if (field.form_field_type === "Test Date") {
                    reportedOnField = field.form_field_uuid;
                }
            });

            this.setState(
                () => ({
                    steps: formFieldsets.form_fieldsets.length,
                    fieldsets: formFieldsets.form_fieldsets,
                    formFields: formFields.form_fields,
                    reportedOnField,
                    locationInfo,
                }),
                () => {
                    this.setActiveFieldset();
                    this.setState({ reportPhase: "form" });
                    this.getRulesetList(() => {
                        this.evaluateFieldsets();
                    });
                }
            );
        }
    };

    determineProgress = (
        formFields = this.state.formFields,
        formFieldsets = this.state.fieldsets,
        complianceReportDetails = this.state.complianceReportDetails
    ) => {
        // figure out where they last left off by looking for details in each fieldset, if they saved them, they
        // would have clicked next so move them to the next step
        let lastFieldset = null;
        formFields.forEach((field) => {
            if (complianceReportDetails[field.form_field_uuid]) {
                lastFieldset = field.form_fieldset_uuid;
            }
        });

        let progress = 0;
        let overallProgress = 0;
        let foundLastFieldset = false;
        for (let idx = 0; idx < formFieldsets.length; idx++) {
            let fieldset = formFieldsets[idx];
            if (fieldset.form_fieldset_uuid === lastFieldset) {
                foundLastFieldset = true;
                progress = idx;
            } else if (foundLastFieldset && fieldset.active) {
                // this is here because if we found the last fieldset they submitted, next we want to find the next
                // fieldset that is active and actually put them on that, since those are the fields they need to keep
                // filling out.  If it was the last fieldset, we would just ignore this section
                progress = idx;
                break;
            }
        }

        overallProgress = progress;
        if (this.state.progress !== -1 && progress > this.state.progress) {
            // if we went to a step=X and X is < than what we've filled out, let them go back, I guess
            // we should really enable all the breadcrumbs though so they can jump ahead as well
            progress = this.state.progress;
        }

        // if they somehow overran the form, set them back to the last page
        if (progress >= formFieldsets.length) {
            progress = formFieldsets.length - 1;
            overallProgress = progress;
        }

        return { progress, overallProgress };
    };

    getReporterInfo = async (reporterUUID, serviceProviderUUID) => {
        let reportFunc = getUsersReport;
        // the users report call takes the type here first which I guess we could set at normal, but leaving it
        // off just in case, then it takes a json payload instead of an ID (like the other report) so we will just
        // put the json in this array so they both kind of just work
        let args = [undefined, { inputs: { "User UUID": reporterUUID } }];

        if (serviceProviderUUID !== null) {
            reportFunc = getServiceProviderUserInfoReport;
            args = [serviceProviderUUID, reporterUUID];
        }

        let reportData = await reportFunc(...args);
        reportData = reportData.data;

        let reporterInfo = {};
        reportData.rows.forEach((row) => {
            if (getReportValue(reportData, row, "User UUID") === reporterUUID) {
                reporterInfo = {
                    user_uuid: getReportValue(reportData, row, "User UUID"),
                    name: getReportValue(reportData, row, "Name"),
                    email: getReportValue(reportData, row, "Email"),
                };
            }
        });

        if (reporterInfo.user_uuid === undefined) {
            // FIXME return some error?
        }

        return reporterInfo;
    };

    // Storing the rulesets to state so we can use them when evaluating
    // the field inputs
    getRulesetList = async (callback) => {
        const rulesetData = await listRulesets();
        const rulesets = await rulesetData.rulesets;

        this.setState({ rulesets }, callback);
    };

    // Rendering the fieldset component. I at one point had all of the fieldsets in an array and only showed one
    // to the user, but this means the Fieldsets dont re-render, and we need the fields to either auto-populate,
    // or show the compliance details already present in the DB.
    defineFieldsets = () => {
        const {
            steps,
            lastFieldsetIndex,
            activeFieldset,
            complianceReportInfo,
            complianceReportDetails,
            renderedFieldset,
        } = this.state;

        let prepopulateData = {
            properties: this.state.locationInfo,
            service_providers: this.state.serviceProviderInfo, // todo
            reporter: this.state.reporterInfo,
        };

        return (
            <Fieldset
                complianceReportInfo={complianceReportInfo}
                formType="survey"
                key={activeFieldset}
                index={activeFieldset}
                active={activeFieldset}
                data={renderedFieldset[0]}
                length={steps}
                submitted={this.state.submitted}
                setActiveFieldset={this.setActiveFieldset}
                submitReport={this.submitReport}
                saveFormInputData={this.saveFormInputData}
                complianceReportDetails={complianceReportDetails}
                prepopulateData={prepopulateData}
                isLastFieldset={lastFieldsetIndex === activeFieldset && activeFieldset !== 0}
                messageOptions={this.state.messageOptions}
            />
        );
    };

    // This function gets the index of the fieldset we want currently active, and puts it in a separate array
    // that gets rendered.
    setActiveFieldset = async (index = 0) => {
        const { fieldsets, activeFieldset } = this.state;
        let { progress, overallProgress } = this.state;

        // are we asking to go forward or back, this then determines how we move the fieldset index below
        let direction = index > activeFieldset ? "fwd" : "back";

        while (index >= 0 && index < fieldsets.length) {
            if (fieldsets[index].active) {
                this.setState({
                    renderedFieldset: [fieldsets[index]],
                    activeFieldset: index,
                    progress: index > progress ? index : progress,
                    overallProgress: index > overallProgress ? index : overallProgress,
                    messageOptions: {}, // reset any errors if we are moving between fieldsets
                });
                if (this.state.complianceReportInfo.compliance_report_uuid) {
                    updateURL("/surveys/newSurvey", {
                        report: this.state.complianceReportInfo.compliance_report_uuid,
                        step: fieldsets[index].ordering,
                    });
                }
                return;
            }
            if (direction === "fwd") {
                index++;
            } else {
                index--;
            }
        }
    };

    // Much like initial info below, if a user is making a new "blank" report they need to look up the location
    // or tell us it is new, this will get that information back in the form of either uuid or new flags
    setLocationInfo = (locationUUID, newLocation) => {
        let reportPhase = "serviceProviderInfo";

        if (newLocation) {
            this.setState({ newLocation, reportPhase });
        } else {
            this.setState({ locationInfo: { property_uuid: locationUUID }, reportPhase });
        }
    };

    // This function receives the service provider and tester information from the initial table selections
    // This data to be used when creating compliance report and possibly auto-populating the test report
    setSurveyorInfo = (type, data) => {
        if (type === "serviceProvider") {
            getServiceProvider(data.service_provider_uuid).then((response) => {
                this.setState({ serviceProviderInfo: response });
            });
        } else if (type === "surveyor") {
            let serviceProviderUUID =
                this.state.serviceProviderInfo !== null ? this.state.serviceProviderInfo.service_provider_uuid : null;
            this.getReporterInfo(data.user_uuid, serviceProviderUUID).then((response) => {
                this.setState({ reporterInfo: response }, () => {
                    this.getTestReportFormData();
                });
            });
        }
    };

    // Called when the user hits the yes button confirming the information is correct
    // and they move to the next fieldset.
    submitReport = async (payload, setSubmittedStatus, isLastFieldset) => {
        const { reportCreated, fieldsets, locationInfo, serviceProviderInfo, reporterInfo } = this.state;
        const formUUID = fieldsets[0]["form_uuid"];

        // we might not have this if the report hasn't been created, so make it here and then set it to the newly
        // created reportInfo if we have to, so that we know its a sync operation and don't rely on setstate
        let complianceReportInfo = this.state.complianceReportInfo;

        // if the report isn't in progress, we should not be submitting the fields again
        if (complianceReportInfo.status && complianceReportInfo.status.toLowerCase() !== "in_progress") {
            if (isLastFieldset) {
                window.location.replace("/surveys/" + this.state.complianceReportInfo.compliance_report_uuid);
            } else {
                await this.evaluateFieldsets(() => {
                    this.setActiveFieldset(this.state.activeFieldset + 1);
                });
            }
            return;
        }

        // Checks to see if compliance report has been created. If not, it creates one, saves that
        // report data to state and marks that a report has now been created for this Test Report. Finally it will now
        // call the api to create compliance report details for each field in the fieldset using the
        // payload data. "type" is to determine whether report details need to be created or
        // updated.
        if (!reportCreated) {
            // if we haven't created the report, we are just going to toss all the items in the payload into the
            // pending details list, so we know we can just look in one place later.  But if we do end up looking in
            // both pending + payload that doesn't matter since the uuid -> answers will be the same.
            let pendingComplianceReportDetails = this.state.pendingComplianceReportDetails;
            Object.keys(payload).forEach((key) => {
                pendingComplianceReportDetails[key] = payload[key];
            });
            this.setState({ pendingComplianceReportDetails });

            // If the user indicated that they are at a new location, we need to make sure we have all the info we need
            // to make a report.
            if (this.state.newLocation) {
                this.beginPropertyCreation();
            }

            // if we have enough info, start the report
            if (locationInfo !== null && locationInfo.property_uuid !== null) {
                const reportInfo = await createComplianceReport({
                    source: "property",
                    source_uuid: locationInfo.property_uuid,
                    form_uuid: formUUID,
                    service_provider_uuid:
                        serviceProviderInfo !== null ? serviceProviderInfo.service_provider_uuid : null,
                    reported_by: reporterInfo.user_uuid,
                });
                complianceReportInfo = reportInfo;
                // set the URL to the report uuid so they don't refresh and end up making a new one
                updateURL("/surveys/newSurveys", { report: reportInfo.compliance_report_uuid, step: 2 });
                this.setState(() => ({ complianceReportInfo: reportInfo, reportCreated: true }));
            }
        }

        if (complianceReportInfo != null && complianceReportInfo.compliance_report_uuid != null) {
            let reportedOn = null;
            Object.keys(payload).forEach((key) => {
                if (key === this.state.reportedOnField && payload[key] != "") {
                    reportedOn = payload[key];
                }
            });
            if (reportedOn !== null) {
                updateComplianceReport(complianceReportInfo.compliance_report_uuid, reportedOn);
            }

            await this.createReportDetails(payload, complianceReportInfo.compliance_report_uuid);
        }

        // They clicked submit or save & submit
        if (setSubmittedStatus) {
            let reportUUID = this.state.complianceReportInfo.compliance_report_uuid;
            let serviceProviderUUID = serviceProviderInfo ? serviceProviderInfo.service_provider_uuid : null;
            submitComplianceReport(reportUUID, serviceProviderUUID)
                .then((response) => {
                    if (isLastFieldset) {
                        // after submitting go back to that report on a flyout, not doing that I felt was confusing
                        window.location.replace("/surveys/" + this.state.complianceReportInfo.compliance_report_uuid);
                    }
                })
                .catch((error) => {
                    let errorMessage = "There was an error submitting the survey.";
                    if (error.name === "APIUserError") {
                        document.getElementById("componentHeader").scrollIntoView({ behavior: "smooth" });

                        // We will need to customize the errors from the backend here depending on what report type this is
                        // just mentioning it now in case we generalize this
                        if (error.apiFieldErrors.reported_by) {
                            errorMessage =
                                "The tester has not yet been approved to submit surveys, the report has been saved, once approved you can try to submit again.";
                        }
                    } else if (error.response.status == 409) {
                        errorMessage = error.response.data.Error;
                    } else if (error.response.status == 400) {
                        // this is bad since we can tell them the field labels they are missing I believe, but likely they
                        // won't get here since a 400 is also likely an APIUserError, also they shouldn't get to this stage
                        // since each page should have checked the values
                        errorMessage =
                            "Some values may be missing or invalid, please review the form for completeness.";
                    }
                    this.setState({
                        messageOptions: {
                            type: "error",
                            message: errorMessage,
                        },
                    });
                });
        } else {
            // If we didn't submit and this is the last page, send them back to the report flyout?
            if (isLastFieldset) {
                window.location.replace("/surveys/" + this.state.complianceReportInfo.compliance_report_uuid);
            } else {
                await this.evaluateFieldsets(() => {
                    this.setActiveFieldset(this.state.activeFieldset + 1);
                });
            }
        }
        // Scroll to top when advancing to next fieldset. If it's the last page we don't need to.
        if (!setSubmittedStatus) {
            setTimeout(() => {
                const header = document.getElementById("componentHeader");
                header.scrollIntoView({ behavior: "smooth" });
            }, 100);
        }
    };

    // checks the current payload and any pending details to see if we have enough info to create an property entry,
    // if we do creates and sets the state
    beginPropertyCreation = async () => {
        let required = ["name", "address1", "city", "state_prov", "postal_code", "country_code"];
        let request = { country_code: SwiftComply.organization.country_code.toLocaleUpperCase() };
        this.state.formFields.forEach((field) => {
            if (field.populate_from != null && field.populate_from.startsWith("properties.")) {
                let param = field.populate_from.split(".")[1];
                if (
                    param !== undefined &&
                    this.state.pendingComplianceReportDetails[field.form_field_uuid] !== undefined
                ) {
                    request[param] = this.state.pendingComplianceReportDetails[field.form_field_uuid];
                }
            }
        });

        let readyToCreate = true;
        required.forEach((param) => {
            if (request[param] === undefined) {
                readyToCreate = false;
            }
        });

        if (readyToCreate) {
            await checkDuplicates("properties", request.name).then((response) => {
                if (response.duplicates.length > 0) {
                    this.props.duplicateContext.setDuplicates(
                        response.duplicates,
                        [{ name: "address", data: "address1" }, "city"],
                        this.state.user.user_type === "service provider" ? null : "/locations",
                        request,
                        (data) => {
                            this.handlePropertyCreationOrSelection(data);
                        },
                        (property_uuid) => {
                            this.handlePropertyCreationOrSelection(null, property_uuid);
                        }
                    );
                } else {
                    this.handlePropertyCreationOrSelection(request);
                }
            });
        }
    };

    handlePropertyCreationOrSelection = async (data, property_uuid) => {
        let locationInfo = {};
        if (property_uuid !== undefined) {
            locationInfo = { property_uuid };
        } else {
            locationInfo = await createProperty(data);
        }

        this.setState({ newLocation: false, locationInfo }, () => {
            this.evaluateFieldsets(() => {
                this.setActiveFieldset(this.state.activeFieldset + 1);
            });
        });
    };

    // Called on submission of each fieldset, this function adds all of the fields submitted in that fieldset with
    // their values to state. This will be used to evaluate fields for the formrules service.
    saveFormInputData = (payload, direction) => {
        this.setState(
            (prevState) => ({ complianceReportDetails: { ...prevState.complianceReportDetails, ...payload } }),
            () => {
                this.evaluateFieldsets(() => {
                    // needed to make sure we evaluate the fields before we set the active fieldset so moved it into here, I don't
                    // like it but thats how the flow works right now.
                    if (direction && direction === "fwd") {
                        this.setActiveFieldset(this.state.activeFieldset + 1);
                    }
                });
            }
        );
    };

    // This function will evaluate all the fields stored in the complianceReportDetails array in state.
    // For each formRule, it evalutes the fieldsets and if the evaluation comes back false,
    // it filters that  fieldset UUID from the list of fieldsets stored in state.
    evaluateFieldsets = async (callback) => {
        const { rulesets, complianceReportDetails } = this.state;
        let updatedFieldsets = this.state.fieldsets;

        await Promise.all(
            rulesets.map(async (rule) => {
                let response = await evaluateRuleset(rule.form_ruleset_uuid, complianceReportDetails);
                for (let i = 0; i < updatedFieldsets.length; i++) {
                    if (updatedFieldsets[i].form_fieldset_uuid === rule.form_fieldset_uuid) {
                        updatedFieldsets[i].active = response.evaluation;
                    }
                }
            })
        );

        let steps = 0;
        let lastFieldsetIndex = 0;
        let index = 0;
        updatedFieldsets.forEach((fieldset) => {
            if (fieldset.active) {
                steps++;
                lastFieldsetIndex = index;
            }
            index++;
        });

        let progress = this.determineProgress();
        this.setState(
            {
                steps: steps,
                fieldsets: updatedFieldsets,
                lastFieldsetIndex: lastFieldsetIndex,
                progress: progress.progress,
                overallProgress: progress.overallProgress,
            },
            callback
        );
    };

    // Here we are creating or updating complaince report details for each field submitted.
    createReportDetails = async (payload, reportUUID) => {
        let { complianceReportDetails } = this.state;

        // if we have any pending details that we couldn't save yet, add them to the payload
        if (Object.keys(this.state.pendingComplianceReportDetails).length !== 0) {
            Object.keys(this.state.pendingComplianceReportDetails).forEach((key) => {
                payload[key] = this.state.pendingComplianceReportDetails[key];
            });
        }

        await Promise.all(
            Object.keys(payload).map(async (key) => {
                const fieldUUID = key;
                const value = payload[key];

                // We can probably use this to decide whether we want to create or update, however the backend was changed
                // so that you can always create these and it will do the right thing, so just call create on anything that
                // has changed (or doesn't exist yet)
                if (!complianceReportDetails[fieldUUID] && value === "") {
                    // first, if the detail is undefined then its a new value, but if the value is blank the user probably
                    // put something in and erased it so we don't need to store it.
                    return;
                }
                if (value !== complianceReportDetails[fieldUUID]) {
                    await createComplianceReportDetail(reportUUID, fieldUUID, value);
                }

                complianceReportDetails[fieldUUID] = value;
            })
        );
        this.setState({ complianceReportDetails, pendingComplianceReportDetails: {} });
    };

    goBack = () => {
        this.props.history.goBack();
    };

    // This populates the breadcrumbs from the available fieldsets stored in state.
    defineBreadCrumbs = () => {
        const { progress, overallProgress, activeFieldset } = this.state;

        return this.state.fieldsets.map((fieldset, idx) => {
            if (fieldset.active) {
                return (
                    <div
                        key={fieldset + idx}
                        onClick={overallProgress >= idx ? () => this.setActiveFieldset(idx) : null}
                        className={
                            overallProgress >= idx
                                ? "breadCrumbs__container breadCrumbs__active"
                                : "breadCrumbs__container"
                        }
                    >
                        <span
                            className={
                                activeFieldset === idx
                                    ? "breadCrumbs__breadCrumb breadCrumbs__breadCrumb-active"
                                    : "breadCrumbs__breadCrumb"
                            }
                        >
                            {fieldset.name}
                        </span>
                    </div>
                );
            }
        });
    };

    render() {
        const { reportPhase, locationInfo } = this.state;

        let header = "";
        if (locationInfo && locationInfo.name) {
            header = "(" + locationInfo.name + ")";
        }

        return (
            <div className="componentContainer" id="testReport">
                <div className="componentHeader" id="componentHeader">
                    <div className="flexAlignCenter">
                        <span>
                            <BallotIcon fontSize="inherit" />
                        </span>
                        <h3>
                            <NavLink to="/surveys">Surveys</NavLink> / New Survey {header}
                        </h3>
                    </div>
                </div>
                <div className="testReportContainer">
                    {this.state.user !== null && reportPhase === "locationAssemblyInfo" && (
                        <SurveyLocationInfo
                            user={this.state.user}
                            sendDataToReport={this.setLocationInfo}
                            surveyDoneByProvider={this.state.surveyDoneByProvider}
                        />
                    )}
                    {this.state.user !== null && reportPhase === "serviceProviderInfo" && (
                        <SurveyorInfo
                            user={this.state.user}
                            sendDataToReport={this.setSurveyorInfo}
                            surveyDoneByProvider={this.state.surveyDoneByProvider}
                        />
                    )}
                    {this.state.user !== null && reportPhase === "form" && (
                        <React.Fragment>
                            {this.defineFieldsets()}
                            <div className="breadCrumbs">{this.defineBreadCrumbs()}</div>
                        </React.Fragment>
                    )}
                </div>
                <div className="dashboardIconLarge dashboardIconLarge__muiIcon">
                    <BallotIcon fontSize="inherit" />
                </div>
            </div>
        );
    }
}

export default Survey;
