import React from 'react';

export const Washington = () => {
    let tables = [
        {
            key: '3B',
            tableTitle: 'Part 3B: Cross-Connection Control for High-Hazard Premises or Systems Served by the PWS',
            rows: [
                {
                    key: 'agricultural',
                    label: 'Agricultural (farms and dairies)'
                },
                {
                    key: 'bottling',
                    label: 'Beverage bottling plants (including breweries)'
                },
                {
                    key: 'car wash',
                    label: 'Car Washes'
                },
                {
                    key: 'chemical plant',
                    label: 'Chemical plants'
                },
                {
                    key: 'laundry',
                    label: 'Commercial laundries and dry cleaners'
                },
                {
                    key: 'reclaimed',
                    label: 'Both reclaimed water and potable water provided'
                },
                {
                    key: 'film',
                    label: 'Film processing facilities'
                },
                {
                    key: 'fire',
                    label: 'Dedicated fire protection systems with chemical addition or using unapproved auxiliary supplies'
                },
                {
                    key: 'food processing',
                    label: 'Food processing plants (including canneries, slaughter houses, rendering plants)'
                },
                {
                    key: 'hospitals',
                    label: 'Hospitals, medical centers, nursing homes, veterinary, medical and dental clinics, blood plasma centers and mortuaries'
                },
                {
                    key: 'irrigation',
                    label: 'Dedicated irrigation systems using purveyor\'s water supply and with chemical addition'
                },
                {
                    key: 'laboratory',
                    label: 'Laboratories'
                },
                {
                    key: 'metal plating',
                    label: 'Metal plating industries'
                },
                {
                    key: 'petroleum',
                    label: 'Petroleum processing or storage plants'
                },
                {
                    key: 'pier',
                    label: 'Piers and docks'
                },
                {
                    key: 'radioactive',
                    label: 'Radioactive material processing plants or nuclear reactors'
                },
                {
                    key: 'access denied',
                    label: 'Survey access denied or restricted'
                },
                {
                    key: 'wastewater station',
                    label: 'Wastewater lift/pump stations (non-residential only)'
                },
                {
                    key: 'wastewater treatment',
                    label: 'Wastewater treatment plants'
                },
                {
                    key: 'aux water supply',
                    label: 'Unapproved auxiliary water supply interconnected with potable water supply'
                },
                {
                    key: 'other',
                    label: 'Other high-hazard premises'
                }
            ],
            columns: [
                {
                    key: 'served',
                    label: 'A. Being Served Water by PWS'
                },
                {
                    key: 'protected',
                    label: 'B. With Premises Isolation by AG or RP'
                },
                {
                    key: 'tested',
                    label: 'C. With Column B AG Inspected or RP Tested'
                },
                {
                    key: 'excepted',
                    label: 'D. Granted Exception from Mandatory Premises Isolation'
                }
            ]
        },
        {
            key: '3C',
            tableTitle: 'Part 3C: Cross-Connection Control for Medical Premises Served by the PWS',
            rows: [
                {
                    key: 'hospital',
                    label: 'Hospitals (include psychiatric hospitals and alcohol and drug treatment centers'
                },
                {
                    key: 'surgery',
                    label: 'Same day surgery centers'
                },
                {
                    key: 'out-patient',
                    label: 'Out-patient clinics and offices'
                },
                {
                    key: 'alternative',
                    label: 'Alternative health out-patient clinics and offices'
                },
                {
                    key: 'psychiatric',
                    label: 'Psychiatric out-patient clinics and offices'
                },
                {
                    key: 'chiropractor',
                    label: 'Chiropractors'
                },
                {
                    key: 'hospice',
                    label: 'Hospice care centers'
                },
                {
                    key: 'childbirth',
                    label: 'Childbirth centers'
                },
                {
                    key: 'kidney',
                    label: 'Kidney dialysis centers'
                },
                {
                    key: 'blood',
                    label: 'Blood centers'
                },
                {
                    key: 'dental',
                    label: 'Dental clinics and offices'
                },
                {
                    key: 'nursing home',
                    label: 'Nursing homes'
                },
                {
                    key: 'boarding',
                    label: 'Boarding homes'
                },
                {
                    key: 'residential treatment',
                    label: 'Residential treatment centers'
                },
                {
                    key: 'mortuary',
                    label: 'Mortuaries'
                },
                {
                    key: 'morgue',
                    label: 'Morgues and autopsy facilities'
                },
                {
                    key: 'veterinarian',
                    label: 'Veterinarian offices, clinics, and hospitals'
                },
                {
                    key: 'other medical',
                    label: 'All other'
                },
            ],
            columns: [
                {
                    key: 'served',
                    label: 'A. Being Served Water by PWS'
                },
                {
                    key: 'protected',
                    label: 'B. With Premises Isolation by AG or RP'
                },
                {
                    key: 'tested',
                    label: 'C. With Column B AG Inspected or RP Tested'
                },
                {
                    key: 'excepted',
                    label: 'D. Granted Exception from Mandatory Premises Isolation'
                }
            ]
        },
        {
            key: '4A',
            tableTitle: 'Part 4A: Backflow Preventer Inventory and Testing Data',
            rows: [
                {
                    key: 'premises_isolation-existing',
                    label: '1. In service at beginning of year'
                },
                {
                    key: 'premises_isolation-existing-tested',
                    label: '2. Inspected and/or tested'
                },
                {
                    key: 'premises_isolation-existing-failed',
                    label: '3. Failed inspection or test this year'
                },
                {
                    key: 'premises_isolation-new',
                    label: '4. New preventers installed'
                },
                {
                    key: 'premises_isolation-new-tested',
                    label: '5. Inspected and/or tested'
                },
                {
                    key: 'premises_isolation-new-failed',
                    label: '6. Failed inspection or test this year'
                },
                {
                    key: 'premises_isolation-deactivated',
                    label: '7. Preventers taken out of service this year'
                },
                {
                    key: 'premises_isolation-total',
                    label: 'Premises Isolation Total at end of year'
                },
                {
                    key: 'in-premise protection-existing',
                    label: '8. In service at beginning of year'
                },
                {
                    key: 'in-premise protection-existing-tested',
                    label: '9. Inspected and/or tested'
                },
                {
                    key: 'in-premise protection-existing-failed',
                    label: '10. Failed inspection or test this year'
                },
                {
                    key: 'in-premise protection-new',
                    label: '11. New preventers installed'
                },
                {
                    key: 'in-premise protection-new-tested',
                    label: '12. Inspected and/or tested'
                },
                {
                    key: 'in-premise protection-new-failed',
                    label: '13. Failed inspection or test this year'
                },
                {
                    key: 'in-premise protection-deactivated',
                    label: '14. Preventers taken out of service this year'
                },
                {
                    key: 'in-premise protection-total',
                    label: 'In-Premises Protection Total at end of year'
                },
                {
                    key: 'grand-total',
                    label: 'Grand total at end of reporting year'
                }
            ],
            columns: [
                {
                    key: 'AirGap',
                    label: 'Air Gap'
                },
                {
                    key: 'RPBA',
                    label: 'RPBA'
                },
                {
                    key: 'RPDA',
                    label: 'RPDA'
                },
                {
                    key: 'DCVA',
                    label: 'DCVA'
                },
                {
                    key: 'DCDA',
                    label: 'DCDA'
                },
                {
                    key: 'PVBA',
                    label: 'PVBA'
                },
                {
                    key: 'SVBA',
                    label: 'SVBA'
                },
                {
                    key: 'AVB',
                    label: 'AVB'
                },
            ]
        }
    ];

    return tables;
}

export const WashingtonGreenForm = (year, data) => {
    let layout = [];
    // We may have 0 - N green forms from the backend, the keys will be like greenX starting at 1, with possible
    // multiple connections in each green form, so how we need to break this down.
    //
    // greenX - starts a new green form / table
    // greenX`part2 - the location info, which we will put at the top
    // greenX`connectionY - start of new connection / assembly info
    // greenX`connectionY`assembly`KEY - make, model, etc will be in place of KEY
    // greenX`connectionY`part3`KEY - various dates and exception reason in place of KEY
    //
    // so with that, we print the location info once, then 2 rows per assembly, make / model in first, and exception
    // info in second

    let greenCount = 1;
    let greenKey = "green" + greenCount;
    while (greenKey + "`part2`name" in data.results) {
        let connCount = 1;
        let connKey = "connection" + connCount
        let locationInfo = <thead>
            <tr>
                <th>Green Form For:</th>
                <th colSpan="2">{data.results[greenKey + "`part2`name"]}</th>
                <th colSpan="2">{data.results[greenKey + "`part2`address"]}</th>
                <th colSpan="2">{data.results[greenKey + "`part2`type"]}</th>
            </tr>
            <tr>
                <th>Assembly Make / Serial #</th>
                <th>Evaluation</th>
                <th>Granted or Renewed</th>
                <th>Expiration</th>
                <th>Next Evaluation</th>
                <th>Reason(s)</th>
            </tr>
        </thead>;
        let connections = [];
        while (greenKey + "`" + connKey + "`assembly`make" in data.results) {
            let connInfo = <React.Fragment key={greenKey + "`" + connKey}>
                <tbody>
                    <tr>
                        <td>{data.results[greenKey + "`" + connKey + "`assembly`make"]} {data.results[greenKey + "`" + connKey + "`assembly`serial_number"]}</td>
                        <td>{data.results[greenKey + "`" + connKey + "`part3`eval"]}</td>
                        <td>{data.results[greenKey + "`" + connKey + "`part3`granted"]}</td>
                        <td>{data.results[greenKey + "`" + connKey + "`part3`expires"]}</td>
                        <td>{data.results[greenKey + "`" + connKey + "`part3`nexteval"]}</td>
                        <td>{data.results[greenKey + "`" + connKey + "`part4`reasons"]}</td>
                    </tr>
                </tbody>
            </React.Fragment>;
            connections.push(connInfo);
            connCount++;
            connKey = "connection" + connCount
        }
        layout.push(<table key={greenKey}>
            {locationInfo}
            {connections}
        </table>);
        greenCount++;
        greenKey = "green" + greenCount;
    }

    // If we had any green forms to add, put a title ahead of them.
    if (greenCount > 1) {
        layout.unshift(<span key="exceptions">
            Exceptions to High-Health Hazard Premises Isolation Requirements
        </span>);
    }

    return layout;
}
