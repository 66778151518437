import React from 'react';
import Page from "../Page";

const Dashboard = () => {
    return (
        <Page>
            Dashboard
        </Page>
    );
}

export default Dashboard;
