import React from "react";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import styles from "../../../../styles/common/matieralUI/autoComplete.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";

const SelectField = ({ label, options, value, setValue, error, disabled }) => {
    // Find the full option object that matches the current value
    const selectedOption = options.find((o) => value === o.value) || null;

    return (
        <FormControl error={!!error}>
            <Autocomplete
                classes={styles}
                options={options}
                value={selectedOption} // Pass the full option object instead of just the value
                onChange={(_, newValue) => setValue(newValue?.value ?? null)}
                renderInput={(params) => <TextField {...params} error={!!error} label={label} variant="outlined" />}
                getOptionLabel={(option) => option?.label ?? ""}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                disabled={disabled}
                disableClearable
            />
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default SelectField;
