import React from 'react';
import FormField from './Forms/FormField';


class AdditionalDetails extends React.Component {
	constructor(props) {
		super(props);
	}

	createDetails = () => {
		let details = [];
		if (Object.keys(this.props.details).length > 0) {
			for (let uuid in this.props.details) {
				let field = this.props.details[uuid];
				let detail = (
					<div key={field.form_field_uuid} className={field.input_type === 'checkbox' ? 'flex-checkbox' : null}>
						<FormField details={field} />
					</div>
				);
				details.push(detail);
			}
		}
		return details;
	}

	render() {
		if (Object.keys(this.props.details).length === 0) {
			return null;
		}

		return (
			<React.Fragment>
				<hr />
				<fieldset id="additional-details" disabled={!this.props.editMode}>
					{this.createDetails()}
				</fieldset>
			</React.Fragment>
		);
	}
}

export default AdditionalDetails;