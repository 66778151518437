import React from "react";

import ExportOptions from "./ExportOptions";
import ReportTablePagination from "./ReportTablePagination";
import SearchBar from "../SearchBar";
import TableInputsFilter from "../TableInputsFilter";
import TableOptions from "../TableOptions";
import Table from "../Table";
import {
    parseReportingParams,
    setReportingParams,
    convertReportingDefaults,
    reportRowToObject,
    updateURLWithReportingParams,
} from "../../utils/reporting";
import { getReportSync, downloadReport, getReportInfo } from "../../api/reportingAPI";
import { setUserPreference } from "../../api/usersAPI";

import { ClickAwayListener } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import dropdown from "../../../images/dropdown.svg";
import { getForm, getFormFields } from "../../api/formsAPI";
import LoaderOverlay from "../LoaderOverlay";
import { CircularProgress } from "@mui/material";

class ReportTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: props.page,
            reports: props.reports,
            activeReport: null,
            reportListEnabled: false,
            reportListDropdownVisible: false,
            reportKey: null,
            reportOutput: null,
            reportRequestID: null,
            reportingParams: {},
            tableType: props.tableType,
            flyoutActive: props.flyoutActive,
            openFlyout: props.openFlyout,
            clickable: props.clickable !== undefined ? props.clickable : true,
            additionalDetails: null,
            isLoading: false,
            isDownloading: false,
        };

        // turn on our report list dropdown if we have more than 1 report
        if (props.reports.length > 1) {
            this.state.reportListEnabled = true;
        }

        // we aren't going to assume we want to load the first report right away, but if we pass in a default idx, we
        // will load the report at that index in the reports array
        if (props.defaultReportIdx !== undefined) {
            this.state.activeReport = props.reports[props.defaultReportIdx];
        }

        let options = {};

        if (SwiftComply.user.preferences["reporting options"]) {
            options = JSON.parse(SwiftComply.user.preferences["reporting options"]);
        }
        if (options !== null && options[props.page] !== undefined) {
            for (const [name, opts] of Object.entries(options[props.page])) {
                this.state.reports.forEach((report, idx) => {
                    if (report.name === name) {
                        this.state.reports[idx].columns = opts.columns;
                    }
                });
            }
        }

        this.state.reportingParams = parseReportingParams();
    }

    componentDidMount() {
        if (this.state.activeReport !== null) {
            // we are going to get the report info, which basically returns everything about a report except the actual
            // data since it doesn't run it.  Then get the additional details which calls other things which then eventually
            // calls updateReport but without the initial flag since at that point it doesn't need it.  Its very confusing
            // and I think the reporting page may still run a report twice
            getReportInfo(this.state.activeReport.reportUUID).then((info) => {
                this.setState(
                    () => ({
                        reportOutput: info,
                    }),
                    () => {
                        this.getAdditionalDetailsInfo("initial");
                    }
                );
            });
        }
    }

    // actually call our get report to run the report on the backend and set the output
    updateReport = (newReportingParams, type, forcePageOne) => {
        let reportingParams = newReportingParams !== undefined ? newReportingParams : this.state.reportingParams;
        if (forcePageOne) {
            reportingParams.page = 1;
            reportingParams.totalRowCount = undefined; //force refresh of totalRowCount
        }

        let handleReportResponse = (response) => {
            reportingParams.nextPage = response.nextPage;
            reportingParams.totalRowCount = response.totalRowCount;
            this.setState(
                () => ({
                    reportOutput: response.data,
                    reportRequestID: response.requestID,
                    reportingParams,
                    reportKey: response.data.report_uuid + this.state.activeReport.name,
                    isLoading: false,
                }),
                () => {
                    if (type === "initial") {
                        this.getAdditionalDetailsInfo("initial");
                    } else {
                        this.getAdditionalDetailsInfo();
                    }
                    if (this.props.sendReportResponse) {
                        this.props.sendReportResponse(response);
                    }
                }
            );
        };

        if (this.state.activeReport.reportFunction !== undefined) {
            this.state.activeReport.reportFunction(reportingParams).then(handleReportResponse);
        } else {
            getReportSync(this.state.activeReport.reportUUID, reportingParams).then(handleReportResponse);
        }
    };

    handleDownload = (exportType) => {
        this.setState({ isDownloading: true });
        const exportAll = exportType == "all" || exportType == "std+vis";
        let { reportingParams } = this.state;

        let visibleColumns = [];
        if (exportType == "all") {
            // modify reportParams so that the report will return data from all the details columns
            const detailNames = Object.keys(this.state.additionalDetails);

            let include_columns = {};
            detailNames.forEach((name) => {
                include_columns[name] = {
                    details: Object.keys(this.state.additionalDetails[name]),
                };
            });
            reportingParams["include_columns"] = include_columns;
        } else if (exportType == "vis") {
            // When a user loads a table for the very first time, this.state.activeReport.columns is the empty array, and only
            // the columns whose names would have not been filtered out by the displayOptions() method of the TableOptions
            // component are displayed. In the empty array case we are copying the same filter logic from the previously
            // mentioned method.
            if (this.state.activeReport.columns.length === 0) {
                let detailsList = [];
                if (Object.keys(this.state.additionalDetails) !== 0) {
                    for (const type in this.state.additionalDetails) {
                        for (const detail in this.state.additionalDetails[type]) {
                            detailsList.push(this.state.additionalDetails[type][detail].label);
                        }
                    }
                }

                this.state.reportOutput.outputs.forEach((output) => {
                    if (
                        !output.name.includes("UUID") &&
                        output.name !== "Actions" &&
                        !detailsList.includes(output.name)
                    ) {
                        visibleColumns.push(output.name);
                    }
                });
            } else {
                visibleColumns = this.state.activeReport.columns.filter(
                    (name) => !name.includes("UUID") && name !== "Actions"
                );
            }
        }

        // if exportAll is true, then the backend ignores the value of visibleColumns and will return data from all the
        // columns in the report output
        downloadReport(
            this.state.activeReport.reportUUID,
            {
                columns: visibleColumns,
                report_request: reportingParams,
                export_all: exportAll,
            },
            this.state.activeReport.name
        ).finally(() => {
            this.setState({ isDownloading: false });
        });
    };

    getAdditionalDetailsInfo = async (type) => {
        const compatibleDetails = this.state.reportOutput.details;
        let detailTypes = [];
        let additionalDetails = {};
        let newOutputs = [];

        compatibleDetails.forEach((type) => {
            switch (type) {
                case "property":
                    detailTypes.push({ formType: "Property Details", type: type });
                    break;
                case "contact":
                    detailTypes.push({ formType: "Contact Details", type: type });
                    break;
                case "service_provider":
                    detailTypes.push({
                        formType: "Service Provider Details",
                        type: type,
                    });
                    break;
                case "equipment":
                    detailTypes.push({ formType: "Equipment Details", type: type });
                    break;
                case "compliance_report":
                    detailTypes.push({
                        formType: "Compliance Report Details",
                        type: type,
                    });
                    break;
                default:
                    null;
            }
        });

        for (const detailType of detailTypes) {
            let additionalFields = {};
            let outputs = [];
            let fields = [];
            if (type === "initial") {
                let form = await getForm(detailType.formType);
                if (form != null) {
                    const fieldsResponse = await getFormFields(form.form_uuid);
                    fields = fieldsResponse.form_fields;
                }
            } else {
                
                fields = this.state.additionalDetails && detailType.type in this.state.additionalDetails 
                    ? Object.keys(this.state.additionalDetails[detailType.type]).map(
                        (i) => this.state.additionalDetails[detailType.type][i]
                        )
                    : []
                ;
            }
            fields.forEach((field) => {
                additionalFields[field.form_field_uuid] = field;
                outputs.push({
                    name: field.label,
                    key: field.form_field_uuid,
                    type: field.data_type,
                });
            });
            additionalDetails[detailType.type] = additionalFields;
            newOutputs = [...newOutputs, ...outputs];
        }

        const reportOutputs = [...this.state.reportOutput.outputs];

        // We are adding the new additional Detail outputs to the actual report output in order for the options to be available in the dropdown. When we
        // include the new columns in the reportingParams and update the table, these outputs will then be duplicated in the report. This makes sure to filter
        // those duplicates and replace our new output with the "official" output from the backend.
        const filteredNewOutputs = newOutputs.filter(({ name: a }) => !reportOutputs.some(({ name: b }) => a === b));

        this.setState(
            () => ({
                reportOutput: {
                    ...this.state.reportOutput,
                    outputs: [...reportOutputs, ...filteredNewOutputs],
                },
                additionalDetails,
            }),
            () => {
                // We are calling setTableOptions here with the columns saved in user preferences (as opposed to adding the include_columns to the URL)
                // so they will be loaded into the table on mount. This only needs to happen once.
                if (type === "initial") {
                    this.setTableOptions(this.state.activeReport, true);
                }
            }
        );
    };

    // ---------------------------------------------
    // columns (options), search, filters, ordering, etc.
    // ---------------------------------------------
    setTableOptions = (newOptions, initial) => {
        // newOptions will be something like:
        //      columns: []
        let options = {};
        if (SwiftComply.user.preferences["reporting options"]) {
            options = JSON.parse(SwiftComply.user.preferences["reporting options"]);
        }

        // safety check, if we are setting options it shouldn't be possible for this to be null
        if (this.state.activeReport !== null) {
            if (options[this.props.page] === undefined) {
                options[this.props.page] = {};
            }

            if (!newOptions.name) {
                newOptions.name = this.state.activeReport.name;
                newOptions.reportUUID = this.state.activeReport.reportUUID;
            }

            options[this.props.page][this.state.activeReport.name] = newOptions;

            let reports = this.state.reports;
            reports.forEach((report, idx) => {
                if (report.name === this.state.activeReport.name) {
                    reports[idx].columns = newOptions.columns;
                }
            });

            this.setState({ reports });
            if (!initial) {
                setUserPreference("reporting options", JSON.stringify(options));
            }

            // Here we are setting additional details options in correct format for reportingParams (ex. include_columns: {equipment: {details: [uuid]})

            let details = { ...this.state.additionalDetails };
            let include_columns = {};

            for (const type in details) {
                let fields = [];
                for (const detail in details[type]) {
                    if (newOptions.columns.includes(details[type][detail].label)) {
                        fields = [...fields, details[type][detail].form_field_uuid];
                        include_columns[type] = {
                            ...include_columns[type],
                            details: fields,
                        };
                    }
                }
            }

            let newReportingParams = setReportingParams(
                this.state.reportingParams,
                { include_columns },
                true,
                convertReportingDefaults(this.state.reportOutput.inputs)
            );
            this.updateReport(newReportingParams);
        }
    };

    setTableFilters = (filters) => {
        let newReportingParams = setReportingParams(
            this.state.reportingParams,
            { filters },
            true,
            convertReportingDefaults(this.state.reportOutput.inputs)
        );
        this.updateReport(newReportingParams);
    };

    setOrdering = (column) => {
        let newReportingParams = setReportingParams(
            this.state.reportingParams,
            { column },
            true,
            convertReportingDefaults(this.state.reportOutput.inputs)
        );
        this.updateReport(newReportingParams);
    };

    fetchData = (direction) => {
        let newReportingParams = setReportingParams(
            this.state.reportingParams,
            { direction },
            true,
            convertReportingDefaults(this.state.reportOutput.inputs)
        );
        if (newReportingParams !== undefined) {
            this.setState({ isLoading: true }, () => {
                this.updateReport(newReportingParams);
            });
        }
    };

    fetchDataV2 = (page, count) => {
        let newReportingParams = setReportingParams(
            { ...this.state.reportingParams },
            { page, count },
            true,
            convertReportingDefaults(this.state.reportOutput.inputs)
        );
        if (newReportingParams !== undefined) {
            this.setState({ isLoading: true }, () => {
                this.updateReport(newReportingParams);
            });
        }
    };
    // ---------------------------------------------
    // report dropdown list things
    // ---------------------------------------------
    getReportList = () => {
        let options = [];
        this.state.reports.forEach((report, idx) => {
            options.push(
                <span key={idx} onClick={() => this.setReport(idx)}>
                    {report.name}
                </span>
            );
        });
        return options;
    };

    toggleReportListDropdown = (action) => {
        let reportListDropdownVisible = action === "close" ? false : !this.state.reportListDropdownVisible;
        this.setState({ reportListDropdownVisible });
    };

    setReport = (idx) => {
        this.setState({ activeReport: this.state.reports[idx] }, () => {
            let newState = {};
            if (this.props.callback) {
                newState = this.props.callback("report selected", this.state.activeReport);
            }
            if (newState === undefined || newState === null || typeof newState !== "object") {
                newState = {};
            }
            if (this.props.sendReportType) {
                this.props.sendReportType(this.state.activeReport);
            }
            this.setState({ ...newState }, () => {
                this.updateReport(undefined, "initial", true);
            });
        });
    };
    // ---------------------------------------------

    // openFlyout wrapper, this will take the row we click on, and convert the fields to their lowersnakecase
    // equivalent ex: User UUID to user_uuid
    openFlyout = (row) => {
        if (this.state.openFlyout !== null) {
            let object = reportRowToObject(row.values, this.state.reportOutput.outputs);
            if (object != null) {
                this.state.openFlyout(object);
            }
        }
    };

    // ParentEventHandler is probably frowned upon in react land but eff it.  A parent should make a reference to this
    // component when it loads it, then the parent should only ever use that ref to call this handler with an "event"
    // that it want the child to be aware of, because presumably it needs to do something
    ParentEventHandler = (event, options) => {
        if (event === "flyout closed") {
            updateURLWithReportingParams(
                this.state.reportingParams,
                options.path,
                convertReportingDefaults(this.state.reportOutput.inputs)
            );
        } else if (event === "needs update") {
            this.updateReport();
        }
    };

    // Helper function to share reportingParams with child component
    GetReportingParams = () => this.state.reportingParams;

    render() {
        return (
            <React.Fragment>
                <div className="tableButtonBankContainer">
                    <div className="flexAlignCenter">
                        {this.state.reportListEnabled && (
                            <ClickAwayListener onClickAway={() => this.toggleReportListDropdown("close")}>
                                <div
                                    className={this.state.reportListDropdownVisible ? "dropdown slideDown" : "dropdown"}
                                    onClick={() => this.toggleReportListDropdown()}
                                    role=""
                                >
                                    <button
                                        className={
                                            this.state.reportListDropdownVisible
                                                ? "longButtonSecondary openBorderRadius"
                                                : "longButtonSecondary"
                                        }
                                        data-testid="Report Table Dropdown"
                                    >
                                        <span>
                                            {this.state.activeReport !== null
                                                ? this.state.activeReport.name
                                                : "Select a Report"}
                                        </span>
                                        <img src={dropdown} alt="dropdown" />
                                    </button>
                                    {this.state.reportListDropdownVisible && (
                                        <div className="dropdown__content" id="dropdown">
                                            {this.getReportList()}
                                        </div>
                                    )}
                                </div>
                            </ClickAwayListener>
                        )}
                        {this.state.reportOutput !== null && this.state.activeReport.type !== "custom" && (
                            <React.Fragment>
                                <SearchBar
                                    key={"searchbar-" + this.state.reportKey}
                                    data={this.state.reportOutput}
                                    params={this.state.reportingParams}
                                    setTableFilters={this.setTableFilters}
                                />
                                <TableInputsFilter
                                    key={"filters-" + this.state.reportKey}
                                    data={this.state.reportOutput}
                                    params={this.state.reportingParams}
                                    setTableFilters={this.setTableFilters}
                                    removeInputs={this.props.removeInputs}
                                    additionalDetails={this.state.additionalDetails}
                                />
                                <TableOptions
                                    key={"options-" + this.state.reportKey}
                                    outputs={this.state.reportOutput.outputs}
                                    defaultColumns={this.state.activeReport.columns}
                                    setTableOptions={this.setTableOptions}
                                    additionalDetails={this.state.additionalDetails}
                                />
                                <ExportOptions
                                    disabled={this.state.isDownloading}
                                    handleDownload={this.handleDownload}
                                    isDownloading={this.state.isDownloading}
                                    details={
                                        this.state.additionalDetails != null
                                            ? Object.keys(this.state.additionalDetails).length != 0
                                            : false
                                    }
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
                {
                    // only output the table is rows isn't null / undefined, I think?
                    this.state.reportOutput &&
                    this.state.reportOutput.rows &&
                    this.state.activeReport.type !== "custom" ? (
                        <div className="mainTableContainer">
                            <Table
                                flyoutActive={this.props.flyoutActive}
                                openFlyout={this.openFlyout}
                                tableType={this.state.tableType}
                                tableData={this.state.reportOutput}
                                fetchData={this.fetchData}
                                paginationParams={this.state.reportingParams}
                                columnsToShow={this.state.activeReport.columns}
                                setOrdering={this.setOrdering}
                                clickable={this.state.clickable}
                                requestID={this.state.reportRequestID}
                                suppressPagination={true}
                            />
                            <ReportTablePagination
                                fetchData={this.fetchDataV2}
                                getReportingParams={this.GetReportingParams}
                                totalRowCount={this.state.reportingParams?.totalRowCount}
                                pageRowCount={this.state.reportingParams?.count}
                                page={this.state.reportingParams?.page}
                                nextPage={this.state.reportingParams?.nextPage}
                                reportUUID={this.state.activeReport.reportUUID}
                                filterJSON={JSON.stringify(this.state.reportingParams?.inputs)}
                                searchJSON={JSON.stringify(this.state.reportingParams?.search)}
                                isLoading={this.state.isLoading}
                            />
                            {this.state.reportRequestID && (
                                <span style={{ fontSize: "30%", color: "lightgray" }}>
                                    report run id: {this.state.reportRequestID}
                                </span>
                            )}
                        </div>
                    ) : (
                        this.state.activeReport && <LoaderOverlay />
                    )
                }
            </React.Fragment>
        );
    }
}

export default ReportTable;
