import React from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { updateSampleReportConfig, deleteSampleReportConfig } from "../../../api/analytesAPI";
import { BasicSampleReportConfigFields } from "./BasicSampleReportConfigFields";
import useGeneralControls from "../GeneralControls/useGeneralControls";
import HomeForm from "../../Common/Home/HomeForm";
import LoadingSpinner from "../../Common/LoadingSpinner";

const EditSampleReportConfig = ({ preloadedBasicState, onEditComplete, onEditCancelled, history }) => {
    const { id: sampleReportConfigId } = getURLParentObject("src") || {};
    const { id: outfallId, path: outfallPath } = getURLParentObject("o") || {};
    const { generalControls, loading: generalControlLoading } = useGeneralControls();

    const onSubmit = (basic) =>
        updateSampleReportConfig(sampleReportConfigId, {
            ...basic,
            reference_point_uuid: outfallId,
        }).then(onEditComplete);

    const onDelete = () => deleteSampleReportConfig(sampleReportConfigId).then(() => history.push(outfallPath));

    if (generalControlLoading) {
        return <LoadingSpinner />;
    }

    const basicFields = BasicSampleReportConfigFields.map((f) =>
        f.id === "general_control_uuid" ? { ...f, options: generalControls } : f
    );

    const initialBasicState = {
        ...preloadedBasicState,
        general_control_uuid: preloadedBasicState.general_control_uuid || "",
        control_type: preloadedBasicState.general_control_uuid ? "general_control" : "specific_control",
    };

    return (
        <HomeForm
            basicFields={basicFields}
            onSubmit={onSubmit}
            onCancel={onEditCancelled}
            onDelete={onDelete}
            preloadedBasicState={initialBasicState}
        />
    );
};

export default withRouter(EditSampleReportConfig);
