/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import { withFormik, Form, Field } from 'formik';
import MessageBox from './MessageBox';
import { requestPasswordReset, resetPassword } from '../api/authAPI';
import LoadingSpinner from './LoadingSpinner';

const FormLayout = ({
	errors,
	status,
	touched,
	isSubmitting,
	setFieldValue,
	messageOptions,
	passwordResetUUID
}) => {
	const getError = (fieldname) => {
		if (touched[fieldname] && errors[fieldname]) {
			return errors[fieldname];
		}
	}

	const handleFormChange = (e) => {
		if (errors && errors[e.target.name]) {
			delete errors[e.target.name];
		}
	}

	const getFormErrors = () => {
		// status will be set if there is something the user can't do to correct the issue
		if (status && status.reset_error !== "") {
			return {
				type: "warning",
				message: status.reset_error
			};
		}

		if (errors.info !== undefined) {
			return {
				type: "info",
				message: errors.info
			};
		}

		let errorMessage = "";
		messageOptions = {};
		['email', 'new_password'].forEach((field) => {
			let fieldError = getError(field);
			if (fieldError !== undefined) {
				errorMessage = fieldError;
			}
		});

		if (errorMessage !== "") {
			messageOptions = {
				type: "error",
				message: errorMessage
			};
		}

		return messageOptions;
	}

	if (isSubmitting) {
		return <LoadingSpinner />;
	} else {
		return (
			<React.Fragment>
				<MessageBox options={getFormErrors()} />
				<Form className="loginForm">
					{passwordResetUUID === undefined && (
						<React.Fragment>
							<label htmlFor="email">Email</label>
							<div className="inputField">
								<Field type="email" name="email" className="inputField__input inputField__input-first"
									onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
								/>
							</div>
							<button className="mainBtn" disabled={isSubmitting} type="submit">Request Password Reset</button>
						</React.Fragment>
					)}
					{passwordResetUUID !== undefined && (
						<React.Fragment>
							<label htmlFor="new_password">Password</label>
							<div className="inputField">
								<Field id="passwordField" type="password" name="new_password"
									onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
								/>
							</div>
							<label htmlFor="new_password">Confirm Password</label>
							<div className="inputField">
								<Field id="passwordFieldConfirm" type="password" name="confirm_password"
									onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
								/>
							</div>
							<button className="mainBtn" disabled={isSubmitting} type="submit">Reset Password</button>
						</React.Fragment>
					)}
				</Form>
			</React.Fragment>
		);
	}

};

const ForgotPasswordForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,
	mapPropsToValues() {
		return {
			email: '',
			new_password: ''
		};
	},

	validate: (values, props) => {
		let errors = {};
		if (props.passwordResetUUID === undefined) {
			if (!values.email) {
				errors.email = 'Please enter your email address';
			}

		} else {
			if (!values.new_password) {
				errors.new_password = 'Please enter a new password';
			} else if (!values.confirm_password || values.new_password != values.confirm_password) {
				errors.new_password = 'Passwords do not match';
			}

		}
		return errors;
	},

	handleSubmit(values, { props, setFieldValue, setSubmitting, setErrors, setStatus }) {
		setSubmitting(true);
		try {
			if (props.passwordResetUUID === undefined) {
				requestPasswordReset(values.email)
					.then(() => {
						// janky but we will set an error on the "info" field to pop the message box for the user to let
						// them know to check their email
						setErrors({ info: "Please check your email for further instructions." });
						setFieldValue("email", "");
						setSubmitting(false);
					})
					.catch(error => {
						setErrors({ email: error.message });
						setSubmitting(false);
					});
			} else {
				resetPassword(props.passwordResetUUID, values.new_password)
					.then(() => {
						window.location.href = '/';
					})
					.catch(error => {
						let errorMessage = error.message;
						if (error.response.status === 404) {
							errorMessage = 'This password reset request is invalid, please request a new one';
						}
						setErrors({ new_password: errorMessage });
						setSubmitting(false);
					});
			}
		} catch (e) {
			setSubmitting(false);
			setStatus({ reset_error: e });
		}
	}
})(FormLayout);

export default ForgotPasswordForm;