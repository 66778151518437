import axios from 'axios';

export const getPropertiesReport = (payload) => {
	const url = '/api/v1/reporting/0eba7876-ec72-465c-919f-35138aefa6c3';

	return axios.post(url, payload)
		.then(response => {
			console.log('Got Properties Report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Properties Report!', error.response.data);
			throw error;
		});
};

export const createProperty = (payload) => {
	const url = '/api/v1/properties';

	return axios.post(url, payload)
		.then(response => {
			console.log('Created Property!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create Property!');
			throw error;
		});
};

export const getProperty = (locationUUID) => {
	const url = `/api/v1/properties/${locationUUID}`;

	return axios.get(url)
		.then(response => {
			console.log('Got Property!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Property!', error.response.data);
			throw error;
		});
};

export const editProperty = (locationUUID, payload) => {
	const url = `/api/v1/properties/${locationUUID}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Edited Property!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t edit Property!', error.response.data);
			throw error;
		});
};

export const deleteProperty = (locationUUID) => {
	const url = `/api/v1/properties/${locationUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Deleted Property!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Delete Property!', error.response.data);
			throw error;
		});
};

export const getPropertyContactList = (locationUUID) => {
	const url = `/api/v1/properties/${locationUUID}/contacts`;

	return axios.get(url)
		.then(response => {
			console.log('Got Property Contact List!', response.data);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get Property Contact List!', error.response.data);
			throw error;
		});
};

export const getPropertyContactListReport = (payload) => {
	const url = `/api/v1/reporting/a3666110-7257-4ad2-b741-ddd73ed34e99`;

	return axios.post(url, payload)
		.then(response => {
			console.log('Got Property Contact List Report!', response.data);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Property Contact List Report!', error.response.data);
			throw error;
		});
};

export const getPropertyContact = (locationUUID, contactUUID) => {
	const url = `/api/v1/properties/${locationUUID}/contacts/${contactUUID}`;

	return axios.get(url)
		.then(response => {
			console.log('Got Property Contact!', response.data);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get Property Contact!', error.response.data);
			throw error;
		});
};

export const createPropertyContact = async (locationUUID, contactUUID, payload) => {
	const url = `/api/v1/properties/${locationUUID}/contacts`;
	payload.contact_uuid = contactUUID;

	return axios.post(url, payload)
		.then(response => {
			console.log('Created Property Contact!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create Property Contact!', error.response.data);
			if (error.response.data['Error'] === 'an entry for this contact already exists') {
				return error.response.data['Error']
			} else {
				throw error;
			}
		});
};

export const updatePropertyContact = async (locationUUID, contactUUID, payload) => {
	const url = `/api/v1/properties/${locationUUID}/contacts/${contactUUID}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated Property Contact!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t update Property Contact!', error.response.data);
			throw error;
		});
};

export const removePropertyContact = (locationUUID, contactUUID) => {
	const url = `/api/v1/properties/${locationUUID}/contacts/${contactUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Removed Property Contact!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t remove Property Contact!', error.response.data);
			throw error;
		});
};

export const getPropertyDetails = (locationUUID) => {
	const url = `/api/v1/properties/${locationUUID}/details`;

	return axios.get(url)
		.then(response => {
			console.log('Got Property Details!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Property Details!', error.response.data);
			throw error;
		});
};

export const linkPropertyToModule = (locationUUID) => {
	const url = `/api/v1/properties/${locationUUID}/module`;

	return axios.put(url)
		.then(response => {
			console.log('Linked Property!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Link Property!', error.response.data);
			throw error;
		});
};

export const savePropertyDetail = async (locationUUID, payload) => {
	const url = `/api/v1/properties/${locationUUID}/details`;

	return axios.post(url, payload)
		.then(response => {
			console.log('Created Property Detail!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create Property Detail!');
			throw error;
		});
};

export const acceptProperty = (locationUUID) => {
	const url = `/api/v1/properties/${locationUUID}/accept`;

	return axios.post(url)
		.then(response => {
			console.log('Accepted Property', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Accept Property', error.response.data);
			throw error;
		});
};

export const rejectProperty = (locationUUID) => {
	const url = `/api/v1/properties/${locationUUID}/reject`;

	return axios.post(url)
		.then(response => {
			console.log('Rejected Property', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Reject Property', error.response.data);
			throw error;
		});
};

export const getPropertyCompliance = (locationUUID, type) => {
	const url = `/api/v1/properties/${locationUUID}/compliance/${type}`;

	return axios.get(url)
		.then(response => {
			console.log('Got Property Compliance', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Property Compliance', error.response.data);
			throw error;
		});
};

export const updatePropertyCompliance = async (propertyUUID, type, payload) => {
	const url = `/api/v1/properties/${propertyUUID}/compliance/${type}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated property compliance', response);
			return response.data;
		})
		.catch(error => {
			console.log('Could\'nt update property compliance', error);
			throw error;
		});
}

export const updatePropertyOverallCompliance = async (propertyUUID, payload) => {
	const url = `/api/v1/properties/${propertyUUID}/overall_compliance`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated overall property compliance', response);
			return response.data;
		})
		.catch(error => {
			console.log('Could\'nt update overall property compliance', error);
			throw error;
		});
}

// state report / reporting fields are their own calls now
export const getPropertySRFields = (propertyUUID) => {
	const url = `/api/v1/properties/${propertyUUID}/srfields`;

	return axios.get(url)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			// this property has no sr fields set, so we don't want to throw an error here, just return nothing
			if (error.response.status == 404) {
				return {};
			}
			throw error;
		});
}

export const updatePropertySRFields = (propertyUUID, srfields) => {
	const url = `/api/v1/properties/${propertyUUID}/srfields`;

	let payload = { sr_fields: JSON.stringify(srfields) };

	return axios.put(url, payload)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			throw error;
		});
}