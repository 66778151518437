import React from 'react';
import * as Moment from 'moment';

export const Massachusetts = () => {
    let tables = [
        {
            key: 'survey',
            tableTitle: 'Facilities surveyed during this reporting period',
            rows: [
                {
                    key: 'commercial',
                    label: 'Commercial'
                },
                {
                    key: 'industrial',
                    label: 'Industrial'
                },
                {
                    key: 'institutional',
                    label: 'Institutional'
                },
                {
                    key: 'municipal',
                    label: 'Municipal'
                },
                {
                    key: 'residential',
                    label: 'Residential (Optional)'
                },
                {
                    key: 'total',
                    label: 'Total'
                }
            ],
            columns: [
                {
                    key: 'served',
                    label: 'Total # of Facilities Served by PWS'
                },
                {
                    key: 'prior',
                    label: '# of Facilities Surveyed Prior to this reporting period'
                },
                {
                    key: 'first',
                    label: '# of Facilities with first time surveys during this reporting period'
                },
                {
                    key: 'remaining',
                    label: '# of Facilities Remaining to be Surveyed'
                },
                {
                    key: 'resurvey',
                    label: '# of Facilities Resurveyed in this reporting period'
                }
            ]
        },
        {
            key: 'device',
            tableTitle: 'Cross-connection(s) within your systems service area protected by RPBP, DCVA',
            rows: [
                {
                    key: 'rowTitle RPBP',
                    label: 'RPBP'
                },
                {
                    key: 'rpbp commercial',
                    label: 'Commercial'
                },
                {
                    key: 'rpbp industrial',
                    label: 'Industrial'
                },
                {
                    key: 'rpbp institutional',
                    label: 'Institutional'
                },
                {
                    key: 'rpbp municipal',
                    label: 'Municipal'
                },
                {
                    key: 'rpbp residential',
                    label: 'Residential (Optional)'
                },
                {
                    key: 'rpbp total',
                    label: 'Total'
                },
                {
                    key: 'rowTitle DCVA',
                    label: 'DCVA'
                },
                {
                    key: 'dcva commercial',
                    label: 'Commercial'
                },
                {
                    key: 'dcva industrial',
                    label: 'Industrial'
                },
                {
                    key: 'dcva institutional',
                    label: 'Institutional'
                },
                {
                    key: 'dcva municipal',
                    label: 'Municipal'
                },
                {
                    key: 'dcva residential',
                    label: 'Residential (Optional)'
                },
                {
                    key: 'dcva total',
                    label: 'Total'
                }
            ],
            columns: [
                {
                    key: 'beginning',
                    label: 'Total # of devices at the beginning of this reporting period'
                },
                {
                    key: 'installed',
                    label: '# of devices installed in this reporting period'
                },
                {
                    key: 'removed',
                    label: '# of devices removed & not replaced in this reporting period'
                },
                {
                    key: 'total',
                    label: 'Total # of devices'
                },
                {
                    key: 'seasonal',
                    label: '# of seasonal devices in Total'
                }
            ]
        },
        {
            key: 'test',
            tableTitle: 'RPBP/DCVA testing performed in this reporting period',
            rows: [
                {
                    key: 'rpbp',
                    label: 'RPBP'
                },
                {
                    key: 'dcva',
                    label: 'DCVA'
                }
            ],
            columns: [
                {
                    key: 'initial',
                    label: '# of Initial tests'
                },
                {
                    key: 'routine',
                    label: '# of Routine tests'
                },
                {
                    key: 'failure',
                    label: '# of Failures'
                },
                {
                    key: 'repair',
                    label: '# of Repairs & Re-tests'
                },
                {
                    key: 'untested',
                    label: '# Not Tested'
                }
            ]
        },
        {
            key: 'test_vb',
            tableTitle: 'PVB/SPPVB testing performed in this reporting period',
            rows: [
                {
                    key: 'pvb',
                    label: 'PVB'
                },
                {
                    key: 'sppvb',
                    label: 'SPPVB'
                }
            ],
            columns: [
                {
                    key: 'initial',
                    label: '# of Initial tests'
                },
                {
                    key: 'routine',
                    label: '# of Routine tests'
                },
                {
                    key: 'failure',
                    label: '# of Failures'
                },
                {
                    key: 'repair',
                    label: '# of Repairs & Re-tests'
                }
            ]
        }
    ];

    return tables;
};

export const MassachusettsPersonnelLists = (year, data) => {
    const layout = [];

    const personnel = new Map();
    personnel.set("Surveyor", { label: "Surveyor's", data: [] });
    personnel.set("Tester", { label: "Tester's", data: [] });

    const setPersonnel = (dataKey, sectionName) => {
        if (dataKey in data.results) {
            const personnelList = JSON.parse(data.results[dataKey]);
            const rows = [];
            personnelList.forEach(person => {
                const fieldsJSX = [];
                const fields = person.split("|");
                // Convert the expiration date epoch to human readable.
                if (fields[3] !== "") {
                    fields[3] = Moment.unix(fields[3]).format('YYYY-MM-DD');
                }
                fields.forEach((field, idx) => {
                    fieldsJSX.push(<td key={field + idx}>{field}</td>);
                });
                rows.push(<tr key={person}>{fieldsJSX}</tr>);
            });
            personnel.set(sectionName, { label: sectionName + "'s", data: rows })
        }
    }

    setPersonnel("part1`personnel`surveyors", "Surveyor");
    setPersonnel("part1`personnel`testers", "Tester");

    for (const [section, entry] of personnel.entries()) {
        const table = <React.Fragment key={section}>
            <span>{section + " Personnel Information"}</span>
            <table key={section}>
                <thead>
                    <tr>
                        <th>{entry.label} FirstName</th>
                        <th>{entry.label} LastName</th>
                        <th>MassDEP Certification ID Number</th>
                        <th>Expiration Date</th>
                        <th>Phone Number</th>
                    </tr>
                </thead>
                <tbody>
                    {entry.data.length > 0 ? entry.data : <tr><td colSpan={5}>None found</td></tr>}
                </tbody>
            </table>
        </React.Fragment>;
        layout.push(table);
    }

    return layout;
};
