import React from 'react';
import Table from '../Table';
import {
	getServiceProviderContactsReport,
	createServiceProviderContact, updateServiceProviderContact, removeServiceProviderContact
} from '../../api/serviceProvidersAPI';
import Flyout from '../Flyout';
import { getDefaultReportingParams, setReportingParams } from '../../utils/reporting';
import LoaderOverlay from '../LoaderOverlay';

class ServiceProviderContacts extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			contactsList: [],
			flyoutActive: false,
			contactInfo: {},
			newContact: false,
			reportingParams: getDefaultReportingParams(),
		};
	}

	async componentDidMount() {
		this.getContactsList();
	}

	getContactsList = (reportingParams) => {
		if (reportingParams === undefined) {
			reportingParams = this.state.reportingParams;
		}
		reportingParams.inputs = { 'Service Provider UUID': this.props.uuid }

		getServiceProviderContactsReport(reportingParams).then(response => {
			if (response.nextPage !== undefined && response.nextPage > reportingParams.page) {
				reportingParams.nextPage = response.nextPage;
			}
			this.setState(() => ({ contactsList: response.data, reportingParams }));
		});
	}

	openFlyout = (contactInfo, newContact) => {
		const overlay = document.getElementById('overlaySecondary');

		if (SwiftComply.user.user_type === 'service provider') {
			return null;
		}

		if (newContact) {
			this.setState(() => ({ newContact: true }), () => {
				this.setState(() => ({ contactInfo: {} }));
			});
		} else {
			if (contactInfo.values) {
				contactInfo = {
					contact_uuid: contactInfo.values[0]
				};
			}
			this.setState(() => ({ newContact: false }), () => {
				this.setState(() => ({ contactInfo }));
			});
		}
		this.setState(() => ({ flyoutActive: true }), () => {
			overlay.classList.add('overlaySecondary__visible');
		});
	}

	closeFlyout = () => {
		const flyoutSecondary = document.getElementById('flyoutSecondary');
		const overlay = document.getElementById('overlaySecondary');

		flyoutSecondary.classList.remove('slideToVisible');
		overlay.classList.remove('overlaySecondary__visible');
		setTimeout(() => {
			this.setState({ flyoutActive: false });
		}, 200);
	}

	handleUpdate = (contact, payload) => {
		return updateServiceProviderContact(this.props.uuid, contact.contact_uuid, payload).then(response => {
			this.props.refresh(false);
			this.getContactsList();
			return Promise.resolve(response);
		})
	}

	handleSave = (contact, payload) => {
		payload.contact_type = 'contact';
		return createServiceProviderContact(this.props.uuid, contact.contact_uuid, payload).then(response => {
			this.props.refresh(false);
			this.getContactsList();
			return Promise.resolve(response);
		});
	}

	handleUnlink = (user) => {
		if (confirm('Are you sure you want to remove this Contact?')) {
			removeServiceProviderContact(this.props.uuid, user.contact_uuid).then(() => {
				this.props.refresh(false);
				this.getContactsList();
				this.closeFlyout();
			});
		}
	}

	// -----------------------------
	// table / reporting functions
	// -----------------------------
	setOrdering = (column) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { column }, false);
		this.getContactsList(newReportingParams);
	}

	fetchData = (direction) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { direction }, false);
		if (newReportingParams !== undefined) {
			this.getContactsList(newReportingParams);
		}
	}
	// END table / reporting functions

	render() {
		const { flyoutActive, newContact, contactInfo, contactsList } = this.state;

		return (
			<div className="flyoutContentContainer">
				{contactsList.length !== 0 ?
					<React.Fragment>
						<Table
							tableType='Service Providers Contacts'
							tableData={contactsList}
							columnsToShow={["Name", "Email", "Phone"]}
							tableSize='small'
							openFlyout={this.openFlyout}
							fetchData={this.fetchData}
							setOrdering={this.setOrdering}
							paginationParams={this.state.reportingParams}
						/>
						{SwiftComply.user.user_type === 'normal' && (
							<div className="flexButtonContainer">
								<button className="medButtonPrimary" onClick={() => this.openFlyout(null, true)}>Add Contact</button>
							</div>
						)}
					</React.Fragment> : <LoaderOverlay />
				}
				<div className="overlaySecondary" id="overlaySecondary" onClick={() => { this.closeFlyout() }}></div>
				{flyoutActive && (contactInfo.contact_uuid || newContact) && (
					<React.Fragment>
						<Flyout
							uuid={contactInfo.contact_uuid}
							source='serviceProviders'
							sourceUUID={this.props.uuid}
							newAddition={newContact}

							flyoutType='contacts'
							flyoutPriority='secondary'

							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							activeTab='contactDetails'

							create={this.handleSave}
							refresh={this.getContactsList}
							update={this.handleUpdate}
							unlink={this.handleUnlink}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default ServiceProviderContacts;