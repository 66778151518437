import queryString from 'query-string';
import { updateURL } from './url';

const search_re = /search_(.+)/;
const inputs_re = /inputs_(.+)/;
const details_re = /details_(.+)/;
const details_field_re = /(contact|equipment|property|service_provider)_([\w-]+)/;
const columns_re = /include_columns_(contact|equipment|property|service_provider)_(details|state_report)/;

const defaultReportingParams = {
	page: 1,
	nextPage: 1,
	count: 10,
	direction: null,
	order: null,
	search: {},				// search_address=123 main st
	inputs: {},				// inputs_active=true
	details: {},			// details_TYPE_UUID=value
	include_columns: {} 	// include_columns_TYPE_(details|state_report)=X,Y,Z
};

export const getDefaultReportingParams = () => {
	// we need to make a full copy of the defaults, so we don't end up with a reference and overwriting defaults
	let reportingParams = JSON.parse(JSON.stringify(defaultReportingParams));
	return reportingParams;
}

export const parseReportingParams = () => {
	let reportingParams = getDefaultReportingParams();
	const qs = queryString.parse(window.location.search);

	for (let key in qs) {
		if (reportingParams[key] !== undefined) {
			if (key === 'page' || key === 'count') {
					reportingParams[key] = parseInt(qs[key], 10);
			} else {
					reportingParams[key] = qs[key];
			}
		} else {
			// search terms ex: search_name, search_firstname
			let match = key.match(search_re);
			if (match && match.length > 1) {
				reportingParams.search[match[1]] = qs[key];
			}
			// inputs ex: inputs_active, inputs_property_uuid
			match = key.match(inputs_re);
			if (match && match.length > 1) {
				reportingParams.inputs[match[1]] = qs[key];
			}
			// additional details ex: details_equipment_UUIDHERE
			match = key.match(details_re);
			if (match && match.length > 1) {
				let detmatch = match[1].match(details_field_re);
				if (detmatch && detmatch.length > 2) {
					if (!reportingParams.details[detmatch[1]]) {
						reportingParams.details[detmatch[1]] = { inputs: {} };
					}
					reportingParams.details[detmatch[1]].inputs[detmatch[2]] = qs[key];
				}
			}
			// additional columns to include in report
			match = key.match(columns_re);
			if (match && match.length > 2) {
				let objtype = match[1];
				let fieldtype = match[2];
				if (!reportingParams.include_columns[objtype]) {
					reportingParams.include_columns[objtype] = {};
				}
				reportingParams.include_columns[objtype][fieldtype] = qs[key].split(",");
			}
		}
	}

	return reportingParams;
}

export const updateURLWithReportingParams = (params, path, defaults, sendBack) => {
	let qs = clearReportingParams(queryString.parse(window.location.search));

	// make this backwards compatible, if we didn't pass it setup the basic structure
	if (defaults === undefined) {
		defaults = {
			search: {},
			inputs: {},
			details: {},
			include_columns: {}
		};
	}

	for (let key in params) {
		if (key === "totalRowCount") {
			continue
		}
		if (key !== "search" && key !== "inputs" && key !== "details" && key !== "include_columns" && params[key]) {
			qs[key] = params[key];
		} else {
			for (let term in params.search) {
				if (params.search[term]) {
					qs["search_" + term] = params.search[term];
				} else {
					delete qs["search_" + term];
				}
			}
			for (let term in params.inputs) {
				if (params.inputs[term]) {
					qs["inputs_" + term] = params.inputs[term];
				} else {
					delete qs["inputs_" + term];
				}
			}
			for (let type in params.details) {
				if (params.details[type].inputs) {
					for (let field in params.details[type].inputs) {
						if (params.details[type].inputs[field]) {
							qs["details_" + type + "_" + field] = params.details[type].inputs[field];
						} else {
							delete qs["details_" + type + "_" + field];
						}
					}
				} else {
					delete qs["details_" + type + "_" + field];
				}
			}
			// The following code is set up to add additional details and state report columns to the URL. Saving in case we want to use this later.

			// for (let type in params.include_columns) {
			// 	let detailType = Object.keys(params.include_columns[type])[0]
			// 	if (params.include_columns[type]) {
			// 		let fields = []
			// 		for (let field in params.include_columns[type][detailType]) {
			// 			if (params.include_columns[type][detailType][field]) {
			// 				fields.push(params.include_columns[type][detailType][field])
			// 				qs["include_columns_" + type + "_" + detailType] = fields.toString();
			// 			} else {
			// 				delete qs["include_columns_" + type + "_" + detailType];
			// 			}
			// 		}
			// 	} else {
			// 		delete qs["include_columns_" + type + "_" + detailType];
			// 	}
			// }
		}
	}

	delete qs.nextPage;

	// don't need to show the default values in the URL, like page=1 etc, or any input default values
	for (let key in qs) {
		let inputKey = key.replace('inputs_', '');
		if (
			qs[key] === defaultReportingParams[key] ||
			qs[key] === defaults.inputs[inputKey]
		) {
			delete qs[key];
			delete window.location.search[key];
		}
	}

	// if we didnt' get a new path in, use the current one
	if (path === undefined) {
		path = window.location.pathname;
	}

	if (sendBack) {
		return updateURL(path, qs, sendBack)
	} else {
		updateURL(path, qs);
	}
}

// Before we update a url with our params, we should reset (delete) anything that was already in the query string for
// them so anything no longer set doesn't persist
const clearReportingParams = (qs) => {
	for (let key in qs) {
		if (defaultReportingParams[key] !== undefined) {
			delete qs[key];
		} else if (key.startsWith("search_")) {
			delete qs[key];
		} else if (key.startsWith("inputs_")) {
			delete qs[key];
		} else if (key.startsWith("details_")) {
			delete qs[key];
		} else if (key.startsWith("include_columns_")) {
			delete qs[key]
		} 
	}

	return qs;
}

export const setReportingParams = (reportingParams, newParams, updateURL, defaults) => {
	// defaults will likely just be inputs for now, but I suppose at some point we might have default search, either
	// way its intent is to pass along to updateURL so we can just not put things in the url that are still the default
	// value

	// Support for ReportTablePagination component, which sets page and count explicitly
	if (newParams.page !== undefined) {
		reportingParams.page = newParams.page
	}
	if (newParams.count !== undefined) {
		reportingParams.count = newParams.count
	}
	// I don't think we need `else`s below, but unclear if this function is ever called
	// with multiple keys in newParams apart from `page` & `count above.
	if (newParams.direction !== undefined) {
		reportingParams = setReportPage(reportingParams, newParams.direction);
	}
	else if (newParams.filters !== undefined) {
		reportingParams = setReportFilters(reportingParams, newParams.filters);
	}
	else if (newParams.column !== undefined) {
		reportingParams = setReportOrdering(reportingParams, newParams.column);
	}
	else if (newParams.include_columns !== undefined) {
		reportingParams = setReportColumns(reportingParams, newParams.include_columns)
	}

	// we only want to update the url for main tables, not flyouts, modals, or anything else we might use, so if we
	// don't explicitly say don't do this, do it
	if (updateURL || updateURL === undefined) {
		updateURLWithReportingParams(reportingParams, undefined, defaults);
	}
	return reportingParams;
}

// components can call this from their "fetchData" function to really just get the right next or prev page set in their
// reporting params.
// reportingParams - current reporting parameters
// direction       - next / prev, the Table component will call your fetchData function with this value, so pass it along
const setReportPage = (reportingParams, direction) => {
	let currentPage = reportingParams.page;
	if (direction === 'next') {
		if (reportingParams.nextPage > currentPage) {
			reportingParams.page++;
		}
	} else {
		if (reportingParams.page > 1) {
			reportingParams.page--;
		}
	}
	if (currentPage !== reportingParams.page) {
		return reportingParams;
	}
}

// Reporting options functions, called from the components handlers for these things just so we can limit the copy and
// paste code
// currentReportingParams - the reporting params typically from a state of a component that were previously set
// filters                - the new filters (bad term) that are to be applied, either search or inputs
const setReportFilters = (currentReportingParams, filters) => {
	// first get a nice clean object
	let reportingParams = getDefaultReportingParams();
	// if we had either search or inputs in our current state, set them now
	if (Object.entries(currentReportingParams.search).length) {
		reportingParams.search = currentReportingParams.search;
	}
	if (Object.entries(currentReportingParams.inputs).length) {
		reportingParams.inputs = currentReportingParams.inputs;
	}
	if (Object.entries(currentReportingParams.details).length) {
		reportingParams.details = currentReportingParams.details;
	}
	if (Object.entries(currentReportingParams.include_columns).length) {
		reportingParams.include_columns = currentReportingParams.include_columns;
	}

	// now, the filters coming in will be either search or inputs, but not both since those are different components
	// if we see that we are setting new search params, wipe them out, and likewise for inputs
	for (let term in filters) {
		if (term.startsWith("search_")) {
			reportingParams.search = {};
		} else if (term.startsWith("inputs_")) {
			reportingParams.inputs = {};
		} else if (term.startsWith("details_")) {
			reportingParams.details = {}
		}
	}
	// now look at the filters again, and set our new terms
	for (let term in filters) {
		if (filters[term] !== "") {
			let match = term.match(search_re);
			if (match && match.length > 1) {
				reportingParams.search[match[1]] = filters[term];
			}
			match = term.match(inputs_re);
			if (match && match.length > 1) {
				reportingParams.inputs[match[1]] = "" + filters[term];
			}
			match = term.match(details_re);
			if (match && match.length > 1) {
				let inputs = {}
				for (let field in filters[term]) {
					if (filters[term][field] !== "") {
						inputs[field] = filters[term][field]
					}
				}
				reportingParams.details[match[1]] = { inputs: inputs };
			}
		}
	}
	return reportingParams;
};

// setReportOrdering reorders the table depending on which column was clicked
// reportingParams - current reporting parameters
// column          - the column name from the react table getTheadThProps onclick event handler
const setReportOrdering = (reportingParams, column) => {
	reportingParams.order = column;
	if (reportingParams.direction === null || reportingParams.direction === 'asc') {
		reportingParams.direction = 'desc';
	} else {
		reportingParams.direction = 'asc';
	}
	// they are changing the ordering so reset the page
	reportingParams.page = 1;
	reportingParams.nextPage = 1;
	return reportingParams;
}

// This was added to keep things consistant in setReportingParams using external functions. Simply if there are new columns, use them, if not make sure to
// use the current columns in reportingParams
const setReportColumns = (reportingParams, columns) => {

	if (columns) {
		reportingParams.include_columns = columns
	}

	return reportingParams
}

// getReportValue will take in the "full" report output, i.e. the one that defines all the outputs, etc.  The row that
// you are interested in, usually the one that comes back from clicking the react table row, and the name of the field
// you want.  It will find the right index and hand you back the value.
export const getReportValue = (report, row, field) => {
	let index = null;

	for (let idx = 0; idx < report.outputs.length; idx++) {
		if (report.outputs[idx].name.toLowerCase() === field.toLowerCase()) {
			index = idx;
			break;
		}
	}

	if (index !== null && row !== undefined && row.values !== undefined) {
		return row.values[index];
	}
}

// Given a row array, returns an object with the column name being lower snake cased and the value being well ...
// the value.
// The row should be the array of vals, and outputs the array of outputs taken from a report response
export const reportRowToObject = (row, outputs) => {
	let object = {};
	if (row === undefined || outputs === undefined || !Array.isArray(row) || !Array.isArray(outputs)) {
		return null;
	}

	outputs.forEach((output, idx) => {
		let key = output.name.toLowerCase().replace(/\s+/g, '_');
		object[key] = row[idx];
	})

	return object;
}

export const convertReportingDefaults = (reportInputs) => {
	let converted = {
		search: {},
		inputs: {}
	};
	reportInputs.forEach(input => {
		if (input.default !== undefined) {
			converted.inputs[input.name.toLowerCase()] = input.default;
		}
	});

	return converted;
}

export const detailsReportMapping = {
	property: "property details",
	contact: "contact details",
	service_provider: "service provider details",
	equipment: "equipment details",
	compliance_report: "compliance report details",
	reference_point: "reference point details",
};
