import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import {
    getSampleReportConfig,
    createSamplingSpecification,
    updateSamplingSpecification,
} from "../../../api/analytesAPI";
import { getErrorText } from "../../../utils/errors";
import { BasicSamplingConfigFields } from "./BasicSamplingConfigFields";
import useAnalytes from "../useAnalytes";
import LoadingSpinner from "../../Common/LoadingSpinner";
import Form from "../../Common/Forms/Form";
import Button from "../../Common/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const AddSamplingSpecification = ({
    history,
    setShowDialog,
    showDialog,
    samplingConfigId,
    sampleReportConfigId,
    onSpecCreated,
    editingSpec,
}) => {
    const { analytes, error } = useAnalytes();
    const [isAuthority, setIsAuthority] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [formState, setFormState] = useState({
        sampling_config_id: samplingConfigId,
        sample_report_config_id: sampleReportConfigId,
    });

    // Determine if we are in edit mode
    // We'll check if we've received an editingSpec row from the grid
    const isEditMode = editingSpec && editingSpec["Sampling Config Specification ID"];

    useEffect(() => {
        if (isEditMode) {
            const parseArrayField = (value) => {
                if (!value || value === "-") return [];
                return value
                    .split(";")
                    .map((val) => val.trim())
                    .filter(Boolean);
            };

            setFormState((prev) => ({
                ...prev,
                sampling_config_id: samplingConfigId,
                sample_report_config_id: sampleReportConfigId,

                name: editingSpec["Name"] || "",
                analytes: parseArrayField(editingSpec["Analyte(s)/Parameter(s)"]),
                sample_type: editingSpec["Sample Type"] || "",
                lab_methods: parseArrayField(editingSpec["Lab Method(s)"]),
                pacing: editingSpec["Pacing"] || "",
                pacing_value: editingSpec["Pacing Value"] || "",
                pacing_unit: editingSpec["Pacing Unit"] || "",
                min_aliquots: editingSpec["Min Aliquots"] || "",
                container_types: parseArrayField(editingSpec["Container Types"]),
                container_volumes: parseArrayField(editingSpec["Container Volumes"]),
                preservatives: parseArrayField(editingSpec["Preservatives"]),
                max_hold_time: editingSpec["Max Hold Time"] || "",
                active_sampling_hours: editingSpec["Active Sampling Hours"] || "",
            }));
        } else {
            // If not editing, reset to default create form
            setFormState({
                sampling_config_id: samplingConfigId,
                sample_report_config_id: sampleReportConfigId,
            });
        }
    }, [isEditMode, editingSpec, samplingConfigId, sampleReportConfigId]);

    const handleFormStateChange = (updater) => {
        setFormState((prevState) => {
            const newState = typeof updater === "function" ? updater(prevState) : { ...prevState, ...updater };
            return {
                ...newState,
                sampling_config_id: samplingConfigId,
                sample_report_config_id: sampleReportConfigId,
            };
        });
    };

    const handleSubmit = () => {
        if (isEditMode) {
            const specId = editingSpec["Sampling Config Specification ID"];
            updateSamplingSpecification(specId, formState)
                .then((response) => {
                    console.log("Specification updated successfully", response);
                    setValidationErrors([]);
                    if (onSpecCreated) {
                        onSpecCreated();
                    }
                    setShowDialog(false);
                })
                .catch((error) => {
                    console.error("Error updating specification", error);
                    setValidationErrors(
                        error.response?.data?.Details?.map((detail) => ({
                            id: detail.field,
                            error: detail.description,
                        })) || []
                    );
                });
        } else {
            createSamplingSpecification(formState)
                .then((response) => {
                    console.log("Specification created successfully", response);
                    setValidationErrors([]);
                    if (onSpecCreated) {
                        onSpecCreated();
                    }
                    setShowDialog(false);
                })
                .catch((error) => {
                    console.error("Error creating specification", error);
                    setValidationErrors(
                        error.response?.data?.Details?.map((detail) => ({
                            id: detail.field,
                            error: detail.description,
                        })) || []
                    );
                });
        }
    };

    useEffect(() => {
        if (sampleReportConfigId) {
            getSampleReportConfig(sampleReportConfigId)
                .then((data) => setIsAuthority(data.assigned_to === "organization"))
                .catch((err) => {
                    console.error(err);
                    setIsAuthority(false);
                });
        } else {
            setIsAuthority(false);
        }
    }, [sampleReportConfigId]);

    if (error) {
        return getErrorText(error);
    }
    if (!analytes.length || isAuthority === null) {
        return <LoadingSpinner />;
    }

    const fields = [
        ...BasicSamplingConfigFields.map((f) => ({
            ...f,
            options: f.id === "analytes" ? analytes : f.options,
        })),
        {
            id: "isAuthority",
            inputType: "hidden",
            defaultValue: isAuthority,
        },
    ];

    return (
        <Dialog open={showDialog} fullWidth>
            <DialogTitle>{isEditMode ? "Edit Sampling Specification" : "Sampling Specification"}</DialogTitle>
            <DialogContent>
                <Form
                    fields={fields}
                    onSubmit={handleSubmit}
                    formState={formState}
                    onFormStateChange={handleFormStateChange}
                    onCancel={() => setShowDialog(false)}
                    validationErrors={validationErrors}
                    onValidationErrorsChange={setValidationErrors}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => setShowDialog(false)}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default withRouter(AddSamplingSpecification);
