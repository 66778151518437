import axios from 'axios';
import { getOwnUser } from './usersAPI';
import moment, * as Moment from 'moment';

export const getBackflowTestReportsReport = async (payload) => {
	const url = '/api/v1/reporting/e083eb8c-87b0-4f21-9293-dfff9c621f75';

	return await axios.post(url, payload)
		.then(response => {
			console.log('Got backflow test reports report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get backflow test reports report!', error.response.data);
			throw error;
		});
};

export const getBackflowSurveysReport = async (payload) => {
	const url = '/api/v1/reporting/4d7171d2-e261-4067-8698-cc02779d9fe8';

	return await axios.post(url, payload)
		.then(response => {
			console.log('Got backflow surveys report', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get backflow surveys report', error.response.data);
			throw error;
		});
}

export const createComplianceReport = async (payload) => {
	const complianceURL = '/api/v1/compliance/reports';

	const user = await getOwnUser();
	payload["submitted_by"] = user['user_uuid'];

	return await axios.post(complianceURL, payload)
		.then(response => {
			console.log('Created compliance report!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create compliance report!', error.response.data);
			throw error;
		});
};

export const updateComplianceReport = (reportUUID, reportedOn, reportedBy, notes) => {
	const url = `/api/v1/compliance/reports/${reportUUID}`;

	let body = {};
	if (reportedBy !== undefined) {
		body.reported_by = reportedBy;
	}
	if (reportedOn !== undefined) {
		body.reported_on = reportedOn;
	}

	if (notes !== undefined) {
		body.notes = notes;
	}

	return axios.put(url, body)
		.then(response => {
			console.log('Updated compliance report!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t update compliance report!', error.response.data);
			throw error;
		});
};

export const getComplianceReport = async (complianceReportUUID) => {
	const complianceURL = `/api/v1/compliance/reports/${complianceReportUUID}`;

	return await axios.get(complianceURL)
		.then(response => {
			console.log('Got compliance report!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get compliance report!', error.response.data);
			throw error;
		});
};

export const createComplianceReportDetail = (reportUUID, fieldUUID, value) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/details`;
	const body = {
		"form_field_uuid": fieldUUID,
		"value": "" + value // this should make sure the value is a string
	}

	return axios.post(url, body)
		.then(response => {
			console.log('Created compliance report detail!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create compliance report detail!', error.response.data);
			throw error;
		});
};

export const updateComplianceReportDetail = (reportUUID, fieldUUID, value) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/details/${fieldUUID}`;

	const body = {
		"value": "" + value // this should make sure the value is a string
	}

	return axios.put(url, body)
		.then(response => {
			console.log('Updated compliance report detail!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t update compliance report detail!', error.response.data);
			throw error;
		});
};

export const getComplianceReportDetails = (reportUUID) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/details`;

	return axios.get(url)
		.then(response => {
			console.log('Got compliance report details', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get compliance report details', error.response.data);
			throw error;
		});
};

export const getComplianceReportDetail = (reportUUID, fieldUUID) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/details/${fieldUUID}`;

	return axios.get(url)
		.then(response => {
			console.log('Got compliance report detail', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get compliance report detail', error.response.data);
			throw error;
		});
};

export const submitComplianceReport = (reportUUID, serviceProviderUUID, dryrun) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/submit`;

	let payload = {};
	if (serviceProviderUUID) {
		payload.service_provider_uuid = serviceProviderUUID;
	}
	if (dryrun) {
		payload.dry_run = true;
	}

	return axios.post(url, payload)
		.then(response => {
			console.log('Submitted compliance report!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t submit compliance report!', error.response.data);
			throw error;
		});
};

export const unsubmitComplianceReport = (reportUUID) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/unsubmit`;

	return axios.post(url)
		.then(response => {
			console.log('Unsubmitted compliance report', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t unsubmit compliance report', error.response.data);
			throw error;
		});
};

export const acceptComplianceReport = (reportUUID) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/accept`;

	return axios.post(url)
		.then(response => {
			console.log('Accepted compliance report!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t accept compliance report!', error.response.data);
			throw error;
		});
};

export const rejectComplianceReport = (reportUUID) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/reject`;

	return axios.post(url)
		.then(response => {
			console.log('Rejected compliance report!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t reject compliance report!', error.response.data);
			throw error;
		});
};

export const resetComplianceReport = (reportUUID) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/reset`;

	return axios.post(url)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			throw error;
		});
};

export const listComplianceReports = (sourceUUID) => {
	let url = '/api/v1/compliance/reports';

	if (sourceUUID !== undefined) {
		url += '?source_uuid=' + sourceUUID
	}

	return axios.get(url)
		.then(response => {
			console.log('Got list of compliance reports', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get list of compliance reports', error.response.data);
			throw error;
		});
};

export const createComplianceReportUpload = (reportUUID, formFieldUUID, uploadUUID) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/details/${formFieldUUID}/uploads`;

	return axios.post(url, {
		'upload_uuid': uploadUUID
	})
		.then(response => {
			console.log('Created compliance report upload', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create compliance report upload', error.response.data);
			throw error;
		});
};

export const listComplianceReportUploads = (reportUUID) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/uploads`;

	return axios.get(url)
		.then(response => {
			console.log('Got compliance report uploads list', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get compliance report uploads list', error.response.data);
			throw error;
		});
};

export const deleteComplianceReportUpload = (reportUUID, formFieldUUID, uploadUUID) => {
	const url = `/api/v1/compliance/reports/${reportUUID}/details/${formFieldUUID}/uploads/${uploadUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Deleted compliance report upload', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t delete compliance report upload', error.response.data);
			throw error;
		});
};

export const deleteComplianceReport = (reportUUID) => {
	const url = `/api/v1/compliance/reports/${reportUUID}`;

	return axios.delete(url)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			throw error;
		});
}

export const listComplianceSettings = () => {
	const url = `/api/v1/compliance/settings`;

	return axios.get(url)
		.then(response => {
			console.log('Got compliance settings list', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get compliance settings list', error.response.data);
			throw error;
		});
};

export const listComplianceRules = () => {
	const url = `/api/v1/compliance/rules`;

	return axios.get(url)
		.then(response => {
			console.log('Got compliance rules list', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get compliance rules list', error.response.data);
			throw error;
		});
};

export const getComplianceAutoAcceptSettings = () => {
	const url = `/api/v1/compliance/auto_accept/settings`;

	return axios.get(url)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			throw error;
		});
}