import React, { useEffect } from "react";
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, FormHelperText } from "@mui/material";
import styles from "../../../../styles/common/matieralUI/radio.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";

const RadioField = ({ label, options, value, setValue, error }) => {
    useEffect(() => {
        // A radio field technically always has a value, so if it somehow is undefined or uninitialized, set it
        if (typeof value === "undefined" && options?.[0]?.value) {
            setValue(options[0].value);
        }
    }, [value]);

    return (
        <FormControl component="fieldset" classes={{ root: styles.formControl }} error={!!error}>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
                classes={{ root: styles.radio }}
                value={value ?? options[0].value}
                onChange={(_, newValue) =>
                    // The MUI radio control always treats its values as strings, even when they aren't strings, which
                    //   causes major problems like a false value being converted to the string "false", which is true
                    // This line fixes that by comparing string with string and using the correct "value" instead of
                    //   the string-converted value
                    setValue(options.find((o) => `${o.value}` === `${newValue}`)?.value ?? options[0].value)
                }
            >
                {options.map((option) => (
                    <FormControlLabel key={option.value} control={<Radio />} {...option} />
                ))}
            </RadioGroup>
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default RadioField;
