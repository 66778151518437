import React, { useEffect, useState } from "react";
import { listReports } from "../../api/reportingAPI";
import styles from "../../styles/common/dataTables.module.css";
import Page from "./Page";
import Card from "./Card";
import SelectField from "./Forms/Fields/SelectField";
import AdvancedReportTable from "./ReportTable/AdvancedReportTable";
import Divider from "./Divider";

const DataTables = ({}) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reportList, setReportList] = useState(null);
    const [selectedReport, setSelectedReport] = useState(null);

    useEffect(() => {
        setError(null);
        setLoading(true);

        listReports()
            .then(setReportList)
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    return (
        <Page error={error} loading={loading}>
            <Card title="Data Tables">
                <div className={styles.selector}>
                    <SelectField
                        label="Select Table"
                        options={(reportList ?? []).map((report) => ({
                            label: report.name,
                            value: report.report_uuid,
                        }))}
                        value={selectedReport}
                        setValue={setSelectedReport}
                    />
                </div>
                {selectedReport && (
                    <>
                        <Divider />
                        <div className={styles.table}>
                            <AdvancedReportTable reportUUID={selectedReport} />
                        </div>
                    </>
                )}
            </Card>
        </Page>
    );
};

export default DataTables;
