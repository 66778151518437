import axios from 'axios';

// Communications

export const createComm = (payload) => {
    const url = '/api/v1/communications';

    return axios.post(url, payload)
        .then(response => {
            console.log('Created Comm', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t create Comm', error.response.data);
            throw error;
        });
};

export const getComm = (commUUID) => {
    const url = `/api/v1/communications/${commUUID}`;

    return axios.get(url)
        .then(response => {
            console.log('Got Comm', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Get Comm', error.response.data);
            throw error;
        });
};

export const updateComm = (commUUID, payload) => {
    const url = `/api/v1/communications/${commUUID}`;
    return axios.put(url, payload)
        .then(response => {
            console.log('Updated Comm', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t update Comm', error.response.data);
            throw error;
        });
};

export const deleteComm = (commUUID) => {
    const url = `/api/v1/communications/${commUUID}`;

    return axios.delete(url)
        .then(response => {
            console.log('Deleted Communication', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Delete Communication', error.response.data);
            throw error;
        });
};

export const publishComm = (commUUID) => {
    const url = `/api/v1/communications/${commUUID}/publish`;
    return axios.put(url)
        .then(response => {
            console.log('Published Comm', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t publish Comm', error.response.data);
            throw error;
        });
};

export const unpublishComm = (commUUID) => {
    const url = `/api/v1/communications/${commUUID}/unpublish`;
    return axios.put(url)
        .then(response => {
            console.log('unpublished Comm', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t unpublish Comm', error.response.data);
            throw error;
        });
};

// Communication Types

export const getCommType = (commType) => {
    const url = '/api/v1/communications/types/' + encodeURIComponent(commType);

    return axios.get(url)
        .then(response => {
            console.log('Got Comm Type', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Get Comm Type', error.response.data);
            throw error;
        });
};

export const createCommType = (payload) => {
    const url = '/api/v1/communications/types';

    return axios.post(url, payload)
        .then(response => {
            console.log('Created Comm Type', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t create Comm Type', error.response.data);
            throw error;
        });
};

export const updateCommType = (commType, payload) => {
    const url = '/api/v1/communications/types/' + encodeURIComponent(commType);
    return axios.put(url, payload)
        .then(response => {
            console.log('Updated Comm Type', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t update Comm type', error.response.data);
            throw error;
        });
};

export const deleteCommType = (commType) => {
    const url = '/api/v1/communications/types/' + encodeURIComponent(commType);
    return axios.delete(url)
        .then(response => {
            console.log('Deleted Comm Type', response);
        })
        .catch(error => {
            console.log('Couldn\'t delete Comm type', error.response.data);
            throw error;
        });
};

// Communication Logs

export const createCommLog = (payload) => {
    const url = '/api/v1/communications/log';

    return axios.post(url, payload)
        .then(response => {
            console.log('Created Comm Log', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t create Comm Log', error.response.data);
            throw error;
        });
};

export const getCommLog = (commLogUUID) => {
    const url = `/api/v1/communications/log/${commLogUUID}`;

    return axios.get(url)
        .then(response => {
            console.log('Got Comm Log', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Get Comm Log', error.response.data);
            throw error;
        });
};

export const updateCommLog = (commLogUUID, payload) => {
    const url = `/api/v1/communications/log/${commLogUUID}`;
    return axios.put(url, payload)
        .then(response => {
            console.log('Updated Comm Log', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t update Comm Log', error.response.data);
            throw error;
        });
};

export const deleteCommLog = (commLogUUID) => {
    const url = `/api/v1/communications/log/${commLogUUID}`;

    return axios.delete(url)
        .then(response => {
            console.log('Deleted Communication Log', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Delete Communication Log', error.response.data);
            throw error;
        });
};

export const markCommLogSent = (commLogUUID) => {
    const url = `/api/v1/communications/log/${commLogUUID}/sent`;
    return axios.put(url)
        .then(response => {
            console.log('Marked Comm Sent', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t mark comm sent', error.response.data);
            throw error;
        });
};

export const markCommLogUnsent = (commLogUUID) => {
    const url = `/api/v1/communications/log/${commLogUUID}/unsent`;
    return axios.put(url)
        .then(response => {
            console.log('Marked Comm Log Unsent', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t mark comm log unsent', error.response.data);
            throw error;
        });
};

export const rebuildCommLogInfo = (commLogUUID) => {
    const url = `/api/v1/communications/log/${commLogUUID}/rebuild`;
    return axios.put(url)
        .then(response => {
            console.log('Rebuilt comm log info', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t rebuild comm log info', error.response.data);
            throw error;
        });
};

// Communication Log Contacts

export const getCommLogContacts = (commLogUUID) => {
    const url = `/api/v1/communications/log/${commLogUUID}/contacts`;

    return axios.get(url)
        .then(response => {
            console.log('Got Comm Log Contacts', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Get Comm Log Contacts', error.response.data);
            throw error;
        });
};

export const getCommLogContact = (commLogUUID, contactUUID, method) => {
    const url = `/api/v1/communications/log/${commLogUUID}/contacts/${contactUUID}/${method}`;

    return axios.get(url)
        .then(response => {
            console.log('Got Comm Log Contact', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Get Comm Log Contact', error.response.data);
            throw error;
        });
};

export const createCommLogContact = (commLogUUID, contactUUID, method, payload) => {
    const url = `/api/v1/communications/log/${commLogUUID}/contacts/${contactUUID}/${method}`;

    return axios.post(url, payload)
        .then(response => {
            console.log('Created Comm Log Contact', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Create Comm Log Contact', error.response.data);
            throw error;
        });
};

export const deleteCommLogContact = (commLogUUID, contactUUID, method) => {
    const url = `/api/v1/communications/log/${commLogUUID}/contacts/${contactUUID}/${method}`;

    return axios.delete(url)
        .then(response => {
            console.log('Deleted Comm Log Contact', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Delete Comm Log Contact', error.response.data);
            throw error;
        });
};

export const markCommLogContactSent = (commLogUUID, contactUUID, method, payload) => {
    const url = `/api/v1/communications/log/${commLogUUID}/contacts/${contactUUID}/${method}/sent`;

    return axios.put(url, payload)
        .then(response => {
            console.log('Marked Comm Log Contact Sent', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Mark Comm Log Contact Sent', error.response.data);
            throw error;
        });
};

export const markCommLogContactUnsent = (commLogUUID, contactUUID, method, payload) => {
    const url = `/api/v1/communications/log/${commLogUUID}/contacts/${contactUUID}/${method}/unsent`;

    return axios.put(url, payload)
        .then(response => {
            console.log('Marked Comm Log Contact Unsent', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Mark Comm Log Contact Unsent', error.response.data);
            throw error;
        });
};

export const markCommLogContactError = (commLogUUID, contactUUID, method, payload) => {
    const url = `/api/v1/communications/log/${commLogUUID}/contacts/${contactUUID}/${method}/error`;

    return axios.put(url, payload)
        .then(response => {
            console.log('Marked Comm Log Contact Error', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Mark Comm Log Contact Error', error.response.data);
            throw error;
        });
};

export const rebuildCommLogContactInfo = (commLogUUID, contactUUID, method) => {
    const url = `/api/v1/communications/log/${commLogUUID}/contacts/${contactUUID}/${method}/rebuild`;
    return axios.put(url)
        .then(response => {
            console.log('Rebuilt comm log contact info', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t rebuild comm log contact info', error.response.data);
            throw error;
        });
};

// Downloads, marking mass items sent

export const getCommList = (payload) => {
    const url = `/api/v1/communications/downloads/list`;

    return axios.post(url, payload)
        .then(response => {
            console.log('Got Comm List', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Get Comm List', error.response.data);
            throw error;
        });
};

export const markCommListSent = (payload) => {
    const url = `/api/v1/communications/downloads/sent`;

    return axios.post(url, payload)
        .then(response => {
            console.log('Marked Comm List Sent', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Mark Comm List Sent', error.response.data);
            throw error;
        });
};

// Emails

export const sendEmails = (payload) => {
    const url = `/api/v1/communications/email`;

    return axios.post(url, payload)
        .then(response => {
            console.log('Sent Emails', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Send Emails', error.response.data);
            throw error;
        });
};

export const sendTestEmail = (payload) => {
    const url = `/api/v1/communications/test_email`;
    return axios.post(url, payload)
        .then(response => {
            console.log('Sent test email', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t send test email', error.response.data);
            throw error;
        });
};

export const sendPreviewEmail = (payload) => {
    const url = `/api/v1/communications/preview_email`;
    return axios.post(url, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
};

// New async download functions
// get the list of requests for the users to interact with
export const getCommDownloadRequests = () => {
    const url = `/api/v1/communications/downloads/requests`;
    return axios.get(url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

// generate requests that the backend can then build
export const createDownloadRequest = (payload) => {
    const url = `/api/v1/communications/downloads/requests`;
    return axios.post(url, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

// update request statuses
export const updateDownloadRequestStatus = (uuid, status) => {
    const url = `/api/v1/communications/downloads/requests/${uuid}`;
    return axios.put(url, { "status": status })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

// mark the download request as sent
export const markDownloadRequestSent = (uuid) => {
    const url = `/api/v1/communications/downloads/requests/${uuid}/sent`;
    return axios.post(url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}