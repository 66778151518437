/** @type {formField[]} */
export const BasicPropertyFields = [
    { id: "name", label: "Location Name", required: true },
    { id: "address1", label: "Address", required: true },
    { id: "address2", label: "Address Line 2" },
    { id: "city", label: "City", required: true },
    { id: "state_prov", label: "State / Prov", inputType: "state_prov_select", required: true },
    { id: "postal_code", label: "Postal Code", required: true },
    { id: "property_id", label: "Account Number" },
    { id: "phone", label: "Phone" },
    { id: "notes", label: "Notes", inputType: "textarea" },
];
