import { useEffect, useState } from "react";
import { getPermitTemplates } from "../../../api/permitsAPI";

/**
 * Custom hook for fetching permit templates
 * Returns an array of permit templates, error object, and loading state
 *
 * @returns {{permitTemplates: {label: string, value: string}[], error: Object, loading: boolean}}
 */
const usePermitTemplates = () => {
    const [error, setError] = useState(null);
    const [permitTemplates, setPermitTemplates] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPermitTemplates()
            .then((response) => {
                const data = response.templates;
                const filteredTemplates = data?.filter((template) => template.legal_control_type === "permit");
                const templates = filteredTemplates?.map((template) => ({
                    label: template.name,
                    value: template.legal_control_template_uuid,
                }));
                setPermitTemplates(templates || []);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return { permitTemplates, error, loading };
};

export default usePermitTemplates;
