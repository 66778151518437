import axios from 'axios';

export const runStateReport = (year) => {
    const url = '/api/v1/statereports';

    let payload = {
        year: parseInt(year, 10)
    }

    return axios.post(url, payload)
        .then(response => {
            console.log('Got State Report', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t get State Report', error.response.data);
            throw error;
        });
};

export const getStateReportFields = () => {
    const url = '/api/v1/statereports/fields';

    return axios.get(url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}