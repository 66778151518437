import React from "react";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";

const TextAreaField = ({ label, value, setValue, error, disabled }) => {
    return (
        <FormControl error={!!error}>
            <TextField
                label={label}
                variant="outlined"
                value={value ?? ""}
                onChange={(event) => setValue(event.currentTarget.value)}
                multiline
                minRows={3}
                maxRows={3}
                error={!!error}
                disabled={disabled}
            />
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default TextAreaField;
