export const Michigan = (year) => {
    let tables = [
        {
            key: 'report',
            rows: [
                {
                    key: 'c0',
                    label: 'C. Total number of industrial, commercial, institutional, residential, and governmental accounts that must be routinely reinspected for cross connections:',
                },
                {
                    key: 'rowTitle C',
                    label: 'Of this number:'
                },
                {
                    key: 'c1',
                    label: 'How many are High Hazard accounts:'
                },
                {
                    key: 'c2',
                    label: 'How many are Low Hazard accounts:'
                },
                {
                    key: 'd',
                    label: `D. Number of accounts from line "C" that received an initial inspection in ${year}:`,
                },
                {
                    key: 'rowTitle E',
                    label: `E. Total number of reinspections required and completed in ${year} based on degree of hazard:`,
                },
                {
                    key: 'e1',
                    label: 'High hazard reinspections required:'
                },
                {
                    key: 'e2',
                    label: 'High hazard reinspections completed:'
                },
                {
                    key: 'e3',
                    label: 'Low hazard reinspections required:'
                },
                {
                    key: 'e4',
                    label: 'Low hazard reinspections completed:'
                },
                {
                    key: 'f',
                    label: `F. Number of accounts where a cross connection(s) was found to exist during inspections or reinspections in ${year}:`,
                },
                {
                    key: 'g',
                    label: 'G. Number of accounts from "F" where corrective actions have been completed:'
                },
                {
                    key: 'h',
                    label: 'H. Total number of accounts from line "C" which are now in compliance with the local cross connection control program; H = C - (F - G):',
                },
                {
                    key: 'i',
                    label: 'I. Total number of backflow prevention devices in system requiring testing:'
                },
                {
                    key: 'rowTitle J',
                    label: `J. Number of backflow prevention devices tested in ${year}:`
                },
                {
                    key: 'j1',
                    label: 'Pass:'
                },
                {
                    key: 'j2',
                    label: 'Fail:'
                },
                {
                    key: 'j3',
                    label: 'Total'
                }
            ],
            columns: [
                {
                    key: 'count',
                    label: 'Count'
                }
            ]
        }
    ];

    return tables;
};
