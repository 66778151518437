import { SET_DUPLICATES, CLOSE_DUPLICATES, RESET_DUPLICATES } from "../actions/duplicatesActions";

const initialState = {
    open: false,
    dupes: [],
    fields: [],
    url: null,
    payload: null,
    saveCallback: null,
    selectedCallback: null,
};

const duplicatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DUPLICATES:
            return {
                ...state,
                open: true,
                dupes: action.payload.dupes,
                fields: action.payload.fields,
                url: action.payload.url,
                payload: action.payload.payload,
                saveCallback: action.payload.saveCallback,
                selectedCallback: action.payload.selectedCallback,
            };
        case CLOSE_DUPLICATES:
            return {
                ...state,
                open: false,
            };
        case RESET_DUPLICATES:
            return initialState;
        default:
            return state;
    }
};

export default duplicatesReducer;
