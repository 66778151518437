import React, { useState } from "react";
import { Field } from 'formik';
import { stateProvLists } from '../../utils/stateProvLists';

const fixmeStyle = {
	lineHeight: '1.7'
}

const StateProvSelectField = (props) => {
	const { fieldName, handleChange, disabled, unabbreviated } = props;

	// default to our org's country, if we passed one in we will then override with that
	let country = SwiftComply.organization.country_code.toLocaleUpperCase();
	if (props.countryCode && props.countryCode != "") {
		country = props.countryCode.toLocaleUpperCase()
	}

	// state prov lists should always have a value of an array of of state / prov names and abbreviations
	let list = stateProvLists[country];

	// make sure we have a list of items before trying to build a select
	if (list && list.length > 0) {
		let options = [<option key="scc3_null"></option>];
		list.forEach((elem) => {
			options.push(<option key={elem.abbrev} value={elem.abbrev}>{unabbreviated ? elem.name : elem.abbrev}</option>)
		})
		return (
			<Field
				style={fixmeStyle}
				component="select"
				disabled={disabled}
				name={fieldName}
				autoComplete="off"
				className="inputField__input inputField__input-first"
				onChange={handleChange}
			>
				{options}
			</Field>
		);
	} else {
		// if we don't support a list of state / prov abbreviations for this country then just let them enter whatever
		// only option we have right now like this is Hong Kong since I couldn't easily find how to address things there
		return (
			<Field
				style={fixmeStyle}
				type="text"
				disabled={disabled}
				name={fieldName}
				autoComplete="off"
				className="inputField__input inputField__input-first"
				onChange={handleChange}
			/>
		);
	}
};

export default StateProvSelectField;