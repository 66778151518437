import React from 'react';

import validator from 'validator';
import { updateURL } from '../../utils/url';
import { getDefaultReportingParams, updateURLWithReportingParams } from '../../utils/reporting';
import { listReports } from '../../api/reportingAPI';
import ReportTable from '../ReportTable/ReportTable';
import Flyout from '../Flyout';

import { getComplianceReport } from '../../api/complianceAPI';
import { getEquipment } from '../../api/equipmentAPI';
import { getUser } from '../../api/usersAPI';

import reportingIcon from '../../../images/dataAnalysisBlue.svg';

class ReportingDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reportUUID: null,
            reportInfo: null,
            reportIdx: -1,
            reports: [],
            flyoutSource: null,
            flyoutSourceUUID: null,
            flyoutActive: false,
            flyoutType: null,
            flyoutUUID: null,
            flyoutTab: null,
            flyoutisServiceProviderUser: false,
            flyoutNoticeUUID: null,
            flyoutBatch: null
        };

        this.table = React.createRef();

        let urlParts = this.props.location.pathname.split("/");
        if (urlParts.length >= 3 && validator.isUUID(urlParts[2])) {
            this.state.reportUUID = urlParts[2];
        }
    }

    componentDidMount() {
        let selectedReportIdx = -1;
        listReports().then(reports => {
            let list = [];
            reports.sort((a, b) => (a.name > b.name) ? 1 : -1);
            let idx = 0;
            reports.forEach(report => {
                if (report.type === 'table') {
                    // some reports are meant to power various parts of the ui, and have a required input like a contact
                    // uuid or property or something.  We can't show these reports to the user in this dashboard as they
                    // have no real way of running it until that is defined, and it kind of messes up the workflow.
                    //
                    // Also, these types of reports are fairly limited in their output anyway and probably not needed
                    // outside of their flyouts
                    let showToUser = true;
                    report.inputs.forEach(input => {
                        if (input.required && !input.default) {
                            showToUser = false;
                        }
                    });
                    if (showToUser) {
                        list.push({
                            reportUUID: report.report_uuid,
                            name: report.name,
                            columns: [],
                            details: report
                        });
                        if (this.state.reportUUID && this.state.reportUUID === report.report_uuid) {
                            selectedReportIdx = idx;
                        }
                        idx++;
                    }
                }
            });
            this.setState({ reports: list, reportIdx: selectedReportIdx });
        });
    }

    callbackHandler = (event, info) => {
        if (event === 'report selected') {
            updateURL('/reporting/' + info.reportUUID);
            this.setState({ reportUUID: info.reportUUID });
            updateURLWithReportingParams();
            return getDefaultReportingParams();
        }
    }

    openFlyout = async (rowInfo) => {
        const overlay = document.getElementById('overlay');

        let flyoutOptions = {};
        for (let i = 0; i < this.state.reports.length; i++) {
            let report = this.state.reports[i];
            if (report.reportUUID === this.state.reportUUID) {
                flyoutOptions = await this.getFlyoutOptions(report.details, rowInfo);
            }
        }

        if (flyoutOptions.flyoutType !== undefined) {
            flyoutOptions.flyoutActive = true;

            this.setState({ ...flyoutOptions });
            overlay.classList.add('overlay__visible');
        }
    }

    getFlyoutOptions = async (details, rowInfo) => {
        // we are basically looking for the first "UUID" column in the list of outputs and assuming that is the primary
        // object we'd want to work on.  If we can't currently handle that UUID type we will just return an empy object
        let options = {};

        for (let i = 0; i < details.outputs.length; i++) {
            let col = details.outputs[i];

            if (col.name.includes('UUID')) {
                if (col.name === 'Property UUID') {
                    options = {
                        flyoutType: 'locations',
                        flyoutTab: 'locationDetails',
                        flyoutUUID: rowInfo.property_uuid
                    };
                } else if (col.name === 'Contact UUID') {
                    options = {
                        flyoutType: 'contacts',
                        flyoutTab: 'contactDetails',
                        flyoutUUID: rowInfo.contact_uuid
                    };
                } else if (col.name === 'Service Provider UUID') {
                    options = {
                        flyoutType: 'serviceProviders',
                        flyoutTab: 'serviceProviderDetails',
                        flyoutUUID: rowInfo.service_provider_uuid
                    };
                } else if (col.name === 'Compliance Report UUID') {
                    options = {
                        flyoutTab: 'testReportDetails',
                        flyoutUUID: rowInfo.compliance_report_uuid
                    }

                    let report = await getComplianceReport(rowInfo.compliance_report_uuid);
                    if (report.source === 'property') {
                        options.flyoutType = 'surveys';
                    } else if (report.source === 'equipment') {
                        let equip = await getEquipment(report.source_uuid);
                        if (equip.equipment_type === 'TK') {
                            options.flyoutType = 'calibrations'; // unused
                        } else {
                            options.flyoutType = 'testReports';
                        }
                    }
                } else if (col.name === 'Equipment UUID') {
                    options = {
                        flyoutUUID: rowInfo.equipment_uuid
                    }

                    let equip = await getEquipment(rowInfo.equipment_uuid);
                    if (equip.equipment_type === 'TK') {
                        options.flyoutType = 'testKits';
                        options.flyoutTab = 'testKitDetails';
                        // fake it that we came from the service provider page, hopefully the sp uuid is provided with
                        // any report that would have the test kit info ... shrug
                        options.flyoutSource = 'serviceProvider';
                        options.flyoutSourceUUID = rowInfo.service_provider_uuid;
                    } else {
                        options.flyoutType = 'assemblies';
                        options.flyoutTab = 'assemblyDetails';
                    }
                } else if (col.name === 'User UUID') {
                    options = {
                        flyoutType: 'users',
                        flyoutTab: 'userDetails',
                        flyoutUUID: rowInfo.user_uuid,
                        flyoutisServiceProviderUser: false
                    }

                    let user = await getUser(rowInfo.user_uuid);
                    if (user.user_type === 'service provider') {
                        options.flyoutisServiceProviderUser = true;
                    }
                } else if (col.name === 'Communication UUID') {
                    options = {
                        flyoutType: 'comms',
                        flyoutTab: 'commDetails',
                        flyoutUUID: rowInfo.communication_uuid,
                    }
                }
                break;
            } else if (col.name === 'Communication Type') {
                options = {
                    flyoutType: 'commTypes',
                    flyoutTab: 'commTypeDetails',
                    flyoutUUID: rowInfo.communication_type,
                }
            }
        }
        return options;
    }

    closeFlyout = () => {
        const flyout = document.getElementById('flyout');
        const overlay = document.getElementById('overlay');

        flyout.classList.remove('slideToVisible');
        overlay.classList.remove('overlay__visible');

        setTimeout(() => {
            this.setState({
                flyoutActive: false,
                flyoutType: null,
                flyoutTab: null,
                flyoutUUID: null
            });
        }, 200);

        this.table.current.ParentEventHandler('flyout closed', { path: '/reporting/' + this.state.reportUUID });
    }

    render() {
        return (
            <div className="componentContainer" id="reportingDashboard">
                <div className="componentHeader">
                    <div className="flexAlignCenter">
                        <img src={reportingIcon} alt='' />
                        <h3>Reporting</h3>
                    </div>
                </div>
                {this.state.reports.length > 0 && (
                    <ReportTable
                        ref={this.table}
                        page='reporting'
                        tableType='Data'
                        openFlyout={this.openFlyout}
                        flyoutActive={this.state.flyoutActive}
                        defaultReportIdx={(this.state.reportIdx !== -1) ? this.state.reportIdx : undefined}
                        reports={this.state.reports}
                        callback={this.callbackHandler}
                        clickable={true}
                    />
                )}
                <img className="dashboardIconLarge dashboardIconLarge__adjusted" src={reportingIcon} alt='' />
                <div className="overlay" id="overlay" onClick={() => { this.closeFlyout() }} />
                {this.state.flyoutActive && (
                    <React.Fragment>
                        <Flyout
                            source={this.state.flyoutSource}
                            sourceUUID={this.state.flyoutSourceUUID}
                            uuid={this.state.flyoutUUID}

                            flyoutType={this.state.flyoutType}
                            flyoutPriority="primary"
                            closeFlyout={this.closeFlyout}
                            openFlyout={this.openFlyout}
                            activeTab={this.state.flyoutTab}

                            refresh={() => this.table.current.ParentEventHandler('needs update')}

                            isServiceProviderUser={this.state.flyoutisServiceProviderUser}
                            noticeUUID={this.state.flyoutNoticeUUID}
                            batch={this.state.flyoutBatch}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default ReportingDashboard;