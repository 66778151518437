import React, { useState, forwardRef, useImperativeHandle } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const DateTimeEditor = forwardRef((props, ref) => {
    console.log("DateTimeEditor - Initial props:", {
        value: props.value,
        field: props.column.getColId(),
    });

    // Initialize state with current value or current date
    const [value, setValue] = useState(() => {
        if (props.value) {
            const dayjsValue = dayjs.unix(props.value);
            console.log(
                "Initializing with timestamp:",
                props.value,
                "as date:",
                dayjsValue.format()
            );
            return dayjsValue;
        }
        console.log("No initial value, using current date/time");
        return dayjs();
    });

    const [isOpen, setIsOpen] = useState(true);

    // Interface with AG Grid
    useImperativeHandle(ref, () => ({
        getValue() {
            const timestamp = value.unix();
            console.log("getValue returning timestamp:", timestamp);

            // Update the cell value directly
            props.node.setDataValue(props.column.getColId(), timestamp);

            return timestamp;
        },
        isCancelBeforeStart() {
            return false;
        },
        isCancelAfterEnd() {
            return false;
        },
        isPopup() {
            return true;
        },
    }));

    const handleAccept = (newValue) => {
        if (!newValue || !newValue.isValid()) {
            console.log("Invalid date selected");
            setIsOpen(false);
            props.api.stopEditing();
            return;
        }

        console.log("handleAccept - new value:", newValue.format());
        setValue(newValue);

        // Update the cell value directly before stopping edit
        const timestamp = newValue.unix();
        console.log("Setting cell value to timestamp:", timestamp);
        props.node.setDataValue(props.column.getColId(), timestamp);

        setIsOpen(false);
        props.api.stopEditing();
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                value={value}
                onChange={(newValue) => {
                    console.log("onChange:", newValue?.format());
                    if (newValue && newValue.isValid()) {
                        setValue(newValue);
                    }
                }}
                open={isOpen}
                onClose={() => {
                    setIsOpen(false);
                    props.api.stopEditing();
                }}
                onAccept={handleAccept}
                onCancel={() => {
                    setIsOpen(false);
                    props.api.stopEditing();
                }}
                slotProps={{
                    textField: {
                        variant: "outlined",
                        size: "small",
                    },
                    actionBar: {
                        actions: ["accept", "cancel"],
                    },
                }}
                closeOnSelect={false}
                ampm={true}
                format="MM/DD/YYYY hh:mm A"
            />
        </LocalizationProvider>
    );
});

export default DateTimeEditor;
