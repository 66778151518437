import React from 'react';
import UserTestkitForm from './UserTestkitForm';

const UserTestkit = () => {
	return(
		<div className="flyoutContentContainer">
			<UserTestkitForm />
		</div>
	);
};

export default UserTestkit;