import axios from 'axios';

export const listRulesets = () => {
	const url = '/api/v1/formrules';

	return axios.get(url)
		.then(response => {
			console.log('Got rulesets', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get rulesets', error.response.data);
			throw error;
		});
};

export const evaluateRuleset = (rulesetUUID, fields) => {
	const url = `/api/v1/formrules/${rulesetUUID}/evaluate`

	let body = { fields: {} }

	// make sure all the values going to the backend are strings
	Object.keys(fields).forEach(key => {
		body.fields[key] = "" + fields[key];
	})

	return axios.post(url, body)
		.then(response => {
			console.log('Evaluated Ruleset', response.data.evaluation, rulesetUUID);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t evaluate ruleset');
			throw error;
		});
};