import axios from "axios";

export const SET_USER_PREFERENCES = "SET_USER_PREFERENCES";

export const setUserPreferences = (data) => ({
    type: SET_USER_PREFERENCES,
    data,
});

const baseURL = "/api/v1/user";

export const updateReportingOptionsPreferences = (section, tableName, columns) => (dispatch, getState) => {
    const reportingOptions = JSON.parse(getState().swiftComply?.user?.preferences?.["reporting options"] || "{}");
    reportingOptions[section] = { [tableName]: { columns, name: tableName } };

    return axios
        .put(baseURL + "/preferences", {
            preference: "reporting options",
            value: JSON.stringify(reportingOptions),
        })
        .then((response) => {
            dispatch(setUserPreferences(response.data.preferences));
        })
        .catch((error) => {
            console.log("Couldn't update User Preferences", error);
            throw error;
        });
};
