import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SamplingConfig from "./SamplingConfig";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import Button from "../../Common/Button";
import { AddRounded } from "@mui/icons-material";
import EditConfigurationDialog from "./EditConfigurationDialog";
import { getReportSync } from "../../../api/reportingAPI";
import { transformReportToGridData } from "../../../utils/agGridUtils";

const SamplingConfigurationsStep = ({
    history,
    formState,
    onFormStateChange,
    onSamplingConfigsChange,
}) => {
    const [configsList, setConfigsList] = useState([]);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState(null);
    const [isAddingNew, setIsAddingNew] = useState(false);

    // Retrieve ID from query params
    const searchParams = new URLSearchParams(window.location.search);
    const sampleReportConfigId = searchParams.get("sample_report_config_id");

    /**
     * This function will fetch specifications for each config so that
     * each analyte can appear as a separate entry (row) in the session storage,
     * matching the table view instead of lumping all analytes into one item.
     * We do this by calling a second endpoint for each config to get
     * the separate specifications, then storing them as an array of rows
     * in the "specifications" key of each config.
     */
    const fetchSpecificationsForConfigs = async (configs) => {
        // We'll map over all configs and fetch specs for each one
        const updatedConfigsPromises = configs.map(async (c) => {
            // If there's no c.id, just return it as is
            if (!c.id) {
                return c;
            }

            try {
                const specsResponse = await getReportSync(
                    "52aeec22-77fa-45fa-9c15-3d497a4bd096",
                    {
                        inputs: {
                            "Sampling Config ID": c.id,
                        },
                    },
                    false
                );
                if (specsResponse?.data?.rows && specsResponse?.data?.outputs) {
                    // transform data for usage like the specs table
                    const rowData = transformReportToGridData(
                        specsResponse.data
                    );
                    // Set each row as a separate specification
                    c.specifications = rowData;
                } else {
                    // if the endpoint doesn't return rows, we initialize c.specifications as an empty array
                    c.specifications = [];
                }
            } catch (error) {
                console.error("Error fetching specs for config:", c.id, error);
                // keep current config as is if there's an error
            }

            return c;
        });

        const updatedConfigs = await Promise.all(updatedConfigsPromises);
        return updatedConfigs;
    };

    const getConfigs = () => {
        if (!sampleReportConfigId) {
            console.log(
                "No sampleReportConfigId found, skipping configs fetch"
            );
            return;
        }

        console.log("Fetching configs...");
        getReportSync(
            "6a8542cb-6309-4da1-a06f-55958e4ca036",
            {
                inputs: {
                    "Sample Report Config Id": sampleReportConfigId,
                    active: "true",
                },
            },
            false
        )
            .then(async (response) => {
                if (response.data.rows && response.data.outputs) {
                    const transformedConfigs = response.data.rows.map((row) =>
                        transformReportRowToConfig(row, response.data.outputs)
                    );

                    // Fetch separate specifications so each analyte remains separate
                    const configsWithSeparateSpecs =
                        await fetchSpecificationsForConfigs(transformedConfigs);

                    setConfigsList(configsWithSeparateSpecs);
                    onSamplingConfigsChange(configsWithSeparateSpecs);
                }
            })
            .catch((error) => {
                console.error("Error fetching configs:", error);
            });
    };

    const handleDialogClose = () => {
        // Refresh config list when dialog closes
        getConfigs();
        setEditDialogOpen(false);
        setSelectedConfig(null);
        setIsAddingNew(false);
    };

    useEffect(() => {
        onSamplingConfigsChange(configsList);
    }, [configsList]);

    useEffect(() => {
        getConfigs();
    }, []);

    // Helper function to transform report row into config object
    const transformReportRowToConfig = (row, outputs) => {
        const getValue = (fieldName) => {
            const index = outputs.findIndex(
                (output) => output.name === fieldName
            );
            return index !== -1 ? row.values[index] : null;
        };

        return {
            id: getValue("Sampling Config ID"),
            name: getValue("Sampling Config Name"),
            sampleSchedule: {
                frequency: getValue("Sampling Frequency"),
                interval: getValue("Sampling Interval"),
                reportSchedule: getValue("Report Schedule"),
            },
            adminPeriod: getValue("Administrative Period"),
            samplingPeriod: getValue("Sampling Period"),
            sampling_count: getValue("Sampling Count"),
            specifications: {
                analytes: getValue("Analytes"),
                labMethods: getValue("Lab Methods"),
                sampleType: getValue("Sample Type"),
                notes: getValue("Notes"),
            },
        };
    };

    const handleEditClick = (config) => {
        setSelectedConfig(config);
        setIsAddingNew(false);
        setEditDialogOpen(true);
    };

    const handleAddNewClick = () => {
        setSelectedConfig(null);
        setIsAddingNew(true);
        setEditDialogOpen(true);
    };

    // Handle specification updates for a specific config
    const handleSpecificationsChange = (configId, updatedConfig) => {
        // this updates only the specifications portion of the config
        const updatedConfigs = configsList.map((config) =>
            config.id === configId
                ? { ...config, specifications: updatedConfig.specifications }
                : config
        );
        setConfigsList(updatedConfigs);
        onSamplingConfigsChange(updatedConfigs);
    };

    const renderConfigs = () => {
        // If no configs found, show placeholder accordion
        if (configsList.length === 0) {
            return (
                <div>
                    <Accordion
                        square={true}
                        sx={{
                            margin: "2rem 0rem !important",
                            boxShadow: "none",
                            border: "1px solid lightgrey",
                            borderRadius: "0.5rem",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignContent: "center",
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ArrowDropDownIcon fontSize="large" />
                                }
                                aria-controls="panel-temp-content"
                                id="panel-temp-header"
                                sx={{
                                    fontSize: "2rem",
                                    flexDirection: "row-reverse",
                                    padding: "1rem",
                                    width: "80%",
                                    "& .MuiAccordionSummary-expandIconWrapper":
                                        {
                                            margin: "1.5rem",
                                        },
                                    "& p": {
                                        fontSize: "1.8rem",
                                    },
                                }}
                            >
                                <Typography>Configuration #1</Typography>
                            </AccordionSummary>
                            <Box
                                sx={{ padding: "1rem", alignContent: "center" }}
                            >
                                <button
                                    color="tertiary"
                                    onClick={() => handleAddNewClick()}
                                >
                                    Edit Configuration
                                </button>
                            </Box>
                        </Box>
                        <Divider variant="middle" />
                        <AccordionDetails>
                            <Typography>No schedule set</Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            );
        }

        // Otherwise, render actual configs
        return configsList.map((config, index) => (
            <div key={config.id || index}>
                <Accordion
                    square={true}
                    sx={{
                        margin: "2rem 0rem !important",
                        boxShadow: "none",
                        border: "1px solid lightgrey",
                        borderRadius: "0.5rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "center",
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon fontSize="large" />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            sx={{
                                fontSize: "2rem",
                                flexDirection: "row-reverse",
                                padding: "1rem",
                                width: "80%",
                                "& .MuiAccordionSummary-expandIconWrapper": {
                                    margin: "1.5rem",
                                },
                                "& p": {
                                    fontSize: "1.8rem",
                                },
                            }}
                        >
                            <Typography>{config.name}</Typography>
                        </AccordionSummary>
                        <Box sx={{ padding: "1rem", alignContent: "center" }}>
                            <button
                                color="tertiary"
                                onClick={() => handleEditClick(config)}
                            >
                                Edit Configuration
                            </button>
                        </Box>
                    </Box>
                    <Divider variant="middle" />
                    <AccordionDetails>
                        <Typography component="div">
                            <SamplingConfig
                                config={config}
                                sampleReportConfigId={sampleReportConfigId}
                                onSpecificationsChange={
                                    handleSpecificationsChange
                                }
                            />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        ));
    };

    return (
        <>
            <div>{renderConfigs()}</div>
            <div>
                <Button color="tertiary" onClick={handleAddNewClick}>
                    <AddRounded /> Add New Configuration
                </Button>
            </div>

            <EditConfigurationDialog
                open={editDialogOpen}
                onClose={handleDialogClose}
                initialConfig={selectedConfig}
                title={
                    isAddingNew ? "Add New Configuration" : "Edit Configuration"
                }
                sampleReportConfigId={sampleReportConfigId}
                samplingConfigId={selectedConfig?.id}
            />
        </>
    );
};

export default withRouter(SamplingConfigurationsStep);
