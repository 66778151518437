/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { fetchDashboards } from "../../store/actions/dashboardActions";
import DashboardGridLayout from "./DashboardGridLayout";
import dashboardLarge from "../../../images/dashboardBlue.svg";
import LoadingSpinner from "../LoadingSpinner";
import { ClickAwayListener } from "@mui/material";
import dropdown from "../../../images/dropdown.svg";
import {
    listDashboardWidgets,
    updateDashboardWidget,
    deleteDashboard,
    updateDashboard,
    createDashboard,
    createDashboardWidget,
    updateDashboardWidgetsCoordinates,
    deleteDashboardWidget,
} from "../../api/dashboardsAPI";
import { updateURL } from "../../utils/url";
import { Tooltip } from "../Tooltip";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import TextField from "@mui/material/TextField";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import EditDashboardFlyout from "./EditDashboardFlyout";
import validator from "validator";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Prompt } from "react-router-dom";
import { setUserPreference } from "../../api/usersAPI";
import moment, * as Moment from "moment";

const defaultWidgets = [
    {
        title: "Non-Compliant Assemblies",
        type: "table",
        properties: JSON.stringify({
            report_uuid: "b54a82b3-ae76-4c06-94cb-8098bdbf4e56",
            columnsToShow: ["Serial Number", "Type", "Compliant"],
            reportingParams: {
                inputs: { "Compliance Expiration Before": JSON.stringify(moment().endOf("day").unix()) },
            },
        }),
    },
    {
        title: "Organization Counts",
        type: "data",
        properties: JSON.stringify({
            report_uuid: "5d5aa887-07dc-4d88-b03d-f556e28c4134",
            labels: ["Active Backflow Assemblies", "Total Locations"],
        }),
    },
    {
        title: "Compliance Due Count",
        type: "data",
        properties: JSON.stringify({
            report_uuid: "26078f8c-8ba7-4a8b-b052-03c105e96489",
            reportingParams: {
                inputs: { "Compliance Expiration Before": JSON.stringify(moment().endOf("day").unix()) },
            },
        }),
    },
    {
        title: "Incomplete Assemblies",
        type: "table",
        properties: JSON.stringify({
            report_uuid: "fa6ab0dc-d447-4998-82fd-ee9eaaba1f3e",
            columnsToShow: ["Make", "Serial Number", "Compliance Reports", "Assigned to Location"],
        }),
    },
    {
        title: "Current Month Compliance Rate",
        type: "chart",
        properties: JSON.stringify({
            report_uuid: "20e067c5-c632-4b4e-83ff-7d679ed54470",
            chartType: "gauge",
            reportingParams: { inputs: { timeframe: "month" } },
        }),
    },
    {
        title: "Current Year Compliance Rate",
        type: "chart",
        properties: JSON.stringify({
            report_uuid: "20e067c5-c632-4b4e-83ff-7d679ed54470",
            chartType: "gauge",
            reportingParams: { inputs: { timeframe: "year" } },
        }),
    },
    {
        title: "Assembly Types",
        type: "chart",
        properties: JSON.stringify({ report_uuid: "f7205439-8070-4837-ae2e-d730707f0dba", chartType: "pie" }),
    },
    {
        title: "Overall Compliance Rate",
        type: "chart",
        properties: JSON.stringify({ report_uuid: "fb8cc2fb-ff47-4068-83ee-a29b83db3ab4", chartType: "donut" }),
    },
    {
        title: "Incomplete Locations",
        type: "table",
        properties: JSON.stringify({
            report_uuid: "4622e3d8-dcf5-4aa9-85b0-7963e784fe8d",
            columnsToShow: ["Name", "Contacts", "Assemblies"],
        }),
    },
];

const defaultDashboard = { name: "System Dashboard" };
class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            loading: true,
            dashboards: [defaultDashboard],
            dashboardListDropdownVisible: false,
            default: true,
            nameInputDropdown: false,
            editMode: false,
            dashboardUUID: null,
            layout: null,
            layoutDirty: false,
            layoutPrompt: false,
            lastLocation: null,
            confirmedNavigation: false,
        };

        const urlParts = this.props.location.pathname.split("/");
        if (urlParts.length >= 3 && validator.isUUID(urlParts[2])) {
            this.state.dashboardUUID = urlParts[2];
            this.state.activeDashboard = null;
            this.state.activeWidgets = [];
        } else if (SwiftComply.user.preferences.default_dashboard) {
            updateURL("/dashboard/" + SwiftComply.user.preferences.default_dashboard);
            this.state.dashboardUUID = SwiftComply.user.preferences.default_dashboard;
            this.state.activeDashboard = null;
            this.state.activeWidgets = [];
        } else {
            this.state.activeDashboard = defaultDashboard;
            this.state.activeWidgets = defaultWidgets;
        }
    }

    componentDidMount = () => {
        this.props.fetchDashboards();
        window.addEventListener("beforeunload", this.handleBeforeUnload);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.dashboards !== this.props.dashboards) {
            this.getDashboards();
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    };

    setLoading = (type) => {
        if (type === "on") {
            this.setState(() => ({ loading: true }));
        } else if (type === "off") {
            this.setState(() => ({ loading: false }));
        }
    };

    getDashboards = async () => {
        const { dashboards } = this.props;

        await dashboards.forEach((dashboard) => {
            if (dashboard.user_uuid === SwiftComply.user.user_uuid) {
                this.setState((prevState) => ({ dashboards: [...prevState.dashboards, dashboard] }));
            }

            if (dashboard.dashboard_uuid === this.state.dashboardUUID) {
                this.setState(() => ({ activeDashboard: dashboard, default: false }));
                this.setActiveWidgets();
            }
        });
        setTimeout(() => {
            this.setLoading("off");
        }, 1000);
    };

    setDashboardDropdownList = () => {
        let options = [];
        this.state.dashboards.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.state.dashboards.forEach((dashboard, idx) => {
            options.push(
                <span key={idx} onClick={() => this.setDashboard(idx)}>
                    {dashboard.name}
                </span>
            );
        });
        return options;
    };

    toggleDashboardListDropdown = (action) => {
        let dashboardListDropdownVisible = action === "close" ? false : !this.state.dashboardListDropdownVisible;
        this.setState({ dashboardListDropdownVisible });
    };

    setDashboard = (idx) => {
        this.setLoading("on");
        setTimeout(() => {
            this.setLoading("off");
        }, 1000);
        setTimeout(() => {
            if (this.state.dashboards[idx].name !== "System Dashboard") {
                this.setState(() => ({ default: false, activeWidgets: [] }));
            } else {
                this.setState(() => ({ default: true, activeWidgets: [] }));
            }
            this.setState({ activeDashboard: this.state.dashboards[idx] }, () => {
                this.callbackHandler("dashboard selected", this.state.activeDashboard);
                if (this.state.dashboards[idx].name === "System Dashboard") {
                    this.setActiveWidgets("default");
                } else {
                    this.setActiveWidgets();
                }
            });
        }, 500);
    };

    setActiveWidgets = (type) => {
        this.setState(
            () => ({ activeWidgets: [], layoutDirty: false }),
            () => {
                if (type === "default") {
                    this.setState(() => ({ activeWidgets: defaultWidgets }));
                } else {
                    listDashboardWidgets(this.state.activeDashboard.dashboard_uuid).then((response) => {
                        this.setState(() => ({ activeWidgets: response.dashboard_widgets }));
                    });
                }
            }
        );
    };

    callbackHandler = (event, info) => {
        if (event === "dashboard selected") {
            if (!this.state.default) {
                updateURL("/dashboard/" + info.dashboard_uuid);
                this.setState({ dashboardUUID: info.dashboard_uuid });
            } else {
                updateURL("/dashboard");
                this.setState(() => ({ dashboardUUID: null }));
            }
        }
    };

    createNewDashboard = () => {
        const newDashboardName = document.getElementById("newDashboardName").value;
        createDashboard(newDashboardName).then((response) => {
            this.setState(
                () => ({ activeDashboard: response, dashboards: [defaultDashboard], default: false }),
                () => {
                    this.callbackHandler("dashboard selected", this.state.activeDashboard);
                    this.setActiveWidgets();
                    this.getDashboards();
                    this.toggleNameInputDropdown("close");
                    this.toggleEditMode("on");
                }
            );
        });
    };

    updateCurrentDashboard = (payload) => {
        updateDashboard(this.state.dashboardUUID, payload).then(() => {
            this.setState(
                () => ({ dashboards: [defaultDashboard] }),
                () => {
                    this.getDashboards();
                }
            );
        });
    };

    toggleNameInputDropdown = (type) => {
        const picker = document.getElementsByClassName("MuiPopover-root");
        if (type === "close") {
            if (picker.length) {
                return null;
            } else {
                this.setState(() => ({ nameInputDropdown: false }));
            }
        } else {
            this.setState((prevState) => ({ nameInputDropdown: !prevState.nameInputDropdown }));
        }
    };

    toggleEditMode = (type) => {
        if (type === "off") {
            this.setState(() => ({ editMode: false }));
        } else if (type === "on") {
            this.setState(() => ({ editMode: true }));
        } else {
            this.setState((prevState) => ({ editMode: !prevState.editMode }));
        }
    };

    addWidget = (payload, type) => {
        let width, height;

        if (payload.type === "table") {
            height = 4;
            width = 6;
        } else if (payload.type === "chart") {
            height = 2;
            width = 3;
        } else if (payload.type === "data") {
            height = 2;
            width = 3;
        } else if (payload.type === "embed") {
            height = 4;
            width = 6;
        }

        if (type === "stage") {
            this.setState((prevState) => ({
                activeWidgets: [
                    ...prevState.activeWidgets,
                    {
                        type: payload.type,
                        title: "",
                        staged: true,
                        coordinates: {
                            x1: 0,
                            y1: prevState.activeWidgets < 1 ? 0 : Infinity,
                            x2: width,
                            y2: height,
                        },
                        properties: JSON.stringify({}),
                    },
                ],
            }));
        } else if (type === "create") {
            // pull this out since we don't want to set some fields (like report) that might not be correct to use
            // only use it for notifications for now because breaking that apart was more effort than its worth
            let reportProperties = {
                report_uuid: payload.report_uuid,
                chartType: payload.chartType,
                columnsToShow: payload.columnsToShow,
                reportingParams: payload.reportingParams,
                url: payload.url,
            };
            if (payload.name == "notifications") {
                reportProperties.report = "notifications";
            }

            createDashboardWidget(this.state.dashboardUUID, {
                type: payload.type,
                title: payload.title,
                coordinates: {
                    x1: payload.coords["x"],
                    y1: payload.coords["y"],
                    x2: payload.coords["x"] + payload.coords["w"] - 1,
                    y2: payload.coords["y"] + payload.coords["h"] - 1,
                },
                properties: JSON.stringify(reportProperties),
            }).then((response) => {
                this.setState(
                    (prevState) => ({ activeWidgets: [...prevState.activeWidgets, response] }),
                    () => {
                        this.setState({ activeWidgets: _.reject(this.state.activeWidgets, { staged: true }) }, () => {
                            if (payload.coords.y > 0) {
                                document
                                    .getElementById(response.dashboard_widget_uuid)
                                    .scrollIntoView({ behavior: "smooth" });
                            }
                        });
                    }
                );
            });
        } else if ("cancel") {
            this.setState({ activeWidgets: _.reject(this.state.activeWidgets, { staged: true }) });
        }
    };

    deleteWidget = (widgetUUID, widgetIndex, staged) => {
        if (!staged) {
            deleteDashboardWidget(this.state.dashboardUUID, widgetUUID).then(() => {
                this.setActiveWidgets();
                setTimeout(() => {
                    this.saveLayout();
                }, 1000);
            });
        } else {
            this.setState({ activeWidgets: _.reject(this.state.activeWidgets, { staged: true }) });
        }
    };

    updateWidget = (payload, widgetUUID, position) => {
        const update = updateDashboardWidget(this.state.dashboardUUID, widgetUUID, payload).then((response) => {
            this.state.activeWidgets.splice(position, 1, response);
            return response;
        });
        return update;
    };

    getLayout = (layout, dirty) => {
        if (dirty) {
            this.setState(() => ({ layoutDirty: true }));
        } else {
            this.setState(() => ({ layout }));
        }
    };

    saveLayout = () => {
        let newCoordWidgets = [];
        if (this.state.layout) {
            for (let i = 0; i < this.state.layout.length; i++) {
                if (this.state.activeWidgets[i] && this.state.activeWidgets[i].dashboard_widget_uuid) {
                    if (this.state.activeWidgets[i].type !== "deleted") {
                        newCoordWidgets.push({
                            dashboard_widget_uuid: this.state.activeWidgets[i].dashboard_widget_uuid,
                            coordinates: {
                                x1: this.state.layout[i]["x"],
                                y1: this.state.layout[i]["y"],
                                x2: this.state.layout[i]["x"] + this.state.layout[i]["w"] - 1,
                                y2: this.state.layout[i]["y"] + this.state.layout[i]["h"] - 1,
                            },
                        });
                    }
                }
            }
        }
        this.setState({ layoutDirty: false });
        updateDashboardWidgetsCoordinates(this.state.dashboardUUID, { dashboard_widgets: newCoordWidgets }).then(
            (response) => {
                this.setActiveWidgets();
                return response;
            }
        );
    };

    deleteDashboard = () => {
        this.setLoading("on");
        deleteDashboard(this.state.dashboardUUID).then(() => {
            this.setState(
                () => ({
                    default: true,
                    activeDashboard: defaultDashboard,
                    activeWidgets: [],
                    editMode: false,
                    dashboards: [defaultDashboard],
                }),
                () => {
                    this.getDashboards();
                    this.callbackHandler("dashboard selected", this.state.activeDashboard);
                    this.setActiveWidgets("default");
                    setTimeout(() => {
                        this.setLoading("off");
                    }, 1000);
                }
            );
        });
        if (
            SwiftComply.user.preferences.default_dashboard &&
            SwiftComply.user.preferences.default_dashboard == this.state.dashboardUUID
        ) {
            setUserPreference("default_dashboard", ""); // setting prefs to empty string will remove them
        }
    };

    layoutPromptOptions = (type) => {
        if (type === "save") {
            if (this.state.lastLocation) {
                this.setState(
                    () => ({ confirmedNavigation: true }),
                    () => {
                        this.props.history.push(this.state.lastLocation.pathname);
                    }
                );
            }
            this.saveLayout();
        } else if (type === "discard") {
            if (this.state.lastLocation) {
                this.setState(
                    () => ({ confirmedNavigation: true }),
                    () => {
                        this.props.history.push(this.state.lastLocation.pathname);
                    }
                );
            }
        } else if (type === "cancel") {
            this.setState(() => ({ layoutPrompt: false }));
        }
        this.setState(() => ({ layoutPrompt: false }));
    };

    handleBlockedNavigation = (nextLocation) => {
        if (this.state.layoutDirty && !this.state.confirmedNavigation) {
            this.setState(() => ({ layoutPrompt: true, lastLocation: nextLocation }));
            return false;
        }
        return true;
    };

    handleBeforeUnload = (e) => {
        e.preventDefault();
        if (this.state.layoutDirty) {
            this.setState(() => ({ layoutPrompt: true }));
            e.returnValue = "You have unsaved layout changes, are you sure you want to leave?";
        }
    };

    setDefaultDashboard = () => {
        setUserPreference("default_dashboard", this.state.dashboardUUID);
    };

    render() {
        return (
            <React.Fragment>
                <Prompt when={this.state.layoutDirty} message={this.handleBlockedNavigation} />
                <div
                    className={this.state.loading ? "mainContent__spinner" : "mainContent__spinner invisible"}
                    data-testid="loading-spinner"
                >
                    <LoadingSpinner />
                </div>
                <Dialog open={this.state.layoutPrompt ? true : false} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        You have unsaved layout changes. Would you like to:{" "}
                    </DialogTitle>
                    <DialogActions>
                        <button className="medButtonPrimary" onClick={() => this.layoutPromptOptions("save")}>
                            Save Layout Changes
                        </button>
                        <button
                            className="medButtonSecondary yellow"
                            onClick={() => this.layoutPromptOptions("discard")}
                        >
                            Discard Layout Changes
                        </button>
                        <button className="medButtonSecondary" onClick={() => this.layoutPromptOptions("cancel")}>
                            Cancel
                        </button>
                    </DialogActions>
                </Dialog>
                <div className="componentContainer" id="dashboard">
                    <div className="componentHeader">
                        <div className="flexAlignCenter">
                            <DashboardRoundedIcon style={{ fontSize: "2rem", marginRight: "1rem" }} />
                            <ClickAwayListener onClickAway={() => this.toggleDashboardListDropdown("close")}>
                                <div className="dropdown" onClick={() => this.toggleDashboardListDropdown()} role="">
                                    <button
                                        className={
                                            this.state.dashboardListDropdownVisible
                                                ? "longButtonSecondary openBorderRadius"
                                                : "longButtonSecondary"
                                        }
                                    >
                                        {this.state.activeDashboard !== null
                                            ? this.state.activeDashboard.name
                                            : "System Dashboard"}
                                        <img src={dropdown} alt="dropdown" />
                                    </button>
                                    {this.state.dashboardListDropdownVisible && (
                                        <div className="dropdown__content" id="dropdown">
                                            {this.setDashboardDropdownList()}
                                        </div>
                                    )}
                                </div>
                            </ClickAwayListener>
                            &nbsp;
                            <button className="smallButtonSecondary" onClick={() => this.setDefaultDashboard()}>
                                Set as Default
                            </button>
                        </div>
                        <div className="flexAlignCenter">
                            <ClickAwayListener onClickAway={() => this.toggleNameInputDropdown("close")}>
                                <div
                                    className={
                                        this.state.nameInputDropdown
                                            ? "tableFilter__wrapper openBorderRadius slideDown"
                                            : "tableFilter__wrapper"
                                    }
                                >
                                    <div className="tableFilter">
                                        <button className="filterButton" onClick={() => this.toggleNameInputDropdown()}>
                                            Add New Dashboard
                                        </button>
                                    </div>
                                    {this.state.nameInputDropdown && (
                                        <div className="tableFilter__dropdownContent-wrapper">
                                            <div className="tableFilter__dropdownContent">
                                                <div className="tableFilter__content">
                                                    <TextField
                                                        variant="outlined"
                                                        id="newDashboardName"
                                                        name="newDashboardName"
                                                        label="New Dashboard Name"
                                                    ></TextField>
                                                </div>
                                                <div className="tableFilter__buttons">
                                                    <button
                                                        className="smallButtonPrimary"
                                                        onClick={this.createNewDashboard}
                                                    >
                                                        create
                                                    </button>
                                                    <button
                                                        className="smallButtonSecondary"
                                                        onClick={() => this.toggleNameInputDropdown("close")}
                                                    >
                                                        cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </ClickAwayListener>
                            {!this.state.default && (
                                <button
                                    className="xsmallButtonSecondary flexJustifyCenter"
                                    style={{ marginLeft: "1rem" }}
                                    onClick={() => this.toggleEditMode()}
                                >
                                    <div style={{ position: "relative" }}>
                                        <Tooltip text="Edit Dashboard" noIcon={true} position="top" />
                                        <EditRoundedIcon fontSize="large" />
                                    </div>
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="mainDashboard__wrapper">
                        <div className="mainDashboard">
                            <EditDashboardFlyout
                                dashboardInfo={this.state.activeDashboard}
                                editMode={this.state.editMode}
                                addWidget={this.addWidget}
                                toggleEditMode={this.toggleEditMode}
                                resetDashboard={this.setActiveWidgets}
                                saveLayout={this.saveLayout}
                                deleteDashboard={this.deleteDashboard}
                                updateDashboard={this.updateCurrentDashboard}
                                layoutDirty={this.state.layoutDirty}
                            />
                            {this.state.activeWidgets.length > 0 && (
                                <DashboardGridLayout
                                    setLoading={this.setLoading}
                                    activeWidgets={this.state.activeWidgets}
                                    default={this.state.default}
                                    createNewDashboard={this.createNewDashboard}
                                    addWidget={this.addWidget}
                                    updateWidget={this.updateWidget}
                                    editMode={this.state.editMode}
                                    resetDashboard={this.setActiveWidgets}
                                    deleteWidget={this.deleteWidget}
                                    sendLayout={this.getLayout}
                                />
                            )}
                            <img
                                className="dashboardIconLarge dashboardIconLarge__adjusted"
                                src={dashboardLarge}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    dashboards: state.dashboard.dashboards,
    loading: state.dashboard.loading,
    error: state.dashboard.error,
});

const mapDispatchToProps = (dispatch) => ({
    fetchDashboards: () => dispatch(fetchDashboards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
