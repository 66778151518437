import React from 'react';
import settings from '../../../images/settingsBlue.svg';
import orgIconLarge from '../../../images/settingsBlue.svg';
import { getOwnUser, editOwnUser, updatePassword } from '../../api/usersAPI';
import UserProfileForm from './UserProfileForm';
import UserCerts from '../Users/UserCerts';

class UserProfile extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			userData: null,
			profileType: 'user'
		};
	}

	componentDidMount() {
		this.getUserData();
	}

	updateUserData = (payload) => {
		return editOwnUser(payload);
	}

	updateUserPassword = (payload) => {
		return updatePassword(payload);
	}

	getUserData = async () => {
		const userData = await getOwnUser();

		this.setState(() => ({ userData }));
	};

	render() {
		const { profileType, orgData, userData } = this.state;

		return (
			<div className="componentContainer">
				<div className="componentHeader">
					<div className="flexAlignCenter">
						<img src={settings} alt='' />
						<h3>Profile</h3>
					</div>
				</div>
				<div className="profileContainer">
					<div className="profile">
						<div className="profile__header">
							{userData &&
								<React.Fragment>
									<span className="profile__header-main">{`${userData.firstname} ${userData.lastname}`}</span>
									<span className="profile__header-sub">{userData.email}</span>
								</React.Fragment>
							}
						</div>
						{
							userData && (
								<div className="profile__content">
									<UserProfileForm
										data={userData}
										updateUserData={this.updateUserData}
										updateUserPassword={this.updateUserPassword}
									/>
									{this.state.userData.user_type === 'service provider' &&
										<React.Fragment>
											<hr />
											<span className="profile__sectionHeaders" >Certifications</span>
											<UserCerts uuid={userData.user_uuid} />
										</React.Fragment>
									}
								</div>
							)
						}
					</div>
				</div>
				<img className="dashboardIconLarge dashboardIconLarge__adjusted" src={orgIconLarge} alt=''></img>
			</div>
		);
	}
}

export default UserProfile;