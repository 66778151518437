import React from "react";
import { createRoot } from "react-dom/client";
import AppRouter from "./routers/AppRouter";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import { configureAxios, getSystemSettings, recordWebError } from "./api/api";
import { urlToPermission } from "./utils/permissionMapping";
import { getOwnUser, getUserPreferences } from "./api/usersAPI";
import { getSections } from "./api/permissionsAPI";
import { getOrganization } from "./api/organizationAPI";
import { Provider } from "react-redux";
import initializeStore from "./store/store";
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
    adaptV4Theme,
} from "@mui/material/styles";
import Intercom from "@intercom/messenger-js-sdk";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

// Import AG Grid initialization
import { initializeAgGrid } from "./config/agGridConfig";
// Import AG Grid styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-material.css";
// Initialize AG Grid before other initialization
initializeAgGrid();

let ENABLE_CONSOLE_LOG = true;

(function () {
    let _oldLog = console.log;
    console.log = function (...message) {
        if (ENABLE_CONSOLE_LOG) {
            _oldLog(...message);
        }
    };
})();
configureAxios();

// capture any errors, and if we aren't in dev mode send them to the backend
onerror = (event, source, lineno, colno, error) => {
    if (process.env.NODE_ENV !== "development") {
        recordWebError(event, source, lineno, colno);
    }
    return false;
};

window.SwiftComply = {
    user: {},
    sections: {},
    organization: {},
    organization_system_settings: null,
    settings: {},
};

// This adds the apple touch icon that is needed for showing the SC logo when a user saves the app to their home screen on iOS
const link = document.createElement("link");
link.rel = "apple-touch-icon";
link.href = "/apple-touch-icon.png";
link.sizes = "180x180";
document.head.appendChild(link);

// get our system settings regardless of where the user is going
const settings = getSystemSettings();

// check here if the base path of the url is one where we require permissions to be.  If so we should be logged in so
// we can go ahead and get the user and their section permissions before we do anything.  If the user 401s we would
// redirect to / forcing them to log in again.
const requiredSectionAccess = urlToPermission();

let theme = createTheme({
    typography: {
        fontFamily: ["Heebo", "Arial", "sans-serif"],
        body1: {
            fontSize: "1.5rem",
        },
    },
    components: {
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontSize: "1.4rem",
                },
            },
        },
        MuiPickersYear: {
            styleOverrides: {
                yearButton: {
                    fontSize: "1.4rem",
                },
            },
        },
        MuiDayCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    fontSize: "1.4rem",
                },
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                labelContainer: {
                    fontSize: "1.6rem",
                    fontWeight: "bold",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                edgeEnd: {
                    padding: "16px",
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                },
            },
        },
    },
});

const container = document.getElementById("root");
const root = createRoot(container);
if (requiredSectionAccess) {
    const ownUser = getOwnUser();
    const userPrefs = getUserPreferences();
    const sections = getSections();
    const org = getOrganization();

    Promise.all([ownUser, userPrefs, sections, org, settings])
        .then((values) => {
            // Note that this section permission checking only happens when the user puts in a url and goes directly there
            // or refreshses the page.  If they are linked there somewhere else in the app this doesn't do anything since
            // that all happens in the router.
            // We ideally need to update the router's private routes to handle all this (then we can remove this bit), however
            // that is overly complicated (thanks react) so for now we can leave this in.  This doesn't present any current
            // security or authz issues since if the user doesn't have permission to a page, calls to API data that would
            // power that would fail anyway.
            const sectionAccess = values[2].sections;
            if (
                requiredSectionAccess !== "default" &&
                !sectionAccess[requiredSectionAccess]
            ) {
                window.location.href = "/profile";
            }

            // Set up intercom widget with user data
            const userData = values[0];

            if (userData.modules.includes("backflow")) {
                try {
                    Intercom({
                        app_id: "v8lh1uja",
                        user_id: userData.user_uuid,
                        name: `${userData.firstname} ${userData.lastname}`,
                        email: userData.email,
                        custom_launcher_selector: "#intercomHelpIcon",
                        hide_default_launcher: true,
                    });
                } catch (error) {
                    console.error("Failed to initialize Intercom:", error);
                }
            }

            root.render(
                <Provider store={initializeStore()}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <AppRouter />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </Provider>
            );
        })
        .catch((error) => {
            // this could be better, but because we need render in the promise, if that fails (mostly in dev) it will kick
            // you back to the login page.  The debugger line is here to stop that if we are in dev, but ideally we should
            // show something to the user
            debugger;
            window.location.href = "/";
        });

    // getOwnUser().then(() => {
    //     getUserPreferences().then(() => {
    //         getSections().then(response => {
    //             SwiftComply.sections = response.sections;
    //             ReactDOM.render(<AppRouter />, document.getElementById('root'));
    //         });
    //     });
    // }).catch(() => {
    // if we couldn't get our own user, then more than likely we are trying to access something we shouldn't and
    // we need to redirect the user to an error page? For now though I guess send them back to login
    // Note that the axios setup in api.js will redirect to / if it gets a 401 status, so if this isn't being
    // triggered it might be that

    // 2020-11-6 I'm not sure why this is getting called, likely some other exception in react is happening and
    // overwriting things?  But with this uncommented, the whereTo does not work and is overwritten with /, but I
    // also can't get any debugging to work here so just commenting it out until we figure it out.
    //
    // 2021-03-10 check getOwnUser for the fix here, I'm still unsure why it was needed so I'm leaving this trail
    // of comments in in case we need to come back to it

    //     window.location.href = '/';
    // });
} else {
    settings.then(() => {
        root.render(
            <Provider store={initializeStore()}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <AppRouter />
                        </LocalizationProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </Provider>
        );
    });
}
