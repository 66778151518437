import React from 'react';
import Table from '../Table';
import { getServiceProviderEquipmentReport } from '../../api/serviceProvidersAPI';
import { createServiceProviderEquipment, updateServiceProviderEquipment } from '../../api/equipmentAPI';
import Flyout from '../Flyout';
import { checkDuplicates } from '../../api/duplicatesAPI';
import { getDefaultReportingParams, setReportingParams, getReportValue } from '../../utils/reporting';
import LoaderOverlay from '../LoaderOverlay';

class ServiceProviderTestKits extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			testKitsList: [],
			flyoutActive: false,
			testKitInfo: {},
			newTestKit: false,
			assignedTo: { uuid: "", name: "" },
			tableColumnsToShow: ["Assigned To", "Make", "Model", "Serial Number", "Compliant"],
			reportingParams: getDefaultReportingParams(),
		};
	}

	async componentDidMount() {
		this.updateTestKitsList();
	}

	updateTestKitsList = async (reportingParams) => {
		if (reportingParams === undefined) {
			reportingParams = this.state.reportingParams;
		}

		getServiceProviderEquipmentReport(this.props.uuid, reportingParams).then(response => {
			if (response.nextPage !== undefined && response.nextPage > reportingParams.page) {
				reportingParams.nextPage = response.nextPage;
			}
			this.setState(() => ({ testKitsList: response.data, reportingParams }));
		});
	}

	openFlyout = async (testKitInfo, newTestKit) => {
		const overlay = document.getElementById('overlaySecondary');
		let assignedTo = this.state.assignedTo;

		if (newTestKit) {
			this.setState({ newTestKit: true, assignedTo: { assigned_to: { uuid: '', name: '' } } });
		} else {
			if (testKitInfo.values) {
				assignedTo = { uuid: testKitInfo.values[2], name: testKitInfo.values[4] };
				testKitInfo = {
					equipment_uuid: testKitInfo.values[0],
				};
			}
			this.setState({ newTestKit: false, testKitInfo, assignedTo });
		}

		await this.setState(() => ({ flyoutActive: true }), () => {
			overlay.classList.add('overlaySecondary__visible');
		});
	}

	closeFlyout = async () => {
		const flyoutSecondary = document.getElementById('flyoutSecondary');
		const overlay = document.getElementById('overlaySecondary');

		flyoutSecondary.classList.remove('slideToVisible');
		overlay.classList.remove('overlaySecondary__visible');
		this.setState({
			flyoutActive: false,
			assignedTo: { uuid: "", name: "" }
		});
	}

	saveNewTestKit = async (payload) => {
		return await createServiceProviderEquipment(this.props.uuid, payload);
	}

	handleUpdate = async (payload) => {
		return await updateServiceProviderEquipment(this.props.uuid, this.state.testKitInfo.equipment_uuid, payload);
	}

	duplicateCheck = (ctx, payload, dupFields, useSelectedDuplicate, saveNew) => {
		checkDuplicates('equipment', payload.serial_number, [{ field: "equipment_type", value: "TK", operator: "eq" }]).then(response => {
			if (response.duplicates.length > 0) {
				ctx.setDuplicates(
					response.duplicates,
					dupFields,
					null,
					payload,
					saveNew,
					useSelectedDuplicate
				);
			} else {
				saveNew(payload);
			}
		});
	}

	// -----------------------------
	// table / reporting functions
	// -----------------------------
	setOrdering = (column) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { column }, false);
		this.updateTestKitsList(newReportingParams);
	}

	fetchData = (direction) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { direction }, false);
		if (newReportingParams !== undefined) {
			this.updateTestKitsList(newReportingParams);
		}
	}
	// END table / reporting functions

	render() {
		const { flyoutActive, newTestKit, testKitInfo, testKitsList, assignedTo } = this.state;

		return (
			<div className="flyoutContentContainer">
				{testKitsList.length !== 0 ?
					<Table
						tableType='Service Providers Test Kits'
						tableData={testKitsList}
						tableSize='small'
						openFlyout={this.openFlyout}
						fetchData={this.fetchData}
						setOrdering={this.setOrdering}
						paginationParams={this.state.reportingParams}
						columnsToShow={this.state.tableColumnsToShow}
					/> : <LoaderOverlay />}

				<div className="flexButtonContainer">
					<button className="medButtonPrimary" onClick={() => this.openFlyout(null, true)}>Add Test Kit</button>
				</div>

				<div className="overlaySecondary" id="overlaySecondary" onClick={() => { this.closeFlyout() }}></div>
				{flyoutActive && (
					<React.Fragment>
						<Flyout
							uuid={testKitInfo.equipment_uuid}
							source='serviceProvider'
							sourceUUID={this.props.uuid}
							newAddition={newTestKit}

							flyoutType='testKits'
							flyoutPriority='secondary'
							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							activeTab='testKitDetails'

							create={this.saveNewTestKit}
							update={this.handleUpdate}
							refresh={this.updateTestKitsList}
							duplicateCheck={this.duplicateCheck}

							isTestKit={true}
							defaultAssignTo={assignedTo.uuid}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default ServiceProviderTestKits;