import axios from 'axios';

export const FETCH_DASHBOARDS_REQUEST = 'FETCH_DASHBOARDS_REQUEST';
export const FETCH_DASHBOARDS_SUCCESS = 'FETCH_DASHBOARDS_SUCCESS';
export const FETCH_DASHBOARDS_FAILURE = 'FETCH_DASHBOARDS_FAILURE';

export const fetchDashboardsRequest = () => ({
    type: FETCH_DASHBOARDS_REQUEST,
});

export const fetchDashboardsSuccess = (dashboards) => ({
    type: FETCH_DASHBOARDS_SUCCESS,
    payload: dashboards,
});

export const fetchDashboardsFailure = (error) => ({
    type: FETCH_DASHBOARDS_FAILURE,
    payload: error,
});

export const fetchDashboards = () => async (dispatch) => {
    dispatch(fetchDashboardsRequest());
    try {
        const url = '/api/v1/dashboards';
        const response = await axios.get(url);
        console.log('Got Dashboards List', response);
        dispatch(fetchDashboardsSuccess(response.data.dashboards));
    } catch (error) {
    console.log('Couldn\'t get dashboards list', error.response.data);
        dispatch(fetchDashboardsFailure(error));
    }
};
