import React from "react";
import { withRouter } from "react-router";
import { getPathname } from "../../../utils/url";
import BasicReportTable from "../../Common/ReportTable/BasicReportTable";
import Card from "../../Common/Card";

const PropertyOutfalls = ({ propertyUUID, history }) => {
    const pathname = getPathname();

    return (
        <Card title="Outfalls" onCreate={() => history.push(`${pathname}/addOutfall`)} onCreateLabel="New Outfall">
            <BasicReportTable
                reportUUID="9c68e84f-22ec-4cd1-9e02-db4268b38d2c"
                inputs={{ "Property UUID": propertyUUID }}
                visibleColumns={["Active", "Outfall Name", "ID", "Effluent Type", "Pipe Diameter"]}
                rowLinks={(row) => `${pathname}/o/${row["Reference Point UUID"]}`}
            />
        </Card>
    );
};

export default withRouter(PropertyOutfalls);
