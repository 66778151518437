/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';

const FormLayout = ({
	errors,
	touched,
	isSubmitting,
	editMode,
	toggleEditMode,
	resetForm
}) => {

	const cancelAction = () => {
		toggleEditMode();
		resetForm();
	};

	return (
		<Form className="userDetailsForm">
			<fieldset disabled={!editMode} autoComplete="off">
				<label htmlFor="serial">Test Kit Serial Number</label>
				<div className="inputField">
					{touched.serial && errors.serial && <p className="formError formError-first">{errors.serial}</p>}
					<Field type="text" name="serial" autoComplete="off" className="inputField__input inputField__input-first" />
				</div>
				<label htmlFor="make">Make</label>
				<div className="inputField">
					{touched.make && errors.make && <p className="formError formError-first">{errors.make}</p>}
					<Field type="tel" name="make" autoComplete="off" className="inputField__input inputField__input-first" />
				</div>
				<label htmlFor="cal">Calibration Date</label>
				<div className="inputField">
					{touched.cal && errors.cal && <p className="formError formError-first">{errors.cal}</p>}
					<Field type="text" name="cal" autoComplete="off" className="inputField__input inputField__input-first" />
				</div>
				<label htmlFor="calexp">Calibration Expiration Date</label>
				<div className="inputField">
					{touched.calexp && errors.calexp && <p className="formError formError-first">{errors.calexp}</p>}
					<Field type="text" name="calexp" autoComplete="off" className="inputField__input inputField__input-first" />
				</div>
				<label htmlFor="primary">Is Primary Test Kit?</label>
				<div className="inputField">
					{touched.primary && errors.primary && <p className="formError formError-first">{errors.primary}</p>}
					<Field type="text" name="primary" autoComplete="off" className="inputField__input inputField__input-first" />
				</div>
				{editMode && (
					<React.Fragment>
						<button className="medButtonPrimary" disabled={isSubmitting} type="submit">Save</button>
						<button className="medButtonTertiary" disabled={isSubmitting} onClick={() => cancelAction()}>Cancel</button>
					</React.Fragment>
				)}
			</fieldset>
		</Form>
	);
};

const UserTestkitForm = withFormik({
	mapPropsToValues() {
		return {
			serial: '',
			mfr: '',
			cal: '',
			calexp: '',
			primary: ''
		};
	},

	validationSchema: Yup.object().shape({
		serial: Yup.string()
			.required('Your name is required!'),
		mfr: Yup.string()
			.required('Your mfr number is required!'),
		cal: Yup.string()
			.required('Your cal is required!'),
	}),

	handleSubmit(values, { props, resetForm, setSubmitting }) {
		props.saveDetails(values);
		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
		}, 3000);
	}
})(FormLayout);

export default UserTestkitForm;

