export const Florida = (year) => {
    let tables = [
        {
            key: 'Part III',
            rows: [
                {
                    key: 'rowTitle A',
                    label: 'A. Non-residential service connections'
                },
                {
                    key: 'non-residential standard',
                    label: '1. Standard service connections'
                },
                {
                    key: 'non-residential irrigation',
                    label: '2. Dedicated irrigation service connections'
                },
                {
                    key: 'non-residential fire',
                    label: '3. Dedicated fire service connections'
                },
                {
                    key: 'non-residential subtotal',
                    label: '4. Total non-residential service connections'
                },
                {
                    key: 'rowTitle B',
                    label: 'B. Residential service connections'
                },
                {
                    key: 'residential standard',
                    label: '1. Standard service connections'
                },
                {
                    key: 'residential irrigation',
                    label: '2. Dedicated irrigation service connections'
                },
                {
                    key: 'residential fire',
                    label: '3. Dedicated fire service connections'
                },
                {
                    key: 'residential subtotal',
                    label: '4. Total non-residential service connections'
                }
            ],
            columns: [
                {
                    key: 'served',
                    label: 'Number Being Served Water'
                },
                {
                    key: 'ag',
                    label: 'Number with an AG at or for the Service Connection'
                },
                {
                    key: 'others',
                    label: 'Number with a DC, DCDA, PVB, RP, or RPDA at or for the Service Connection'
                },
                {
                    key: 'duc',
                    label: 'Number with a DuC at or for the Service Connection'
                }
            ]
        },
        {
            key: 'Part IV',
            rows: [
                {
                    key: 'ag',
                    label: 'A. AGs at or for service connections'
                },
                {
                    key: 'non-residential',
                    label: 'B. DCs, DCDAs, PVBs, RPs, & RPDAs at or for non-residential service connections'
                },
                {
                    key: 'residential',
                    label: 'C. DCs, DCDAs, PVBs, RPs, & RPDAs at or for residential service connections'
                },
                {
                    key: 'duc',
                    label: 'D. DuCs at or for residential service connections'
                }
            ],
            columns: [
                {
                    key: 'inspected',
                    label: 'Number Inspected During the Year'
                },
                {
                    key: 'tested',
                    label: 'Number Tested During the Year'
                },
                {
                    key: 'replaced',
                    label: 'Number Refurbished/Replaced During the Year'
                },
                {
                    key: 'repaired',
                    label: 'Number Repaired During the Year'
                },
                {
                    key: 'cleaned',
                    label: 'Number Cleaned During the Year'
                },
            ]
        }
    ];

    return tables;
}