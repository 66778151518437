/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import AdditionalDetails from '../AdditionalDetails';
import { withFormik, Form, Field } from 'formik';
import MessageBox from '../MessageBox';
import * as Yup from 'yup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FormLayout = ({
	data,
	errors,
	touched,
	isSubmitting,
	editMode,
	toggleEditMode,
	resetForm,
	newAddition,
	closeFlyout,
	source,
	setFieldValue,
	apiErrors,
	messageOptions,
	values,
	contactTypeTranslation
}) => {

	const cancelAction = () => {
		if (newAddition) {
			resetForm();
			closeFlyout();
		} else {
			toggleEditMode();
			resetForm();
		}
	};

	const getError = (fieldname) => {
		if (apiErrors && apiErrors[fieldname]) {
			return apiErrors[fieldname];
		} else if (touched[fieldname] && errors[fieldname]) {
			return errors[fieldname];
		}
	}

	const handleFormChange = (e) => {
		// this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
		// might be set so we can check it again
		if (apiErrors && apiErrors[e.target.name]) {
			delete apiErrors[e.target.name];
		}
	}

	const getFormErrors = () => {
		let errorList = [];
		messageOptions = {};
		['firstname', 'lastname', 'email', 'phone', 'notes'].forEach((field) => {
			let err = getError(field);
			if (err) {
				errorList.push(err);
			}
		});

		if (errorList.length !== 0) {
			messageOptions = {
				type: "error",
				message: "Please correct the following errors",
				list: errorList,
			};
			document.getElementById('contactsForm').scrollIntoView();
		}

		return messageOptions;
	}

	const createContactTypeCheckboxes = () => {
		let options = [];
		for (const [key, value] of Object.entries(contactTypeTranslation)) {
			let option =
				<FormControlLabel
					key={key}
					control={
						<Checkbox
							onChange={(e) => { setFieldValue(e.target.name, e.target.checked); handleFormChange(e); }}
							checked={values[key]}
							name={key}
							color='primary'
							disabled={!editMode}
						/>
					}
					label={value.charAt(0).toUpperCase() + value.slice(1)}
				/>
			options.push(option);
		};
		return options;
	}

	return (
		<Form className="userDetailsForm" id="contactsForm">
			<MessageBox options={getFormErrors()} />
			<fieldset disabled={!editMode}>
				<div className="detailsFieldset">
					<div className={'inputField medium ' + ((getError('firstname')) ? 'formRed' : '')}>
						<label htmlFor="firstname">First Name *</label>
						<Field type="text" name="firstname" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField medium ' + ((getError('lastname')) ? 'formRed' : '')}>
						<label htmlFor="lastname">Last Name</label>
						<Field type="text" name="lastname" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('email')) ? 'formRed' : '')}>
						<label htmlFor="email">Email</label>
						<Field type="text" name="email" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('phone')) ? 'formRed' : '')}>
						<label htmlFor="phone">Phone</label>
						<Field type="text" name="phone" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('notes')) ? 'formRed' : '')}>
						<label htmlFor="notes">Notes</label>
						<Field component="textarea" rows="2" name="notes" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
				</div>

				{source && source == "property" && (
					<React.Fragment>
						<div className='inputField long'>
							<label htmlFor="primary_contact">Primary Contact?</label>
							<Field component="select" name="primary_contact" autoComplete="off" className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							>
								<option value="false">No</option>
								<option value="true">Yes</option>
							</Field>
						</div>
						<div className='inputField long contactTypes'>
							{createContactTypeCheckboxes()}
						</div>
					</React.Fragment>
				)}
			</fieldset>
			<AdditionalDetails
				details={data.c3_additional_details}
				editMode={editMode}
			/>
			{editMode && (
				<React.Fragment>
					<div className="formButtonsBank">
						<button className="medButtonPrimary" disabled={isSubmitting} type="submit">Save</button>
						<button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
					</div>
				</React.Fragment>
			)}
		</Form>
	);
};

const ContactDetailsForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,
	enableReinitialize: true,
	mapPropsToValues(props) {
		let values = {
			firstname: (props.data && props.data.firstname ? props.data.firstname : ''),
			lastname: (props.data && props.data.lastname ? props.data.lastname : ''),
			email: (props.data && props.data.email ? props.data.email : ''),
			phone: (props.data && props.data.phone ? props.data.phone : ''),
			notes: (props.data && props.data.notes ? props.data.notes : ''),
			primary_contact: props?.data?.primary_contact?.toString()
		};

		for (const [key, value] of Object.entries(props.contactTypeTranslation)) {
			if (props.data.contact_types && props.data.contact_types.includes(value)) {
				values[key] = true
			} else {
				values[key] = false
			}
		}

		for (let uuid in props.data.c3_additional_details) {
			values[uuid] = (props.data.c3_additional_details[uuid].value && props.data.c3_additional_details[uuid].value !== "false")
				? props.data.c3_additional_details[uuid].value
				: '';
		}

		return values;
	},

	validationSchema: Yup.object().shape({
		firstname: Yup.string().required('First name is required'),
		email: Yup.string().email()
	}),

	handleSubmit(values, { props, resetForm, setSubmitting }) {
		let uuid;
		if (props.data) {
			uuid = props.data.contact_uuid;
		}
		if (values.primary_contact !== undefined) {
			values.primary_contact = (values.primary_contact === "true");
		}

		props.saveDetails(props.context, uuid, {
			...values
		});
		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
		}, 3000);
	}
})(FormLayout);

export default ContactDetailsForm;