import { getURLBaseSection } from "./url";

/**
 * Get the corresponding "permission name" for the back-end based on the current path
 */
export const urlToPermission = () => {
    const sectionMapping = {
        assemblies: "Equipment",
        c: "Contacts",
        cfg: "default",
        communications: "Communications",
        config: "default",
        contacts: "Contacts",
        dashboard: "Dashboards",
        datatables: "Reporting",
        iu: "Properties",
        lo: "Properties",
        locations: "Properties",
        notifications: "Notifications",
        o: "ReferencePoints",
        organization: "Settings",
        p: "LegalControls",
        payments: "Payments",
        profile: "default", // anyone logged in should get access to this
        reporting: "Reporting",
        serviceProviders: "ServiceProviders",
        settings: "Settings",
        stateReports: "StateReports",
        surveys: "ComplianceReports",
        testReports: "ComplianceReports",
        users: "Users",
    };

    return sectionMapping[getURLBaseSection().section];
};
