import React from 'react';
import Table from '../Table';
import { getContactAddresses, createContactAddress, updateContactAddress, deleteContactAddress } from '../../api/contactsAPI';
import { getDefaultReportingParams, setReportingParams } from '../../utils/reporting';
import ContactAddressForm from './ContactAddressForm';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import LoaderOverlay from '../LoaderOverlay';

class ContactAddresses extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			contactInfo: {},
			showAddressForm: false,
			addressInfo: {},
			contactAddressesList: [],
			reportingParams: getDefaultReportingParams(),
			newAddition: false,
			deleteMode: false,
			listDisplayColumns: [
				{
					dataName: "contact_address_uuid",
					displayName: "UUID",
					display: false
				},
				{
					dataName: ["address1", "address2"],
					displayName: "Address"
				},
				{
					dataName: "city",
					displayName: "City"
				},
				{
					dataName: "state_prov",
					displayName: "State"
				},
				{
					dataName: "postal_code",
					displayName: "Zip"
				},
				{
					dataName: "primary_address",
					displayName: "Primary",
					dataType: "boolean"
				},
				{
					dataName: "address1",
					displayName: "address1",
					display: false
				},
				{
					dataName: "address2",
					displayName: "address2",
					display: false
				}
			]
		}
	}

	componentDidMount() {
		this.getAddressList();
	}

	getAddressList = async (reportingParams) => {
		const { uuid } = this.props;
		// convert reporting params to ones that a list call will understand
		if (reportingParams === undefined) {
			reportingParams = this.state.reportingParams;
		}
		let options = reportingParams;
		// we don't need this stuff in this request or for "list" calls
		delete options.inputs;
		delete options.search;
		delete options.nextPage;
		if (options.order === 'Address') {
			options.order = 'address1';
		} else if (options.order === 'state') {
			options.order = 'state_prov';
		}
		// direction and order can be null now, and apparently the query string stringify doesn't ignore that, so delete
		// them for now here until we either just move to the generic report function or make our own stringify
		if (options.direction === null) {
			delete options.direction;
		}
		if (options.order === null) {
			delete options.order;
		}
		getContactAddresses(uuid, options).then(response => {
			if (response.nextPage !== undefined && response.nextPage > reportingParams.page) {
				reportingParams.nextPage = response.nextPage;
			}
			this.setState(() => ({ contactAddressesList: response.data.addresses, reportingParams }));
		});
	}

	openNewAddressForm = () => {
		this.setState(() => ({
			newAddition: true,
			showAddressForm: true,
			addressInfo: {}
		}));
	}

	closeNewAddressForm = () => {
		this.setState(() => ({
			newAddition: false,
			showAddressForm: false,
			addressInfo: {},
			deleteMode: false
		}));
	}

	saveAddress = async (data) => {
		console.log("ok we are saving what did we pass?", data)
		const { uuid } = this.props;
		if (this.state.addressInfo !== undefined && this.state.addressInfo.contact_address_uuid !== undefined) {
			let address = await updateContactAddress(uuid, this.state.addressInfo.contact_address_uuid, data);
			if (address.contact_address_uuid) {
				this.getAddressList();
				this.closeNewAddressForm();
			}
		} else {
			let address = await createContactAddress(uuid, data);
			if (address.contact_address_uuid) {
				this.getAddressList();
				this.closeNewAddressForm();
			}
		}
		this.props.refresh(false);
	}

	deleteAddress = async (type) => {
		const { uuid } = this.props;
		if (type === 'delete') {
			if (this.state.addressInfo !== undefined && this.state.addressInfo.contact_address_uuid !== undefined) {
				await deleteContactAddress(uuid, this.state.addressInfo.contact_address_uuid);
				this.getAddressList();
				this.closeNewAddressForm();
				this.setState(() => ({ deleteMode: false }));
				this.props.refresh(false);
			}
		} else if (type === 'cancel') {
			this.setState(() => ({ deleteMode: false }));
		} else {
			this.setState(() => ({ deleteMode: true }), () => {
				setTimeout(() => {
					document.getElementById('flyoutBase').scrollIntoView(false);
				}, 100);
			});
		}
	}

	// this is going to be called when we click on the table as the "open flyout", the row is the values on the row
	// they clicked on, so you have to know the position of the item you want
	editAddress = (row) => {
		let addressInfo = {};

		this.state.contactAddressesList.forEach((info, idx) => {
			if (info["contact_address_uuid"] == row.values[0]) {
				addressInfo = info;
			}
		})
		this.setState({
			showAddressForm: true,
			addressInfo
		});
	}


	// -----------------------------
	// table / reporting functions
	// -----------------------------
	setOrdering = (column) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { column }, false);
		this.getAddressList(newReportingParams);
	}

	fetchData = (direction) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { direction }, false);
		if (newReportingParams !== undefined) {
			this.getAddressList(newReportingParams);
		}
	}
	// END table / reporting functions


	render() {
		return (
			<div className="flyoutContentContainer" id="flyoutBase">
				{this.state.showAddressForm ?
					<React.Fragment>
						<ContactAddressForm
							data={this.state.addressInfo}
							close={this.closeNewAddressForm}
							saveAddress={this.saveAddress}
							deleteAddress={this.deleteAddress}
						/>
						{!this.state.deleteMode && !this.state.newAddition && (
							<div className="deleteButton__container">
								<button className="deleteButton" onClick={() => this.deleteAddress()}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
							</div>
						)}
						{this.state.deleteMode && (
							<div className="flyout__deleteMode">
								<span>Warning: These actions cannot be undone.</span>
								<button className="medButtonSecondary" type="button" onClick={() => this.deleteAddress('delete')}>Delete Address</button>
								<button className="exit" onClick={() => this.deleteAddress('cancel')}><CloseIcon fontSize="large" /></button>
							</div>
						)}
					</React.Fragment>
					:
					(this.state.contactAddressesList ? (
							<div>
								<Table
									tableData={this.state.contactAddressesList}
									tableType='Contact Addresses'
									tableSize='small'
									dataSource='list'
									columns={this.state.listDisplayColumns}
									openFlyout={this.editAddress}
									fetchData={this.fetchData}
									setOrdering={this.setOrdering}
									paginationParams={this.state.reportingParams}
								/>
								<div className="flexButtonContainer">
									<button className="medButtonPrimary" onClick={() => this.openNewAddressForm()}>Add Address</button>
								</div>
							</div>
						) : <LoaderOverlay />
					)
				}
			</div>
		)
	}
}

export default ContactAddresses;