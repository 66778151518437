import React, { useState, useEffect } from 'react';
import { ClickAwayListener } from '@mui/material';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import validator from 'validator';
import { sendTestEmail } from '../../api/communicationsAPI';

const TestEmailButton = (props) => {
    const [emailInputDropdown, setEmailInputDropdown] = useState(false);
    const [testEmailContact, setTestEmailContact] = useState('');
    const [testEmailEmail, setTestEmailEmail] = useState('');
    const [sentCooldown, setSentCooldown] = useState(false);

    const toggleEmailInput = (type) => {
        const picker = document.getElementsByClassName('MuiPopover-root');
        if (type === 'close') {
            if (picker.length) {
                return null;
            } else {
                setEmailInputDropdown(false);
                setTestEmailContact('');
                setTestEmailEmail('');
            }
        } else if (type === 'sent') {
            setSentCooldown(true);
            setTimeout(() => {
                setSentCooldown(false);
                setEmailInputDropdown(false);
                setTestEmailContact('');
                setTestEmailEmail('');
            }, 2000)
        } else {
            setEmailInputDropdown(!emailInputDropdown)
        }
    }

    const testEmailSender = async (e) => {

        if (e) {
            setTestEmailContact(e.target.value);
        } else {
            const sentEmail = await sendTestEmail({
                communication_log_uuid: props.data.communication_log_uuid,
                contact_uuid: props.logContacts ? testEmailContact : props.data.contact_uuid,
                email_address: testEmailEmail ? testEmailEmail : SwiftComply.user.email
            });
            if (sentEmail) {
                toggleEmailInput('sent');
            }

        }
    }

    let disableSave = true;
    if (props.logContacts) {
        if (testEmailContact && !sentCooldown) {
            disableSave = false;
        }
    } else if (!sentCooldown) {
        disableSave = false;
    }


    return (
        <ClickAwayListener onClickAway={() => toggleEmailInput('close')}>
            <div className={emailInputDropdown ? 'slideDown' : ''}>
                <div>
                    <button className="medButtonSecondary" onClick={() => toggleEmailInput()}>
                        Send Test Email
										</button>
                </div>
                {emailInputDropdown && (
                    <div className='tableFilter__dropdownContent-wrapper'>
                        <div className='tableFilter__dropdownContent'>
                            <div className='tableFilter__content'>
                                {props.logContacts &&
                                    <FormControl variant="outlined" style={{ marginBottom: '1.5rem' }}>
                                        <InputLabel htmlFor='testEmailContact'>Contact</InputLabel>
                                        <Select
                                            native
                                            label="Contact"
                                            name="testEmailContact"
                                            id="testEmailContact"
                                            value={testEmailContact}
                                            onChange={testEmailSender}
                                        >
                                            <option></option>
                                            {props.logContacts.map((contact, idx) => {
                                                if (contact.method === 'email') {
                                                    return (
                                                        <option
                                                            key={idx}
                                                            value={contact.contact_uuid}>
                                                            {contact.info.lastname !== undefined ? `${contact.info.firstname} ${contact.info.lastname}` : `${contact.info.firstname}`}
                                                        </option>
                                                    )
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                }
                                <TextField
                                    disabled
                                    variant='outlined'
                                    id='testEmailEmail'
                                    name='testEmailEmail'
                                    label="Email"
                                    onChange={(e) => setTestEmailEmail(e.target.value)}
                                    defaultValue={SwiftComply.user.email}
                                >
                                </TextField>
                            </div>
                            <div className='tableFilter__buttons'>
                                <button
                                    disabled={disableSave} className="smallButtonPrimary" onClick={() => testEmailSender()}>{sentCooldown ? 'Sent!' : 'Send'}</button>
                                {!sentCooldown &&
                                    <button className="smallButtonSecondary" onClick={() => toggleEmailInput('close')}>Cancel</button>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    )
};

export default TestEmailButton;

