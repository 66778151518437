/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { withFormik, Form } from 'formik';
import FormField from '../Forms/FormField';
import { Tooltip } from '../Tooltip';

let shouldSubmit = false;

const FormLayout = ({
	errors,
	touched,
	data,
	values,
	confirm,
	confirmFieldset,
	setActiveFieldset,
	index,
	isLastFieldset,
	setFieldValue,
	complianceReportDetails,
	prepopulateData,
	submitted,
	complianceReportInfo,
	changedFields,
	formType,
	isSubmitting,
	isValidating
}) => {

	// Scroll to the first error on next or submit button click.
	useEffect(() => {
		if (isSubmitting && !isValidating) {
			let keys = Object.keys(errors);
			if (keys.length > 0) {
			const errorElement = document.getElementsByName(keys[0]);
			if (errorElement.length !== 0) {
				errorElement[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
				setTimeout(() => {
					errorElement[0].focus();
				}, 500);
				
				}
			}
		}
	}, [errors, isSubmitting, isValidating]);

	const handleFormChange = (e) => {
		if (e.target.type === 'checkbox') {
			setFieldValue(e.target.name, e.target.checked);
		} else {
			setFieldValue(e.target.name, e.target.value);
		}
		changedFields[e.target.name] = true;
	}

	// this should let you just use the row.length to get the right size of the columns
	const colSizing = ['', 'long', 'medium', 'small'];

	const fields = () => {
		let fieldCounts = [];
		let layout = [];
		data.forEach((field) => {
			// -1 because the db starts at 1
			if (!layout[field.row - 1]) {
				layout[field.row - 1] = [];
			}
			layout[field.row - 1][field.col - 1] = field;
			fieldCounts[field.row - 1] = (fieldCounts[field.row - 1]) ? fieldCounts[field.row - 1] + 1 : 1;
		});

		let fields = [];
		layout.forEach((row, ridx) => {
			row.forEach((field, cidx) => {
				if (fieldCounts[ridx] > 3) {
					// I don't know how they did this but I guess just bail?
					return;
				}
				let size = 'inputField ' + colSizing[fieldCounts[ridx]];
				if (touched[field.form_field_uuid] && errors[field.form_field_uuid]) {
					size = 'inputField formRed ' + colSizing[fieldCounts[ridx]];
				}

				let disabled = false;
				if (field.form_field_type === 'Tester Name') {
					if (complianceReportInfo.reported_by !== undefined) {
						disabled = true;
					}
				}
				if (complianceReportInfo.status !== undefined && complianceReportInfo.status.toLowerCase() !== 'in_progress') {
					disabled = true;
				}

				let tooltip = null;
				if (field.form_field_type === 'Assembly Serial Number') {
					tooltip = <Tooltip text="If you are testing a new or recently replaced assembly for the first time, this assembly serial info should be 
					for the new assembly, not the assembly that was replaced." position="left" autoOpen={!prepopulateData.equipment?.serial_number} />;
				}
				
				// its possible that we don't have a field for this col, maybe someone wanted to put in a space, should
				// be fine I think, so we will still build up the divs, but only call FormField if we have a field
				let html =
					<div key={ridx + '-' + cidx} className={size}>
						{field && touched[field.form_field_uuid] && errors[field.form_field_uuid] && <p className="testFormError"></p>}
						{field && (
							<>
							<div className={field.input_type === 'checkbox' ? 'flex-checkbox' : null}>
								<FormField
									disabled={disabled}
									details={field}
									extraData={prepopulateData}
									prepopulateValues={values} // this is just values, but the prepop stuff is in here so we are stuck until we ditch formik
									values={complianceReportDetails}
									handleChange={handleFormChange}
									setFieldValue={setFieldValue}
									tooltip={tooltip}
								/>
							</div>
							</>
						)}
					</div>
				fields.push(html);
			});
		});

		return fields;
	}

	let reportPath = 'testReports';
	if (formType === 'survey') {
		reportPath = 'surveys';
	}

	return (
		<Form className="formField">
			{fields()}
			<div className={confirm ? 'testReport__buttons testReport__buttons-confirm' : 'testReport__buttons'}>
				{!isLastFieldset && !confirm && (
					<React.Fragment>
						{index !== 0 && <button type="button" className="smallButtonTertiary" onClick={() => setActiveFieldset(index - 1)}>Back</button>}
						<button type="submit" className="smallButtonPrimary" onClick={() => { shouldSubmit = false; }}>Next</button>
					</React.Fragment>
				)}
				{isLastFieldset && !confirm && (
					<React.Fragment>
						{index !== 0 && <button type="button" className="smallButtonTertiary" onClick={() => setActiveFieldset(index - 1)}>Back</button>}
						{(!submitted) ?
							<React.Fragment>
								<button type="submit" className="smallButtonPrimary" onClick={() => { shouldSubmit = false; }}>Save</button>
								<button type="button" className="smallButtonPrimary" onClick={() => { confirmFieldset(true); shouldSubmit = true; }}>Save &amp; Submit</button>
							</React.Fragment>
							:
							<button type="button" className="smallButtonPrimary" onClick={() => { window.location.replace(`/${reportPath}/${complianceReportInfo.compliance_report_uuid}`) }}>Close</button>
						}
					</React.Fragment>
				)}
				{confirm && (
					<React.Fragment>
						<span className="testReport__buttons-confirm-text">Is this information correct?</span>
						<button type="submit" className="smallButtonPrimary yellow">Yes</button>
						<button type="button" className="smallButtonTertiary" onClick={() => confirmFieldset(false)}>Back</button>
					</React.Fragment>
				)}
			</div>
		</Form>
	);
};

const FieldsetForm = withFormik({
	enableReinitialize: true,
	mapPropsToValues: (props) => {
		if (props.data) {
			let fields = {};
			props.data.forEach((field) => {
				let value = '';
				// set the field value to either the value from the report details, meaning one that the tester already
				// saved with the report, or if we don't have that and this field is meant to be prepopulated and the
				// report is currently in_progress we will pull data from our incoming objects.
				if (props.complianceReportDetails[field.form_field_uuid]) {
					// fix booleans
					if (props.complianceReportDetails[field.form_field_uuid] === 'true') {
						value = true;
					} else if (props.complianceReportDetails[field.form_field_uuid] === 'false') {
						value = false;
					} else {
						value = props.complianceReportDetails[field.form_field_uuid];
					}
				} else if (field.populate_from && (props.complianceReportInfo.status === undefined || props.complianceReportInfo.status == "in_progress")) {
					if (field.populate_from.startsWith('f:')) {
						// "function" populations, I dunno I have no better idea right now
						switch (field.populate_from) {
							case 'f:date.now':
								// the date picker handles its own setup, and defaults to now unless there is a value
								// so I don't believe this is needed anymore (and does actually break things)
								//value = moment();
								break;
						}
					} else {
						// this might come in as foo.bar or foo.bar,baz.qux or more, so split on , and then look at each piece
						let sources = field.populate_from.split(",");
						sources.forEach((source) => {
							let values = [];
							let parts = source.split(".");
							if (parts.length === 2) {
								if (props.prepopulateData[parts[0]] && props.prepopulateData[parts[0]][parts[1]]) {
									if (Array.isArray(props.prepopulateData[parts[0]])) {
										values = props.prepopulateData[parts[0]];
									} else {
										value = props.prepopulateData[parts[0]][parts[1]];
									}
								}
							} else {
								let ref = props.prepopulateData;
								// this will traverse our population data up until the last thing which would be the field to use
								for (let i = 0; i < parts.length - 1; i++) {
									if (ref) {
										ref = ref[parts[i]];
									}
								}
								if (ref) {
									if (Array.isArray(ref)) {
										values = ref;
									} else {
										value = ref[parts[parts.length - 1]];
									}
								}
							}

							// for things like reporter equipment, that is an array that I believe the only value should
							// be the one selected at the beginning, so we would have set our values to the ref and that
							// is an array so pull the first element out and grab whatever value we are looking for
							if (values.length !== 0) {
								value = values[0][parts[parts.length - 1]];
							}
						});
					}
				}

				// only populate this value if it is in the values list, however if this is an input we are still going
				// to let them enter whatever so ignore the list
				if (field.values.length !== 0 && field.input_type !== "input") {
					if (!field.values.includes(value)) {
						value = "";
					}
				}

				fields[field.form_field_uuid] = value;

			});
			return fields;
		}
	},
	validationSchema(props) {
		let schema = props.validationSchema();
		return schema;
	},
	handleSubmit(values, { props, setSubmitting }) {
		// we only want to submit fields that have a value or were touched, the others we don't care about so figure
		// that out and remove any fields from values that don't align with our plan
		Object.keys(values).forEach(key => {
			if (!props.changedFields[key] && values[key] == "") {
				delete values[key];
			}
		})
		setSubmitting(true);
		props.submitFieldset(values, shouldSubmit, props.isLastFieldset);
		setTimeout(() => {
			setSubmitting(false);
		}, 3000);
	}
})(FormLayout);

export default FieldsetForm;

