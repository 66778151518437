export const SET_DUPLICATES = "SET_DUPLICATES";
export const CLOSE_DUPLICATES = "CLOSE_DUPLICATES";
export const RESET_DUPLICATES = "RESET_DUPLICATES";

export const setDuplicates = (dupes, fields, url, payload, saveCallback, selectedCallback) => ({
    type: SET_DUPLICATES,
    payload: { dupes, fields, url, payload, saveCallback, selectedCallback },
});

export const closeDuplicates = () => ({
    type: CLOSE_DUPLICATES,
});

export const resetDuplicates = () => ({
    type: RESET_DUPLICATES,
});
