import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import LocationAssemblies from '../components/Locations/LocationAssemblies';
import TestReportLocationAssemblyInfo from '../components/TestReports/TestReportLocationAssemblyInfo';
import { getOwnUser } from '../api/usersAPI';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

class ModalEquipment extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			user: null,
			setInactive: false,
			replacement: null,
			locationUUID: null
		};
	}

	componentDidMount = () => {
		getOwnUser().then(user => {
			this.setState(() => ({ user }));
		});
	}

	getLocationAssemblyInfo = (locationUUID, assemblyUUID, newLocation, newAssembly) => {
		let reportPhase = 'serviceProviderInfo';

		if (newLocation) {
			this.setState({ newLocation, newAssembly: true, reportPhase });
		} else if (newAssembly) {
			this.setState({ locationInfo: { property_uuid: locationUUID }, newAssembly: true, reportPhase });
		} else {
			this.setState({ locationInfo: { property_uuid: locationUUID }, assemblyInfo: { equipment_uuid: assemblyUUID }, reportPhase });
		}
	}

	handleInactiveCheck = () => {
		this.setState((prevState) => ({ setInactive: !prevState.setInactive }));
	}

	handleSelectReplacement = (payload, locationUUID) => {
		this.setState(() => ({ replacement: payload, locationUUID }));
	}

	cancelAction = () => {
		this.props.handleClose();
		this.setState(() => ({ setInactive: false, replacement: null, location: null }))
	}


	render() {

		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.handleClose}
				className="widget__dialog"
				id='modalEquipment'
			>
				<DialogTitle>Choose Assembly</DialogTitle>
				<DialogContent>
					{this.props.locationUUID === undefined &&
						<TestReportLocationAssemblyInfo
							user={this.state.user}
							sendDataToReport={this.handleSelectReplacement}
							source='newAssembly'
						/>
					}
					{this.props.locationUUID !== undefined &&
						<React.Fragment>
							<LocationAssemblies
								uuid={this.props.locationUUID}
								flyoutPriority="secondary"
								flyoutType="modalEquipment"
								// openFlyout={this.props.setReplacement}
								openFlyout={this.handleSelectReplacement}
								replacement={this.props.equipmentUUID}
							/>
						</React.Fragment>
					}
					{this.props.setReplacement &&
						<div className="modalSection">
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.setInactive}
										onChange={(e) => { this.handleInactiveCheck(e) }}
										value={this.state.setInactive}
										color='primary'
									/>
								}
								label='Set Replaced Assembly As Inactive?'
							/>
						</div>
					}
				</DialogContent>
				<DialogActions>
					{this.state.replacement &&
						<button className="smallButtonPrimary" onClick={() => this.props.setReplacement(this.state.replacement, this.state.locationUUID, this.state.setInactive)}>save</button>
					}
					<button className="smallButtonSecondary" onClick={this.cancelAction}>cancel</button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default ModalEquipment;