import React, { useState, useEffect } from 'react';
import CommDetailsForm from './CommDetailsForm';
import editModeIcon from '../../../images/editMode.svg';
import { getReportSync } from '../../api/reportingAPI';
import { updateComm, createComm, deleteComm, getComm, publishComm, unpublishComm } from '../../api/communicationsAPI';
import { getReportValue } from '../../utils/reporting';
import { ModalDuplicateContext } from '../../contexts/ModalDuplicateContext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import Flyout from '../Flyout';

const CommDetails = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [data, setData] = useState({});
    const [apiErrors, setApiErrors] = useState({});
    const [isNew, setIsNew] = useState(props.newAddition);
    const [commEditorOpen, setCommEditorOpen] = useState(false);
    const [emailEditorOpen, setEmailEditorOpen] = useState(false);
    const [commText, setCommText] = useState(null);
    const [emailText, setEmailText] = useState(null);
    const [formErrors, setFormErrors] = useState();
    const [typesList, setTypesList] = useState([]);
    const [flyoutActive, setFlyoutActive] = useState(false);

    useEffect(() => {
        if (props.newAddition) {
            setEditMode(true);
            if (props.data && props.data.clone) {
                setData(props.data);
                setEmailText(props.data.email_body);
                setCommText(props.data.template);
            }
            getCommTypes();
        } else {
            getCommData(props.uuid);
            getCommTypes();
        }
    }, [props.uuid]);

    const getCommData = (uuid) => {
        if (uuid !== undefined && uuid !== '') {
            getComm(uuid).then(response => {
                props.setHeader(response.name);
                setData(response);
            });
        }
    };

    const getCommTypes = async () => {
        const reportOutput = await getReportSync('14d17cfb-ac90-4b30-84fb-5c7ecf076503', { count: 100 });
        const typesList = [];
        reportOutput.data.rows.map((row, idx) => {
            let type = getReportValue(reportOutput.data, row, 'Communication Type');

            typesList.push(type);
        });
        setTypesList(typesList)
    }

    const saveDetails = async (ctx, uuid, payload) => {
        if (payload.communication_type_source === "ad hoc") {
            // we require a period, maybe we should remove that but for now ad hoc just get a hardcoded 1 day
            payload.period = "1 day";
        }

        if (isNew) {
            saveNewComm({ ...payload, template: commText, email_body: emailText });
        } else {

            updateComm(uuid, { ...payload, template: commText, email_body: emailText })
                .then(response => {
                    props.refresh();
                    setEditMode(false);
                    props.setHeader(response.name);
                    getCommData(props.uuid);
                    getCommTypes();

                })
                .catch(error => {
                    if (error.name === 'APIUserError') {
                        setApiErrors(error.apiFieldErrors);
                    }
                });
        }
    };

    const saveNewComm = (payload) => {
        createComm(payload)
            .then(response => {
                setEditMode(false);
                setIsNew(false);
                setData(response);

                setTimeout(() => {
                    if (!props.downloads) {
                        props.refresh();
                    }
                    props.setHeader(response.name);
                    props.openFlyout(response, false, 'comm');
                }, 500)
            })
            .catch(error => {
                if (error.name === 'APIUserError') {
                    setApiErrors(error.apiFieldErrors);
                }
            });
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
        setDeleteMode(false);
    };

    const deleteThisComm = async (type) => {
        if (type === 'delete') {
            await deleteComm(data['communication_uuid']);
            await props.closeFlyout();
            await props.refresh();
        } else if (type === 'cancel') {
            setDeleteMode(false);
        } else {
            setDeleteMode(true);
            setTimeout(() => {
                document.getElementById('flyoutBase').scrollIntoView(false);
            }, 100);
        }
    };

    const editorControls = (action, type, payload) => {
        if (action === 'open') {
            if (type === 'commText') {
                setCommEditorOpen(true);
            } else if (type === 'emailText') {
                setEmailEditorOpen(true);
            }
        } else if (action === 'close') {
            if (type === 'commText') {
                setCommEditorOpen(false);
            } else if (type === 'emailText') {
                setEmailEditorOpen(false);
            }
        } else if (action === 'save') {
            if (type === 'commText') {
                setCommText(payload);
                setCommEditorOpen(false);
            } else if (type === 'emailText') {
                setEmailText(payload)
                setEmailEditorOpen(false);
            }
        }
    }

    const openFlyout = (assemblyInfo, newAssembly) => {
        const overlay = document.getElementById('overlaySecondary');

        setFlyoutActive(true);
        overlay.classList.add('overlaySecondary__visible');
    }

    const closeFlyout = () => {
        const flyoutSecondary = document.getElementById('flyoutSecondary');
        const overlay = document.getElementById('overlaySecondary');

        flyoutSecondary.classList.remove('slideToVisible');
        overlay.classList.remove('overlaySecondary__visible');
        setTimeout(() => {
            setFlyoutActive(false)
        }, 200);
    }

    const setNewCommType = (type) => {
        setData({ communication_type: type })
    }

    const setCommStatus = async (type) => {
        if (type === 'publish') {
            await publishComm(props.commUUID ? props.commUUID : props.uuid);
        } else if (type === 'unpublish') {
            await unpublishComm(props.commUUID ? props.commUUID : props.uuid);
        }
        setEditMode(false);
        props.refresh();
        getCommData(props.uuid);
    }

    return (
        <ModalDuplicateContext.Consumer>
            {(ctx) => {
                return (
                    <div className="flyoutContentContainer" id="flyoutBase">
                        <button className="editModeButton" onClick={() => toggleEditMode()}>
                            {!editMode && (
                                <img src={editModeIcon} alt='' />
                            )}
                        </button>
                        <CommDetailsForm
                            data={data}
                            context={ctx}
                            editMode={editMode}
                            saveDetails={saveDetails}
                            toggleEditMode={toggleEditMode}
                            newAddition={props.newAddition}
                            closeFlyout={props.closeFlyout}
                            openFlyout={props.openFlyout}
                            closeSecondaryFlyout={closeFlyout}
                            openSecondaryFlyout={openFlyout}
                            deleteThisComm={deleteThisComm}
                            apiErrors={apiErrors}
                            editorControls={editorControls}
                            commEditorOpen={commEditorOpen}
                            emailEditorOpen={emailEditorOpen}
                            commText={commText}
                            emailText={emailText}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            typesList={typesList}
                            setCommStatus={setCommStatus}
                            cloneComm={props.cloneComm}
                        />
                        {editMode && !deleteMode && !props.newAddition && (data.status === 'draft') && (
                            <div className="deleteButton__container">
                                <button className="deleteButton" onClick={() => deleteThisComm()}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
                            </div>
                        )}
                        {editMode && deleteMode && (
                            <div className="flyout__deleteMode">
                                <span>Warning: This will delete all associated historical records and actions cannot be undone.</span>
                                <button className="medButtonSecondary" type="button" onClick={() => deleteThisComm('delete')}>Delete Communication</button>
                                <button className="exit" onClick={() => deleteThisComm('cancel')}><CloseIcon fontSize="large" /></button>
                            </div>
                        )}
                        <div className="overlaySecondary" id="overlaySecondary" onClick={() => { closeFlyout() }}></div>
                        {flyoutActive && props.newAddition && (
                            <React.Fragment>
                                <Flyout
                                    source='comm'
                                    newAddition="true"
                                    flyoutType='commTypes'
                                    flyoutPriority='secondary'
                                    closeFlyout={closeFlyout}
                                    activeTab='commTypeDetails'
                                    getCommTypes={getCommTypes}
                                    setNewCommType={setNewCommType}
                                />
                            </React.Fragment>
                        )}
                    </div>
                );
            }}
        </ModalDuplicateContext.Consumer>
    );
};

export default CommDetails;