import React from 'react';
import { getFieldsetFields } from '../../api/formsAPI';
import FieldsetForm from './FieldsetForm';
import MessageBox from '../MessageBox';
import * as Yup from 'yup';
import { addFormFieldPopulateData } from '../../utils/fixes';
import { addFormFieldSuggestedValues } from '../../utils/suggestedValues';
import FileSelect from './FileSelect';
import { uploadFile } from '../../api/uploadsAPI';
import { createComplianceReportUpload } from '../../api/complianceAPI';

class Fieldset extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			confirm: false,
			fields: [],
			complianceDetailsCreated: false,
			files: []
		};
	}

	componentDidMount() {
		this.getFields();
	}

	getFields = async () => {
		let fieldsData = await getFieldsetFields(this.props.data.form_uuid, this.props.data['form_fieldset_uuid']);
		this.setState(() => ({ fields: addFormFieldSuggestedValues(addFormFieldPopulateData(fieldsData.form_fields)) }));
	};

	confirmFieldset = (bool) => {
		this.setState(() => ({ confirm: bool }));
	};

	submitFieldset = async (payload, shouldSubmit, isLastFieldset) => {
		await this.props.submitReport(payload, shouldSubmit, isLastFieldset);
		if (this.state.files.length > 0) {
			this.state.files.map(async (file) => {
				const data = new FormData();
				data.set('file', file.file);
				data.set('scope', "organization");
				const upload = await uploadFile(data);
				await createComplianceReportUpload(this.props.complianceReportInfo.compliance_report_uuid, file.formFieldUUID, upload.uuid);
			});
		}
	}

	receiveFiles = (payload) => {
		this.setState((prevState) => ({ files: [...prevState.files, payload] }));
	}

	createYupValidationSchema = () => {
		let schema = {};

		// if we have compliance report info, and if this report is still in progress, then build the validation.
		// if we don't have a status then the report is likely still in a "new" state so also build the validation in
		// that case
		if (
			!this.props.complianceReportInfo.status ||
			this.props.complianceReportInfo.status.toLowerCase() === "in_progress"
		) {
			this.state.fields.forEach((field) => {
				let validator = Yup.string();
				if (field.data_type === 'numeric') {
					validator = Yup.number();
				}

				if (field.required) {
					validator = validator.required('required');
				}

				schema[field.form_field_uuid] = validator;
			});
		}

		return Yup.object().shape(schema);
	}

	render() {
		const { prepopulateData, index, data, complianceReportDetails, setActiveFieldset, submitted, messageOptions, complianceReportInfo } = this.props;
		const { fields } = this.state;

		const changedFields = {};

		return (
			<div className='testReport testReport__active'>
				<div className="testReport__header">
						<FileSelect
							fields={fields}
							formType={this.props.formType}
							sendFiles={this.retrieveFiles}
							reportUUID={this.props.complianceReportInfo?.compliance_report_uuid}
							sendFilesToFieldset={this.receiveFiles}
							disabled={complianceReportInfo.status !== undefined && complianceReportInfo.status.toLowerCase() !== 'in_progress'}
							files={this.state.files}
						/>
					<span className="testReport__header-main">{data['name']}</span>
				</div>
				<div className="testReport__content">
					<MessageBox options={messageOptions} />
					{fields.length > 0 ?
						(
							<FieldsetForm
								complianceReportInfo={complianceReportInfo}
								formType={this.props.formType}
								data={fields}
								prepopulateData={prepopulateData}
								submitFieldset={this.submitFieldset}
								setActiveFieldset={setActiveFieldset}
								confirm={this.state.confirm}
								confirmFieldset={this.confirmFieldset}
								index={index}
								complianceReportDetails={complianceReportDetails}
								isLastFieldset={this.props.isLastFieldset}
								validationSchema={this.createYupValidationSchema}
								submitted={submitted}
								changedFields={changedFields}
							/>
						)
						: null}
				</div>
			</div>
		);
	}
}

export default Fieldset;
