import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Box, Typography } from "@mui/material";
import { getReportSync } from "../../../api/reportingAPI";
import { getSampleReportConfig } from "../../../api/analytesAPI";
import SummaryGrid from "../../Common/Forms/SummaryGrid";
import { scheduleToText } from "../../../utils/forms";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Divider from "@mui/material/Divider";
import SamplingConfig from "./SamplingConfig";

const SRCReviewStep = ({ completeState }) => {
    const [loadedData, setLoadedData] = useState(completeState);
    const searchParams = new URLSearchParams(window.location.search);
    const sampleReportConfigId = searchParams.get("sample_report_config_id");

    useEffect(() => {
        const loadData = async () => {
            // If we have complete state with data, use it
            if (
                completeState?.reportConfig &&
                Object.keys(completeState.reportConfig).length > 0
            ) {
                setLoadedData(completeState);
                return;
            }

            // Try to load from storage
            const savedState = sessionStorage.getItem("srcCompleteState");
            if (savedState) {
                setLoadedData(JSON.parse(savedState));
                return;
            }

            // If no data in state or storage, fetch from API
            try {
                const fetchedData = await fetchAllData();
                setLoadedData(fetchedData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        loadData();
    }, [completeState, sampleReportConfigId]);

    const fetchAllData = async () => {
        if (!sampleReportConfigId) {
            console.warn("No sample report config ID available");
            return null;
        }

        try {
            // Fetch report config using getSampleReportConfig
            const reportConfigResponse = await getSampleReportConfig(
                sampleReportConfigId
            );

            // Filter reportConfig to match session storage format
            const filteredReportConfig = {
                name: reportConfigResponse.name,
                assigned_to: reportConfigResponse.assigned_to,
                control_type: reportConfigResponse.control_type,
                lab_report_required: reportConfigResponse.lab_report_required,
                custody_chain_required:
                    reportConfigResponse.custody_chain_required,
                general_control_uuid: reportConfigResponse.general_control_uuid,
                report_schedule: reportConfigResponse.report_schedule,
            };

            // Fetch sampling configs
            const samplingConfigsResponse = await getReportSync(
                "6a8542cb-6309-4da1-a06f-55958e4ca036",
                {
                    inputs: {
                        "Sample Report Config ID": sampleReportConfigId,
                        active: "true",
                    },
                },
                false
            );

            let transformedConfigs = [];
            if (
                samplingConfigsResponse.data.rows &&
                samplingConfigsResponse.data.outputs
            ) {
                transformedConfigs = samplingConfigsResponse.data.rows.map(
                    (row) =>
                        transformReportRowToConfig(
                            row,
                            samplingConfigsResponse.data.outputs
                        )
                );

                // Fetch separate specifications for each config
                const configsWithSeparateSpecs =
                    await fetchSpecificationsForConfigs(transformedConfigs);

                return {
                    reportConfig: filteredReportConfig,
                    samplingConfigs: configsWithSeparateSpecs,
                };
            }

            return null;
        } catch (error) {
            console.error("Error fetching all data:", error);
            return null;
        }
    };

    // Helper function to transform the sampling config row
    const transformReportRowToConfig = (row, outputs) => {
        const getValue = (fieldName) => {
            const index = outputs.findIndex(
                (output) => output.name === fieldName
            );
            return index !== -1 ? row.values[index] : null;
        };

        return {
            id: getValue("Sampling Config ID"),
            name: getValue("Sampling Config Name"),
            sampleSchedule: {
                frequency: getValue("Sampling Frequency"),
                interval: getValue("Sampling Interval"),
                reportSchedule: getValue("Report Schedule"),
            },
            adminPeriod: getValue("Administrative Period"),
            samplingPeriod: getValue("Sampling Period"),
            sampling_count: getValue("Sampling Count"),
            specifications: [], // Initialize as empty array, will be populated by fetchSpecificationsForConfigs
        };
    };

    // Helper function to transform specifications
    const transformSpecificationsToMatchFormat = (specsResponse) => {
        if (!specsResponse?.data?.rows || !specsResponse?.data?.outputs) {
            return [];
        }

        return specsResponse.data.rows.map((row) => {
            const getValue = (fieldName) => {
                const index = specsResponse.data.outputs.findIndex(
                    (output) => output.name === fieldName
                );
                return index !== -1 ? row.values[index] : "";
            };

            return {
                "Sampling Config ID": getValue("Sampling Config ID"),
                "Sampling Config Specification ID": getValue(
                    "Sampling Config Specification ID"
                ),
                Name: getValue("Name"),
                "Analyte(s)/Parameter(s)": getValue("Analyte(s)/Parameter(s)"),
                "Sample Type": getValue("Sample Type"),
                Pacing: getValue("Pacing"),
                "Lab Method(s)": getValue("Lab Method(s)"),
            };
        });
    };

    // Fetch specs for each config
    const fetchSpecificationsForConfigs = async (configs) => {
        const updatedConfigsPromises = configs.map(async (c) => {
            if (!c.id) {
                return c;
            }

            try {
                const specsResponse = await getReportSync(
                    "52aeec22-77fa-45fa-9c15-3d497a4bd096",
                    {
                        inputs: {
                            "Sampling Config ID": c.id,
                        },
                    },
                    false
                );

                c.specifications =
                    transformSpecificationsToMatchFormat(specsResponse);
            } catch (error) {
                console.error("Error fetching specs for config:", c.id, error);
                c.specifications = [];
            }

            return c;
        });

        const updatedConfigs = await Promise.all(updatedConfigsPromises);
        return updatedConfigs;
    };

    const reviewConfigFields = [
        {
            id: "name",
            label: "Report Name",
        },
        {
            id: "assigned_to",
            label: "Assigned To",
        },
        {
            id: "lab_report_required",
            label: "Lab Report Required",
            inputType: "custom",
            customDisplay: (value) => (value ? "Yes" : "No"),
        },
        {
            id: "custody_chain_required",
            label: "Chain of Custody Required",
            inputType: "custom",
            customDisplay: (value) => (value ? "Yes" : "No"),
        },
        {
            id: "report_schedule",
            label: "Report Schedule",
            inputType: "custom",
            customDisplay: (value) => {
                if (!value || Object.keys(value).length === 0) return "-";
                return scheduleToText(value) || "-";
            },
        },
    ];

    const renderConfigs = () => {
        if (!loadedData?.samplingConfigs?.length) {
            return null;
        }

        return loadedData.samplingConfigs.map((config, index) => (
            <div key={config.id || index}>
                <Accordion
                    square={true}
                    sx={{
                        margin: "2rem 0rem !important",
                        boxShadow: "none",
                        border: "1px solid lightgrey",
                        borderRadius: "0.5rem",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "center",
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon fontSize="large" />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            sx={{
                                fontSize: "2rem",
                                flexDirection: "row-reverse",
                                padding: "1rem",
                                width: "100%",
                                "& .MuiAccordionSummary-expandIconWrapper": {
                                    margin: "1.5rem",
                                },
                                "& p": {
                                    fontSize: "1.8rem",
                                },
                            }}
                        >
                            <Typography>{config.name}</Typography>
                        </AccordionSummary>
                    </Box>
                    <Divider variant="middle" />
                    <AccordionDetails>
                        <Typography component="div">
                            <SamplingConfig
                                config={config}
                                sampleReportConfigId={sampleReportConfigId}
                                readOnly={true}
                            />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        ));
    };

    return (
        <div>
            {/* Summary Grid for reportConfig */}
            <Box
                sx={{
                    border: "1px solid lightgrey",
                    borderRadius: "0.5rem",
                    padding: "2rem",
                    marginBottom: "2rem",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "1.6rem",
                        marginBottom: "1rem",
                    }}
                >
                    Report Configuration
                </Typography>
                <SummaryGrid
                    fields={reviewConfigFields}
                    formState={loadedData?.reportConfig || {}}
                    separateFields={["report_schedule"]}
                />
            </Box>

            <Box
                sx={{
                    border: "1px solid lightgrey",
                    borderRadius: "0.5rem",
                    padding: "2rem",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "1.6rem",
                        marginBottom: "1rem",
                    }}
                >
                    Sampling Configurations
                </Typography>
                {renderConfigs()}
            </Box>
        </div>
    );
};

export default withRouter(SRCReviewStep);
