/**
 * Try to extract some useful text out of a generic error object
 *
 * @param {Object | string | null} [e] - the error object
 * @returns {string | null}
 */
export const getErrorText = (e) => {
    if (!e) {
        return null;
    }

    if (typeof e === "object") {
        if (e.message) {
            // The error contains a text message, just display that
            return e.message;
        }

        if (e.status ?? e.response?.status) {
            // Try to build an error message based on status, like "403: Forbidden"
            return `Error ${e.status ?? e.response?.status}: ${
                e.statusText ?? e.response?.statusText ?? "Unknown error"
            }`;
        }
    }

    // Some other type of error, just display the raw object
    return JSON.stringify(e);
};
