import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

const DatePicker = ({
    field,
    form,
    setFieldValue,
    editMode,
    valueType,
    value,
    name,
    type,
    disablePast,
    disableFuture,
}) => {
    let initialDate;
    if (value) {
        initialDate = new Date(value);
    } else if (field && field.value) {
        initialDate = new Date(field.value);
    } else {
        initialDate = null;
    }
    if (initialDate && !initialDate.toString().includes("-") && initialDate.toString().length <= 10) {
        // moment wants shit in ms
        initialDate *= 1000;
    }
    const [selectedDate, setSelectedDate] = useState(initialDate);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (field.value && !field.value.toString().includes("-")) {
            if (field.value.toString().length <= 10) {
                if (field.value.toString() === "0") {
                    setSelectedDate(null);
                } else {
                    setSelectedDate(new Date(field.value * 1000));
                }
            }
        } else if (field.value === null) {
            setSelectedDate(null);
        }
    }, [field]);

    useEffect(() => {
        if (type === "noTextInput") {
            setSelectedDate(new Date(value));
        }
    }, [value]);

    const handleDateChange = (date) => {
        if (date == null) {
            setFieldValue(field.name, null);
            setSelectedDate(null);
            return;
        }
        // set our date object to midnight local time since we only care about the day not the hour / min / sec
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        // if (data) due to issue of when date = null this errors.
        setIsOpen(false);
        if (date) {
            if (valueType === "epoch") {
                setSelectedDate(new Date(date));
                if (!field) {
                    setFieldValue(name, date.getTime() / 1000);
                } else {
                    setFieldValue(field.name, date.getTime() / 1000);
                }
            } else {
                let parsed = moment(date).startOf("day");
                setSelectedDate(new Date(parsed));
                if (parsed.valueOf()) {
                    setFieldValue(field.name, format(date, "yyyy-MM-dd"));
                }
            }
        }
    };

    let widgetProps = {};

    if (type === "noTextInput") {
        widgetProps.open = isOpen;
        widgetProps.KeyboardButtonProps = {
            onFocus: (e) => {
                setIsOpen(true);
            },
        };
        widgetProps.PopoverProps = {
            disableRestoreFocus: true,
            onClose: () => {
                setIsOpen(false);
            },
        };
        widgetProps.InputProps = {
            onFocus: () => {
                setIsOpen(true);
            },
        };
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiDatePicker
                format="yyyy-MM-dd"
                emptyLabel="YYYY-MM-DD"
                disableToolbar
                autoOk
                inputVariant="outlined"
                variant="inline"
                disabled={!editMode}
                name={field ? field.name : null}
                disablePast={disablePast ? true : false}
                disableFuture={disableFuture ? true : false}
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                sx={{
                    display: "block",
                    "& .MuiInputBase-input": {
                        fontSize: "1.6rem",
                    },
                }}
                {...widgetProps}
            />
        </LocalizationProvider>
    );
};

export default DatePicker;
