import { CheckRounded, CloseRounded } from "@mui/icons-material";
import styles from "../styles/common/checkmarks.module.css";
import React from "react";
import { formatDate, timestampToDate } from "./forms";

/**
 * Renders a checkmark or an X mark in place of a true/false value
 *
 * @param {boolean|"true"|"false"} value - Either a boolean or string representing true/false, anything else returns "-"
 */
export const booleanToCheckMark = (value) => {
    if (value === true || value === "true") {
        return <CheckRounded className={styles.yes} />;
    }

    if (value === false || value === "false") {
        return <CloseRounded className={styles.no} />;
    }

    return "-";
};

/**
 * Returns a function that converts a raw value returned from the reporting service to text based on the
 * options defined for a form field
 *
 * @param {formField[]} fields - Array of field options used on a form
 * @param {string} id - The identifier of the field in the array
 *
 * @returns {(value:string) => string}
 */
export const rawValueToLabel = (fields, id) => (value) =>
    fields.find((f) => f.id === id).options?.find((o) => o.value === value)?.label ?? value;

// prettier-ignore
export const defaultColumnFormats = {
    "timestamp": { format: (ts) => formatDate(timestampToDate(ts), "short"), alignment: "left" },
    "boolean": { format: booleanToCheckMark, alignment: "center" },
    "int": { alignment: "center" },
    "numeric": { alignment: "center" },
};
