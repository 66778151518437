import React, { useState } from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { createLimit } from "../../../api/referencePointsAPI";
import { getErrorText } from "../../../utils/errors";
import { BasicLimitFields } from "./LimitFields";
import useAnalytes from "../useAnalytes";
import useGeneralControls from "../GeneralControls/useGeneralControls";
import HomeForm from "../../Common/Home/HomeForm";
import LoadingSpinner from "../../Common/LoadingSpinner";

const AddLimit = ({ history }) => {
    const { id: outfallId, path: outfallPath } = getURLParentObject("o") || {};
    const { analytes, error: analyteError, loading: analyteLoading } = useAnalytes();
    const { generalControls, error: generalControlError, loading: generalControlLoading } = useGeneralControls();

    const onSubmit = (basic) =>
        createLimit(outfallId, { ...basic, calculation: "none" }).then(() => history.push(outfallPath));

    if (analyteError || generalControlError) {
        return getErrorText(analyteError || generalControlError);
    }

    if (analyteLoading || generalControlLoading) {
        return <LoadingSpinner />;
    }

    const basicFields = BasicLimitFields.map((f) =>
        f.id === "analyte"
            ? { ...f, options: analytes }
            : f.id === "general_control_uuid"
            ? { ...f, options: generalControls }
            : f
    );

    return (
        <HomeForm
            basicFields={basicFields}
            onSubmit={onSubmit}
            onCancel={() => history.goBack()}
        />
    );
};

export default withRouter(AddLimit);
