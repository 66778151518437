/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import AdditionalDetails from '../AdditionalDetails';
import AssemblyBFPStateReportFields,
{ AssemblyBFPStateReportFieldsPreprocess, AssemblyBFPStateReportFieldsPostprocess } from './AssemblyBFPStateReportFields';
import { getFormFieldSuggestedValues } from '../../utils/suggestedValues';
import { withFormik, Form, Field } from 'formik';
import MessageBox from '../MessageBox';
import DatePicker from '../DatePicker';
import * as Yup from 'yup';
import moment, * as Moment from 'moment';
import { Tooltip } from '../Tooltip';

const FormLayout = ({
	data,
	values,
	errors,
	touched,
	isSubmitting,
	editMode,
	toggleEditMode,
	resetForm,
	newAddition,
	closeFlyout,
	setFieldValue,
	apiErrors,
	messageOptions,
	context,
	duplicateCheck,
	checkReplacement,
	setInactive,
	replacement,
	replacementData,
	source
}) => {

	const [suggestedValues, setSuggestedValues] = useState({});

	useEffect(() => {
		setSuggestedValues({ "Assembly Make": getFormFieldSuggestedValues("Assembly Make") });
	}, [])

	const cancelAction = () => {
		if (newAddition) {
			resetForm();
			closeFlyout();
		} else {
			toggleEditMode();
			resetForm();
		}
	};

	const getError = (fieldname) => {
		if (apiErrors && apiErrors[fieldname]) {
			return apiErrors[fieldname];
		} else if (touched[fieldname] && errors[fieldname]) {
			return errors[fieldname];
		}
	};

	const handleFormChange = (e) => {
		// this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
		// might be set so we can check it again
		if (apiErrors && apiErrors[e.target.name]) {
			delete apiErrors[e.target.name];
		}
		if (errors[e.target.name]) {
			delete errors[e.target.name];
		}
	};

	const getFormErrors = () => {
		let errorList = [];
		messageOptions = {};

		['serial_number', 'equipment_type', 'make', 'model', 'size', 'notes', 'equipment_id', 'next_test', 'compliance_date', 'installed_on', 'first_recorded_on', 'deactivated_on', 'removed_on', 'replaced_on', 'location_notes'].forEach((field) => {
			let err = getError(field);
			if (err) {
				errorList.push(err);
			}
		});

		if (errorList.length !== 0) {
			messageOptions = {
				type: "error",
				message: "Please correct the following errors",
				list: errorList,
			};
			document.getElementById('assemblyForm').scrollIntoView();
		}

		return messageOptions;
	}

	return (
		<Form className="userDetailsForm" id="assemblyForm">
			<MessageBox options={getFormErrors()} />
			<fieldset disabled={!editMode}>
				<div className="detailsFieldset">
					<div className={'inputField medium ' + ((getError('serial_number')) ? 'formRed' : '')}>
						<label htmlFor="serial_number">Serial Number *</label>
						<Field type="text" name="serial_number" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField medium ' + ((getError('equipment_type')) ? 'formRed' : '')}>
						<label htmlFor="equipment_type">Type *</label>

						<Field component="select" name="equipment_type" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						>
							<option value="AirGap">AirGap</option>
							<option value="AVB">AVB</option>
							<option value="BB">BB</option>
							<option value="CV">CV</option>
							<option value="DC">DC</option>
							<option value="DCDA">DCDA</option>
							<option value="DCDA-II">DCDA-II</option>
							<option value="DuC">DuC</option>
							<option value="HC">HC</option>
							<option value="PVB">PVB</option>
							<option value="RP">RP</option>
							<option value="RPDA">RPDA</option>
							<option value="RPDA-II">RPDA-II</option>
							<option value="SVB">SVB</option>
							<option value="Unknown">UNKNOWN</option>
						</Field>
					</div>
					<div className={'inputField medium ' + ((getError('make')) ? 'formRed' : '')}>
						<label htmlFor="make">Make *</label>
						<Field type="text" name="make" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							{... (suggestedValues["Assembly Make"] && suggestedValues["Assembly Make"].length > 0 && { list: "make-list" })}
						/>
						{(suggestedValues["Assembly Make"] && suggestedValues["Assembly Make"].length > 0) && (
							<datalist id="make-list">
								{suggestedValues["Assembly Make"].map((suggestedValue) => {
									return (
										<option key={suggestedValue} value={suggestedValue}>{suggestedValue}</option>
									);
								})}
							</datalist>
						)}
					</div>
					<div className={'inputField medium ' + ((getError('model')) ? 'formRed' : '')}>
						<label htmlFor="model">Model *</label>
						<Field type="text" name="model" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField medium ' + ((getError('size')) ? 'formRed' : '')}>
						<label htmlFor="size">Size *</label>
						<Field component="select" name="size" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						>
							<option value=""></option>
							<option value=".25">.25</option>
							<option value=".375">.375</option>
							<option value=".5">.5</option>
							<option value=".75">.75</option>
							<option value="1">1</option>
							<option value="1.25">1.25</option>
							<option value="1.5">1.5</option>
							<option value="2">2</option>
							<option value="2.5">2.5</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="6">6</option>
							<option value="8">8</option>
							<option value="10">10</option>
							<option value="12">12</option>
						</Field>
					</div>
					<div className={'inputField medium ' + ((getError('equipment_id')) ? 'formRed' : '')}>
						<label htmlFor="equipment_id">Assembly ID</label>
						<Field type="text" name="equipment_id" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					{!newAddition && <div className={'inputField medium ' + ((getError('first_recorded_on')) ? 'formRed' : '')}>
						<label htmlFor="first_recorded_on">First Recorded On</label>
						<Field
							name="first_recorded_on"
							component={DatePicker}
							valueType="string"
							editMode={editMode}
							values={values}
							setFieldValue={setFieldValue}
						/>
					</div>}
					{(data.property_uuid || source) && <div className={'inputField medium ' + ((getError('installed_on')) ? 'formRed' : '')}>
						<label htmlFor="installed_on">Installed On</label>
						<Field
							name="installed_on"
							component={DatePicker}
							valueType="string"
							editMode={editMode}
							values={values}
							setFieldValue={setFieldValue}
						/>
					</div>}
					{data.property_uuid &&
						<React.Fragment>
							{data.deactivated_on && <div className={'inputField medium ' + ((getError('deactivated_on')) ? 'formRed' : '')}>
								<label htmlFor="deactivated_on">Set Inactive On</label>
								<Field
									name="deactivated_on"
									component={DatePicker}
									valueType="epoch"
									editMode={editMode}
									setFieldValue={setFieldValue}
								/>
							</div>}
							{data.removed_on && <div className={'inputField medium ' + ((getError('removed_on')) ? 'formRed' : '')}>
								<label htmlFor="removed_on">Removed On</label>
								<Field
									name="removed_on"
									component={DatePicker}
									valueType="epoch"
									editMode={editMode}
									setFieldValue={setFieldValue}
								/>
							</div>}
							{data.replaced_on && <div className={'inputField medium ' + ((getError('replaced_on')) ? 'formRed' : '')}>
								<label htmlFor="replaced_on">Replaced On</label>
								<Field
									name="replaced_on"
									component={DatePicker}
									valueType="epoch"
									editMode={editMode}
									setFieldValue={setFieldValue}
								/>
							</div>}
						</React.Fragment>
					}
					{(data.property_uuid || source) && <div className={'inputField long ' + ((getError('location_notes')) ? 'formRed' : '')}>
						<label htmlFor="location_notes">Assembly Location</label>
						<Field component="textarea" rows="2" name="location_notes" autoComplete="off" className="inputField__input inputField__input-first" maxLength="160"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>}
					<div className={'inputField long ' + ((getError('notes')) ? 'formRed' : '')}>
						<label htmlFor="notes">Comments</label>
						<Field component="textarea" rows="2" name="notes" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					{!newAddition && (
						<React.Fragment>
							<div className={'inputField small ' + ((getError('next_test')) ? 'formRed' : '')}>
								<label htmlFor="next_test">Next Test Due</label>
								<Field
									name="next_test"
									component={DatePicker}
									valueType="epoch"
									editMode={editMode}
									setFieldValue={setFieldValue}
								/>
							</div>
							<div className={'inputField small ' + ((getError('compliance_date')) ? 'formRed' : '')}>
								<label htmlFor="compliance_date">Compliance Date</label>
								<Tooltip text="The date that the compliance of this assembly was calculated, often this is the last test date" />
								<Field
									name="compliance_date"
									component={DatePicker}
									valueType="epoch"
									editMode={editMode}
									setFieldValue={setFieldValue}
								/>
							</div>
							<div className={'inputField small ' + ((getError('compliant')) ? 'formRed' : '')}>
								<label htmlFor="compliant">Last Test Compliance</label>
								<Tooltip text="Indicates whether the last test was compliant or not.  Note that it might say it is compliant but the assembly is still out of compliance due to the expiration date" position="top-end"/>
								<Field
									name="compliant"
									component="select"
									onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
								>
									<option value=""></option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</Field>
							</div>
						</React.Fragment>
					)}
					{editMode && (
						<React.Fragment>
							{newAddition &&
								<div>
									<input
										type="checkbox"
										name="replacement"
										id="replacement"
										onChange={checkReplacement}
										checked={replacement}
									>
									</input>
									{!replacement && <span>Replacement</span>}
									{replacement &&
										<span>Replacing Assembly #
											<a
												style={{ color: '#007AFF' }}
												href={replacementData.uuid ? `/assemblies/${replacementData.uuid}` : `/assemblies/${replacementData[0]}`}
												target="_blank"
												rel="noopener noreferrer" >
												{replacementData.uuid ? replacementData.serial_number : replacementData[5]}
											</a>
											{setInactive && ' and setting it as inactive.'}
										</span>}
								</div>}
						</React.Fragment>
					)}
				</div>
			</fieldset>
			<AdditionalDetails
				details={data.c3_additional_details}
				editMode={editMode}
			/>
			<AssemblyBFPStateReportFields
				setFieldValue={setFieldValue}
				editMode={editMode}
				values={values}
			/>
			{editMode && (
				<React.Fragment>
					<div className="formButtonsBank">
						<button className="medButtonPrimary" disabled={isSubmitting} type="submit">Save</button>
						<button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
						<button
							className="medButtonSecondary"
							type="button"
							onClick={
								(e) => {
									duplicateCheck(context, { serial_number: values.serial_number, equipment_uuid: (newAddition) ? undefined : data.equipment_uuid }, { disableSave: true });
									// we don't have any feedback if there were no dupes or what we are doing, so adding something quick here
									e.target.innerHTML = "Checking...";
									setTimeout((btn) => { btn.innerHTML = "Check Duplicates" }, 2000, e.target);
								}
							}
						>
							Check Duplicates
						</button>
					</div>
				</React.Fragment>
			)}
		</Form>
	);
};

const AssemblyDetailsForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,
	enableReinitialize: true,
	mapPropsToValues(props) {
		let values = {
			serial_number: (props.data.serial_number) ? props.data.serial_number : '',
			equipment_type: (props.data.equipment_type) ? props.data.equipment_type : 'RP',
			make: (props.data.make) ? props.data.make : '',
			model: (props.data.model) ? props.data.model : '',
			size: (props.data.size) ? props.data.size : '',
			notes: (props.data.notes) ? props.data.notes : '',
			equipment_id: (props.data.equipment_id) ? props.data.equipment_id : '',
			installed_on: (props.data.installed_on) ? Moment.unix(props.data.installed_on) : null,
			first_recorded_on: (props.data.first_recorded_on) ? Moment.unix(props.data.first_recorded_on) : null,
			deactivated_on: (props.data.deactivated_on) ? Moment.unix(props.data.deactivated_on) : null,
			removed_on: (props.data.removed_on) ? Moment.unix(props.data.removed_on) : null,
			replaced_on: (props.data.replaced_by) ? Moment.unix(props.data.removed_on) : null,
			location_notes: (props.data.location_notes) ? props.data.location_notes : '',
		};

		for (let uuid in props.data.c3_additional_details) {
			values[uuid] = (props.data.c3_additional_details[uuid].value && props.data.c3_additional_details[uuid].value !== "false")
				? props.data.c3_additional_details[uuid].value
				: '';
		}

		let sr_vals = AssemblyBFPStateReportFieldsPreprocess(props.data.sr_fields);
		for (const key in sr_vals) {
			values[key] = sr_vals[key];
		}

		values.next_test = (props.lastTest !== null) ? Moment.unix(props.lastTest.expiration_date) : null;
		values.compliance_date = (props.lastTest !== null) ? Moment.unix(props.lastTest.compliance_date) : null;
		values.compliant = (props.lastTest !== null) ? props.lastTest.compliant : "";

		return values;
	},

	validationSchema: Yup.object().shape({
		serial_number: Yup.string().required('Serial Number is required'),
		equipment_type: Yup.string().required('Equipment Type is required'),
		make: Yup.string().required('Make is required'),
		model: Yup.string().required('Model is required'),
		size: Yup.string().required('Size is required'),
		description: Yup.string(),
		equipment_id: Yup.string(),
		installed_on: Yup.string().nullable(),
		first_recorded_on: Yup.string().nullable(),
		deactivated_on: Yup.string().nullable(),
		removed_on: Yup.string().nullable(),
		replaced_on: Yup.string().nullable()
	}),

	handleSubmit(values, { props, resetForm, setSubmitting }) {
		// Formatting the dates back to unix timestamps for the backend
		const properties = {
			installed_on: values.installed_on ? Moment(values.installed_on).format('X') : null,
			deactivated_on: values.deactivated_on ? Moment(values.deactivated_on).format('X') : null,
			removed_on: values.removed_on ? Moment(values.removed_on).format('X') : null,
			notes: values.location_notes
		};

		// Looping through the properties to omit anything that hasn't changed from what came in from the db
		for (const property in properties) {
			if ((props.data[property] === Moment(values[property]).format('X')) || (values[property] === null)) {
				delete properties[property];
			}
		}

		// Since we will be (re)building the sr_fields object, first delete whatever currently exists.
		delete values.sr_fields;
		values.sr_fields = AssemblyBFPStateReportFieldsPostprocess(values);

		if (!props.newAddition) {
			props.saveDetails(props.context, { ...values, first_recorded_on: Moment(values.first_recorded_on).format('X') }, properties);
		} else {
			props.saveDetails(props.context, values, properties);
		}
		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
		}, 3000);
	}
})(FormLayout);

export default AssemblyDetailsForm;
