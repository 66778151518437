import React, { forwardRef } from "react";
import { IconButton as MuiIconButton } from "@mui/material";
import styles from "../../styles/common/matieralUI/iconButton.module.css";

/**
 * Helper component to override the MaterialUI icon buttons with our styles
 *
 * @param {JSX.Element} icon - Icon component to use on the button
 * @param {"small"|"normal"} [size] - Normal or small version of icon
 * @param props - All other props are passed to the MaterialUI <IconButton>
 */
const IconButton = forwardRef(({ icon, size, ...props }, ref) => (
    <MuiIconButton
        {...props}
        classes={{ root: size === "small" ? styles.small : styles.normal }}
        ref={ref}
    >
        {icon}
    </MuiIconButton>
));
export default IconButton;
