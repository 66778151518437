/* eslint-disable react/prop-types */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import orgLogo from '../../images/InfinityIconOrg.svg'
import addtestreport from '../../images/addtestreport.svg';
import contacts from '../../images/contacts.svg';
import dashboard from '../../images/dashboard.svg';
import dataanalysis from '../../images/dataanalysis.svg';
import devices from '../../images/devices.svg';
import locations from '../../images/locations.svg';
import serviceprovider from '../../images/serviceprovider.svg';
import settings from '../../images/settings.svg';
import users from '../../images/users.svg';
import infinityIcon from '../../images/InfinityIcon2.svg';
import { getOrganizationSystemSettings } from '../api/organizationAPI';
import { ClickAwayListener } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BallotIcon from '@mui/icons-material/Ballot';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import { StateReportSupportedStateProvs } from './StateReports/StateReportsDashboard';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

class Sidebar extends React.Component {
	// commenting the full list out until they are implemented - BC
	//sidebarCats = ['dashboard', 'locations', 'sp', 'assemblies', 'testreports', 'letters', 'formbuilder', 'data', 'users', 'notes', 'settings', 'help']

	sidebarOrdering = [
		'Dashboards',
		'Properties',
		'Contacts',
		'ServiceProviders',
		'Equipment',
		'ComplianceReports',
		'Surveys',
		'Reporting',
		'Users',
		'Communications',
		'StateReports',
		'Notifications',
		'Settings',
		'Help'
	];

	constructor(props) {
		super(props);

		this.state = {
			sidebarItems: []
		};
	}

	async componentDidMount() {
		getOrganizationSystemSettings().then(orgSettings => {
			let sidebarItems = [];
			try {
				let perms = SwiftComply.sections;
				this.sidebarOrdering.forEach((section) => {
					if (perms.sections[section]) {
						if (section == "StateReports") {
							if (StateReportSupportedStateProvs.includes(SwiftComply.organization.state_prov)) {
								sidebarItems.push(section);
							}
						} else {
							sidebarItems.push(section);
						}
					}
					else if (section === 'Help') {
						sidebarItems.push('Help');
					}
				});
			}
			finally {
				// if this failed I'm not actually sure what to do other than show nothing?
				this.setState({ sidebarItems });
			}
		});
	}

	slideSidebar = (type) => {
		const sidebar = document.querySelector('.sidebar');
		if (type === 'close') {
			sidebar.classList.remove('slide');
		} else if (type === 'toggle') {
			sidebar.classList.toggle('slide');
		}
	};

	collapseSidebar = (device) => {
		const sidebar = document.querySelector('.sidebar');
		const sidebarButtons = document.querySelectorAll('.sidebarText');
		const sidebarIcon = document.querySelector('.infinityIcon');
		const sidebarIconWrapper = document.querySelector('.infinityIconWrapper');
		const toggleButton = document.querySelector('.sidebarToggleButton');
		const mainContent = document.querySelector('.mainContent');

		if (device == 'mobile') {
			sidebar.classList.remove('collapse');
			sidebar.classList.toggle('slide');
		} else {
			this.setState((prevState) => ({ sidebarCollapsed: !prevState.sidebarCollapsed }), () => {
				if (this.state.sidebarCollapsed) {
					mainContent.classList.add('mainContent__condensed');
					for (let i = 0; i < sidebarButtons.length; i++) {
						sidebarButtons[i].classList.toggle('hide');
					}
				} else {
					mainContent.classList.remove('mainContent__condensed');
					for (let i = 0; i < sidebarButtons.length; i++) {
						setTimeout(() => {
							sidebarButtons[i].classList.toggle('hide');
						}, 75);
					}
				}
				sidebarIcon.classList.toggle('smallIconTransform');
				sidebar.classList.toggle('collapse');
				sidebarIconWrapper.classList.toggle('sidebarLogoDrawerAlign');
				setTimeout(() => {
					toggleButton.classList.toggle('rotate');
				}, 200);
			});
		}
	};

	render() {
		const sidebarButtons = (
			this.state.sidebarItems.map((button, index) => {
				let buttonName, buttonImg, buttonLink, altText, title, muiIcon, externalLink;

				switch (button) {
					case 'Dashboards':
						buttonName = 'Dashboard';
						buttonImg = dashboard;
						buttonLink = '/dashboard';
						altText = 'Dashboard';
						title = altText;
						break;
					case 'Properties':
						buttonName = 'Locations';
						buttonImg = locations;
						buttonLink = '/locations';
						altText = 'Properties';
						title = altText;
						break;
					case 'Contacts':
						buttonName = 'Contacts';
						buttonImg = contacts;
						buttonLink = '/contacts';
						altText = 'Contacts';
						title = altText;
						break;
					case 'ServiceProviders':
						buttonName = 'Service Providers';
						buttonImg = serviceprovider;
						buttonLink = '/serviceProviders';
						altText = 'Service Providers';
						title = altText;
						break;
					case 'Equipment':
						buttonName = 'Assemblies';
						buttonImg = devices;
						buttonLink = '/assemblies';
						altText = 'Assemblies';
						title = altText;
						break;
					case 'ComplianceReports':
						buttonName = 'Test Reports';
						buttonImg = addtestreport;
						buttonLink = '/testReports';
						altText = 'Compliance Reports';
						title = altText;
						break;
					case 'Surveys':
						buttonName = 'Surveys';
						buttonImg = null;
						buttonLink = '/surveys';
						altText = 'Surveys';
						title = altText;
						muiIcon = <BallotIcon fontSize="inherit" />;
						break;
					case 'Reporting':
						buttonName = 'Reporting';
						buttonImg = dataanalysis;
						buttonLink = '/reporting';
						altText = 'Reporting';
						title = altText;
						break;
					case 'Users':
						buttonName = 'Users';
						buttonImg = users;
						buttonLink = '/users';
						altText = 'Users';
						title = altText;
						break;
					case 'Communications':
						buttonName = 'Communications';
						buttonImg = null;
						buttonLink = '/communications/logs';
						altText = 'Communications';
						title = altText;
						muiIcon = <MailRoundedIcon fontSize="inherit" />;
						break;
					case 'StateReports':
						buttonName = 'State Reports';
						buttonImg = null;
						buttonLink = '/stateReports';
						altText = 'State Reports';
						title = altText;
						muiIcon = <AccountBalanceRoundedIcon fontSize="inherit" />;
						break;
					case 'Notifications':
						buttonName = 'Notifications';
						buttonImg = null;
						buttonLink = '/notifications';
						altText = 'Notifications';
						title = altText;
						muiIcon = <NotificationsRoundedIcon fontSize="inherit" />;
						break;
					case 'Settings':
						buttonName = 'Settings';
						buttonImg = settings;
						buttonLink = '/organization';
						altText = 'Organization Settings';
						title = altText;
						break;
					case 'Help':
						buttonName = 'Knowledge Base';
						buttonImg = null;
						buttonLink = null;
						externalLink = SwiftComply.user.user_type === 'service provider' ?
							{ pathname: 'https://intercom.help/swiftcomply/en/collections/8779349-backflow-testers' } :
							{ pathname: 'https://intercom.help/swiftcomply/en/collections/8733342-swiftcomply-backflow' };
						altText = 'Knowledge Base';
						title = altText;
						muiIcon = <SchoolRoundedIcon fontSize="inherit" />
						break;
				}
				return (
					<NavLink key={index} to={buttonLink ? buttonLink : externalLink} target={externalLink ? '_blank' : null} onClick={() => this.slideSidebar('close')} >
						<div className={this.props.location.pathname.startsWith(buttonLink) ? 'activated' : null} title={title}>
							{buttonImg && <img src={buttonImg} alt={altText} />}
							{!buttonImg && (<span className="sidebar__muiIcon">{muiIcon}</span>)}
							<span className="sidebarText">{buttonName}</span>
						</div>
					</NavLink>
				);
			})
		);

		if (this.props.location.pathname === '/') {
			return null;
		} else {

			return (
				<ClickAwayListener onClickAway={() => this.slideSidebar('close')}>
					<React.Fragment>
						<div className="sidebar">
							<button className="sidebarToggleButton-mobile__wrapper" onClick={() => this.slideSidebar('toggle')}>
								<div className='sidebarToggleButton-mobile'>
									<MenuIcon color='inherit' fontSize='inherit' />
								</div>
							</button>
							<div className="sidebar__content">
								{sidebarButtons}
							</div>
							<div className="infinityIconWrapper">
								<img className="infinityIcon" src={infinityIcon} alt='' />
							</div>
						</div>
						<button className="sidebarToggleButton__wrapper" onClick={() => this.collapseSidebar()}>
							<div className='sidebarToggleButton'>
								<ArrowBackIcon color='inherit' fontSize='inherit' />
							</div>
						</button>
					</React.Fragment>
				</ClickAwayListener>
			);
		}
	}
}

export default withRouter(Sidebar);
