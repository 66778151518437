import { California } from './California';
import { Washington, WashingtonGreenForm } from './Washington';
import { Colorado } from './Colorado';
import { Florida } from './Florida';
import { Michigan } from './Michigan';
import { Massachusetts, MassachusettsPersonnelLists } from './Massachusetts'

export const getBackflowStateReportLayout = (state, year) => {
    switch (state) {
        case 'CA':
            return California(year);
        case 'WA':
            return Washington(year);
        case 'CO':
            return Colorado(year);
        case 'FL':
            return Florida(year);
        case 'MI':
            return Michigan(year);
        case 'MA':
            return Massachusetts(year);
    }
    return [];
}

// Add this layout prior to the regular layout
export const getBackflowStateReportPreLayout = (state, year, data) => {
    switch (state) {
        case 'MA':
            return MassachusettsPersonnelLists(year, data);
    }
    return [];
}

// Add this layout after the regular layout
export const getBackflowStateReportAdditionalLayout = (state, year, data) => {
    switch (state) {
        case 'WA':
            return WashingtonGreenForm(year, data);
    }
    return [];
}
