import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { getURLModule, getURLParentObject } from "../../../utils/url";
import { templateTypes } from "./TemplateTypes";
import { createTemplate, getTemplate, updateTemplate } from "../../../api/templatesAPI";
import styles from "../../../styles/common/addTemplate.module.css";
import formStyles from "../../../styles/common/homeForm.module.css";
import Page from "../Page";
import Card from "../Card";
import Editor from "../Editor";
import TemplatesSidebar from "./TemplatesSidebar";
import Form from "../Forms/Form";
import Button from "../Button";

const EditTemplate = ({ history }) => {
    const [formState, setFormState] = useState({});
    const [loading, setLoading] = useState(false);
    const [initialContent, setInitialContent] = useState(null);
    const [content, setContent] = useState("");
    const [pageLoadError, setPageLoadError] = useState(null);
    const [generalError, setGeneralError] = useState(null);
    const [fieldErrors, setFieldErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const ref = useRef();
    const { slug } = getURLModule();
    const { id: templateId } = getURLParentObject("t") || {};

    useEffect(() => {
        if (!templateId) {
            return;
        }

        setLoading(true);

        getTemplate(templateId)
            .then((data) => {
                setFormState({
                    name: data.name,
                    category: data.legal_control_type,
                });

                setInitialContent(data.template);
                if (data.template_errors?.length) {
                    const generalErrs = data.template_errors.filter((f) => f.is_general_err).map((f) => f.msg);

                    setGeneralError({
                        message: (
                            <div>
                                <div>
                                    Template contains errors that may prevent it from successfully generating a rendered
                                    document. See the fields in the sidebar for more information.
                                </div>
                                {!!generalErrs?.length && (
                                    <>
                                        <div>Additional Errors:</div>
                                        <ul>
                                            {generalErrs.map((message) => (
                                                <li>{message}</li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </div>
                        ),
                        severity: "warning",
                        duration: 7000,
                    });

                    setFieldErrors(data.template_errors);
                }
            })
            .catch(setPageLoadError)
            .finally(() => setLoading(false));
    }, [templateId]);

    console.log(content);

    // Check for any content that is not whitespace
    const isEmpty = () =>
        !content
            .replaceAll(" ", "")
            .replaceAll("&nbsp;", "")
            .replaceAll(/<\/*(?:br|p)\/*>/g, "");

    const onSave = () => {
        // Clear state in case there was a previous failed submission
        setValidationErrors([]);
        setGeneralError(null);

        const formErrors = [];

        if (!formState.category) {
            formErrors.push({ id: "category", error: "This field cannot be blank" });
        }

        if (!formState.name) {
            formErrors.push({ id: "name", error: "This field cannot be blank" });
        }

        if (formErrors.length) {
            setValidationErrors(formErrors);
            setGeneralError({ message: "Please correct the highlighted fields and try again." });
            return;
        }

        if (isEmpty()) {
            setGeneralError({ message: "Can't save an empty template" });
            return;
        }

        const payload = {
            name: formState.name,
            legal_control_type: formState.category,
            template: content,
        };

        (templateId ? updateTemplate(templateId, payload) : createTemplate(payload))
            .then((response) => {
                if (response.template_errors?.length) {
                    // The template was saved correctly but with warnings, switch from "create template" mode to
                    // "edit template" mode
                    history.push(`/${slug}/cfg/t/${response.legal_control_template_uuid ?? ""}`);
                    history.go();
                } else {
                    // The template was saved with no warnings, go back to template list
                    history.push(`/${slug}/cfg/t`);
                }
            })
            .catch(console.error);
    };

    return (
        <Page loading={loading} error={pageLoadError}>
            <div className={styles.wrapper}>
                <div className={styles.cardWrapper}>
                    <Card>
                        <h2>Standard Fields</h2>
                        <Form
                            fields={[
                                {
                                    id: "category",
                                    label: "Template Category",
                                    inputType: "select",
                                    options: templateTypes,
                                    required: true,
                                },
                                {
                                    id: "name",
                                    label: "Template Name",
                                    required: true,
                                },
                            ]}
                            formState={formState}
                            onFormStateChange={setFormState}
                            validationErrors={validationErrors}
                            onValidationErrorsChange={setValidationErrors}
                            generalError={generalError}
                        />
                        <h2>Template</h2>
                        <Editor initialContent={initialContent} ref={ref} onChange={setContent} />
                        <div className={formStyles.buttonArray}>
                            <Button onClick={() => history.push(`/${slug}/cfg/t`)}>Cancel</Button>
                            <Button color="primary" onClick={onSave}>
                                Save
                            </Button>
                        </div>
                    </Card>
                </div>
                <TemplatesSidebar
                    onAddField={(...args) => ref.current?.insertField(...args)}
                    fieldErrors={fieldErrors}
                />
            </div>
        </Page>
    );
};

export default withRouter(EditTemplate);
