/**
 * Checks if a column name represents an ID or UUID field (case insensitive)
 * @param {string} columnName - The name of the column to check
 * @returns {boolean} True if the column should be hidden from view
 */
const isHiddenColumn = (col) => col.match(/[^a-z](?:uuid|id)$/i);

/**
 * Transforms a report response into AG Grid compatible format
 * @param {Object} reportResponse - The response from the reporting API
 * @returns {Array} Array of objects compatible with AG Grid
 */
export const transformReportToGridData = (reportResponse) => {
    if (!reportResponse?.outputs || !reportResponse?.rows) {
        return [];
    }

    // Get all column names from outputs (including hidden ones)
    const columnNames = reportResponse.outputs.map((output) => output.name);

    // Transform each row into an object with proper key-value pairs
    return reportResponse.rows.map((row) => {
        const rowData = {};
        row.values.forEach((value, index) => {
            // Include all data (even for hidden columns) as it might be needed later
            rowData[columnNames[index]] = value;
        });
        return rowData;
    });
};

/**
 * Creates AG Grid column definitions from report outputs
 * @param {Array} outputs - The outputs array from the report response
 * @returns {Array} Array of column definitions for AG Grid
 */
export const createColumnDefsFromReport = (outputs) => {
    if (!outputs) {
        return [];
    }

    // Filter out ID/UUID columns from view
    return outputs
        .filter((output) => !isHiddenColumn(output.name))
        .map((output) => ({
            field: output.name,
            headerName: output.name,
            // Add specific formatting for timestamp fields
            valueFormatter:
                output.type === "timestamp" && output.name !== "0"
                    ? (params) => {
                          const timestamp = parseInt(params.value);
                          return timestamp
                              ? new Date(timestamp * 1000).toLocaleString()
                              : "";
                      }
                    : undefined,
        }));
};

/**
 * Transforms sample report event results into detail grid format
 * @param {Array} sampleReportResults - Array of sample report results from the API
 * @returns {Array} Formatted data for the detail grid
 */
export const transformSampleResultsToDetailData = (sampleReportResults) => {
    if (!sampleReportResults) return [];

    return sampleReportResults.map((result) => ({
        analyte: result.analyte || "",
        result: result.sample_value ? `${result.sample_value} mg/L` : "-", // Assuming mg/L as default unit
        nd: "-", // Not provided in API response
        mdl: "-", // Not provided in API response
        mrl: "-", // Not provided in API response
        sample_type: result.sample_type || "-",
        sample_start: result.sample_start
            ? new Date(result.sample_start * 1000).toLocaleString()
            : "-",
        sample_end: result.sample_end
            ? new Date(result.sample_end * 1000).toLocaleString()
            : "-",
        qualifications: "-", // Not provided in API response
        lab_method: result.lab_method || "-",
    }));
};
