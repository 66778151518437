import React from "react";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { Autocomplete } from '@mui/material';
import styles from "../../../../styles/common/intervalField.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";
import { intervalUnits } from "../../../../utils/forms";

const IntervalField = ({ label, value, setValue, error }) => {
    const [count = "", unit = "minutes"] = value?.split(" ") ?? [];

    return (
        <FormControl error={!!error} data-testid={`${label} interval`}>
            <div className={styles.wrapper}>
                <TextField
                    label={label}
                    type="number"
                    variant="outlined"
                    value={count}
                    onChange={(event) => setValue(`${event.currentTarget.value ?? 0} ${unit}`)}
                    error={!!error}
                />
                {!!count && (
                    <Autocomplete
                        style={{ flexGrow: 1 }}
                        lable="Units"
                        options={intervalUnits}
                        value={unit}
                        onChange={(_, newUnit) => setValue(`${count} ${newUnit ?? "minutes"}`)}
                        renderInput={(params) => <TextField {...params} variant="outlined" error={!!error} />}
                        disableClearable
                    />
                )}
            </div>
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default IntervalField;
