import React, { useState, useEffect } from "react";
import Page from "../../../components/Common/Page";
import Card from "../../../components/Common/Card";
import Form from "../../../components/Common/Forms/Form";
import Button from "../../../components/Common/Button";
import ProgressBar from "../../../components/Common/Forms/ProgressBar";
import styles from "../../../styles/common/multiStepForm.module.css";
import Snackbar from "../../../components/Common/Snackbar";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import { withRouter } from "react-router-dom";

const SRCMultiStepForm = ({
    steps,
    currentStep,
    onSubmit,
    customButton,
    setCurrentStep,
    generalError,
    setgeneralError,
    loading,
    title,
    onDelete,
    showDelete,
    onCancel,
    validationErrors,
    onValidationError,
    renderExtraButtons,
    onStepChange,
    initialState,
    SRCData,
    history,
    location,
}) => {
    const [formState, setFormState] = useState(() => {
        const savedState = sessionStorage.getItem("srcFormState");
        const existingSRC = sessionStorage.getItem("existingSRC");
        const parsedExistingSRC = existingSRC ? JSON.parse(existingSRC) : {};
        const parsedSavedState = savedState ? JSON.parse(savedState) : {};

        return {
            ...parsedExistingSRC,
            ...parsedSavedState,
            report_schedule: parsedSavedState.report_schedule || parsedExistingSRC.recurrence_config || {},
        };
    });

    const [samplingConfigs, setSamplingConfigs] = useState(() => {
        const savedConfigs = sessionStorage.getItem("srcSamplingConfigs");
        return savedConfigs ? JSON.parse(savedConfigs) : initialState?.samplingConfigs || [];
    });

    const [completeState, setCompleteState] = useState(() => {
        const savedComplete = sessionStorage.getItem("srcCompleteState");
        return savedComplete
            ? JSON.parse(savedComplete)
            : {
                  reportConfig: formState,
                  samplingConfigs: samplingConfigs,
              };
    });

    useEffect(() => {
        const existingSRC = sessionStorage.getItem("existingSRC");
        const parsedExistingSRC = existingSRC ? JSON.parse(existingSRC) : {};

        sessionStorage.setItem(
            "srcFormState",
            JSON.stringify({
                ...formState,
                report_schedule: formState.report_schedule || parsedExistingSRC.recurrence_config || {},
            })
        );
    }, [formState]);

    useEffect(() => {
        sessionStorage.setItem("srcSamplingConfigs", JSON.stringify(samplingConfigs));
    }, [samplingConfigs]);

    useEffect(() => {
        setCompleteState((prevState) => {
            const newState = {
                reportConfig: formState,
                samplingConfigs: samplingConfigs.map((config) => ({
                    ...config,
                    specifications: config.specifications || [],
                })),
            };
            sessionStorage.setItem("srcCompleteState", JSON.stringify(newState));
            return newState;
        });
    }, [formState, samplingConfigs]);

    const handleFormStateChange = (updater) => {
        setFormState((prevState) => {
            const existingSRC = sessionStorage.getItem("existingSRC");
            const parsedExistingSRC = existingSRC ? JSON.parse(existingSRC) : {};

            const newState = typeof updater === "function" ? updater(prevState) : { ...prevState, ...updater };

            return {
                ...newState,
                report_schedule: newState.report_schedule || parsedExistingSRC.recurrence_config || {},
            };
        });
    };

    const handleSamplingConfigsChange = (configs) => {
        setSamplingConfigs(configs);
    };

    const renderStepContent = () => {
        const { customStep } = steps[currentStep];

        if (customStep) {
            return customStep.render({
                formState,
                onFormStateChange: handleFormStateChange,
                samplingConfigs,
                onSamplingConfigsChange: handleSamplingConfigsChange,
                completeState,
                validationErrors,
                onValidationError,
            });
        }
    };

    const handleNext = () => {
        const currentStepData = steps[currentStep];
        let fieldsToValidate = [];

        if (currentStepData.fields && currentStepData.fields.length > 0) {
            fieldsToValidate = currentStepData.fields;
        } else if (currentStepData.orderedContent && currentStepData.orderedContent.length > 0) {
            fieldsToValidate = currentStepData.orderedContent
                .filter((item) => item.type === "field" && item.content)
                .map((item) => item.content)
                .filter((field) => {
                    return !field.id.includes("SystemFields") && !field.disabled;
                });
        }

        if (fieldsToValidate.length > 0) {
            const validationErrors = fieldsToValidate.reduce((errors, field) => {
                const fieldValue = formState[field.id] || formState[field.template_string] || field.value;

                if (field.required && (!fieldValue || (typeof fieldValue === "string" && fieldValue.trim() === ""))) {
                    errors.push({
                        id: field.id,
                        error: `${field.label || "This field"} is required`,
                    });
                }
                return errors;
            }, []);

            if (validationErrors.length > 0) {
                onValidationError(validationErrors);
                return;
            }
        }

        // Clear the validation errors when moving to the next step
        onValidationError([]);

        if (currentStep === 0) {
            onSubmit(formState, true);
        } else if (currentStep < steps.length - 1) {
            setCurrentStep((prevStep) => prevStep + 1);
            onStepChange(currentStep + 1);
        } else if (currentStep === steps.length - 1) {
            // On final step, call onSubmit with current form state
            onSubmit(formState, false);
        }
    };

    const handlePrev = () => {
        if (currentStep > 0) {
            setCurrentStep((prevStep) => prevStep - 1);
            onStepChange(currentStep - 1, true);
        }
    };

    const clearStoredState = () => {
        sessionStorage.removeItem("srcFormState");
        sessionStorage.removeItem("srcSamplingConfigs");
        sessionStorage.removeItem("srcCompleteState");
        sessionStorage.removeItem("existingSRC");
    };

    useEffect(() => {
        const unlisten = history.listen((newLocation) => {
            const isLeavingForm = !newLocation.pathname.includes("addSampleReportConfig");

            if (isLeavingForm) {
                clearStoredState();
            }
        });

        return () => {
            unlisten();
            if (!location.pathname.includes("addSampleReportConfig")) {
                clearStoredState();
            }
        };
    }, [history, location]);

    const handleSaveExit = () => {
        // Call onSubmit with current form state
        onSubmit(formState, false);
    };

    const renderButtons = () => {
        const isFirstStep = currentStep === 0;
        const isLastStep = currentStep === steps.length - 1;

        return (
            <>
                <span>
                    {!isFirstStep ? (
                        <Button onClick={handlePrev} data-testid="previous-button">
                            Back
                        </Button>
                    ) : (
                        <Button onClick={onCancel} data-testid="cancel-button">
                            Cancel
                        </Button>
                    )}
                </span>
                <span className={styles.cardButtonGroup}>
                    <Button onClick={handleSaveExit} disabled={validationErrors?.length > 0}>
                        Save & Exit
                    </Button>
                    <Button
                        onClick={handleNext}
                        disabled={validationErrors?.length > 0}
                        data-testid={isLastStep ? "submit-button" : "next-button"}
                    >
                        {isLastStep ? "Finish" : "Next"}
                    </Button>
                </span>
            </>
        );
    };

    return (
        <Page loading={loading}>
            <div style={{ margin: "2rem 35rem 0rem 35rem" }}>
                <ProgressBar
                    steps={steps.length}
                    currentStep={currentStep}
                    stepTitles={steps.map((step) => step.title)}
                />
            </div>
            <Card title={steps[currentStep].title} onDelete={onDelete} showDelete={showDelete}>
                {renderStepContent()}
                <div className={styles.buttonArray}>{renderButtons()}</div>
                <Snackbar
                    message={generalError?.message}
                    onClose={() => setgeneralError(null)}
                    severity={generalError?.severity}
                    duration={generalError?.duration}
                />
            </Card>
        </Page>
    );
};

export default withRouter(SRCMultiStepForm);
