import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
	// TODO We need to check the backend or some cached state of permissions / section access here to see if they are
	// allowed, doing it is easy, but doing it in a way that react won't flip out is apparently hard

	return (
		<Route {...rest} component={(props) => {
			return (
				<Component {...props} />
			);
		}} />
	);
}; 