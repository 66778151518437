import React from "react";
import dropdown from "../../images/dropdown.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { ClickAwayListener } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "194px",
  },
  textFieldInput: {
    fontSize: "1.4rem",
  },
  checkboxLabel: {
    fontSize: "1.4rem",
  },
  checkboxLabelRoot: {
    marginLeft: "0px",
  },
  modalDialogRoot: {
    display: "grid",
  },
  // This isn't ideal however I could not find what was causing this to use so much space and making a big blob
  // of whitespace on the right of things.
  dialogContent: {
    maxWidth: "470px",
  },
});

class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      defaultSearch: props.defaultSearch
        ? props.defaultSearch.toLowerCase()
        : props.data.default_search.toLowerCase(),
      searchInput: "",
      values: {},
    };

    // for each searchable output, we will display a field for the user
    this.props.data.outputs.forEach((output) => {
      if (output.searchable) {
        let lcname = output.name.toLowerCase();
        this.state.values["search_" + lcname] = "";
        if (this.props.params.search[lcname]) {
          this.state.values["search_" + lcname] =
            this.props.params.search[lcname];
        }
      }
    });

    this.state.searchInput = this.setSearchInput(this.state.values);
  }

  displaySearchOptions = () => {
    const { classes } = this.props;
    const { defaultSearch } = this.state;

    // make a copy so our sorting doesn't impact other things.
    let outputs = JSON.parse(JSON.stringify(this.props.data.outputs));

    // the outputs I think come to us sorted already, maybe, might just be up to how the report is built, but
    // this will put the default first, and then I guess just make sure the rest are sorted
    outputs.sort(function (a, b) {
      let nameA = a.name.toLowerCase();
      let nameB = b.name.toLowerCase();

      // always put the default search term first
      if (nameA === defaultSearch) {
        return -1;
      } else if (nameB === defaultSearch) {
        return 1;
      }

      // then normal alpha sorting
      if (nameA < nameB) {
        return -1;
      } else if (nameB < nameA) {
        return 1;
      }

      return 0;
    });

    let searchFields = [];
    outputs.forEach((output) => {
      if (output.searchable) {
        let field = (
          <TextField
            key={"search-" + output.name}
            id={"search-" + output.name}
            label={
              output.name +
              (output.name.toLowerCase() === this.state.defaultSearch
                ? " (default)"
                : "")
            }
            name={"search_" + output.name}
            className={
              output.name.toLowerCase() === this.state.defaultSearch
                ? "defaultSearchInput"
                : ""
            }
            classes={{
              root: classes.formControl,
            }}
            InputProps={{
              classes: {
                input: classes.textFieldInput,
              },
            }}
            variant="outlined"
            onChange={(e) => {
              this.handleChange("search_" + output.name.toLowerCase(), e);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this.handleSave();
              }
            }}
            value={this.state.values["search_" + output.name.toLowerCase()]}
          />
        );
        searchFields.push(field);

        if (output.name.toLowerCase() === this.state.defaultSearch) {
          searchFields.push(<div key="spacer" id="spacer" />);
        }
      }
    });

    return searchFields;
  };

  // parse the search input character by character to figure out what they've entered and then return that to the
  // caller so they can set state or whatever the heck they want to do
  parseSearchBarInput = (searchInput) => {
    let values = {};
    // because I hate react, if you don't setup all the values here, it complains they went from controlled to
    // uncontrolled.
    this.props.data.outputs.forEach((output) => {
      if (output.searchable) {
        let lcname = output.name.toLowerCase();
        values["search_" + lcname] = "";
      }
    });
    let inParen = false;
    let term = "";
    let value = "";
    for (let char of searchInput) {
      // we've added state report fields as srp:field and sre:field, which messes with our parsing, but we've
      // now changed it so they get wrapped in (), and I think its safe then to say we only assume we are done
      // with the field name if we aren't in a paren.
      if (char === ":" && !inParen) {
        // searches are term:value so if we hit a : (which is unlikely in a value) we just got done parsing a term
        // so move the value we have to that
        // if we had a term with a space in it, we've probably already parsed it out so nothing to do here
        if (term === "") {
          // someone may have entered an "advanced" search by hand like foo address:bar, if so right now we
          // likely have a value of "foo address".  If this is the case, anything before the : and after a
          // space will be our term, anything before that will be the default term
          if (value.match(/ /)) {
            let split = value.split(" ");
            // slice off all but the last term and set that to our default search term
            values["search_" + this.state.defaultSearch] = split
              .slice(0, -1)
              .join(" ")
              .trim();
            // our non default term then is the last value
            term = split[split.length - 1];
          } else {
            term = value;
          }
          value = "";
        }
      } else if (char === "(") {
        // starting a term or value with a space in it
        inParen = true;
        // we have something in the value, and no search term, so this is likely starting a term with a space
        // in it, thereform we can assume the value is the default search
        if (term === "" && value !== "") {
          values["search_" + this.state.defaultSearch] = value.trim();
          value = "";
        }
      } else if (char === ")") {
        // ending a term or value with a space in it
        inParen = false;
        // they've ended a () so figure out where our value goes.  Note that if they are for some reason
        // entering a default search of something like (foo bar) because they think they need the () this will
        // not handle that correctly.  We can handle that at the end of the loop, but things like:
        // (foo bar) address:blah (contact name):john would get unweildy very fast, so probably better for users
        // that might do this to inform them that the () is only to be used around "non default" terms / values
        if (term === "") {
          term = value;
        } else {
          values["search_" + term.toLowerCase()] = value.trim();
        }
        value = "";
      } else if (char === " ") {
        // we hit a space, so if we are in parens, its just part of the value, if we aren't, then we just moved
        // on to the next term:value combo.  However, if we have no term we are just going to assume the user
        // entered a search term with a space in it
        if (inParen || term === "") {
          value += char;
        } else {
          // special case, if we have no term set, this is the default search
          if (term === "") {
            values["search_" + this.state.defaultSearch] = value.trim();
          } else {
            values["search_" + term.toLowerCase()] = value.trim();
          }
          term = "";
          value = "";
        }
      } else {
        value += char;
      }
    }
    // deal with any remaining value.  If were were in a paren, its likely this was a term that was never finished
    // also remember this gets called onChange of the input so we will be parsing unfinished data.
    if (value !== "" && !inParen) {
      if (term === "") {
        values["search_" + this.state.defaultSearch] = value.trim();
      } else {
        values["search_" + term.toLowerCase()] = value.trim();
      }
    }
    return values;
  };

  // kind of the opposite of above, if we are using the advanced option drop down thing, when we are done we want to
  // parse the search term state and set our search input to what
  setSearchInput = (searchValues) => {
    let terms = [];
    for (let term in searchValues) {
      let value = searchValues[term];
      if (term.startsWith("search_")) {
        // remove the search_ prefix since users don't use that
        // 2022-08-26 removed the extra replace of any _ here as I am not certain what that was for, no search
        // 		terms seem to have that, and if they did it would fail if we remove the _, maybe it was in case
        // 		a user tries to use a backend term like account_number, but we don't expose them like that so
        //		it shouldn't be an issue.  If we do find a place we need to do it, then this likely needs to
        //		change to only do that if the term doens't start with srp or sre
        let userTerm = term.replace("search_", "");

        if (value !== "") {
          // both fields and values need to be wrapped in () if they contain a space, mostly because "" we
          // treat as an exact match (I think)
          if (value.match(/ /)) {
            value = "(" + value + ")";
          }
          if (userTerm.match(/ /)) {
            userTerm = "(" + userTerm + ")";
          } else if (
            userTerm.startsWith("srp:") ||
            userTerm.startsWith("sre:")
          ) {
            // state report fields have a : in them which we normally use to split the field and value
            // because of this we are going to always wrap them in () since the parsing was changed to not
            // split on : if we are in parens
            userTerm = "(" + userTerm + ")";
          }
          if (userTerm === this.state.defaultSearch) {
            terms.push(value);
          } else {
            terms.push(userTerm + ":" + value);
          }
        }
      }
    }
    return terms.join(" ");
  };

  handleChange = (name, e) => {
    let values = this.state.values;
    let searchInput = this.state.searchInput;
    if (e.target.type === "checkbox") {
      values[name.toLowerCase()] = !values[name.toLowerCase()];
    } else if (name === "searchInput") {
      searchInput = e.target.value;
      values = this.parseSearchBarInput(searchInput);
      if (searchInput === "") {
        // if they removed everything, see if we should reload the table after 2 seconds
        setTimeout(this.handleBackspaceTimeout, 2000);
      }
    } else {
      values[name.toLowerCase()] = e.target.value;
      searchInput = this.setSearchInput(values);
    }
    this.setState({ values, searchInput });
  };

  handleSave = () => {
    this.props.setTableFilters(this.state.values);
    this.toggleDropdown("close");
  };

  handleClear = () => {
    let values = {};
    for (let term in this.state.values) {
      values[term] = "";
    }
    this.setState({ values, searchInput: "" }, () => {
      if (!this.props.widget) {
        this.handleSave();
      }
    });
  };

  // when a user backspaces over all their input, it could be confusing if they leave and come back as to why they
  // only see a few things, so this handler checks to see if its still blank, and if it is calls handleSave to
  // essentially re-search using nothing so we get all the results.  If it isn't blank we do nothing.
  handleBackspaceTimeout = () => {
    if (this.state.searchInput === "") {
      this.handleSave();
    }
  };

  toggleDropdown = (type) => {
    if (type === "close") {
      this.setState(() => ({ dropdownOpen: false }));
    } else {
      this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
    }
  };

  displayClearIcon = () => {
    for (const value in this.state.values) {
      if (this.state.values[value] != "" && value.startsWith("search")) {
        return (
          <button
            onClick={this.handleClear}
            className="clearIcon"
            title="Clear filters"
          >
            <ClearIcon fontSize={"large"} />
          </button>
        );
      }
    }
  };

  render() {
    const { dropdownOpen } = this.state;

    if (this.props.widget) {
      return (
        <div className="searchBar__dropdownContent">
          {this.displaySearchOptions()}
        </div>
      );
    }

    return (
      <React.Fragment>
        <ClickAwayListener onClickAway={() => this.toggleDropdown("close")}>
          <div
            className={
              dropdownOpen
                ? "searchBarWrapper openBorderRadius"
                : "searchBarWrapper"
            }
          >
            <div className="searchBar">
              <button
                className="searchBar__searchButton"
                onClick={this.handleSave}
              >
                <SearchIcon fontSize={"large"} />
              </button>
              <input
                id="searchInput"
                value={this.state.searchInput}
                onChange={(e) => {
                  this.handleChange("searchInput", e);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.handleSave();
                  }
                }}
                autoFocus
                data-testid="searchInput"
              />
              {this.displayClearIcon()}
              <button
                className="searchBar__dropdownButton"
                onClick={() => this.toggleDropdown()}
              >
                <img src={dropdown} alt="dropdown" />
              </button>
            </div>
            {dropdownOpen && (
              <div className="searchBar__dropdownContent-wrapper">
                <div className="searchBar__dropdownContent">
                  {this.displaySearchOptions()}
                  <div className="searchBar__buttons">
                    <button
                      className="smallButtonPrimary"
                      onClick={this.handleSave}
                    >
                      Save
                    </button>
                    <button
                      className="smallButtonSecondary"
                      onClick={this.handleClear}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ClickAwayListener>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SearchBar);
