import React from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

const MessageBox = ({
	options
}) => {

	// if no options were passed in return null here since proceeding will fail
	if (options === undefined) {
		return null;
	}

	let contents = [];
	// message displays "up top" beside the icon
	if (options.message !== undefined && options.message !== "") {
		// allow for non list messages to be multiple lines
		let message = options.message.split("\n").map((line, idx) => <span key={idx}>{line}<br /></span>);
		contents.push(<span className="message-box-message" key="message">{message}</span>);
	}
	// if passed in a list is put as an unordered list
	if (options.list !== undefined && options.list instanceof Array && options.list.length !== 0) {
		let list = [];
		options.list.forEach((error, idx) => {
			if (error !== "") {
				list.push(<li key={idx}>{error}</li>);
			}
		})
		contents.push(<div key="errorlist"><ul>{list}</ul></div>);
	}

	// if we have no message or no list of things, we really have nothing to show
	if (contents.length === 0) {
		return null;
	}

	// by default we will be informational, maybe will change this to no type = no message but shrug
	let icon = <InfoOutlinedIcon fontSize="large" color="primary" />;
	let type = (options.type !== undefined) ? options.type : "info";
	if (options.type === 'error') {
		icon = <CancelOutlinedIcon fontSize="large" style={{ color: "#FF005C" }} />;
	} else if (options.type === 'success') {
		icon = <CheckCircleOutlinedIcon fontSize="large" style={{ color: "#00D89D" }} />;
	} else if (options.type === 'warning') {
		icon = <WarningOutlinedIcon fontSize="large" style={{ color: "#FDB600" }} />;
	}

	return (
		<React.Fragment>
			<div className={'message-box ' + type}>
				<i>{icon}</i>
				{contents}
			</div>
		</React.Fragment>
	);
}

export default MessageBox;