export const stateProvLists = {
    "US": [
        { "name": "Alabama (AL)", "abbrev": "AL" },
        { "name": "Alaska (AK)", "abbrev": "AK" },
        { "name": "Arizona (AZ)", "abbrev": "AZ" },
        { "name": "Arkansas (AR)", "abbrev": "AR" },
        { "name": "California (CA)", "abbrev": "CA" },
        { "name": "Colorado (CO)", "abbrev": "CO" },
        { "name": "Connecticut (CT)", "abbrev": "CT" },
        { "name": "Delaware (DE)", "abbrev": "DE" },
        { "name": "Florida (FL)", "abbrev": "FL" },
        { "name": "Georgia (GA)", "abbrev": "GA" },
        { "name": "Hawaii (HI)", "abbrev": "HI" },
        { "name": "Idaho (ID)", "abbrev": "ID" },
        { "name": "Illinois (IL)", "abbrev": "IL" },
        { "name": "Indiana (IN)", "abbrev": "IN" },
        { "name": "Iowa (IA)", "abbrev": "IA" },
        { "name": "Kansas (KS)", "abbrev": "KS" },
        { "name": "Kentucky (KY)", "abbrev": "KY" },
        { "name": "Louisiana (LA)", "abbrev": "LA" },
        { "name": "Maine (ME)", "abbrev": "ME" },
        { "name": "Maryland (MD)", "abbrev": "MD" },
        { "name": "Massachusetts (MA)", "abbrev": "MA" },
        { "name": "Michigan (MI)", "abbrev": "MI" },
        { "name": "Minnesota (MN)", "abbrev": "MN" },
        { "name": "Mississippi (MS)", "abbrev": "MS" },
        { "name": "Missouri (MO)", "abbrev": "MO" },
        { "name": "Montana (MT)", "abbrev": "MT" },
        { "name": "Nebraska (NE)", "abbrev": "NE" },
        { "name": "Nevada (NV)", "abbrev": "NV" },
        { "name": "New Hampshire (NH)", "abbrev": "NH" },
        { "name": "New Jersey (NJ)", "abbrev": "NJ" },
        { "name": "New Mexico (NM)", "abbrev": "NM" },
        { "name": "New York (NY)", "abbrev": "NY" },
        { "name": "North Carolina (NC)", "abbrev": "NC" },
        { "name": "North Dakota (ND)", "abbrev": "ND" },
        { "name": "Ohio (OH)", "abbrev": "OH" },
        { "name": "Oklahoma (OK)", "abbrev": "OK" },
        { "name": "Oregon (OR)", "abbrev": "OR" },
        { "name": "Pennsylvania (PA)", "abbrev": "PA" },
        { "name": "Rhode Island (RI)", "abbrev": "RI" },
        { "name": "South Carolina (SC)", "abbrev": "SC" },
        { "name": "South Dakota (SD)", "abbrev": "SD" },
        { "name": "Tennessee (TN)", "abbrev": "TN" },
        { "name": "Texas (TX)", "abbrev": "TX" },
        { "name": "Utah (UT)", "abbrev": "UT" },
        { "name": "Vermont (VT)", "abbrev": "VT" },
        { "name": "Virginia (VA)", "abbrev": "VA" },
        { "name": "Washington (WA)", "abbrev": "WA" },
        { "name": "Washington D.C. (DC)", "abbrev": "DC" },
        { "name": "West Virginia (WV)", "abbrev": "WV" },
        { "name": "Wisconsin (WI)", "abbrev": "WI" },
        { "name": "Wyoming (WY)", "abbrev": "WY" },
        { "name": "Armed Forces Americas (AA)", "abbrev": "AA" },
        { "name": "Armed Forces EMEA + Canada (AE)", "abbrev": "AE" },
        { "name": "Armed Forces Pacific (AP)", "abbrev": "AP" }
    ],
    "CA": [
        { "name": "Alberta (AB)", "abbrev": "AB" },
        { "name": "British Columbia (BC)", "abbrev": "BC" },
        { "name": "Manitoba (MB)", "abbrev": "MB" },
        { "name": "New Brunswick (NB)", "abbrev": "NB" },
        { "name": "Newfoundland and Labrador (NL)", "abbrev": "NL" },
        { "name": "Nova Scotia (NS)", "abbrev": "NS" },
        { "name": "Ontario (ON)", "abbrev": "ON" },
        { "name": "Prince Edward Island (PE)", "abbrev": "PE" },
        { "name": "Quebec (QC)", "abbrev": "QC" },
        { "name": "Saskatchewan (SK)", "abbrev": "SK" }
    ]
}
