import React, { useState, useEffect } from 'react';
import CommLogContactDetailsForm from './CommLogContactDetailsForm';
import editModeIcon from '../../../images/editMode.svg';
import {
    markCommLogContactSent, markCommLogContactError, markCommLogContactUnsent,
    deleteCommLogContact, getCommLogContact, rebuildCommLogContactInfo
} from '../../api/communicationsAPI';
import { getReport } from '../../api/reportingAPI';
import { getReportValue, reportRowToObject } from '../../utils/reporting';
import { ModalDuplicateContext } from '../../contexts/ModalDuplicateContext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import TestEmailButton from './TestEmailButton';
import { ClickAwayListener } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import queryString from 'query-string';
import Flyout from '../Flyout';
import { Tooltip } from '../Tooltip';

const CommLogContactDetails = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [data, setData] = useState({});
    const [apiErrors, setApiErrors] = useState({});
    const [formErrors, setFormErrors] = useState();
    const [trackingInfo, setTrackingInfo] = useState('');
    const [trackingInputDropdown, setTrackingInputDropdown] = useState(false);
    const [errorInfo, setErrorInfo] = useState('');
    const [errorInputDropdown, setErrorInputDropdown] = useState(false);
    const [rebuiltCooldown, setRebuiltCooldown] = useState(false);
    const [flyoutActive, setFlyoutActive] = useState(false);

    useEffect(() => {
        // setData(props.data);
    }, [props.uuid]);

    useEffect(() => {
        getLogContactData();
        props.setHeader('Communication Log Contact')
    }, [])

    const getLogContactData = async () => {
        const logContactData = await getCommLogContact(props.data.communication_log_uuid, props.data.contact_uuid, props.data.method ? props.data.method : props.data.contact_method);

        if (logContactData) {
            logContactData.info = JSON.parse(logContactData.info);
            setData(logContactData)
        }
    };

    const prepEmail = async () => {
        let communication_type;

        if (props.data.communication_type) {
            communication_type = props.data.communication_type;
        } else if (props.reportResponse && props.reportResponse.communication_type) {
            communication_type = props.reportResponse.communication_type;
        } else if (props.commData && props.commData.communication_type) {
            communication_type = props.commData.communication_type;
        }

        await props.sendEmail(data.contact_uuid, communication_type, data.communication_log_uuid)
        setTimeout(() => {
            getLogContactData();
            props.refreshDashboard?.()
            props.refresh();
        }, [3000])
    }

    const toggleEditMode = () => {
        setEditMode(!editMode);
        setDeleteMode(false);
    };

    const deleteThisCommContact = async (type) => {
        if (type === 'delete') {
            await deleteCommLogContact(data['communication_log_uuid'], data['contact_uuid'], data['method']);
            if (props.refreshDashboard) {
                await props.refreshDashboard();
            } else {
                await props.refresh();
            }
            await props.closeFlyout();
        } else if (type === 'cancel') {
            setDeleteMode(false);
        } else {
            setDeleteMode(true);
            setTimeout(() => {
                document.getElementById('flyoutBase').scrollIntoView(false);
            }, 100);
        }
    };

    const toggleSent = async (type) => {
        if (type === 'send') {
            await markCommLogContactSent(data.communication_log_uuid, data.contact_uuid, data.method, { tracking: trackingInfo });
            setTrackingInputDropdown(false);
            getLogContactData();
            props.refreshDashboard?.()
            await props.refresh();

        } else if (type === 'unsend') {
            await markCommLogContactUnsent(data.communication_log_uuid, data.contact_uuid, data.method);
            getLogContactData();
            props.refreshDashboard?.()
            await props.refresh();
        }
    }

    const toggleReturned = async (type) => {
        if (type === 'returned') {
            await markCommLogContactError(data.communication_log_uuid, data.contact_uuid, data.method, { error: errorInfo });
            setErrorInputDropdown(false);
            getLogContactData();
            props.refreshDashboard?.()
            await props.refresh();
        }
    }

    const toggleTrackingInput = (type) => {
        const picker = document.getElementsByClassName('MuiPopover-root');
        if (type === 'close') {
            if (picker.length) {
                return null;
            } else {
                setTrackingInputDropdown(false);
                setTrackingInfo('');
            }
        } else if (type === 'sent') {
            setSentCooldown(false);
            setTrackingInputDropdown(false);
            setTrackingInfo('');
        } else {
            setTrackingInputDropdown(!trackingInputDropdown)
        }
    }

    const toggleErrorInput = (type) => {
        const picker = document.getElementsByClassName('MuiPopover-root');
        if (type === 'close') {
            if (picker.length) {
                return null;
            } else {
                setErrorInputDropdown(false);
                setErrorInfo('');
            }
        } else if (type === 'returned') {
            setSentCooldown(false);
            setErrorInputDropdown(false);
            setErrorInfo('');
        } else {
            setErrorInputDropdown(!errorInputDropdown)
        }
    }

    const downloadLog = async () => {
        let commType;

        if (props.commInfo && props.commInfo.communication_type) {
            commType = props.commInfo.communication_type
        } else if (props.data && props.data.communication_type) {
            commType = props.data.communication_type
        } else if (props.commData && props.commData.communication_type) {
            commType = props.commData.communication_type
        } else {
            commType = props.reportResponse.communication_type
        }

        let query = queryString.stringify({
            communication_type: commType,
            communication_log_uuid: data.communication_log_uuid,
            contact_uuid: data.contact_uuid,
            method: data.method
        });
        window.open("/service/communications/download?" + query, "_blank");
    }

    const rebuildInfo = async () => {
        const rebuildResponse = await rebuildCommLogContactInfo(props.data.communication_log_uuid, props.data.contact_uuid, props.data.method ? props.data.method : props.data.contact_method);
        if (rebuildResponse) {
            setRebuiltCooldown(true);
            setTimeout(() => {
                setRebuiltCooldown(false);
                getLogContactData();
            }, 2000)
        }
    }

    const openFlyout = () => {
        const overlay = document.getElementById('overlaySecondary');
        // setLogContactInfo(payload);
        setFlyoutActive(true);
        overlay.classList.add('overlaySecondary__visible');
    }

    const closeFlyout = () => {
        const flyoutSecondary = document.getElementById('flyoutSecondary');
        const overlay = document.getElementById('overlaySecondary');

        flyoutSecondary.classList.remove('slideToVisible');
        overlay.classList.remove('overlaySecondary__visible');
        // getCommLogData();
        setTimeout(() => {
            setFlyoutActive(false)
        }, 200);
    }

    return (
        <ModalDuplicateContext.Consumer>
            {(ctx) => {
                return (
                    <div className="flyoutContentContainer" id="flyoutBase">
                        <CommLogContactDetailsForm
                            data={data}
                            context={ctx}
                            editMode={editMode}
                            toggleEditMode={toggleEditMode}
                            newAddition={props.newAddition}
                            closeFlyout={props.closeFlyout}
                            openFlyout={props.openFlyout}
                            apiErrors={apiErrors}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                        />
                        {props.source !== 'commLog' &&
                            <span className='locationAssemblyInfo__inputs-subtext'>
                                <button className="buttonSecondary" onClick={() => openFlyout()}>Go to Communication Log</button>
                            </span>
                        }
                        {!props.newAddition &&
                            <React.Fragment>
                                <div className="flexButtonContainer" style={{ marginTop: "0" }} id="logContactButtons">
                                    {data.method !== 'email' &&
                                        <React.Fragment>
                                            <div>
                                                <button
                                                    className="medButtonPrimary"
                                                    onClick={() => { downloadLog() }}>
                                                    Download
                                                </button>
                                            </div>
                                            {(data.status === 'pending' || data.status === 'error') &&
                                                <ClickAwayListener onClickAway={() => toggleTrackingInput('close')}>
                                                    <div className={trackingInputDropdown ? 'slideDown' : ''}>
                                                        <div>
                                                            <button
                                                                className="medButtonSecondary"
                                                                onClick={() => { toggleTrackingInput() }}>
                                                                Mark as Sent
                                                            </button>
                                                        </div>
                                                        {trackingInputDropdown &&
                                                            <div className='tableFilter__dropdownContent-wrapper'>
                                                                <div className='tableFilter__dropdownContent'>
                                                                    <div className='tableFilter__content'>
                                                                        <TextField
                                                                            variant='outlined'
                                                                            id='trackingInfo'
                                                                            name='trackingInfo'
                                                                            label="Tracking Info"
                                                                            onChange={(e) => setTrackingInfo(e.target.value)}
                                                                        >
                                                                        </TextField>
                                                                    </div>
                                                                    <div className='tableFilter__buttons'>
                                                                        <button className="smallButtonPrimary" onClick={() => toggleSent('send')}>Mark As Sent</button>
                                                                        <button className="smallButtonSecondary" onClick={() => toggleTrackingInput('close')}>Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </ClickAwayListener>
                                            }
                                            {data.status === 'sent' &&
                                                <ClickAwayListener onClickAway={() => toggleErrorInput('close')}>
                                                    <div className={errorInputDropdown ? 'slideDown' : ''} id='markAsReturnedInput'>
                                                        <div>
                                                            <button
                                                                className="medButtonSecondary"
                                                                onClick={() => { toggleErrorInput() }}>
                                                                Mark as Returned
                                                            </button>
                                                        </div>
                                                        {errorInputDropdown &&
                                                            <div className='tableFilter__dropdownContent-wrapper'>
                                                                <div className='tableFilter__dropdownContent'>
                                                                    <div className='tableFilter__content'>
                                                                        <TextField
                                                                            variant='outlined'
                                                                            id='errorInfo'
                                                                            name='errorInfo'
                                                                            label="Reason for Return"
                                                                            onChange={(e) => setErrorInfo(e.target.value)}
                                                                        >
                                                                        </TextField>
                                                                    </div>
                                                                    <div className='tableFilter__buttons'>
                                                                        <button className="smallButtonPrimary" onClick={() => toggleReturned('returned')}>Save</button>
                                                                        <button className="smallButtonSecondary" onClick={() => toggleErrorInput('close')}>Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </ClickAwayListener>
                                            }
                                        </React.Fragment>
                                    }
                                    {data.method === 'email' &&
                                        <React.Fragment>
                                            <TestEmailButton data={data} />
                                            <button
                                                className="medButtonPrimary"
                                                onClick={() => prepEmail()}
                                            >
                                                {props.sentCooldown ? 'Sent!' : data.status === 'sent' ? 'Re-Send Email' : 'Send Email'}
                                            </button>
                                        </React.Fragment>
                                    }
                                    {(data.status === 'sent' || data.status === 'error') &&
                                        <button
                                            className="medButtonSecondary"
                                            onClick={() => toggleSent('unsend')}>
                                            Mark as Unsent
                                        </button>
                                    }
                                </div>
                                <div className="overlaySecondary" id="overlaySecondary" onClick={() => { closeFlyout() }}></div>
                                {flyoutActive && (
                                    <React.Fragment>
                                        <Flyout
                                            data={props.data}
                                            source='commLogContact'
                                            flyoutType='commLogs'
                                            flyoutPriority='secondary'
                                            closeFlyout={closeFlyout}
                                            closeSecondaryFlyout={props.closeFlyout}
                                            openFlyout={openFlyout}
                                            activeTab='commLogDetails'
                                            newAddition={false}
                                            sendEmail={props.sendEmail}
                                            sentCooldown={props.sentCooldown}
                                            refreshDashboard={props.refresh}
                                            refresh={getLogContactData}
                                        />
                                    </React.Fragment>
                                )}
                                {data.status === 'pending' &&
                                    <div className="formButtonsBank">
                                        <button className="medButtonSecondary" onClick={() => rebuildInfo()}>
                                            <Tooltip text="Rebuilds communication with updated contact details if the record has been changed" />
                                            {rebuiltCooldown ? 'Rebuilt!' : 'Rebuild Info'}
                                        </button>
                                        <button className="medButtonSecondary" onClick={() => deleteThisCommContact('delete')}>Remove Contact</button>
                                    </div>
                                }
                            </React.Fragment>
                        }
                        {/*!deleteMode && !props.newAddition && (
                            <div className="deleteButton__container">
                                <button className="deleteButton" onClick={() => deleteThisCommContact()}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
                            </div>
                        )}
                        {deleteMode && (
                            <div className="flyout__deleteMode">
                                <span>Warning: These actions cannot be undone.</span>
                                <button className="medButtonSecondary" type="button" onClick={() => deleteThisCommContact('delete')}>Delete Contact</button>
                                <button className="exit" onClick={() => deleteThisCommContact('cancel')}><CloseIcon fontSize="large" /></button>
                            </div>
                        )*/}
                    </div>
                );
            }}
        </ModalDuplicateContext.Consumer>
    );
};

export default CommLogContactDetails;
