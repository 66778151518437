import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { BasicSampleReportConfigFields } from "./BasicSampleReportConfigFields";
import { getErrorText } from "../../../utils/errors";
import useGeneralControls from "../GeneralControls/useGeneralControls";
import Form from "../../Common/Forms/Form";
import LoadingSpinner from "../../Common/LoadingSpinner";

const ReportConfigurationStep = ({
    formState,
    onFormStateChange,
    completeState,
    validationErrors,
    onValidationError,
}) => {
    const { generalControls, error: generalControlError, loading: generalControlLoading } = useGeneralControls();

    useEffect(() => {
        // Load data from existingSRC
        const existingSRC = sessionStorage.getItem("existingSRC");
        if (existingSRC) {
            const parsedData = JSON.parse(existingSRC);

            let report_schedule = {};

            // First check if report_schedule exists directly
            if (parsedData.report_schedule) {
                report_schedule = parsedData.report_schedule;
            }
            // Then check for recurrence_config
            else if (parsedData.recurrence_config) {
                report_schedule = {
                    schedule_type: "rep",
                    frequency: parsedData.recurrence_config.frequency,
                    interval: parsedData.recurrence_config.interval,
                    start: parsedData.recurrence_config.start,
                    until: parsedData.recurrence_config.until,
                };
            }
            // Finally check for ad_hoc_dates
            else if (parsedData.ad_hoc_dates && parsedData.ad_hoc_dates.length > 0) {
                report_schedule = {
                    schedule_type: "one",
                    ad_hoc_date: parsedData.ad_hoc_dates[0],
                };
            }

            onFormStateChange({
                name: parsedData.name,
                assigned_to: parsedData.assigned_to,
                control_type: parsedData.control_type,
                lab_report_required: parsedData.lab_report_required,
                custody_chain_required: parsedData.custody_chain_required,
                general_control_uuid: parsedData.general_control_uuid,
                report_schedule: report_schedule,
                sample_report_config_id: parsedData.sample_report_config_id,
                additional_requirements: parsedData.additional_requirements || "",
                compliance_determinants: parsedData.compliance_determinants || [],
            });
        } else if (completeState?.reportConfig && Object.keys(completeState.reportConfig).length > 0) {
            onFormStateChange(completeState.reportConfig);
        }
    }, []);

    if (generalControlError) {
        return getErrorText(generalControlError);
    }

    if (generalControlLoading) {
        return <LoadingSpinner />;
    }

    const handleFormChange = (updater) => {
        if (typeof updater === "function") {
            onFormStateChange((prevState) => ({
                ...prevState,
                ...updater(prevState),
            }));
        } else {
            onFormStateChange((prevState) => ({
                ...prevState,
                ...updater,
            }));
        }
    };

    const fields = BasicSampleReportConfigFields.map((f) =>
        f.id === "general_control_uuid" ? { ...f, options: generalControls } : f
    );

    return (
        <Form
            fields={fields}
            formState={formState}
            preloadedBasicState={{ general_control: null }}
            onFormStateChange={handleFormChange}
            validationErrors={validationErrors}
            onValidationErrorsChange={onValidationError}
        />
    );
};

export default withRouter(ReportConfigurationStep);
