import React from "react";
import { FormControl, FormHelperText, TextField, Checkbox } from "@mui/material";
import { Autocomplete } from "@mui/material";
import styles from "../../../../styles/common/matieralUI/autoComplete.module.css";
import chipStyles from "../../../../styles/common/matieralUI/chip.module.css";
import checkboxStyles from "../../../../styles/common/matieralUI/checkbox.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";

const MultiSelectField = ({ label, options, setValue, value, error }) => {
    // Convert value array to array of full option objects
    const selectedOptions = (value || []).map((v) => options.find((opt) => opt.value === v)).filter(Boolean);

    return (
        <FormControl error={!!error}>
            <Autocomplete
                multiple
                disableCloseOnSelect
                disableClearable
                classes={styles}
                options={options}
                value={selectedOptions}
                ChipProps={{ classes: chipStyles }}
                onChange={(_, newValue) => {
                    // Extract just the values from the selected options
                    setValue(newValue.map((option) => option.value));
                }}
                renderInput={(params) => <TextField {...params} label={label} variant="outlined" error={!!error} />}
                getOptionLabel={(option) => option?.label ?? ""}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox classes={checkboxStyles} checked={selected} />
                        {option.label}
                    </li>
                )}
                isOptionEqualToValue={(option, value) => option.value === value.value}
            />
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default MultiSelectField;
