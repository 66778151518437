import React, { useEffect, useState } from 'react';
import { listComplianceSettings, listComplianceRules, getComplianceAutoAcceptSettings } from '../../api/complianceAPI';
import BasicTable from '../BasicTable';
import { checkForPrice } from '../../api/paymentsAPI';
import LoaderOverlay from '../LoaderOverlay';

export default function OrgSettingsAndRules(props) {

    const [settingsList, setSettingsList] = useState(null);
    const [rulesList, setRulesList] = useState(null);
    const [rulesTable, setRulesTable] = useState(null);
    const [autoAcceptSettings, setAutoAcceptSettings] = useState([]);
    const [price, setPrice] = useState(null);

    useEffect(() => {
        getSettingsAndRules();
        // getBackflowReportPrice();
        getComplianceAutoAcceptSettings().then(response => { setAutoAcceptSettings(response.settings) })
    }, [])

    const getSettingsAndRules = async () => {
        const settingsResponse = await listComplianceSettings();
        const rulesResponse = await listComplianceRules();
        let groupedSettings = _.groupBy(settingsResponse.settings, "compliance_type")
        let groupedRules = _.groupBy(rulesResponse.rules, "compliance_settings_uuid")
        setSettingsList(groupedSettings);
        setRulesList(groupedRules);
    }

    const setSettingsTables = () => {
        let tables = [];

        ['Assembly Test', 'Survey', 'Test Kit Calibration'].forEach((type, idx) => {
            tables.push(
                <div className="mainTableContainer" key={idx}>
                    <div style={{ color: '#506788', display: 'flex', justifyContent: 'space-between' }}>
                        <span>{`${type} Settings`}</span>
                        {/* type === 'Assembly Test' && price && <span>{`Price: ${price}`}</span> */}
                    </div>
                    <BasicTable
                        tableData={settingsList[type]}
                        tableType={type + " Settings"}
                        dataSource="list"
                        columns={settingsListColumns(type)}
                        sortable={false}
                        openFlyout={(row) => {
                            setRulesTables(row['Compliance Setting UUID'], (type === 'Assembly Test' ? row['Type'] : type))
                        }}
                    />
                </div>
            )
        })
        return tables;
    }

    const setRulesTables = (settingsUUID, header) => {
        let rules = [];
        Object.keys(rulesList).forEach((setting, idx) => {
            if (setting === settingsUUID) {
                rulesList[setting].forEach((rule) => {
                    Object.keys(rule).forEach((key) => {
                        if (rule[key] === null) {
                            rule[key] = '';
                        } else if (key === "override_rules" && Array.isArray(rule[key])) {
                            // override_rules is an array of rule uuids that this rule overrides, so we have to loop
                            // over our overrides, then file that rule uuid in the rulesList and put that form field type
                            // in a new array that we will join to display to the user
                            let override = [];
                            rule[key].forEach((ruleUUID) => {
                                rulesList[setting].forEach((overriddenRule) => {
                                    if (overriddenRule.compliance_rule_uuid === ruleUUID) {
                                        override.push(overriddenRule.form_field_type);
                                    }
                                });
                            });
                            rule[key] = override.join(", ");
                        }
                    })
                })
                rules.push(
                    <div className="mainTableContainer" key={idx}>
                        <span style={{ color: '#506788' }}>{`${header} Rules`}</span>
                        <div key={idx}>
                            <BasicTable
                                tableData={rulesList[setting]}
                                tableType="Rules List"
                                dataSource="list"
                                columns={rulesListColumns(header)}
                                clickable={false}
                                sortable={false}
                            />
                        </div>
                    </div>
                )
            }
        })
        setRulesTable(rules);
    }

    const getBackflowReportPrice = async () => {
        const price = await checkForPrice('Backflow Assembly Test');
        setPrice((parseInt(price.price) / 100).toFixed(2));
    }

    // if the org has auto accept settings, show the table that displays the basic true / false values first, then
    // another that will show any specific targeting
    const displayAutoAcceptSettingsTable = () => {
        let autoAcceptTargetingRules = [];
        autoAcceptSettings.forEach(setting => {
            if (setting.property_rules.length > 0) {
                setting.property_rules.forEach(ruleGroup => {
                    let rulesText = []
                    ruleGroup.rules.forEach(rule => {
                        rulesText.push(rule.field.replace("addl:", "") + ": " + rule.values.join(", "))
                    });
                    autoAcceptTargetingRules.push({ form: setting.form_type, type: "property", rules: rulesText.join(" AND ") });
                });
            }
            if (setting.equipment_rules.length > 0) {
                setting.equipment_rules.forEach(ruleGroup => {
                    let rulesText = []
                    ruleGroup.rules.forEach(rule => {
                        rulesText.push(rule.field.replace("addl:", "") + ": " + rule.values.join(", "))
                    });
                    autoAcceptTargetingRules.push({ form: setting.form_type, type: "equipment", rules: rulesText.join(" AND ") });
                });
            }
        });

        return (
            <React.Fragment>
                <BasicTable
                    tableData={autoAcceptSettings}
                    tableType="Rules List"
                    dataSource="list"
                    columns={[
                        { displayName: "Form Type", dataName: "form_type" },
                        { displayName: "Enabled", dataName: "auto_accept_enabled" },
                        { displayName: "User Default", dataName: "default_auto_accept_users" },
                        { displayName: "Disable if Comments", dataName: "reject_comments" },
                        { displayName: "Disable if Needs Review", dataName: "reject_needs_review" },
                        { displayName: "Disable if Inactive", dataName: "reject_inactive" }
                    ]}
                    clickable={false}
                    sortable={false}
                />
                {autoAcceptTargetingRules.length > 0 && (
                    <div className="mainTableContainer">
                        <span style={{ color: '#506788' }}>{`Auto Accept Targeting Rules`}</span>
                        <div>
                            <BasicTable
                                tableData={autoAcceptTargetingRules}
                                tableType="Targeting Rules List"
                                dataSource="list"
                                columns={[
                                    { displayName: "Form", dataName: "form" },
                                    { displayName: "Type", dataName: "type" },
                                    { displayName: "Rules", dataName: "rules" }
                                ]}
                                clickable={false}
                                sortable={false}
                            />
                        </div>
                    </div>)
                }
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <span className="settingsSectionHeader">Settings</span>
            {settingsList && setSettingsTables()}
            {rulesTable && rulesTable.length !== 0 &&
                <React.Fragment>
                    <span className="settingsSectionHeader">Rules</span>
                    {rulesTable}
                </React.Fragment>
            }
            {autoAcceptSettings.length > 0 &&
                <React.Fragment>
                    <span className="settingsSectionHeader">Auto Accept Settings</span>
                    {displayAutoAcceptSettingsTable()}
                </React.Fragment>
            }
        </React.Fragment>
    )
}

const settingsListColumns = (type) => {
    const columns = [
        {
            dataName: 'source_type',
            displayName: 'Type',
            display: type === 'Assembly Test' && true
        },
        {
            dataName: 'period',
            displayName: 'Period'
        },
        {
            dataName: 'preserve_date',
            displayName: "Preserve Date"
        },
        {
            dataName: 'end_of_month_expiration',
            displayName: 'End Of Month Expiration'
        },
        {
            dataName: 'compliance_settings_uuid',
            displayName: 'Compliance Setting UUID',
            display: false
        }
    ];

    return columns;
}

const rulesListColumns = (type) => {
    const columns = [
        {
            dataName: 'form_field_type',
            displayName: 'Field Type'
        },
        {
            dataName: 'min_value',
            displayName: 'Min Value'
        },
        {
            dataName: 'max_value',
            displayName: 'Max Value'
        },
        {
            dataName: 'match',
            displayName: 'Match'
        },
        {
            dataName: 'not_match',
            displayName: 'Not Match'
        },
        {
            dataName: 'accept',
            displayName: 'Accept',
        },
        {
            dataName: 'reject',
            displayName: 'Reject'
        },
        {
            dataName: 'override_rules',
            displayName: 'Override Rules'
        },
    ]

    return columns;
}