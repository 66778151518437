import React from 'react';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import { ClickAwayListener } from '@mui/material';
import dropdown from '../../../images/dropdown.svg';
import StateReportTables from './StateReportTables';
import { stateProvLists } from '../../utils/stateProvLists';
import { runStateReport } from '../../api/statereports';
import { getOrganization } from '../../api/organizationAPI';
import ReportProblemIcon from '@mui/icons-material/ReportProblemOutlined';
import LoaderOverlay from '../LoaderOverlay';

export const StateReportSupportedStateProvs = ['CA', 'WA', 'CO', 'FL', 'MI', 'MA'];

class StateReportsDashboard extends React.Component {
    constructor(props) {
        super(props);

        // Allow state reports to be run from 2020 through the current year.
        let currentYear = new Date().getFullYear();
        let supportedYears = [];
        for (let i = currentYear; i >= 2020; i--) {
            supportedYears.push(i);
        }

        this.state = {
            supportedYears: supportedYears,
            yearListDropdownVisible: false,
            stateReportYear: null,
            stateReportData: null,
            orgState: null,
            buildingReport: false
        }
    }

    componentDidMount = () => {
        getOrganization().then(() => {
            // this will automatically populate SwiftComply.organization so we can just ignore the response
            const orgState = stateProvLists.US.find(({ abbrev }) => abbrev === SwiftComply.organization.state_prov);
            this.setState(() => ({ orgState }));
        });
    }

    toggleYearListDropdown = (action) => {
        this.setState(() => ({ yearListDropdownVisible: action === 'close' ? false : true }));
    }

    getStateReportYearList = () => {
        let options = [];
        this.state.supportedYears.forEach((year, idx) => {
            options.push(<span key={idx} onClick={() => this.setStateReportYear(year)}>{year}</span>);
        });
        return options;
    }

    setStateReportYear = (year) => {
        this.setState(() => ({ stateReportYear: year }), () => {
            this.toggleYearListDropdown('close');
            this.setState({ buildingReport: true });
            runStateReport(year).then(data => {
                this.setState({ stateReportData: data });
                this.setState({ buildingReport: false });
            });
        })
    }

    parseWarnings = (warnings) => {
        let contents = [];

        warnings.forEach((w, idx) => {
            let list = [];
            let uuid, type;

            if (w.details['equipment_uuid']) {
                uuid = w.details['equipment_uuid'];
                type = 'assemblies';
            }
            else if (w.details['property_uuid']) {
                uuid = w.details['property_uuid'];
                type = 'locations';
            }

            if (uuid) {
                list.push(
                    <li key={idx}>{w.message}&nbsp;
                        (<a href={`/${type}/${uuid}`} target='_blank'>view</a>)
                    </li>
                );
            } else {
                list.push(<li key={idx}>{w.message}</li>);
            }

            contents.push(list);
        })

        return <React.Fragment>
            <div className={'message-box info'}>
                <i><ReportProblemIcon fontSize="large" color="primary" /></i>
                <span>There were some issues that could affect the accuracy of this report:</span>
                <div key="errorlist"><ul>{contents}</ul></div>
            </div>
        </React.Fragment>;
    }

    render() {
        const { stateReportYear, stateReportData, orgState } = this.state;

        let warnings;
        if (stateReportData && stateReportData.warnings && stateReportData.warnings.length > 0) {
            warnings = this.parseWarnings(stateReportData.warnings);
        }

        return (
            <div className="componentContainer" id="stateReportContainer">
                <div className="componentHeader">
                    <div className="flexAlignCenter">
                        <AccountBalanceRoundedIcon className="dashboardIcon" />
                        <h3>State Reports</h3>
                    </div>
                </div>
                <AccountBalanceRoundedIcon className="dashboardIconLarge dashboardIconLarge__adjusted" />
                {orgState != null && StateReportSupportedStateProvs.includes(orgState.abbrev.toLocaleUpperCase()) ?
                    <div className="tableButtonBankContainer">
                        <div className="flexAlignCenter">
                            <ClickAwayListener onClickAway={() => this.toggleYearListDropdown('close')}>
                                <div className="dropdown" onClick={() => this.toggleYearListDropdown()} role=''>
                                    <button className={this.state.yearListDropdownVisible ? 'longButtonSecondary openBorderRadius' : 'longButtonSecondary'}>
                                        {(stateReportYear !== null) ? stateReportYear : "Select a Year"}
                                        <img src={dropdown} alt='dropdown' />
                                    </button>
                                    {this.state.yearListDropdownVisible && (
                                        <div className="dropdown__content" id="dropdown">
                                            {this.getStateReportYearList()}
                                        </div>
                                    )}
                                </div>
                            </ClickAwayListener>
                        </div>
                    </div>
                    :
                    <div className="flexJustifyCenter">Your state either does not require a state report or is currently not supported</div>
                }
                {
                    this.state.buildingReport ? <LoaderOverlay /> :
                        stateReportData !== null && (
                            <React.Fragment>
                                <div className="flexJustifyCenter">{`${stateReportYear} ${orgState.name} State Report`}</div>
                                {warnings &&
                                    <div className="flexJustifyCenter">
                                        {warnings}
                                    </div>
                                }
                                <div className="mainTableContainer flexJustifyCenter">
                                    <div id="stateReport">
                                        <StateReportTables
                                            orgState={orgState}
                                            year={stateReportYear}
                                            data={stateReportData}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                }
            </div>
        )
    }
}

export default StateReportsDashboard;
