import axios from 'axios';

export const getSections = () => {
	const url = '/api/v1/permissions';

	return axios.get(url)
		.then(response => {
			console.log('Got Permissions', response);

			if (response) {
				SwiftComply.sections = response.data
			}
			return SwiftComply.sections;
		})
		.catch(error => {
			console.log('Couldn\'t Get Permissions', error.response.data);
			throw error;
		});
};

export const hasRole = (roleName) => {
	const url = `/api/v1/permissions/role/${roleName}`;

	return axios.get(url)
		.then(response => {
			return response.data.has_role;
		})
		.catch(error => {
			console.log(error);
			return false;
		});
}

export const getRoles = () => {
	const url = '/api/v1/permissions/roles';

	return axios.get(url)
		.then(response => {
			return response.data.roles;
		})
		.catch(error => {
			throw error;
		});
};