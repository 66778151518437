import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Menu, MenuItem } from "@mui/material";
import { logout } from "../../api/authAPI";
import styles from "../../styles/common/header.module.css";
import {
    ContactSupportRounded,
    ExpandMoreRounded,
    NotificationsRounded,
} from "@mui/icons-material";
import IconButton from "./IconButton";
import BreadCrumbs from "./Templates/BreadCrumbs";

const Header = () => {
    const [anchor, setAnchor] = useState(/** @type {Element} */ null);
    const { user } = useSelector((state) => state.swiftComply);
    const dropdownRef = useRef();

    return (
        (<div className={styles.header}>
            <div className={styles.content}>
                <BreadCrumbs />
                <div className={styles.rightGroup}>
                    <IconButton icon={<ContactSupportRounded />} size="large" />
                    <IconButton icon={<NotificationsRounded />} size="large" />
                    <div className={styles.clickableGroup} onClick={() => setAnchor(dropdownRef.current)}>
                        <span>{`${user?.firstname} ${user?.lastname}`}</span>
                        <div className={styles.userIcon}>
                            <span>{user?.firstname?.charAt(0)}</span>
                        </div>
                        <IconButton icon={<ExpandMoreRounded ref={dropdownRef} />} size="large" />
                    </div>
                </div>
            </div>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                onClose={() => setAnchor(null)}
            >
                <MenuItem>Profile</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </div>)
    );
};

export default Header;
