import { useEffect, useState } from "react";
import { getAnalytesList } from "../../api/analytesAPI";

/**
 * Custom hook for fetching analytes list
 * Returns array of { label, value } objects, error object, and loading state
 *
 * @returns {{analytes:{label:string, value:string}[], error:*, loading:boolean}}
 */
const useAnalytes = () => {
    const [error, setError] = useState(null);
    const [analytes, setAnalytes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getAnalytesList()
            .then((data) => {
                setAnalytes(
                    data.analytes.map((analyte) => ({
                        label: analyte.analyte + (analyte.abbreviation ? ` (${analyte.abbreviation})` : ""),
                        value: analyte.analyte,
                    })),
                );
            })
            .catch(setError)
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return { analytes, error, loading };
};

export default useAnalytes;
