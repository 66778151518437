import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { MenuOpenRounded, MenuRounded } from "@mui/icons-material";
import { toggleSidebar } from "../../store/actions/sidebarActions";
import styles from "../../styles/common/sidebar.module.css";
import transitions from "../../styles/common/transitions.module.css";
import infinityIcon from "../../../images/InfinityIcon2.svg";
import IconButton from "./IconButton";

const Sidebar = ({ sections, location }) => {
    const { open } = useSelector((state) => state.sidebar);
    const dispatch = useDispatch();

    return (
        (<div className={`${styles.sidebar} ${open ? styles.open : styles.closed}`}>
            <IconButton
                className={styles.toggle}
                icon={open ? <MenuOpenRounded /> : <MenuRounded />}
                onClick={() => dispatch(toggleSidebar())}
                size="large" />
            {sections.map((s) => {
                const { title, icon: IconComponent, link, divider } = s;

                if (divider) {
                    return <hr key="divider" />;
                }

                return (
                    <Link to={link ?? "#"} key={title} disabled={!link}>
                        <div
                            className={
                                transitions.fast + (location.pathname.indexOf(link) === 0 ? ` ${styles.selected}` : "")
                            }
                        >
                            {IconComponent && (
                                <span className={styles.icon}>
                                    <IconComponent className={styles.icon} fontSize="inherit" />
                                </span>
                            )}
                            {open && <span className={styles.link}>{title}</span>}
                        </div>
                    </Link>
                );
            })}
            <div className={styles.infinityLogo}>
                <img src={infinityIcon} alt="Swift Comply Logo" />
            </div>
        </div>)
    );
};

export default withRouter(Sidebar);
