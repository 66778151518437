export const California = (year) => {
    let tables = [
        {
            key: 'top',
            rows: [
                {
                    key: 'containment',
                    label: 'Backflow Assemblies on the Service Connections or Meter (Reduced Pressure Principle and Double Check Valve assemblies)'
                },
                {
                    key: 'isolation',
                    label: 'Backflow Assemblies On-site but not on the Service Connections or Meter (Reduced Pressure Principle and Double Check Valve assemblies)'
                },
                {
                    key: 'airgap',
                    label: 'Air-gap Separation'
                }
            ],
            columns: [
                {
                    key: 'total',
                    label: `Total Number in System in ${year}`
                },
                {
                    key: 'installed',
                    label: `Number Installed in ${year}`
                },
                {
                    key: 'tested',
                    label: `Number Tested in ${year}`
                },
                {
                    key: 'failed',
                    label: `Number failed in ${year}`
                },
                {
                    key: 'repaired/replaced',
                    label: `Number Repaired / Replaced`
                },

            ]
        },
        {
            key: 'bottom',
            rows: [
                {
                    key: 'inactive',
                    label: `No. of Inactive Backflow Prevention Assemblies in water system in ${year}`
                }
            ],
            columns: [
                {
                    key: 'inactive',
                    label: `Total Number in System in ${year}`
                },
                {
                    key: 'installed',
                    label: `Number Installed in ${year}`
                }
            ]
        }
    ];

    return tables;
}
