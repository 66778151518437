import React from 'react';
import loader from '../../../images/loadingInfinity.svg';

const LoadingSpinner = () => (
    <div className="loader">
        <img src={loader} alt="Loading..."/>
    </div>
);

export default LoadingSpinner;
