import React from 'react';
import loader from '../../images/loadingInfinity.svg';

const LoadingSpinner = () => (
	<div className="loader">
		<img className="loader__image" src={loader} />
	</div>
);


export default LoadingSpinner;