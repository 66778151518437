import React from 'react';

export const ModalDuplicateContext = new React.createContext();

export default class ModalDuplicateProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: (props.open) ? props.open : false,
            dupes: (props.dupes) ? props.dupes : [],
            fields: (props.fields) ? props.fields : [],
            url: null,
            setDuplicates: this.setDuplicates,
            close: this.close,
            payload: null,
            saveCallback: null,
            selectedCallback: null,
            save: this.save,
            useSelected: this.useSelected
        };
    }

    setDuplicates = (dupes, fields, url, payload, saveCallback, selectedCallback) => {
        this.setState({
            ...this.state,
            open: true,
            dupes,
            fields,
            url,
            payload,
            saveCallback,
            selectedCallback
        });
    }

    close = () => {
        this.setState({
            open: false,
            dupes: [],
            fields: [],
            payload: null,
            saveCallback: null
        });
    }

    save = () => {
        if (this.state.saveCallback !== null) {
            this.state.saveCallback(this.state.payload);
            this.close();
        }
    }

    useSelected = (uuid) => {
        if (this.state.selectedCallback !== null) {
            this.state.selectedCallback(uuid, this.state.payload);
            this.close();
        }
    }

    render() {
        return (
            <ModalDuplicateContext.Provider value={this.state}>
                {this.props.children}
            </ModalDuplicateContext.Provider>
        )
    }
}