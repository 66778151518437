import React from 'react';
import { NavLink } from 'react-router-dom';
import testReports from '../../../images/testReportsBlue.svg';
import Flyout from '../Flyout';
import { updateURL } from '../../utils/url';
import validator from 'validator';
import testReportLarge from '../../../images/testReportsBlue.svg';
import { getReportSync } from '../../api/reportingAPI';

import ReportTable from '../ReportTable/ReportTable';
import { getReportValue } from '../../utils/reporting';

class TestReportsDashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			flyoutActive: false,
			complianceReportUUID: null,
			complianceReportInfo: {},
			pendingPaymentCount: 0
		};

		this.table = React.createRef();

		const urlParts = this.props.location.pathname.split("/");
		if (urlParts.length >= 3 && validator.isUUID(urlParts[2])) {
			this.state.complianceReportUUID = urlParts[2];
		}
	}

	componentDidMount() {
		if (this.state.complianceReportUUID !== null) {
			this.openFlyout({ compliance_report_uuid: this.state.complianceReportUUID }, false);
		}
		if (SwiftComply.user.user_type == "service provider") {
			getReportSync("7ce2d483-581b-4ada-99f8-1e7c7b6ae4ab", { "inputs": { "form type": "Backflow Assembly Test" } }).then(response => {
				let count = getReportValue(response.data, response.data.rows[0], "count");
				if (count !== undefined && count > 0) {
					this.setState({ pendingPaymentCount: count });
				}
			});
		}
	}

	openFlyout = async (complianceReportInfo) => {
		const overlay = document.getElementById('overlay');

		complianceReportInfo = {
			compliance_report_uuid: complianceReportInfo.compliance_report_uuid
		};

		this.setState(() => ({ complianceReportInfo, flyoutActive: true }));
		// update the url in the browser without causing any reloads
		updateURL('/testReports/' + complianceReportInfo.compliance_report_uuid, {});

		overlay.classList.add('overlay__visible');
	}

	closeFlyout = () => {
		const flyout = document.getElementById('flyout');
		const overlay = document.getElementById('overlay');

		flyout.classList.remove('slideToVisible');
		overlay.classList.remove('overlay__visible');

		setTimeout(() => {
			this.setState(() => ({
				flyoutActive: false,
				complianceReportInfo: {},
				complianceReportUUID: null
			}));
		}, 200);

		this.table.current.ParentEventHandler('flyout closed', { path: '/testReports' });
	}

	render() {
		const { pendingPaymentCount, complianceReportInfo, flyoutActive } = this.state;

		let header = ""
		if (pendingPaymentCount > 0) {
			header = "(" + pendingPaymentCount + " pending payment)";
		}

		return (
			<div className="componentContainer">
				<div className="componentHeader">
					<div className="flexAlignCenter">
						<img src={testReports} alt='' />
						<h3>Test Reports {header}</h3>
					</div>
					<NavLink to='/testReports/newTestReport'>
						<button className="longButtonPrimary">New Test Report</button>
					</NavLink>
				</div>
				<ReportTable
					ref={this.table}
					page='testreports'
					tableType='Test Reports'
					openFlyout={this.openFlyout}
					flyoutActive={flyoutActive}
					defaultReportIdx='0'
					reports={[
						{
							reportUUID: "e083eb8c-87b0-4f21-9293-dfff9c621f75",
							name: "Backflow Assemblies Test Reports",
							columns: ['Result', 'Status', 'Serial Number', 'Type', 'Location Name', 'Address', 'City']
						}
					]}
				/>
				<img className="dashboardIconLarge dashboardIconLarge__adjusted" src={testReportLarge} alt='' />
				<div className="overlay" id="overlay" onClick={() => { this.closeFlyout() }}>
				</div>
				{flyoutActive && (
					<React.Fragment>
						<Flyout
							uuid={complianceReportInfo.compliance_report_uuid}
							newAddition={false}

							flyoutType='testReports'
							flyoutPriority="primary"
							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							activeTab='testReportDetails'

							refresh={() => this.table.current.ParentEventHandler('needs update')}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default TestReportsDashboard;