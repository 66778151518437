import React from "react";
import { withRouter } from "react-router";
import { createContact, saveContactDetail } from "../../../api/contactsAPI";
import { getURLModule, getURLParentObject } from "../../../utils/url";
import { BasicContactFields } from "./BasicContactFields";
import { createPropertyContact } from "../../../api/propertiesAPI";
import { detailsReportMapping } from "../../../utils/reporting";
import HomeForm from "../Home/HomeForm";

const AddContact = ({ history }) => {
    const { slug } = getURLModule();
    const { id: propertyId, path: propertyPath } = getURLParentObject("iu") || {};

    // The form has been submitted, return to the page that opened the form
    const exitForm = (contactUUID) => {
        if (propertyId) {
            // Return to the linked property when done
            history.push(propertyPath);
        } else {
            // This is an unlinked contact, return to the new contact
            history.push(`/${slug}/c/${contactUUID}`);
        }
    };

    // Link the contact to the current property (if one exists) and exit
    const linkAndExit = (contactUUID) => {
        if (propertyId) {
            return createPropertyContact(propertyId, contactUUID, {}).then(() => exitForm(contactUUID));
        }
        exitForm(contactUUID);
    };

    // Make the API call to save the contact, link it, and exit
    const onSubmit = (basic, details) => {
        let contactUUID = null;

        return createContact(basic)
            .then((result) => {
                contactUUID = result.contact_uuid;

                return Promise.all(
                    Object.entries(details).map((detail) =>
                        saveContactDetail(contactUUID, {
                            form_field_uuid: detail[0],
                            value: typeof detail[1] === "undefined" ? undefined : `${detail[1]}`,
                        })
                    )
                );
            })
            .then(() => linkAndExit(contactUUID));
    };

    return (
        <HomeForm
            basicFields={BasicContactFields}
            detailsFormName={detailsReportMapping.contact}
            onSubmit={onSubmit}
            onCancel={() => history.goBack()}
        />
    );
};

export default withRouter(AddContact);
