import React from "react";
import { withRouter } from "react-router";
import { getURLModule, getURLParentObject } from "../../../utils/url";
import { updateGeneralControl, deleteGeneralControl } from "../../../api/permitsAPI";
import { BasicGeneralControlFields } from "./BasicGeneralControlFields";
import HomeForm from "../../Common/Home/HomeForm";

const EditGeneralControl = ({ preloadedBasicState, onEditComplete, onEditCancelled, history }) => {
    const { slug } = getURLModule();
    const { id: generalControlId } = getURLParentObject("gc") || {};

    const onSubmit = (basic) => updateGeneralControl(generalControlId, basic).then(onEditComplete);

    const onDelete = () =>
        deleteGeneralControl(generalControlId).then(() => history.push(`/${slug}/cfg/gc`));

    return (
        <HomeForm
            basicFields={BasicGeneralControlFields}
            onSubmit={onSubmit}
            onCancel={onEditCancelled}
            onDelete={onDelete}
            preloadedBasicState={preloadedBasicState}
        />
    );
};

export default withRouter(EditGeneralControl);
