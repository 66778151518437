import React from 'react';
import { ModalDuplicateContext } from '../../contexts/ModalDuplicateContext';
import Survey from './Survey';

// This is only here because I could not get the duplicate context working from "within" the app router, so instead
// we call this wrapper, which will let us use the context in the test report.

class SurveyWrapper extends React.Component {
    render() {
        return (
            <ModalDuplicateContext.Consumer>
                {(ctx) => {
                    return (
                        <Survey {...this.props} duplicateContext={ctx} />
                    );
                }}
            </ModalDuplicateContext.Consumer>
        );
    }
}

export default SurveyWrapper;