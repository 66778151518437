import React, { useState, useEffect } from 'react';
import { withFormik, Form, Field } from 'formik';
import DatePicker from '../DatePicker';
import MessageBox from '../MessageBox';
import { sendEmails, createDownloadRequest } from '../../api/communicationsAPI';

const FormLayout = ({
    isSubmitting,
    setSubmitting,
    setFieldValue,
    typesList,
    formValues,
    formErrors,
    values,
    downloadFormData,
    emailsSentPromptOptions,
    emailsSent,
    clearDownloads,
    refreshDownloads,
    downloadFormRef
}) => {
    const [errors, setErrors] = useState({});

    const dateRe = /\d{4}-\d{2}-\d{2}/;
    useEffect(() => {
        // the dates need to be set to the end of the day, because the datepicker takes the date as the users timezone
        // but then when it shows it it converts it to UTC.  Maybe it is our fault with all the workarounds we've done
        // but I didn't want to get too invasive in that component for this
        if (formValues.due_on && formValues.due_on.match(dateRe)) {
            formValues.due_on += " 23:59:59";
        }
        if (formValues.generated_on && formValues.generated_on.match(dateRe)) {
            formValues.generated_on += " 23:59:59";
        }
    }, [formValues]);

    useEffect(() => {
        clearDownloads();
    }, [values])

    useEffect(() => {
        getFormErrors();
    }, [formErrors])

    const getCommunicationTypeOptions = () => {
        let optionsList;
        optionsList = typesList.map((type, index) => {
            return (
                <option value={type} key={index}>{type}</option>
            );
        });
        return optionsList;
    }

    const getFormErrors = () => {
        let messageOptions = {};
        if (formErrors !== "") {
            messageOptions = {
                type: "error",
                message: formErrors
            };
        }
        setErrors(messageOptions);
    }

    const downloadCommunications = () => {
        setErrors({});
        setSubmitting(true);
        createDownloadRequest(values).then(() => {
            refreshDownloads();
        }).catch((err) => {
            if (err.response.status == 409) {
                setErrors({ type: "error", message: "You can not request the same download that has already been requested" });
            } else {
                setErrors({ type: "error", message: "Could not request download" });
            }
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const sendEmailsAction = () => {
        setSubmitting(true);
        sendEmails(values).finally(() => {
            emailsSentPromptOptions('sent');
            setSubmitting(false);
        });
    }

    return (
        <Form className="commDownloadForm" id="commDownloadForm">
            <div className="flexJustifyCenter">
                <MessageBox options={errors} />
            </div>
            <div className="tableButtonBankContainer">
                <div className="flexAlignCenter" style={{ color: '#95a3b7' }}>
                    <div className='inputField small'>
                        <label htmlFor="communication_type">Communication Type *</label>
                        <Field
                            component="select"
                            id="communication_type"
                            name="communication_type"
                            autoComplete="off"
                            className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); }}
                        >
                            <option value=""></option>
                            {getCommunicationTypeOptions('communication_type')}
                        </Field>
                    </div>
                    <div className='inputField small'>
                        <label htmlFor="method">Communication Method *</label>
                        <Field
                            component="select"
                            id="method"
                            name="method"
                            autoComplete="off"
                            className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); }}
                        >
                            <option value=""></option>
                            <option value="email">Email</option>
                            <option value="letter">Letter</option>
                            <option value="postcard">Postcard</option>
                        </Field>
                    </div>
                    <div className='inputField small'>
                        <label htmlFor="due_on">Due On</label>
                        <Field
                            name="due_on"
                            component={DatePicker}
                            field={{ name: "due_on", value: formValues.due_on }}
                            valueType="string"
                            editMode={true}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                    <div className='inputField small'>
                        <label htmlFor="generated_on">Generated On</label>
                        <Field
                            name="generated_on"
                            component={DatePicker}
                            field={{ name: "generated_on", value: formValues.generated_on }}
                            valueType="string"
                            editMode={true}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                </div>
            </div>
            <div className="tableButtonBankContainer">
                <div className="flexAlignCenter" style={{ color: '#95a3b7' }}>
                    <div className='inputField extraSmall flex-checkbox' style={{ alignItems: 'center', margin: '2rem 0rem' }}>
                        <label htmlFor="unsent">Unsent</label>
                        <Field type="checkbox" name="unsent" className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.checked); }}
                        />
                    </div>
                </div>
            </div>
            <div className="componentHeader">
                <div className="flexAlignCenter">
                    <button className="medButtonPrimary" disabled={isSubmitting} type="submit" ref={downloadFormRef}>Get List</button>
                </div>
                <div className="componentHeader__buttonBank">
                    {downloadFormData && downloadFormData.length > 0 &&
                        <React.Fragment>
                            {values.method !== 'email' &&
                                <React.Fragment>
                                    <button className="medButtonPrimary" disabled={isSubmitting} type="button" onClick={() => { downloadCommunications(); }}>Request Downloads</button>
                                </React.Fragment>
                            }
                            {values.method === 'email' &&
                                <button className="medButtonPrimary" disabled={(isSubmitting || emailsSent)} type="button" onClick={() => { sendEmailsAction(); }}>Send Emails</button>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        </Form>
    );
};

const CommDownloadsForm = withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    mapPropsToValues(props) {
        let values = {
            communication_type: (props.formValues.communication_type ? props.formValues.communication_type : ''),
            method: (props.formValues.method ? props.formValues.method : ''),
            unsent: ((props.formValues.unsent && props.formValues.unsent === 'true') ? true : false),
            due_on: (props.formValues.due_on ? props.formValues.due_on : null),
            generated_on: (props.formValues.generated_on ? props.formValues.generated_on : null)
        };

        return values;
    },

    handleSubmit(values, { props, setSubmitting }) {
        let formValues = {};
        ["communication_type", "method", "unsent", "due_on", "generated_on"].forEach((field) => {
            if (values[field]) {
                if (field === "unsent") {
                    values["unsent"] = (values["unsent"] === "true" || values["unsent"] === true) ? true : false;
                }
                if (field === "due_on" || field === "generated_on") {
                    // we set the date values in the UI to DATE 23:59:59 to make sure they display correctly, but want just
                    // the date on the backend so split off the time portion before submitting
                    values[field] = values[field].split(' ')[0];
                }
                formValues[field] = values[field];
            }
        });
        setSubmitting(true);
        props.handleSubmit(formValues);
        setTimeout(() => {
            setSubmitting(false);
        }, 1000);
    }
})(FormLayout);

export default CommDownloadsForm;