import axios from "axios";

export const getSampleReport = (sampleReportId) => {
    const url = `/api/v1/analytes/sample_reports/${sampleReportId}`;

    return axios.get(url).then((response) => response.data);
};

export const getSampleReportEvent = (sampleReportEventId) => {
    const url = `/api/v1/analytes/sample_report_events/${sampleReportEventId}`;
    return axios.get(url).then((response) => response.data);
};

export const updateSampleReportEvent = (eventId, sampleResults) =>
    axios
        .put(`/api/v1/analytes/sample_report_events/${eventId}`, {
            sample_report_results: sampleResults,
        })
        .then((response) => {
            console.log("Sample report event update response:", response.data);
            return response.data;
        });
