import React, { useEffect, useState } from "react";
import { getTemplateFields } from "../../../api/templatesAPI";
import { Accordion, AccordionDetails, AccordionSummary, Badge } from "@mui/material";
import {
    BorderColorRounded,
    BusinessCenterRounded,
    ExpandMoreRounded,
    MenuOpenRounded,
    MenuRounded,
    StarRounded,
    TableChartRounded,
    WarningRounded,
} from "@mui/icons-material";
import styles from "../../../styles/common/templateSidebar.module.css";
import IconButton from "../IconButton";
import ToolTip from "../ToolTip";
import DataEntryFields from "./DataEntryFields";
import LoadingSpinner from "../LoadingSpinner";

/**
 * Sidebar shown in the template editor
 *
 * @param {(Object) => void} onAddField - Callback when user clicks a field, field object is the parameter
 * @param {{key:string, msg:string}[]} [fieldErrors] - Error to be displayed on specific field(s)
 */
const TemplatesSidebar = ({ onAddField, fieldErrors }) => {
    const [open, setOpen] = useState(true);
    const [templateData, setTemplateData] = useState(null);
    const [templateFields, setTemplateFields] = useState(null);
    const [sectionInfo, setSectionInfo] = useState({
        document_fields: { label: "Permit Fields", icon: StarRounded },
        system_fields: { label: "System Fields", icon: BusinessCenterRounded },
        data_entry: { label: "Data Entry", icon: BorderColorRounded },
        reports: { label: "System Tables", icon: TableChartRounded, isBlock: true },
    });

    useEffect(() => {
        if (templateData) {
            rebuildAllFields(templateData);
        }
    }, [JSON.stringify(fieldErrors)]);

    useEffect(() => {
        getTemplateFields("permit").then((data) => {
            rebuildAllFields(data.template_data);
            setTemplateData(data.template_data);
        });
    }, []);

    const getFieldError = (field) => fieldErrors?.find((e) => e.key === field.template_string);

    const buildField = (field, fieldError) => (
        // Note - "onMouseDown" works here but "onClick" does not, due to an event ordering issue in Jodit
        (<div className={styles.field} onMouseDown={() => onAddField(field)} key={field.name || field.label}>
            {field.label}
            {field.template_required && " *"}
            {fieldError && (
                <ToolTip title={fieldError.msg}>
                    <WarningRounded />
                </ToolTip>
            )}
        </div>)
    );

    const buildFieldsList = (sectionName, sectionData) => {
        if (!sectionData) {
            return null;
        }

        let sectionHasError = false;

        const fields = Array.isArray(sectionData)
            ? // This section contains an array, so map each entry to a link on the sidebar
              sectionData.map((field) => {
                  const fieldError = getFieldError(field);

                  if (fieldError) {
                      sectionHasError = true;
                  }

                  return buildField(
                      {
                          ...field,
                          section: sectionName,
                          isBlockElement: sectionInfo[sectionName].isBlock,
                      },
                      fieldError
                  );
              })
            : // This section is for system_fields, which is an object with arrays
              Object.entries(sectionData)
                  .map(([category, fields]) =>
                      fields.map((field) => {
                          const fieldError = getFieldError(field);

                          if (fieldError) {
                              sectionHasError = true;
                          }

                          return buildField(
                              {
                                  ...field,
                                  label: `${category}: ${field.label}`,
                                  section: sectionName,
                                  isBlockElement: sectionInfo[sectionName].isBlock,
                              },
                              fieldError
                          );
                      })
                  )
                  .flat();

        setSectionInfo((prev) => ({
            ...prev,
            [sectionName]: {
                ...prev[sectionName],
                sectionHasError,
            },
        }));

        return fields;
    };

    const rebuildAllFields = (data) => {
        setTemplateFields(
            Object.fromEntries(
                Object.keys(sectionInfo).map((section) => [section, buildFieldsList(section, data[section])])
            )
        );
    };

    if (!templateData || !templateFields) {
        return (
            <div className={`${styles.sidebar} ${styles.open}`}>
                <LoadingSpinner />;
            </div>
        );
    }

    return (
        (<div className={`${styles.sidebar} ${open ? styles.open : styles.closed}`}>
            <IconButton
                className={styles.toggle}
                icon={open ? <MenuOpenRounded /> : <MenuRounded />}
                onClick={() => setOpen((prev) => !prev)}
                size="large" />
            {Object.entries(sectionInfo).map(([section, info]) => {
                const Icon = info.icon;

                return open ? (
                    <Accordion classes={{ root: styles.accordion }} key={`${section} accordion`}>
                        <AccordionSummary
                            classes={{ content: styles.accordionSummary }}
                            expandIcon={<ExpandMoreRounded classes={{ root: styles.expandIcon }} />}
                        >
                            <Badge
                                overlap="rectangular" // omitting this causes a console error (?)
                                color="primary" // omitting this makes the dot invisible (?)
                                variant="dot"
                                classes={{ badge: styles.errorDot }}
                                invisible={!sectionInfo[section]?.sectionHasError}
                            >
                                <Icon className={styles.accordionIcon} />
                            </Badge>
                            <span className={styles.sectionTitle}>{info.label}</span>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: styles.accordionDetails }}>
                            {section === "data_entry" ? (
                                <DataEntryFields onAddClicked={onAddField} />
                            ) : (
                                templateFields[section]
                            )}
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <div className={styles.collapsedSection}>
                        <Badge
                            overlap="rectangular"
                            color="primary"
                            variant="dot"
                            classes={{ badge: styles.errorDot }}
                            invisible={!sectionInfo[section]?.sectionHasError}
                        >
                            <Icon
                                className={styles.accordionIcon}
                                key={`${section} icon`}
                                onClick={() => setOpen(true)}
                            />
                        </Badge>
                    </div>
                );
            })}
        </div>)
    );
};

export default TemplatesSidebar;
