import React from "react";
import { FormControlLabel, Checkbox, Switch } from "@mui/material";
import labelStyles from "../../../../styles/common/checkboxField.module.css";
import switchStyles from "../../../../styles/common/matieralUI/switch.module.css";
import checkboxStyles from "../../../../styles/common/matieralUI/checkbox.module.css";

/**
 * Checkbox boolean input component for forms
 *
 * @param {string|JSX.Element} label - Text displayed beside or below checkbox
 * @param {"box"|"switch"} [type] - Traditional "box" style checkbox or "switch" (slider) style, default is "box"
 * @param {boolean} [disabled]
 * @param {boolean} value - Current state
 * @param {(boolean) => void} setValue - State setter function
 */
const CheckboxField = ({ label, type, disabled, value, setValue }) => {
    return (
        <FormControlLabel
            classes={labelStyles}
            label={label}
            labelPlacement={type === "switch" ? "bottom" : "end"}
            disabled={disabled}
            control={
                type === "switch" ? (
                    <Switch
                        disableRipple
                        classes={switchStyles}
                        checked={!!value}
                        onChange={(_, checked) => setValue(checked)}
                    />
                ) : (
                    <Checkbox
                        classes={checkboxStyles}
                        checked={!!value}
                        onChange={(_, checked) => setValue(checked)}
                    />
                )
            }
        />
    );
};

export default CheckboxField;
