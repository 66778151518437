import axios from "axios";

export const getTemplateList = () => {
    const url = "/api/v1/legalcontrols/templates";

    return axios.get(url).then((response) => response.data);
};

export const getTemplateFields = (templateType) => {
    const url = `/api/v1/legalcontrols/templates/type/${templateType}`;

    return axios.get(url).then((response) => response.data);
};

export const createTemplate = (payload) => {
    const url = "/api/v1/legalcontrols/templates";

    return axios.post(url, payload).then((response) => response.data);
};

export const getTemplate = (uuid) => {
    const url = `/api/v1/legalcontrols/templates/${uuid}`;

    return axios.get(url).then((response) => response.data);
};

export const updateTemplate = (uuid, payload) => {
    const url = `/api/v1/legalcontrols/templates/${uuid}`;

    return axios.put(url, payload).then((response) => response.data);
};

export const deleteTemplate = (uuid) => {
    const url = `/api/v1/legalcontrols/templates/${uuid}`;

    return axios.delete(url).then((response) => response.data);
};

export const duplicateTemplate = (uuid) =>
    getTemplate(uuid).then((data) =>
        createTemplate({
            ...data,
            legal_control_template_uuid: undefined,
            name: `${data.name} (Copy)`,
        })
    );
