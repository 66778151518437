import React, { useState, useEffect } from "react";
import { withFormik, Form, Field } from "formik";
import FormField from "../Forms/FormField";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { getUpload } from "../../api/uploadsAPI";
import { listComplianceReportUploads } from "../../api/complianceAPI";
import { acceptedFiletypes, maxFileUploadSize } from "../../utils/settings";
import MessageBox from "../MessageBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import editModeIcon from "../../../images/editMode.svg";
import EditIcon from "@mui/icons-material/Edit";
import { ClickAwayListener } from "@mui/material";
import TextField from "@mui/material/TextField";

let FormLayout = ({
    data,
    setFieldValue,
    isSubmitting,
    resetForm,
    toggleNew,
    newCalibration,
    formData,
    formValues,
    serviceProviderUsers,
    status,
    messageOptions,
    makeReportDecision,
    deleteCalibration,
    values,
    submitTestKit,
    editTestKit,
    setStatus,
    setSubmitting,
    refresh,
    saveTestKit,
    deleteMode,
}) => {
    const [editMode, setEditMode] = useState(false);
    const [imageLink, setImageLink] = useState(null);
    const [uploadFile, setUploadFile] = useState(null);
    const [imageEdit, setImageEdit] = useState(true);
    const [commentInfo, setCommentInfo] = useState("");
    const [commentInputDropdown, setCommentInputDropdown] = useState(false);

    useEffect(() => {
        if (!newCalibration) {
            // This is to check if the calibration was submitted but had errors since that is the only way we will get an "in progress" status.
            // We will display a message to resubmit and allow them to edit or delete the calibration.
            if (formData && formData.status === "in_progress") {
                setStatus(["Submission Failed. Check your data and try again."]);
                toggleEditCalibrationMode();
                setImageEdit(false);
            }
            setUploadLink();
        }
    }, [formData]);

    const handleFormChange = (e) => {
        setFieldValue(e.target.name, e.target.value);
    };

    const handleFileChange = (file) => {
        if (file) {
            setFieldValue("file", file);
            setUploadFile(file);
            const newImageLink = window.URL.createObjectURL(file);
            setImageLink(newImageLink);
        } else {
            delete values.file;
            setUploadFile(null);
            setImageLink(null);
        }
    };

    const convertBlobToFile = (blob) => {
        // This is necessary in order to be able to resubmit a calibration if the status is in progress and the file will stay the same.
        const file = new File([blob], "file.jpg", { type: blob.type });
        return file;
    };

    const setUploadLink = async () => {
        try {
            const response = await listComplianceReportUploads(formData.compliance_report_uuid);

            if (response && response.compliance_report_uploads.length >= 1) {
                const uploadUUID =
                    response.compliance_report_uploads[response.compliance_report_uploads.length - 1].upload_uuid;
                const uploadResponse = await getUpload(uploadUUID);

                if (uploadResponse) {
                    const newImageLink = window.URL.createObjectURL(uploadResponse);
                    const file = convertBlobToFile(uploadResponse);
                    setFieldValue("file", file);
                    setUploadFile(file);
                    setImageLink(newImageLink);
                }
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const onSubmit = (type) => {
        let errors = [];
        // Since we aren't using Yup validation in this file, we are doing this manually on submit by checking if our required values exist or not.
        // This initial function will clear the current red highlighted errors to check if they have been fixed.
        const allFormRed = document.querySelectorAll(".formRed");
        Array.from(allFormRed).forEach((el) => {
            el.classList.remove("formRed");
        });
        data.forEach((field) => {
            let fieldWrapper = document.getElementById(field.form_field_uuid);
            if (field.required && !values[field.form_field_uuid]) {
                fieldWrapper.classList.add("formRed");
                errors.push(field.label + " is required");
            }
            if (field.form_field_type == "Test Date") {
                for (const value in values) {
                    if (value === field.form_field_uuid) {
                        values.reported_on = values[value];
                    }
                }
            }
        });
        if (!values.reported_by) {
            errors.push("Reporter is required");
            const fieldWrapper = document.getElementById("reported_by");
            if (fieldWrapper) {
                fieldWrapper.classList.add("formRed");
            }
        }
        if (!values.reported_on && !errors.includes("Calibration Date is required")) {
            errors.push("Calibration Date was either not set or an error occurred when parsing.");
        }
        if (!values["file"]) {
            errors.push("An image or copy of the calibration document is required");
            const fieldWrapper = document.getElementById("fileSelect");
            if (fieldWrapper) {
                fieldWrapper.classList.add("formRed");
            }
        } else if (values["file"].size > maxFileUploadSize.size) {
            errors.push("Selected image exceeds max size of " + maxFileUploadSize.label);
            const fieldWrapper = document.getElementById("fileSelect");
            if (fieldWrapper) {
                fieldWrapper.classList.add("formRed");
            }
        }
        setStatus(errors);
        if (errors.length > 0) {
            setSubmitting(false);
            return;
        }

        delete values.data;
        let file = uploadFile;
        delete values.file;
        delete values.newCalibration;
        if (!newCalibration) {
            let report = formData.compliance_report_uuid;

            editTestKit(values, file, report);
            toggleEditCalibrationMode();
            setImageEdit(false);
            handleFileChange(uploadFile);
        } else {
            saveTestKit(values, file);
        }
    };

    const cancelAction = () => {
        if (newCalibration) {
            resetForm();
            toggleNew();
        } else {
            toggleEditCalibrationMode();
            refresh();
        }

        setUploadLink();
    };

    const toggleEditCalibrationMode = () => {
        setEditMode(!editMode);
    };

    const fields = () => {
        let fields = [];
        const sortedData = data.sort((a, b) => a.row - b.row);

        sortedData.forEach((el) => {
            let field = (
                <div
                    className="inputField dateFields"
                    id={el.form_field_uuid}
                    key={formValues ? el.form_field_uuid + formData.compliance_report_uuid : el.form_field_uuid}
                >
                    <div className={el.input_type === "checkbox" ? "flex-checkbox" : null}>
                        <FormField
                            details={el}
                            handleChange={handleFormChange}
                            setFieldValue={setFieldValue}
                            values={formValues}
                            disabled={formData && formData.status !== "in_progress"}
                        />
                    </div>
                </div>
            );
            fields.push(field);
        });
        return fields;
    };

    const getUserOptions = () => {
        let options = [<option key="-1" value=""></option>];
        serviceProviderUsers.forEach((user) => {
            // the user has to be approved and have either a null approved until, meaning I guess forever, or an
            // approved_until that is in the future
            if (
                user.status == "approved" &&
                (user.approved_until == "" || user.approved_until > Math.floor(Date.now() / 1000))
            ) {
                options.push(
                    <option key={user.uuid} value={user.uuid}>
                        {user.name}
                    </option>
                );
            }
        });
        return options;
    };

    const getFormErrors = () => {
        messageOptions = {};

        if (status && status.length !== 0) {
            messageOptions = {
                type: "error",
                message: "Please correct the following errors",
                list: status,
            };
        }

        return messageOptions;
    };

    let showFileSelect;
    if (editMode || newCalibration) {
        showFileSelect = null;
    } else {
        showFileSelect = "disappear";
    }

    const getAcceptingRejectingUser = () => {
        // if this isn't accepted or rejected don't show anything
        if (!formData || (formData.status != "accepted" && formData.status != "rejected")) {
            return "";
        }

        let user = formData.last_modified_by.firstname + " " + formData.last_modified_by.lastname;
        if (formData.accepted_rejected_by != null) {
            user = formData.accepted_rejected_by.firstname + " " + formData.accepted_rejected_by.lastname;
        }

        if (formData.status == "accepted") {
            return <div>Accepted by: {user}</div>;
        } else if (formData.status == "rejected") {
            // I don't think we even show rejected test kit cals, so this is likely to never show up anyway
            return <div>Rejected by: {user}</div>;
        }
    };

    const toggleCommentInput = (type) => {
        const picker = document.getElementsByClassName("MuiPopover-root");
        if (type === "close") {
            if (picker.length) {
                return null;
            } else {
                setCommentInputDropdown(false);
                setCommentInfo("");
            }
        } else {
            setCommentInputDropdown(!commentInputDropdown);
        }
    };

    const rejectCalibration = () => {
        makeReportDecision("reject", formData.compliance_report_uuid, commentInfo);
        setCommentInputDropdown(false);
    };

    return (
        <React.Fragment>
            <div
                className={editMode || newCalibration ? "calibrationCard calibrationCard__editMode" : "calibrationCard"}
                id="calibration"
            >
                <Form className="">
                    <MessageBox options={getFormErrors()} />
                    {fields()}
                    {!editMode && !newCalibration && formData.status === "in_progress" && (
                        <button
                            className="editCalibrationButton"
                            type="button"
                            onClick={() => toggleEditCalibrationMode()}
                        >
                            <img src={editModeIcon} alt="" />
                        </button>
                    )}
                    {editMode ||
                        (deleteMode && (
                            <button
                                className="deleteButton"
                                type="button"
                                onClick={() => deleteCalibration(formData.compliance_report_uuid)}
                            >
                                <DeleteForeverIcon fontSize="large" color="inherit" />
                            </button>
                        ))}
                    {(newCalibration || editMode) && (
                        <div className="inputField long" id="reported_by">
                            <label htmlFor="reported_by">
                                Reporter (must be a user currently approved for the service provider) *
                            </label>
                            <Field
                                component="select"
                                name="reported_by"
                                autoComplete="off"
                                className="inputField__input inputField__input-first bgWhite"
                            >
                                {getUserOptions()}
                            </Field>
                        </div>
                    )}
                    {!newCalibration && !editMode && (
                        <a target="_blank" href={imageLink}>
                            <span className="file">
                                <ImageSearchIcon fontSize="inherit" />
                            </span>
                        </a>
                    )}
                    <React.Fragment>
                        <div id="fileSelect" className={showFileSelect}>
                            <div className="inputField">
                                {imageEdit && <label htmlFor="file">Test Kit Image / Calibration Documents *</label>}
                                <div className="flex">
                                    {!imageEdit && <span name={"file"}></span>}
                                    {imageEdit && (
                                        <React.Fragment>
                                            <input
                                                type="file"
                                                name={"file"}
                                                accept={acceptedFiletypes.join()}
                                                onChange={(e) => handleFileChange(e.target.files[0])}
                                            />
                                            <span className="acceptedFiletypes">{acceptedFiletypes.join(", ")}</span>
                                        </React.Fragment>
                                    )}
                                    <div
                                        className="currentImage"
                                        style={imageEdit ? { paddingLeft: "6rem" } : { paddingLeft: "0rem" }}
                                    >
                                        <div className="flex" style={{ alignItems: "end" }}>
                                            <span>Current Calibration Image</span>
                                            {!imageEdit && (
                                                <button onClick={() => setImageEdit(true)}>
                                                    <EditIcon
                                                        fontSize="large"
                                                        style={{ marginLeft: "1rem", color: "#7689A3" }}
                                                    />
                                                </button>
                                            )}
                                        </div>
                                        {imageLink && (
                                            <a target="_blank" id="filePreviewLink" href={imageLink}>
                                                <img className="filePreview" id="filePreview" alt="" src={imageLink} />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(newCalibration || editMode) && (
                            <div className="formButtonsBank">
                                <button
                                    className="medButtonPrimary"
                                    disabled={isSubmitting}
                                    type="button"
                                    onClick={() => onSubmit()}
                                >
                                    Save
                                </button>
                                <button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>
                                    Cancel
                                </button>
                            </div>
                        )}
                        {getAcceptingRejectingUser()}
                    </React.Fragment>
                </Form>
            </div>
            {!newCalibration && !deleteMode && (
                <div className="formButtonsBank">
                    {formData.status === "submitted" && SwiftComply.user.user_type !== "service provider" && (
                        <React.Fragment>
                            <button
                                className="medButtonPrimary"
                                onClick={() => makeReportDecision("accept", formData.compliance_report_uuid)}
                            >
                                Accept
                            </button>
                            <ClickAwayListener onClickAway={() => toggleCommentInput("close")}>
                                <div className={commentInputDropdown ? "slideDown" : ""}>
                                    <button
                                        className="medButtonSecondary red"
                                        onClick={() => {
                                            toggleCommentInput();
                                        }}
                                    >
                                        Reject
                                    </button>
                                    {commentInputDropdown && (
                                        <div className="tableFilter__dropdownContent-wrapper">
                                            <div className="tableFilter__dropdownContent">
                                                <div className="tableFilter__content">
                                                    <Field
                                                        component="textarea"
                                                        rows="3"
                                                        variant="outlined"
                                                        id="commentInfo"
                                                        name="commentInfo"
                                                        placeholder="Reason for Rejection"
                                                        onChange={(e) => setCommentInfo(e.target.value)}
                                                    ></Field>
                                                </div>
                                                <div className="tableFilter__buttons">
                                                    <button
                                                        className="smallButtonPrimary"
                                                        onClick={() => rejectCalibration()}
                                                    >
                                                        Reject
                                                    </button>
                                                    <button
                                                        className="smallButtonSecondary"
                                                        onClick={() => toggleCommentInput("close")}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </ClickAwayListener>
                        </React.Fragment>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

const TestKitCalibrationForm = withFormik({
    enableReinitialize: true,

    mapPropsToValues(props) {
        let values = {};
        if (SwiftComply.user.user_type === "service provider") {
            values.reported_by = SwiftComply.user.user_uuid;
        } else {
            if (props.formData) {
                values.reported_by = props.formData.reported_by.user_uuid ? props.formData.reported_by.user_uuid : "";
            }
        }
        return values;
    },
})(FormLayout);

export default TestKitCalibrationForm;
