import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { hasRole } from '../api/permissionsAPI';
import { getComplianceAutoAcceptSettings } from '../api/complianceAPI';

export default function AutoAccept({ data, newAddition, editMode, AASettingsRef, AASettings, hasPermissions }) {

    const [settings, setSettings] = useState([]);
    const [AAType, setAAType] = useState('');
    const [AAValue, setAAValue] = useState(null);
    const [formTypes, setFormTypes] = useState([]);

    useEffect(() => {
        // only org admins can see org auto accept settings
        hasRole("Org.Admin").then(hasRole => {
            if (hasRole) {
                getComplianceAutoAcceptSettings().then(response => {
                    let orgForms = [];
                    response.settings.forEach(setting => {
                        if (setting.auto_accept_enabled) {
                            orgForms.push(setting.form_type);
                        }
                    })
                    setFormTypes(orgForms);
                });
            }
        });
    }, [])

    useEffect(() => {
        setSettings(AASettings);
    }, [AASettings])

    useEffect(() => {
        AASettingsRef.current = { settings }
    }, [settings])

    const removeAASetting = async (idx) => {
        let newSettings = [...settings];
        newSettings.splice(idx, 1);
        setSettings(newSettings);
    };

    const getAASelectOptions = () => {
        return (
            <React.Fragment>
                <option value=''> -- select an option -- </option>
                {formTypes.map((type, idx) => {
                    return <option key={idx} value={type}>{type}</option>
                })}
            </React.Fragment>
        )
    }

    const handleAASelectChange = (e) => {
        setAAType(e.target.value);
    }

    const handleAAValueChange = (e) => {
        setAAValue(e.target.value);
    }

    const addAA = () => {
        const newSettings = [...settings];

        const matchedSetting = newSettings.findIndex(setting => setting.form_type === AAType);
        if (matchedSetting > -1) {
            newSettings[matchedSetting] = { auto_accept: AAValue, form_type: AAType }
        } else {
            newSettings.push({ auto_accept: AAValue, form_type: AAType })
        }

        setSettings(newSettings);
        setAAType('');
        setAAValue(null);
        document.getElementById("AASelect").value = '';
    }

    const settingsTable = () => {

        const labelMaker = (bool) => {
            let label;
            JSON.parse(bool) === true ? label = "Enabled" : label = "Disabled"
            return label;
        }

        return (
            <table id="settingsTable">
                <thead>
                    <tr>
                        <th>Form Type</th>
                        <th>Status</th>
                        {editMode && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {settings.map((setting, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{setting.form_type}</td>
                                <td>{labelMaker(setting.auto_accept)}</td>
                                {editMode &&
                                    <td className="flex">
                                        <CloseIcon
                                            className="removeIcon"
                                            fontSize="large"
                                            onClick={() => removeAASetting(idx)}
                                        />
                                    </td>
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    if (hasPermissions && formTypes.length > 0) {
        return (
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <span style={{ marginBottom: ".75rem" }}>Auto Accept Settings</span>
                {editMode &&
                    <div className="flexAlignCenter">
                        <div className="detailsFieldset medium">
                            <select name='AASelect' id='AASelect' onChange={(e) => handleAASelectChange(e)}>
                                {getAASelectOptions()}
                            </select>
                        </div>
                        {AAType &&
                            <React.Fragment>
                                <div className="detailsFieldset medium" style={{ marginLeft: "1rem" }}>
                                    <select name="AAValue" id="AAValue" onChange={(e) => handleAAValueChange(e)}>
                                        <option> -- select an option -- </option>
                                        <option value={"true"}>Enabled</option>
                                        <option value={"false"}>Disabled</option>
                                    </select>
                                </div>
                                <button style={{ marginLeft: "2rem" }} disabled={!AAValue} className="xsmallButtonPrimary" type="button" onClick={() => addAA()}>+</button>
                            </React.Fragment>
                        }
                    </div>
                }
                {settings.length > 0 && settingsTable()}
                {settings.length === 0 &&
                    <div className="noSettingsNotice">No current settings.</div>
                }
            </div>
        )
    } else {
        return (null);
    }
}