import axios from 'axios';

export const getServiceProviderBalance = (serviceProviderUUID) => {
    const url = `/api/v1/service_providers/${serviceProviderUUID}/balance`;

    return axios.get(url)
        .then(response => {
            console.log('Got service provider balance', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t get service provider balance', error.response.data);
            throw error;
        });
};

export const getComplianceReportPrice = (serviceProviderUUID, type, reportUUID) => {
    const url = `/api/v1/payments/price/${type}/${serviceProviderUUID}/${reportUUID}`;

    return axios.get(url)
        .then(response => {
            console.log('Got report price', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t get report price', error.response.data);
            throw error;
        });
};

export const checkForPrice = (type) => {
    const url = `/api/v1/payments/price/${type}`;

    return axios.get(url)
        .then(response => {
            console.log('Got price', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t get price', error.response.data);
            throw error;
        });
};

export const initiatePaymentProcess = (payload) => {
    const url = '/api/v1/payments';

    return axios.post(url, payload)
        .then(response => {
            console.log('Payment successfully initiated', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t initiate payment', error.response.data);
            throw error;
        });
};

