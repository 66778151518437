import React, { useState, useEffect } from 'react';
import { getOrganizationSetting } from '../../api/organizationAPI';
import BasicTable from '../BasicTable';
import { Tooltip } from '../Tooltip';

const BillingSyncSettings = () => {
    const [billingSyncSettings, setBillingSyncSettings] = useState(null);

    useEffect(() => {
        getBillingSyncSettings();
    }, [])

    const getBillingSyncSettings = async () => {
        // We'll always show the available settings (with their default) even if they have none
        // explicitly set.
        let settings = {
            "enable_property_creation": "No",
            "exclusive_contact_types": "None",
            "unlink_orphaned_contacts": "No"
        };

        // Get the billing sync settings and return the defaults if none are found.
        const settingVal = await getOrganizationSetting('org.billing_sync.settings');

        if (settingVal == null) {
            setBillingSyncSettings(formatBillingSyncSettings(settings));
            return;
        }

        let billingSyncSettings = {};

        try {
            billingSyncSettings = JSON.parse(settingVal);
        } catch (e) {
            console.log("Error parsing Billing Sync Settings:", e);
            setBillingSyncSettings(formatBillingSyncSettings({ "error_parsing_settings": "Error" }));
            return;
        }

        // Update the settings object with the values from the database.
        if ("enable_property_creation" in billingSyncSettings && billingSyncSettings.enable_property_creation) {
            settings.enable_property_creation = "Yes";
        }

        if ("exclusive_contact_types" in billingSyncSettings &&
            Array.isArray(billingSyncSettings.exclusive_contact_types) &&
            billingSyncSettings.exclusive_contact_types.length > 0) {

            settings.exclusive_contact_types = billingSyncSettings.exclusive_contact_types.join(", ");
        }

        if ("unlink_orphaned_contacts" in billingSyncSettings && billingSyncSettings.unlink_orphaned_contacts) {
            settings.unlink_orphaned_contacts = "Yes";
        }

        setBillingSyncSettings(formatBillingSyncSettings(settings));
    }

    // This just takes the settings object (key/value pairs) and returns an array of objects that
    // BasicTable expects.
    const formatBillingSyncSettings = settings => {
        const labels = {
            "enable_property_creation": "Location Creation Enabled",
            "exclusive_contact_types": "Exclusive Contact Types",
            "unlink_orphaned_contacts": "Unlink Contacts That Had Role(s) Removed",
            "error_parsing_settings": "Error Parsing Billing Sync Settings"
        };

        let formattedSettings = [];
        for (const s in settings) {
            formattedSettings.push({ "setting": labels[s], "value": settings[s] });
        }

        return formattedSettings;
    }

    return (
        <React.Fragment>
            {billingSyncSettings &&
                <div className="mainTableContainer" style={{ marginTop: "5rem" }}>
                    <span style={{ color: '#506788' }}>
                        {"Billing Sync Settings"}
                        <Tooltip text={(
                            <React.Fragment>
                                <p><b>Location Creation Enabled</b> - This indicates if lines containing new locations
                                    in the CSV file will be created as new location records in SwiftComply Backflow
                                    during the billing sync update. "No" means the CSV line containing the new location
                                    will be skipped.
                                </p>
                                <p><b>Exclusive Contact Types</b> - This will enforce only 1 contact per listed type
                                    (e.g. owner, mailing, etc) at any given location. Existing contacts with the listed
                                    contact type will have that listed role removed from their contact record.
                                </p>
                                <p><b>Unlink Contacts That Had Role(s) Removed</b> - If a contact has all its roles
                                    removed due to the Exclusive Contact Types rule, the role-less contact record would
                                    then be removed from the associated location record. The contact record would still
                                    exist in your SwiftComply Backflow database. This setting has no effect unless
                                    Exclusive Contact Types are defined.
                                </p>
                                <p><b>Error</b> - There was an error trying to determine Billing Sync settings. Please
                                    contact SwiftComply at support@swiftcomply.com.
                                </p>
                            </React.Fragment>
                        )}
                            html={true}
                        />
                    </span>
                    <div>
                        <BasicTable
                            tableData={billingSyncSettings}
                            dataSource="list"
                            columns={[
                                { displayName: "Setting", dataName: "setting" },
                                { displayName: "Value", dataName: "value" }
                            ]}
                            clickable={false}
                            sortable={false}
                        />
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default BillingSyncSettings;
