import React from 'react';
import { Field } from 'formik';
import DatePickerField from './DatePickerField';
import StateProvSelectField from '../Forms/StateProvSelectField';
import moment from 'moment';

class FormField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			disabled: (props.disabled) ? props.disabled : false,
			tooltip: props.tooltip || null
		};
	}

	createField = () => {
		const { details, prepopulateValues, values, setFieldValue, handleChange } = this.props;
		let field = [];
		if (details.label && details.input_type !== 'label') {
			field.push(
				<>
				<label
					key={`label_${details.form_field_uuid}`}
					htmlFor={details.form_field_uuid} >
						{this.state.tooltip}
					{details.label} {(details.required) ? " *" : ""}
				</label>
				</>
			);
		}

		if (details.input_type == "input") {
			field.push(
				<React.Fragment key={details.form_field_uuid}>
					<Field
						disabled={this.state.disabled}
						autoComplete="off"
						key={details.form_field_uuid}
						className="inputField__input inputField__input-first"
						name={details.form_field_uuid}
						{... (handleChange && { onChange: handleChange })}
						{... (details.suggested_values && Array.isArray(details.suggested_values) && details.suggested_values.length > 0) && { list: details.form_field_uuid + "-list" }}
					/>
					{(details.suggested_values && Array.isArray(details.suggested_values) && details.suggested_values.length > 0) && (
						<datalist id={details.form_field_uuid + "-list"}>
							{details.suggested_values.map((suggestedValue) => {
								return (
									<option key={suggestedValue} value={suggestedValue}>{suggestedValue}</option>
								);
							})}
						</datalist>
					)}
				</React.Fragment>
			);
		} else if (details.input_type == 'select') {
			let options = [<option key="scc3_null" value=""></option>];
			details.values.forEach((value) => {
				let fieldLabel = value
				let fieldValue = value

				if (value.startsWith('{')) {
					let jsonValue = JSON.parse(value);
					fieldLabel = jsonValue.label
					fieldValue = jsonValue.value
				}
				options.push(<option key={fieldValue} value={fieldValue}>{fieldLabel}</option>);
			});

			field.push(
				<Field
					disabled={this.state.disabled}
					component="select"
					key={details.form_field_uuid}
					name={details.form_field_uuid}
					{... (handleChange && { onChange: handleChange })}
				>
					{options}
				</Field>
			);
		} else if (details.input_type == 'radio') {

			details.values.forEach(value => {
				let fieldLabel = value
				let fieldValue = value
				if (value.startsWith('{')) {
					let jsonValue = JSON.parse(value);
					fieldLabel = jsonValue.label
					fieldValue = jsonValue.value
				}

				field.push(
					<React.Fragment key={value}>
						<div className="radio__wrapper">
							<Field
								disabled={this.state.disabled}
								type="radio"
								key={details.form_field_uuid}
								name={details.form_field_uuid}
								id={value}
								value={fieldValue}
								{... (handleChange && { onChange: handleChange })}
							/>
							<span>{fieldLabel}</span>
						</div>
					</React.Fragment>
				);
			});
		} else if (details.input_type == 'checkbox') {

			field.push(
				<React.Fragment key={details.form_field_uuid}>
					<Field
						disabled={this.state.disabled}
						type="checkbox"
						key={details.form_field_uuid}
						name={details.form_field_uuid}
						{... (handleChange && { onChange: handleChange })}
					/>
				</React.Fragment>
			);
		} else if (details.input_type == 'textarea') {

			field.push(
				<React.Fragment key={details.form_field_uuid}>
					<Field
						component="textarea"
						disabled={this.state.disabled}
						rows="2"
						key={details.form_field_uuid}
						name={details.form_field_uuid}
						{... (handleChange && { onChange: handleChange })}
					/>
				</React.Fragment>
			);
		} else if (details.input_type == 'past_date') {
			field.push(
				<React.Fragment key={details.form_field_uuid}>
					<DatePickerField details={details} disableFuture={true} setFieldValue={setFieldValue} prepopulateValues={prepopulateValues} values={values} disabled={this.state.disabled} />
				</React.Fragment>
			);
		} else if (details.input_type == 'date') {
			field.push(
				<React.Fragment key={details.form_field_uuid}>
					<DatePickerField details={details} disableFuture={false} setFieldValue={setFieldValue} prepopulateValues={prepopulateValues} values={values} disabled={this.state.disabled} />
				</React.Fragment>
			);
		} else if (details.input_type == 'state_prov_select') {
			field.push(
				<StateProvSelectField
					disabled={this.state.disabled}
					fieldName={details.form_field_uuid}
					{... (handleChange && { handleChange })}
				/>
			);
		} else if (details.input_type == 'label') {
			field.push(
				<React.Fragment key={details.form_field_uuid}>
					<span className="formField__label">{details.label}</span>
				</React.Fragment>
			);
		} else if (details.input_type.match(/^custom:/)) {
			// a selection of something for a tester, at least for now, in extraData where will be tester.something that
			// is an array of values to put here.  We should already preselect the first one in the list.  This is going
			// to look very similar to the preselection bits.
			let tmp = details.input_type.split(":");
			let customType = tmp[1];
			let options = [];

			if (customType === 'reporter.certifications') {
				if (this.props.extraData && this.props.extraData.reporter && this.props.extraData.reporter.certifications) {
					this.props.extraData.reporter.certifications.forEach((obj, idx) => {
						let m = null;
						// deal with scientific notation (in reports) or normal timestamps in api calls (need to fix this)
						if (obj.expiration_date.toLowerCase().includes('e+')) {
							m = moment.unix(Number(obj.expiration_date));
						} else {
							m = moment.unix(Number.parseInt(obj.expiration_date, 10));
						}
						let expDate = m.format('YYYY-MM-DD');
						options.push(<option key={idx} value={obj.certification_number}>{obj.certifying_agency + " " + obj.certification_number + " (exp: " + expDate + ")"}</option>);
					});
				}
			} else if (customType === 'reporter.equipment') {
				if (this.props.extraData && this.props.extraData.reporter && this.props.extraData.reporter.equipment) {
					this.props.extraData.reporter.equipment.forEach((obj, idx) => {
						options.push(<option key={idx} value={obj.serial_number}>{obj.make + " " + obj.serial_number}</option>);
					});
				}
			}

			field.push(
				<Field
					disabled={this.state.disabled}
					component="select"
					key={details.form_field_uuid}
					name={details.form_field_uuid}
					{... (handleChange && { onChange: handleChange })}
				>
					{options}
				</Field>
			);
		}

		return field;
	}

	render() {
		return (
			<React.Fragment>
				{this.createField()}
			</React.Fragment>
		);
	}
}

export default FormField;
