import React from "react";

export const stateReportFieldsByState = {
    backflow: {
        properties: {
            CO: [
                {
                    id: "zoning",
                    label: "Zoning",
                    input_type: "select",
                    values: [
                        { label: "Commercial", value: "commercial" },
                        { label: "Residential", value: "residential" },
                    ],
                },
            ],
            FL: [
                {
                    id: "zoning",
                    label: "Zoning",
                    input_type: "select",
                    values: [
                        { label: "Residential", value: "residential" },
                        { label: "Non-Residential", value: "non-residential" },
                    ],
                },
            ],
            MA: [
                {
                    id: "zoning",
                    label: "Zoning",
                    input_type: "select",
                    values: [
                        { label: "Commercial", value: "commercial" },
                        { label: "Industrial", value: "industrial" },
                        { label: "Institutional", value: "institutional" },
                        { label: "Municipal", value: "municipal" },
                        { label: "Residential", value: "residential" },
                    ],
                },
            ],
            MI: [
                {
                    id: "zoning",
                    label: "Zoning",
                    input_type: "select",
                    values: [
                        { label: "Commercial", value: "commercial" },
                        { label: "Governmental", value: "governmental" },
                        { label: "Industrial", value: "industrial" },
                        { label: "Institutional", value: "institutional" },
                        { label: "Residential", value: "residential" },
                    ],
                },
                {
                    id: "hazard_level",
                    label: "Hazard Level",
                    input_type: "select",
                    values: [
                        { label: "High", value: "high" },
                        { label: "Low", value: "low" },
                    ],
                },
            ],
            WA: [
                {
                    id: "npdes_permit",
                    label: "NPDES Permit #",
                    input_type: "input",
                },
                {
                    id: "hazard_type",
                    label: "Location Table 13 Hazard Type",
                    input_type: "select",
                    values: [
                        { label: "Agricultural (farms and dairies)", value: "agricultural" },
                        { label: "Alternative health out-patient clinics and offices", value: "alternative" },
                        { label: "Beverage bottling plants", value: "bottling" },
                        { label: "Blood centers", value: "blood" },
                        { label: "Boarding homes", value: "boarding" },
                        { label: "Car washes", value: "car wash" },
                        { label: "Chemical plants", value: "chemical plant" },
                        { label: "Childbirth centers", value: "childbirth" },
                        { label: "Chiropractors", value: "chiropractor" },
                        { label: "Commercial laundries and dry cleaners", value: "laundry" },
                        {
                            label: "Dedicated fire protection systems with chemical addition or using unapproved auxiliary supplies",
                            value: "fire",
                        },
                        {
                            label: "Dedicated irrigation systems using purveyor's water supply and with chemical addition",
                            value: "irrigation",
                        },
                        { label: "Dental clinics and offices", value: "dental" },
                        { label: "Film processing facilities", value: "film" },
                        { label: "Food processing plants", value: "food processing" },
                        { label: "Hospice care centers", value: "hospice" },
                        {
                            label: "Hospitals (include psychiatric hospitals and alcohol and drug treatment centers)",
                            value: "hospital",
                        },
                        { label: "Kidney dialysis centers", value: "kidney" },
                        { label: "Laboratories", value: "laboratory" },
                        { label: "Metal plating industries", value: "metal plating" },
                        { label: "Morgues and autopsy facilities (not in hospitals)", value: "morgue" },
                        { label: "Mortuaries", value: "mortuary" },
                        { label: "Nursing homes", value: "nursing home" },
                        { label: "Other High Hazard (3B)", value: "other" },
                        { label: "Other Medical-Related High Hazard (3C)", value: "other medical" },
                        { label: "Out-patient clinics and offices", value: "out-patient" },
                        { label: "Petroleum processing or storage plants", value: "petroleum" },
                        { label: "Piers and docks", value: "pier" },
                        {
                            label: "Premises where both reclaimed water and potable water are provided",
                            value: "reclaimed",
                        },
                        {
                            label: "Premises with an unapproved auxiliary water supply interconnected with the potable water supply",
                            value: "aux water supply",
                        },
                        { label: "Psychiatric out-patient clinics and offices", value: "psychiatric" },
                        { label: "Radioactive material processing plants or nuclear reactors", value: "radioactive" },
                        { label: "Residential treatment centers", value: "residential treatment" },
                        { label: "Same day surgery centers", value: "surgery" },
                        { label: "Survey access denied or restricted", value: "access denied" },
                        { label: "Veterinarian offices, clinics, and hospitals", value: "veterinarian" },
                        { label: "Wastewater lift stations and pumping stations", value: "wastewater station" },
                        { label: "Wastewater treatment plants", value: "wastewater treatment" },
                    ],
                },
                {
                    id: "other_hazard_type",
                    label: "Other High Hazard Type",
                    input_type: "input",
                },
                {
                    id: "connections",
                    label: "Number of Metered Connections to this Location",
                    input_type: "input",
                    data_type: "numeric",
                },
            ],
        },
    },
    pretreatment: {},
};
