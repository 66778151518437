// this file is only for temporary data fixes until the backend or something else catches up, or testing ideas on how
// to change the backend or something like that, I don't know.  Suffice to say that you probably shouldn't put anything
// in here

const populateData = {};

const inputTypes = {
    'Tester Certification Number': 'custom:reporter.certifications',
    'Test Kit': 'custom:reporter.equipment',
    'Service Location State / Prov': 'state_prov_select'
}

export const addFormFieldPopulateData = (fields) => {
    for (let i = 0; i < fields.length; i++) {
        if (populateData[fields[i].form_field_type]) {
            fields[i].populate_from = populateData[fields[i].form_field_type];
        }
        if (inputTypes[fields[i].form_field_type]) {
            fields[i].input_type = inputTypes[fields[i].form_field_type];
        }
    }

    return fields;
}
