/** @type {formField[]} */
export const BasicSampleReportConfigFields = [
    {
        id: "name",
        label: "Configuration Name",
        required: true,
    },
    {
        id: "assigned_to",
        label: "Assigned To",
        inputType: "select",
        options: [
            { label: "Industry", value: "property" },
            { label: "Authority", value: "organization" },
        ],
        required: true,
    },
    {
        id: "lab_report_required",
        label: "Lab Report",
        inputType: "select",
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        toggle: {
            label: "Compliance",
            id: "compliance_determinants",
            behavior: "add_to_array",
            hideWhen: (formState) => formState.assigned_to !== "property",
        },
        required: true,
    },
    {
        id: "custody_chain_required",
        label: "Chain of Custody",
        inputType: "select",
        dataType: "boolean",
        options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
        ],
        toggle: {
            label: "Compliance",
            id: "compliance_determinants",
            behavior: "add_to_array",
            hideWhen: (formState) => formState.assigned_to !== "property",
        },
        required: true,
    },
    { id: "additional_requirements", label: "Additional Requirements (optional)", inputType: "textarea" },
    {
        id: "control_type",
        label: "Connect to Legal Control",
        inputType: "radio",
        options: [
            { label: "General Control", value: "general_control" },
            { label: "Specific Control", value: "specific_control" },
        ],
        hideWhen: (formState) => formState.assigned_to !== "property",
    },
    {
        id: "general_control_uuid",
        label: "Select General Control",
        inputType: "select",
        options: [], // This will be populated with fetched data
        hideWhen: (formState) => formState.assigned_to !== "property" || formState.control_type !== "general_control",
    },
    {
        id: "report_schedule",
        label: "Report Schedule",
        inputType: "schedule",
        hideWhen: (formState) =>
            !formState.assigned_to ||
            (formState.assigned_to === "property" && formState.control_type !== "general_control"),
    },
    {
        id: "specific_control_hint",
        label: "The schedule of this report will be completed during the specific control creation process.",
        inputType: "label",
        hideWhen: (formState) => formState.assigned_to !== "property" || formState.control_type !== "specific_control",
    },
];
