import React, { useEffect, useState } from "react";
import { getURLParentObject, getURLModule } from "../../../utils/url";
import { getLimit } from "../../../api/referencePointsAPI";
import { BasicLimitFields } from "./LimitFields";
import EditLimit from "./EditLimit";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import SummaryGrid from "../../Common/Forms/SummaryGrid";
import useGeneralControls from "../GeneralControls/useGeneralControls";
import BasicReportTable from "../../Common/ReportTable/BasicReportTable";

const ViewLimit = () => {
    const { id: outfallId } = getURLParentObject("o") || {};
    const { id: limitId } = getURLParentObject("l") || {};
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [basicFields, setBasicFields] = useState({});
    const [editMode, setEditMode] = useState(false);
    const { generalControls, loading: generalControlsLoading } = useGeneralControls();
    const { slug } = getURLModule();

    const refreshLimit = () => {
        setLoading(true);
        setError(null);

        getLimit(outfallId, limitId)
            .then((data) =>
                setBasicFields({
                    ...BasicLimitFields.reduce((prev, curr) => ({ ...prev, [curr.id]: data[curr.id] }), {}),
                    general_control_uuid: data.general_control_uuid,
                })
            )
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const onEditComplete = () => {
        setEditMode(false);
        refreshLimit();
    };

    useEffect(() => {
        refreshLimit();
    }, []);

    if (editMode && !loading && !error) {
        return (
            <EditLimit
                preloadedBasicState={basicFields}
                onEditComplete={onEditComplete}
                onEditCancelled={() => setEditMode(false)}
            />
        );
    }

    const fields = BasicLimitFields
        .map((f) => f.id === "general_control_uuid" ? { ...f, label: "General Control" } : f);

    const values = {
        ...basicFields,
        general_control_uuid:
            generalControls.find((control) => control.value === basicFields.general_control_uuid)?.label ||
            "Not connected",
    };

    return (
        <Page loading={loading || generalControlsLoading} error={error}>
            <Card title="Limit Summary" onEdit={() => setEditMode(true)}>
                <SummaryGrid fields={fields} formState={values} />
            </Card>
            <Card title={"Enforceability"}>
                <BasicReportTable
                    reportUUID="d821dcaf-5fa2-4440-8f70-fed7346b94f5"
                    visibleColumns={["Status", "Legal Control Name", "Effective Date", "Expiration Date"]}
                    inputs={{ "limit uuid": limitId }}
                    rowLinks={
                        (row) => row["Legal Control UUID"] ? 
                        `/${slug}/p/${row["Legal Control UUID"]}` : 
                        `/${slug}/cfg/gc/${row["General Control UUID"]}`}
                />
            </Card>
        </Page>
    );
};

export default ViewLimit;
