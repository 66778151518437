import React, { useState } from "react";
import SchedulingDialog from "../../Common/Forms/Fields/SchedulingDialog";
import {
    dateToAPIString,
    formatDate,
    timestampToDate,
} from "../../../utils/forms";
import { FiberManualRecordRounded } from "@mui/icons-material";
import sampleIcon from "../../../../images/sampleIcon.svg";
import Button from "../../Common/Button";
import LoadingSpinner from "../../Common/LoadingSpinner";
import styles from "../../../styles/common/reportSchedulingStep.module.css";

/**
 * The ReportSchedulingStep component renders a table of selected reports from step 2,
 * allowing the user to set schedules for each report.
 *
 * @param {Object} props
 * @param {Object} props.formState - The current form state
 * @param {Object} props.tableSelections - The table selections from step 2
 * @param {Object} props.templateData - The template data containing report definitions
 * @param {Object} props.recurrenceData - The existing recurrence data for scheduling
 * @param {Function} props.onRecurrenceDataChange - Function to update the recurrence data
 * @param {Function} props.onOpenSamplingConfigDialog - Function to open the sampling config dialog
 */
const ReportSchedulingStep = ({
    formState,
    tableSelections,
    templateData,
    recurrenceData,
    onRecurrenceDataChange,
    onOpenSamplingConfigDialog,
}) => {
    const [showSchedulingDialog, setShowSchedulingDialog] = useState(false);
    const [selectedSampleReportConfigID, setSelectedSampleReportConfigID] =
        useState(null);

    // Extract effective and expiration dates from formState
    const minDate = timestampToDate(formState["EffectiveDate"]);
    const maxDate = timestampToDate(formState["ExpirationDate"]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const handleOpenSchedulingDialog = (sampleReportConfigID) => {
        setSelectedSampleReportConfigID(sampleReportConfigID);
        setShowSchedulingDialog(true);
    };

    const handleCloseSchedulingDialog = () => {
        setShowSchedulingDialog(false);
        setSelectedSampleReportConfigID(null);
    };

    const handleSetSchedule = (newSchedule) => {
        if (selectedSampleReportConfigID) {
            onRecurrenceDataChange(newSchedule, selectedSampleReportConfigID);
        }
        setShowSchedulingDialog(false);
    };

    const handleSampleClick = (sampleReportConfigID) => {
        onOpenSamplingConfigDialog(sampleReportConfigID);
    };

    if (!templateData || !templateData.reports) {
        return <LoadingSpinner />;
    }

    const getUniqueSelectedReports = () => {
        const uniqueConfigIds = new Set();

        // Map template strings to reports for quick lookup
        const reportsByTemplateString = Object.fromEntries(
            templateData.reports.map((report) => [
                report.template_string,
                report,
            ])
        );

        // Get all pivot table reports with their categoryIndex
        const pivotReports = (templateData.inputs_order || [])
            .filter((input) => input.category === "reports")
            .map((input) => {
                const report = reportsByTemplateString[input.template_string];
                if (report && report.table_type === "pivot-table") {
                    return {
                        ...report,
                        categoryIndex: input.category_index, // Associate categoryIndex
                    };
                }
                return null;
            })
            .filter(Boolean);

        return pivotReports.flatMap((report) => {
            const uniqueKey = `${report.key}_${report.categoryIndex}`;
            const reportState = formState[report.report_uuid]?.[uniqueKey];
            if (!reportState) return [];
            const reportRows = reportState?.rows || [];
            const selections = tableSelections[uniqueKey] || {};

            return reportRows
                .filter((_, index) => !selections[index]) // Include only selected rows
                .reduce((acc, row) => {
                    // Find the index of "Sample Report Config ID" in outputs
                    const sampleReportConfigIdIndex =
                        reportState?.outputs?.findIndex(
                            (output) =>
                                output.name === "Sample Report Config ID"
                        );

                    // Extract the Sample Report Config ID and ensure it's a string
                    const sampleReportConfigID = String(
                        row.values[sampleReportConfigIdIndex]
                    );

                    // Check if this Config ID has already been added
                    if (!uniqueConfigIds.has(sampleReportConfigID)) {
                        uniqueConfigIds.add(sampleReportConfigID);
                        acc.push({
                            report,
                            reportState,
                            row,
                            sampleReportConfigID,
                        });
                    }
                    return acc;
                }, []);
        });
    };

    const uniqueSelectedReports = getUniqueSelectedReports();

    return (
        <div className={styles.scrollableWrapper}>
            <h3 className={styles.tableHeader}>
                Set the schedule of your reports in order to proceed
            </h3>
            {uniqueSelectedReports.length === 0 ? (
                <div className={styles.noRowsFound}>
                    No reports selected for scheduling.
                </div>
            ) : (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Outfall Name</th>
                                    <th>Assigned To</th>
                                    <th>Report Name</th>
                                    <th>Frequency</th>
                                    <th>Due Date/Time</th>
                                    <th>Start Date/Time</th>
                                    <th>End Date/Time</th>
                                    <th>Samples</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {uniqueSelectedReports.map(
                                    (
                                        {
                                            report,
                                            reportState,
                                            row,
                                            sampleReportConfigID,
                                        },
                                        index
                                    ) => {
                                        const isScheduled =
                                            recurrenceData[
                                                sampleReportConfigID
                                            ];
                                        const status = isScheduled
                                            ? "Scheduled"
                                            : "Available";

                                        // Logic to extract columns from row values
                                        const outfallNameIndex =
                                            reportState.outputs.findIndex(
                                                (output) =>
                                                    output.name === "Outfall"
                                            );
                                        const assignedToIndex =
                                            reportState.outputs.findIndex(
                                                (output) =>
                                                    output.name ===
                                                    "Assigned To"
                                            );
                                        const reportNameIndex =
                                            reportState.outputs.findIndex(
                                                (output) =>
                                                    output.name ===
                                                    "Sample Report Config Name"
                                            );

                                        const outfallName =
                                            row.values[outfallNameIndex] || "";
                                        const assignedTo =
                                            row.values[assignedToIndex] || "";
                                        const reportName =
                                            row.values[reportNameIndex] || "";

                                        // Extract frequency, due date/time, start date/time, and end date/time from templateData
                                        let frequency = "-";
                                        let dueDateTime = "-";
                                        let startDateTime = "-";
                                        let endDateTime = "-";

                                        const recurrenceInfo =
                                            templateData.association_data
                                                ?.sample_report_with_sampling_config_iu?.[
                                                sampleReportConfigID
                                            ];

                                        if (recurrenceInfo) {
                                            if (recurrenceInfo.ad_hoc_dates) {
                                                // If it's an ad-hoc schedule, use ad_hoc_dates[0] for dueDateTime
                                                const adHocTimestamp =
                                                    recurrenceInfo
                                                        .ad_hoc_dates[0];
                                                dueDateTime = formatDate(
                                                    timestampToDate(
                                                        adHocTimestamp
                                                    )
                                                );
                                            } else if (
                                                recurrenceInfo.recurrence_config
                                            ) {
                                                // If it's a recurrence, use frequency, start, and until
                                                const recurrenceConfig =
                                                    recurrenceInfo.recurrence_config;
                                                frequency =
                                                    recurrenceConfig.frequency
                                                        ? capitalizeFirstLetter(
                                                              recurrenceConfig.frequency
                                                          )
                                                        : "-";
                                                startDateTime = formatDate(
                                                    timestampToDate(
                                                        recurrenceConfig.start
                                                    )
                                                );
                                                endDateTime = formatDate(
                                                    timestampToDate(
                                                        recurrenceConfig.until
                                                    )
                                                );
                                            }
                                        }

                                        return (
                                            <tr key={`${report.key}-${index}`}>
                                                {/* Status Column */}
                                                <td>
                                                    <div
                                                        className={
                                                            styles.statusColumn
                                                        }
                                                    >
                                                        <FiberManualRecordRounded
                                                            className={
                                                                isScheduled
                                                                    ? styles.scheduledIcon
                                                                    : styles.availableIcon
                                                            }
                                                        />
                                                        <span
                                                            className={
                                                                isScheduled
                                                                    ? styles.scheduledText
                                                                    : styles.availableText
                                                            }
                                                        >
                                                            {status}
                                                        </span>
                                                    </div>
                                                </td>
                                                {/* Outfall Name Column */}
                                                <td>{outfallName}</td>
                                                {/* Assigned To Column */}
                                                <td>{assignedTo}</td>
                                                {/* Report Name Column */}
                                                <td>{reportName}</td>
                                                {/* Frequency Column */}
                                                <td>{frequency}</td>
                                                {/* Due Date/Time Column */}
                                                <td>{dueDateTime}</td>
                                                {/* Start Date/Time Column */}
                                                <td>{startDateTime}</td>
                                                {/* End Date/Time Column */}
                                                <td>{endDateTime}</td>
                                                {/* Samples Column */}
                                                <td>
                                                    <div
                                                        className={
                                                            styles.sampleIcon
                                                        }
                                                    >
                                                        <img
                                                            src={sampleIcon}
                                                            alt="Sample Icon"
                                                            onClick={() =>
                                                                handleSampleClick(
                                                                    sampleReportConfigID
                                                                )
                                                            }
                                                            data-testid={`sample-icon-${sampleReportConfigID}`}
                                                        />
                                                    </div>
                                                </td>
                                                {/* Action Column */}
                                                <td>
                                                    <div
                                                        className={
                                                            styles.buttonContainer
                                                        }
                                                    >
                                                        <Button
                                                            size="small"
                                                            onClick={() =>
                                                                handleOpenSchedulingDialog(
                                                                    sampleReportConfigID
                                                                )
                                                            }
                                                            data-testid={`schedule-button-${sampleReportConfigID}`}
                                                        >
                                                            {isScheduled
                                                                ? "Edit Schedule"
                                                                : "Set Schedule"}
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {showSchedulingDialog && (
                <SchedulingDialog
                    open={showSchedulingDialog}
                    onClose={handleCloseSchedulingDialog}
                    value={
                        recurrenceData[selectedSampleReportConfigID] || {
                            schedule_type: "rep",
                            day_or_date: "date",
                            interval: "1",
                            day_of_week: "MO",
                            nth: "1",
                            start: dateToAPIString(new Date()),
                            until: dateToAPIString(new Date()),
                            ad_hoc_date: dateToAPIString(new Date()),
                        }
                    }
                    setValue={(newSchedule) => handleSetSchedule(newSchedule)}
                    minDate={minDate}
                    maxDate={maxDate}
                    data-testid="scheduling-dialog"
                />
            )}
        </div>
    );
};

export default ReportSchedulingStep;
