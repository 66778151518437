import React from "react";
import styles from "../../styles/common/chip.module.css";

/**
 * Simple pill-shaped text chip
 *
 * @param {string} title - Text to be displayed on the chip
 * @param {string} [className] - Optional CSS class used for additional coloring/styling
 */
const Chip = ({ title, className }) => (
    <div className={[styles.main, className].filter(Boolean).join(" ")}>
        <span className={styles.title}>{title}</span>
    </div>
);

/**
 * A shortcut for creating a chip that displays active/inactive status
 *
 * @param {boolean} active - Sets chip text and color to "Active" (green) or "Inactive" (red)
 */
export const ActiveChip = ({ active }) => (
    <Chip title={active ? "Active" : "Inactive"} className={active ? styles.active : styles.inactive} />
);

export default Chip;
