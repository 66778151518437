/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withFormik, Form, Field } from 'formik';
import StateProvSelectField from '../Forms/StateProvSelectField';
import * as Yup from 'yup';

const FormLayout = ({
	errors,
	touched,
	update,
	values,
	hasUpdatePermission,
}) => {

	const submitAction = () => {
		update(values);
	};

	return (
		<React.Fragment>
			<Form key={'organizationInfoForm'} className="profileForm">
				<fieldset disabled={!hasUpdatePermission}>
					<div className="formField">
						<div className="inputField long">
							<label htmlFor="name">Organization Name</label>
							{touched.name && errors.name && <p className="formError formError-first">{errors.name}</p>}
							<Field type="text" name='name' autoComplete="off" className="inputField__input inputField__input-first"></Field>
						</div>
						<div className="inputField long">
							<label htmlFor="address1">Address</label>
							{touched.address1 && errors.address1 && <p className="formError formError-first">{errors.address1}</p>}
							<Field type="text" name='address1' autoComplete="off" className="inputField__input inputField__input-first"></Field>
						</div>
						<div className="inputField long">
							<label htmlFor="address2"></label>
							{touched.address2 && errors.address2 && <p className="formError formError-first">{errors.address2}</p>}
							<Field type="text" name='address2' autoComplete="off" className="inputField__input inputField__input-first"></Field>
						</div>
						<div className="inputField short">
							<label htmlFor="city">City</label>
							{touched.city && errors.city && <p className="formError formError-first">{errors.city}</p>}
							<Field type="text" name='city' autoComplete="off" className="inputField__input inputField__input-first"></Field>
						</div>
						<div className="inputField short">
							<label htmlFor="state_prov">State / Prov</label>
							{touched.state_prov && errors.state_prov && <p className="formError formError-first">{errors.state_prov}</p>}
							<StateProvSelectField
								fieldName="state_prov"
								unabbreviated
							/>
						</div>
						<div className="inputField short">
							<label htmlFor="postal_code">Zip Code</label>
							{touched.postal_code && errors.postal_code && <p className="formError formError-first">{errors.postal_code}</p>}
							<Field type="text" name='postal_code' autoComplete="off" className="inputField__input inputField__input-first"></Field>
						</div>
						{hasUpdatePermission &&
							<React.Fragment>
								<div className="formButtonsBank">
									<button className="medButtonSecondary" type="button" onClick={() => submitAction()}>Save</button>
								</div>
							</React.Fragment>
						}
					</div>
				</fieldset>
			</Form>
		</React.Fragment>
	);
};

const OrgInfo = withFormik({
	enableReinitialize: true,
	mapPropsToValues(props) {
		if (props.data) {
			return {
				name: props.data.name ? props.data.name : '',
				address1: props.data.address1 ? props.data.address1 : '',
				address2: props.data.address2 ? props.data.address2 : '',
				city: props.data.city ? props.data.city : '',
				state_prov: props.data.state_prov ? props.data.state_prov : '',
				postal_code: props.data.postal_code ? props.data.postal_code : ''
			};
		}
	},

	validationSchema: Yup.object().shape({
		name: Yup.string()
			.required('Organization name is required')
		,
		address1: Yup.string()
			.required('Address is required')
		,
		city: Yup.string()
			.required('City is required')
		,
		state_prove: Yup.string()
			.required('State / Prov is required')
		,
		postal_code: Yup.string()
			.required('Postal code is required')
		,
	}),

})(FormLayout);

export default OrgInfo;
