/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import AdditionalDetails from '../AdditionalDetails';
import AutoAccept from "../AutoAccept";
import { withFormik, Form, Field } from 'formik';
import MessageBox from '../MessageBox';
import StateProvSelectField from '../Forms/StateProvSelectField';
import * as Yup from 'yup';

const FormLayout = ({
	data,
	errors,
	touched,
	isSubmitting,
	editMode,
	toggleEditMode,
	resetForm,
	newAddition,
	closeFlyout,
	setFieldValue,
	apiErrors,
	messageOptions,
	providerContacts,
	AASettings,
	AASettingsRef,
	isOrgAdmin,
	getAASettings
}) => {

	const cancelAction = () => {
		if (newAddition) {
			resetForm();
			closeFlyout();
		} else {
			getAASettings();
			toggleEditMode();
			resetForm();
		}
	};

	const getError = (fieldname) => {
		if (apiErrors && apiErrors[fieldname]) {
			return apiErrors[fieldname];
		} else if (touched[fieldname] && errors[fieldname]) {
			return errors[fieldname];
		}
	}

	const handleFormChange = (e) => {
		// this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
		// might be set so we can check it again
		if (apiErrors && apiErrors[e.target.name]) {
			delete apiErrors[e.target.name];
		}
	}

	const getFormErrors = () => {
		let errorList = [];
		messageOptions = {};
		['name', 'address1', 'address2', 'city', 'state_prov', 'postal_code', 'website', 'notes'].forEach((field) => {
			let err = getError(field);
			if (err) {
				errorList.push(err);
			}
		});

		if (errorList.length !== 0) {
			messageOptions = {
				type: "error",
				message: "Please correct the following errors",
				list: errorList,
			};
			document.getElementById('serviceProvidersForm').scrollIntoView();
		}

		return messageOptions;
	}

	let contacts = [];
	providerContacts.forEach(contact => {
		contacts.push(<option key={contact.contact_uuid} value={contact.contact_uuid}>{contact.name}</option>);
	});

	// TODO: I removed the disabled from the fieldset and applied it to each field due to Firefox imcompatibility I observed when
	// dealing the the very similar form code in Location Contacts. The solution Eric came up with is the same as below.
	// It may be worth revisiting later to see if there is another workaround or if it is even necessary in this case. @Dylan
	return (
		<Form className="userDetailsForm" id="serviceProvidersForm">
			<MessageBox options={getFormErrors()} />
			<fieldset>
				<div className="detailsFieldset">
					<div className={'inputField long ' + ((getError('name')) ? 'formRed' : '')}>
						<label htmlFor="name">Service Provider Name *</label>
						<Field type="text" name="name" autoComplete="off" disabled={!editMode} className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('address1')) ? 'formRed' : '')}>
						<label htmlFor="address1">Address *</label>
						<Field type="text" name="address1" autoComplete="off" disabled={!editMode} className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					{(editMode || data.address2 != "") && (
						<div className={'inputField long ' + ((getError('address2')) ? 'formRed' : '')}>
							<Field type="text" name="address2" autoComplete="off" disabled={!editMode} className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
					)}
					<div className={'inputField small ' + ((getError('city')) ? 'formRed' : '')}>
						<label htmlFor="city">City *</label>
						<Field type="text" name="city" autoComplete="off" disabled={!editMode} className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField small ' + ((getError('state_prov')) ? 'formRed' : '')}>
						<label htmlFor="state_prov">State / Prov *</label>
						<StateProvSelectField
							fieldName="state_prov"
							disabled={!editMode}
							handleChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField small ' + ((getError('postal_code')) ? 'formRed' : '')}>
						<label htmlFor="postal_code">Postal Code *</label>
						<Field type="text" name="postal_code" autoComplete="off" disabled={!editMode} className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('website')) ? 'formRed' : '')}>
						<label htmlFor="phone">Phone Number</label>
						<Field type="text" name="phone" autoComplete="off" disabled={!editMode} className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('primary_contact_uuid')) ? 'formRed' : '')}>
						<label htmlFor="primary_contact_uuid">Primary Contact</label>
						<Field component="select" name="primary_contact_uuid" autoComplete="off" disabled={!editMode} className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						>
							<option value=""></option>
							{contacts}
						</Field>
					</div>
					<div className={'inputField long ' + ((getError('website')) ? 'formRed' : '')}>
						<label htmlFor="website">Website</label>
						<Field type="text" name="website" autoComplete="off" disabled={!editMode} className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					{
						// hiding notes for now since both orgs and providers can see it so don't want someone to put something
						// private in there that the providers then see
					}
					{false && (
						<div className={'inputField long ' + ((getError('notes')) ? 'formRed' : '')}>
							<label htmlFor="notes">Notes</label>
							<Field type="text" name="notes" autoComplete="off" disabled={!editMode} className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
					)}
				</div>
			</fieldset>
			<AdditionalDetails
				details={data.c3_additional_details}
				editMode={editMode}
			/>
			<AutoAccept
				data={data}
				newAddition={newAddition}
				editMode={editMode}
				AASettings={AASettings}
				AASettingsRef={AASettingsRef}
				hasPermissions={isOrgAdmin}
			/>
			{editMode && (
				<React.Fragment>
					<div className="formButtonsBank">
						<button className="medButtonPrimary" disabled={isSubmitting} type="submit">Save</button>
						<button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
					</div>
				</React.Fragment>
			)}
		</Form>
	);
};

const ServiceProviderDetailsForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,
	enableReinitialize: true,
	mapPropsToValues(props) {
		let values = {
			name: (props.data.name ? props.data.name : ''),
			address1: (props.data.address1 ? props.data.address1 : ''),
			address2: (props.data.address2 ? props.data.address2 : ''),
			city: (props.data.city ? props.data.city : ''),
			state_prov: (props.data.state_prov ? props.data.state_prov : ''),
			postal_code: (props.data.postal_code ? props.data.postal_code : ''),
			website: (props.data.website ? props.data.website : ''),
			notes: (props.data.notes ? props.data.notes : ''),
			phone: (props.data.phone ? props.data.phone : ''),
		};

		props.providerContacts.forEach(contact => {
			if (contact.primary_contact) {
				values.primary_contact_uuid = contact.contact_uuid;
			}
		});

		for (let uuid in props.data.c3_additional_details) {
			values[uuid] =
				(props.data.c3_additional_details[uuid].value && props.data.c3_additional_details[uuid].value !== "false")
					? props.data.c3_additional_details[uuid].value
					: '';
		}

		return values;
	},

	validationSchema: Yup.object().shape({
		name: Yup.string().required('Service Provider name is required'),
		address1: Yup.string().required('Address is required'),
		address2: Yup.string().notRequired(),
		city: Yup.string().required('City is required'),
		state_prov: Yup.string().required('State / Prov is required'),
		postal_code: Yup.string().required('Zip Code is required'),
		website: Yup.string().strip(true).nullable(true),
		notes: Yup.string().strip(true).nullable(true)
	}),

	handleSubmit(values, { props, resetForm, setSubmitting }) {
		const country = { 'country_code': SwiftComply.organization.country_code };
		let uuid;
		if (props.data) {
			uuid = props.data.service_provider_uuid;
		}

		props.saveDetails(props.context, uuid, {
			...values, ...country
		});
		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
		}, 3000);
	}
})(FormLayout);

export default ServiceProviderDetailsForm;