import React, { useEffect, useState } from "react";
import { getContact, getContactDetails } from "../../../api/contactsAPI";
import { getURLParentObject } from "../../../utils/url";
import { getForm, getFormFields } from "../../../api/formsAPI";
import { BasicContactFields } from "./BasicContactFields";
import { detailsReportMapping } from "../../../utils/reporting";
import { reportInputToFilterField } from "../../../utils/forms";
import Page from "../Page";
import Card from "../Card";
import EditContact from "./EditContact";
import SummaryGrid from "../Forms/SummaryGrid";

const ViewContact = () => {
    const { id: contactId } = getURLParentObject("c") || {};
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [basicFields, setBasicFields] = useState({});
    const [detailsFields, setDetailsFields] = useState({});
    const [detailsFieldsLabels, setDetailsFieldsLabels] = useState({});
    const [editMode, setEditMode] = useState(false);

    const refreshContact = () => {
        setLoading(true);
        setError(null);

        getContact(contactId)
            .then((data) =>
                setBasicFields(BasicContactFields.reduce((prev, curr) => ({ ...prev, [curr.id]: data[curr.id] }), {}))
            )
            .then(() => getContactDetails(contactId))
            .then((response) =>
                setDetailsFields(
                    response.details?.reduce((prev, curr) => ({ ...prev, [curr.form_field_uuid]: curr.value }), {}) ||
                        {}
                )
            )
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const onEditComplete = () => {
        setEditMode(false);
        refreshContact();
    };

    useEffect(() => {
        setLoading(true);
        setError(null);

        getForm(detailsReportMapping.contact)
            .then((data) => (data?.form_uuid ? getFormFields(data.form_uuid) : { form_fields: [] }))
            .then((fields) => {
                setDetailsFieldsLabels(
                    fields.form_fields?.reduce(
                        (prev, curr) => ({
                            ...prev,
                            [curr.form_field_uuid]: reportInputToFilterField(curr),
                        }),
                        {}
                    )
                );
            })
            .catch(setError)
            .finally(() => setLoading(false));

        refreshContact();
    }, []);

    if (editMode && !loading && !error) {
        return (
            <EditContact
                preloadedBasicState={basicFields}
                preloadedDetailsState={detailsFields}
                onEditComplete={onEditComplete}
                onEditCancelled={() => setEditMode(false)}
            />
        );
    }

    const fields = [...BasicContactFields, ...Object.entries(detailsFieldsLabels).map((f) => ({ ...f[1], id: f[0] }))];

    const values = {
        ...basicFields,
        ...detailsFields,
    };

    return (
        <Page loading={loading} error={error}>
            <Card
                title={[basicFields.firstname, basicFields.lastname].filter(Boolean).join(" ") || "View Contact"}
                onEdit={() => setEditMode(true)}
            >
                <SummaryGrid fields={fields} formState={values} />
            </Card>
        </Page>
    );
};

export default ViewContact;
