import axios from 'axios';

export const getEquipmentReport = (payload) => {
	const url = '/api/v1/reporting/b54a82b3-ae76-4c06-94cb-8098bdbf4e56';

	return axios.post(url, payload)
		.then(response => {
			console.log('Got Equipment Report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Equipment Report!', error.response.data);
			throw error;
		});
};

export const getPropertyEquipmentList = (locationUUID) => {
	const url = `/api/v1/equipment/properties/${locationUUID}`;

	return axios.get(url)
		.then(response => {
			console.log('Got Property Equipment List!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get Property Equipment List!', error.response.data);
			throw error;
		});
};

export const getPropertyEquipmentReport = (payload) => {
	const url = `/api/v1/reporting/7e79f121-d53f-4494-9912-073ee8a20ee0`;

	return axios.post(url, payload)
		.then(response => {
			console.log('Got Property Equipment Report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Property Equipment Report!', error.response.data);
			throw error;
		});
};

export const getEquipmentList = () => {
	const url = '/api/v1/equipment';

	return axios.get(url)
		.then(response => {
			console.log('Got Equipment List!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get Equipment List!', error.response.data);
			throw error;
		});
};

export const getEquipment = (assemblyUUID) => {
	const url = `/api/v1/equipment/${assemblyUUID}`;

	return axios.get(url)
		.then(response => {
			console.log('Got Equipment!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get Equipment!', error.response.data);
			throw error;
		});
};

export const createEquipment = (payload) => {
	const url = '/api/v1/equipment';

	return axios.post(url, payload)
		.then(response => {
			console.log('Created Equipment!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create Equipment!', error.response.data);
			throw error;
		});
};

export const updateEquipment = (equipmentUUID, payload) => {
	const url = `/api/v1/equipment/${equipmentUUID}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated Equipment!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t update Equipment!', error.response.data);
			throw error;
		});
};

export const deleteEquipment = (equipmentUUID) => {
	const url = `/api/v1/equipment/${equipmentUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Deleted Equipment!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t delete Equipment!', error.response.data);
			throw error;
		});
};

export const getEquipmentProperty = async (equipmentUUID) => {
	const url = `/api/v1/equipment/${equipmentUUID}/properties`;

	return axios.get(url)
		.then(response => {
			console.log('Got Equipment Properties!', response);
			// equipment may have multiple properties it was attached to in the past, we only care about currently
			// active locations, so if a removed_on date was set, we are going to ignore it.  If no active property
			// is set, we will just return blank here.
			let data = {};
			if (response.data.equipment_properties) {
				for (let i = 0; i < response.data.equipment_properties.length; i++) {
					if (!response.data.equipment_properties[i].removed_on) {
						data = response.data.equipment_properties[i];
					}
				}
			}
			return data;
		})
		.catch(error => {
			console.log('Couldn\'t get Equipment Properties!', error.response.data);
			throw error;
		});
};

export const createEquipmentProperty = async (equipmentUUID, propertyUUID, notes) => {
	const url = `/api/v1/equipment/${equipmentUUID}/properties`;

	let payload = { 'property_uuid': propertyUUID };
	// notes or "equipment location" is a newer addition so only add it if we sent it
	if (notes) {
		payload.notes = notes;
	}

	await axios.post(url, payload)
		.then(response => {
			console.log('Created Property Equipment!', response);
		})
		.catch(error => {
			console.log('Couldn\'t create Property Equipment!', error.response.data);
			throw error;
		});

};

export const updateEquipmentProperty = async (equipmentUUID, propertyUUID, payload) => {
	const url = `/api/v1/equipment/${equipmentUUID}/properties/${propertyUUID}`;

	await axios.put(url, payload)
		.then(response => {
			console.log('Updated Property Equipment', response);
		})
		.catch(error => {
			console.log('Couldn\'t update Property Equipment', error.response.data);
			throw error;
		});

};

export const deleteEquipmentProperty = async (equipmentUUID, propertyUUID) => {
	const url = `/api/v1/equipment/${equipmentUUID}/properties/${propertyUUID}`;

	await axios.delete(url)
		.then(response => {
			console.log('Deleted Property Equipment!', response);
		})
		.catch(error => {
			console.log('Couldn\'t delete Property Equipment!', error.response.data);
			throw error;
		});

};

export const createServiceProviderEquipment = async (serviceProviderUUID, payload) => {
	const equipmentURL = '/api/v1/equipment';

	const equipmentInfo = await axios.post(equipmentURL, payload);
	const equipmentUUID = equipmentInfo.data.equipment_uuid;

	await axios.post(`/api/v1/service_providers/${serviceProviderUUID}/equipment`, {
		'equipment_uuid': equipmentUUID,
		'user_uuid': payload.assigned_to,
		'owner': (payload.owner) ? true : false
	})
		.then(response => {
			console.log('Created Service Provider Equipment!', response);
		})
		.catch(error => {
			console.log('Couldn\'t create Service Provider Equipment!', error.response.data);
			throw error;
		});

	return equipmentInfo.data;
};

export const addEquipmentToServiceProvider = async (serviceProviderUUID, equipmentUUID, payload) => {

	return axios.post(`/api/v1/service_providers/${serviceProviderUUID}/equipment`, {
		'equipment_uuid': equipmentUUID,
		'user_uuid': payload.assigned_to,
		'owner': (payload.owner) ? true : false
	})
		.then(response => {
			console.log('Added equipment to service provider', response);
			return payload;
		})
		.catch(error => {
			console.log('Couldn\'t add equipment to service provider', error.response.data);
			throw error;
		});
};



export const updateServiceProviderEquipment = async (serviceProviderUUID, equipmentUUID, payload) => {
	const url = `/api/v1/service_providers/${serviceProviderUUID}/equipment/${equipmentUUID}`;

	await axios.put(url, {
		'user_uuid': payload.assigned_to,
		'owner': (payload.owner) ? true : false
	})
		.then(response => {
			console.log('Updated Service Provider Equipment!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t update Service Provider Equipment!', error.response.data);
			throw error;
		});
};

// DETAILS

export const getEquipmentDetails = (equipmentUUID) => {
	const url = `/api/v1/equipment/${equipmentUUID}/details`;

	return axios.get(url)
		.then(response => {
			console.log('Got Equipment Details!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Equipment Details!', error.response.data);
			throw error;
		});
};

export const saveEquipmentDetail = async (equipmentUUID, payload) => {
	const url = `/api/v1/equipment/${equipmentUUID}/details`;

	return axios.post(url, payload)
		.then(response => {
			console.log('Created Equipment Detail!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create Equipment Detail!');
			throw error;
		});
};

export const getEquipmentCompliance = async (equipmentUUID, type) => {
	const url = `/api/v1/equipment/${equipmentUUID}/compliance/${type}`;

	return axios.get(url)
		.then(response => {
			console.log('Got equipment compliance', response);
			return response.data;
		})
		.catch(error => {
			console.log('Could\'nt get equipment compliance', error);
			throw error;
		});
};

export const listEquipmentCompliance = async (equipmentUUID) => {
	const url = `/api/v1/equipment/${equipmentUUID}/compliance`;

	return axios.get(url)
		.then(response => {
			console.log('Got list of equipment compliance', response);
			return response.data;
		})
		.catch(error => {
			console.log('Could\'nt get list of equipment compliance', error);
			throw error;
		});
};

export const updateEquipmentCompliance = async (equipmentUUID, type, payload) => {
	const url = `/api/v1/equipment/${equipmentUUID}/compliance/${type}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated equipment compliance', response);
			return response.data;
		})
		.catch(error => {
			console.log('Could\'nt update equipment compliance', error);
			throw error;
		});
};

export const updateEquipmentOverallCompliance = async (equipmentUUID, payload) => {
	const url = `/api/v1/equipment/${equipmentUUID}/overall_compliance`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated overall equipment compliance', response);
			return response.data;
		})
		.catch(error => {
			console.log('Could\'nt update overall equipment compliance', error);
			throw error;
		});
};

export const acceptEquipment = (equipmentUUID) => {
	const url = `/api/v1/equipment/${equipmentUUID}/accept`;

	return axios.post(url)
		.then(response => {
			console.log('Accepted Equipment', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Accept Equipment', error.response.data);
			throw error;
		});
};

export const rejectEquipment = (equipmentUUID) => {
	const url = `/api/v1/equipment/${equipmentUUID}/reject`;

	return axios.post(url)
		.then(response => {
			console.log('Rejected Equipment', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Reject Equipment', error.response.data);
			throw error;
		});
};

// state report / reporting fields are their own calls now
export const getEquipmentSRFields = (equipmentUUID) => {
	const url = `/api/v1/equipment/${equipmentUUID}/srfields`;

	return axios.get(url)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			// this equipment has no sr fields set, so we don't want to throw an error here, just return nothing
			if (error.response.status == 404) {
				return {};
			}
			throw error;
		});
};

export const updateEquipmentSRFields = (equipmentUUID, srfields) => {
	const url = `/api/v1/equipment/${equipmentUUID}/srfields`;

	let payload = { sr_fields: JSON.stringify(srfields) };

	return axios.put(url, payload)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			throw error;
		});
};