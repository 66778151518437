/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import { withFormik, Form, Field } from 'formik';
import { login } from '../api/authAPI';
import MessageBox from './MessageBox';
import * as Yup from 'yup';

const FormLayout = ({
	errors,
	status,
	touched,
	isSubmitting,
	setFieldValue,
	messageOptions
}) => {
	const getError = (fieldname) => {
		if (touched[fieldname] && errors[fieldname]) {
			return errors[fieldname];
		}
	}

	// Since this is a pretty simple form, any change that happens we want to blank out all the errors.
	// Mostly because when we have an invalid login, we just set the email field as an error.  But likely 99% of the 
	// time its just going to be a typo in the password, so if they change that we need to make sure we clear all the
	// errors so they can submit again easily
	const handleFormChange = (e) => {
		errors = {};
	}

	const getFormErrors = () => {
		// status will be set if there is something the user can't do to correct the issue
		if (status && status.login_error !== "") {
			return {
				type: "warning",
				message: status.login_error
			};
		}

		let errorList = [];
		messageOptions = {};
		['email', 'password'].forEach((field) => {
			let err = getError(field);
			if (err) {
				errorList.push(err);
			}
		});

		if (errorList.length !== 0) {
			messageOptions = {
				type: "error",
				message: "Please correct the following errors",
				list: errorList,
			};
		}

		return messageOptions;
	}

	return (
		<React.Fragment>
			<MessageBox options={getFormErrors()} />
			<Form className="loginForm">
				<label htmlFor="email">Email</label>
				<div className="inputField">
					<Field type="email" name="email" className="inputField__input inputField__input-first"
						onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
					/>
				</div>
				<label htmlFor="password">Password</label>
				<div className="inputField">
					<Field id="passwordField" type="password" name="password"
						onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
					/>
				</div>
				<button className="mainBtn" disabled={isSubmitting} type="submit">LOGIN</button>
			</Form>
		</React.Fragment>
	);
};

const LoginForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,
	mapPropsToValues() {
		return {
			email: '',
			password: ''
		};
	},

	validationSchema: Yup.object().shape({
		email: Yup.string().email()
			.required('Please enter your email'),
		password: Yup.string()
			.required('Please enter your password'),
	}),

	handleSubmit(values, { setFieldValue, setSubmitting, setErrors, setStatus }) {
		try {
			// wrap in try so that if we have disabled logins or another message to return we can catch it and set the status
			login(values).catch(e => {
				setErrors({ email: e });
			});
		} catch (e) {
			setStatus({ login_error: e });
		}
		setFieldValue('password', '', false);
		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
		}, 1000);
	}
})(FormLayout);

export default LoginForm;