import React from "react";
import AssemblyDetailsForm from "./AssemblyDetailsForm";
import editModeIcon from "../../../images/editMode.svg";
import {
  getEquipment,
  updateEquipment,
  createEquipment,
  deleteEquipment,
  createEquipmentProperty,
  getEquipmentProperty,
  getEquipmentDetails,
  saveEquipmentDetail,
  getEquipmentCompliance,
  updateEquipmentProperty,
  updateEquipmentCompliance,
  updateEquipmentOverallCompliance,
  deleteEquipmentProperty,
  acceptEquipment,
  rejectEquipment,
  getEquipmentSRFields,
  updateEquipmentSRFields,
} from "../../api/equipmentAPI";
import { getForm, getFormFields } from "../../api/formsAPI";
import { APIUserError } from "../../api/api";
import { checkDuplicates } from "../../api/duplicatesAPI";
import { ModalDuplicateContext } from "../../contexts/ModalDuplicateContext";
import { updateURL } from "../../utils/url";
import validator from "validator";
import ModalEquipment from "../ModalEquipment";
import moment, * as Moment from "moment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";

const dupFields = [
  { name: "Type", data: "equipment_type" },
  { name: "Make", data: "make" },
  { name: "Model", data: "model" },
];

class AssemblyDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      newAddition: props.newAddition,
      apiErrors: {},
      data: {},
      lastTest: null,
      replacement: false,
      modalOpen: false,
      replacementData: null,
      deleteMode: false,
      removeMode: false,
      setInactive: false,
    };
  }

  componentDidMount() {
    if (this.state.newAddition) {
      this.toggleEditMode();
      this.getAdditionalDetailsInfo().then((assemblyData) => {
        this.setState({ data: assemblyData });
      });
    } else {
      this.getAssemblyData(this.props.uuid);
    }
  }

  getAssemblyData = async (uuid) => {
    if (uuid !== undefined && uuid !== "") {
      let assemblyData = await getEquipment(uuid);
      this.props.setHeader(assemblyData.serial_number, assemblyData.active);
      assemblyData = await this.getAdditionalDetailsInfo(assemblyData);

      let srresp = await getEquipmentSRFields(uuid);
      assemblyData.sr_fields = srresp.sr_fields;

      // we were combining this with the assemblyProperties one below which would override some fields
      let data = assemblyData;

      // Grabbing equipment properties here and adding to equipment data in state
      const assemblyProperties = await getEquipmentProperty(
        assemblyData.equipment_uuid,
      );
      data.property_uuid = assemblyProperties.property_uuid;
      data.installed_on = assemblyProperties.installed_on;
      data.deactivated_on = assemblyProperties.deactivated_on;
      data.removed_on = assemblyProperties.removed_on;
      data.replaced_by = assemblyProperties.replaced_by;
      data.location_notes = assemblyProperties.notes;

      let inspection = null;
      try {
        inspection = await getEquipmentCompliance(
          assemblyData.equipment_uuid,
          "Assembly Test",
        );
      } catch (e) {
        if (e.response && e.response.status === 404) {
          // No inspection found; silently ignore 404
          inspection = null;
        } else {
          // Log other types of errors
          console.error("Error fetching equipment compliance:", e);
        }
      }
      this.setState({ data, lastTest: inspection });
      return data;
    }
  };

  getAdditionalDetailsInfo = async (assemblyData) => {
    if (assemblyData === undefined) {
      assemblyData = {};
    }
    assemblyData.c3_additional_details = {};

    let form = await getForm("equipment details");
    if (form != null) {
      let fields = await getFormFields(form.form_uuid);
      fields.form_fields.forEach((field) => {
        assemblyData.c3_additional_details[field.form_field_uuid] = field;
      });

      if (assemblyData.equipment_uuid !== undefined) {
        let details = await getEquipmentDetails(assemblyData.equipment_uuid);
        details.equipment_details.forEach((detail) => {
          // check to make sure we know about this field (detail) as we could have had old details
          // from old custom fields returned
          if (assemblyData.c3_additional_details[detail.form_field_uuid]) {
            assemblyData.c3_additional_details[detail.form_field_uuid].value =
              detail.value;
          }
        });
      }
    }

    return assemblyData;
  };

  saveDetails = async (ctx, payload, properties) => {
    const { data } = this.state;
    if (this.state.newAddition && this.props.duplicateCheck) {
      this.props.duplicateCheck(
        ctx,
        payload,
        dupFields,
        this.useSelectedDuplicate,
        this.saveNewAssembly,
      );
      return;
    }

    if (this.state.newAddition) {
      this.handleDuplicateCheck(ctx, payload);
    } else {
      let editMode = false;
      let apiErrors = {};
      let updateCompliancePayload = {};
      try {
        if (this.props.update) {
          await this.props.update(payload);
        }

        const updatedEquipment = await updateEquipment(
          data["equipment_uuid"],
          payload,
        );
        // If successful update of assembly, check if needs review is true in which we will automatically accept this assembly since
        // if it's being edited it must be a valid assembly or has been corrected to be so.
        if (this.state.data.needs_review === true) {
          await acceptEquipment(this.state.data.equipment_uuid);
          await this.getAssemblyData(this.props.uuid);
        }

        updateEquipmentSRFields(data["equipment_uuid"], payload.sr_fields);

        // An assembly, for now, can only replace another assembly if that assembly is
        // attached to a property. Maybe later this should be possible for all assemblies, and if
        // then old assembly was attached to a property, auto-attach the new assembly to that property.
        if (data["property_uuid"]) {
          Object.keys(properties).map((property) => {
            updateEquipmentProperty(
              data["equipment_uuid"],
              data["property_uuid"],
              {
                [property]: properties[property],
              },
            );
          });
        }
        await this.saveAdditionalDetails(data["equipment_uuid"], payload);
        this.props.setHeader(
          updatedEquipment.serial_number,
          updatedEquipment.active,
        );

        // if the next_test or compliance_date comes back as something like 2020-04-20 then they didn't actually
        // change the field, but if it came back as an epoch, they did, so if so save it
        if (payload.next_test && Number.isInteger(payload.next_test)) {
          updateCompliancePayload.expiration_date = payload.next_test;
        }
        if (
          payload.compliance_date &&
          Number.isInteger(payload.compliance_date)
        ) {
          updateCompliancePayload.compliance_date = payload.compliance_date;
        }
        // update compliant if we set it (it comes to here as a string) and the last test is null or if it
        // doesn't match what the last test was
        if (
          (payload.compliant !== "" && this.state.lastTest === null) ||
          (payload.compliant === "true" &&
            this.state.lastTest.compliant === false) ||
          (payload.compliant === "false" &&
            this.state.lastTest.compliant === true)
        ) {
          updateCompliancePayload.compliant =
            payload.compliant === "true" ? true : false;
        }
        if (Object.keys(updateCompliancePayload).length > 0) {
          // if we updated any of the compliance data, send that to the backend now
          await updateEquipmentOverallCompliance(
            this.state.data["equipment_uuid"],
            updateCompliancePayload,
          );
          await updateEquipmentCompliance(
            this.state.data["equipment_uuid"],
            "Assembly Test",
            updateCompliancePayload,
          );
        }
      } catch (error) {
        // if we got an error don't take them out of edit mode
        // editMode = true;
        if (error instanceof APIUserError) {
          apiErrors = error.apiFieldErrors;
          if (error.apiFieldErrors["expiration_date"]) {
            delete error.apiFieldErrors["expiration_date"];
            apiErrors["next_test"] =
              "Next test due date can not be set to before the last test date";
          }
        }
      }
      // note that this was an attempt to make sure that if the user didn't set a compliant even though one was
      // already set that we would merge the lastTest info into the new update and it would show the user that its
      // still set.  However I guess because the state isn't changing, and even though formik it running its mapPropsToValues
      // it still shows blank until they reload.
      let lastTest = { ...this.state.lastTest, ...updateCompliancePayload };
      this.setState({
        editMode,
        apiErrors,
        ...(Object.keys(lastTest).length !== 0 && { lastTest }),
      });
      this.props.refresh();
    }
  };

  handleDuplicateCheck = (ctx, payload, options) => {
    if (options === undefined) {
      options = {};
    }
    let conditionals = [
      { field: "equipment_type", value: "TK", operator: "neq" },
    ];
    if (payload.equipment_uuid) {
      conditionals.push({
        field: "equipment_uuid",
        value: payload.equipment_uuid,
        operator: "neq",
      });
    }
    checkDuplicates("equipment", payload.serial_number, conditionals).then(
      (response) => {
        if (response.duplicates.length > 0) {
          ctx.setDuplicates(
            response.duplicates,
            dupFields,
            "/assemblies",
            payload,
            options.disableSave ? null : this.saveNewAssembly,
            this.useSelectedDuplicate,
          );
        } else if (!options.disableSave) {
          this.saveNewAssembly(payload);
        }
      },
    );
  };

  saveNewAssembly = async (payload) => {
    try {
      let assemblyData;
      let replacement = {};
      let { replacementData } = this.state;
      if (this.props.create) {
        assemblyData = await this.props.create(payload);
      } else {
        // When saving a new assembly that is a replacement, this will fill in the replaced_by field with the new
        // assembly being created, then remove that assembly from the location since it is being replaced.
        if (replacementData) {
          // This fires when the replacement is coming from the locations page and assemblies tab.
          if (this.props.source === "property") {
            replacement.serial = replacementData.values[5];
            replacement.uuid = replacementData.values[0];
            replacement.source = this.props.sourceUUID;
            // This fires when searching by location from the assemblies page.
          } else if (replacementData.locationUUID) {
            replacement.serial = replacementData.serial_number;
            replacement.uuid = replacementData.uuid;
            replacement.source = replacementData.locationUUID;
            // This fires when searching by serial from the assemblies page.
          } else if (replacementData.property) {
            replacement.serial = replacementData.serial_number;
            replacement.uuid = replacementData.uuid;
            replacement.source = replacementData.property;
          }
          if (
            confirm(
              this.state.setInactive
                ? `Are you sure you want to replace assembly #${replacement.serial} with this new assembly and set it as inactive?`
                : `Are you sure you want to replace assembly #${replacement.serial} with this new assembly?`,
            )
          ) {
            assemblyData = await createEquipment(payload);
            await createEquipmentProperty(
              assemblyData.equipment_uuid,
              replacement.source,
            );
            await updateEquipmentProperty(
              assemblyData.equipment_uuid,
              replacement.source,
              {
                notes: payload.location_notes,
                installed_on: payload.installed_on
                  ? Moment(payload.installed_on).format("X")
                  : null,
              },
            );
            if (this.state.setInactive) {
              await updateEquipment(replacement.uuid, {
                active: false,
              });
            }
            await updateEquipmentProperty(
              replacement.uuid,
              replacement.source,
              {
                replaced_by: assemblyData.equipment_uuid,
                removed_on: Moment().unix(),
              },
            );
            this.props.setHeader(
              assemblyData.serial_number,
              assemblyData.active,
            );
            await this.saveAdditionalDetails(
              assemblyData.equipment_uuid,
              payload,
            );
            await updateEquipmentSRFields(
              assemblyData.equipment_uuid,
              payload.sr_fields,
            );
            const data = await this.getAssemblyData(
              assemblyData.equipment_uuid,
            );
            this.setState({ editMode: false, newAddition: false });
            await this.props.refresh();
            await this.props.openFlyout(data, null);
          }
        } else {
          assemblyData = await createEquipment(payload);
          if (this.props.sourceUUID) {
            await createEquipmentProperty(
              assemblyData.equipment_uuid,
              this.props.sourceUUID,
            );
            await updateEquipmentProperty(
              assemblyData.equipment_uuid,
              this.props.sourceUUID,
              {
                notes: payload.location_notes,
                installed_on: payload.installed_on
                  ? Moment(payload.installed_on).format("X")
                  : null,
              },
            );
          }
          this.props.setHeader(assemblyData.serial_number, assemblyData.active);
          await this.saveAdditionalDetails(
            assemblyData.equipment_uuid,
            payload,
          );
          await updateEquipmentSRFields(
            assemblyData.equipment_uuid,
            payload.sr_fields,
          );

          const data = await this.getAssemblyData(assemblyData.equipment_uuid);
          this.setState({ editMode: false, newAddition: false });
          await this.props.refresh();
          await this.props.openFlyout(data, null);
        }
      }
    } catch (error) {
      if (error instanceof APIUserError) {
        this.setState({ apiErrors: error.apiFieldErrors });
      }
    }
  };

  useSelectedDuplicate = async (uuid) => {
    if (this.props.source === "property") {
      try {
        await createEquipmentProperty(uuid, this.props.sourceUUID);
      } catch (error) {
        if (error instanceof APIUserError) {
          if (error.message.includes("at another property"))
            this.setState({
              apiErrors: {
                serial_number:
                  "This assembly is already installed at another location.  Please remove it from that location first.",
              },
            });
        }
        return;
      }
    } else {
      updateURL("/assemblies/" + uuid, {});
    }
    const data = await this.getAssemblyData(uuid);
    this.setState({ editMode: false, newAddition: false });
    if (this.props.refresh) {
      this.props.refresh();
    }
    await this.props.openFlyout(data, null);
  };

  saveAdditionalDetails = async (uuid, payload) => {
    for (let fieldName in payload) {
      // so janky but whatever
      if (validator.isUUID(fieldName)) {
        if (
          this.state.data.c3_additional_details[fieldName].value !==
          payload[fieldName]
        ) {
          saveEquipmentDetail(uuid, {
            form_field_uuid: fieldName,
            value: "" + payload[fieldName], // make sure what we send to the API is a string
          });
        }
      }
    }
  };

  toggleEditMode = () => {
    this.setState((prevState) => ({
      editMode: !prevState.editMode,
      deleteMode: false,
    }));
  };

  toggleSetInactive = () => {
    this.setState(() => ({ setInactive: false }));
  };

  deleteThisEquipment = async (type) => {
    if (type === "delete") {
      await deleteEquipment(this.state.data["equipment_uuid"]);
      await this.props.refresh();
      await this.props.closeFlyout();
    } else if (type === "remove") {
      await deleteEquipmentProperty(
        this.state.data.equipment_uuid,
        this.state.data.property_uuid,
      );
      await this.props.refresh();
      await this.props.closeFlyout();
    } else if (type === "cancel") {
      this.setState(() => ({ deleteMode: false }));
    } else {
      this.setState(
        () => ({ deleteMode: true }),
        () => {
          setTimeout(() => {
            document.getElementById("flyoutBase").scrollIntoView(false);
          }, 100);
        },
      );
    }
  };

  removeThisEquipment = async (action) => {
    if (action === "remove") {
      await updateEquipmentProperty(
        this.state.data["equipment_uuid"],
        this.state.data["property_uuid"],
        {
          removed_on: Moment().unix(),
        },
      );
      await this.props.refresh();
      await this.props.closeFlyout();
    } else if (action === "cancel") {
      this.setState(() => ({ removeMode: false }));
    } else {
      this.setState(
        () => ({ removeMode: true }),
        () => {
          setTimeout(() => {
            document.getElementById("flyoutBase").scrollIntoView(false);
          }, 100);
        },
      );
    }
  };

  setEquipmentAsInactive = async () => {
    let warningText;
    if (this.state.data.active) {
      warningText = `Are you sure you want to set assembly #${this.state.data["serial_number"]} as inactive?`;
    } else {
      warningText = `Are you sure you want to reactivate assembly #${this.state.data["serial_number"]} ?`;
    }

    if (confirm(warningText)) {
      await updateEquipment(this.state.data["equipment_uuid"], {
        active: !this.state.data.active,
      });
      await this.props.refresh();
      const data = await this.getAssemblyData(this.props.uuid);
      this.setState({ editMode: false }, () => {
        this.props.openFlyout(data, null);
      });
    } else {
      null;
    }
  };

  checkReplacement = (e, alternate) => {
    let checked;
    if (e) {
      checked = e.target.checked;
    }
    if (checked || alternate) {
      this.handleModalOpen();
    } else {
      this.setState(
        () => ({ replacement: false }),
        () => {
          this.handleModalClose("unchecked");
        },
      );
    }
  };

  setReplacement = (payload, locationUUID, setInactive) => {
    if (locationUUID) {
      this.setState(
        () => ({
          replacementData: { ...payload, locationUUID: locationUUID },
          replacement: true,
          setInactive,
        }),
        async () => {
          this.handleModalClose();
        },
      );
    } else {
      this.setState(
        () => ({ replacementData: payload, replacement: true, setInactive }),
        async () => {
          if (!this.props.newAddition) {
            if (
              confirm(
                setInactive
                  ? `Are you sure you want to replace assembly #${this.state.data.serial_number} with assembly #${this.state.replacementData[5]} and set it as inactive?`
                  : `Are you sure you want to replace assembly #${this.state.data.serial_number} with assembly #${this.state.replacementData[5]}?`,
              )
            ) {
              if (setInactive) {
                await updateEquipment(this.state.data["equipment_uuid"], {
                  active: false,
                });
              }
              await updateEquipmentProperty(
                this.state.data.equipment_uuid,
                this.state.data.property_uuid,
                {
                  replaced_by: this.state.replacementData[0],
                  removed_on: Moment().unix(),
                },
              );
              await this.props.refresh();
              await this.props.closeFlyout();
            }
          }
          this.handleModalClose();
        },
      );
    }
  };

  replaceEquipment = () => {
    this.checkReplacement(null, true);
  };

  handleModalOpen = () => {
    this.setState(() => ({ modalOpen: true }));
  };

  handleModalClose = (type) => {
    this.setState(
      () => ({ modalOpen: false }),
      () => {
        if (
          this.state.replacementData &&
          this.state.replacementData.length <= 0
        ) {
          this.setState(() => ({
            replacement: false,
            setInactive: false,
            replacementData: [],
          }));
        }
      },
    );
  };

  reviewAction = async (type) => {
    if (type === "accept") {
      await acceptEquipment(this.state.data.equipment_uuid);
      this.getAssemblyData(this.props.uuid);
    } else if (type === "reject") {
      await rejectProperty(this.state.data.equipment_uuid);
      this.getAssemblyData(this.props.uuid);
    }
  };

  render() {
    if (!this.state.data) {
      return <div />;
    }
    return (
      <ModalDuplicateContext.Consumer>
        {(ctx) => {
          return (
            <div className="flyoutContentContainer" id="flyoutBase">
              <button
                className="editModeButton"
                onClick={() => this.toggleEditMode()}
              >
                {!this.state.editMode && <img src={editModeIcon} alt="" />}
              </button>
              {this.state.data.c3_additional_details !== undefined && (
                <React.Fragment>
                  <AssemblyDetailsForm
                    context={ctx}
                    data={this.state.data}
                    lastTest={this.state.lastTest}
                    editMode={this.state.editMode}
                    saveDetails={this.saveDetails}
                    toggleEditMode={this.toggleEditMode}
                    newAddition={this.props.newAddition}
                    closeFlyout={this.props.closeFlyout}
                    apiErrors={this.state.apiErrors}
                    assignToOptions={this.props.assignToOptions}
                    defaultAssignTo={this.props.defaultAssignTo}
                    duplicateCheck={this.handleDuplicateCheck}
                    checkReplacement={this.checkReplacement}
                    replacement={this.state.replacement}
                    source={this.props.source}
                    replacementData={this.state.replacementData}
                    setInactive={this.state.setInactive}
                    toggleSetInactive={this.toggleSetInactive}
                  />
                </React.Fragment>
              )}
              <ModalEquipment
                handleClose={this.handleModalClose}
                handleOpen={this.handleModalOpen}
                open={this.state.modalOpen}
                locationUUID={
                  this.props.sourceUUID
                    ? this.props.sourceUUID
                    : this.state.data.property_uuid
                }
                setReplacement={this.setReplacement}
                source={this.props.source}
                replacement={this.state.replacement}
                equipmentUUID={this.props.uuid}
              />
              {this.state.editMode &&
                !this.props.newAddition &&
                !this.state.deleteMode &&
                !this.state.removeMode && (
                  <div className="flexButtonContainer" id="assemblyOptions">
                    {(this.props.sourceUUID ||
                      this.state.data.property_uuid) && (
                      <React.Fragment>
                        <div>
                          <button
                            className="medButtonSecondary"
                            onClick={() => this.replaceEquipment()}
                          >
                            Replace Assembly
                          </button>
                        </div>
                        <div>
                          <button
                            className="medButtonSecondary"
                            onClick={() => this.removeThisEquipment()}
                          >
                            Remove from Location
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                    <div>
                      <button
                        className="medButtonSecondary"
                        onClick={() => this.setEquipmentAsInactive()}
                      >
                        {this.state.data.active
                          ? "Set as Inactive"
                          : "Reactivate Assembly"}
                      </button>
                    </div>
                  </div>
                )}
              {this.state.removeMode && this.state.editMode && (
                <div className="removeModeWrapper">
                  <div className="flyout__deleteMode-long">
                    WARNING: The <b className="bold">Installed on Date</b> and
                    any text in the <b className="bold">Assembly Location</b>{" "}
                    field will not be retained once this action is completed. If
                    you want to retain this data please take the following
                    steps:
                    <ol type="1">
                      <li>
                        Copy the <b className="bold">Installed on Date</b> and
                        the text in the{" "}
                        <b className="bold">Assembly Location</b> fields and
                        paste them in the assembly{" "}
                        <b className="bold">Comments</b> field.
                      </li>
                      <li>
                        Proceed with removing the assembly from the location via
                        the <i>“Remove From Location”</i> button.
                      </li>
                      <li>
                        Link the assembly to the appropriate location record
                      </li>
                      <li>
                        Paste the copied assembly location text back into the{" "}
                        <b className="bold">Assembly Location</b> field
                      </li>
                      <li>
                        Paste the copied installed on date in the{" "}
                        <b className="bold">Installed on Date</b> Field
                      </li>
                    </ol>
                  </div>
                  <div
                    className="flyout__deleteMode"
                    style={{ marginTop: "3rem", marginBottom: "1rem" }}
                  >
                    <span>These actions cannot be undone.</span>
                    <button
                      className="longButtonSecondary"
                      onClick={() => this.removeThisEquipment("remove")}
                    >
                      Remove Assembly From Location
                    </button>
                    <button
                      className="exit"
                      onClick={() => this.removeThisEquipment("cancel")}
                    >
                      <CloseIcon fontSize="large" />
                    </button>
                  </div>
                </div>
              )}
              {this.state.editMode &&
                !this.state.deleteMode &&
                !this.props.newAddition &&
                !this.state.removeMode && (
                  <div className="deleteButton__container">
                    <button
                      className="deleteButton"
                      onClick={() => this.deleteThisEquipment()}
                    >
                      <DeleteForeverIcon fontSize="inherit" color="inherit" />
                    </button>
                  </div>
                )}
              {this.state.deleteMode && this.state.editMode && (
                <React.Fragment>
                  <div className="flyout__deleteMode">
                    <span>Warning: These actions cannot be undone.</span>
                    <button
                      className="medButtonSecondary"
                      onClick={() => this.deleteThisEquipment("delete")}
                    >
                      Delete Assembly
                    </button>
                    {(this.props.sourceUUID ||
                      this.state.data.property_uuid) && (
                      <button
                        className="medButtonSecondary"
                        onClick={() => this.deleteThisEquipment("remove")}
                      >
                        Delete Assembly from Location
                      </button>
                    )}
                    <button
                      className="exit"
                      onClick={() => this.deleteThisEquipment("cancel")}
                    >
                      <CloseIcon fontSize="large" />
                    </button>
                  </div>
                </React.Fragment>
              )}
              {!this.props.newAddition && !this.state.editMode && (
                <div className="flexButtonContainer">
                  {this.state.data.needs_review && (
                    <button
                      onClick={() => this.reviewAction("accept")}
                      className="medButtonPrimary"
                    >
                      Accept Assembly
                    </button>
                  )}
                  <button
                    onClick={() => {
                      window.location.replace(
                        "/testReports/newTestReport?assembly=" +
                          this.props.uuid,
                      );
                    }}
                    className="medButtonPrimary"
                  >
                    Add Test Report
                  </button>
                </div>
              )}
            </div>
          );
        }}
      </ModalDuplicateContext.Consumer>
    );
  }
}

export default AssemblyDetails;
