import React from 'react';
import { getBackflowTestReportsReport } from '../../api/complianceAPI';
import Table from '../Table';
import Flyout from '../Flyout';
import { getReportValue } from '../../utils/reporting';
import { getDefaultReportingParams, setReportingParams } from '../../utils/reporting';
import LoaderOverlay from '../LoaderOverlay';

class AssemblyTestHistory extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			reportList: null,
			tableColumns: ['Result', 'Status', 'Reported On'],
			flyoutActive: false,
			complianceReportInfo: null,
			reportingParams: getDefaultReportingParams(),
		};
	}

	async componentDidMount() {
		this.getAssemblyReportList();
	}

	openFlyout = (complianceReportInfo) => {
		if (this.props.flyoutPriority === 'secondary') {
			// we are already in a second flyout and that is our limit, so instead pop them open to a new tab?
			window.open("/testReports/" + getReportValue(this.state.reportList, complianceReportInfo, "Compliance Report UUID"), "_blank");
			return;
		}

		const overlay = document.getElementById('overlaySecondary');

		if (complianceReportInfo.values) {
			complianceReportInfo = {
				compliance_report_uuid: complianceReportInfo.values[0]
			};
		}

		this.setState({ complianceReportInfo, flyoutActive: true }, () => {
			overlay.classList.add('overlaySecondary__visible');
		});
	}

	closeFlyout = () => {
		const flyoutSecondary = document.getElementById('flyoutSecondary');
		const overlay = document.getElementById('overlaySecondary');

		flyoutSecondary.classList.remove('slideToVisible');
		overlay.classList.remove('overlaySecondary__visible');
		setTimeout(() => {
			this.setState(() => ({ flyoutActive: false }));
		}, 200);
	}

	getAssemblyReportList = (reportingParams) => {
		const { uuid } = this.props;

		if (reportingParams === undefined) {
			reportingParams = this.state.reportingParams;
		}
		reportingParams.inputs = { "Equipment UUID": uuid };

		getBackflowTestReportsReport(reportingParams).then(response => {
			if (response.nextPage !== undefined && response.nextPage > reportingParams.page) {
				reportingParams.nextPage = response.nextPage;
			}
			this.setState(() => ({ reportList: response.data, reportingParams }));
		});
	}

	// -----------------------------
	// table / reporting functions
	// -----------------------------
	setOrdering = (column) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { column }, false);
		this.getAssemblyReportList(newReportingParams);
	}

	fetchData = (direction) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { direction }, false);
		if (newReportingParams !== undefined) {
			this.getAssemblyReportList(newReportingParams);
		}
	}
	// END table / reporting functions

	render() {
		const { uuid } = this.props;
		const { reportList, tableColumns, flyoutActive, complianceReportInfo } = this.state;

		return (
			<div className="flyoutContentContainer">
				{reportList !== null ? (
						<React.Fragment>
							<Table
								tableType='Assembly Test Reports'
								tableData={reportList}
								tableSize='small'
								openFlyout={this.openFlyout}
								columnsToShow={tableColumns}
								fetchData={this.fetchData}
								setOrdering={this.setOrdering}
								paginationParams={this.state.reportingParams}
							/>
							<div className="flexButtonContainer">
								<button
									onClick={() => {
										window.location.replace('/testReports/newTestReport?assembly=' + uuid);
									}}
									className="medButtonPrimary"
								>
									Add Test Report
								</button>
							</div>
						</React.Fragment>
					) : <LoaderOverlay />
				}
				<div className="overlaySecondary" id="overlaySecondary" onClick={() => { this.closeFlyout() }}></div>
				{flyoutActive && complianceReportInfo && (
					<React.Fragment>
						<Flyout
							uuid={complianceReportInfo.compliance_report_uuid}
							source='equipment'
							sourceUUID={this.props.uuid}

							flyoutType='testReports'
							flyoutPriority='secondary'
							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							activeTab='testReportDetails'

							refresh={this.getAssemblyReportList}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default AssemblyTestHistory;