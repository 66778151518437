import axios from 'axios';

export const checkDuplicates = (type, value, conditionals) => {
    const url = '/api/v1/duplicates';

    let cond = [];
    if (conditionals && conditionals.length) {
        cond = conditionals;
    }

    return axios.post(url, { 'type': type, 'search': value, 'conditionals': cond })
        .then(response => {
            console.log('Got Duplicate Info', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t get Duplicate Info!', error.response.data);
            throw error;
        });
}