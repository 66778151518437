import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { getFieldOptions } from "../../../utils/forms";
import { getForm, getFormFields } from "../../../api/formsAPI";
import { DeleteForeverRounded } from "@mui/icons-material";
import { Backdrop } from "@mui/material";
import styles from "../../../styles/common/homeForm.module.css";
import Page from "../Page";
import Card from "../Card";
import Form from "../Forms/Form";
import Button from "../Button";
import DuplicatesDialog from "./DuplicatesDialog";
import DeleteDialog from "./DeleteDialog";
import { getURLObjects, URLSectionInfo } from "../../../utils/url";
import Divider from "../Divider";

/**
 * A generic form for creating or editing objects
 *
 * @param {formField[]} [basicFields] - Fields common to all objects of this type (see <Form>)
 * @param {string} detailsFormName - Name of the API form use to get/set details fields
 * @param {formField[]} [srFields] - State report fields
 * @param {(basic:Object,details:Object,sr:Object) => Promise} onSubmit - Called when the user saves the object
 * @param {() => void} onCancel - Called when the user clicks cancel
 * @param {(basic:Object,details:Object,sr:Object) => Promise<{label:string,value:string}[]>} onCheckDuplicates
 *     - Callback function that should return a promise that resolves to an array of duplicates
 * @param {(uuid:string) => Promise} onUseDuplicate - Called if the user chooses to use a duplicate in the list
 * @param {() => void} onDelete - Called when the user clicks the delete button
 * @param {Object} [preloadedBasicState] - If editing, the initial basic field values of the object
 * @param {Object} [preloadedDetailsState] - If editing, the initial details field values of the object
 * @param {Object} [preloadedSrState] - If editing, the initial state report field values of the object
 * @param {string | React.ReactNode} [deleteText] - Text to display in the delete dialog. Can be jsx if breaks are needed.
 */
const HomeForm = ({
    basicFields,
    detailsFormName,
    srFields,
    onSubmit,
    onCancel,
    onCheckDuplicates,
    onUseDuplicate,
    onDelete,
    preloadedBasicState,
    preloadedDetailsState,
    preloadedSrState,
    customButton,
    deleteText,
}) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [detailsFields, setDetailsFields] = useState(null);
    const [basicFieldsState, setBasicFieldsState] = useState(preloadedBasicState || {});
    const [detailsFieldsState, setDetailsFieldsState] = useState(preloadedDetailsState || {});
    const [srFieldsState, setSrFieldsState] = useState(preloadedSrState || {});
    const [basicFieldsErrors, setBasicFieldsErrors] = useState([]);
    const [detailsFieldsErrors, setDetailsFieldsErrors] = useState([]);
    const [srFieldsErrors, setSrFieldsErrors] = useState([]);
    const [otherErrors, setOtherErrors] = useState([]);
    const [duplicates, setDuplicates] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [busy, setBusy] = useState(false);
    const urlObject = getURLObjects().pop();
    const sectionInfo = URLSectionInfo[urlObject.section];

    useEffect(() => {
        if (!detailsFormName || detailsFields) {
            return;
        }

        setLoading(true);

        getForm(detailsFormName)
            .then((data) => (data?.form_uuid ? getFormFields(data.form_uuid) : { form_fields: [] }))
            .then((fields) => {
                setDetailsFields(
                    fields.form_fields.map((f) => ({
                        id: f.form_field_uuid,
                        label: f.label,
                        inputType: f.input_type,
                        dataType: f.data_type,
                        options: getFieldOptions(f.values),
                        required: f.required,
                    }))
                );
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }, []);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .transform((value) => (value === "" ? null : value))
            .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, "Invalid email address")
            .max(254, "Email must be at most 254 characters")
            .nullable(),
    });

    // Break up the specified validation error into inline error messages on the form
    const showValidationErrors = (validationError) => {
        const errorDetails = validationError.response?.data?.Details;

        if (errorDetails?.length) {
            const basicErrors = [];
            const detailsErrors = [];
            const srErrors = [];
            const unknownFieldErrors = [];

            errorDetails.forEach((errorDetail) => {
                // This appears to be a validation error on a specific field, attempt to display the error on that input
                const { field, description } = errorDetail;

                if (field && description) {
                    if (basicFields?.find((f) => f.id === field)) {
                        basicErrors.push({ id: field, error: description });
                        return;
                    }

                    if (detailsFields?.find((f) => f.id === field)) {
                        detailsErrors.push({ id: field, error: description });
                        return;
                    }

                    if (srFields?.find((f) => f.id === field)) {
                        srErrors.push({ id: field, error: description });
                        return;
                    }

                    // This error has a "field" and a "description" but the field was not found on the form
                    unknownFieldErrors.push(`"${field}" field: ${description}`);
                    return;
                }

                if (description) {
                    // This error has a description but no field
                    unknownFieldErrors.push(description);
                    return;
                }

                // This error does not even have a "description," just display the raw data
                unknownFieldErrors.push(JSON.stringify(errorDetail));
            });

            setBasicFieldsErrors(basicErrors);
            setDetailsFieldsErrors(detailsErrors);
            setSrFieldsErrors(srErrors);
            setOtherErrors(unknownFieldErrors);
            return;
        }

        // Unknown error type, show a generic error message
        setOtherErrors([
            validationError.message ||
                validationError.Error ||
                "unknown error (see browser console for more information)",
        ]);

        console.warn("Error while attempting to save:", JSON.stringify(validationError, null, 2));
    };

    // Call the parent component's duplicate check function
    // - if duplicates found, show the "Duplicates Check" dialog
    // - if none found, call the callback, if defined
    const doDuplicatesCheck = async (noDuplicatesCallback) => {
        const duplicatesArray = await onCheckDuplicates?.(basicFieldsState, detailsFieldsState, srFieldsState).catch(
            (error) => console.log("Duplicate check failed", error)
        );

        if (duplicatesArray?.length) {
            // Duplicates found, show the duplicate check dialog
            setDuplicates(duplicatesArray);
        } else if (noDuplicatesCallback) {
            // Call the callback and do not show any dialog
            setDuplicates(null);
            noDuplicatesCallback();
        } else {
            // Show the duplicates dialog with the "No Duplicates Found" message
            setDuplicates([]);
        }
    };

    const doSave = (callback) => {
        setDuplicates(null);
        setBusy(true);

        validationSchema
            .validate({ ...basicFieldsState, ...detailsFieldsState, ...srFieldsState }, { abortEarly: false })
            .then(() => callback(basicFieldsState, detailsFieldsState, srFieldsState))
            .catch((error) => {
                if (error instanceof Yup.ValidationError) {
                    const yupErrors = error.inner.map((err) => ({
                        id: err.path,
                        error: err.message,
                    }));
                    setBasicFieldsErrors(
                        yupErrors.filter((err) => basicFields?.some((field) => field.id === err.id) ?? false)
                    );
                    setDetailsFieldsErrors(
                        yupErrors.filter((err) => detailsFields?.some((field) => field.id === err.id) ?? false)
                    );
                    setSrFieldsErrors(
                        yupErrors.filter((err) => srFields?.some((field) => field.id === err.id) ?? false)
                    );
                } else {
                    showValidationErrors(error);
                }
            })
            .finally(() => {
                setBusy(false);
            });
    };

    const doUseDuplicate = (uuid) => {
        setDuplicates(null);
        setBusy(true);

        onUseDuplicate(uuid)
            .catch(showValidationErrors)
            .finally(() => setBusy(false));
    };

    const generalError =
        basicFieldsErrors?.length || detailsFieldsErrors?.length || srFieldsErrors?.length ? (
            "Form submission failed. Please correct the highlighted fields and try again."
        ) : otherErrors.length ? (
            <div>
                Form submission failed:
                <ul>
                    {otherErrors.map((msg, i) => (
                        <li key={i}>{msg}</li>
                    ))}
                </ul>
            </div>
        ) : null;

    return (
        <Page loading={loading} error={error}>
            <Card title={urlObject.id ? `Edit ${sectionInfo.getObjectName(basicFieldsState)}` : sectionInfo.title}>
                {!!basicFields?.length && (
                    <Form
                        fields={basicFields}
                        formState={basicFieldsState}
                        onFormStateChange={setBasicFieldsState}
                        validationErrors={basicFieldsErrors}
                        onValidationErrorsChange={setBasicFieldsErrors}
                        generalError={{ message: generalError }}
                    />
                )}
                {!!detailsFields?.length && (
                    <>
                        <Divider />
                        <Form
                            fields={detailsFields}
                            formState={detailsFieldsState}
                            onFormStateChange={setDetailsFieldsState}
                            validationErrors={detailsFieldsErrors}
                            onValidationErrorsChange={setDetailsFieldsErrors}
                        />
                    </>
                )}
                {!!srFields?.length && (
                    <>
                        <Divider />
                        <Form
                            fields={srFields}
                            formState={srFieldsState}
                            onFormStateChange={setSrFieldsState}
                            validationErrors={srFieldsErrors}
                            onValidationErrorsChange={setSrFieldsErrors}
                        />
                    </>
                )}
                <Divider />
                <div className={styles.buttonArray}>
                    <Button
                        data-testid="save-button"
                        color="primary"
                        onClick={() => doDuplicatesCheck(() => doSave(onSubmit))}
                    >
                        Save
                    </Button>
                    <Button onClick={onCancel}>Cancel</Button>
                    {onCheckDuplicates && (
                        // This can't be shortened to just onClick={doDuplicatesCheck} because it needs to be called
                        // with no parameters and onClick would pass the event object
                        <Button onClick={() => doDuplicatesCheck()}>Check Duplicates</Button>
                    )}
                    {onDelete && (
                        <Button color="warning" onClick={() => setShowDeleteDialog(true)}>
                            <DeleteForeverRounded /> Delete
                        </Button>
                    )}
                    {customButton && customButton(doSave)}
                </div>
            </Card>
            {onCheckDuplicates && (
                <DuplicatesDialog
                    open={!!duplicates}
                    options={duplicates}
                    onSelect={doUseDuplicate}
                    onSave={() => doSave(onSubmit)}
                    onCancel={() => setDuplicates(null)}
                />
            )}
            {onDelete && (
                <DeleteDialog
                    open={showDeleteDialog}
                    onConfirm={onDelete}
                    onCancel={() => setShowDeleteDialog(false)}
                    text={deleteText}
                />
            )}
            {/* A bug in MUI v4 requires setting zIndex manually */}
            <Backdrop open={busy} style={{ zIndex: 10000 }} />
        </Page>
    );
};

export default HomeForm;
