import React, { useState } from "react";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { scheduleToText } from "../../../../utils/forms";
import styles from "../../../../styles/common/schedulingDialog.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";
import SchedulingDialog from "./SchedulingDialog";

export const ScheduleFieldInput = ({ label, value, error, onClick, type }) => {
    if (type === "permit") {
        return (
            <FormControl error={!!error}>
                <TextField
                    onClick={onClick}
                    slotProps={{
                        input: {
                            readOnly: true,
                        },
                    }}
                    label={value ? label : `${label} - Click to set`}
                    variant="outlined"
                    value={value ? scheduleToText(value) : ""}
                    error={!!error}
                />
                {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
            </FormControl>
        );
    } else {
        if (Object.keys(value).length === 0 || !value) {
            return (
                <span>
                    Not defined.
                    <button onClick={onClick} className={styles.link}>
                        Set Schedule
                    </button>
                </span>
            );
        } else {
            return (
                <span>
                    {scheduleToText(value)}
                    <button onClick={onClick} className={styles.link}>
                        Edit Schedule
                    </button>
                </span>
            );
        }
    }
};

const ScheduleField = ({ label, value, setValue, error }) => {
    const [showDialog, setShowDialog] = useState(false);

    return (
        <>
            <ScheduleFieldInput label={label} value={value} onClick={() => setShowDialog(true)} />
            <SchedulingDialog
                title={label}
                open={showDialog}
                onClose={() => setShowDialog(false)}
                value={value}
                setValue={setValue}
            />
        </>
    );
};

export default ScheduleField;
