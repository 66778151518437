import { useEffect, useState } from "react";
import { getGeneralControls } from "../../../api/permitsAPI";

/**
 * Custom hook for fetching general controls list
 * Returns array of { label, value } objects, error object, and loading state
 *
 * @returns {{generalControls:{label:string, value:string}[], error:*, loading:boolean}}
 */
const useGeneralControls = () => {
    const [error, setError] = useState(null);
    const [generalControls, setGeneralControls] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getGeneralControls()
            .then((data) => {
                setGeneralControls(
                    data.general_controls.map((control) => ({
                        label: control.name,
                        value: control.general_control_uuid,
                    }))
                );
            })
            .catch(setError)
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return { generalControls, error, loading };
};

export default useGeneralControls;
