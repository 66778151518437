import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { cloneDeep } from "lodash";
import dashboardReducer from "./reducers/dashboardReducer";
import swiftComplyReducer from "./reducers/swiftComplyReducer";
import propertiesReducer from "./reducers/propertiesReducer";
import duplicatesReducer from "./reducers/duplicatesReducer";
import sidebarReducer from "./reducers/sidebarReducer";

const initializeStore = (preloadedState) => {
    const rootReducer = combineReducers({
        dashboard: dashboardReducer,
        swiftComply: swiftComplyReducer,
        properties: propertiesReducer,
        duplicates: duplicatesReducer,
        sidebar: sidebarReducer,
    });

    const middleware = [thunk];
    let enhancer;

    if (process.env.NODE_ENV === "development") {
        enhancer = composeWithDevTools(applyMiddleware(...middleware));
    } else {
        enhancer = applyMiddleware(...middleware);
    }

    // The original Backflow creates a global variable called SwiftComply, which contains user settings, etc
    // We need to access that object, but do not want to overwrite the original, so clone it
    return createStore(rootReducer, { swiftComply: cloneDeep(SwiftComply) }, enhancer);
};

export default initializeStore;
