import React from "react";
import { getURLModule, getURLBaseSection } from "../../../utils/url";
import Home from "../../Common/Home/Home";
import BasicReportTable from "../../Common/ReportTable/BasicReportTable";

const GeneralControlsHome = () => {
    const { slug } = getURLModule();
    const baseSection = getURLBaseSection().section;

    return (
        <Home cardTitle="General Controls" addNewForm="addGeneralControl">
            <BasicReportTable
                reportUUID="171fc90e-e457-4383-9341-636f533c0df9"
                rowLinks={(row) => `/${slug}/${baseSection}/gc/${row["General Control UUID"]}`}
            />
        </Home>
    );
};

export default GeneralControlsHome;
