import React, { useState, useEffect } from 'react';
import Flyout from '../Flyout';
import { getReportSync } from '../../api/reportingAPI';
import Table from '../Table';
import LoaderOverlay from '../LoaderOverlay';
import { NavLink } from 'react-router-dom';

const CommunicationsTab = (props) => {
    const [tableData, setTableData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [flyoutActive, setFlyoutActive] = useState(false);
    const [flyoutUUID, setFlyoutUUID] = useState('');

    useEffect(() => {
        if (props.flyoutType == "locations") {
            getData("c57c22af-6a1d-443d-a933-39a8de938a10", { "Property UUID": props.uuid });
        } else if (props.flyoutType == "contacts") {
            getData("0e48f7d7-f5dc-4bc6-b4cd-c08d10c60500", { "Contact UUID": props.uuid });
        } else if (props.flyoutType == "assemblies") {
            getData("cd65f843-54c7-49b6-9b52-44dbaf1eba45", { "Equipment UUID": props.uuid });
        }

    }, []);

    const getData = async (reportUUID, inputData) => {
        let reportResponse = await getReportSync(reportUUID, { "inputs": inputData, count: 200 });
        setTableData(reportResponse.data);
        setDataLoaded(true);
    };

    const openFlyout = (commsInfo) => {
        window.open(`/communications/logs/${commsInfo.values[2]}`, '_blank');
    };

    const closeFlyout = () => {
        setTimeout(() => {
            setFlyoutUUID('');
            setFlyoutActive(false);
        }, 200);
    };

    return (
        <div className="flyoutContentContainer">
            {dataLoaded ? (
                    <Table
                        tableData={tableData}
                        tableType='Communications'
                        tableSize='small'
                        openFlyout={openFlyout}
                        pageSize={200}
                    />
                ) : <LoaderOverlay />
            }
            {props.flyoutType !== 'contacts' &&
            <div className="flexButtonContainer">
                <NavLink to={{ 
                    pathname: '/communications/logs', 
                    state: {
                        originData: props.assemblyInfo ? props.assemblyInfo : props.locationInfo }}}>
                    <button className="medButtonPrimary" data-testid="redirect_to_adHoc_comm">Add Comm</button>
                </NavLink>
            </div>
            }
            {flyoutActive &&
                <Flyout
                    uuid={flyoutUUID}
                    flyoutType='commLogs'
                    flyoutPriority="secondary"
                    activeTab="commLogDetails"
                    closeFlyout={closeFlyout}
                />
            }
        </div>
    );
};

export default CommunicationsTab;