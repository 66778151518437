import React, { useState, useEffect } from 'react';
import settings from '../../../images/settingsBlue.svg';
import orgIconLarge from '../../../images/settingsBlue.svg';
import { getOrganizationSystemSettings, getOrganization, updateOrganization } from '../../api/organizationAPI';
import OrgInfo from './OrgInfo';
import BillingSync from './BillingSync';
import OrgSettingsAndRules from './OrgSettingsAndRules';
import { getAPITokens, deleteAPIToken } from '../../api/tokens';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Flyout from '../Flyout';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

const initialFlyoutDetails = {
	flyoutActive: false,
	flyoutType: null,
	flyoutUUID: null,
	flyoutTab: null,
	flyoutNewAddition: false
}

const initialSectionPositions = {
	apiTokensSection: { expand: false, visible: false },
	organizationInfoSection: { expand: false, visible: true },
	billingSyncSection: { expand: false, visible: false },
	settingsSection: { expand: false, visible: true }
}

export default function OrgDashboard() {

	const [apiTokens, setApiTokens] = useState([]);
	const [apiTokenSecret, setApiTokenSecret] = useState("");
	const [flyoutDetails, setFlyoutDetails] = useState(initialFlyoutDetails);
	const { flyoutActive, flyoutType, flyoutUUID, flyoutTab, flyoutNewAddition } = flyoutDetails;
	const [deleteTokenPrompt, setDeleteTokenPrompt] = useState(false);
	const [selectedToken, setSelectedToken] = useState(null);
	const [sectionPositions, setSectionPositions] = useState(initialSectionPositions);
	const { apiTokensSection, organizationInfoSection, billingSyncSection, settingsSection } = sectionPositions;
	const [billingSyncStatus, setBillingSyncStatus] = useState(null);
	const [orgData, setOrgData] = useState({});
	const [apiErrors, setApiErrors] = useState({});
	const [hasUpdatePermission, setHasUpdatePermission] = useState(false)

	useEffect(() => {
		getOrgData();
		getAPITokens().then((tokens) => {
			setApiTokens(tokens)
		})
		getOrganizationSystemSettings().then((orgSettings) => {
			setSectionPositions({
				apiTokensSection: {
					expand: false,
					visible: orgSettings.enable_apitoken_ui ? true : false
				},
				organizationInfoSection: {
					expand: !orgSettings.enable_apitoken_ui && !orgSettings.enable_billingsync ? true : false,
					visible: true
				},
				billingSyncSection: {
					expand: false,
					visible: orgSettings.enable_billingsync ? true : false
				},
				settingsSection: {
					expand: false,
					visible: true
				}
			})
		})
	}, [])

	useEffect(() => {
		if (billingSyncStatus === 'waiting for review') {
			setSectionPositions({ ...sectionPositions, billingSyncSection: { ...sectionPositions.billingSyncSection, expand: true } });
		}
	}, [billingSyncStatus])

	const addAPIToken = () => {
		const overlay = document.getElementById('overlay');
		setFlyoutDetails({
			flyoutActive: true,
			flyoutType: "apiToken",
			flyoutTab: "apiTokenDetails",
			flyoutUUID: "",
			flyoutNewAddition: true
		})
		overlay.classList.add('overlay__visible');
	}

	const editAPIToken = (tokenUUID) => {
		const overlay = document.getElementById('overlay');
		setFlyoutDetails({
			flyoutActive: true,
			flyoutType: "apiToken",
			flyoutTab: "apiTokenDetails",
			flyoutUUID: tokenUUID,
			flyoutNewAddition: false
		});
		overlay.classList.add('overlay__visible');
	}

	const removeAPIToken = (tokenUUID) => {
		deleteAPIToken(tokenUUID).then(() => {
			let tokens = [...apiTokens];
			for (let i = 0; i < tokens.length; i++) {
				if (tokens[i].api_token_uuid == tokenUUID) {
					tokens.splice(i, 1);
					break;
				}
			}
			setApiTokens(tokens);
			setDeleteTokenPrompt(false);
			setSelectedToken(null);
		})
	}

	const closeFlyout = () => {
		const flyout = document.getElementById('flyout');
		const overlay = document.getElementById('overlay');

		flyout.classList.remove('slideToVisible');
		overlay.classList.remove('overlay__visible');

		setTimeout(() => {
			setFlyoutDetails({
				flyoutActive: false,
				flyoutType: null,
				flyoutTab: null,
				flyoutUUID: null
			});
		}, 200);
	}

	const refreshTokens = () => {
		getAPITokens().then((tokens) => {
			setApiTokens(tokens)
		});
	}

	const promptOptions = (type, token) => {
		if (type === 'delete') {
			setDeleteTokenPrompt(true);
			setSelectedToken(token);
		} else {
			setDeleteTokenPrompt(false);
		}
	}

	const toggleSections = (type) => {
		setSectionPositions({ ...sectionPositions, [type]: { ...sectionPositions[type], expand: !sectionPositions[type].expand } })
	}

	const getBillingSyncStatus = (status) => {
		setBillingSyncStatus(status);
	}

	const updateOrgData = (payload) => {
		try {
			updateOrganization(orgData.organization_uuid, payload);
		} catch (error) {
			if (error instanceof APIUserError) {
				setApiErrors(error.apiFieldErrors);
			}
		}
	}

	const getOrgData = async () => {
		try {
			const data = await getOrganization();
			setOrgData(data.organization);
		} catch (error) {
			if (error instanceof APIUserError) {
				setApiErrors(error.apiFieldErrors);
			}
		}
	};

	return (
		<div className="componentContainer" id="organization">
			<Dialog
				open={deleteTokenPrompt ? true : false}
				aria-labelledby="form-dialog-title"
			>
				{selectedToken &&
					<React.Fragment>
						<DialogTitle id="form-dialog-title">{`Are you sure you want to delete token "${selectedToken.name}"? These actions cannot be undone.`} </DialogTitle>
						<DialogActions>
							<button className="medButtonPrimary" onClick={() => removeAPIToken(selectedToken.api_token_uuid)}>Delete</button>
							<button className="medButtonSecondary" onClick={() => promptOptions()}>Cancel</button>
						</DialogActions>
					</React.Fragment>
				}
			</Dialog>
			<div className="componentHeader">
				<div className="flexAlignCenter">
					<img src={settings} alt='' />
					<h3>Settings</h3>
				</div>
			</div>
			<div className="accordian">
				{billingSyncSection.visible && (
					<div className="accordian__element billingSync" id="billingSync">
						<div className="accordian__element-header" onClick={() => toggleSections('billingSyncSection')}>
							<span>
								{billingSyncSection.expand ? <RemoveRoundedIcon fontSize='inherit' color='inherit' /> : <AddRoundedIcon fontSize='inherit' color='inherit' />}
							</span>
							<span>Billing Sync</span>
						</div>
						<div className="accordian__element-content" style={billingSyncSection.expand ? { display: 'block' } : { display: 'none' }}>
							<BillingSync
								sendStatus={getBillingSyncStatus}
							/>
						</div>
					</div>
				)}
				{apiTokensSection.visible && (
					<div className="accordian__element apiTokens" id="tokens">
						<div className="accordian__element-header" onClick={() => toggleSections('apiTokensSection')}>
							<span>
								{apiTokensSection.expand ? <RemoveRoundedIcon fontSize='inherit' color='inherit' /> : <AddRoundedIcon fontSize='inherit' color='inherit' />}
							</span>
							<span>API Tokens</span>
						</div>
						<div className="accordian__element-content" style={apiTokensSection.expand ? { display: 'block' } : { display: 'none' }}>
							<div className="addToken__container">
								<button type="button" className="medButtonPrimary" onClick={() => addAPIToken()}>Add API Token</button>
							</div>
							{Object.keys(apiTokens.filter((t) => { return (t.active) ? true : false; })).length <= 0 ?
								<span className="flexJustifyCenter">There are currently no API tokens.</span>
								:
								<table>
									<thead>
										<tr>
											<th>Name</th>
											<th style={{ width: "45rem" }}>Token ID</th>
											<th style={{ width: "11rem" }}>Actions</th>
										</tr>
									</thead>
									<tbody>
										{
											apiTokens.filter((t) => { return (t.active) ? true : false; }).map((token, i) => {
												return <tr key={i}>
													<td>
														{token.name}
													</td>
													<td>
														{token.api_token_uuid}
													</td>
													<td>
														<button onClick={() => { editAPIToken(token.api_token_uuid) }}><EditIcon fontSize="large" style={{ color: '#506788' }} /></button>
														<button onClick={() => { promptOptions('delete', token) }}><DeleteIcon fontSize="large" style={{ color: '#FF005C' }} /></button>
													</td>
												</tr>
											})
										}
									</tbody>
								</table>
							}
						</div>
					</div>
				)}
				{settingsSection.visible && (
					<div className="accordian__element settings" id="settings">
						<div className="accordian__element-header" onClick={() => toggleSections('settingsSection')}>
							<span>
								{settingsSection.expand ? <RemoveRoundedIcon fontSize='inherit' color='inherit' /> : <AddRoundedIcon fontSize='inherit' color='inherit' />}
							</span>
							<span>Compliance Reports Settings and Rules</span>
						</div>
						<div className="accordian__element-content" style={settingsSection.expand ? { display: 'block' } : { display: 'none' }}>
							<OrgSettingsAndRules />
						</div>
					</div>
				)}
				<div className="accordian__element organizationInfo" id="organizationInfo">
					<div className="accordian__element-header" onClick={() => toggleSections('organizationInfoSection')}>
						<span>
							{organizationInfoSection.expand ? <RemoveRoundedIcon fontSize='inherit' color='inherit' /> : <AddRoundedIcon fontSize='inherit' color='inherit' />}
						</span>
						<span>Organization Info</span>
					</div>
					<div className="accordian__element-content" style={organizationInfoSection.expand ? { display: 'block' } : { display: 'none' }}>
						<OrgInfo
							data={orgData}
							update={updateOrgData}
							hasUpdatePermission={hasUpdatePermission}
						/>
					</div>

				</div>
			</div>
			<img className="dashboardIconLarge dashboardIconLarge__adjusted" src={orgIconLarge} alt=''></img>
			<div className="overlay" id="overlay" onClick={() => { closeFlyout() }} />
			{flyoutActive && (
				<div id="APITokenFlyout">
					<Flyout
						uuid={flyoutUUID}
						flyoutType={flyoutType}
						activeTab={flyoutTab}
						newAddition={flyoutNewAddition}
						removeAPIToken={removeAPIToken}
						flyoutPriority="primary"
						closeFlyout={closeFlyout}
						refresh={refreshTokens}
					/>
				</div>
			)}
		</div>
	)
}
