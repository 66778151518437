/* eslint-disable react/prop-types */
import React from 'react';
import formBuilder from '../../../images/formBuilderBlue.svg';
import orgIconLarge from '../../../images/orgIconLarge.svg';

class formBuilderDashboard extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="componentContainer">
				<div className="componentHeader">
					<img src={formBuilder} alt=''/>
					<h3>Form Builder</h3>
				</div>
				<img className="orgIconLarge" src={orgIconLarge} alt=''></img>
			</div>
		);
	}
}

export default formBuilderDashboard;