import React from "react";
import { getPathname } from "../../../utils/url";
import Home from "../../Common/Home/Home";
import AdvancedReportTable from "../../Common/ReportTable/AdvancedReportTable";

const IndustrialUsersHome = () => {
    const pathname = getPathname();

    return (
        <Home addNewForm="addIU">
            <AdvancedReportTable
                reportUUID="2b408dd5-1df9-48be-8f28-b1e9cf8ea985"
                defaultColumns={["Name", "Address", "City", "State / Prov", "Postal Code", "Phone", "Discharge POTW"]}
                rowLinks={(row) => `${pathname}/${row["Property UUID"]}`}
            />
        </Home>
    );
};

export default IndustrialUsersHome;
