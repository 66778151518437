import React, { useEffect, useState, useMemo } from "react";
import { withRouter, useLocation, useHistory } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import {
    createSampleReportConfig,
    updateSampleReportConfig,
} from "../../../api/analytesAPI";
import SRCMultiStepForm from "./SRCMultiStepForm";
import { addSampleReportConfigSteps } from "./AddSampleReportConfigSteps";

const AddSampleReportConfig = () => {
    const location = useLocation();
    const history = useHistory();
    const { id: outfallId, path: outfallPath } = getURLParentObject("o") || {};
    const [validationErrors, setValidationErrors] = useState([]);

    const [isEditMode, setIsEditMode] = useState(false);

    // Initialize states
    const [currentStep, setCurrentStep] = useState(0);
    const [SRCData, setSRCData] = useState(() => {
        const existingSRC = sessionStorage.getItem("existingSRC");
        return existingSRC ? JSON.parse(existingSRC) : {};
    });

    // Update URL parameters handling
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const stepFromURL = parseInt(queryParams.get("step"), 10);
        const srcId = queryParams.get("sample_report_config_id");
        const editMode = queryParams.get("isEditMode") === "true";

        setIsEditMode(editMode);

        // Load existing SRC data if needed
        if (srcId && !SRCData.sample_report_config_id) {
            const existingSRC = sessionStorage.getItem("existingSRC");
            if (existingSRC) {
                setSRCData(JSON.parse(existingSRC));
            }
        }

        // Set step from URL
        if (
            !isNaN(stepFromURL) &&
            stepFromURL >= 0 &&
            stepFromURL < addSampleReportConfigSteps.length
        ) {
            setCurrentStep(stepFromURL);
        }
    }, []);

    // Update URL sync effect
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let needsUpdate = false;

        // Update step in URL if different
        const urlStep = queryParams.get("step");
        if (urlStep !== String(currentStep)) {
            queryParams.set("step", currentStep);
            needsUpdate = true;
        }

        // Update sample_report_config_id in URL if different
        const urlSrcId = queryParams.get("sample_report_config_id");
        if (
            SRCData.sample_report_config_id &&
            urlSrcId !== String(SRCData.sample_report_config_id)
        ) {
            queryParams.set(
                "sample_report_config_id",
                SRCData.sample_report_config_id
            );
            needsUpdate = true;
        }

        // Ensure isEditMode is in URL
        const urlEditMode = queryParams.get("isEditMode");
        if (urlEditMode !== String(isEditMode)) {
            queryParams.set("isEditMode", isEditMode);
            needsUpdate = true;
        }

        // Update URL if needed
        if (needsUpdate) {
            history.replace({ search: queryParams.toString() });
        }
    }, [
        currentStep,
        SRCData.sample_report_config_id,
        isEditMode,
        history,
        location.search,
    ]);

    // minimal fix for removing aria-hidden from #root if present
    useEffect(() => {
        const root = document.getElementById("root");
        if (root && root.hasAttribute("aria-hidden")) {
            root.removeAttribute("aria-hidden");
        }
    }, []);

    const onSubmit = async (basic, nextStep) => {
        // Check if we have an existing config in session storage
        const existingSRC = sessionStorage.getItem("existingSRC");
        const parsedExistingSRC = existingSRC ? JSON.parse(existingSRC) : null;
        const isEditMode = location.search.includes("isEditMode=true");

        try {
            let response;

            // Case 1: Edit mode - always update
            // Case 2: Not edit mode but we have an existing SRC ID - update
            // Case 3: First time creation - create new
            if (
                (isEditMode || parsedExistingSRC?.sample_report_config_id) &&
                parsedExistingSRC?.sample_report_config_id
            ) {
                // Update existing config
                response = await updateSampleReportConfig(
                    parsedExistingSRC.sample_report_config_id,
                    {
                        ...basic,
                        reference_point_uuid: outfallId,
                    }
                );
                console.log(
                    "Updating existing config:",
                    parsedExistingSRC.sample_report_config_id
                );
                setValidationErrors([]);
            } else {
                // Otherwise, create a new config
                response = await createSampleReportConfig({
                    ...basic,
                    reference_point_uuid: outfallId,
                });
            }

            // Store result
            sessionStorage.setItem("existingSRC", JSON.stringify(response));

            if (nextStep) {
                setSRCData(response);
                setCurrentStep(1);
            } else {
                // Clear session storage before navigating away
                sessionStorage.removeItem("existingSRC");
                sessionStorage.removeItem("srcFormState");
                sessionStorage.removeItem("srcSamplingConfigs");
                sessionStorage.removeItem("srcCompleteState");

                // Navigate to the view page
                history.push(
                    `${outfallPath}/src/${response.sample_report_config_id}`
                );
            }
        } catch (error) {
            console.error("Error submitting sample report config:", error);
            setValidationErrors(
                error.response?.data?.Details?.map((detail) => ({
                    id: detail.field,
                    error: detail.description,
                })) || []
            );
        }
    };

    const getSteps = () => {
        const steps = addSampleReportConfigSteps.map((step) => ({ ...step }));
        return steps;
    };

    const onStepChange = (step) => {
        console.log("step change", step);
    };

    return (
        <SRCMultiStepForm
            steps={getSteps()}
            onCancel={() => history.goBack()}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            onStepChange={onStepChange}
            onSubmit={onSubmit}
            SRCData={SRCData}
            validationErrors={validationErrors}
            onValidationError={setValidationErrors}
        />
    );
};

export default withRouter(AddSampleReportConfig);
