import React from "react";

const AssembliesIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10.549V14.7677C0 15.059 0.236069 15.295 0.527344 15.295H3.9743V10.0216H0.527344C0.236069 10.0216 0 10.2577 0 10.549Z"/>
        <path d="M11.11 9.49933V8.58018C11.11 8.55217 11.1071 8.52498 11.103 8.4982H6.89821C6.89395 8.52498 6.8912 8.55231 6.8912 8.58018V9.50002L6.12889 10.0216H5.0293V15.295H6.20099C6.42058 15.295 6.60103 15.3307 6.80428 15.4146C6.93515 15.4686 7.07907 15.5286 7.22697 15.5904C7.69582 15.786 8.18059 15.9884 8.46555 16.0882C8.64311 16.1505 8.82178 16.1815 9.00058 16.1815C9.17924 16.1815 9.35791 16.1505 9.53534 16.0882C9.89967 15.9605 10.6649 15.6478 11.2092 15.4189C11.4156 15.3321 11.5991 15.295 11.8228 15.295H12.9746V10.0216H11.8723L11.11 9.49933Z"/>
        <path d="M17.4731 10.0216H14.0293V15.295H17.4731C17.7644 15.295 18.0004 15.059 18.0004 14.7677V10.549C18.0004 10.2577 17.7644 10.0216 17.4731 10.0216Z"/>
        <path d="M12.8672 1.82812H5.13281C4.55026 1.82812 4.07812 2.3004 4.07812 2.88281C4.07812 3.46523 4.55026 3.9375 5.13281 3.9375H12.8672C13.4497 3.9375 13.9219 3.46536 13.9219 2.88281C13.9219 2.3004 13.4497 1.82812 12.8672 1.82812Z"/>
        <path d="M7.94531 4.99219H10.0547V7.44351H7.94531V4.99219Z"/>
    </svg>
);

export default AssembliesIcon;
