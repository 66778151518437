import React, { useState, useEffect } from 'react';
import { getCommDownloadRequests } from '../../api/communicationsAPI';
import BasicTable from '../BasicTable';
import Flyout from '../Flyout';

const CommDownloadRequests = (props) => {
    const [requestList, setRequestList] = useState([]);
    const [flyoutActive, setFlyoutActive] = useState(false);
    const [commDlReqInfo, setCommDlReqInfo] = useState(null);

    useEffect(() => {
        getDownloadRequests();
        // refresh our table every 30 seconds
        const interval = setInterval(() => { getDownloadRequests(); }, 30000)
        // I guess this return gets called every time the component gets torn down?
        return () => clearInterval(interval);
    }, [props.refreshRandomNum]);

    const getDownloadRequests = () => {
        getCommDownloadRequests().then((data) => {
            let list = [];
            data.requests.forEach((req) => {
                list.push({
                    "communication_download_request_uuid": req.communication_download_request_uuid,
                    "description": req.description,
                    "status": req.status,
                    "range": req.start + " - " + req.end,
                    "requested_on": req.requested_on,
                    "requested_by": req.requested_by.firstname + " " + req.requested_by.lastname,
                    "size": req.size
                });
            });
            setRequestList(list);
        });
    }

    return (
        <div className="mainTableContainer">
            {requestList.length > 0 && (
                <BasicTable
                    tableData={requestList}
                    tableType="Download Requests"
                    dataSource="list"
                    columns={requestTableColumns}
                    openFlyout={(row) => {
                        setCommDlReqInfo(row);
                        setFlyoutActive(true);
                    }}
                    highlight={true}
                />
            )}
            {flyoutActive && (
                <React.Fragment>
                    <Flyout
                        uuid={commDlReqInfo.communication_download_request_uuid}
                        data={commDlReqInfo}
                        newAddition={false}
                        flyoutType="commDownloadRequest"
                        flyoutPriority="primary"
                        activeTab="commDownloadRequestDetails"
                        closeFlyout={() => { setFlyoutActive(false); }}
                        openFlyout={() => { }}
                        refresh={getDownloadRequests}
                    />
                </React.Fragment>
            )}
        </div>
    );
};

export default CommDownloadRequests;

const requestTableColumns = [
    {
        dataName: "communication_download_request_uuid",
        displayName: "communication_download_request_uuid",
        display: false
    },
    {
        dataName: "description",
        displayName: "Description",
        display: true
    },
    {
        dataName: "status",
        displayName: "Status",
        display: true
    },
    {
        dataName: "range",
        displayName: "Range",
        display: true
    },
    {
        dataName: "requested_on",
        displayName: "Requested On",
        display: true
    },
    {
        dataName: "requested_by",
        displayName: "Requested By",
        display: true
    },
    {
        dataName: "size",
        displayName: "size",
        display: false
    }
];