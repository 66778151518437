import React from 'react';
import {
    getBackflowStateReportLayout,
    getBackflowStateReportPreLayout,
    getBackflowStateReportAdditionalLayout
} from './Backflow/Layout';

const StateReportTables = ({ orgState, year, data }) => {
    let tables = getBackflowStateReportPreLayout(orgState.abbrev, year, data);
    const layout = getBackflowStateReportLayout(orgState.abbrev, year);

    // mapping the template, which will create a new table for every object within the tables array
    layout.map(table => {
        tables.push(
            <React.Fragment key={table.key}>
                {/* Choose to show table titles here if it makes things easier to read. Defined in the layout file. */}
                {table.tableTitle &&
                    <span>{table.tableTitle}</span>
                }
                <table>
                    <thead>
                        <tr className="headerRow">
                            {/* This blank header tag is for the top left cell of the table */}
                            <th>{''}</th>
                            {/* mapping the columns within the table in order and showing the full label */}
                            {table.columns.map(column => {
                                return (
                                    <th key={table.key + '`' + column.key}>{column.label}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {/* mapping the rows per table in order and showing full label */}
                        {table.rows.map(row => {
                            {/* some tables are hard to read without their respective table headers, so those go here */ }
                            if (row.key.includes('rowTitle')) {
                                return (
                                    <tr key={row.key}>
                                        <th colSpan={table.columns.length + 1}>{row.label}</th>
                                    </tr>
                                )
                            } else {
                                return (
                                    <tr key={table.key + '`' + row.key}>
                                        <td className="rowLabel">{row.label}</td>
                                        {table.columns.map(column => {
                                            let dataKey = column.key === '' ? table.key + '`' + row.key : table.key + '`' + row.key + '`' + column.key;
                                            return <td key={dataKey} id={dataKey}>{data.results[dataKey] ? data.results[dataKey] : 0}</td>
                                        })}
                                    </tr>
                                )
                            }
                        })}
                    </tbody>
                </table>
            </React.Fragment>
        )
    });

    tables = tables.concat(getBackflowStateReportAdditionalLayout(orgState.abbrev, year, data));
    return tables;
}


export default StateReportTables;
