/*
* Note about this data structure: unlike other "basic fields" objects, this one is not used in creating a new
*   object (i.e. "New Permit" button).  Permits are created through a multi-step form (see AddPermit.js).  These
*   fields are used only to view an existing permit object.
*/

/** @type {formField[]} */
export const BasicPermitFields = [
    { id: "name", label: "Permit Name" },
    { id: "permit_number", label: "Permit Number" },
    { id: "status", label: "Status" },
    { id: "effective_date", inputType: "date", label: "Effective Date" },
    { id: "expiration_date", inputType: "date", label: "Expiration Date" },
    { id: "signed_on", inputType: "date", label: "Signature Date" },
];
