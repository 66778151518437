import React, { useState, useEffect } from "react";
import Page from "../Page";
import Card from "../Card";
import Form from "./Form";
import Button from "../Button";
import ProgressBar from "./ProgressBar";
import styles from "../../../styles/common/multiStepForm.module.css";
import MultiStepFormReportTable from "../ReportTable/MultistepFormReportTable";
import Snackbar from "../Snackbar";
import LoadingSpinner from "../../LoadingSpinner";

/**
 * Renders a multi-step form with progress bar and navigation buttons
 *
 * @param {string} title - The title of the form
 * @param {Array} steps - An array of step objects containing form fields
 * @param {Object} [initialState] - The initial state of the form
 * @param {Function} onSubmit - Callback function to handle form submission
 * @param {Function} onCancel - Callback function to handle form cancellation
 * @param {Function} onStepChange - Callback function to handle step changes
 * @param {Function} onFormStateChange - Callback function to update the form state in the parent component
 * @param {Object} tableSelections - Object containing table selection state
 * @param {Function} setTableSelections - Function to update table selection state
 * @param {Object} columnConfig - Object containing column configuration for table rendering
 * @param {boolean} showSamplingConfigDialog - Flag to control the visibility of the sampling config dialog
 * @param {Function} setShowSamplingConfigDialog - Function to update the visibility of the sampling config dialog
 * @param {string} pdfData - Base64-encoded PDF data to be displayed in the preview step
 * @param {Function} onValidationError - Callback function to handle validation errors
 * @param {Array} validationErrors - Array of validation errors
 * @returns {JSX.Element} The rendered component
 */
const MultiStepForm = ({
    title,
    steps,
    initialState,
    onSubmit,
    onCancel,
    onStepChange,
    // onFormStateChange,
    onLegacyFormStateChange,
    tableSelections,
    setTableSelections,
    columnConfig,
    onOpenSamplingConfigDialog,
    pdfData,
    onValidationError,
    validationErrors,
    templateDataLoading,
    templateData,
    currentStep,
    setCurrentStep,
    onNextStep,
    fetchAndSetReportData,
    renderExtraButtons,
    deleteButtonStep,
    onDelete,
    showDelete,
    loading,
    onTemplateDataChange,
    recurrenceData,
    onRecurrenceDataChange,
    generalError,
    setgeneralError,
    onTableSelectionsChange,
}) => {
    const [formState, setFormState] = useState({});

    useEffect(() => {
        setFormState(initialState || {});
    }, [initialState]);

    const handleFormStateChange = (updater) => {
        setFormState((prevState) => {
            const newState = typeof updater === "function" ? updater(prevState) : { ...prevState, ...updater };
            if (onLegacyFormStateChange) {
                onLegacyFormStateChange(newState);
            }
            return newState;
        });
    };

    const handleNext = () => {
        const currentStepData = steps[currentStep];
        let fieldsToValidate = [];

        if (currentStepData.fields && currentStepData.fields.length > 0) {
            fieldsToValidate = currentStepData.fields;
        } else if (currentStepData.orderedContent && currentStepData.orderedContent.length > 0) {
            fieldsToValidate = currentStepData.orderedContent
                .filter((item) => item.type === "field" && item.content)
                .map((item) => item.content)
                .filter((field) => {
                    return !field.id.includes("SystemFields") && !field.disabled;
                });
        }

        if (fieldsToValidate.length > 0) {
            const validationErrors = fieldsToValidate.reduce((errors, field) => {
                const fieldValue = formState[field.id] || formState[field.template_string] || field.value;

                if (field.required && (!fieldValue || (typeof fieldValue === "string" && fieldValue.trim() === ""))) {
                    errors.push({
                        id: field.id,
                        error: `${field.label || "This field"} is required`,
                    });
                }
                return errors;
            }, []);

            if (validationErrors.length > 0) {
                onValidationError(validationErrors);
                return;
            }
        }

        // Clear the validation errors when moving to the next step
        onValidationError([]);

        if (currentStep === 0) {
            onNextStep(formState);
        } else if (currentStep < steps.length - 1) {
            setCurrentStep((prevStep) => prevStep + 1);
            onStepChange(currentStep + 1);
        } else if (currentStep === steps.length - 1) {
            onSubmit(formState);
        }
    };

    const handlePrev = () => {
        if (currentStep > 0) {
            // Clear the validation errors for the current step
            onValidationError([]);

            setCurrentStep((prevStep) => prevStep - 1);
            onStepChange(currentStep - 1, true);
        }
    };

    const renderStepContent = () => {
        const { orderedContent, customStep } = steps[currentStep];

        if (customStep) {
            return customStep.render({
                formState,
                pdfData,
                tableSelections,
                templateData,
                recurrenceData,
                onRecurrenceDataChange,
                onTemplateDataChange,
                onOpenSamplingConfigDialog,
            });
        }

        if (currentStep === deleteButtonStep && templateDataLoading) {
            return <LoadingSpinner />;
        }

        // Handle regular fields for steps without orderedContent (like step 1 and sign document)
        if (!orderedContent && steps[currentStep].fields) {
            return (
                <Form
                    fields={steps[currentStep].fields}
                    formState={formState}
                    onFormStateChange={(updater) => handleFormStateChange(updater)}
                    onLegacyFormStateChange={onLegacyFormStateChange}
                    validationErrors={validationErrors}
                />
            );
        }

        // Handle orderedContent for step 2
        return (
            <>
                {orderedContent?.map((item, index) => {
                    if (item.type === "field") {
                        return (
                            <Form
                                key={`field-${index}`}
                                fields={[item.content]}
                                formState={formState}
                                onFormStateChange={(updater) => handleFormStateChange(updater)}
                                onLegacyFormStateChange={onLegacyFormStateChange}
                                validationErrors={validationErrors}
                            />
                        );
                    } else if (item.type === "table") {
                        const reportObject = templateData?.reports?.find(
                            (report) =>
                                report.report_uuid === item.content.reportUUID && report.key === item.content.key
                        );

                        return reportObject ? (
                            <MultiStepFormReportTable
                                key={`table-${index}`}
                                reportUUID={item.content.reportUUID}
                                visibleColumns={item.content.visibleColumns}
                                formState={formState}
                                tableKey={item.content.key}
                                onFormStateChange={handleFormStateChange}
                                tableSelections={tableSelections}
                                setTableSelections={setTableSelections}
                                columnConfig={columnConfig}
                                onOpenSamplingConfigDialog={onOpenSamplingConfigDialog}
                                templateData={templateData}
                                fetchAndSetReportData={fetchAndSetReportData}
                                recurrenceData={recurrenceData}
                                onRecurrenceDataChange={onRecurrenceDataChange}
                                onTableSelectionsChange={onTableSelectionsChange}
                                categoryIndex={item.content.categoryIndex}
                            />
                        ) : null;
                    }
                    return null;
                })}
            </>
        );
    };

    const renderButtons = () => {
        if (currentStep === 0) {
            return (
                <>
                    <Button onClick={onCancel} data-testid="cancel-button">
                        Cancel
                    </Button>
                    <Button onClick={handleNext} disabled={validationErrors.length > 0} data-testid="next-button">
                        Next
                    </Button>
                </>
            );
        } else if (currentStep === steps.length - 1) {
            return (
                <>
                    <Button onClick={handlePrev} data-testid="previous-button">
                        Previous
                    </Button>
                    <Button onClick={handleNext} data-testid="submit-button">
                        Submit
                    </Button>
                </>
            );
        } else {
            return (
                <>
                    <Button onClick={handlePrev} data-testid="previous-button">
                        Previous
                    </Button>
                    {renderExtraButtons && renderExtraButtons()}
                    <Button onClick={handleNext} data-testid="next-button">
                        Next
                    </Button>
                </>
            );
        }
    };

    return (
        <Page loading={loading}>
            <Card title={title} onDelete={onDelete} showDelete={showDelete}>
                <ProgressBar
                    steps={steps.length}
                    currentStep={currentStep}
                    stepTitles={steps.map((step) => step.title)}
                />
                {renderStepContent()}
                <div className={styles.buttonArray}>{renderButtons()}</div>
                <Snackbar
                    message={generalError?.message}
                    onClose={() => setgeneralError(null)}
                    severity={generalError?.severity}
                    duration={generalError?.duration}
                />
            </Card>
        </Page>
    );
};

export default MultiStepForm;
