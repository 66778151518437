/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import AdditionalDetails from '../AdditionalDetails';
import { withFormik, Form, Field } from 'formik';
import MessageBox from '../MessageBox';
import * as Yup from 'yup';
import * as Moment from 'moment';

const SUPPORTED_FORMATS = ['image/png', 'image/jpg', 'application/pdf', 'image/jpeg'];
const FILE_SIZE = 1000000;

const FormLayout = ({
	data,
	errors,
	touched,
	isSubmitting,
	editMode,
	toggleEditMode,
	resetForm,
	newAddition,
	closeFlyout,
	setFieldValue,
	apiErrors,
	serviceProviderUsers,
	defaultAssignTo,
	messageOptions
}) => {

	const cancelAction = () => {
		if (newAddition) {
			resetForm();
			closeFlyout();
		} else {
			toggleEditMode();
			resetForm();
		}
	};

	const getError = (fieldname) => {
		if (apiErrors && apiErrors[fieldname]) {
			return apiErrors[fieldname];
		} else if (touched[fieldname] && errors[fieldname]) {
			return errors[fieldname];
		}
	}

	const handleFormChange = (e) => {
		if (e.target.type === 'checkbox') {
			setFieldValue(e.target.name, e.target.checked);
		} else {
			setFieldValue(e.target.name, e.target.value);
		}
		// this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
		// might be set so we can check it again
		if (apiErrors && apiErrors[e.target.name]) {
			delete apiErrors[e.target.name];
		}
	};

	const handleFileChange = (e) => {
		setFieldValue('file', e.target.files[0]);

		const imagePreview = document.getElementById('filePreview');
		const imagePreviewLink = document.getElementById('filePreviewLink');
		imagePreview.src = window.URL.createObjectURL(e.target.files[0]);
		imagePreviewLink.href = window.URL.createObjectURL(e.target.files[0]);

		imagePreview.classList.add('showFilePreview');
	};

	const getUserOptions = () => {
		let options = [<option key="-1" value=""></option>];
		serviceProviderUsers.forEach(user => {
			options.push(<option key={user.uuid} value={user.uuid}>{user.name}</option>)
		});
		return options;
	}

	const getFormErrors = () => {
		let errorList = [];
		messageOptions = {};
		['serial_number', 'make', 'model', 'description', 'equipment_id', 'assigned_to', 'file', 'notes'].forEach((field) => {
			let err = getError(field);
			if (err) {
				errorList.push(err);
			}
		});

		if (errorList.length !== 0) {
			messageOptions = {
				type: "error",
				message: "Please correct the following errors",
				list: errorList,
			};
			document.getElementById('assemblyForm').scrollIntoView();
		}

		return messageOptions;
	}

	return (
		<Form className="userDetailsForm" id="assemblyForm">
			<MessageBox options={getFormErrors()} />
			<fieldset disabled={!editMode}>
				<div className="detailsFieldset">
					<div className={'inputField medium ' + ((getError('serial_number')) ? 'formRed' : '')}>
						<label htmlFor="serial_number">Serial Number *</label>
						<Field type="text" name="serial_number" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField medium ' + ((getError('make')) ? 'formRed' : '')}>
						<label htmlFor="make">Make *</label>
						<Field type="text" name="make" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField medium ' + ((getError('model')) ? 'formRed' : '')}>
						<label htmlFor="model">Model *</label>
						<Field type="text" name="model" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					{/* Not sure this field is needed at all
					<div className={'inputField medium ' + ((getError('equipment_id')) ? 'formRed' : '')}>
						<label htmlFor="equipment_id">Test Kit ID</label>
						<Field type="text" name="equipment_id" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					*/}
					<div className={'inputField long ' + ((getError('description')) ? 'formRed' : '')}>
						<label htmlFor="description">Description</label>
						<Field type="text" name="description" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					{/*editMode && newAddition && (
						<div id="fileSelect">
							<div className={'inputField ' + ((getError('file')) ? 'formRed' : '')}>
								<label htmlFor="file">Test Kit Image *</label>
								<div className="flex">
									<input type="file" id="file" name="file"
										onChange={(e) => handleFileChange(e)}
									/>
									<a target="_blank" id="filePreviewLink">
										<img className="filePreview" id="filePreview" alt="" />
									</a>
								</div>
							</div>
						</div>
					)
					*/}
					<div className={'inputField long ' + ((getError('assigned_to')) ? 'formRed' : '')}>
						<label htmlFor="assigned_to">Assigned To</label>
						<Field component="select" name="assigned_to" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }} placeholder={defaultAssignTo}
						>
							{getUserOptions()}
						</Field>
					</div>
					<div className={'inputField long ' + ((getError('notes')) ? 'formRed' : '')}>
						<label htmlFor="property_id">Notes</label>
						<Field component="textarea" rows="2" name="notes" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					{!newAddition &&
						<div className={'inputField long ' + ((getError('owner')) ? 'formRed' : '')}>
							<label htmlFor="owner">Owned By Company?</label>
							<Field type="checkbox" name="owner" autoComplete="off" className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
					}
					{!newAddition && (
						<div className="inputField medium">
							<label htmlFor="next_test">Calibration Due Date</label>
							<Field type="text" name="next_test" autoComplete="off" className="inputField__input inputField__input-first" disabled={true} />
						</div>
					)}
					<AdditionalDetails
						details={data.c3_additional_details}
						editMode={editMode}
					/>
				</div>
				{editMode && (
					<React.Fragment>
						<div className="formButtonsBank">
							<button className="medButtonPrimary" disabled={isSubmitting} type="submit">Save</button>
							<button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
						</div>
					</React.Fragment>
				)}
			</fieldset>
		</Form>
	);
};

const TestKitDetailsForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,
	enableReinitialize: true,
	mapPropsToValues(props) {

		let values = {
			serial_number: (props.data.serial_number) ? props.data.serial_number : '',
			make: (props.data.make) ? props.data.make : '',
			model: (props.data.model) ? props.data.model : '',
			description: (props.data.description) ? props.data.description : '',
			equipment_id: (props.data.equipment_id) ? props.data.equipment_id : '',
			assigned_to: (props.data.assigned_to) ? props.data.assigned_to : '',
			owner: (props.data.owner && props.data.owner === 'false') ? false : true,
			file: null,
			notes: (props.data.notes) ? props.data.notes : '',
		};

		for (let uuid in props.data.c3_additional_details) {
			values[uuid] = (props.data.c3_additional_details[uuid].value) ? props.data.c3_additional_details[uuid].value : '';
		}

		values.next_test = (props.lastTest !== null) ? Moment.unix(props.lastTest.expiration_date).format('YYYY-MM-DD') : '';

		return values;

	},

	validationSchema: Yup.object().shape({
		serial_number: Yup.string().required('Serial Number is required'),
		make: Yup.string().required('Make is required'),
		model: Yup.string().required('Model is required'),
		description: Yup.string(),
		equipment_id: Yup.string(),
		notes: Yup.string(),
		file: Yup.mixed()
			// .required('An image of the test kit calibration is required')
			.test('fileType', 'Unsupported file format', value => { if (value) { SUPPORTED_FORMATS.includes(value.type); } return true; })
			.test('fileSize', 'File size is too large', value => { if (value) { value.size <= FILE_SIZE; } return true; })
		,
	}),

	handleSubmit(values, { props, resetForm, setSubmitting }) {
		values.equipment_type = 'TK';
		// deal with unchecked checkboxes
		['owner'].forEach(field => {
			if (props.data[field] && !values[field]) {
				// so I think this will set the value to false only if it was true to begin with
				values[field] = false;
			}
		});
		props.saveDetails(props.context, values);
		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
		}, 3000);
	}
})(FormLayout);

export default TestKitDetailsForm;
