import axios from "axios";

export const GET_PROPERTIES_REPORT_REQUEST = "GET_PROPERTIES_REPORT_REQUEST";
export const GET_PROPERTIES_REPORT_SUCCESS = "GET_PROPERTIES_REPORT_SUCCESS";
export const GET_PROPERTIES_REPORT_FAIL = "GET_PROPERTIES_REPORT_FAIL";

export const getPropertiesReport = () => (dispatch) => {
    const url = "/api/v1/reporting/0eba7876-ec72-465c-919f-35138aefa6c3";

    dispatch({ type: GET_PROPERTIES_REPORT_REQUEST });

    return axios
        .post(url)
        .then((response) => {
            dispatch({
                type: GET_PROPERTIES_REPORT_SUCCESS,
                payload: {
                    data: response.data,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_PROPERTIES_REPORT_FAIL,
                payload: JSON.stringify(error, null, 2),
            });
        });
};
