import React from 'react';
import { withFormik, Form, Field, FieldArray } from 'formik';
import MessageBox from '../MessageBox';
import * as Yup from 'yup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Tooltip } from '../Tooltip';
import ComplianceReportIssues from '../TestReports/ComplianceReportIssues';

const FormLayout = ({
    isSubmitting,
    errors,
    touched,
    editMode,
    toggleEditMode,
    resetForm,
    newAddition,
    closeFlyout,
    setFieldValue,
    apiErrors,
    setApiErrors,
    messageOptions,
    values,
    formErrors,
    setFormErrors
}) => {

    const cancelAction = () => {
        if (newAddition) {
            resetForm();
            closeFlyout();
        } else {
            toggleEditMode();
            resetForm();
        }
        // we might set api errors from our parent so if we cancel the edit just clear them out
        setApiErrors([]);
    };

    const getError = (fieldname) => {
        if (apiErrors && apiErrors[fieldname]) {
            return apiErrors[fieldname];
        } else if (touched[fieldname] && errors[fieldname]) {
            return errors[fieldname];
        }
    };

    const handleFormChange = (e) => {
        // this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
        // might be set so we can check it again
        if (Object.keys(apiErrors).length > 0) {
            setApiErrors({});
        }
    };

    const getFormErrors = () => {
        let errorList = [];
        messageOptions = {};
        ['communication_type', 'source', 'trigger_type', 'trigger_info'].forEach((field) => {
            let err = getError(field);
            if (err) {
                errorList.push(err);
            }
        });

        if (Object.keys(apiErrors).length > 0) {
            errorList.push(apiErrors);
        }

        if (errorList.length !== 0) {
            messageOptions = {
                type: "error",
                message: "Please correct the following errors",
                list: errorList,
            };
            if (formErrors != errorList.join(",")) {
                document.getElementById('commTypeDetailsForm').scrollIntoView();
            }
            setFormErrors(errorList.join(","));
        }

        return messageOptions;
    };

    const getSelectOptions = (optionType) => {
        let optionsList;
        let options = [];

        if (optionType === 'source') {
            options = ['', 'property', 'equipment', 'ad hoc'];
        } else if (optionType === 'trigger_type') {
            let element = document.getElementById('trigger_type');
            if (values.source === 'ad hoc') {
                element.disabled = true;
                options = ['none'];
            } else {
                if (element) {
                    element.disabled = false;
                }
                if (values.source === 'property') {
                    options = ['', 'compliance expiration'];
                } else if (values.source === 'equipment') {
                    options = ['', 'compliance expiration'];
                }
            }
        } else if (optionType === 'trigger_info') {
            let element = document.getElementById('trigger_info');
            if (values.source === 'ad hoc') {
                element.disabled = true;
                options = [''];
            } else {
                if (element) {
                    element.disabled = false;
                }
                if (values.source === 'equipment' && values.trigger_type === 'compliance expiration') {
                    options = ['', 'assembly test']
                } else if (values.source === 'property' && values.trigger_type === 'compliance expiration') {
                    options = ['', 'survey']
                }
            }
        }

        optionsList = options.map((type, index) => {
            let label;
            if (type === 'property') {
                label = 'location';
            } else if (type === 'equipment') {
                label = 'assembly';
            } else {
                label = type;
            }
            return (
                <option
                    value={type}
                    key={index}
                >
                    {label.charAt(0).toUpperCase() + label.slice(1)}
                </option>
            );
        });

        return optionsList;
    };

    return (
        <Form className="commDetailsForm" id="commTypeDetailsForm">
            <MessageBox options={getFormErrors()} />
            <fieldset disabled={!editMode}>
                <div className="detailsFieldset">
                    <div className={'inputField medium ' + ((getError('communication_type')) ? 'formRed' : '')}>
                        {editMode &&
                            <Tooltip text="Name of the group of communications which may have multiple templates.\n
                                For example, 'First Test Notice'. This may include two Templates - one for Residential and one for Commercial assemblies.' Create a second type for 'Second Test Notice"
                            />
                        }
                        <label htmlFor="communication_type">Type Label *</label>
                        <Field type="text" name="communication_type" autoComplete="off" className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                        />
                    </div>
                    <div className={'inputField medium ' + ((getError('source')) ? 'formRed' : '')}>
                        {editMode &&
                            <Tooltip text="The primary item we look at to determine if a communication needs generated.\n
                                For example an assembly (equipment) having a test about to be due, or a location (property) needing an survey inspection.\n
                                Choosing ad hoc here will allow you to create communications that are not automatically generated but you can include any contents you wish later in the template."
                            />
                        }
                        <label htmlFor="source">Source *</label>
                        <Field component="select" id="source" name="source" autoComplete="off" className="inputField__input inputField__input-first"
                            onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                                if (e.target.value === "ad hoc") {
                                    setFieldValue("trigger_type", "none");
                                } else {
                                    setFieldValue("trigger_type", "");
                                    setFieldValue("trigger_info", "");
                                }
                                handleFormChange(e);
                            }}
                        >
                            {getSelectOptions('source')}
                        </Field>
                    </div>
                    <div className={'inputField medium ' + ((getError('trigger_type')) ? 'formRed' : '')} id="commTriggerType">
                        {editMode &&
                            <Tooltip text="This is how the system determines when to create a communication.\n
                                Compliance expiration looks for any upcoming or overdue compliance items (location survey / inspections, assembly equipment tests).\n
                                None is for ad hoc communications where we do not trigger off any condition."
                            />
                        }
                        <label htmlFor="trigger_type">Trigger Type *</label>
                        <Field component="select" id="trigger_type" name="trigger_type" autoComplete="off" className="inputField__input inputField__input-first"
                            onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                                setFieldValue("trigger_info", "");
                                handleFormChange(e);
                            }}
                        >
                            {getSelectOptions('trigger_type')}
                        </Field>
                    </div>
                    <div className={'inputField medium ' + ((getError('trigger_info')) ? 'formRed' : '')} id="commTriggerInfo">
                        {editMode &&
                            <Tooltip text="Trigger info specifies which item the trigger type should look at.\n
                                For example compliance expiration on assembly equipment might look at assembly tests, whereas on a location property might look at a survey.
                            " />
                        }
                        <label htmlFor="trigger_info">Trigger Info</label>
                        <Field component="select" id="trigger_info" name="trigger_info" autoComplete="off" className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                        >
                            {getSelectOptions('trigger_info')}
                        </Field>
                    </div>
                </div>
                {
                    editMode && (
                        <div className="formButtonsBank">
                            <button className="medButtonPrimary" disabled={isSubmitting} type="submit">Save</button>
                            <button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
                        </div>
                    )
                }
            </fieldset>
        </Form>
    );
};

const CommTypeDetailsForm = withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    mapPropsToValues(props) {
        let values = {
            communication_type: (props.data.communication_type ? props.data.communication_type : ''),
            source: (props.data.source ? props.data.source : ''),
            trigger_type: (props.data.trigger_type ? props.data.trigger_type : ''),
            trigger_info: (props.data.trigger_info ? props.data.trigger_info : ''),
        };

        return values;
    },

    validationSchema: Yup.object().shape({
        communication_type: Yup.string().required('Type Label is required'),
        source: Yup.string().required('Source is required'),
        trigger_type: Yup.string().when('source', {
            is: 'equipment' || 'property',
            then: Yup.string().required('Trigger Type is required'),
            otherwise: null
        }),
        trigger_info: Yup.string().when('source', {
            is: 'equipment' || 'property',
            then: Yup.string().required('Trigger Info is required'),
            otherwise: null
        }),
    }),

    handleSubmit(values, { props, setSubmitting }) {

        Object.keys(values).forEach((key) => (values[key] == '') && delete values[key]);

        props.saveDetails(null, props.data.communication_type, values);
        setSubmitting(true);
        setTimeout(() => {
            setSubmitting(false);
        }, 3000);
    }
})(FormLayout);

export default CommTypeDetailsForm; 
