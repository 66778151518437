import parseInterval from "postgres-interval";
import { format } from "date-fns-tz";

/**
 * Options for a form field (e.g. a "select") can be specified in various forms by the API.  This converts them
 * to an array of objects with "label" and "value".
 *
 * - example, array of objects:
 *  [{ label: "dog", value: "1" }, { label: "cat", value: "2" }]
 *
 * - example, array of string values:
 *  ["dog", "cat", "tree"]
 *
 * - example, array of JSON encoded strings:
 *  ["{ label: \"dog\", value: \"1\" }", "{ label: \"cat\", value: \"2\" }"]
 *
 * @param {Object[] | string[]} values - Array of options in any of the formats above
 * @returns {{label:string, value:string}[] | null}
 */
export const getFieldOptions = (values) => {
    if (!values?.length) {
        return null;
    }

    return values.map((value) => {
        if (typeof value === "string") {
            if (value[0] === "{") {
                return JSON.parse(value);
            }

            return { label: value, value };
        }

        return value;
    });
};

/**
 * Converts an "input" from the reporting service to a formField object suitable for <Form>
 *
 * @param {Object} input - Object returned from reporting service API
 * @returns {formField}
 */
export const reportInputToFilterField = (input) => {
    let inputType,
        dataType = "text",
        defaultValue = input.default,
        options = getFieldOptions(input.values),
        suggestions = null;

    switch (input.type) {
        case "boolean":
            inputType = "checkbox";
            dataType = "boolean";

            // This horrible code must exist because there is a bug with default values in the report service
            if (input.default.toLowerCase() === "true") {
                defaultValue = true;
            } else if (input.default.toLowerCase() === "false") {
                defaultValue = false;
            }

            break;

        case "ternary_boolean":
            inputType = "select";
            options = [
                { label: "True", value: "true" },
                { label: "False", value: "false" },
                { label: "All", value: "null" },
            ];
            break;

        case "timestamp":
            inputType = "date";
            break;

        case "multi":
            inputType = "multi";
            break;

        default:
        case "string":
            inputType = options?.length ? "select" : "input";
            break;
    }

    return {
        id: input.id ?? input.name?.toLowerCase(),
        label: input.label ?? input.name,
        inputType,
        dataType,
        options,
        suggestions,
        defaultValue,
        required: input.required,
    };
};

/**
 * Convert a JS Date object to readable text
 *  - The intention of this function is just to have consistent date formatting across the app
 *  - All dates shown in the app should include a timezone
 *
 *  @param {Date|string} date - Either a JS Date object or a string that can be parsed to create one
 *  @param {"short"|"standard"|"long"} [length] - Type of formatting to use
 *  @returns {string}
 * */
export const formatDate = (date, length = "standard") => {
    if (!date) {
        return "";
    }

    const d = new Date(date);

    if (isNaN(d)) {
        return "";
    }

    switch (length) {
        case "long":
            return format(d, "EEEE, MMMM d y, h:mm aa zzzz");

        case "short":
            return format(d, "y-MM-dd h:mma zzz");

        default:
        case "standard":
            return format(d, "EEE, MMM d y, h:mma zzz");
    }
};

/**
 * Convert a JS date to a timestamp string for the API
 *  - The API expects dates to be a unix timestamp, in seconds (important - no milliseconds)
 *
 * @param {Date} date
 * @returns {string}
 */
export const dateToAPIString = (date) => {
    return `${Math.floor(date.getTime() / 1000)}`;
};

/**
 * Convert an API timestamp string to a JS Date
 *
 * @param {string} timestamp - unix timestamp, in seconds (important - no milliseconds)
 * @returns {Date}
 */
export const timestampToDate = (timestamp) => {
    return new Date(parseInt(timestamp) * 1000);
};

/**
 * Units supported by Postgres "interval" type, sorted from largest to smallest
 */
export const intervalUnits = ["years", "months", "days", "hours", "minutes", "seconds", "milliseconds"];

/**
 * Converts a Postgres "interval" type to a string that can be used by a form field
 *
 * @param {string} interval - The API string, examples: "00:05:00" or "2 days"
 * @returns {string}
 */
export const intervalToFormField = (interval) => {
    // Get an object of the form {days: 5, hours: 3, ...}
    const parsed = parseInterval(interval);
    let count = 0;
    let unit = "minutes";

    // Use only the largest unit in the list
    for (const u of intervalUnits) {
        if (parsed[u]) {
            count = parsed[u];
            unit = u;
            break;
        }
    }

    return `${count} ${unit}`;
};

/**
 * The options used internally by a recurrence form field
 *  - Note that these are not identical to the options passed to the API
 *
 * @typedef {Object} recurrenceOptions
 * @property {"one"|"rep"} schedule_type - one-time or repeating
 * @property {"daily"|"weekly"|"monthly"|"annually"} frequency - the base unit of the recurrence - weeks, months, etc
 * @property {string} interval - the number of "frequency" units - "every 2 weeks," "every 3 month," etc
 * @property {"date"|"weekday"|"last"} day_or_date - for monthly recurrence, how the day of the month is selected:
 *     on the same date every month, on the nth weekday (e.g. every 3rd Monday), or on the last day of the month
 * @property {string} nth - the value of N for recurrences like "every Nth Monday," as a string, e.g. "3"
 * @property {"MO"|"TU"|"WE"|"TH"|"FR"|"SA"|"SU"} day_of_week - which weekday for recurrences like "every Nth Monday"
 * @property {string} start - starting date/time of the recurrence, in timestamp form
 * @property {string} until - ending date/time of the recurrence, in timestamp form
 * @property {string} ad_hoc_date - the date, only if this is a "one-time" schedule
 * @property {Number} stop_after - optional maximum number of occurrences
 */

/**
 * Converts a recurrence options object to an English text string
 *
 * @param {recurrenceOptions} recurrenceOptions
 * @returns {string}
 * */
export const scheduleToText = ({
    schedule_type,
    frequency,
    interval,
    day_or_date,
    nth,
    day_of_week,
    start,
    until,
    ad_hoc_date,
    stop_after,
} = {}) => {
    if (!schedule_type || (schedule_type === "one" && !ad_hoc_date) || (schedule_type === "rep" && !frequency)) {
        return "";
    }

    if (schedule_type === "one") {
        return `One time: ${formatDate(timestampToDate(ad_hoc_date), "long")}`;
    }

    const frequencyText = {
        daily: "Every day",
        weekly: "Every week",
        monthly: "Every month",
        annually: "Every year",
    };
    const ordinals = {
        "-1": "last",
        1: "first",
        2: "second",
        3: "third",
        4: "fourth",
    };
    const ordinalSuffixes = {
        0: "th",
        1: "st",
        2: "nd",
        3: "rd",
        4: "th",
        5: "th",
        6: "th",
        7: "th",
        8: "th",
        9: "th",
    };
    const weekdayNames = {
        MO: "Monday",
        TU: "Tuesday",
        WE: "Wednesday",
        TH: "Thursday",
        FR: "Friday",
        SA: "Saturday",
        SU: "Sunday",
    };

    const textParts = [
        interval > 1 ? frequencyText[frequency]?.replace("Every", `Every ${interval}`) + "s" : frequencyText[frequency],
    ];

    if (frequency === "monthly") {
        switch (day_or_date) {
            case "weekday":
                textParts.push(`on the ${ordinals[nth]} ${weekdayNames[day_of_week]} of the month`);
                break;

            case "last":
                textParts.push("on the last day of the month");
                break;

            default:
            case "date":
                if (start) {
                    const dateStr = `${timestampToDate(start).getDate()}`;
                    textParts.push(`on the ${dateStr}${ordinalSuffixes[dateStr.slice(-1)]}`);
                }
                break;
        }
    }

    if (start) {
        textParts.push(`from ${formatDate(timestampToDate(start), "short")}`);
    }

    if (until) {
        textParts.push(`until ${formatDate(timestampToDate(until), "short")}`);
    }

    if (stop_after) {
        textParts.push(`and stop after ${stop_after} occurrences`);
    }

    return textParts.join(" ");
};
