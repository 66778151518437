import axios from 'axios';
import queryString from 'query-string';

export const getContactsReport = (payload) => {
	const url = '/api/v1/reporting/76ab96f4-f941-45be-86f8-157f9484422a';

	return axios.post(url, payload)
		.then(response => {
			console.log('Got Contacts Report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Contacts Report!', error.response.data);
			throw error;
		});
};

export const getContactLocationsReport = (payload) => {
	const url = '/api/v1/reporting/65d6bfb2-5e18-4e00-ab0b-93c8d5b0a0a0';

	return axios.post(url, payload)
		.then(response => {
			console.log('Got Contact Locations Report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Contact Locations Report!', error.response.data);
			throw error;
		});
};

export const getContact = (contactUUID) => {
	const url = `/api/v1/contacts/${contactUUID}`;

	return axios.get(url)
		.then(response => {
			console.log('Got Contact!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get Contact!', error.response.data);
			throw error;
		});
};

export const updateContact = (contactUUID, payload) => {
	const url = `/api/v1/contacts/${contactUUID}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated Contact!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t update Contact!', error.response.data);
			throw error;
		});
};

export const createContact = (payload) => {
	const url = '/api/v1/contacts';

	return axios.post(url, payload)
		.then(response => {
			console.log('Created Contact!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create Contact!', error.response.data);
			throw error;
		});
};

export const deleteContact = (contactUUID) => {
	const url = `/api/v1/contacts/${contactUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Deleted Contact!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t delete Contact!', error.response.data);
			throw error;
		});
};

export const getContactAddresses = (contactUUID, options) => {
	const url = `/api/v1/contacts/${contactUUID}/addresses`;

	let qs = queryString.stringify(options);

	return axios.get(url + ((qs) ? '?' + qs : ''))
		.then(response => {
			console.log('Got Contact Addresses!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Contact Addresses!', error.response.data);
			throw error;
		});
}

export const createContactAddress = (contactUUID, payload) => {
	const url = `/api/v1/contacts/${contactUUID}/addresses`;

	return axios.post(url, payload)
		.then(response => {
			console.log('Created Contact Address!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create Contact Address!', error.response.data);
			throw error;
		});
};

export const updateContactAddress = (contactUUID, addressUUID, payload) => {
	const url = `/api/v1/contacts/${contactUUID}/addresses/${addressUUID}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated Contact Address!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t update Contact Address!', error.response.data);
			throw error;
		});
};

export const deleteContactAddress = (contactUUID, addressUUID) => {
	const url = `/api/v1/contacts/${contactUUID}/addresses/${addressUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Deleted Contact Address!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t delete Contact Address!', error.response.data);
			throw error;
		});
};

export const getContactDetails = (contactUUID) => {
	const url = `/api/v1/contacts/${contactUUID}/details`;

	return axios.get(url)
		.then(response => {
			console.log('Got Contact Details!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Contact Details!', error.response.data);
			throw error;
		});
};

export const saveContactDetail = (contactUUID, payload) => {
	const url = `/api/v1/contacts/${contactUUID}/details`;

	return axios.post(url, payload)
		.then(response => {
			console.log('Saved Contact Detail!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Save Contact Detail!', error.response.data);
			throw error;
		});
};

export const getContactTypes = () => {
	const url = `/api/v1/contacts/properties/types`;

	return axios.get(url)
		.then(response => {
			console.log('Got Contact Types!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Contact Types!', error.response.data);
			throw error;
		});
}
