/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import AdditionalDetails from '../AdditionalDetails';
import LocationBFPStateReportFields,
{ LocationBFPStateReportFieldsPreprocess, LocationBFPStateReportFieldsPostprocess } from './LocationBFPStateReportFields';
import { withFormik, Form, Field } from 'formik';
import MessageBox from '../MessageBox';
import StateProvSelectField from '../Forms/StateProvSelectField';
import * as Yup from 'yup';
import DatePicker from '../DatePicker';
import moment, * as Moment from 'moment';
import { Tooltip } from '../Tooltip';

const FormLayout = ({
	data,
	values,
	errors,
	touched,
	isSubmitting,
	editMode,
	toggleEditMode,
	resetForm,
	newAddition,
	closeFlyout,
	setFieldValue,
	apiErrors,
	messageOptions,
	context,
	duplicateCheck,
	propertyContacts
}) => {

	const cancelAction = () => {
		if (newAddition) {
			resetForm();
			closeFlyout();
		} else {
			toggleEditMode();
			resetForm();
		}
	};

	const getError = (fieldname) => {
		if (apiErrors && apiErrors[fieldname]) {
			return apiErrors[fieldname];
		} else if (touched[fieldname] && errors[fieldname]) {
			return errors[fieldname];
		}
	}

	const handleFormChange = (e) => {
		// this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
		// might be set so we can check it again
		if (apiErrors && apiErrors[e.target.name]) {
			delete apiErrors[e.target.name];
		}
		if (errors[e.target.name]) {
			delete errors[e.target.name];
		}
	}

	const getFormErrors = () => {
		let errorList = [];
		messageOptions = {};
		['name', 'address1', 'address2', 'city', 'state_prov', 'postal_code', 'property_id', 'notes', 'next_survey', 'compliance_date'].forEach((field) => {
			let err = getError(field);
			if (err) {
				errorList.push(err);
			}
		});

		if (errorList.length !== 0) {
			messageOptions = {
				type: "error",
				message: "Please correct the following errors",
				list: errorList,
			};
			document.getElementById('locationsForm').scrollIntoView();
		}

		return messageOptions;
	}

	let contacts = [];
	propertyContacts.forEach(contact => {
		contacts.push(<option key={contact.contact_uuid} value={contact.contact_uuid}>{contact.name}</option>);
	});

	return (
		<Form className="userDetailsForm" id="locationsForm">
			<MessageBox options={getFormErrors()} />
			<fieldset disabled={!editMode}>
				<div className="detailsFieldset">
					<div className={'inputField long ' + ((getError('name')) ? 'formRed' : '')}>
						<label htmlFor="name">Location Name *</label>
						<Field type="text" name="name" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('address1')) ? 'formRed' : '')}>
						<label htmlFor="address1">Address *</label>
						<Field type="text" name="address1" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					{(editMode || data.address2 != "") && (
						<div className={'inputField long ' + ((getError('address2')) ? 'formRed' : '')}>
							<Field type="text" name="address2" autoComplete="off" className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
					)}
					<div className={'inputField small ' + ((getError('city')) ? 'formRed' : '')}>
						<label htmlFor="city">City *</label>
						<Field type="text" name="city" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField small ' + ((getError('state_prov')) ? 'formRed' : '')}>
						<label htmlFor="state_prov">State / Prov *</label>
						<StateProvSelectField
							fieldName="state_prov"
							handleChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField small ' + ((getError('postal_code')) ? 'formRed' : '')}>
						<label htmlFor="postal_code">Postal Code *</label>
						<Field type="text" name="postal_code" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('property_id')) ? 'formRed' : '')}>
						<label htmlFor="property_id">Account Number</label>
						<Field type="text" name="property_id" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('primary_contact_uuid')) ? 'formRed' : '')}>
						<label htmlFor="primary_contact_uuid">Primary Contact</label>
						<Field component="select" name="primary_contact_uuid" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						>
							<option value=""></option>
							{contacts}
						</Field>
					</div>
					<div className={'inputField long ' + ((getError('phone')) ? 'formRed' : '')}>
						<label htmlFor="phone">Phone</label>
						<Field type="text" name="phone" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					<div className={'inputField long ' + ((getError('notes')) ? 'formRed' : '')}>
						<label htmlFor="property_id">Notes</label>
						<Field component="textarea" rows="2" name="notes" autoComplete="off" className="inputField__input inputField__input-first"
							onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
						/>
					</div>
					{!newAddition && (
						<React.Fragment>
							<div className={'inputField small ' + ((getError('next_survey')) ? 'formRed' : '')}>
								<label htmlFor="next_survey">Next Survey Due</label>
								<Field
									name="next_survey"
									component={DatePicker}
									valueType="epoch"
									editMode={editMode}
									setFieldValue={setFieldValue}
								/>
							</div>
							<div className={'inputField small ' + ((getError('compliance_date')) ? 'formRed' : '')}>
								<label htmlFor="compliance_date">Compliance Date</label>
								<Tooltip text="The date that the compliance of this location was calculated, often this is the last survey date" />
								<Field
									name="compliance_date"
									component={DatePicker}
									valueType="epoch"
									editMode={editMode}
									setFieldValue={setFieldValue}
								/>
							</div>
							<div className={'inputField small ' + ((getError('compliant')) ? 'formRed' : '')}>
								<label htmlFor="compliant">Last Survey Compliance</label>
								<Tooltip text="Indicates whether the last survey was compliant or not.  Note that it might say it is compliant but the location is still out of compliance due to the expiration date" position="top-end" />
								<Field
									name="compliant"
									component="select"
									onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
								>
									<option value=""></option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</Field>
							</div>
						</React.Fragment>
					)}
				</div>
			</fieldset>
			<AdditionalDetails
				details={data.c3_additional_details}
				editMode={editMode}
			/>
			<LocationBFPStateReportFields
				setFieldValue={setFieldValue}
				editMode={editMode}
			/>
			{
				editMode && (
					<React.Fragment>
						<div className="formButtonsBank">
							<button className="medButtonPrimary" disabled={isSubmitting} type="submit">Save</button>
							<button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
							<button
								className="medButtonSecondary"
								type="button"
								onClick={
									(e) => {
										duplicateCheck(context, { name: values.name, property_uuid: (newAddition) ? undefined : data.property_uuid });
										// we don't have any feedback if there were no dupes or what we are doing, so adding something quick here
										e.target.innerHTML = "Checking...";
										setTimeout((btn) => { btn.innerHTML = "Check Duplicates" }, 2000, e.target);
									}
								}
							>
								Check Duplicates
							</button>
						</div>
					</React.Fragment>
				)
			}
		</Form>
	);
};

const LocationDetailsForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,
	enableReinitialize: true,
	mapPropsToValues(props) {
		let values = {
			name: (props.data.name ? props.data.name : ''),
			address1: (props.data.address1 ? props.data.address1 : ''),
			address2: (props.data.address2 ? props.data.address2 : ''),
			city: (props.data.city ? props.data.city : ''),
			state_prov: (props.data.state_prov ? props.data.state_prov : ''),
			postal_code: (props.data.postal_code ? props.data.postal_code : ''),
			property_id: (props.data.property_id ? props.data.property_id : ''),
			phone: (props.data.phone ? props.data.phone : ''),
			notes: (props.data.notes ? props.data.notes : '')
		};

		props.propertyContacts.forEach(contact => {
			if (contact.primary_contact) {
				values.primary_contact_uuid = contact.contact_uuid;
			}
		});

		for (let uuid in props.data.c3_additional_details) {
			values[uuid] = (props.data.c3_additional_details[uuid].value && props.data.c3_additional_details[uuid].value !== "false")
				? props.data.c3_additional_details[uuid].value
				: '';
		}

		let sr_vals = LocationBFPStateReportFieldsPreprocess(props.data.sr_fields);
		for (const key in sr_vals) {
			values[key] = sr_vals[key];
		}

		values.next_survey = (props.lastSurvey !== null) ? Moment.unix(props.lastSurvey.expiration_date) : null;
		values.compliance_date = (props.lastSurvey !== null) ? Moment.unix(props.lastSurvey.compliance_date) : null;
		values.compliant = (props.lastSurvey !== null) ? props.lastSurvey.compliant : "";

		return values;
	},

	validationSchema: Yup.object().shape({
		name: Yup.string().required('Location name is required'),
		address1: Yup.string().required('Address is required'),
		address2: Yup.string(),
		city: Yup.string().required('City is required'),
		state_prov: Yup.string().required('State / Prov is required'),
		postal_code: Yup.string().required('Zip Code is required'),
		property_id: Yup.string()
	}),

	handleSubmit(values, { props, resetForm, setSubmitting }) {
		const country = { 'country_code': SwiftComply.organization.country_code };
		let uuid;
		if (props.data) {
			uuid = props.data.property_uuid;
		}

		// Since we will be (re)building the sr_fields object, first delete whatever currently exists.
		delete values.sr_fields;
		values.sr_fields = LocationBFPStateReportFieldsPostprocess(values);

		props.saveDetails(props.context, uuid, {
			...values, ...country
		});
		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
		}, 3000);
	}
})(FormLayout);

export default LocationDetailsForm;
