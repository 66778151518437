import axios from "axios";

export const getPermit = (permitUUID) => {
    const url = `/api/v1/legalcontrols/${permitUUID}`;
    return axios.get(url).then((response) => response.data);
};

export const getPermitTemplates = () => {
    const url = "/api/v1/legalcontrols/templates";
    return axios.get(url).then((response) => response.data);
};

export const getPermitTemplateForm = (formUUID) => {
    const url = `/api/v1/legalcontrols/form/${formUUID}`;
    return axios.get(url).then((response) => response.data);
};

export const createTemplateDocument = (data) => {
    const url = "/api/v1/legalcontrols/form";
    return axios.post(url, data).then((response) => response.data);
};

export const updateTemplateDocument = (templateDocumentUUID, convertedFormState) => {
    const url = `/api/v1/legalcontrols/form/${templateDocumentUUID}`;

    // Find and update the document fields with the date values from form_data
    const updatedDocumentFields = convertedFormState.template_data.document_fields.map((field) => {
        if (field.name === "EffectiveDate") {
            return {
                ...field,
                value: convertedFormState.template_data.form_data.EffectiveDate || "",
            };
        }
        if (field.name === "ExpirationDate") {
            return {
                ...field,
                value: convertedFormState.template_data.form_data.ExpirationDate || "",
            };
        }
        return field;
    });

    // Create the updated data object with the modified document fields
    const data = {
        ...convertedFormState,
        template_data: {
            ...convertedFormState.template_data,
            form_data: convertedFormState.template_data.form_data,
            document_fields: updatedDocumentFields,
        },
    };

    return axios.put(url, data).then((response) => response.data);
};

export const deleteTemplateDocument = (templateDocumentUUID) => {
    const url = `/api/v1/legalcontrols/form/${templateDocumentUUID}`;
    return axios.delete(url).then((response) => response.data);
};

export const createLegalControl = (permitData) => {
    const url = `/api/v1/legalcontrols/permits`;
    return axios.put(url, permitData).then((response) => response.data);
};

export const updateLegalControl = (permitData) => {
    const url = `/api/v1/legalcontrols/permits/${permitData.legal_control_uuid}`;
    return axios.put(url, permitData).then((response) => response.data);
};

export const getTemplateDocument = (templateDocumentUUID) => {
    const url = `/api/v1/legalcontrols/form/${templateDocumentUUID}`;
    return axios.get(url).then((response) => response.data);
};

export const deleteLegalControlPending = (legalControlUUID) => {
    const url = `/api/v1/legalcontrols/${legalControlUUID}`;
    return axios.delete(url).then((response) => response.data);
};

export const createGeneralControl = (data) => {
    const url = "/api/v1/legalcontrols/general_controls";
    return axios.post(url, data).then((response) => response.data);
};

export const updateGeneralControl = (uuid, data) => {
    const url = `/api/v1/legalcontrols/general_controls/${uuid}`;
    return axios.put(url, data).then((response) => response.data);
};

export const deleteGeneralControl = (uuid) => {
    const url = `/api/v1/legalcontrols/general_controls/${uuid}`;
    return axios.delete(url).then((response) => response.data);
};

export const getGeneralControls = () => {
    const url = "/api/v1/legalcontrols/general_controls";
    return axios.get(url).then((response) => response.data);
};

export const getGeneralControl = (uuid) => {
    const url = `/api/v1/legalcontrols/general_controls/${uuid}`;
    return axios.get(url).then((response) => response.data);
};
