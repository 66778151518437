import axios from 'axios';

export const getForm = (formtype) => {
	const url = `/api/v1/forms/type/${formtype}`;
	return axios.get(url)
		.then(response => {
			let activeForm = null;
			response.data.forms.forEach(form => {
				if (form.active) {
					activeForm = form;
				}
			})
			console.log('Got Form', response);
			return activeForm;
		})
		.catch(error => {
			console.log('Couldn\'t get Form', error.response.data);
			throw error;
		});
};

export const getFieldsets = (formUUID) => {
	const url = `/api/v1/forms/${formUUID}/fieldsets`;

	return axios.get(url)
		.then(response => {
			console.log('Got Form Fieldsets!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Form Fieldsets!', error.response.data);
			throw error;
		});
};

export const getFieldsetFields = (formUUID, formFieldsetUUID) => {
	const url = `/api/v1/forms/${formUUID}/fieldsets/${formFieldsetUUID}/fields`;

	return axios.get(url)
		.then(response => {
			console.log('Got Fieldset Fields!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Fieldset Fields!', error.response.data);
			throw error;
		});
};

export const getFormFields = (formUUID) => {
	const url = `/api/v1/forms/${formUUID}/fields`;

	return axios.get(url)
		.then(response => {
			console.log('Got Form Fields!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Form Fields!', error.response.data);
			throw error;
		});
};

export const getField = (formFieldUUID) => {
	const url = `/api/v1/forms/fields/${formFieldUUID}`;

	return axios.get(url)
		.then(response => {
			console.log('Got Form Field', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Form Field', error.response.data);
			throw error;
		});
};