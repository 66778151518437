import React from "react";
import { dateToAPIString, timestampToDate } from "../../../../utils/forms";
import { FormControl, FormHelperText } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { AccessTimeRounded } from "@mui/icons-material";
import styles from "../../../../styles/common/dateTimePicker.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";

const DateField = ({
    label,
    timeLabel = "Time",
    disableFuture,
    value,
    setValue,
    error,
    disabled,
}) => {
    const date = value ? timestampToDate(value) : null;

    const handleDateChange = (newDate) => {
        if (newDate === null) {
            setValue(null);
            return;
        }
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);

        updateParentState(newDate, date ?? new Date());
    };

    const handleTimeChange = (newTime) => {
        if (newTime === null) {
            setValue(null);
            return;
        }
        updateParentState(date ?? new Date(), newTime);
    };

    const updateParentState = (newDate, newTime) => {
        if (newDate && newTime) {
            setValue(
                dateToAPIString(
                    new Date(
                        newDate.getFullYear(),
                        newDate.getMonth(),
                        newDate.getDate(),
                        newTime.getHours(),
                        newTime.getMinutes()
                    )
                )
            );
        } else {
            setValue(null);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl error={!!error}>
                <div className={styles.wrapper}>
                    <DatePicker
                        label={label}
                        value={date}
                        onChange={handleDateChange}
                        disabled={disabled}
                        disableFuture={disableFuture}
                        format="yyyy-MM-dd"
                        emptyLabel="YYYY-MM-DD"
                        variant="outlined"
                        error={!!error}
                        slotProps={{
                            textField: {
                                inputProps: {
                                    "data-testid": `${label}-date`,
                                },
                            },
                            actionBar: {
                                sx: {
                                    "& .MuiButton-root": {
                                        fontSize: "1.2rem",
                                    },
                                },
                            },
                        }}
                    />
                    <TimePicker
                        label={timeLabel}
                        value={date}
                        onChange={handleTimeChange}
                        disabled={disabled}
                        ampm={true}
                        variant="outlined"
                        error={!!error}
                        slotProps={{
                            textField: {
                                inputProps: {
                                    "data-testid": `${label}-time`,
                                },
                            },
                            actionBar: {
                                sx: {
                                    "& .MuiButton-root": {
                                        fontSize: "1.2rem",
                                    },
                                },
                            },
                        }}
                        slots={{
                            openPickerIcon: AccessTimeRounded,
                        }}
                    />
                </div>
                {error && (
                    <FormHelperText classes={helperTextStyles}>
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
        </LocalizationProvider>
    );
};

export default DateField;
