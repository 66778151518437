/*
Some form fields, whether they are on a location detail form, a test report, survey, etc might need to have suggested
values associated with them.  These can take on two forms.  First would just be a list of values.  Second could be an
object where each key is the value from some other field, and the list is then put in place if that value is selected.

Originally this was done in the backend just adding values to a form field, however those values are also double checked
by the forms service on submission, and it would reject values not in the suggested list which isn't what we want.  So
the backend would need another return type to do this properly.  Also until we are sure how this is going to work just
going to do it here to make life easier.
*/

const suggestedValues = {
    "Assembly Make": [
        "Ames", "Apollo/Conbraco", "ARI", "Backflow Direct", "BEECO", "Belvedere", "Cash Acme", "Chicago Faucet", "Cla-Val",
        "Conbraco", "Crane", "Dema", "Diversey", "Ecolab", "FEBCO", "Hunter Industries", "Hydro Systems", "Neptune",
        "OMNI Brass", "Rain Bird", "Sloan Valve", "T&S Brass & Bronze", "Toro", "WaterSaver", "Watts", "Wilkins/Zurn",
        "Zurn Industries", "N/A - AirGap", "N/A - Method", "Other", "Unknown"
    ],
};

// addFormFieldSuggestedValues expects a list of fields like one we would get back from the forms service, and will
// go over them adding in any suggested values it sees
export const addFormFieldSuggestedValues = (fields) => {
    for (let i = 0; i < fields.length; i++) {
        if (suggestedValues[fields[i].form_field_type]) {
            fields[i].suggested_values = suggestedValues[fields[i].form_field_type];
        }
    }
    return fields;
}

// getFormFieldSuggestedValues takes in a field type and just returns the suggested values to the caller so it can do
// whatever it wants with them.  Useful on say a details form thta doesn't use the forms service to build itself
export const getFormFieldSuggestedValues = (fieldType) => {
    let suggestions = [];
    if (suggestedValues[fieldType]) {
        suggestions = suggestedValues[fieldType];
    }
    return suggestions;
}