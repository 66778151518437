import React, { useEffect, useState } from "react";
import LoadingSpinner from "../Common/LoadingSpinner";
import { getErrorText } from "../../utils/errors";

const PDFPreview = ({ pdfData }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (pdfData) {
            setLoading(false);
        }
    }, [pdfData]);

    if (error) {
        return <div>{getErrorText(error)}</div>;
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div>
            <iframe src={`data:application/pdf;base64,${pdfData}`} width="100%" height="600px" allowFullScreen></iframe>
        </div>
    );
};

export default PDFPreview;
