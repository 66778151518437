import React from 'react';
import exit from '../../images/x.svg';
import usersIcon from '../../images/userMedium.svg';
import contactsIcon from '../../images/contactsMedium.svg';
import testReportIcon from '../../images/testReportsMedium.svg';
import locationsIcon from '../../images/locationsMedium.svg';
import lettersIcon from '../../images/lettersMedium.svg';
import serviceProvidersIcon from '../../images/serviceProviderMedium.svg';
import assembliesIcon from '../../images/assemblyMedium.svg';
import settingsIcon from '../../images/settingsBlue.svg';
import FlyoutCategories from './FlyoutCategories';
import { getOrganizationSystemSettings } from '../api/organizationAPI';

/*
Ok this component has gotten out of hand so a few rules on the props.

uuid - UUID of the thing being displayed
data - if provided, will be api data for the object defined by uuid
source - if this is being opened as a secondary, where did we come from (property, serviceProvider, etc)
sourceUUID - the UUID of the source item

newAddition - boolean whether we are making a new thing or not.

create (new object, form payload) - function to be called when something is created
update (updated object, form payload) - function to be called when the thing is updated
delete (object uuid) - function to be called when the thing is deleted
unlink (object uuid) - function to be called to unlink the thing from its source
refresh () - function that should handle refreshing the parent of the flyout

flyoutType - the type of flyout (contacts, locations, etc)
flyoutPriority - primary / secondary, i.e. how this is displayed
openFlyout - function to open the flyout
closeFlyout - function to close the flyout
activeTab - which "panel" / category to display first, usually the details one
*/

class Flyout extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeCategory: props.activeTab,
			uuid: props.uuid,
			tabs: [],
			header: '',
			active: true,
			readyToRender: false // because I hate react
		};
	}

	componentDidMount() {
		const { flyoutPriority, flyoutType, newAddition, activeTab } = this.props;
		if (flyoutPriority === 'primary') {
			const flyout = document.getElementById('flyout');
			setTimeout(() => {
				flyout.classList.add('slideToVisible');
			});
		} else if (flyoutPriority === 'secondary') {
			const flyoutSecondary = document.getElementById('flyoutSecondary');
			setTimeout(() => {
				flyoutSecondary.classList.add('slideToVisible');
			});
		}

		if (newAddition) {
			if (flyoutType === 'locations') {
				this.setHeader('New Location');
			} else if (flyoutType === 'assemblies') {
				this.setHeader('New Assembly');
			} else if (flyoutType === 'testKits') {
				this.setHeader('New Test Kit');
			} else if (flyoutType === 'serviceProviders') {
				this.setHeader('New Service Provider');
			} else if (flyoutType === 'users') {
				this.setHeader('New User');
			} else if (flyoutType === 'contacts') {
				this.setHeader('New Contact');
			} else if (activeTab === 'commDetails') {
				this.setHeader('New Communication');
			} else if (activeTab === 'commTypeDetails') {
				this.setHeader('New Communication Type');
			} else if (activeTab === 'commLogDetails') {
				this.setHeader('New Communication Log');
			} else if (activeTab === 'commLogContactDetails') {
				this.setHeader('New Communication Log Contact');
			}
		}
		getOrganizationSystemSettings().then(orgSettings => {
			this.setState({ readyToRender: true });
		});
	}

	setHeader = (header, active) => {
		let isActive;

		if (active === undefined) {
			isActive = true;
		} else {
			isActive = active;
		}
		this.setState({ header, active: isActive });
	}

	defineCategories = () => {
		const { flyoutType, newAddition } = this.props;

		// if we explicitly set the disable legacy comms to false, we want them to see it, maybe they need to check out
		// something.  However if it is empty string, undef, null (because I'm not sure what it will be exactly) then
		// default it to true since we've migrated.
		let tabs;
		switch (flyoutType) {
			case 'locations':
				if (newAddition) {
					tabs = { locationDetails: 'DETAILS' };
				} else {
					tabs = { locationDetails: 'DETAILS', locationAssemblies: 'ASSEMBLIES', locationContacts: 'CONTACTS', communications: 'COMMS', locationSurveyHistory: 'SURVEYS', complianceHistory: 'COMPLIANCE HISTORY' };
				}
				break;
			case 'serviceProviders':
				if (newAddition) {
					tabs = { serviceProviderDetails: 'DETAILS' };
				} else {
					tabs = { serviceProviderDetails: 'DETAILS', serviceProviderTestKits: 'TEST KITS', serviceProviderUsers: 'USERS', serviceProviderContacts: 'CONTACTS' };
				}
				break;
			case 'assemblies':
				if (newAddition) {
					tabs = { assemblyDetails: 'DETAILS' };
				} else {
					tabs = { assemblyDetails: 'DETAILS', locationDetails: 'LOCATION', assemblyTestHistory: 'REPORT HISTORY', communications: 'COMMS', complianceHistory: 'COMPLIANCE HISTORY' };
				}
				break;
			case 'testKits':
				if (newAddition) {
					tabs = { testKitDetails: 'DETAILS' }
				} else {
					tabs = { testKitDetails: 'DETAILS', testKitCalibration: 'CALIBRATIONS' };
				}
				break;
			case 'contacts':
				if (newAddition) {
					tabs = { contactDetails: 'DETAILS' };
				} else {
					tabs = { contactDetails: 'DETAILS', contactAddresses: 'CONTACT ADDRESSES', contactLocations: 'CONTACT LOCATIONS', communications: 'COMMS' };
				}
				break;
			case 'testReports':
				tabs = { testReportDetails: 'DETAILS', testReportIssues: 'COMPLIANCE ISSUES', testReportFiles: 'FILES' };
				break;
			case 'surveys':
				tabs = { testReportDetails: 'DETAILS', testReportIssues: 'COMPLIANCE ISSUES', testReportFiles: 'FILES' };
				break;
			case 'users':
				if (newAddition) {
					tabs = { userDetails: 'DETAILS' };
				} else {
					tabs = { userDetails: 'DETAILS' };

					if (this.props.isServiceProviderUser) {
						tabs.userCerts = 'CERTIFICATIONS';
					}
					// Don't show permissions tab if not accessing details from the Users Dashboard
					if (this.props.flyoutPriority !== null && this.props.flyoutPriority !== 'secondary') {
						// actually just don't show permissions for now
						//tabs.permissions = 'PERMISSIONS';
					}
				}
				break;
			case 'apiToken':
				tabs = { apiTokenDetails: 'DETAILS' };
				break;
			case 'comms':
				if (newAddition) {
					tabs = { commDetails: 'DETAILS' };
				} else {
					tabs = { commDetails: 'DETAILS' };
				}
				break;
			case 'commTypes':
				if (newAddition) {
					tabs = { commTypeDetails: 'DETAILS' };
				} else {
					tabs = { commTypeDetails: 'DETAILS' };
				}
				break;
			case 'commLogs':
				if (newAddition) {
					tabs = { commLogDetails: 'DETAILS' };
				} else {
					tabs = { commLogDetails: 'DETAILS' };
				}
				break;
			case 'commLogContact':
				if (newAddition) {
					tabs = { commLogContactDetails: 'DETAILS' };
				} else {
					tabs = { commLogContactDetails: 'DETAILS' };
				}
				break;
			case 'commDownloadRequest':
				tabs = { commDownloadRequestDetails: 'DETAILS' };
				break;
		}

		return (
			Object.keys(tabs).map((tab, index) => {
				return (
					<button
						key={index}
						onClick={() => this.setState({ activeCategory: tab })}
						className={this.state.activeCategory === tab ? 'active' : null}
					>
						<span>{tabs[tab]}</span>
					</button>
				);
			})
		);
	}

	render() {
		const { flyoutType, flyoutPriority } = this.props;

		let imageType;
		if (flyoutType === 'users') {
			imageType = usersIcon;
		} else if (flyoutType === 'locations') {
			imageType = locationsIcon;
		} else if (flyoutType === 'serviceProviders') {
			imageType = serviceProvidersIcon;
		} else if (flyoutType === 'assemblies') {
			imageType = assembliesIcon;
		} else if (flyoutType === 'contacts') {
			imageType = contactsIcon;
		} else if (flyoutType === 'testReports' || flyoutType === 'surveys') {
			imageType = testReportIcon;
		} else if (flyoutType === 'comms' || flyoutType === 'commTypes' || flyoutType === 'commLogs') {
			imageType = lettersIcon;
		} else if (flyoutType === 'apiToken') {
			imageType === settingsIcon;
		} else {
			imageType = null;
		}


		return (
			<div
				className={flyoutPriority === 'primary' ? 'flyoutContainer' : 'flyoutContainer flyoutSecondary'}
				id={flyoutPriority === 'primary' ? 'flyout' : 'flyoutSecondary'}
			>
				<div className="flyout">
					<div className="flyout__header">
						<span className="flyout__header-title">{this.state.header}</span>
						{!this.state.active && <span className="flyout__header-inactive">Inactive</span>}
						<button
							id='exitButton'
							onClick={() => this.props.closeFlyout()}
						>
							<img src={exit} alt='' />
						</button>
						<img
							className={flyoutType === 'assemblies' ? 'flyout__header-icon headerIconLow' : 'flyout__header-icon'}
							src={imageType} alt=''
						/>
					</div>
					<div className="flyout__categories">
						{this.state.readyToRender && (this.defineCategories())}
					</div>
					<div className="flyout__body">
						<FlyoutCategories setHeader={this.setHeader} activeCategory={this.state.activeCategory} {...this.props} />
					</div>
				</div>
			</div>
		);
	}
}

export default Flyout;
