import React from "react";
import ReportConfigurationStep from "./ReportConfigurationStep";
import SamplingConfigurationsStep from "./SamplingConfigurationsStep";
import SRCReviewStep from "./SRCReviewStep";
import { BasicSampleReportConfigFields } from "./BasicSampleReportConfigFields";

export const addSampleReportConfigSteps = [
    {
        title: "Report Configuration",
        customStep: {
            render: (props) => <ReportConfigurationStep {...props} />,
        },
        fields: BasicSampleReportConfigFields
    },
    {
        title: "Sampling Configurations",
        customStep: {
            render: (props) => <SamplingConfigurationsStep {...props} />,
        },
    },
    {
        title: "Review",
        customStep: {
            render: (props) => <SRCReviewStep {...props} />,
        },
    },
];
