import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getPathname, getURLParentObject } from "../../../utils/url";
import { getReferencePoint, getReferencePointDetails } from "../../../api/referencePointsAPI";
import { getForm, getFormFields } from "../../../api/formsAPI";
import { detailsReportMapping } from "../../../utils/reporting";
import { BasicOutfallFields } from "./BasicOutfallFields";
import { BasicSampleReportConfigFields } from "../SampleReportConfigs/BasicSampleReportConfigFields";
import { formatDate, reportInputToFilterField, timestampToDate } from "../../../utils/forms";
import { rawValueToLabel } from "../../../utils/columnFormats";
import AdvancedReportTable from "../../Common/ReportTable/AdvancedReportTable";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import SummaryGrid from "../../Common/Forms/SummaryGrid";
import EditOutfall from "./EditOutfall";
import BasicReportTable from "../../Common/ReportTable/BasicReportTable";
import LoadingSpinner from "../../Common/LoadingSpinner";

const ViewOutfall = ({ history }) => {
    const pathname = getPathname();
    const { id: outfallId } = getURLParentObject("o") || {};
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [basicFields, setBasicFields] = useState({});
    const [detailsFields, setDetailsFields] = useState({});
    const [detailsFieldsLabels, setDetailsFieldsLabels] = useState({});
    const [editMode, setEditMode] = useState(false);

    const refreshOutfall = () => {
        setLoading(true);
        setError(null);

        getReferencePoint(outfallId)
            .then((data) =>
                setBasicFields({
                    ...BasicOutfallFields.reduce((prev, curr) => {
                        if (curr.id === "latitude") {
                            return { ...prev, [curr.id]: data.geom?.lat };
                        } else if (curr.id === "longitude") {
                            return { ...prev, [curr.id]: data.geom?.lon };
                        } else {
                            return { ...prev, [curr.id]: data[curr.id] };
                        }
                    }, {}),
                    property_uuid: data.property_uuid,
                })
            )
            .then(() => getReferencePointDetails(outfallId))
            .then((response) =>
                setDetailsFields(
                    response.details?.reduce(
                        (prev, curr) => ({
                            ...prev,
                            [curr.form_field_uuid]: curr.value,
                        }),
                        {}
                    ) || {}
                )
            )
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const onEditComplete = () => {
        setEditMode(false);
        refreshOutfall();
    };

    useEffect(() => {
        setLoading(true);
        setError(null);

        getForm(detailsReportMapping.reference_point)
            .then((data) => (data?.form_uuid ? getFormFields(data.form_uuid) : { form_fields: [] }))
            .then((fields) =>
                setDetailsFieldsLabels(
                    fields.form_fields?.reduce(
                        (prev, curr) => ({
                            ...prev,
                            [curr.form_field_uuid]: reportInputToFilterField(curr),
                        }),
                        {}
                    )
                )
            )
            .catch(setError)
            .finally(() => setLoading(false));

        refreshOutfall();
    }, []);

    if (editMode && !loading && !error) {
        return (
            <EditOutfall
                preloadedBasicState={basicFields}
                preloadedDetailsState={detailsFields}
                onEditComplete={onEditComplete}
                onEditCancelled={() => setEditMode(false)}
            />
        );
    }

    const notShownOnSummaryGrid = ["name", "id", "active"];
    const fields = [
        ...BasicOutfallFields.filter((f) => !notShownOnSummaryGrid.includes(f.id)),
        ...Object.entries(detailsFieldsLabels).map((f) => ({ ...f[1], id: f[0] })),
    ];

    const values = {
        ...basicFields,
        ...detailsFields,
    };

    const onCreateSampleReportConfig = () => {
        sessionStorage.removeItem("existingSRC");
        sessionStorage.removeItem("srcFormState");
        sessionStorage.removeItem("srcSamplingConfigs");
        sessionStorage.removeItem("srcCompleteState");
        history.push(`${pathname}/addSampleReportConfig`);
    };

    return (
        <Page error={error}>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <Card title={`${basicFields.name} (${basicFields.id})`} onEdit={() => setEditMode(true)}>
                    <SummaryGrid fields={fields} formState={values} />
                </Card>
            )}
            <Card title="Limits" onCreate={() => history.push(`${pathname}/addLimit`)} onCreateLabel="New Limit">
                <AdvancedReportTable
                    reportUUID="d821dcaf-5fa2-4440-8f70-fed7346b94f5"
                    inputs={{"outfall uuid": outfallId }}
                    defaultColumns={[
                        "Analyte",
                        "Limit Type",
                        "Limit Unit",
                        "Min Value",
                        "Max Value",
                        "Definition",
                        "Rationale",
                    ]}
                    rowLinks={(row) => `${pathname}/l/${row["Limit UUID"]}`}
                />
            </Card>
            <Card
                title="Sample Report Configs"
                onCreate={() => onCreateSampleReportConfig()}
                onCreateLabel="New Sample Report Config"
            >
                <BasicReportTable
                    reportUUID="33bbab41-7bba-4acb-b743-af3c829faa75"
                    inputs={{ "Outfall UUID": outfallId }}
                    rowLinks={(row) => `${pathname}/src/${row["Sample Report Config ID"]}`}
                    columnFormats={{
                        "Ad Hoc Dates": (value) => formatDate(timestampToDate(value), "short"),
                        "Assigned To": rawValueToLabel(BasicSampleReportConfigFields, "assigned_to"),
                    }}
                />
            </Card>
        </Page>
    );
};

export default withRouter(ViewOutfall);
