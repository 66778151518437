import React, { useEffect, useState } from "react";
import { Menu } from "@mui/material";
import styles from "../../styles/common/popUpMenuForm.module.css";
import menuStyles from "../../styles/common/matieralUI/menu.module.css";
import IconButton from "./IconButton";
import Form from "./Forms/Form";
import Button from "./Button";

/**
 * An icon button that opens a pop-up form when clicked
 * The form will include "Apply" and "Cancel" buttons at the bottom
 *
 * @param {JSX.Element} icon - MaterialUI icon that opens the form when clicked
 * @param {string} [title] - Title displayed at top of form
 * @param {formField[]} formFields - The form fields displayed inside the pop-up
 *     - See the <Form> component for a description of field properties
 * @param {Object} [initialFormState] - Object containing the state of the form before the user makes any changes
 * @param {(Object) => void} onApply - Called if the user applies the changes, selected values are passed as an object
 */
const PopUpMenuForm = ({ icon, title, formFields, initialFormState, onApply }) => {
    const [anchor, setAnchor] = useState(/** @type {Element} */ null);
    const [formState, setFormState] = useState(initialFormState ?? {});

    useEffect(() => {
        setFormState(initialFormState ?? {});
    }, [JSON.stringify(formFields), JSON.stringify(initialFormState)]);

    const onClose = () => setAnchor(null);

    return (<>
        <IconButton
            icon={icon}
            onClick={(event) => setAnchor(event.currentTarget)}
            size="large" />
        <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={onClose} classes={menuStyles}>
            <div className={styles.menuInner}>
                {title && <div className={styles.title}>{title}</div>}
                <div className={styles.formBody}>
                    <Form fields={formFields} formState={formState} onFormStateChange={setFormState} />
                </div>
                <div className={styles.buttonWrapper}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            onApply(formState);
                            onClose();
                        }}
                    >
                        Apply
                    </Button>
                </div>
            </div>
        </Menu>
    </>);
};

export default PopUpMenuForm;
