import axios from 'axios';

export const getOrganization = () => {
	const url = '/api/v1/organization/current';

	return axios.get(url)
		.then(response => {
			console.log('Got Organization Data', response);
			SwiftComply.organization = response.data.organization;
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Organization Data', error.response.data);
			throw error;
		});
};

export const updateOrganization = (organization_uuid, payload) => {
	const url = `/api/v1/organization/${organization_uuid}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated Organization Data', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Update Organization Data', error.response.data);
			throw error;
		});
};

export const listOrganizations = (can_login) => {
	const url = '/api/v1/organization';
	let req_config = {params: {}};
	if (can_login === true) {
		req_config.params.can_login = true;
	}

	return axios.get(url, req_config)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			throw error;
		});
};

export const getOrganizationSetting = (setting) => {
	const url = `/api/v1/organization/${SwiftComply.organization.organization_uuid}/settings/${setting}`;

	return axios.get(url)
		.then(response => {
			return response.data.value;
		})
		.catch(() => {
			return null;
		});
}

// org system settings are just another setting, but we might want to use it all over the UI, so instead of fetching
// it all the time, parsing, etc we are going to do that work in this function to make it easier on the caller
export const getOrganizationSystemSettings = () => {
	const url = `/api/v1/organization/${SwiftComply.organization.organization_uuid}/settings/org.system_settings`;

	if (SwiftComply.organization_system_settings !== null) {
		// we've already loaded the settings so just return them, but we have to do that as a resolved promise since
		// that is what callers will expect.
		return Promise.resolve(SwiftComply.organization_system_settings);
	}

	// I'm just always going to return some valid json object here to make the caller's life easier
	return axios.get(url)
		.then(response => {
			SwiftComply.organization_system_settings = {};
			if (response.data && response.data.value != null && response.data.value != "") {
				SwiftComply.organization_system_settings = JSON.parse(response.data.value);
			}
			return SwiftComply.organization_system_settings;
		})
		.catch(err => {
			if (err.response && err.response.status == 404) {
				// 404 on settings means it isn't set, so if that is the case set our cache to an empty object
				SwiftComply.organization_system_settings = {};
			}
			return {};
		});
}
