/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import infinityWhite from '../../images/infinitylogowhite.svg';
import fullLogo from '../../images/SwiftComplyRetina.png';
import '../styles/styles.scss';

const ForgotPassword = () => {
    const urlParts = window.location.pathname.split("/");
    let requestUUID = urlParts[2];

    return (
        <div className="loginContainer">
            <div className="loginLeft">
                <img className="loginLeft__logo" src={infinityWhite} />
            </div>
            <div className="loginRight">
                <div className="loginRight__container">
                    <div className="loginRight__logo-wrapper">
                        <img className="loginRight__logo" src={fullLogo} />
                    </div>
                    {SwiftComply?.settings?.disable_login === true
                        ? <div>Password resets are temporarily disabled due to system maintenance.</div>
                        : <ForgotPasswordForm passwordResetUUID={requestUUID} />
                    }
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;