import SamplingScheduleField from "./SamplingScheduleField";
import { scheduleToText } from "../../../utils/forms";

const compliance_toggle = {
    label: "Compliance",
    id: "compliance_determinants",
    behavior: "add_to_array",
    hideWhen: (formState) => formState.isAuthority,
};

/** @type {formField[]} */
export const BasicSamplingConfigFields = [
    {
        id: "name",
        label: "Configuration Name",
        required: true,
    },
    {
        id: "analytes",
        label: "Analyte(s)/Parameter(s)",
        inputType: "multi",
        options: [],
        toggle: compliance_toggle,
        required: true,
    },
    {
        id: "sample_type",
        label: "Sample Type",
        inputType: "select",
        options: [
            { label: "Grab", value: "grab" },
            { label: "Meter", value: "meter" },
            { label: "Composite", value: "composite" },
            { label: "Field-composite", value: "field-composite" },
        ],
        toggle: compliance_toggle,
    },
    {
        id: "pacing",
        label: "Pacing",
        inputType: "select",
        options: [
            { label: "Flow", value: "flow" },
            { label: "Time", value: "time" },
        ],
        toggle: compliance_toggle,
        testId: "pacing-select",
    },
    {
        // This would normally be an "interval" type but the API does not currently support this - the count/unit are
        //  stored separately in "pacing_value" and "pacing_unit"
        id: "pacing_value_time",
        label: "Pacing Value",
        inputType: "amount",
        options: [
            { label: "minutes", value: "minutes" },
            { label: "hours", value: "hours" },
            { label: "days", value: "days" },
            { label: "weeks", value: "weeks" },
            { label: "months", value: "months" },
        ],
        toggle: compliance_toggle,
        hideWhen: (formState) => formState.pacing !== "time",
    },
    {
        id: "pacing_value_flow",
        label: "Pacing Value",
        inputType: "amount",
        options: [
            { label: "million-gallons", value: "million-gallons" },
            { label: "gallons", value: "gallons" },
            { label: "liters", value: "liters" },
        ],
        toggle: compliance_toggle,
        hideWhen: (formState) => formState.pacing !== "flow",
    },
    {
        id: "min_aliquots",
        label: "Minimum Aliquots",
        dataType: "numeric",
        toggle: compliance_toggle,
    },
    {
        id: "container_types",
        label: "Container Type",
        inputType: "multi",
        options: [
            { label: "Clear glass", value: "clear glass" },
            { label: "Amber glass", value: "amber glass" },
            { label: "HDPE", value: "HDPE" },
            { label: "Opaque HDPE", value: "opaque HDPE" },
            { label: "Amber HDPE", value: "amber HDPE" },
        ],
        toggle: compliance_toggle,
    },
    {
        id: "container_volumes",
        label: "Container Volume",
        inputType: "multi",
        options: [
            { label: "40 ml", value: "40 ml" },
            { label: "50 ml", value: "50 ml" },
            { label: "125 ml", value: "125 ml" },
            { label: "200 ml", value: "200 ml" },
            { label: "250 ml", value: "250 ml" },
            { label: "500 ml", value: "500 ml" },
            { label: "1000 ml", value: "1000 ml" },
            { label: "2000 ml", value: "2000 ml" },
            { label: "4000 ml", value: "4000 ml" },
        ],
        toggle: compliance_toggle,
    },
    {
        id: "preservatives",
        label: "Preservative",
        inputType: "multi",
        options: [
            { label: "H2SO4", value: "H2SO4" },
            { label: "Na2S2O3", value: "Na2S2O3" },
            { label: "NaOH", value: "NaOH" },
            { label: "HCl", value: "HCl" },
            { label: "HNO3", value: "HNO3" },
            { label: "AlCL3 + NaOH", value: "AlCL3 + NaOH" },
            { label: "NaOH + ZnAC", value: "NaOH + ZnAC" },
            { label: "EDTA", value: "EDTA" },
        ],
        toggle: compliance_toggle,
    },
    {
        id: "max_hold_time",
        label: "Maximum Hold Time",
        inputType: "interval",
        toggle: compliance_toggle,
    },
    {
        id: "lab_methods",
        label: "Lab Method(s)",
        inputType: "multi",
        options: [
            {
                label: "120.1 - Conductance (Specific Conductance, μmhos, 25 °C) by Conductivity Meter",
                value: "120.1",
            },
            {
                label: "130.1 - Hardness, Total (mg/L as CaCO3) (Colorimetric, Automated EDTA) by Spectrophotometer",
                value: "130.1",
            },
            {
                label: "150.2 - pH, Continuous Monitoring (Electrometric) by pH Meter",
                value: "150.2",
            },
            { label: "160.2", value: "160.2" },
            {
                label: "160.4 - Residue, Volatile (Gravimetric, Ignition at 550 °C) by Muffle Furnace",
                value: "160.4",
            },
            {
                label: "180.1 - Turbidity by Nephelometry. Revision 2.0",
                value: "180.1",
            },
            {
                label: "200.2 - Sample Preparation Procedure for Spectrochemical Determination of Total Recoverable Elements. Revision 2.8",
                value: "200.2",
            },
            {
                label: "200.5 - Trace Elements in Drinking Water by Axially Viewed Inductively Coupled Plasma-Atomic Emission Spectrometry. Revision 4.2",
                value: "200.5",
            },
            {
                label: "200.7 - Metals and Trace Elements in Water and Wastes by Inductively Coupled Plasma-Atomic Emission Spectrometry. Revision 4.4",
                value: "200.7",
            },
            {
                label: "200.8 - Trace Elements in Water and Wastes by Inductively Coupled Plasma-Mass Spectrometry. Revision 5.4",
                value: "200.8",
            },
            {
                label: "200.9 - Trace Elements by Stabilized Temperature Graphite Furnace Atomic Absorption Spectrometry. Revision 2.2",
                value: "200.9",
            },
            {
                label: "206.5 - Arsenic, Sample Digestion Prior to Total Arsenic Analysis by Silver Diethyldithiocarbamate or Hydride Procedures",
                value: "206.5",
            },
            {
                label: "218.6 - Dissolved Hexavalent Chromium in Drinking Water, Groundwater, and Industrial Wastewater by Ion Chromatography. Revision 3.3",
                value: "218.6",
            },
            {
                label: "231.2 - Gold, Atomic Absorption, Furnace Technique",
                value: "231.2",
            },
            {
                label: "235.2 - Iridium, Atomic Absorption, Furnace Technique",
                value: "235.2",
            },
            {
                label: "245.1 - Mercury in Water by Cold Vapor Atomic Absorption Spectrometry. Revision 3.0",
                value: "245.1",
            },
            {
                label: "245.2 - Mercury, Cold Vapor Technique, Automated",
                value: "245.2",
            },
            {
                label: "245.7 - Mercury in Water by Cold Vapor Atomic Fluorescence Spectrometry. Revision 2.0",
                value: "245.7",
            },
            {
                label: "252.2 - Osmium, Atomic Absorption, Furnace Technique",
                value: "252.2",
            },
            {
                label: "253.2 - Palladium, Atomic Absorption, Furnace Technique",
                value: "253.2",
            },
            {
                label: "255.2 - Platinum, Atomic Absorption, Furnace Technique",
                value: "255.2",
            },
            {
                label: "265.2 - Rhodium, Atomic Absorption, Furnace Technique",
                value: "265.2",
            },
            {
                label: "267.2 - Ruthenium, Atomic Absorption, Furnace Technique",
                value: "267.2",
            },
            {
                label: "279.2 - Thallium, Atomic Absorption, Furnace Technique",
                value: "279.2",
            },
            {
                label: "283.2 - Titanium, Atomic Absorption, Furnace Technique",
                value: "283.2",
            },
            {
                label: "289.2 - Zinc, Atomic Absorption, Furnace Technique",
                value: "289.2",
            },
            {
                label: "300 - Inorganic Anions by Ion Chromatography. Revision 2.1",
                value: "300",
            },
            {
                label: "300.1 - Inorganic Anions in Drinking Water by Ion Chromatography. Revision 1.0",
                value: "300.1",
            },
            {
                label: "310.2 - Alkalinity, Colorimetric, Automated Methyl Orange",
                value: "310.2",
            },
            { label: "335.2", value: "335.2" },
            {
                label: "335.4 - Total Cyanide by Semi-Automated Colorimetry. Revision 1.0",
                value: "335.4",
            },
            { label: "349", value: "349" },
            {
                label: "350.1 - Ammonia Nitrogen by Semi-Automated Colorimetry. Revision 2.0",
                value: "350.1",
            },
            {
                label: "351.1 - Total Kjeldahl Nitrogen (Colorimetric, Automated Phenate) by Autoanalyzer",
                value: "351.1",
            },
            {
                label: "351.2 - Total Kjeldahl Nitrogen by Semi-Automated Colorimetry. Revision 2.0",
                value: "351.2",
            },
            {
                label: "352.1 - Nitrogen, Nitrate (Colorimetric, Brucine) by Spectrophotometer",
                value: "352.1",
            },
            {
                label: "353.2 - Nitrate-Nitrite by Automated Colorimetry. Revision 2.0",
                value: "353.2",
            },
            {
                label: "365.1 - Phosphorus by Semi-Automated Colorimetry. Revision 2.0",
                value: "365.1",
            },
            {
                label: "365.3 - Phosphorus, All Forms, Colorimetric, Ascorbic Acid, Two Reagent",
                value: "365.3",
            },
            {
                label: "365.4 - Phosphorus, Total, Colorimetric, Automated, Block Digester, Automated Analyzer II",
                value: "365.4",
            },
            {
                label: "375.2 - Sulfate by Automated Colorimetry. Revision 2.0",
                value: "375.2",
            },
            {
                label: "410.3 - Chemical Oxygen Demand (Titrimetric, High Level for Saline Waters) by Titration",
                value: "410.3",
            },
            {
                label: "410.4 - Chemical Oxygen Demand by Semi-Automated Colorimetry. Revision 2.0",
                value: "410.4",
            },
            {
                label: "420.1 - Phenolics, Total Recoverable, Spectrophotometric, Manual 4-AAP With Distillation",
                value: "420.1",
            },
            {
                label: "420.4 - Total Recoverable Phenolics by Semi-Automated Colorimetry. Revision 1.0",
                value: "420.4",
            },
            {
                label: "525.1 - Organic Compounds in Drinking Water by Liquids-Solid Extraction and Capillary Column Gas Chromatography/Mass Spectrometry. Revision 2.2",
                value: "525.1",
            },
            {
                label: "525.2 - Organic Compounds in Drinking Water by Liquid-Solid Extraction and Capillary Column Gas Chromatography/Mass Spectrometry. Revision 2.0",
                value: "525.2",
            },
            { label: "600", value: "600" },
            { label: "601 - Purgeable Halocarbons", value: "601" },
            { label: "602 - Purgeable Aromatics", value: "602" },
            { label: "603 - Acrolein and Acrylonitrile", value: "603" },
            { label: "604 - Phenols", value: "604" },
            { label: "605 - Benzidines", value: "605" },
            { label: "606 - Phthalate Ester", value: "606" },
            { label: "607 - Nitrosamines", value: "607" },
            {
                label: "608.1 - Organochlorine Pesticides in Municipal and Industrial Wastewater",
                value: "608.1",
            },
            {
                label: "608.2 - Certain Organochlorine Pesticides in Municipal and Industrial Wastewater",
                value: "608.2",
            },
            {
                label: "608.3 - Organochlorine Pesticides and PCBs by GC/HSD (replaces Method 608)",
                value: "608.3",
            },
            { label: "609 - Nitroaromatics and Isophorone", value: "609" },
            { label: "610 - Polynuclear Aromatic Hydrocarbons", value: "610" },
            { label: "611 - Haloethers", value: "611" },
            { label: "612 - Chlorinated Hydrocarbons", value: "612" },
            {
                label: "613 - 2,3,7,8-Tetrachloro Dibenzo-p-Dioxin",
                value: "613",
            },
            {
                label: "614 - Organophosphorus Pesticides in Municipal and Industrial Wastewater",
                value: "614",
            },
            {
                label: "614.1 - Organophosphorus Pesticides in Municipal and Industrial Wastewater",
                value: "614.1",
            },
            {
                label: "615 - Chlorinated Herbicides in Municipal and Industrial Wastewater",
                value: "615",
            },
            {
                label: "617 - Organohalide Pesticides and PCBs in Municipal and Industrial Wastewater",
                value: "617",
            },
            {
                label: "619 - Triazine Pesticides in Municipal and Industrial Wastewater",
                value: "619",
            },
            {
                label: "622 - Organophosphorus Pesticides in Municipal and Industrial Wastewater",
                value: "622",
            },
            {
                label: "622.1 - Thiophosphate Pesticides in Municipal and Industrial Wastewater",
                value: "622.1",
            },
            {
                label: "624.1 - Purgeables by GC/MS (replaces Method 624)",
                value: "624.1",
            },
            {
                label: "625.1 - Base/Neutrals and Acids by GC/MS (replaces Method 625) • Validation of SPE Products & Associated Procedures with Method 625.1",
                value: "625.1",
            },
            {
                label: "632 - Carbamate and Urea Pesticides in Municipal and Industrial Wastewater",
                value: "632",
            },
            {
                label: "1613B - Tetra- through Octa-Chlorinated Dioxins and Furans by Isotope Dilution HRGC/HRMS. Revision B",
                value: "1613B",
            },
            {
                label: "1624B - Volatile Organic Compounds by Isotope Dilution GC/MS",
                value: "1624B",
            },
            {
                label: "1625B - Semivolatile Organic Compounds by Isotope Dilution GC/MS",
                value: "1625B",
            },
            {
                label: "1627 - Kinetic Test Method for the Prediction of Mine Drainage Quality",
                value: "1627",
            },
            {
                label: "1631E - Mercury in Water by Oxidation, Purge and Trap, and Cold Vapor Atomic Fluorescence Spectrometry. Revision E",
                value: "1631E",
            },
            {
                label: "1664A - n-Hexane Extractable Material (HEM; Oil and Grease) and Silica Gel Treated n-Hexane Extractable Material (SGT-HEM; Non-polar Material) by Extraction and Gravimetry. Revision A",
                value: "1664A",
            },
            {
                label: "1664B - n-Hexane Extractable Material (HEM; Oil and Grease) and Silica Gel Treated n-Hexane Extractable Material (SGT-HEM; Non-polar Material) by Extraction and Gravimetry. Revision B",
                value: "1664B",
            },
            { label: "1669", value: "1669" },
            { label: "1677", value: "1677" },
            { label: "1681", value: "1681" },
            { label: "5210B", value: "5210B" },
            { label: "9030B", value: "9030B" },
            {
                label: "OIA-1677-09 - Available Cyanide by Ligand Exchange and Flow Injection Analysis (FIA)",
                value: "OIA-1677-09",
            },
            { label: "SW846", value: "SW846" },
        ],
        toggle: compliance_toggle,
    },
    {
        id: "active_sampling_hours",
        label: "Active Sampling Interval (Hours)",
        dataType: "numeric",
        toggle: compliance_toggle,
    },
    { id: "notes", label: "Notes", inputType: "textarea" },
];
