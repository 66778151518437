import React from 'react';
import serviceProvider from '../../../images/serviceProviderBlue.svg';
import serviceProviderLarge from '../../../images/serviceProviderBlue.svg';
import cog from '../../../images/cog.svg';
import Table from '../Table';
import TableOptions from '../TableOptions';
import SearchBar from '../SearchBar';
import Flyout from '../Flyout';
import validator from 'validator';
import { getServiceProviderEquipmentReport, getServiceProviderUsersReport } from '../../api/serviceProvidersAPI';
import { parseReportingParams, updateURLWithReportingParams, setReportingParams } from '../../utils/reporting';
import { getOwnUser } from '../../api/usersAPI';
import { updateURL } from '../../utils/url';
import LoaderOverlay from '../LoaderOverlay';

class TestKitDashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			user: null,
			flyoutActive: false,
			needsUpdate: false,
			equipmentInfo: {},
			newEquipment: false,
			equipmentUUID: null,
			reportOutput: null,
			reportingParams: {},
			tableColumns: ['Assigned To', 'Service Provider Name', 'Serial Number', 'Make', 'Model', 'Compliant']
		};

		let urlParts = this.props.location.pathname.split("/");
		if (urlParts.length >= 3 && validator.isUUID(urlParts[2])) {
			this.state.equipmentUUID = urlParts[2];
		}

		if (localStorage.getItem('testkit dashboard options')) {
			let options = JSON.parse(localStorage.getItem('testkit dashboard options'));
			if (options.columns) {
				this.state.tableColumns = options.columns;
			}
		}

		this.state.reportingParams = parseReportingParams();
	}

	setTableOptions = (options) => {
		// for now the only options are the cols to show
		this.setState({ ...this.state, tableColumns: options.columns });
		localStorage.setItem('testkit dashboard options', JSON.stringify(options));
	}

	async componentDidMount() {
		getOwnUser().then(user => {
			this.setState({ user });
			this.updateEquipmentList(true);
		});
	}

	// -----------------------------
	// table / reporting functions
	// -----------------------------
	setTableOptions = (options) => {
		// for now the only options are the cols to show
		this.setState({ ...this.state, tableColumns: options.columns });
		localStorage.setItem('testkit dashboard options', JSON.stringify(options));
	}

	setTableFilters = (filters) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { filters });
		this.updateEquipmentList(true, newReportingParams);
	}

	setOrdering = (column) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { column });
		this.updateEquipmentList(true, newReportingParams);
	}

	fetchData = (direction) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { direction });
		if (newReportingParams !== undefined) {
			this.updateEquipmentList(true, newReportingParams);
		}
	}
	// END table / reporting functions

	openFlyout = async (equipmentInfo, newEquipment) => {
		const overlay = document.getElementById('overlay');

		if (newEquipment) {
			this.setState({
				newEquipment: true,
				equipmentInfo: {}
			});
		} else {
			if (equipmentInfo.values) {
				equipmentInfo = {
					equipment_uuid: equipmentInfo.values[0]
				};
			}
			this.setState({
				newEquipment: false,
				equipmentInfo
			});
			// update the url in the browser without causing any reloads
			updateURL('/serviceProvidersEquipment/' + equipmentInfo.equipment_uuid, {});
		}
		this.setState(() => ({ flyoutActive: true }), () => {
			overlay.classList.add('overlay__visible');
		});
	}

	closeFlyout = () => {
		const flyout = document.getElementById('flyout');
		const overlay = document.getElementById('overlay');

		if (this.state.needsUpdate) {
			this.updateEquipmentList(true);
		}

		flyout.classList.remove('slideToVisible');
		overlay.classList.remove('overlay__visible');

		setTimeout(() => {
			this.setState(() => ({
				flyoutActive: false,
				equipmentInfo: {},
				equipmentUUID: null
			}));
		}, 200);

		updateURLWithReportingParams(this.state.reportingParams, '/serviceProvidersEquipment');
	}

	updateEquipmentList = async (immediate, newReportingParams) => {
		if (immediate === undefined) {
			immediate = true;
		}

		if (immediate) {
			let reportingParams = (newReportingParams !== undefined) ? newReportingParams : this.state.reportingParams;

			getServiceProviderEquipmentReport(undefined, reportingParams).then(response => {
				if (response.nextPage !== undefined && response.nextPage > this.state.reportingParams.page) {
					reportingParams.nextPage = response.nextPage;
				}
				this.setState(() => ({ reportOutput: response.data, reportingParams }));
			});

			if (this.state.equipmentUUID !== null) {
				this.openFlyout({ "equipment_uuid": this.state.equipmentUUID });
			}

		} else {
			this.setState({ needsUpdate: true });
		}
	}

	getAssignToOptions = () => {
		getServiceProviderUsers();
		return [];
	}

	getServiceProviderUsers = async (serviceProviderUUID) => {
		let reportOutput = await getServiceProviderUsersReport(serviceProviderUUID);
		return null;
	}

	render() {
		const { flyoutActive, equipmentInfo, newEquipment, reportOutput } = this.state;
		return (
			<div className="componentContainer">
				<div className="componentHeader">
					<img src={serviceProvider} alt='' />
					<h3>Test Kits</h3>
				</div>
				<div className="tableButtonBankContainer">
					<div className="flexAlignCenter">
						<button onClick={() => this.openFlyout(null, true)} className="longButtonPrimary">Add Test Kit</button>
						{reportOutput !== null && (
							<React.Fragment>
								<SearchBar
									data={reportOutput}
									params={this.state.reportingParams}
									setTableFilters={this.setTableFilters}
									defaultSearch="assigned to"
								/>
								<button className="xsmallButtonSecondary flexJustifyCenter" onClick={() => { this._optionsModal.handleOpen(); }}>
									<img src={cog} alt='' />
								</button>
							</React.Fragment>
						)}
					</div>
				</div>
				{reportOutput !== null ? (
					<div className="mainTableContainer">
						<Table
							flyoutActive={flyoutActive}
							openFlyout={this.openFlyout}
							tableType='Test Kits'
							tableData={reportOutput}
							fetchData={this.fetchData}
							paginationParams={this.state.reportingParams}
							columnsToShow={this.state.tableColumns}
							setOrdering={this.setOrdering}
						/>
						<TableOptions
							ref={(modal) => { this._optionsModal = modal; }}
							outputs={reportOutput.outputs}
							defaultColumns={this.state.tableColumns}
							setTableOptions={this.setTableOptions}
						/>
					</div>
				) : <LoaderOverlay />}
				<img className="dashboardIconLarge dashboardIconLarge__adjusted" src={serviceProviderLarge} alt='' />
				<div className="overlay" id="overlay" onClick={() => { this.closeFlyout() }}>
				</div>
				{flyoutActive && (
					<React.Fragment>
						<Flyout
							uuid={equipmentInfo.equipment_uuid}
							newAddition={newEquipment}

							flyoutType='testKits'
							flyoutPriority='primary'
							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							flyoutActive={flyoutActive}
							activeTab='testKitDetails'

							refresh={this.updateEquipmentList}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default TestKitDashboard;