/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';
import editModeIcon from '../../../images/editMode.svg';
import medal from '../../../images/medalLarge.svg';
import warning from '../../../images/warningLarge.svg';
import checkGreen from '../../../images/checkGreen.svg';
import MessageBox from '../MessageBox';
import DatePicker from '../DatePicker';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getCertificationUploads } from '../../api/usersAPI';
import { getOrganizationSetting } from '../../api/organizationAPI';
import { getUpload } from '../../api/uploadsAPI';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { acceptedFiletypes } from '../../utils/settings';

const SUPPORTED_FORMATS = ['image/png', 'image/jpg', 'application/pdf', 'image/jpeg'];
const FILE_SIZE = 1000000;

const FormLayout = ({
	errors,
	touched,
	resetForm,
	isSubmitting,
	deleteCertification,
	data,
	newCertification,
	toggleNewCertification,
	setFieldValue,
	messageOptions,
	values,
	saveCertification,
	certifyingAgencyToType
}) => {
	const [editCertificationMode, setEditCertificationMode] = useState(false);
	const [uploads, setUploads] = useState([]);

	useEffect(() => {
		if (!newCertification) {
			let useAdminUrl = false;
			if (SwiftComply.user.user_uuid !== data.user_uuid) {
				useAdminUrl = true;
			}
			getCertificationUploads(data.user_certification_uuid, useAdminUrl).then((response) => {
				if (response && response.uploads.length >= 1) {
					// the backend now orders the user cert uploads by last mod desc, so now the most recent, which in 
					// theory should be their most recent cert, will be all we need to make a link for
					const imageLink = document.getElementById(`imageLink${response.uploads[0].user_certification_uuid}`);
					imageLink.href = '/uploads/' + response.uploads[0].upload_uuid;
				}
			});
		}
	}, [data]);

	const onSubmit = () => {
		setEditCertificationMode(false)
		if (!newCertification) {
			saveCertification(values, data.user_certification_uuid).catch(error => {
				if (error.name === 'APIUserError') {
					setErrors(convertApiErrors(error.apiFieldErrors));
				}
			});
		} else {
			saveCertification(values).catch(error => {
				if (error.name === 'APIUserError') {
					setErrors(convertApiErrors(error.apiFieldErrors));
				}
			});
		}
	}

	const getError = (fieldname) => {
		if (touched[fieldname] && errors[fieldname]) {
			return errors[fieldname];
		}
	};

	const handleFormChange = (e) => {
		// this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
		// might be set so we can check it again
		if (errors && errors[e.target.name]) {
			delete errors[e.target.name];
		}
	};

	const getFormErrors = () => {
		let errorList = [];
		messageOptions = {};
		['certification_number', 'certifying_agency', 'expiration_date', 'certification_type', 'file'].forEach((field) => {
			let err = getError(field);
			if (err) {
				errorList.push(err);
			}
		});

		if (errorList.length !== 0) {
			messageOptions = {
				type: "error",
				message: "Please correct the following errors",
				list: errorList,
			};
			document.getElementById('certificationForm').scrollIntoView();
		}

		return messageOptions;
	};

	const cancelAction = () => {
		if (newCertification) {
			setEditCertificationMode(false);
			resetForm();
			toggleNewCertification(false);
		} else {
			setEditCertificationMode(!editCertificationMode);
			resetForm();
		}
	};

	const toggleEditCertificationMode = () => {
		setEditCertificationMode(!editCertificationMode);
	};

	let editMode;
	if (newCertification || editCertificationMode) {
		editMode = true;
	}

	let certifications;
	let status = true;
	if (!newCertification) {
		status = new Date() < new Date(0).setUTCSeconds(data.expiration_date);
	}

	const handleCertifyingAgencyChange = (value) => {
		if (editMode) {
			setFieldValue("certification_type", "");
		}
		let options = [<option key="-1" value=""></option>];
		let certType = certifyingAgencyToType[value];
		if (certType !== undefined) {
			certType.forEach((v) => {
				options.push(<option key={v} value={v}>{v}</option>);
			})
		}
		return options;
	};

	const [certTypeOptions, setCertTypeOptions] = useState(() => {
		return handleCertifyingAgencyChange(data.certifying_agency);
	});

	const handleFileChange = (e) => {
		setFieldValue('file', e.target.files[0]);

		const imagePreview = document.getElementById(`filePreview${data.user_certification_uuid}`);
		const imagePreviewLink = document.getElementById(`filePreviewLink${data.user_certification_uuid}`);
		imagePreview.src = window.URL.createObjectURL(e.target.files[0]);
		imagePreviewLink.href = window.URL.createObjectURL(e.target.files[0]);

		imagePreview.classList.add('showFilePreview');
	};

	const getCertifyingAgencyOptions = () => {
		let optionsList = [];

		Object.keys(certifyingAgencyToType).sort().forEach((agency, idx) => {
			optionsList.push(<option value={agency} key={agency}>{agency}</option>);
		})

		return optionsList;
	};

	certifications = (
		<React.Fragment>
			<MessageBox options={getFormErrors()} />
			{!newCertification ?
				(
					<span className="cert__header">
						{data.certification_type + `    `}&nbsp;&nbsp;
						<span className={status ? 'valid underline' : 'expired underline'}>
							{status ? 'VALID' : 'EXPIRED'}
						</span>
					</span>
				) : (<span className="cert__header">New Certification</span>)
			}
			<div className={editMode ? 'card editCard' : 'card'} id="certificationForm">
				<div className="card__container">
					<div className={editMode ? 'card__field editCard__field' : 'card__field'}>
						<div className={'inputField ' + ((getError('certification_number')) ? 'formRed' : '')}>
							<label htmlFor="certification_number">Certification Number *</label>
							<Field type="text" name='certification_number' autoComplete="off" disabled={!editMode} className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
					</div>
					<div className={editMode ? 'card__field editCard__field' : 'card__field'}>
						<div className={'inputField ' + ((getError('certifying_agency')) ? 'formRed' : '')}>
							<label htmlFor="certifying_agency">Certifying Agency *</label>
							<Field
								disabled={!newCertification}
								component="select"
								name="certifying_agency"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); setCertTypeOptions(handleCertifyingAgencyChange(e.target.value)); }}
							>
								<option value=""></option>
								{getCertifyingAgencyOptions()}
							</Field>
						</div>
					</div>
					{!editMode && <div className="card__field-blank"></div>}
					<a target="_blank" id={`imageLink${data.user_certification_uuid}`} style={editMode && { display: 'none ' }}>
						<span className="file">
							<ImageSearchIcon id={`image${data.user_certification_uuid}`} fontSize="inherit" />
						</span>
					</a>
					<div className={editMode ? 'card__field editCard__field' : 'card__field'}>
						<div className={'inputField ' + ((getError('expiration_date')) ? 'formRed' : '')}>
							<label htmlFor="expiration_date">Expiration Date *</label>
							<Field
								name="expiration_date"
								component={DatePicker}
								valueType="epoch"
								disabled={true}
								editMode={editMode}
								values={values}
								setFieldValue={setFieldValue}
							/>
							{!newCertification && status && <img className="validCheck" src={checkGreen} alt='' />}
						</div>
					</div>
					<div className={editMode ? 'card__field editCard__field' : 'card__field'}>
						<div className={'inputField ' + ((getError('certification_type')) ? 'formRed' : '')}>
							<label htmlFor="certification_type">Certification Type *</label>
							{editMode && (
								<Field
									disabled={!newCertification}
									component="select"
									name="certification_type"
									onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
								>
									{certTypeOptions}
								</Field>
							)}
							{!editMode && (
								<Field type="text" name='certification_type' autoComplete="off" disabled={true} className="inputField__input inputField__input-first" />
							)}
						</div>
					</div>
					{!editMode && <div className="card__field-blank"></div>}
					<div className={editMode ? 'card__field editCard__field' : 'card__field'} id="fileSelect" style={!editMode ? { display: 'none' } : { display: 'inherit' }}>
						<div className={'inputField ' + ((getError('file')) ? 'formRed' : '')}>
							<div className="certUploadAndDisplay">
								<div>
									<label htmlFor="file">Certificate Image *</label>
									<input type="file" name="file" accept={acceptedFiletypes.join()} onChange={(e) => handleFileChange(e)} />
									<span>{acceptedFiletypes.join(', ')}</span>
								</div>
								<div>
									<div>
										<span>Current Certificate Image</span>
										<a target="_blank" id={`filePreviewLink${data.user_certification_uuid}`}>
											<img className="filePreview" id={`filePreview${data.user_certification_uuid}`} alt="" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					!newCertification && !editMode && (
						<div>
							<img className={status ? 'card__validIcon' : 'card__expIcon'} src={status ? medal : warning} alt='' />
						</div>
					)
				}
				{
					!newCertification && !editCertificationMode && (
						<button className="editCertificationButton" type="button" onClick={() => toggleEditCertificationMode()}>
							<img src={editModeIcon} alt='' />
						</button>
					)
				}
				{!newCertification && <button className="xsmallButtonTertiary" type="button" onClick={() => deleteCertification(data.user_certification_uuid)}><DeleteForeverIcon fontSize='large' /></button>}
			</div>
			{
				editMode &&
				<div className="certificationButtonBank flexButtonContainer">
					<button className="medButtonPrimary" disabled={isSubmitting} onClick={() => onSubmit()} type="submit">{newCertification ? 'Save' : 'Update'}</button>
					<button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
				</div>
			}
		</React.Fragment>
	);

	return (
		<Form>
			{certifications}
		</Form>
	);
};

// the apiFieldErrors are already in a format that works name -> error message however we want to remove the API
// field names for a UI one
const convertApiErrors = (apiFieldErrors) => {
	const fieldTranslation = {
		certification_number: 'Certification Number',
		certifying_agency: 'Certifying Agency',
		expiration_date: 'Expiration Date',
		certification_type: 'Certification Type'
	};

	let errors = {};
	for (let field in apiFieldErrors) {
		let error = apiFieldErrors[field].replace(field, fieldTranslation[field]);
		errors[field] = error;
	}
	return errors;
};

const CertificationsForm = withFormik({
	validateOnChange: false,
	validateOnBlur: false,
	enableReinitialize: true,
	mapPropsToValues(props) {
		return {
			file: null,
			certification_number: (props.data.certification_number) ? props.data.certification_number : '',
			certifying_agency: (props.data.certifying_agency) ? props.data.certifying_agency : '',
			certification_type: (props.data.certification_type) ? props.data.certification_type : '',
			expiration_date: (props.data.expiration_date) ? props.data.expiration_date : null
		};
	},

	validationSchema: Yup.object().shape({
		certification_number: Yup.string()
			.required('certification number is required')
		,
		certifying_agency: Yup.string()
			.required('certifying agency is required')
		,
		certification_type: Yup.string()
			.required('certification type is required')
		,
		expiration_date: Yup.mixed()
			.required('expiration date is required')
		,
		file: Yup.mixed()
			.required('An image of the certification is required')
			.test('fileType', 'Unsupported file format', value => { if (value) { SUPPORTED_FORMATS.includes(value.type); } return true; })
			.test('fileSize', 'File size is too large', value => { if (value) { value.size <= FILE_SIZE; } return true; })
		,
	}),



	handleSubmit(values, { props, resetForm, setSubmitting, setErrors }) {
		setSubmitting(true);
		setTimeout(() => {
			setSubmitting(false);
		}, 3000);
	}
})(FormLayout);

export default CertificationsForm;
