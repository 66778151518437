import axios from "axios";

export const createReferencePoint = (payload) => {
    const url = "/api/v1/referencepoints";

    payload.type = "Outfall";

    return axios
        .post(url, payload)
        .then((response) => {
            console.log("Created Reference Point!", response.data);
            return response.data;
        })
        .catch((error) => {
            console.log("Could not create Reference Point", error.response.data);
            throw error;
        });
};

export const getPropertyReferencePointsReport = (payload) => {
    const url = "/api/v1/reporting/9c68e84f-22ec-4cd1-9e02-db4268b38d2c";

    return axios
        .post(url, payload)
        .then((response) => {
            console.log("Got Property Reference Points Report!", response.data);
            return { data: response.data, nextPage: response.headers["x-scc3-reporting-next-page"] };
        })
        .catch((error) => {
            console.log("Couldn't get Property Reference Points Report!", error.response.data);
            throw error;
        });
};

export const getReferencePoint = (referencePointUUID) => {
    const url = `/api/v1/referencepoints/${referencePointUUID}`;

    return axios.get(url).then((response) => response.data);
};

export const updateReferencePoint = (referencePointUUID, payload) => {
    const url = `/api/v1/referencepoints/${referencePointUUID}`;

    return axios
        .put(url, payload)
        .then((response) => {
            console.log("Updated Reference Point!", response);
            return response.data;
        })
        .catch((error) => {
            console.log("Couldn't update Reference Point!", error.response.data);
            throw error;
        });
};

export const getReferencePointDetails = (referencePointUUID) => {
    const url = `/api/v1/referencepoints/${referencePointUUID}/details`;

    return axios
        .get(url)
        .then((response) => {
            console.log("Got Reference Point Details!", response);
            return response.data;
        })
        .catch((error) => {
            console.log("Couldn't Get Reference Point Details!", error.response.data);
            throw error;
        });
};

export const saveReferencePointDetail = (referencePointUUID, payload) => {
    const url = `/api/v1/referencepoints/${referencePointUUID}/details`;

    return axios
        .post(url, payload)
        .then((response) => {
            console.log("Saved Reference Point Detail!", response);
            return response.data;
        })
        .catch((error) => {
            console.log("Couldn't Save Reference Point Detail!", error.response.data);
            throw error;
        });
};

export const deleteReferencePoint = (referencePointUUID) => {
    const url = `/api/v1/referencepoints/${referencePointUUID}`;

    return axios
        .delete(url)
        .then((response) => {
            console.log("Deleted Reference Point!", response);
            return response.data;
        })
        .catch((error) => {
            console.log("Couldn't delete Reference Point!", error.response.data);
            throw error;
        });
};

export const getReferencePointsLimits = (referencePointUUID) => {
    const url = `/api/v1/referencepoints/${referencePointUUID}/limits`;

    return axios.get(url).then((response) => response.data);
};

export const getLimit = (referencePointUUID, limitUUID) => {
    const url = `/api/v1/referencepoints/${referencePointUUID}/limits/${limitUUID}`;

    return axios.get(url).then((response) => response.data);
};

export const updateLimit = (referencePointUUID, limitUUID, payload) => {
    const url = `/api/v1/referencepoints/${referencePointUUID}/limits/${limitUUID}`;

    return axios
        .put(url, payload)
        .then((response) => {
            console.log("Updated Limit!", response);
            return response.data;
        })
        .catch((error) => {
            console.log("Couldn't update Limit!", error.response.data);
            throw error;
        });
};

export const deleteLimit = (referencePointUUID, limitUUID) => {
    const url = `/api/v1/referencepoints/${referencePointUUID}/limits/${limitUUID}`;

    return axios
        .delete(url)
        .then((response) => {
            console.log("Deleted Limit!", response);
            return response.data;
        })
        .catch((error) => {
            console.log("Couldn't delete Limit!", error.response.data);
            throw error; // Updated to throw error for consistent error handling
        });
};

export const createLimit = (referencePointUUID, payload) => {
    const url = `/api/v1/referencepoints/${referencePointUUID}/limits`;

    return axios
        .post(url, payload)
        .then((response) => {
            console.log("Saved Limit!", response);
            return response.data;
        })
        .catch((error) => {
            console.log("Couldn't Save Limit!", error.response.data);
            throw error;
        });
};

export const getReferencePointSampleReportConfigs = (referencePointUUID) => {
    const url = `/api/v1/referencepoints/${referencePointUUID}/samplereportConfigs`;

    return axios
        .get(url)
        .then((response) => {
            console.log("Got Reference Point Sampling Reports!", response);
            return response.data;
        })
        .catch((error) => {
            console.log("Couldn't Get Reference Point Sampling Reports!", error.response.data);
            throw error;
        });
};
