/**
 * Converts form state to permit data structure required by the API.
 *
 * @param {Object} formState - The current state of the form
 * @param {Object} templateData - The template data for the permit
 * @param {Object} tableSelections - The current selections in the table
 * @param {Object} recurrenceData - The recurrence data to be associated with the permit
 * @returns {Object} The converted permit data ready for API submission
 */
export const convertFormStateToPermitData = (formState, templateData, tableSelections, recurrenceData) => {
    // Initialize the converted data object with existing template data
    const convertedData = {
        legal_control_type: formState.legal_control_type || "permit",
        template_uuid: formState.template_uuid,
        template_data: {
            data_entry: (templateData?.data_entry || []).map((entry) => ({
                name: entry.name,
                label: entry.label,
                template_string: entry.template_string,
                type: entry.type,
                value: formState[entry.template_string] || "",
            })),
            document_fields: (templateData?.document_fields || []).map((field) => ({
                name: field.name,
                label: field.label,
                template_string: field.template_string,
                type: field.type,
                value: formState[field.template_string] || "",
            })),
            system_fields: Object.fromEntries(
                Object.entries(templateData?.system_fields || {}).map(([key, fields]) => [
                    key,
                    (fields || []).map((field) => ({
                        name: field.name,
                        label: field.label,
                        template_string: field.template_string,
                        type: field.type,
                        value: formState[field.template_string] || "",
                    })),
                ])
            ),
            system_uuids: {
                property_uuid: formState.industrial_user,
            },
            form_data: {
                ...formState,
                tableSelections: tableSelections,
            },
            reports: [],
            association_data: {
                sample_report_with_sampling_config_iu: filterRecurrenceDataForSelectedRows(
                    formState,
                    templateData,
                    tableSelections,
                    recurrenceData
                ),
            },
        },
    };

    // Map template strings to reports for quick lookup
    const reportsByTemplateString = Object.fromEntries(
        templateData.reports.map((report) => [report.template_string, report])
    );

    // Get reports ordered by category_index from inputs_order
    const orderedReports = (templateData.inputs_order || [])
        .filter((input) => input.category === "reports")
        .map((input) => {
            const report = reportsByTemplateString[input.template_string];
            if (report) {
                return {
                    ...report,
                    categoryIndex: input.category_index,
                };
            }
            return null;
        })
        .filter(Boolean);

    // Now proceed to process orderedReports
    convertedData.template_data.reports = orderedReports.map((report) => {
        // Create a unique key for each table instance using the report key and category index
        const uniqueKey = `${report.key}_${report.categoryIndex}`;
        const reportState = formState[report.report_uuid]?.[uniqueKey];
        const reportRows = reportState?.rows || [];
        const isTableTypePivot = report.table_type === "pivot-table";
        let inputs = null;

        if (isTableTypePivot) {
            // For pivot tables, filter out selected rows and map the remaining ones to inputs
            inputs = reportRows
                .filter((_, index) => !tableSelections[uniqueKey]?.[index])
                .map((row) => {
                    const sampleReportConfigIdIndex = reportState?.outputs?.findIndex(
                        (output) => output.name === "Sample Report Config ID"
                    );
                    const sampleReportConfigID = row.values[sampleReportConfigIdIndex];
                    return {
                        "Property UUID": formState.industrial_user,
                        "Sample Report Config ID": sampleReportConfigID,
                        "Assigned To": "Industry",
                    };
                });
        } else {
            // For non-pivot tables, construct inputs based on required input keys
            inputs = {};
            report.required_input_keys?.forEach((key) => {
                if (key === "Property UUID") {
                    inputs[key] = formState.industrial_user;
                } else if (key === "Effective Date") {
                    inputs[key] = formState["EffectiveDate"];
                } else if (key === "Expiration Date") {
                    inputs[key] = formState["ExpirationDate"];
                } else {
                    inputs[key] = formState[key] || "";
                }
            });
            inputs = [{ ...inputs, ...report.input_baseline }];
        }

        let activeRows = null;

        if (!isTableTypePivot) {
            // Get the selections for this specific table instance
            const selections = tableSelections[uniqueKey] || {};
            // Check if any rows are selected (true in tableSelections)
            const hasAnySelection = Object.values(selections).some((value) => value === true);

            if (!hasAnySelection) {
                // If no rows are selected, include all rows in activeRows
                activeRows = reportRows.map((row) => {
                    if (report.row_key_label) {
                        // If row_key_label exists, map only the specified columns
                        return report.row_key_label.map((key) => {
                            const keyIndex = reportState?.outputs?.findIndex((output) => output.name === key);
                            return row.values[keyIndex];
                        });
                    }
                    return row.values;
                });
            } else {
                // If some rows are selected, include only unselected rows in activeRows
                activeRows = reportRows
                    .filter((_, index) => !selections[index]) // Filter out selected rows
                    .map((row) => {
                        if (report.row_key_label) {
                            // Map specified columns for unselected rows
                            return report.row_key_label.map((key) => {
                                const keyIndex = reportState?.outputs?.findIndex((output) => output.name === key);
                                return row.values[keyIndex];
                            });
                        }
                        // Use all values for unselected rows
                        return row.values;
                    });
            }
        }
        // Note: activeRows remains null for pivot tables as they don't use this property

        const reportRecurrenceData = {};
        reportRows.forEach((row, index) => {
            if (!tableSelections[uniqueKey]?.[index]) {
                const sampleReportConfigIdIndex = reportState?.outputs?.findIndex(
                    (output) => output.name === "Sample Report Config ID"
                );
                const sampleReportConfigID = row.values[sampleReportConfigIdIndex];
                if (recurrenceData[sampleReportConfigID]) {
                    reportRecurrenceData[sampleReportConfigID] = recurrenceData[sampleReportConfigID];
                }
            }
        });
        if (Object.keys(reportRecurrenceData).length > 0) {
            convertedData.template_data.association_data[report.key] = {
                ...convertedData.template_data.association_data[report.key],
                ...reportRecurrenceData,
            };
        }

        return {
            report_uuid: report.report_uuid,
            label: report.label,
            template_string: report.template_string,
            key: report.key,
            active_rows: activeRows,
            inputs: inputs || report.inputs,
            visible_columns: report.visible_columns || [],
            table_type: report.table_type,
            row_key_label: report.row_key_label,
            required_input_keys: report.required_input_keys,
        };
    });

    return convertedData;
};

/**
 * Filters recurrence data to only include data for currently selected rows.
 *
 * @param {Object} formState - The current form state
 * @param {Object} templateData - The template data containing report definitions
 * @param {Object} tableSelections - The current table selections
 * @param {Object} recurrenceData - The recurrence data to filter
 * @returns {Object} Filtered recurrence data containing only selected rows
 */
export const filterRecurrenceDataForSelectedRows = (formState, templateData, tableSelections, recurrenceData) => {
    const selectedConfigIds = new Set();

    // Map template strings to reports for quick lookup
    const reportsByTemplateString = Object.fromEntries(
        templateData.reports.map((report) => [report.template_string, report])
    );

    // Get all pivot table reports with their categoryIndex
    const pivotReports = (templateData.inputs_order || [])
        .filter((input) => input.category === "reports")
        .map((input) => {
            const report = reportsByTemplateString[input.template_string];
            if (report && report.table_type === "pivot-table") {
                return {
                    ...report,
                    categoryIndex: input.category_index,
                };
            }
            return null;
        })
        .filter(Boolean);

    // Process each pivot report
    pivotReports.forEach((report) => {
        const uniqueKey = `${report.key}_${report.categoryIndex}`;
        const reportState = formState[report.report_uuid]?.[uniqueKey];
        const reportRows = reportState?.rows || [];
        const selections = tableSelections[uniqueKey] || {};

        // Find the index of "Sample Report Config ID" in outputs
        const sampleReportConfigIdIndex = reportState?.outputs?.findIndex(
            (output) => output.name === "Sample Report Config ID"
        );

        // Add selected Sample Report Config IDs to the set
        reportRows.forEach((row, index) => {
            if (!selections[index]) {
                // If row is selected (not true in tableSelections)
                const configId = String(row.values[sampleReportConfigIdIndex]);
                selectedConfigIds.add(configId);
            }
        });
    });

    // Filter recurrenceData to only include selected config IDs
    return Object.entries(recurrenceData).reduce((acc, [configId, data]) => {
        if (selectedConfigIds.has(configId)) {
            acc[configId] = data;
        }
        return acc;
    }, {});
};

/**
 * Constructs initial table selections based on template data and form state.
 *
 * @param {Object} templateData - The template data for the permit
 * @param {Object} initialFormState - The initial state of the form
 * @returns {Object} An object representing the initial table selections
 */
export const constructTableSelections = (templateData, initialFormState) => {
    const tableSelections = {};

    // Get reports ordered by category_index to match the display order
    const orderedReports = (templateData?.inputs_order || [])
        .filter((input) => input.category === "reports")
        .map((input, index) => {
            const report = templateData?.reports?.find((r) => r.template_string === input.template_string);
            return {
                ...report,
                categoryIndex: input.category_index,
            };
        });

    // Process each report with its category index
    orderedReports.forEach((report) => {
        const uniqueKey = `${report.key}_${report.categoryIndex}`; // Create unique key for each instance
        const reportState = initialFormState[report.report_uuid]?.[report.key];
        const reportRows = reportState?.rows || [];

        // Check for saved selections with the unique key
        const savedSelections = initialFormState.tableSelections?.[uniqueKey];
        if (savedSelections) {
            tableSelections[uniqueKey] = savedSelections;
        } else {
            tableSelections[uniqueKey] = {};

            if (report.table_type === "pivot-table") {
                const inputs = report.inputs || [];
                const sampleReportConfigIDs = inputs.map((input) => input["Sample Report Config ID"]);
                const sampleReportConfigIdIndex = reportState?.outputs?.findIndex(
                    (output) => output.name === "Sample Report Config ID"
                );

                reportRows.forEach((row, index) => {
                    const rowSampleReportConfigUUID = row.values[sampleReportConfigIdIndex];
                    tableSelections[uniqueKey][index] = sampleReportConfigIDs.includes(rowSampleReportConfigUUID)
                        ? null
                        : true;
                });
            } else if (report.active_rows && report.row_key_label) {
                const activeRows = report.active_rows || [];
                const rowKeyLabels = report.row_key_label;

                reportRows.forEach((row, index) => {
                    const rowUUIDs = rowKeyLabels.map((label) => {
                        const labelIndex = reportState?.outputs?.findIndex((output) => output.name === label);
                        return row.values[labelIndex];
                    });

                    const isActiveRow = activeRows.some((activeRow) =>
                        rowUUIDs.every((uuid, index) => activeRow[index] === uuid)
                    );

                    tableSelections[uniqueKey][index] = isActiveRow ? null : true;
                });
            }
        }
    });

    return tableSelections;
};

export const constructRecurrenceData = (templateData) => {
    const recurrenceData = {};

    if (templateData?.association_data?.sample_report_with_sampling_config_iu) {
        const sampleReportData = templateData.association_data.sample_report_with_sampling_config_iu;

        Object.entries(sampleReportData).forEach(([sampleReportConfigID, scheduleData]) => {
            const sampleReportConfigIDStr = String(sampleReportConfigID);

            if (scheduleData.recurrence_config) {
                recurrenceData[sampleReportConfigIDStr] = {
                    recurrence_config: scheduleData.recurrence_config,
                };
            } else if (scheduleData.ad_hoc_dates && scheduleData.ad_hoc_dates.length > 0) {
                recurrenceData[sampleReportConfigIDStr] = {
                    ad_hoc_dates: scheduleData.ad_hoc_dates,
                };
            }
        });
    }

    return recurrenceData;
};

/**
 * Handles and displays errors with a default message.
 *
 * @param {Error} error - The error object to handle
 * @param {string} defaultMessage - The default message to display if no specific error message is available
 */
export const handleError = (error, defaultMessage) => {
    if (error) {
        const message = defaultMessage + (error.response?.data?.Error || error.message || "An error occurred");
        const capitalizedMessage = message.charAt(0).toUpperCase() + message.slice(1);
        return {
            message: capitalizedMessage,
            severity: "error",
            duration: 5000,
        };
    }
    return null;
};

/**
 * Converts document fields from camelCase to snake_case and extracts their values.
 *
 * @param {Object} convertedFormState - The converted form state containing template data
 * @returns {Object} An object with snake_case keys and corresponding field values
 */
export const convertDocumentFieldsToSnakeCase = (convertedFormState) => {
    return convertedFormState.template_data.document_fields.reduce((acc, td) => {
        const snake = td.name
            .split(/(?=[A-Z])/)
            .map((s) => s.toLowerCase())
            .join("_");

        // Exclude effective_date and expiration_date
        if (snake !== "effective_date" && snake !== "expiration_date") {
            acc[snake] = td.value || null;
        }
        return acc;
    }, {});
};

/**
 * Maps field types to corresponding input types for form rendering.
 *
 * This helper function takes a field type and returns an object with the appropriate
 * input type and additional properties needed for rendering the form field correctly.
 *
 * @param {string} type - The type of the field to be mapped
 * @returns {Object} An object containing the inputType and additional properties if needed
 */
export const mapInputType = (type) => {
    switch (type) {
        case "string":
            return { inputType: "input" };
        case "textarea":
            return { inputType: "textarea" };
        case "numeric":
            return { inputType: "input", dataType: "numeric" };
        case "checkbox":
            return { inputType: "checkbox" };
        case "date":
            return { inputType: "date" };
        default:
            return { inputType: "input" };
    }
};
