/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withFormik, Form, Field } from 'formik';
import MessageBox from '../MessageBox';
import * as Yup from 'yup';

const FormLayout = ({
	errors,
	touched,
	resetForm,
	updateUserData,
	updateUserPassword,
	values,
	setFieldValue
}) => {
	const [apiErrors, setApiErrors] = useState({});

	const [detailsMessageOptions, setDetailsMessageOptions] = useState({});
	const [pwMessageOptions, setPwMessageOptions] = useState({});

	const cancelAction = () => {
		resetForm();
		setApiErrors({});
		setDetailsMessageOptions({});
		setPwMessageOptions({});
	};

	const submitAction = (type) => {
		if (type === 'details') {
			updateUserData(values).then(() => {
				setDetailsMessageOptions({
					type: "success",
					message: "Your changes have been saved"
				});
				setTimeout(() => {
					setDetailsMessageOptions({});
				}, 2000)
			}).catch(error => {
				if (error.name === 'APIUserError') {
					setApiErrors(error.apiFieldErrors);
					setDetailsMessageOptions({
						type: "error",
						message: "Please correct the following errors",
						list: Object.values(error.apiFieldErrors)
					});
					setDetailsMessageList(Object.values(error.apiFieldErrors));
				}
			});
		} else if (type === 'password') {
			if (values.new_password !== values.confirm_new_password) {
				// set both because we ignore it if there is no message
				setApiErrors({
					new_password: "New passwords do not match",
					confirm_new_password: "New passwords do not match"
				});
				setPwMessageOptions({
					type: "error",
					message: "Please correct the following errors",
					list: ["New passwords do not match"]
				});
				return;
			} else if (values.current_password === values.new_password) {
				// set both because we ignore it if there is no message
				setApiErrors({
					new_password: "New password can not be the same as current password",
					confirm_new_password: "New password can not be the same as current password"
				});
				setPwMessageOptions({
					type: "error",
					message: "Please correct the following errors",
					list: ["New password can not be the same as current password"]
				});
				return;
			}

			const passwordData = {
				current_password: values.current_password,
				new_password: values.new_password
			};
			updateUserPassword(passwordData).then(() => {
				resetForm();
				setPwMessageOptions({
					type: "success",
					message: "Your password has been updated"
				});
				setTimeout(() => {
					setPwMessageOptions({});
				}, 2000)
			}).catch(error => {
				if (error.name === 'APIUserError') {
					setApiErrors(error.apiFieldErrors);
					setPwMessageOptions({
						type: "error",
						message: "Please correct the following errors",
						list: Object.values(error.apiFieldErrors)
					});
				}
			});
		}
	};

	const getError = (fieldname) => {
		if (apiErrors && apiErrors[fieldname]) {
			return apiErrors[fieldname];
		} else if (touched[fieldname] && errors[fieldname]) {
			return errors[fieldname];
		}
	}

	const handleFormChange = (e) => {
		// this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
		// might be set so we can check it again
		if (apiErrors && apiErrors[e.target.name]) {
			delete apiErrors[e.target.name];
		}
		if (e.target.name === "new_password" || e.target.name === "confirm_new_password") {
			// if the new or confirm was changed wipe out the errors for both since they are kind of coordinated
			delete apiErrors.new_password;
			delete apiErrors.confirm_new_password;
		}
	}

	return (
		<React.Fragment>
			<Form key={'profileForm' + 1} className="profileForm">
				<MessageBox options={detailsMessageOptions} />
				<fieldset>
					<div className="formField">
						<div className={"inputField medium " + ((getError('firstname')) ? "error" : "")}>
							<label htmlFor="firstname">First Name</label>
							<Field type="text" name='firstname' autoComplete="off" className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
						<div className={"inputField medium " + ((getError('lastname')) ? "error" : "")}>
							<label htmlFor="lastname">Last Name</label>
							<Field type="text" name='lastname' autoComplete="off" className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
						<div className={"inputField medium " + ((getError('phone')) ? "error" : "")}>
							<label htmlFor="phone">Phone #</label>
							<Field type="text" name='phone' autoComplete="off" className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
						<div className={"inputField medium " + ((getError('job_title')) ? "error" : "")}>
							<label htmlFor="job_title">Job Title</label>
							<Field type="text" name='job_title' autoComplete="off" className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
						<React.Fragment>
							<div className="formButtonsBank" id="detailsform-actions-div">
								<button className="medButtonPrimary" type="button" onClick={() => submitAction('details')}>Save</button>
								<button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
							</div>
						</React.Fragment>
					</div>
				</fieldset>
			</Form>
			<Form key={'profileForm' + 2} className="profileForm profileForm__password">
				<MessageBox options={pwMessageOptions} />
				<fieldset>
					<div className="formField">
						<div className={"inputField small " + ((getError('current_password')) ? "error" : "")}>
							<label htmlFor="current_password">Current Password</label>
							<Field type="password" name='current_password' autoComplete="off" className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
						<div className={"inputField small " + ((getError('new_password')) ? "error" : "")}>
							<label htmlFor="new_password">New Password</label>
							<Field type="password" name='new_password' autoComplete="off" className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
						<div className={"inputField small " + ((getError('confirm_new_password')) ? "error" : "")}>
							<label htmlFor="confirm_new_password">Confirm New Password</label>
							<Field type="password" name='confirm_new_password' autoComplete="off" className="inputField__input inputField__input-first"
								onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
							/>
						</div>
						<React.Fragment>
							<div className="formButtonsBank" id="pwform-actions-div">
								<button className="medButtonPrimary" type="button" onClick={() => submitAction('password')}>Save</button>
								<button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
							</div>
						</React.Fragment>
					</div>
				</fieldset>
			</Form>
		</React.Fragment >
	);
};

const UserProfileForm = withFormik({
	enableReinitialize: true,
	mapPropsToValues(props) {
		if (props.data) {
			return {
				firstname: props.data.firstname ? props.data.firstname : '',
				lastname: props.data.lastname ? props.data.lastname : '',
				phone: props.data.phone ? props.data.phone : '',
				job_title: props.data.job_title ? props.data.job_title : '',
				current_password: '',
				new_password: '',
				confirm_new_password: ''
			};
		}
	},

	validationSchema: Yup.object().shape({
		firstname: Yup.string()
			.required('First name is required'),
		lastname: Yup.string()
			.required('Last name is required'),
		phone: Yup.string(),
		job_title: Yup.string(),
	}),

})(FormLayout);

export default UserProfileForm;