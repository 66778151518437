import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import BasicReportTable from "../../Common/ReportTable/BasicReportTable";

const SamplingConfigDialog = ({ open, onClose, sampleReportConfigID }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Sampling Configurations</DialogTitle>
            <DialogContent>
                {sampleReportConfigID !== null && (
                    <BasicReportTable
                        reportUUID="6a8542cb-6309-4da1-a06f-55958e4ca036"
                        inputs={{ "Sample Report Config Id": sampleReportConfigID }}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default SamplingConfigDialog;
