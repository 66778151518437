import React, { useState, useEffect } from 'react';
import CommTypeDetailsForm from './CommTypeDetailsForm';
import editModeIcon from '../../../images/editMode.svg';
import { updateURL } from '../../utils/url';
import { getCommType, updateCommType, createCommType, deleteCommType } from '../../api/communicationsAPI';
import { ModalDuplicateContext } from '../../contexts/ModalDuplicateContext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';

const CommTypeDetails = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [data, setData] = useState({});
    const [apiErrors, setApiErrors] = useState({});
    const [isNew, setIsNew] = useState(props.newAddition);
    const [textEditorOpen, setTextEditorOpen] = useState(false);
    const [commText, setCommText] = useState();
    const [formErrors, setFormErrors] = useState();

    useEffect(() => {
        if (props.newAddition) {
            setEditMode(true);
        } else {
            getCommTypeData(props.uuid);
        }
    }, [props.uuid]);

    const getCommTypeData = (uuid) => {
        if (uuid !== undefined && uuid !== '') {
            getCommType(uuid).then(response => {
                props.setHeader(response.communication_type);
                setData(response);
            });
        }
    };

    const saveDetails = async (ctx, uuid, payload) => {

        if (isNew) {
            saveNewCommType(payload);
        } else {
            if (data.communication_type != payload.communication_type) {
                // they are changing the comm type, so we need to set the value they passed from the form to
                // new_comm_type and our comm_type to the original value so we can find and update it.
                payload.new_communication_type = payload.communication_type;
                payload.communication_type = data.communication_type;
            }

            updateCommType(uuid, payload)
                .then(response => {
                    props.refresh();
                    setEditMode(false);
                    props.setHeader(response.communication_type);
                    setData(response);
                    updateURL(`/communications/types/${response.communication_type}`);
                })
                .catch(error => {
                    if (error.name === 'APIUserError') {
                        setApiErrors(error.message);
                    }
                });
        }
    };

    const saveNewCommType = (payload) => {
        createCommType(payload)
            .then(response => {
                setEditMode(false);
                setIsNew(false);
                setData(response);

                if (props.source === 'comm') {
                    props.getCommTypes();
                    props.setNewCommType(response.communication_type);
                    props.closeFlyout();
                } else {
                    props.setHeader(response.communication_type);
                    props.openFlyout(response, false, 'type');
                    if (!props.downloads) {
                        props.refresh();
                    }
                }
            })
            .catch(error => {
                if (error.name === 'APIUserError') {
                    setApiErrors(error.message);
                }
            });
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
        setDeleteMode(false);
    };

    const deleteThisCommType = async (type) => {
        if (type === 'delete') {
            deleteCommType(data.communication_type).then(() => {
                props.closeFlyout();
                props.refresh();
            }).catch(error => {
                if (error.response.status === 409) {
                    setApiErrors([error.response.data.Error]);
                }
            });
        } else if (type === 'cancel') {
            setDeleteMode(false);
            setApiErrors([]);
        } else {
            setDeleteMode(true);
            setTimeout(() => {
                document.getElementById('flyoutBase').scrollIntoView(false);
            }, 100);
        }
    };

    return (
        <ModalDuplicateContext.Consumer>
            {(ctx) => {
                return (
                    <div className="flyoutContentContainer" id="flyoutBase">
                        <button className="editModeButton" onClick={() => toggleEditMode()}>
                            {!editMode && (
                                <img src={editModeIcon} alt='' />
                            )}
                        </button>
                        <CommTypeDetailsForm
                            data={data}
                            context={ctx}
                            editMode={editMode}
                            saveDetails={saveDetails}
                            toggleEditMode={toggleEditMode}
                            newAddition={props.newAddition}
                            closeFlyout={props.closeFlyout}
                            openFlyout={props.openFlyout}
                            deleteThisCommType={deleteThisCommType}
                            apiErrors={apiErrors}
                            setApiErrors={setApiErrors}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                        />
                        {editMode && !deleteMode && !props.newAddition && (
                            <div className="deleteButton__container">
                                <button className="deleteButton" onClick={() => deleteThisCommType()}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
                            </div>
                        )}
                        {editMode && deleteMode && (
                            <div className="flyout__deleteMode">
                                <span>Warning: This will delete all associated templates and actions cannot be undone.</span>
                                <button className="medButtonSecondary" type="button" onClick={() => deleteThisCommType('delete')}>Delete Type</button>
                                <button className="exit" onClick={() => deleteThisCommType('cancel')}><CloseIcon fontSize="large" /></button>
                            </div>
                        )}
                    </div>
                );
            }}
        </ModalDuplicateContext.Consumer>
    );
};

export default CommTypeDetails;