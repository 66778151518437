import React from "react";
import { Tooltip as MuiTooltip } from "@mui/material";
import styles from "../../styles/common/matieralUI/toolTip.module.css";

const ToolTip = ({ placement, arrow, children, ...props }) => (
    <MuiTooltip {...props} classes={styles} placement={placement || "top"} arrow>
        {children}
    </MuiTooltip>
);

export default ToolTip;
