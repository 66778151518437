import React, { useState, useRef, useEffect, useMemo } from 'react';
import JoditEditor from "jodit-react";

const NewEditor = (props) => {

    const onEditorChange = (editorNewValue) => {
        props.setContent(editorNewValue);
    };

    const config = {
        readonly: props.newAddition || props.editMode ? false : true,
        zIndex: 1000000,
        image: { url: 'none' },
        uploader: { "insertImageAsBase64URI": true },
        spellcheck: true,
        placeholder: '',
        activeButtonsInReadOnly: ['print'],
        buttons: [
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent', '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'table',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr',
            'eraser',
            'copyformat', '|',
            'symbol',
            'print'
        ],
    }

    return useMemo(
        () => (
            <JoditEditor
                value={props.content}
                config={config}
                tabIndex={1}
                onBlur={onEditorChange}
                onChange={onEditorChange}
            />
        ), []
    );
}

export default NewEditor;