import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getURLModule, getURLParentObject } from "../../../utils/url";
import { getPermit } from "../../../api/permitsAPI";
import { BasicPermitFields } from "./BasicPermitFields";
import chipStyles from "../../../styles/common/chip.module.css";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import SummaryGrid from "../../Common/Forms/SummaryGrid";
import Chip from "../../Common/Chip";

const ViewPermit = ({ location }) => {
    const [error, setError] = useState(null);
    const [permitData, setPermitData] = useState({});
    const { slug } = getURLModule();
    const { id: permitId } = getURLParentObject("p") || {};
    const { templateUUID } = location.state || {};

    useEffect(() => {
        setError(null);

        getPermit(permitId).then((data) => {
            const updatedBasicFields = BasicPermitFields.reduce((prev, curr) => {
                return { ...prev, [curr.id]: data[curr.id] };
            }, {});

            setPermitData(updatedBasicFields);
        });
    }, [permitId]);

    const { name, permit_number, status } = permitData;
    const title = name ? (permit_number ? `${name} (${permit_number})` : name) : permit_number || "Unnamed Permit";
    const notShownOnSummaryGrid = ["name", "permit_number", "status"];
    const fields = [
        ...BasicPermitFields.filter((f) => !notShownOnSummaryGrid.includes(f.id)),
        {
            id: "template_link",
            inputType: "link",
            label: "Template",
            url: `/${slug}/cfg/t/${templateUUID}`,
        },
        {
            id: "pdf_link",
            inputType: "download",
            label: "Download Document",
            url: `/legalcontrols/download/${permitId}`,
            filename: `${title.replaceAll(/[^\w ]/g, "").replaceAll(/ +/g, "-")}.pdf`,
        },
    ];

    return (
        <Page error={error}>
            <Card
                title={title}
                chip={
                    status ? (
                        <Chip
                            title={status}
                            className={status === "completed" ? chipStyles.active : chipStyles.inactive}
                        />
                    ) : null
                }
            >
                <SummaryGrid fields={fields} formState={permitData} />
            </Card>
        </Page>
    );
};

export default withRouter(ViewPermit);
