import React from 'react';
import UserDetails from './Users/UserDetails';
import UserCerts from './Users/UserCerts';
import UserTestkit from './Users/UserTestkit';
import UserPermissions from './Users/UserPermissions';
import LocationDetails from './Locations/LocationDetails';
import LocationAssemblies from './Locations/LocationAssemblies';
import LocationContacts from './Locations/LocationContacts';
import ServiceProviderContacts from './ServiceProviders/ServiceProviderContacts';
import ServiceProviderDetails from './ServiceProviders/ServiceProviderDetails';
import ServiceProviderTestKits from './ServiceProviders/ServiceProviderTestKits';
import TestKitDetails from './ServiceProviders/TestKitDetails';
import TestKitCalibration from './ServiceProviders/TestKitCalibration';
import ServiceProviderUsers from './ServiceProviders/ServiceProviderUsers';
import AssemblyDetails from './Assemblies/AssemblyDetails';
import AssemblyTestHistory from './Assemblies/AssemblyTestHistory';
import ContactDetails from './Contacts/ContactDetails';
import ContactAddresses from './Contacts/ContactAddresses';
import ContactLocations from './Contacts/ContactLocations';
import TestReportDetails from './TestReports/TestReportDetails';
import TestReportFiles from './TestReports/TestReportFiles';
import ComplianceReportIssues from './TestReports/ComplianceReportIssues';
import LocationSurveyHistory from './Locations/LocationSurveyHistory';
import APITokenFlyout from './Settings/APITokenFlyout';
import CommDetails from './Communications/CommDetails';
import CommTypeDetails from './Communications/CommTypeDetails';
import CommLogDetails from './Communications/CommLogDetails';
import CommLogContactDetails from './Communications/CommLogContactDetails';
import CommDownloadRequestDetails from './Communications/CommDownloadRequestDetails';
import CommunicationsTab from './Communications/CommunicationsTab';
import ComplianceHistory from './ComplianceHistory';

const FlyoutCategories = (props) => {
	return (
		<React.Fragment>
			{props.activeCategory === 'userDetails' ? <UserDetails {...props} /> : null}
			{props.activeCategory === 'userCerts' ? <UserCerts {...props} /> : null}
			{props.activeCategory === 'userTestkit' ? <UserTestkit {...props} /> : null}
			{props.activeCategory === 'userPermissions' ? <UserPermissions {...props} /> : null}

			{props.activeCategory === 'locationDetails' ? <LocationDetails {...props} /> : null}
			{props.activeCategory === 'locationContacts' ? <LocationContacts {...props} /> : null}
			{props.activeCategory === 'locationAssemblies' ? <LocationAssemblies {...props} /> : null}
			{props.activeCategory === 'locationSurveyHistory' ? <LocationSurveyHistory {...props} /> : null}

			{props.activeCategory === 'serviceProviderDetails' ? <ServiceProviderDetails {...props} /> : null}
			{props.activeCategory === 'serviceProviderUsers' ? <ServiceProviderUsers {...props} /> : null}
			{props.activeCategory === 'serviceProviderContacts' ? <ServiceProviderContacts {...props} /> : null}
			{props.activeCategory === 'serviceProviderTestKits' ? <ServiceProviderTestKits {...props} /> : null}
			{props.activeCategory === 'testKitDetails' ? <TestKitDetails {...props} /> : null}
			{props.activeCategory === 'testKitCalibration' ? <TestKitCalibration {...props} /> : null}

			{props.activeCategory === 'assemblyDetails' ? <AssemblyDetails {...props} /> : null}
			{props.activeCategory === 'assemblyTestHistory' ? <AssemblyTestHistory {...props} /> : null}

			{props.activeCategory === 'contactDetails' ? <ContactDetails {...props} /> : null}
			{props.activeCategory === 'contactAddresses' ? <ContactAddresses {...props} /> : null}
			{props.activeCategory === 'contactLocations' ? <ContactLocations {...props} /> : null}

			{props.activeCategory === 'testReportDetails' ? <TestReportDetails {...props} /> : null}
			{props.activeCategory === 'testReportIssues' ? <ComplianceReportIssues {...props} /> : null}
			{props.activeCategory === 'testReportFiles' ? <TestReportFiles {...props} /> : null}

			{props.activeCategory === 'commDetails' ? <CommDetails {...props} /> : null}
			{props.activeCategory === 'commTypeDetails' ? <CommTypeDetails {...props} /> : null}
			{props.activeCategory === 'commLogDetails' ? <CommLogDetails {...props} /> : null}
			{props.activeCategory === 'commLogContactDetails' ? <CommLogContactDetails {...props} /> : null}
			{props.activeCategory === 'commDownloadRequestDetails' ? <CommDownloadRequestDetails {...props} /> : null}

			{props.activeCategory === 'communications' ? <CommunicationsTab {...props} /> : null}

			{props.activeCategory === 'apiTokenDetails' ? <APITokenFlyout {...props} /> : null}

			{props.activeCategory === 'complianceHistory' ? <ComplianceHistory {...props} /> : null}
		</React.Fragment>
	);
};

export default FlyoutCategories;
