import {
    FETCH_DASHBOARDS_REQUEST,
    FETCH_DASHBOARDS_SUCCESS,
    FETCH_DASHBOARDS_FAILURE,
} from "../actions/dashboardActions";

const initialState = {
    loading: false,
    dashboards: [],
    error: "",
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DASHBOARDS_REQUEST:
            return { ...state, loading: true };
        case FETCH_DASHBOARDS_SUCCESS:
            return { ...state, loading: false, dashboards: action.payload };
        case FETCH_DASHBOARDS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default dashboardReducer;
