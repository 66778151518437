import React from 'react';
import locations from '../../../images/locationsBlue.svg';
import Flyout from '../Flyout';
import validator from 'validator';
import { updateURL } from '../../utils/url';
import locationsLarge from '../../../images/locationsBlue.svg';

import ReportTable from '../ReportTable/ReportTable';

class LocationsDashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			flyoutActive: false,
			locationInfo: {},
			newLocation: false,
			locationUUID: null
		};

		this.table = React.createRef();

		const urlParts = this.props.location.pathname.split("/");
		if (urlParts.length >= 3 && validator.isUUID(urlParts[2])) {
			this.state.locationUUID = urlParts[2];
		}
	}

	async componentDidMount() {
		if (this.state.locationUUID !== null) {
			this.openFlyout({ property_uuid: this.state.locationUUID }, false);
		}
	}

	openFlyout = async (locationInfo, newLocation) => {
		const overlay = document.getElementById('overlay');

		if (newLocation) {
			this.setState({ locationInfo: {}, newLocation: true, flyoutActive: true });
		} else {
			this.setState({ locationInfo: {property_uuid: locationInfo.property_uuid, location_name: locationInfo.name}, newLocation: false, flyoutActive: true });
			// update the url in the browser without causing any reloads
			updateURL('/locations/' + locationInfo.property_uuid, {});
		}
		overlay.classList.add('overlay__visible');
	}

	closeFlyout = () => {
		const flyout = document.getElementById('flyout');
		const overlay = document.getElementById('overlay');

		flyout.classList.remove('slideToVisible');
		overlay.classList.remove('overlay__visible');


		setTimeout(() => {
			this.setState(() => ({
				flyoutActive: false,
				locationInfo: {},
				locationUUID: null
			}));
		}, 200);

		this.table.current.ParentEventHandler('flyout closed', { path: '/locations' });
	}

	render() {
		const { flyoutActive, locationInfo, newLocation } = this.state;
		return (
			<div className="componentContainer">
				<div className="componentHeader">
					<div className="flexAlignCenter">
						<img src={locations} alt='' />
						<h3>Locations</h3>
					</div>
					<button onClick={() => this.openFlyout(null, true)} className="longButtonPrimary">Add Location</button>
				</div>
				<ReportTable
					ref={this.table}
					page='locations'
					tableType='Locations'
					openFlyout={this.openFlyout}
					flyoutActive={flyoutActive}
					defaultReportIdx='0'
					reports={[
						{
							reportUUID: "0eba7876-ec72-465c-919f-35138aefa6c3",
							name: "Locations",
							columns: ['Account Number', 'Name', 'Address', 'Device Count', 'Survey Compliance', 'Survey Expiration', 'Primary Contact', 'Phone', 'Email']
						}
					]}
				/>
				<img className="dashboardIconLarge dashboardIconLarge__adjusted" src={locationsLarge} alt='' />
				<div className="overlay" id="overlay" onClick={() => { this.closeFlyout() }}>
				</div>
				{flyoutActive && (
					<React.Fragment>
						<Flyout
							uuid={locationInfo.property_uuid}
							newAddition={newLocation}
							locationInfo={locationInfo}
							flyoutType='locations'
							flyoutPriority="primary"
							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							activeTab='locationDetails'

							refresh={() => this.table.current.ParentEventHandler('needs update')}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default LocationsDashboard;