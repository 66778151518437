import React from 'react';
import { Field } from 'formik';
import DatePicker from '../DatePicker';
import moment from 'moment';

// date fields need special handing, and instead of having to define a pre / post process for every state that has them
// we can just list them here.  TODO we might want to move WA ones here, but I didn't want to mess with them at the moment
const SRdateFields = {
    "CO": ["date_suspended", "date_unsuspended"]
};

// certain fields, like selects, radios, anything we "hardcode" the values in we should lower case the value so
// they match with what we hardcode
const SRlowerCaseFields = {
    "CA": ["protection_type"],
    "CO": ["protection_type"],
    "FL": ["connection_type"],
    "MA": [""],
    "WA": ["protection_type", "hazard_type"]
}

const AssemblyBFPStateReportFields = (props) => {
    const { editMode, setFieldValue, values } = props;

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setFieldValue(e.target.name, e.target.value);
        } else {
            setFieldValue(e.target.name, null);
        }
    }

    let stateReportFields = {
        "CA": CAFields(props, handleCheckboxChange),
        "CO": COFields(props, handleCheckboxChange),
        "FL": FLFields(props, handleCheckboxChange),
        "MA": MAFields(props, handleCheckboxChange),
        "WA": WAFields(props, handleCheckboxChange),
    };

    if (!(SwiftComply.organization.state_prov.toLocaleUpperCase() in stateReportFields)) {
        return null;
    } else {
        return (
            <React.Fragment>
                <hr />
                <fieldset id="state-report-fields">
                    {stateReportFields[SwiftComply.organization.state_prov.toLocaleUpperCase()]}
                </fieldset>
            </React.Fragment>
        );
    }
}

// used in a formik mapPropsToValues function to process the JSON from the backend into values that we need
const AssemblyBFPStateReportFieldsPreprocess = (sr_fields) => {
    if (!sr_fields) {
        return {};
    }

    let fields = JSON.parse(sr_fields);
    let values = {};

    let funcs = {
        "WA": preprocessWAFields
    };

    let orgState = SwiftComply.organization.state_prov.toLocaleUpperCase();

    // lower case any values that need it, we need to do this for the fields coming in here so that when we pass them
    // to any specific preprocess functions they are just done
    if (orgState in SRlowerCaseFields) {
        for (const field of SRlowerCaseFields[orgState]) {
            if (field in fields) {
                fields[field] = fields[field].toLocaleLowerCase();
            }
        }
    }

    if (orgState in funcs) {
        return funcs[orgState](fields, values);
    }

    for (const key in fields) {
        if (orgState in SRdateFields && SRdateFields[orgState].includes(key)) {
            let m = moment.unix(fields[key]);
            values["sr_" + key] = m.valueOf();
        } else {
            values["sr_" + key] = fields[key];
        }
    }

    return values;
}

const preprocessWAFields = (fields, values) => {
    if (!values.sr_exception) {
        values.sr_exception = {};
    }

    for (const key in fields) {
        values["sr_" + key] = fields[key];
    }

    for (const name of ["evaluated", "granted", "expires", "next_eval"]) {
        if (fields.exception && fields.exception[name]) {
            let m = moment.unix(fields.exception[name]);
            values.sr_exception[name] = m.valueOf();
        } else {
            delete values.sr_exception[name];
        }
    }

    if (fields.exception && fields.exception.reasons) {
        let reasons = fields.exception.reasons.split(",");
        for (const r of reasons) {
            values["sr_excep_reason_" + r] = r;
        }
    }
    return values;
}

// used in handleSubmit calls to convert names / values into the sr_fields JSON to store in the backend
const AssemblyBFPStateReportFieldsPostprocess = (values) => {
    let sr_fields = {};
    let funcs = {
        "WA": postprocessWAFields
    };

    let orgState = SwiftComply.organization.state_prov.toLocaleUpperCase();

    if (orgState in funcs) {
        return funcs[orgState](values);
    }

    // potential TODO here.  Because we preprocess the values from the backend and put all the fields into the values
    // object, we might get vals here that aren't on the form, if we do we end up passing them through as is.  Maybe not
    // a problem at all, but if it is, that is why
    for (const key in values) {
        if (key.startsWith("sr_")) {
            let lookupKey = key.replace("sr_", "");
            if (orgState in SRdateFields && SRdateFields[orgState].includes(lookupKey)) {
                if (values[key] != null) {
                    if (typeof values[key] === "string") {
                        values[key] = parseInt(values[key], 10);
                    }
                    if (values[key].toString().length === 13) {
                        values[key] /= 1000;
                    }
                }
            }
            if (values[key] != null && values[key] != undefined) {
                sr_fields[key.replace("sr_", "")] = values[key];
            }
        }
    }

    return sr_fields;
}

const postprocessWAFields = (values) => {
    let sr_fields = { exception: {} };

    for (const name of ["protection_type", "hazard_type", "other_hazard_type", "pws", "bypass", "bypass_for", "irrigation"]) {
        if (values["sr_" + name]) {
            sr_fields[name] = values["sr_" + name];
        }
    }

    for (const name of ["evaluated", "granted", "expires", "next_eval", "comments"]) {
        if (values.sr_exception && values.sr_exception[name]) {
            let val = values.sr_exception[name];
            if (name !== "comments") {
                if (typeof val === "string") {
                    val = parseInt(val, 10);
                }
                if (val.toString().length === 13) {
                    val /= 1000;
                }
            }

            sr_fields.exception[name] = val;
        }
    }

    let reasons = [];
    for (const key in values) {
        if (key.startsWith("sr_excep_reason_") && values[key]) {
            reasons.push(values[key]);
        }
    }
    if (reasons.length > 0) {
        sr_fields.exception.reasons = reasons.join(",");
    }
    return sr_fields;
}

export default AssemblyBFPStateReportFields;
export { AssemblyBFPStateReportFieldsPreprocess, AssemblyBFPStateReportFieldsPostprocess };

const CAFields = (props, handleCheckboxChange) => {
    const { editMode, setFieldValue, values } = props;
    return [
        <div className="inputField long" key="sr_protection_type">
            <label htmlFor="sr_protection_type">Protection Type</label>
            <Field
                disabled={!editMode}
                component="select"
                name="sr_protection_type"
            >
                <option value=""></option>
                <option value="containment">containment</option>
                <option value="isolation">isolation</option>
            </Field>
        </div>
    ];
}

const COFields = (props, handleCheckboxChange) => {
    const { editMode, setFieldValue, values } = props;
    return [
        <div className="inputField long" key="sr_protection_type">
            <label htmlFor="sr_protection_type">Protection Type</label>
            <Field
                disabled={!editMode}
                component="select"
                name="sr_protection_type"
            >
                <option value=""></option>
                <option value="containment">Containment</option>
                <option value="containment by isolation">Containment by Isolation</option>
                <option value="isolation">Isolation</option>
            </Field>
        </div>,
        <div className="inputField long" key="sr_date_suspended">
            <label htmlFor="sr_date_suspended">Date Suspended</label>
            <Field
                name="sr_date_suspended"
                component={DatePicker}
                valueType="epoch"
                setFieldValue={setFieldValue}
                editMode={editMode}
            />
        </div>,
        <div className="inputField long" key="sr_date_unsuspended">
            <label htmlFor="sr_date_unsuspended">Date Unsuspended</label>
            <Field
                name="sr_date_unsuspended"
                component={DatePicker}
                valueType="epoch"
                setFieldValue={setFieldValue}
                editMode={editMode}
            />
        </div>,
        <div className="inputField long" key="sr_suppliers_waterworks">
            <label htmlFor="sr_suppliers_waterworks">Supplier's Waterworks?</label>
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_suppliers_waterworks"
                value="true"
                checked={(values["sr_suppliers_waterworks"]) ? true : false}
                onChange={handleCheckboxChange}
            />
        </div>,
    ];
}

const FLFields = (props, handleCheckboxChange) => {
    const { editMode, setFieldValue, values } = props;
    return [
        <div className="inputField long" key="sr_connection_type">
            <label htmlFor="sr_connection_type">Connection Type</label>
            <Field
                disabled={!editMode}
                component="select"
                name="sr_connection_type"
            >
                <option value=""></option>
                <option value="fire">Fire</option>
                <option value="irrigation">Irrigation</option>
                <option value="standard">Standard</option>
            </Field>
        </div>,
    ];
}

const MAFields = (props, handleCheckboxChange) => {
    const { editMode, setFieldValue, values } = props;
    return [
        <div className="inputField long" key="sr_seasonal">
            <label htmlFor="sr_seasonal">Seasonal?</label>
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_seasonal"
                value="true"
                checked={(values["sr_seasonal"]) ? true : false}
                onChange={handleCheckboxChange}
            />
        </div>,
    ];
}

const WAFields = (props, handleCheckboxChange) => {
    const { editMode, setFieldValue, values } = props;
    return [
        <div className="inputField long" key="sr_protection_type">
            <label htmlFor="sr_protection_type">Protection Type</label>
            <Field
                disabled={!editMode}
                component="select"
                name="sr_protection_type"
            >
                <option value=""></option>
                <option value="premises_isolation">premises isolation</option>
                <option value="in-premise protection">in-premise protection</option>
                <option value="fixture">fixture</option>
            </Field>
        </div>,
        <div className="inputField long" key="sr_hazard_type">
            <label htmlFor="sr_hazard_type">Assembly Table 13 Hazard Type</label>
            <Field
                disabled={!editMode}
                component="select"
                name="sr_hazard_type"
            >
                <option value=""></option>
                <option value="agricultural">Agricultural (farms and dairies)</option>
                <option value="alternative">Alternative health out-patient clinics and offices</option>
                <option value="bottling">Beverage bottling plants</option>
                <option value="blood">Blood centers</option>
                <option value="boarding">Boarding homes</option>
                <option value="car wash">Car washes</option>
                <option value="chemical plant">Chemical plants</option>
                <option value="childbirth">Childbirth centers</option>
                <option value="chiropractor">Chiropractors</option>
                <option value="laundry">Commercial laundries and dry cleaners</option>
                <option value="fire">Dedicated fire protection systems with chemical addition or using unapproved auxiliary supplies</option>
                <option value="irrigation">Dedicated irrigation systems using purveyor's water supply and with chemical addition</option>
                <option value="dental">Dental clinics and offices</option>
                <option value="film">Film processing facilities</option>
                <option value="food processing">Food processing plants</option>
                <option value="hospice">Hospice care centers</option>
                <option value="hospital">Hospitals (include psychiatric hospitals and alcohol and drug treatment centers)</option>
                <option value="kidney">Kidney dialysis centers</option>
                <option value="laboratory">Laboratories</option>
                <option value="metal plating">Metal plating industries</option>
                <option value="morgue">Morgues and autopsy facilities (not in hospitals)</option>
                <option value="mortuary">Mortuaries</option>
                <option value="nursing home">Nursing homes</option>
                <option value="other">Other High Hazard (3B)</option>
                <option value="other medical">Other Medical-Related High Hazard (3C)</option>
                <option value="out-patient">Out-patient clinics and offices</option>
                <option value="petroleum">Petroleum processing or storage plants</option>
                <option value="pier">Piers and docks</option>
                <option value="reclaimed">Premises where both reclaimed water and potable water are provided</option>
                <option value="aux water supply">Premises with an unapproved auxiliary water supply interconnected with the potable water supply</option>
                <option value="psychiatric">Psychiatric out-patient clinics and offices</option>
                <option value="radioactive">Radioactive material processing plants or nuclear reactors</option>
                <option value="residential treatment">Residential treatment centers</option>
                <option value="surgery">Same day surgery centers</option>
                <option value="access denied">Survey access denied or restricted</option>
                <option value="veterinarian">Veterinarian offices, clinics, and hospitals</option>
                <option value="wastewater station">Wastewater lift stations and pumping stations</option>
                <option value="wastewater treatment">Wastewater treatment plants</option>
            </Field>
        </div>,
        <div className="inputField long" key="sr_other_hazard_type">
            <label htmlFor="sr_other_hazard_type">Other High Hazard Type</label>
            <Field
                disabled={!editMode}
                name="sr_other_hazard_type"
            />
        </div>,
        <div className="inputField long" key="sr_irrigation">
            <label htmlFor="sr_irrigation">Low Hazard Irrigation?</label>
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_irrigation"
                value="true"
                checked={(values["sr_irrigation"]) ? true : false}
                onChange={handleCheckboxChange}
            />
        </div>,
        <div className="inputField long" key="sr_pws">
            <label htmlFor="sr_pws">Protects Public Water Supply?</label>
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_pws"
                value="true"
                checked={(values["sr_pws"]) ? true : false}
                onChange={handleCheckboxChange}
            />
        </div>,
        <div className="inputField long" key="sr_bypass">
            <label htmlFor="sr_bypass">Bypass?</label>
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_bypass"
                value="true"
                checked={(values["sr_bypass"]) ? true : false}
                onChange={handleCheckboxChange}
            />
        </div>,
        <div className="inputField long" key="sr_bypass_for">
            <label htmlFor="sr_bypass_for">Serial Number of Bypassed Assembly</label>
            <Field
                disabled={!editMode}
                name="sr_bypass_for"
            />
        </div>,
        <div className="inputField long" key="sr_excep_dates">
            <label htmlFor="exception.evaluated">Exception Evaluated On</label>
            <Field
                name="sr_exception.evaluated"
                component={DatePicker}
                valueType="epoch"
                setFieldValue={setFieldValue}
                editMode={editMode}
            />
            <label htmlFor="exception.granted">Exception Granted On</label>
            <Field
                name="sr_exception.granted"
                component={DatePicker}
                valueType="epoch"
                setFieldValue={setFieldValue}
                editMode={editMode}
            />
            <label htmlFor="exception.expires">Exception Expires On</label>
            <Field
                name="sr_exception.expires"
                component={DatePicker}
                valueType="epoch"
                setFieldValue={setFieldValue}
                editMode={editMode}
            />
            <label htmlFor="exception.next_eval">Exception Next Eval On</label>
            <Field
                name="sr_exception.next_eval"
                component={DatePicker}
                valueType="epoch"
                setFieldValue={setFieldValue}
                editMode={editMode}
            />
        </div>,
        <div className="inputField long" key="sr_excep_reasons">
            <label>Exception Reasons</label>
            <br />
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_excep_reason_medhealth"
                value="medhealth"
                checked={(values["sr_excep_reason_medhealth"]) ? true : false}
                onChange={handleCheckboxChange}
            />
            <label htmlFor="sr_excep_reason_medhealth">Medical/Health Services Facility not having laboratory or similar facilities, no water-connected X-ray equipment, e.g., Psychiatric or Counseling Office, outpatient clinics, etc.</label>
            <br />
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_excep_reason_dental"
                value="dental"
                checked={(values["sr_excep_reason_dental"]) ? true : false}
                onChange={handleCheckboxChange}
            />
            <label htmlFor="sr_excep_reason_dental">Dental Office having independent water supplies for dental work (no interconnection with purveyor’s water system) and digital X-ray equipment, i.e. no water-connected X-ray or other dental equipment.</label>
            <br />
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_excep_reason_bottling"
                value="bottling"
                checked={(values["sr_excep_reason_bottling"]) ? true : false}
                onChange={handleCheckboxChange}
            />
            <label htmlFor="sr_excep_reason_bottling">“Bottling Plant” without bottling processes, e.g., Warehousing only.</label>
            <br />
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_excep_reason_laundry"
                value="laundry"
                checked={(values["sr_excep_reason_laundry"]) ? true : false}
                onChange={handleCheckboxChange}
            />
            <label htmlFor="sr_excep_reason_laundry">Laundry or Dry Cleaners without cleaning processes on premises, e.g., customer drop-off and/or pick-up only.</label>
            <br />
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_excep_reason_marina"
                value="marina"
                checked={(values["sr_excep_reason_marina"]) ? true : false}
                onChange={handleCheckboxChange}
            />
            <label htmlFor="sr_excep_reason_marina">Marina/Dock for small boat moorage only (no water/sewage facilities on board).</label>
            <br />
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_excep_reason_agricultural"
                value="agricultural"
                checked={(values["sr_excep_reason_agricultural"]) ? true : false}
                onChange={handleCheckboxChange}
            />
            <label htmlFor="sr_excep_reason_agricultural">Agricultural Premises with “hobby farm” (non-commercial) activities only.</label>
            <br />
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_excep_reason_chiropractor"
                value="chiropractor"
                checked={(values["sr_excep_reason_chiropractor"]) ? true : false}
                onChange={handleCheckboxChange}
            />
            <label htmlFor="sr_excep_reason_chiropractor">Chiropractor’s office with digital X-ray equipment, i.e. no water-connected X-ray or other medical equipment.</label>
            <br />
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_excep_reason_mortuary"
                value="mortuary"
                checked={(values["sr_excep_reason_mortuary"]) ? true : false}
                onChange={handleCheckboxChange}
            />
            <label htmlFor="sr_excep_reason_mortuary">Mortuary facility with funeral services only, no water-connected equipment for processing or embalming bodies.</label>
            <br />
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_excep_reason_film"
                value="film"
                checked={(values["sr_excep_reason_film"]) ? true : false}
                onChange={handleCheckboxChange}
            />
            <label htmlFor="sr_excep_reason_film">Digital film printing with no water-connected equipment and/or self-contained film processing operation with no water-connected equipment and using only pre-packaged/pre-mixed chemicals.</label>
            <br />
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_excep_reason_nursing"
                value="nursing"
                checked={(values["sr_excep_reason_nursing"]) ? true : false}
                onChange={handleCheckboxChange}
            />
            <label htmlFor="sr_excep_reason_nursing">Nursing or boarding home with no water-connected medical equipment, specialized plumbing, or other hazards.</label>
            <br />
            <Field
                disabled={!editMode}
                type="checkbox"
                name="sr_excep_reason_other"
                value="other"
                checked={(values["sr_excep_reason_other"]) ? true : false}
                onChange={handleCheckboxChange}
            />
            <label htmlFor="sr_excep_reason_other">Other, please describe in comments</label>
        </div>,
        <div className="inputField long" key="sr_exception.comments">
            <label htmlFor="sr_exception.comments">Comments</label>
            <Field
                component="textarea"
                disabled={!editMode}
                rows="2"
                name="sr_exception.comments"
            />
        </div>
    ];
}
