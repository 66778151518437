import React, { useState, useRef, useEffect, useMemo } from 'react';
import NewEditor from './NewEditor';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const CommsEditor = (props) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        let defaultText;
        if (props.type === 'commText') {
            defaultText = props.data.template;
        } else if (props.type === 'emailText') {
            defaultText = props.data.email_body;
        } else if (props.type === 'additionalContents') {
            defaultText = props.data.additional_contents;
        }
        setContent(defaultText)
    }, [props.data])

    let title;
    if (props.type === 'commText') {
        if (props.newAddition) {
            title = 'New Communication';
        } else {
            title = props.data.name;
        }
    } else if (props.type === 'emailText') {
        title = 'Email Body'
    } else if (props.type == 'additionalContents') {
        title = 'Additional Contents'
    }


    return (
        <div>
            <Dialog id={props.postcard ? 'postcardEditor' : 'standardEditor'} open={props.open} onClose={() => props.editorControls('close', props.type)} aria-labelledby="form-dialog-title" disableEnforceFocus>
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <NewEditor
                        data={props.data}
                        newAddition={props.newAddition}
                        editMode={props.editMode}
                        type={props.type}
                        content={content}
                        setContent={setContent}
                    />
                </DialogContent>
                <DialogActions>
                    {props.editMode &&
                        <button className="smallButtonPrimary" onClick={() => props.editorControls('save', props.type, content)}>save</button>
                    }
                    <button className="smallButtonSecondary" onClick={() => props.editorControls('close', props.type)}>close</button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CommsEditor;