import axios from 'axios';

// API tokens

export const getAPITokens = () => {
    const url = '/api/tokens';

    return axios.get(url)
        .then(response => {
            return response.data.tokens;
        })
        .catch(error => {
            throw error;
        });
};

export const getAPIToken = (uuid) => {
    const url = `/api/tokens/${uuid}`;

    return axios.get(url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
};

export const createAPIToken = (name) => {
    const url = '/api/tokens';

    return axios.post(url, { "name": name })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export const updateAPIToken = (uuid, name) => {
    const url = `/api/tokens/${uuid}`;

    return axios.put(url, { "name": name })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export const deleteAPIToken = (uuid) => {
    const url = `/api/tokens/${uuid}`;

    return axios.delete(url)
        .catch(error => {
            throw error;
        });
}

export const getTokenRoles = (uuid) => {
    const url = `/api/tokens/${uuid}/roles`;

    return axios.get(url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
};

export const removeTokenRole = (uuid, role) => {
    const url = `/api/tokens/${uuid}/role/${role}`;

    return axios.delete(url)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
};

export const addTokenRole = (uuid, role) => {
    const url = `/api/tokens/${uuid}/roles`;

    let payload = {
        roles: [{ role_name: role }]
    }

    return axios.post(url, payload)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
};