import axios from 'axios';
import queryString from 'query-string';

// Service providers
export const listServiceProviders = (params) => {
	const url = '/api/v1/service_providers';
	let qs = queryString.stringify(params);

	return axios.get(url + ((qs) ? '?' + qs : ''))
		.then(response => {
			return response.data;
		})
		.catch(error => {
			throw error;
		});
};

export const getServiceProvidersReport = (payload) => {
	const url = '/api/v1/reporting/0a3d6a20-46df-47b9-9321-a8cc9a10bb75';

	return axios.post(url, payload)
		.then(response => {
			console.log('Got Service Providers Report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Service Providers Report!', error.response.data);
			throw error;
		});
};

const baseURL = '/api/v1/service_providers'

export const createServiceProvider = (payload) => {
	const url = baseURL;

	return axios.post(url, payload)
		.then(response => {
			console.log('Created Service Provider!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create Service Provider', error.response.data);
			throw error;
		});
};

export const getServiceProvider = (serviceProviderUUID) => {
	const url = `${baseURL}/${serviceProviderUUID}`;

	return axios.get(url)
		.then(response => {
			console.log('Got Service Provider!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get Service Provider', error.response.data);
			throw error;
		});
};

export const editServiceProvider = (serviceProviderUUID, payload) => {
	const url = `${baseURL}/${serviceProviderUUID}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Edited Service Provider!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t edit Service Provider', error.response.data);
			throw error;
		});
};

export const deleteServiceProvider = (serviceProviderUUID) => {
	const url = `${baseURL}/${serviceProviderUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Deleted Service Provider!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t delete Service Provider', error.response.data);
			throw error;
		});
};

// Service provider users
export const getServiceProviderUsersReport = (serviceProviderUUID, payload) => {
	const url = '/api/v1/reporting/b157ea13-0949-446b-84a5-84d5a27322eb';

	if (payload === undefined) {
		payload = { inputs: {} };
	} else if (payload.inputs === undefined) {
		payload.inputs = {};
	}

	if (serviceProviderUUID !== undefined) {
		payload.inputs["Service Provider UUID"] = serviceProviderUUID;
	}

	return axios.post(url, payload)
		.then(response => {
			console.log('Got Service Provider Users Report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Service Provider Users Report!', error.response.data);
			throw error;
		});
};

export const setServiceProviderUserStatus = (serviceProviderUUID, userUUID, status, payload) => {
	const url = `/api/v1/service_providers/${serviceProviderUUID}/users/${userUUID}/status`;

	let params = {};
	if (status === undefined) {
		// nothing to do
		return;
	}
	params.status = status;

	if (payload && payload.approved_until) {
		let approved_until = payload.approved_until;
		if (typeof approved_until === "string") {
			approved_until = parseInt(approved_until, 10);
		}
		if (approved_until.toString().length === 13) {
			// came to us in ms, convert to s
			approved_until /= 1000;
		}
		params.approved_until = approved_until;
	}

	if (payload && payload.notes) {
		// doesn't seem to be used by the backend yet
		params.notes = payload.notes;
	}

	return axios.put(url, params)
		.then(response => {
			console.log('set service provider user status', response);
			return response.data;
		})
		.catch(error => {
			console.log('couldn\'t set service provider user status', error.response.data);
			throw error;
		});
}

export const createServiceProviderUser = (serviceProviderUUID, payload) => {
	console.log(payload)
	const url = `/api/v1/service_providers/${serviceProviderUUID}/users`;

	return axios.post(url, payload)
		.then(response => {
			console.log('Created Service Provider User', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create service provider user', error.response.data);
			if (error.response.data['Error'] === 'an entry for this user already exists') {
				return error.response.data['Error']
			} else {
				throw error;
			}
		});
};

export const removeServiceProviderUser = (serviceProviderUUID, userUUID) => {
	const url = `/api/v1/service_providers/${serviceProviderUUID}/users/${userUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Removed user from service provider', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t remove user from service provider', error.response.data);
			throw error;
		});
};

// Service provider contacts
export const getServiceProviderContactsReport = (params) => {
	const url = '/api/v1/reporting/849587d7-6462-47f7-9a73-29cff8fc9c95';

	return axios.post(url, params)
		.then(response => {
			console.log('Got Service ProviderContacts Report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Service Provider Contacts Report!', error.response.data);
			throw error;
		});
};

export const getServiceProviderContact = (serviceProviderUUID, contactUUID) => {
	const url = `/api/v1/service_providers/${serviceProviderUUID}/contacts/${contactUUID}`;

	return axios.get(url)
		.then(response => {
			console.log('Got Service Provider Contact!', response.data);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get ServiceProvider Contact!', error.response.data);
			throw error;
		});
};

export const createServiceProviderContact = async (serviceProviderUUID, contactUUID, payload) => {
	const url = `${baseURL}/${serviceProviderUUID}/contacts`;
	payload.contact_uuid = contactUUID;

	await axios.post(url, payload)
		.then(response => {
			console.log('Created Service Provider Contact Link!', response);
		})
		.catch(error => {
			console.log('Couldn\'t create Service provider Contact Link!', error.response.data);
			throw error;
		});
}

export const updateServiceProviderContact = async (serviceProviderUUID, contactUUID, payload) => {
	const url = `/api/v1/service_providers/${serviceProviderUUID}/contacts/${contactUUID}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated Service Provider Contact!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t update Service Provider Contact!', error.response.data);
			throw error;
		});
};

export const removeServiceProviderContact = (serviceProviderUUID, contactUUID) => {
	const url = `/api/v1/service_providers/${serviceProviderUUID}/contacts/${contactUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Removed Service Provider Contact!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t remove Service Provider Contact!', error.response.data);
			throw error;
		});
};

export const getServiceProviderDetails = (serviceProviderUUID) => {
	const url = `/api/v1/service_providers/${serviceProviderUUID}/details`;

	return axios.get(url)
		.then(response => {
			console.log('Got Service Provider Details!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Service Provider Details!', error.response.data);
			throw error;
		});
};

export const saveServiceProviderDetail = async (serviceProviderUUID, payload) => {
	const url = `/api/v1/service_providers/${serviceProviderUUID}/details`;

	return axios.post(url, payload)
		.then(response => {
			console.log('Created Service Provider Detail!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t create Service Provider Detail!');
			throw error;
		});
};

// Equipment
export const getServiceProviderEquipmentReport = (serviceProviderUUID, payload) => {
	const url = '/api/v1/reporting/34279b1e-3d98-4bba-862d-ebeab85f3040';

	if (payload === undefined) {
		payload = { inputs: {} };
	} else if (payload.inputs === undefined) {
		payload.inputs = {};
	}
	if (serviceProviderUUID !== undefined) {
		payload.inputs["Service Provider UUID"] = serviceProviderUUID;
	}

	return axios.post(url, payload)
		.then(response => {
			console.log('Got Equipment Report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Equipment Report!', error.response.data);
			throw error;
		});
};

export const getServiceProviderEquipment = async (serviceProviderUUID) => {
	const url = `/api/v1/service_providers/${serviceProviderUUID}/equipment`;

	return axios.get(url)
		.then(response => {
			console.log('Got Service Provider Equipment!', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t Get Service Provider Equipment!', error.response.data);
			throw error;
		});
};

export const deleteServiceProviderEquipment = async (serviceProviderUUID, equipmentUUID) => {
	const url = `/api/v1/service_providers/${serviceProviderUUID}/equipment/${equipmentUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Deleted service provider equipment', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t delete service provider equipment', error.response.data);
			throw error;
		});
};

export const getServiceProviderUserInfoReport = (serviceProviderUUID, userUUID, payload) => {
	const url = '/api/v1/reporting/d98de6bc-acc5-4ca7-93cd-f0bef01c7ddc';

	if (payload === undefined) {
		payload = { inputs: {} };
	} else if (payload.inputs === undefined) {
		payload.inputs = {};
	}
	payload.inputs["Service Provider UUID"] = serviceProviderUUID;
	payload.inputs["User UUID"] = userUUID;

	return axios.post(url, payload)
		.then(response => {
			console.log('Got Service Provider User Info Report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Service Provider User Info Report!', error.response.data);
			throw error;
		});
};

// Auto Accept Settings

export const listSPAutoAcceptSettings = (service_provider_uuid) => {
	const url = `/api/v1/service_providers/${service_provider_uuid}/auto_accept`;

	return axios.get(url)
		.then(response => {
			console.log('Got SP auto accept settings', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get SP auto accept settings', error.response.data);
			throw error;
		});
};

export const getSPAutoAcceptSetting = (service_provider_uuid, formType) => {
	const url = `/api/v1/service_providers/${service_provider_uuid}/auto_accept/${formType}`;

	return axios.get(url)
		.then(response => {
			console.log('Got SP auto accept setting', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get SP auto accept setting', error.response.data);
			throw error;
		});
};

export const updateSPAutoAcceptSetting = (service_provider_uuid, formType, payload) => {
	const url = `/api/v1/service_providers/${service_provider_uuid}/auto_accept/${formType}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated SP auto accept setting', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t update SP auto accept setting', error.response.data);
			throw error;
		});
};

export const removeSPAutoAcceptSetting = (service_provider_uuid, formType) => {
	const url = `/api/v1/service_providers/${service_provider_uuid}/auto_accept/${formType}`;

	return axios.delete(url)
		.then(response => {
			console.log('Deleted SP auto accept setting', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t delete SP auto accept setting', error.response.data);
			throw error;
		});
};