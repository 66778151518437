/** @type {formField[]} */
export const BasicOutfallFields = [
    { id: "name", label: "Name", required: true },
    { id: "id", label: "ID", required: true },
    {
        id: "subtype",
        label: "Effluent Type",
        suggestions: ["End of pipe", "End of process"],
    },
    { id: "size", label: "Pipe Diameter" },
    { id: "latitude", label: "Latitude" },
    { id: "longitude", label: "Longitude" },
    { id: "notes", label: "Notes", inputType: "textarea" },
    { id: "active", label: "Active", inputType: "checkbox" },
];
