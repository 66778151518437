import { TOGGLE_SIDEBAR } from "../actions/sidebarActions";

const initialState = { open: true };

const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return { ...state, open: !state.open };

        default:
            return state;
    }
};

export default sidebarReducer;
