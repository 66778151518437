import { LicenseManager } from "ag-grid-enterprise";

// Initialize AG Grid
export const initializeAgGrid = () => {
    const licenseKey = window.__INITIAL_CONFIG__?.AG_GRID_LICENSE_KEY;
    if (licenseKey) {
        LicenseManager.setLicenseKey(licenseKey);
    } else {
        console.warn("AG Grid license key not found");
    }
};

// Default grid configuration
export const defaultGridConfig = {
    theme: "ag-theme-material",
    defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
    },
};
