import React from "react";
import { getURLModule, getURLBaseSection } from "../../../utils/url";
import Home from "../../Common/Home/Home";
import AdvancedReportTable from "../../Common/ReportTable/AdvancedReportTable";

const LocationsHome = () => {
    const { slug } = getURLModule();
    const baseSection = getURLBaseSection().section;

    return (
        <Home addNewForm="addLocation">
            <AdvancedReportTable
                reportUUID="0eba7876-ec72-465c-919f-35138aefa6c3"
                defaultColumns={[
                    "Account Number",
                    "Name",
                    "Address",
                    "Phone",
                    "Device Count",
                    "Survey Compliance",
                    "Survey Expiration",
                    "Primary Contact",
                    "Contact Phone",
                    "Contact Email",
                ]}
                rowLinks={(row) => `/${slug}/${baseSection}/${row["Property UUID"]}`}
            />
        </Home>
    );
};

export default LocationsHome;
