import axios from 'axios';

export const getBillingSyncStatus = () => {
    const url = '/api/v1/billing_sync/status';

    return axios.get(url)
        .then(response => {
            console.log('Got Billing Sync Status', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t get billing sync status', error.response.data);
            throw error;
        });
};

export const approveSyncPlan = () => {
    const url = '/api/v1/billing_sync/approve';

    return axios.post(url)
        .then(response => {
            console.log('Approved Sync Plan', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t approve sync plan', error.response.data);
            throw error;
        });
};

export const uploadCSV = (payload) => {
    const url = '/service/billing_sync/upload';

    return axios.post(url, payload)
        .then(response => {
            console.log('Uploaded CSV', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t upload CSV', error.response.data);
            throw error;
        });
};

export const downloadSyncPlan = () => {
    const url = '/service/billing_sync/export';

    return axios.post(url, { headers: { 'responseType': 'blob' } })
        .then(response => {
            console.log('Downloaded sync plan', response);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export.zip');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(error => {
            console.log('Couldn\'t download sync plan', error.response.data);
            throw error;
        });
};

