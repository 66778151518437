import React from 'react';
import { getPropertyContactListReport, createPropertyContact, updatePropertyContact, removePropertyContact } from '../../api/propertiesAPI';
import { getContact, createContact } from '../../api/contactsAPI';
import Table from '../Table';
import Flyout from '../Flyout';
import { getDefaultReportingParams, setReportingParams } from '../../utils/reporting';
import LoaderOverlay from '../LoaderOverlay';

class LocationContacts extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			locationContactsList: [],
			listDisplayColumns: [
				{
					dataName: "contact_type",
					displayName: "Type"
				},
				{
					dataName: ['firstname', 'lastname'],
					displayName: "Name"
				},
				{
					dataName: "email",
					displayName: "Email"
				},
				{
					dataName: "phone",
					displayName: "Phone"
				},
				{
					dataName: "contact_uuid",
					displayName: "UUID",
					display: false
				},
				{
					dataName: "firstname",
					displayName: "firstname",
					display: false
				},
				{
					dataName: "lastname",
					displayName: "lastname",
					display: false
				}
			],
			flyoutActive: false,
			contactInfo: {},
			newContact: false,
			reportingParams: getDefaultReportingParams(),
		};
	}

	componentDidMount() {
		this.getContactsList(true);
	}

	getContactsList = (onload, reportingParams) => {
		const { uuid } = this.props;

		if (reportingParams === undefined) {
			reportingParams = this.state.reportingParams;
		}
		reportingParams.inputs = { "Property UUID": uuid };

		getPropertyContactListReport(reportingParams).then(response => {
			if (response.nextPage !== undefined && response.nextPage > reportingParams.page) {
				reportingParams.nextPage = response.nextPage;
			}
			this.setState(() => ({ locationContactsList: response.data, reportingParams }));
		});

		// since we refreshed this list, we probably updated or added something, so refresh the main table to get
		// updated contacts
		if (!onload) {
			this.props.refresh();
		}
	}

	openFlyout = (contactInfo, newContact) => {
		const overlay = document.getElementById('overlaySecondary');

		if (newContact) {
			this.setState(() => ({ newContact: true, contactInfo: {} }));
		} else {
			if (contactInfo.values) {
				contactInfo = {
					contact_uuid: contactInfo.values[0]
				};
			}

			if (this.props.flyoutPriority === 'secondary') {
				// we are already in a second flyout and that is our limit, so instead pop them open to a new tab?
				window.open("/contacts/" + contactInfo.contact_uuid, "_blank");
				return;
			}

			this.setState(() => ({ newContact: false, contactInfo }));
		}
		this.setState(() => ({ flyoutActive: true }), () => {
			overlay.classList.add('overlaySecondary__visible');
		});
	}

	closeFlyout = () => {
		const flyoutSecondary = document.getElementById('flyoutSecondary');
		const overlay = document.getElementById('overlaySecondary');

		flyoutSecondary.classList.remove('slideToVisible');
		overlay.classList.remove('overlaySecondary__visible');
		setTimeout(() => {
			this.setState(() => ({ flyoutActive: false }));
		}, 200);
	}

	handleUpdate = (contact, payload) => {
		return updatePropertyContact(this.props.uuid, contact.contact_uuid, payload).then(response => {
			this.props.refresh(false);
			this.getContactsList();
			return Promise.resolve(response);
		})
	}

	handleSave = (contact, payload) => {
		return createPropertyContact(this.props.uuid, contact.contact_uuid, payload).then(response => {
			this.props.refresh(false);
			return Promise.resolve(response);
		});
	}

	handleUnlink = (user) => {
		if (confirm('Are you sure you want to remove this Contact?')) {
			removePropertyContact(this.props.uuid, user.contact_uuid).then(() => {
				this.props.refresh(false);
				this.getContactsList();
				this.closeFlyout();
			});
		}
	}

	// -----------------------------
	// table / reporting functions
	// -----------------------------
	setOrdering = (column) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { column }, false);
		this.getContactsList(true, newReportingParams);
	}

	fetchData = (direction) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { direction }, false);
		if (newReportingParams !== undefined) {
			this.getContactsList(true, newReportingParams);
		}
	}
	// END table / reporting functions

	render() {
		const { flyoutActive, newContact, contactInfo } = this.state;

		return (
			<div className="flyoutContentContainer">
				{this.state.locationContactsList.length !== 0 ?
					<React.Fragment>
						<Table
							tableData={this.state.locationContactsList}
							tableType='Location Contacts'
							tableSize='small'
							columns={this.state.listDisplayColumns}
							openFlyout={this.openFlyout}
							fetchData={this.fetchData}
							setOrdering={this.setOrdering}
							paginationParams={this.state.reportingParams}
						/>
						{this.props.flyoutPriority !== 'secondary' && (
							<div className="flexButtonContainer">
								<button className="medButtonPrimary" onClick={() => this.openFlyout(null, true)}>Add Contact</button>
							</div>
						)}
					</React.Fragment> : <LoaderOverlay />
				}
				<div className="overlaySecondary" id="overlaySecondary" onClick={() => { this.closeFlyout() }}></div>
				{flyoutActive && (contactInfo.contact_uuid || newContact) && (
					<React.Fragment>
						<Flyout
							uuid={contactInfo.contact_uuid}
							source='property'
							sourceUUID={this.props.uuid}
							newAddition={newContact}

							flyoutType='contacts'
							flyoutPriority='secondary'
							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							activeTab='contactDetails'

							create={this.handleSave}
							update={this.handleUpdate}
							unlink={this.handleUnlink}
							refresh={this.getContactsList}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default LocationContacts;