import React, { useEffect, useState } from "react";
import { getURLParentObject } from "../../../utils/url";
import { getSampleReport, getSampleReportEvent } from "../../../api/sampleReportsAPI";
import { BasicSampleReportFields } from "./BasicSampleReportFields";
import { formatDate, timestampToDate } from "../../../utils/forms";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import SummaryGrid from "../../Common/Forms/SummaryGrid";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-material.css";
import { getReportSync } from "../../../api/reportingAPI";
import {
    transformReportToGridData,
    createColumnDefsFromReport,
    transformSampleResultsToDetailData,
} from "../../../utils/agGridUtils";
import PostAddIcon from "@mui/icons-material/PostAdd";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import styles from "../../../styles/common/agGrid.module.css";
import { useHistory } from "react-router-dom";

const ViewSampleReport = () => {
    const history = useHistory();
    const urlObject = getURLParentObject("sr");
    console.log("URL Object:", urlObject);
    const { id: sampleReportId } = urlObject || {};
    console.log("Sample Report ID:", sampleReportId);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState({});
    const [eventsData, setEventsData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

    const IconCellRenderer = (params) => {
        const handleClick = (e) => {
            e.stopPropagation();
            const sampleReportEventId = params.data["Sample Report Event ID"];

            history.push(`${urlObject.path}/AddSampleReportEventData/${sampleReportEventId}`);
        };

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}
                onClick={handleClick}
            >
                <PostAddIcon style={{ cursor: "pointer" }} sx={{ fontSize: 22 }} />
            </div>
        );
    };

    // Add custom cell renderer for status
    const StatusCellRenderer = (params) => {
        let color;
        switch (params.value) {
            case "Complete":
                color = "var(--complete)";
                break;
            case "Partial":
                color = "var(--partial)";
                break;
            case "No Data":
                color = "var(--no-data)";
                break;
            default:
                color = "var(--no-data)";
        }

        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    color: color,
                }}
            >
                <FiberManualRecordIcon
                    sx={{
                        fontSize: 12,
                        color: color,
                    }}
                />
                <span>{params.value}</span>
            </div>
        );
    };

    // Fetch basic sample report data for summary box
    useEffect(() => {
        if (!sampleReportId) {
            setError(new Error("No sample report ID provided"));
            return;
        }

        setLoading(true);
        setError(null);

        getSampleReport(sampleReportId)
            .then((data) => {
                const transformedData = {
                    ...data,
                    last_modified_by: data.last_modified_by
                        ? `${data.last_modified_by.firstname} ${data.last_modified_by.lastname}`
                        : "-",
                    last_modified_on: data.last_modified_on ? formatDate(timestampToDate(data.last_modified_on)) : "-",
                    due_date: data.due_date ? formatDate(timestampToDate(data.due_date)) : "-",
                    report_schedule: "-",
                };
                setReportData(transformedData);
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }, [sampleReportId]);

    // Fetch sample report data
    useEffect(() => {
        if (sampleReportId) {
            const reportUUID = "b329a59e-1381-4767-8f6a-9798089c5420";

            const payload = {
                count: 100,
                inputs: {
                    active: "true",
                },
            };

            getReportSync(reportUUID, payload)
                .then((response) => {
                    const gridData = transformReportToGridData(response.data);
                    const columns = createColumnDefsFromReport(response.data.outputs);

                    const newColumns = [
                        {
                            width: 30,
                            headerName: "",
                            field: "expandControl",
                            cellRenderer: "agGroupCellRenderer",
                            cellRendererParams: {
                                suppressCount: true,
                                suppressDoubleClickExpand: true,
                            },
                            lockPosition: true,
                            suppressMovable: true,
                            suppressMenu: true,
                            sortable: false,
                            headerClass: "no-select-header",
                            suppressHeaderKeyboardEvent: () => true,
                        },
                    ];

                    // Find and modify the status column
                    const statusColumnIndex = columns.findIndex((col) => col.field === "Status");
                    if (statusColumnIndex !== -1) {
                        const statusColumn = {
                            ...columns[statusColumnIndex],
                            cellRenderer: StatusCellRenderer,
                            lockPosition: true,
                            suppressMovable: true,
                            width: 150,
                        };
                        newColumns.push(statusColumn);
                    }

                    // Add the rest of the columns
                    newColumns.push(...columns.filter((col) => col.field !== "Status"));

                    // Modify the edit column
                    newColumns.push({
                        headerName: "",
                        field: "edit",
                        pinned: "right",
                        cellRenderer: IconCellRenderer,
                        width: 50,
                        suppressMenu: true,
                        sortable: false,
                        filter: false,
                        suppressSizeToFit: true,
                        cellStyle: { padding: 0 },
                        headerClass: "no-select-header",
                        suppressHeaderKeyboardEvent: () => true,
                    });

                    setEventsData(gridData);
                    setColumnDefs(newColumns);
                })
                .catch((error) => {
                    console.error("Error fetching sample events:", error);
                    setError(error);
                });
        }
    }, [sampleReportId]);

    // Detail grid options
    const detailGridOptions = {
        columnDefs: [
            { field: "analyte", headerName: "Analyte" },
            { field: "result", headerName: "Result Value" },
            { field: "nd", headerName: "ND (Y/N)" },
            { field: "mdl", headerName: "MDL Value" },
            { field: "mrl", headerName: "MRL Value" },
            { field: "sample_type", headerName: "Sample Type" },
            { field: "sample_start", headerName: "Sample Start Date/Time" },
            { field: "sample_end", headerName: "Sample End Date/Time" },
            { field: "qualifications", headerName: "Qualifications" },
            { field: "lab_method", headerName: "Lab Method" },
        ],
        defaultColDef: {
            sortable: true,
            filter: false,
            resizable: true,
            suppressMenu: true,
            suppressSizeToFit: false,
            width: undefined,
            headerClass: "detail-grid-header",
        },
        getRowStyle: () => ({
            background: "var(--bg-2)",
        }),
    };

    // Get detail row data
    const getDetailRowData = (params) => {
        const sampleReportEventId = params.data["Sample Report Event ID"];

        getSampleReportEvent(sampleReportEventId)
            .then((response) => {
                console.log("Sample Report Event Data:", response);
                const detailData = transformSampleResultsToDetailData(response.sample_report_results);
                params.successCallback(detailData);
            })
            .catch((error) => {
                console.error("Error fetching sample report event:", error);
                params.successCallback([]);
            });
    };

    // Grid options
    const gridOptions = {
        defaultColDef: {
            sortable: true,
            filter: false,
            suppressMenu: true,
            suppressClickEdit: true,
            resizable: true,
            suppressSizeToFit: false,
            width: undefined,
        },
        rowStyle: { cursor: "default" },
        suppressCellFocus: true,
        suppressRowClickSelection: true,
        rowHoverClass: "ag-row-hover",
        // Master/Detail configuration
        masterDetail: true,
        detailRowHeight: 300,
        detailCellRendererParams: {
            detailGridOptions: detailGridOptions,
            getDetailRowData: getDetailRowData,
        },
    };

    return (
        <Page loading={loading} error={error}>
            <Card title="Sample Report">
                <style>{`
                    .no-select-header {
                        pointer-events: none !important;
                        background-color: var(--bg-1) !important;
                    }
                    .no-select-header:hover {
                        background-color: var(--bg-1) !important;
                        cursor: default !important;
                    }
                    .detail-grid-header {
                        background-color: var(--bg-2) !important;
                    }
                `}</style>
                <SummaryGrid
                    fields={BasicSampleReportFields}
                    formState={reportData}
                    separateFields={["report_schedule"]}
                />
            </Card>
            <Card title="Sampling Events">
                <div
                    className={`ag-theme-material`}
                    style={{
                        height: "500px",
                        width: "100%",
                        marginTop: "1rem",
                    }}
                >
                    <AgGridReact rowData={eventsData} columnDefs={columnDefs} {...gridOptions} />
                </div>
            </Card>
        </Page>
    );
};

export default ViewSampleReport;
