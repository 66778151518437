import axios from 'axios';

export const getUserNotifications = () => {
    const url = '/api/v1/notifications/users';

    return axios.get(url)
        .then(response => {
            console.log('Got User Notifications List', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Get User Notifications List', error.response.data);
            throw error;
        });
};

export const getOrgNotifications = () => {
    const url = '/api/v1/notifications/organizations';

    return axios.get(url)
        .then(response => {
            console.log('Got Org Notifications List', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Get Org Notifications List', error.response.data);
            throw error;
        });
};

export const markNotificationAsRead = (uuid, type) => {
    const url = '/api/v1/notifications/users';
    let payload = {};
    payload["notification_uuid"] = uuid;
    payload["notification_type"] = type;

    return axios.put(url, payload)
        .then(response => {
            console.log('Marked Notification As Read', response);
            return response.data;
        })
        .catch(error => {
            console.log('Couldn\'t Mark Notification As Read', error.response.data);
            throw error;
        });
};