import React, {useState} from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { default as MuiTooltip } from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';

export const Tooltip = ({ text, position, noIcon, useIcon, html, error, autoOpen }) => {

	const [open, setOpen] = useState(error || autoOpen ? true : undefined);

	const TooltipTheme = withStyles(theme => (
		error ? {
			tooltip: {
				lineHeight: '1.5rem',
				background: '#FF005C',
				boxShadow: '2px 3px 2px rgba(0, 0, 0, 0.1)',
				opacity: '80%'
			},
			arrow: {
				color: '#FF005C',
				fontSize: 11
			}
		} :
			{
				tooltip: {
					lineHeight: '1.5rem',
					background: '#7689A3',
					boxShadow: '2px 3px 2px rgba(0, 0, 0, 0.1)',
					opacity: '95%',
					pointerEvents: 'auto'
				},
				arrow: {
					color: '#7689A3',
					fontSize: 11
				}
	}))(MuiTooltip);

	const handleClose = () => {
        setOpen(undefined);
    };

	const titleContent = (
		<>
			<span className="tooltip__text">
				{html ? text : text.split("\\n").map((line, idx) => <p key={idx}>{line}</p>)}
			</span>
			{autoOpen && open && <button onClick={() => handleClose()} className="tooltip__close">
			<CloseIcon fontSize="large" />
			</button>}
		</>
	);

	

	return (
		<TooltipTheme
			title={titleContent}
			placement={position ? position : 'top'}
			TransitionComponent={Zoom}
			arrow
			enterDelay={noIcon ? 700 : 0}
			open={open}
			

		>
			<span className={noIcon && !error ? 'tooltip-Overlay' : 'tooltip'}>
				{noIcon ? null : useIcon ? useIcon : <InfoOutlinedIcon />}
			</span>
		</TooltipTheme>
	);
};


