import React from 'react';
import { getBackflowSurveysReport } from '../../api/complianceAPI';
import Table from '../Table';
import Flyout from '../Flyout';
import { getReportValue } from '../../utils/reporting';
import { getDefaultReportingParams, setReportingParams } from '../../utils/reporting';
import LoaderOverlay from '../LoaderOverlay';

class LocationSurveyHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reportList: null,
            tableColumns: ['Result', 'Status', 'Reported On'],
            flyoutActive: false,
            complianceReportInfo: null,
            reportingParams: getDefaultReportingParams(),
        };
    }

    async componentDidMount() {
        this.getLocationSurveyList();
    }

    openFlyout = (complianceReportInfo) => {
        if (this.props.flyoutPriority === 'secondary') {
            // we are already in a second flyout and that is our limit, so instead pop them open to a new tab?
            window.open("/surveys/" + getReportValue(this.state.reportList, complianceReportInfo, "Compliance Report UUID"), "_blank");
            return;
        }

        const overlay = document.getElementById('overlaySecondary');

        if (complianceReportInfo.values) {
            complianceReportInfo = {
                compliance_report_uuid: complianceReportInfo.values[0]
            };
        }

        this.setState({ complianceReportInfo, flyoutActive: true }, () => {
            overlay.classList.add('overlaySecondary__visible');
        });
    }

    closeFlyout = () => {
        const flyoutSecondary = document.getElementById('flyoutSecondary');
        const overlay = document.getElementById('overlaySecondary');

        flyoutSecondary.classList.remove('slideToVisible');
        overlay.classList.remove('overlaySecondary__visible');
        setTimeout(() => {
            this.setState(() => ({ flyoutActive: false }));
        }, 200);
    }

    getLocationSurveyList = (reportingParams) => {
        const { uuid } = this.props;

        if (reportingParams === undefined) {
            reportingParams = this.state.reportingParams;
        }
        reportingParams.inputs = { "Property UUID": uuid };

        getBackflowSurveysReport(reportingParams).then(response => {
            if (response.nextPage !== undefined && response.nextPage > reportingParams.page) {
                reportingParams.nextPage = response.nextPage;
            }
            this.setState(() => ({ reportList: response.data, reportingParams }));
        });
    }

    // -----------------------------
    // table / reporting functions
    // -----------------------------
    setOrdering = (column) => {
        let newReportingParams = setReportingParams(this.state.reportingParams, { column }, false);
        this.getLocationSurveyList(newReportingParams);
    }

    fetchData = (direction) => {
        let newReportingParams = setReportingParams(this.state.reportingParams, { direction }, false);
        if (newReportingParams !== undefined) {
            this.getLocationSurveyList(newReportingParams);
        }
    }
    // END table / reporting functions

    render() {
        const { uuid } = this.props;
        const { reportList, tableColumns, flyoutActive, complianceReportInfo } = this.state;

        return (
            <div className="flyoutContentContainer">
                {reportList !== null ?
                    <React.Fragment>
                        <Table
                            tableType='Surveys'
                            tableData={reportList}
                            tableSize='small'
                            openFlyout={this.openFlyout}
                            columnsToShow={tableColumns}
                            fetchData={this.fetchData}
                            setOrdering={this.setOrdering}
                            paginationParams={this.state.reportingParams}
                        />
                        <div className="flexButtonContainer">
                            <button
                                onClick={() => {
                                    window.location.replace('/surveys/newSurvey?location=' + uuid);
                                }}
                                className="medButtonPrimary"
                            >
                                Add Survey
							</button>
                        </div>
                    </React.Fragment> : <LoaderOverlay />
                }
                <div className="overlaySecondary" id="overlaySecondary" onClick={() => { this.closeFlyout() }}></div>
                {flyoutActive && complianceReportInfo && (
                    <React.Fragment>
                        <Flyout
                            uuid={complianceReportInfo.compliance_report_uuid}
                            source='location'
                            sourceUUID={this.props.uuid}

                            flyoutType='surveys'
                            flyoutPriority='secondary'
                            closeFlyout={this.closeFlyout}
                            openFlyout={this.openFlyout}
                            activeTab='testReportDetails'

                            refresh={this.getLocationSurveyList}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default LocationSurveyHistory;