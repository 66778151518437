import React, { useState } from "react";

import { Tooltip } from "../Tooltip";

import {
    CircularProgress,
    ClickAwayListener,
    FormControl,
    Select,
    InputLabel,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";

function DropDown({ toggleDropdown, onExport, details }) {
    const [exportType, setExportType] = useState(details ? "std+vis" : "all");

    return (
        <div className="tableFilter__dropdownContent-wrapper">
            <div className="tableFilter__dropdownContent">
                <div className="tableFilter__content">
                    <div className="sectionHeader noBorder">
                        <FormControl variant="outlined">
                            <InputLabel>Export Columns</InputLabel>
                            <Select
                                native
                                onChange={(e) => setExportType(e.target.value)}
                                label="Export Columns"
                                value={exportType}
                            >
                                <option value="all">All</option>
                                {details && (
                                    <option value="std+vis">
                                        All Standard + Visible Details
                                    </option>
                                )}
                                <option value="vis">Only Visible</option>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="tableFilter__buttons">
                    <button
                        className="smallButtonPrimary"
                        onClick={() => onExport(exportType)}
                    >
                        Export
                    </button>
                    <button
                        className="smallButtonSecondary"
                        onClick={() => toggleDropdown("close")}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default function ExportOptions({
    disabled,
    handleDownload,
    isDownloading,
    details,
}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    function toggleDropdown(type) {
        const picker = document.getElementsByClassName("MuiPopover-root");
        if (type === "close") {
            if (picker.length) {
                return null;
            } else {
                setDropdownOpen(false);
            }
        } else {
            setDropdownOpen(!dropdownOpen);
        }
    }

    return (
        <div id="tableOptions">
            <ClickAwayListener onClickAway={() => toggleDropdown("close")}>
                <div
                    className={
                        dropdownOpen
                            ? "tableFilter__wrapper openBorderRadius slideDown"
                            : "tableFilter__wrapper"
                    }
                >
                    <div className="tableFilter">
                        <button
                            data-testid="export"
                            className="filterButton"
                            disabled={disabled}
                            onClick={toggleDropdown}
                        >
                            {!dropdownOpen && (
                                <Tooltip
                                    text="Export to CSV"
                                    noIcon={true}
                                    position="top"
                                />
                            )}
                            {isDownloading ? (
                                <CircularProgress size="2rem" />
                            ) : (
                                <GetAppIcon fontSize="inherit" />
                            )}
                        </button>
                    </div>
                    {dropdownOpen && (
                        <DropDown
                            toggleDropdown={toggleDropdown}
                            onExport={handleDownload}
                            details={details}
                        />
                    )}
                </div>
            </ClickAwayListener>
        </div>
    );
}
