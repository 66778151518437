import React, { useEffect, useState } from "react";
import { getURLParentObject } from "../../../utils/url";
import { getSamplingConfig } from "../../../api/analytesAPI";
import { getReportSync } from "../../../api/reportingAPI";
import { BasicSamplingConfigFields } from "./BasicSamplingConfigFields";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import SummaryGrid from "../../Common/Forms/SummaryGrid";
import EditSamplingConfig from "./EditSamplingConfig";
import useAnalytes from "../useAnalytes";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Divider from "@mui/material/Divider";

export const viewSamplingConfigFields = [
    {
        id: "name",
        label: "Configuration Name",
    },
    {
        id: "sample_schedule",
        label: "Sample Schedule",
    },
    {
        id: "sampling_period",
        label: "Sampling Period",
    },
    {
        id: "administrative_period",
        label: "Administrative Period",
    },
];

const ViewSamplingConfig = () => {
    const { id: samplingConfigId } = getURLParentObject("sc") || {};
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [samplingConfigData, setSamplingConfigData] = useState({});
    const [specifications, setSpecifications] = useState(null);
    const { analytes } = useAnalytes();

    const transformSpecificationsData = (specsResponse) => {
        if (!specsResponse?.data?.rows || !specsResponse?.data?.outputs) {
            return [];
        }

        return specsResponse.data.rows.map((row) => {
            const getValue = (fieldName) => {
                const index = specsResponse.data.outputs.findIndex((output) => output.name === fieldName);
                const value = index !== -1 ? row.values[index] : null;
                return value || "-";
            };

            // Convert string values to arrays
            const analytes = getValue("Analyte(s)/Parameter(s)");
            const labMethods = getValue("Lab Method(s)");
            const containerTypes = getValue("Container Types");
            const containerVolumes = getValue("Container Volumes");
            const preservatives = getValue("Preservatives");

            return {
                name: getValue("Name") || "-",
                analytes: analytes === "-" ? ["-"] : analytes.split(";").map((a) => a.trim()),
                sample_type: getValue("Sample Type") || "-",
                pacing: getValue("Pacing") || "-",
                pacing_value: getValue("Pacing Value") || "-",
                pacing_unit: getValue("Pacing Unit") || "-",
                lab_methods: labMethods === "-" ? ["-"] : labMethods.split(";").map((m) => m.trim()),
                min_aliquots: getValue("Min Aliquots") || "-",
                container_types: containerTypes === "-" ? ["-"] : containerTypes.split(";").map((t) => t.trim()),
                container_volumes: containerVolumes === "-" ? ["-"] : containerVolumes.split(";").map((v) => v.trim()),
                preservatives: preservatives === "-" ? ["-"] : preservatives.split(";").map((p) => p.trim()),
                max_hold_time: getValue("Max Hold Time") || "-",
                active_sampling_hours: getValue("Active Sampling Hours") || "-",
                notes: getValue("Notes") || "-",
            };
        });
    };

    const fetchSpecifications = async () => {
        if (!samplingConfigId) return;

        try {
            const specsResponse = await getReportSync(
                "52aeec22-77fa-45fa-9c15-3d497a4bd096",
                {
                    inputs: {
                        "Sampling Config ID": samplingConfigId,
                    },
                },
                false
            );

            console.log("Specifications Report Response:", specsResponse);
            const transformedSpecs = transformSpecificationsData(specsResponse);
            setSpecifications(transformedSpecs);
        } catch (error) {
            console.error("Error fetching specifications:", error);
            setSpecifications([]);
        }
    };

    const refreshSamplingConfig = async () => {
        setLoading(true);
        setError(null);

        try {
            const data = await getSamplingConfig(samplingConfigId);
            console.log("Sampling config data from API:", data);

            let scheduleText = "-";
            if (data.sample_schedule?.frequency) {
                scheduleText =
                    data.sample_schedule.frequency.charAt(0).toUpperCase() + data.sample_schedule.frequency.slice(1);

                if (data.sample_schedule.stop_after) {
                    scheduleText = `${scheduleText} and stop after ${data.sample_schedule.stop_after} occurrences`;
                }
            }

            setSamplingConfigData({
                ...data,
                name: data.name || "-",
                sampling_period: data.sampling_period || "-",
                administrative_period: data.administrative_period || "-",
                sample_schedule: scheduleText,
            });

            // Fetch specifications after getting sampling config
            await fetchSpecifications();
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        refreshSamplingConfig();
    }, []);

    const onEditComplete = async () => {
        setEditMode(false);
        await refreshSamplingConfig();
    };

    if (editMode && !loading && !error) {
        const editableFields = { ...samplingConfigData };

        for (const key in editableFields) {
            if (key.includes("interval")) {
                editableFields[key] = intervalToFormField(editableFields[key]);
            }
        }

        return (
            <EditSamplingConfig
                preloadedBasicState={editableFields}
                onEditComplete={onEditComplete}
                onEditCancelled={() => setEditMode(false)}
            />
        );
    }

    const summaryData = viewSamplingConfigFields.map((fieldInfo) => ({
        ...fieldInfo,
        value: samplingConfigData[fieldInfo.id] ?? "",
    }));

    const renderSpecifications = () => {
        if (!specifications) return "Loading specifications...";
        if (specifications.length === 0) return "No specifications found";

        return specifications.map((spec, index) => (
            <Accordion
                key={index}
                square={true}
                sx={{
                    margin: "2rem 0rem !important",
                    boxShadow: "none",
                    border: "1px solid lightgrey",
                    borderRadius: "0.5rem",
                }}
            >
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon fontSize="large" />}
                    aria-controls={`spec-panel-${index}-content`}
                    id={`spec-panel-${index}-header`}
                    sx={{
                        fontSize: "2rem",
                        flexDirection: "row-reverse",
                        padding: "1rem",
                        width: "100%",
                        "& .MuiAccordionSummary-expandIconWrapper": {
                            margin: "1.5rem",
                        },
                        "& p": {
                            fontSize: "1.8rem",
                        },
                    }}
                >
                    {spec.name}
                </AccordionSummary>
                <Divider variant="middle" />
                <AccordionDetails
                    sx={{
                        padding: "2rem!important",
                    }}
                >
                    <SummaryGrid fields={BasicSamplingConfigFields} formState={spec} />
                </AccordionDetails>
            </Accordion>
        ));
    };

    return (
        <Page loading={loading || !analytes} error={error}>
            <Card title="Sampling Configuration Summary">
                <SummaryGrid
                    fields={summaryData.map((field) => ({
                        id: field.id,
                        label: field.label,
                        inputType: "text",
                    }))}
                    formState={summaryData.reduce((acc, field) => {
                        acc[field.id] = field.value;
                        return acc;
                    }, {})}
                />
            </Card>

            <Card title="Sampling Specifications">{renderSpecifications()}</Card>
        </Page>
    );
};

export default ViewSamplingConfig;
