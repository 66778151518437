/* eslint-disable react/prop-types */
import React from "react";
import { withRouter } from "react-router";
import { listOrganizations } from "../api/organizationAPI";
import { logout } from "../api/authAPI";
import orgLogo from "../../images/SwiftComplyRetina.png";
import headerLine from "../../images/headerLine.svg";
import dropdown from "../../images/dropdown.svg";
import { checkForPrice } from "../api/paymentsAPI";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";

// orgs that require users to login, likely via SSO, so we don't want them in the quick switcher
// if we get many more of these a better solution will be needed, likely an API call specific
// to this feature, but for now this is easiest
const RemoveOrgsFromDropdown = "ebda7a2c-fb5a-4772-a3fb-8df6339a675a";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            organizationData: "",
            organizations: [],
            userData: "",
            sidebarCollapsed: false,
            userDropdown: false,
            orgsDropdown: false,
            showPaymentButton: false,
        };
    }

    async componentDidMount() {
        if (this.props.location.pathname === "/") {
            null;
        } else {
            const organizationData = SwiftComply.organization;
            const userData = SwiftComply.user;
            let isPrice = { price: 0 };
            if (userData.user_type == "service provider") {
                isPrice = await checkForPrice("Backflow Assembly Test");
            }
            const orgs = await listOrganizations(true);
            const filteredOrgs = orgs.organizations.filter(
                (org) =>
                    org.organization_uuid == organizationData.organization_uuid ||
                    !RemoveOrgsFromDropdown.includes(org.organization_uuid)
            );
            this.setState(() => ({
                organizationData,
                organizations: filteredOrgs,
                userData,
                userType: userData.user_type,
                showPaymentButton: isPrice.price !== "0",
            }));
        }
    }

    logout = () => {
        logout();
    };

    selectOption = (type) => {
        if (type === "profile") {
            this.toggleDropdown(null, true);
            this.props.history.push("/profile");
        } else if (type === "payments") {
            this.toggleDropdown(null, true);
            this.props.history.push("/payments");
        } else if (type === "help") {
            setTimeout(() => {
                this.toggleDropdown(null, true);
            }, 300);
        }
    };

    toggleDropdown = (type, close) => {
        const { organizations } = this.state;
        if (close) {
            this.setState(() => ({ userDropdown: false, orgsDropdown: false }));
        }

        if (type === "orgs") {
            if (organizations.length > 1) {
                this.setState((prevState) => ({ orgsDropdown: !prevState.orgsDropdown }));
            }
        } else if (type === "user") {
            this.setState((prevState) => ({ userDropdown: !prevState.userDropdown }));
        }
    };

    routeToOrg = (host) => {
        const path = window.location.pathname;
        const filteredPath = path.split("/")[1];
        window.location.href = `https://${host}.c3swift.com/${filteredPath}`;
    };

    render() {
        const { organizationData, userData, showPaymentButton, orgsDropdown, userDropdown, organizations } = this.state;
        const firstName = userData.firstname;
        const lastName = userData.lastname;

        const setOrgsDropdown = () => {
            const { organizations, organizationData } = this.state;
            const currentOrg = organizationData.organization_uuid;

            const filterAndSortOrgs = organizations
                .filter((org) => org.organization_uuid !== currentOrg)
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

            const orgsDropdown = filterAndSortOrgs.map((org) => (
                <button
                    key={org.organization_uuid}
                    value={org.organization_uuid}
                    onClick={() => this.routeToOrg(org.host)}
                >
                    {org.name}
                </button>
            ));

            return orgsDropdown;
        };

        let homeButtonLink = "/dashboard";
        if (userData.user_type === "service provider") {
            homeButtonLink = "/testReports";
        }

        if (this.props.location.pathname === "/") {
            return null;
        } else {
            return (
                <div className="header">
                    {userData && (
                        <React.Fragment>
                            <div className="header__content">
                                <div className="header__content-org-title">
                                    <div className="header__content-leftGroup">
                                        <img
                                            src={orgLogo}
                                            className="orgLogo"
                                            alt="orgLogo"
                                            onClick={() =>
                                                this.props.history.push(homeButtonLink, { organizationData })
                                            }
                                        />
                                        <span
                                            className="orgName"
                                            onClick={() => this.toggleDropdown("orgs")}
                                            style={
                                                organizations.length > 1 ? { cursor: "pointer" } : { cursor: "default" }
                                            }
                                        >
                                            {organizationData.name}
                                        </span>
                                        {organizations.length > 1 && (
                                            <img
                                                src={dropdown}
                                                alt="Open Org Switcher Dropdown"
                                                className="orgSwitcherArrow"
                                                onClick={() => this.toggleDropdown("orgs")}
                                            />
                                        )}
                                    </div>
                                </div>
                                {SwiftComply?.settings?.system_notice != "" && (
                                    <div>
                                        <span>{SwiftComply.settings.system_notice}</span>
                                    </div>
                                )}
                                {userData.user_type === "service provider" && showPaymentButton && (
                                    <div className="header__content-paymentsButton">
                                        <button
                                            className="medButtonPrimary"
                                            onClick={() => this.selectOption("payments")}
                                        >
                                            Make A Payment
                                        </button>
                                    </div>
                                )}
                                <div className="header__content-rightGroup">
                                    <ContactSupportOutlinedIcon className="helpIcon" id="intercomHelpIcon" />
                                    <img src={headerLine} alt="" />
                                    <div className="clickableGroup" onClick={() => this.toggleDropdown("user")}>
                                        <span>{`${firstName} ${lastName}`}</span>
                                        <div className="header__userIcon">
                                            <div className="header__userIcon-icon"></div>
                                            <span>{firstName.charAt(0)}</span>
                                        </div>
                                        <img src={dropdown} alt="" />
                                    </div>
                                </div>
                            </div>
                            {userDropdown && (
                                <div className="header__dropdown user">
                                    {userData.user_type === "service provider" && showPaymentButton && (
                                        <button onClick={() => this.selectOption("payments")}>Make A Payment</button>
                                    )}
                                    <button onClick={() => this.selectOption("profile")}>Profile</button>
                                    <button onClick={() => this.selectOption("help")} id="intercomHelpIcon">
                                        Help
                                    </button>
                                    <button onClick={() => this.logout()}>Logout</button>
                                </div>
                            )}
                            {orgsDropdown && <div className="header__dropdown orgs">{setOrgsDropdown()}</div>}
                        </React.Fragment>
                    )}
                    {(userDropdown || orgsDropdown) && (
                        <span className="dropdown-overlay" onClick={() => this.toggleDropdown(null, true)}></span>
                    )}
                </div>
            );
        }
    }
}

export default withRouter(Header);
