import React, { useState } from "react";
import styles from "../../styles/common/downloadLink.module.css";
import axios from "axios";

/**
 * Renders a clickable link for the user to download a file from the API
 * - Note: a regular <a> element for downloading will not work because extra headers are needed
 *
 * @param url - link to the downloadable object
 * @param filename - downloaded file name
 * @param [children] - Text label of the link, same content as an <a> ... </a> element
 */
const DownloadLink = ({ url, filename, children }) => {
    const [downloading, setDownloading] = useState(false);

    const onClick = () => {
        setDownloading(true);

        axios
            .get(url, { responseType: 'blob' })
            .then((response) => {
                const link = document.createElement("a");
                const blob = new Blob([response.data]);
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .finally(() => setDownloading(false));
    };

    return (
        <span className={styles.link + (downloading ? ` ${styles.busy}` : "")} onClick={downloading ? null : onClick}>
            {downloading ? "Downloading..." : children}
        </span>
    );
};

export default DownloadLink;
