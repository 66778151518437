import {
    SET_USER_PREFERENCES,
} from "../actions/swiftComplyActions";

const initialState = {};

const swiftComplyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_PREFERENCES:
            // Extra check for reporting options - if the data looks invalid, reset it
            const key = "reporting options";
            let parsed = null;

            try {
                parsed = JSON.parse(action.data?.[key]);
            }
            catch {
                action.data[key] = "{}";
            }

            if (!parsed || typeof parsed !== 'object' || Array.isArray(parsed)) {
                action.data[key] = "{}";
            }

            return {
                ...state,
                user: {
                    ...state.user,
                    preferences: action.data,
                },
            };

        default:
            return state;
    }
};

export default swiftComplyReducer;
