import axios from 'axios';

export const getComplianceReportPDF = (compliance_report_uuid) => {
	const url = `/compliance_report_pdfs/${compliance_report_uuid}`;

	return axios.get(url, { params: { "with_images": true }, responseType: "blob" })
		.then(response => {
			console.log('Got compliance report PDF', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get compliance report PDF', error.response.data);
		});
}