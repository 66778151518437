import React, { useState, useEffect } from 'react';
import CommLogDetailsForm from './CommLogDetailsForm';
import editModeIcon from '../../../images/editMode.svg';
import {
    createCommLog, getCommLog, updateCommLog, deleteCommLog, rebuildCommLogInfo,
    getCommLogContacts, createCommLogContact, getComm, markCommLogSent, markCommLogUnsent
} from '../../api/communicationsAPI';
import { ModalDuplicateContext } from '../../contexts/ModalDuplicateContext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import { getReportSync } from '../../api/reportingAPI';
import { getReportValue } from '../../utils/reporting';
import CommsModal from './CommsModal';
import Flyout from '../Flyout';
import TestEmailButton from './TestEmailButton';
import { Tooltip } from '../Tooltip';
import queryString from 'query-string';

const CommLogDetails = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);
    const [data, setData] = useState({});
    const [commData, setCommData] = useState({});
    const [logContacts, setLogContacts] = useState(null);
    const [logContactInfo, setLogContactInfo] = useState(null);
    const [apiErrors, setApiErrors] = useState({});
    const [isNew, setIsNew] = useState(props.newAddition);
    const [formErrors, setFormErrors] = useState();
    const [flyoutActive, setFlyoutActive] = useState(false);
    const [commsList, setCommsList] = useState([]);
    const [rebuiltCooldown, setRebuiltCooldown] = useState(false);
    const [showEmailOptions, setShowEmailOptions] = useState(false);
    const [showLetterOptions, setShowLetterOptions] = useState(false);

    useEffect(() => {
        if (props.newAddition) {
            setEditMode(true);
            setCommData(props.data);
        } else {
            getCommLogData();
        }
    }, [props.uuid]);

    useEffect(() => {
        getCommsList();
    }, [])

    const getCommLogData = async () => {
        let logUUID;
        if (props.data.communication_log_uuid) {
            logUUID = props.data.communication_log_uuid;
        } else if (props.uuid) {
            logUUID = props.uuid;
        }
        const commLog = await getCommLog(logUUID);
        const commData = await getComm(commLog.communication_uuid);
        commLog.info = JSON.parse(commLog.info);
        props.setHeader('Communication Log');
        getLogContacts(commLog.communication_log_uuid);
        setData(commLog);
        setCommData(commData);
    };

    const getLogContacts = async (uuid) => {
        let commLogUUID;
        if (uuid) {
            commLogUUID = uuid;
        } else {
            commLogUUID = props.uuid;
        }
        const contacts = await getCommLogContacts(commLogUUID);
        let showEmail, showLetter = false;
        contacts.contacts.forEach((contact) => {
            contact['info'] = JSON.parse(contact['info']);
            if (logContactInfo && logContactInfo.contact_uuid === contact.contact_uuid) {
                setLogContactInfo(contact);
            }
            if (contact.method === 'email') {
                showEmail = true;
            }
            if (contact.method === 'letter' || contact.method === 'postcard') {
                showLetter = true;
            }
        })

        if (showEmail) {
            setShowEmailOptions(true);
        } else {
            setShowEmailOptions(false);
        }

        if (showLetter) {
            setShowLetterOptions(true);
        } else {
            setShowLetterOptions(false);
        }

        setLogContacts(contacts.contacts);

    };

    const getCommsList = async () => {
        // for new comms, we only want to let them pick published communications.  If it isn't new we need to pull all
        // of the comms in case this was created on an old template
        let commsReportPayload = { inputs: { status: "Published", }, count: 100 };
        if (!isNew) {
            commsReportPayload.inputs.status = "All";
        }
        const reportOutput = await getReportSync('9f160db4-7658-4cb2-b4e5-29c242f148e2', commsReportPayload);
        const commsList = [];
        reportOutput.data.rows.map((row, idx) => {
            let name = getReportValue(reportOutput.data, row, 'Name');
            let communication_uuid = getReportValue(reportOutput.data, row, 'Communication UUID');
            let contactMethods = getReportValue(reportOutput.data, row, 'Contact Methods');

            commsList.push({ name, communication_uuid, contactMethods });
        });
        setCommsList(commsList)
    }

    const prepEmail = async () => {
        let communication_type;

        if (props.data.communication_type) {
            communication_type = props.data.communication_type
        } else {
            commsList.forEach((comm) => {
                if (comm.communication_uuid === data.communication_uuid) {
                    communication_type = comm.name
                }
            })
        }

        await props.sendEmail(data.contact_uuid, communication_type, data.communication_log_uuid)
        setTimeout(() => {
            getCommLogData();
            props.refreshDashboard?.()
            props.refresh();
        }, [3000])
    }

    const saveDetails = async (ctx, uuid, payload) => {

        if (isNew) {
            saveNewCommLog(payload);
        } else {
            updateCommLog(uuid, payload)
                .then(() => {
                    if (props.source !== 'commLogContact') {
                        props.refresh();
                    }
                    setEditMode(false);
                })
                .catch(error => {
                    if (error.name === 'APIUserError') {
                        setApiErrors(error.apiFieldErrors);
                    }
                });
        }
    };

    const saveNewCommLog = (payload) => {
        createCommLog(payload)
            .then(response => {
                setEditMode(false);
                setIsNew(false);
                setData(response);
                response.info = JSON.parse(response.info);
                props.openFlyout(response, false, 'log');
                props.refresh();
            })
            .catch(error => {
                if (error.name === 'APIUserError') {
                    setApiErrors(error.apiFieldErrors);
                }
            });
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
        setDeleteMode(false);
    };

    const deleteThisCommLog = async (type) => {
        if (type === 'delete') {
            await deleteCommLog(data['communication_log_uuid']);
            props.closeFlyout();
            if (props.refreshDashboard) {
                props.refreshDashboard();
                props.closeSecondaryFlyout();
            } else {
                props.refresh();
            }
        } else if (type === 'cancel') {
            setDeleteMode(false);
        } else {
            setDeleteMode(true);
            setTimeout(() => {
                document.getElementById('flyoutBase').scrollIntoView(false);
            }, 100);
        }
    };

    const rebuildInfo = async () => {
        const rebuildResponse = await rebuildCommLogInfo(props.uuid ? props.uuid : props.data.communication_log_uuid);
        if (rebuildResponse) {
            setRebuiltCooldown(true);
            setTimeout(() => {
                setRebuiltCooldown(false);
                getCommLogData(props.uuid ? props.uuid : props.data.communication_log_uuid);
            }, 2000)
        }
    }

    const createNewContact = async (payload) => {
        const response = await createCommLogContact(
            data.communication_log_uuid,
            payload.contact_uuid,
            payload.method
        );

        if (response) {
            getCommLogData();
            if (props.refreshDashboard) {
                await props.refreshDashboard();
            } else {
                await props.refresh();
            }
        }
    }

    const downloadLogs = async () => {
        let commType, method;

        if (commData && commData.communication_type) {
            commType = commData.communication_type
        } else if (props.data && props.data.communication_type) {
            commType = props.data.communication_type
        }

        if (props.data && props.data.contact_method) {
            method = props.data.contact_method
        } else {
            if (commData.contact_methods.includes('postcard')) {
                method = 'postcard'
            } else {
                method = 'letter'
            }
        }

        let query = queryString.stringify({
            communication_type: commType,
            communication_log_uuid: data.communication_log_uuid,
            method: method
        });
        window.open("/service/communications/download?" + query, "_blank");
    }

    const toggleSent = async (type) => {
        if (type === 'send') {
            await markCommLogSent(data.communication_log_uuid);
            getCommLogData();
            props.refreshDashboard?.()
            await props.refresh();

        } else if (type === 'unsend') {
            await markCommLogUnsent(data.communication_log_uuid);
            getCommLogData();
            props.refreshDashboard?.()
            await props.refresh();
        }
    }

    const openFlyout = (payload) => {
        const overlay = document.getElementById('overlaySecondary');
        setLogContactInfo(payload);
        setFlyoutActive(true);
        overlay.classList.add('overlaySecondary__visible');
    }

    const closeFlyout = () => {
        const flyoutSecondary = document.getElementById('flyoutSecondary');
        const overlay = document.getElementById('overlaySecondary');

        flyoutSecondary.classList.remove('slideToVisible');
        overlay.classList.remove('overlaySecondary__visible');
        getCommLogData();
        setTimeout(() => {
            setFlyoutActive(false)
        }, 200);
    }

    return (
        <ModalDuplicateContext.Consumer>
            {(ctx) => {
                return (
                    <div className="flyoutContentContainer" id="flyoutBase">
                        {data.sent_on === "0" &&
                            <button className="editModeButton" onClick={() => toggleEditMode()}>
                                {!editMode && (
                                    <img src={editModeIcon} alt='' />
                                )}
                            </button>
                        }
                        <CommLogDetailsForm
                            data={data}
                            context={ctx}
                            editMode={editMode}
                            saveDetails={saveDetails}
                            toggleEditMode={toggleEditMode}
                            newAddition={props.newAddition}
                            closeFlyout={props.closeFlyout}
                            openFlyout={props.openFlyout}
                            flyoutPriority={props.flyoutPriority}
                            closeSecondaryFlyout={closeFlyout}
                            openSecondaryFlyout={openFlyout}
                            deleteThisCommLog={deleteThisCommLog}
                            apiErrors={apiErrors}
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            commsList={commsList}
                            logContacts={logContacts}
                            createNewContact={createNewContact}
                            commData={commData}
                        />
                        {!props.newAddition && !editMode &&
                            <React.Fragment>
                                {(showLetterOptions || (logContacts && logContacts.length === 0)) &&
                                    <div className="flexButtonContainer" id="logButtons">
                                        <div>
                                            <button
                                                className="medButtonPrimary"
                                                onClick={() => { downloadLogs() }}>
                                                Download
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="medButtonPrimary"
                                                onClick={() => { toggleSent(data.sent_on && data.sent_on !== "0" ? 'unsend' : 'send') }}>
                                                {data.sent_on && data.sent_on !== "0" ? 'Mark as Unsent' : 'Mark as Sent'}
                                            </button>
                                        </div>
                                    </div>
                                }
                                {showEmailOptions &&
                                    <div className={showLetterOptions ? "flexButtonContainer secondary" : "flexButtonContainer"}>
                                        <React.Fragment>
                                            <TestEmailButton data={data} logContacts={logContacts} />
                                            <button className="medButtonSecondary" onClick={() => prepEmail()}>{props.sentCooldown ? 'Sent!' : 'Send Email/s'}</button>
                                        </React.Fragment>
                                    </div>
                                }
                                <div className="formButtonsBank">
                                    {data.sent_on && data.sent_on === "0" && <button className="medButtonSecondary" onClick={() => rebuildInfo()}>
                                        <Tooltip text="Rebuilds communication with updated location, assembly, or contact details if records have been changed" />
                                        {rebuiltCooldown ? 'Rebuilt!' : 'Rebuild Info'}
                                    </button>}
                                </div>
                            </React.Fragment>
                        }
                        <div className="overlaySecondary" id="overlaySecondary" onClick={() => { closeFlyout() }}></div>
                        {flyoutActive && (
                            <React.Fragment>
                                <Flyout
                                    data={logContactInfo}
                                    commInfo={props.data}
                                    commLogInfo={data}
                                    source='commLog'
                                    flyoutType='commLogContact'
                                    flyoutPriority='secondary'
                                    closeFlyout={closeFlyout}
                                    openFlyout={openFlyout}
                                    activeTab='commLogContactDetails'
                                    newAddition={false}
                                    refresh={getCommLogData}
                                    refreshDashboard={props.refresh}
                                    rebuildInfo={rebuildInfo}
                                    rebuiltCooldown={rebuiltCooldown}
                                    sendEmail={props.sendEmail}
                                    sentCooldown={props.sentCooldown}
                                    reportResponse={props.reportResponse}
                                    commData={commData}
                                // setNewCommType={setNewCommType}
                                />
                            </React.Fragment>
                        )}
                        {editMode && !deleteMode && !props.newAddition && (
                            <div className="deleteButton__container">
                                <button className="deleteButton" onClick={() => deleteThisCommLog()}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
                            </div>
                        )}
                        {editMode && deleteMode && (
                            <div className="flyout__deleteMode">
                                <span>Warning: This will delete all associated historical records and actions cannot be undone.</span>
                                <button className="medButtonSecondary" type="button" onClick={() => deleteThisCommLog('delete')}>Delete Log</button>
                                <button className="exit" onClick={() => deleteThisCommLog('cancel')}><CloseIcon fontSize="large" /></button>
                            </div>
                        )}
                    </div>
                );
            }}
        </ModalDuplicateContext.Consumer>
    );
};

export default CommLogDetails;
