import React from 'react';
import { Field } from 'formik';
import DatePicker from '../DatePicker';

// certain fields, like selects, radios, anything we "hardcode" the values in we should lower case the value so
// they match with what we hardcode
let SRlowerCaseFields = {
    "CO": ["zoning"],
    "FL": ["zoning"],
    "MA": ["zoning"],
    "MI": ["zoning", "hazard_level"],
    "WA": ["hazard_type"]
};

const LocationBFPStateReportFields = (props) => {
    const { editMode, setFieldValue } = props;

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setFieldValue(e.target.name, e.target.value);
        } else {
            setFieldValue(e.target.name, null);
        }
    }

    let stateReportFields = {
        "CO": COFields(props, handleCheckboxChange),
        "FL": FLFields(props, handleCheckboxChange),
        "MA": MAFields(props, handleCheckboxChange),
        "MI": MIFields(props, handleCheckboxChange),
        "WA": WAFields(props, handleCheckboxChange),
    };

    if (!(SwiftComply.organization.state_prov.toLocaleUpperCase() in stateReportFields)) {
        return null;
    } else {
        return (
            <React.Fragment>
                <hr />
                <fieldset id="state-report-fields">
                    {stateReportFields[SwiftComply.organization.state_prov.toLocaleUpperCase()]}
                </fieldset>
            </React.Fragment>
        );
    }
}

// used in a formik mapPropsToValues function to process the JSON from the backend into values that we need
const LocationBFPStateReportFieldsPreprocess = (sr_fields) => {
    if (!sr_fields) {
        return {};
    }

    let fields = JSON.parse(sr_fields);
    let values = {};

    let funcs = {};

    let orgState = SwiftComply.organization.state_prov.toLocaleUpperCase();

    // lower case any values that need it, we need to do this for the fields coming in here so that when we pass them
    // to any specific preprocess functions they are just done
    if (orgState in SRlowerCaseFields) {
        for (const field of SRlowerCaseFields[orgState]) {
            if (field in fields) {
                fields[field] = fields[field].toLocaleLowerCase();
            }
        }
    }

    if (orgState in funcs) {
        return funcs[orgState](fields, values);
    }

    for (const key in fields) {
        values["sr_" + key] = fields[key];
    }

    return values;
}

// used in handleSubmit calls to convert names / values into the sr_fields JSON to store in the backend
const LocationBFPStateReportFieldsPostprocess = (values) => {
    let sr_fields = {};
    let funcs = {};

    if (SwiftComply.organization.state_prov.toLocaleUpperCase() in funcs) {
        return funcs[SwiftComply.organization.state_prov.toLocaleUpperCase()](values);
    }

    // potential TODO here.  Because we preprocess the values from the backend and put all the fields into the values
    // object, we might get vals here that aren't on the form, if we do we end up passing them through as is.  Maybe not
    // a problem at all, but if it is, that is why
    for (const key in values) {
        if (key.startsWith("sr_")) {
            sr_fields[key.replace("sr_", "")] = values[key];
        }
    }

    return sr_fields;
}

export default LocationBFPStateReportFields;
export { LocationBFPStateReportFieldsPreprocess, LocationBFPStateReportFieldsPostprocess };

const COFields = (props, handleCheckboxChange) => {
    const { editMode, setFieldValue, values } = props;
    return [
        <div className="inputField long" key="sr_zoning">
            <label htmlFor="sr_zoning">Zoning</label>
            <Field
                disabled={!editMode}
                component="select"
                name="sr_zoning"
            >
                <option value=""></option>
                <option value="commercial">Commercial</option>
                <option value="residential">Residential</option>
            </Field>
        </div>
    ];
}

const FLFields = (props, handleCheckboxChange) => {
    const { editMode, setFieldValue, values } = props;
    return [
        <div className="inputField long" key="sr_zoning">
            <label htmlFor="sr_zoning">Zoning</label>
            <Field
                disabled={!editMode}
                component="select"
                name="sr_zoning"
            >
                <option value=""></option>
                <option value="residential">Residential</option>
                <option value="non-residential">Non-Residential</option>
            </Field>
        </div>
    ];
}

const MAFields = (props, handleCheckboxChange) => {
    const { editMode, setFieldValue, values } = props;
    return [
        <div className="inputField long" key="sr_zoning">
            <label htmlFor="sr_zoning">Zoning</label>
            <Field
                disabled={!editMode}
                component="select"
                name="sr_zoning"
            >
                <option value=""></option>
                <option value="commercial">Commercial</option>
                <option value="industrial">Industrial</option>
                <option value="institutional">Institutional</option>
                <option value="municipal">Municipal</option>
                <option value="residential">Residential</option>
            </Field>
        </div>
    ];
}

const MIFields = (props, handleCheckboxChange) => {
    const { editMode, setFieldValue, values } = props;
    return [
        <div className="inputField long" key="sr_zoning">
            <label htmlFor="sr_zoning">Zoning</label>
            <Field
                disabled={!editMode}
                component="select"
                name="sr_zoning"
            >
                <option value=""></option>
                <option value="commercial">Commercial</option>
                <option value="governmental">Governmental</option>
                <option value="industrial">Industrial</option>
                <option value="institutional">Institutional</option>
                <option value="residential">Residential</option>
            </Field>
        </div>,
        <div className="inputField long" key="sr_hazard_level">
            <label htmlFor="sr_hazard_level">Hazard Level</label>
            <Field
                disabled={!editMode}
                component="select"
                name="sr_hazard_level"
            >
                <option value=""></option>
                <option value="high">High</option>
                <option value="low">Low</option>
            </Field>
        </div>
    ];
}

const WAFields = (props, handleCheckboxChange) => {
    const { editMode, setFieldValue, values } = props;
    return [
        <div className="inputField long" key="sr_npdes_permit">
            <label htmlFor="sr_npdes_permit">NPDES Permit #</label>
            <Field
                disabled={!editMode}
                name="sr_npdes_permit"
            />
        </div>,
        <div className="inputField long" key="sr_hazard_type">
            <label htmlFor="sr_hazard_type">Location Table 13 Hazard Type</label>
            <Field
                disabled={!editMode}
                component="select"
                name="sr_hazard_type"
            >
                <option value=""></option>
                <option value="agricultural">Agricultural (farms and dairies)</option>
                <option value="alternative">Alternative health out-patient clinics and offices</option>
                <option value="bottling">Beverage bottling plants</option>
                <option value="blood">Blood centers</option>
                <option value="boarding">Boarding homes</option>
                <option value="car wash">Car washes</option>
                <option value="chemical plant">Chemical plants</option>
                <option value="childbirth">Childbirth centers</option>
                <option value="chiropractor">Chiropractors</option>
                <option value="laundry">Commercial laundries and dry cleaners</option>
                <option value="fire">Dedicated fire protection systems with chemical addition or using unapproved auxiliary supplies</option>
                <option value="irrigation">Dedicated irrigation systems using purveyor's water supply and with chemical addition</option>
                <option value="dental">Dental clinics and offices</option>
                <option value="film">Film processing facilities</option>
                <option value="food processing">Food processing plants</option>
                <option value="hospice">Hospice care centers</option>
                <option value="hospital">Hospitals (include psychiatric hospitals and alcohol and drug treatment centers)</option>
                <option value="kidney">Kidney dialysis centers</option>
                <option value="laboratory">Laboratories</option>
                <option value="metal plating">Metal plating industries</option>
                <option value="morgue">Morgues and autopsy facilities (not in hospitals)</option>
                <option value="mortuary">Mortuaries</option>
                <option value="nursing home">Nursing homes</option>
                <option value="other">Other High Hazard (3B)</option>
                <option value="other medical">Other Medical-Related High Hazard (3C)</option>
                <option value="out-patient">Out-patient clinics and offices</option>
                <option value="petroleum">Petroleum processing or storage plants</option>
                <option value="pier">Piers and docks</option>
                <option value="reclaimed">Premises where both reclaimed water and potable water are provided</option>
                <option value="aux water supply">Premises with an unapproved auxiliary water supply interconnected with the potable water supply</option>
                <option value="psychiatric">Psychiatric out-patient clinics and offices</option>
                <option value="radioactive">Radioactive material processing plants or nuclear reactors</option>
                <option value="residential treatment">Residential treatment centers</option>
                <option value="surgery">Same day surgery centers</option>
                <option value="access denied">Survey access denied or restricted</option>
                <option value="veterinarian">Veterinarian offices, clinics, and hospitals</option>
                <option value="wastewater station">Wastewater lift stations and pumping stations</option>
                <option value="wastewater treatment">Wastewater treatment plants</option>
            </Field>
        </div>,
        <div className="inputField long" key="sr_other_hazard_type">
            <label htmlFor="sr_other_hazard_type">Other High Hazard Type</label>
            <Field
                disabled={!editMode}
                name="sr_other_hazard_type"
            />
        </div>,
        <div className="inputField long" key="sr_connections">
            <label htmlFor="sr_connections">Number of Metered Connections to this Location</label>
            <Field
                disabled={!editMode}
                name="sr_connections"
                type="number"
            />
        </div>
    ];
}
