import React from "react";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { deleteProperty, editProperty, savePropertyDetail, updatePropertySRFields } from "../../../api/propertiesAPI";
import { getURLBaseSection, getURLModule, getURLParentObject } from "../../../utils/url";
import { BasicPropertyFields } from "./BasicPropertyFields";
import { stateReportFieldsByState } from "../../../utils/stateReportFields";
import { getFieldOptions } from "../../../utils/forms";
import HomeForm from "../Home/HomeForm";
import { detailsReportMapping } from "../../../utils/reporting";

const EditProperty = ({
    preloadedBasicState,
    preloadedDetailsState,
    preloadedSrState,
    onEditComplete,
    onEditCancelled,
    history,
}) => {
    const { module } = getURLModule();
    const { id: propertyId, path: propertyPath } = getURLParentObject("iu") || {};
    const { organization } = useSelector((state) => state.swiftComply);

    const onSubmit = (basic, details, sr) =>

    {
        console.log({basic, details, sr});

        return editProperty(propertyId, basic)
            .then(() =>
                Promise.all(
                    Object.entries(details).map((detail) =>
                        savePropertyDetail(propertyId, {
                            form_field_uuid: detail[0],
                            value: typeof detail[1] === "undefined" ? undefined : `${detail[1]}`,
                        })
                    )
                )
            )
            .then(() => {
                if (Object.keys(sr).length) {
                    return updatePropertySRFields(propertyId, sr);
                }
            })
            .then(onEditComplete)
    };

    const onDelete = () =>
        deleteProperty(propertyId).then(() => history.push(getURLBaseSection().path));

    return (
        <HomeForm
            basicFields={BasicPropertyFields}
            detailsFormName={detailsReportMapping.property}
            srFields={stateReportFieldsByState[module]?.properties?.[organization.state_prov]?.map((f) => ({
                id: f.id,
                label: f.label,
                inputType: f.input_type,
                dataType: f.data_type,
                options: getFieldOptions(f.values),
                required: f.required,
            }))}
            onSubmit={onSubmit}
            onCancel={onEditCancelled}
            onDelete={onDelete}
            preloadedBasicState={preloadedBasicState}
            preloadedDetailsState={preloadedDetailsState}
            preloadedSrState={preloadedSrState}
        />
    );
};

export default withRouter(EditProperty);
