import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const HelperRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} component={(props) => (
		props.location.pathname !== '/' ? (
			<React.Fragment>
				<Component {...props} />
			</React.Fragment>
		) : null
	)} />
);