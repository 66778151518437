import axios from 'axios';
import { getOwnUser } from './usersAPI';

// There is a new fetchDashboards action in redux which replaces this API call
// will keep this in case we need to use the old implementation as an example
// export const listDashboards = async () => {
//     const url = '/api/v1/dashboards';

//     return axios.get(url)
//         .then(response => {
//             console.log('Got Dashboards List', response);
//             return response.data
//         })
//         .catch(error => {
//             console.log('Couldn\'t get dashboards list', error.response.data);
//             throw error;
//         });
// };

export const createDashboard = async (name) => {
    const url = '/api/v1/dashboards';

    let payload = {};
    const user = await getOwnUser();

    payload = {
        "user_uuid": user['user_uuid'],
        "height": 1,
        "width": 1,
        "name": name ? name : null
    }

    return axios.post(url, payload)
        .then(response => {
            console.log('Created New Dashboard', response);
            return response.data
        })
        .catch(error => {
            console.log('Couldn\'t create dashboard', error.response.data);
            throw error;
        });
};

export const deleteDashboard = async (dashboardUUID) => {
    const url = `/api/v1/dashboards/${dashboardUUID}`;

    return axios.delete(url)
        .then(response => {
            console.log('Deleted Dashboard', response);
            return response.data
        })
        .catch(error => {
            console.log('Couldn\'t delete dashboard', error.response.data);
            throw error;
        });
};

export const updateDashboard = async (dashboardUUID, payload) => {
    const url = `/api/v1/dashboards/${dashboardUUID}`;

    return axios.put(url, payload)
        .then(response => {
            console.log('updated Dashboard', response);
            return response.data
        })
        .catch(error => {
            console.log('Couldn\'t update dashboard', error.response.data);
            throw error;
        });
};

export const listDashboardWidgets = async (dashboardUUID) => {
    const url = `/api/v1/dashboards/${dashboardUUID}/widgets`;

    return axios.get(url)
        .then(response => {
            console.log('Got Dashboard Widget List', response);
            return response.data
        })
        .catch(error => {
            console.log('Couldn\'t get dashboard widget list', error.response.data);
            throw error;
        });
};

export const createDashboardWidget = async (dashboardUUID, payload) => {
    const url = `/api/v1/dashboards/${dashboardUUID}/widgets`;

    return axios.post(url, payload)
        .then(response => {
            console.log('Created Dashboard Widget', response);
            return response.data
        })
        .catch(error => {
            console.log('Couldn\'t create dashboard widget', error.response.data);
            throw error;
        });
};

export const deleteDashboardWidget = async (dashboardUUID, widgetUUID) => {
    const url = `/api/v1/dashboards/${dashboardUUID}/widgets/${widgetUUID}`;

    return axios.delete(url)
        .then(response => {
            console.log('Deleted Dashboard Widget', response);
            return response.data
        })
        .catch(error => {
            console.log('Couldn\'t delete dashboard widget', error.response.data);
            throw error;
        });
};

export const updateDashboardWidget = async (dashboardUUID, widgetUUID, payload) => {
    const url = `/api/v1/dashboards/${dashboardUUID}/widgets/${widgetUUID}`;

    return axios.put(url, payload)
        .then(response => {
            console.log('Updated Dashboard Widget', response);
            return response.data
        })
        .catch(error => {
            console.log('Couldn\'t update dashboard widget', error.response.data);
            throw error;
        });
};

export const updateDashboardWidgetsCoordinates = async (dashboardUUID, payload) => {
    const url = `/api/v1/dashboards/${dashboardUUID}/widgets/layout`;

    console.log(payload)

    return axios.put(url, payload)
        .then(response => {
            console.log('Updated Dashboard Widgets Coordinates', response);
            return response.data
        })
        .catch(error => {
            console.log('Couldn\'t update dashboard widgets coordinates', error.response.data);
            throw error;
        });
};