import React from "react";
import serviceProvider from "../../../images/serviceProviderBlue.svg";
import Flyout from "../Flyout";
import validator from "validator";
import { updateURL } from "../../utils/url";
import serviceProviderLarge from "../../../images/serviceProviderBlue.svg";
import { hasRole } from "../../api/permissionsAPI";
import ReportTable from "../ReportTable/ReportTable";
import { getUsersReport } from "../../api/usersAPI";

class ServiceProvidersDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            flyoutActive: false,
            serviceProviderInfo: {},
            newServiceProvider: false,
            serviceProviderUUID: null,
            isOrgAdmin: false,
            SPCategory: null,
        };

        this.table = React.createRef();

        let urlParts = this.props.location.pathname.split("/");
        if (urlParts.length >= 3 && validator.isUUID(urlParts[2])) {
            this.state.serviceProviderUUID = urlParts[2];
        } else if (urlParts.length === 3 && urlParts[2] == "users") {
            this.state.SPCategory = "Service Provider Users";
        } else if (urlParts.length === 4 && urlParts[3] == "newuser") {
            this.state.SPCategory = "Service Provider Users";
            this.state.newServiceProvider = true;
        } else if (urlParts.length >= 4 && validator.isUUID(urlParts[3])) {
            this.state.SPCategory = "Service Provider Users";
            this.state.serviceProviderUUID = urlParts[3];
        }

        if (this.props.location.comingFrom === "SP") {
            this.state.SPCategory = "Service Provider Users";
        }
    }

    async componentDidMount() {
        if (this.state.serviceProviderUUID !== null) {
            if (this.state.SPCategory === "Service Provider Users") {
                this.openFlyout({ user_uuid: this.state.serviceProviderUUID }, false);
            } else {
                this.openFlyout({ service_provider_uuid: this.state.serviceProviderUUID }, false);
            }
        }

        if (this.state.newServiceProvider === true) {
            this.openFlyout(null, true);
        }

        hasRole("Org.Admin").then((hasRole) => {
            if (hasRole) {
                this.setState(() => ({ isOrgAdmin: true }));
            }
        });
    }

    openFlyout = async (serviceProviderInfo, newServiceProvider) => {
        const overlay = document.getElementById("overlay");

        if (newServiceProvider) {
            this.setState({ serviceProviderInfo: {}, newServiceProvider: true, flyoutActive: true });
            if (this.state.SPCategory === "Service Provider Users") {
                updateURL("/serviceProviders/users/newuser", {});
            }
        } else {
            if (this.state.SPCategory === "Service Providers") {
                serviceProviderInfo = {
                    service_provider_uuid: serviceProviderInfo.service_provider_uuid,
                };
            }
            this.setState({ serviceProviderInfo, newServiceProvider: false, flyoutActive: true });
            // update the url in the browser without causing any reloads
            if (this.state.SPCategory === "Service Provider Users") {
                updateURL("/serviceProviders/users/" + serviceProviderInfo.user_uuid, {});
            } else {
                updateURL("/serviceProviders/" + serviceProviderInfo.service_provider_uuid, {});
            }
        }
        overlay.classList.add("overlay__visible");
    };

    closeFlyout = () => {
        const flyout = document.getElementById("flyout");
        const overlay = document.getElementById("overlay");
        let path = "/serviceProviders";

        if (this.state.SPCategory === "Service Provider Users") {
            path = "/serviceProviders/users";
        }

        flyout.classList.remove("slideToVisible");
        overlay.classList.remove("overlay__visible");

        setTimeout(() => {
            this.setState(() => ({
                flyoutActive: false,
                serviceProviderInfo: {},
                serviceProviderUUID: null,
            }));
        }, 200);

        this.table.current.ParentEventHandler("flyout closed", { path });
    };

    getReportType = (type) => {
        this.setState(() => ({ SPCategory: type.name }));
    };

    callbackHandler = (event, info) => {
        if (event === "report selected") {
            let path = "/serviceProviders";
            if (info.name === "Service Provider Users") {
                path += "/users";
            }
            updateURL(path);
        }
    };

    render() {
        const { flyoutActive, serviceProviderInfo, newServiceProvider, isOrgAdmin, SPCategory } = this.state;
        return (
            <div className="componentContainer">
                <div className="componentHeader">
                    <div className="flexAlignCenter">
                        <img src={serviceProvider} alt="" />
                        <h3>Service Providers</h3>
                    </div>
                    {SwiftComply.user.user_type === "normal" &&
                        ((SPCategory === "Service Provider Users" && (
                            <button onClick={() => this.openFlyout(null, true)} className="longButtonPrimary">
                                Add Service Provider User
                            </button>
                        )) || (
                            <button onClick={() => this.openFlyout(null, true)} className="longButtonPrimary">
                                Add Service Provider
                            </button>
                        ))}
                </div>
                <ReportTable
                    ref={this.table}
                    page={SPCategory === "Service Provider Users" ? "users" : "serviceproviders"}
                    tableType={SPCategory === "Service Provider Users" ? "Users" : "Service Providers"}
                    openFlyout={this.openFlyout}
                    callback={this.callbackHandler}
                    flyoutActive={flyoutActive}
                    sendReportType={this.getReportType}
                    defaultReportIdx={SPCategory === "Service Provider Users" ? "1" : "0"}
                    reports={[
                        {
                            reportUUID: "0a3d6a20-46df-47b9-9321-a8cc9a10bb75",
                            name: "Service Providers",
                            columns: ["Name", "Address", "City", "Website"],
                        },
                        ...(SwiftComply.user.user_type === "normal"
                            ? [
                                  {
                                      reportFunction: (reportingParams) => {
                                          return getUsersReport("service provider", reportingParams);
                                      },
                                      reportUUID: "f60dbccd-0d3e-4987-b78e-fbb46a0e2a27",
                                      name: "Service Provider Users",
                                      columns: [
                                          "Name",
                                          "Email",
                                          "Certification Status",
                                          "Job Title",
                                          "Phone",
                                          "Organization Name",
                                      ],
                                  },
                              ]
                            : []),
                    ]}
                    removeInputs={["user type"]}
                />
                <img className="dashboardIconLarge dashboardIconLarge__adjusted" src={serviceProviderLarge} alt="" />
                <div
                    className="overlay"
                    id="overlay"
                    onClick={() => {
                        this.closeFlyout();
                    }}
                ></div>
                {flyoutActive && (
                    <React.Fragment>
                        <Flyout
                            uuid={
                                SPCategory === "Service Provider Users"
                                    ? serviceProviderInfo.user_uuid
                                    : serviceProviderInfo.service_provider_uuid
                            }
                            newAddition={newServiceProvider}
                            isOrgAdmin={isOrgAdmin}
                            flyoutType={SPCategory === "Service Provider Users" ? "users" : "serviceProviders"}
                            userType="Service Provider Users"
                            flyoutPriority="primary"
                            closeFlyout={this.closeFlyout}
                            openFlyout={this.openFlyout}
                            activeTab={
                                SPCategory === "Service Provider Users" ? "userDetails" : "serviceProviderDetails"
                            }
                            isServiceProviderUser={true}
                            userSP={
                                this.props.location?.comingFrom === "SP" ? this.props.location.serviceProvider : null
                            }
                            refresh={() => this.table.current.ParentEventHandler("needs update")}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default ServiceProvidersDashboard;
