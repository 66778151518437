import React from 'react';
import assembly from '../../../images/assemblyBlue.svg';
import Flyout from '../Flyout';
import validator from 'validator';
import { updateURL } from '../../utils/url';
import assemblyLarge from '../../../images/assemblyBlue.svg';

import ReportTable from '../ReportTable/ReportTable';

class AssembliesDashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			flyoutActive: false,
			assemblyInfo: {},
			newAssembly: false,
			assemblyUUID: null,
		};

		this.table = React.createRef();

		let urlParts = this.props.location.pathname.split("/");
		if (urlParts.length >= 3 && validator.isUUID(urlParts[2])) {
			this.state.assemblyUUID = urlParts[2];
		}
	}

	async componentDidMount() {
		if (this.state.assemblyUUID !== null) {
			this.openFlyout({ equipment_uuid: this.state.assemblyUUID }, false);
		}
	}

	openFlyout = async (assemblyInfo, newAssembly) => {
		const overlay = document.getElementById('overlay');

		if (newAssembly) {
			this.setState({ assemblyInfo: {}, newAssembly: true, flyoutActive: true });
		} else {
			const {equipment_uuid, property_uuid, location_name, serial_number} = assemblyInfo;
			this.setState({ assemblyInfo: {equipment_uuid, property_uuid, location_name, serial_number}, newAssembly: false, flyoutActive: true });
			// update the url in the browser without causing any reloads
			updateURL('/assemblies/' + assemblyInfo.equipment_uuid, {});
		}
		overlay.classList.add('overlay__visible');
	}

	closeFlyout = () => {
		const flyout = document.getElementById('flyout');
		const overlay = document.getElementById('overlay');

		flyout.classList.remove('slideToVisible');
		overlay.classList.remove('overlay__visible');

		setTimeout(() => {
			this.setState(() => ({
				flyoutActive: false,
				assemblyInfo: {},
				assemblyUUID: null
			}));
		}, 200);

		this.table.current.ParentEventHandler('flyout closed', { path: '/assemblies' });
	}

	render() {
		const { flyoutActive, assemblyInfo, newAssembly } = this.state;
		return (
			<div className="componentContainer" id="assembliesDashboard">
				<div className="componentHeader">
					<div className="flexAlignCenter">
						<img src={assembly} alt='' />
						<h3>Assemblies</h3>
					</div>
					<button onClick={() => this.openFlyout(null, true)} className="longButtonPrimary">Add Assembly</button>
				</div>
				<ReportTable
					ref={this.table}
					page='assemblies'
					tableType='Assemblies'
					openFlyout={this.openFlyout}
					flyoutActive={flyoutActive}
					defaultReportIdx='0'
					reports={[
						{
							reportUUID: "b54a82b3-ae76-4c06-94cb-8098bdbf4e56",
							name: "Backflow Assemblies",
							columns: ['Assembly ID', 'Type', 'Make', 'Model', 'Serial Number', 'Size', 'Compliant', 'Compliance Expiration', 'Address', 'City']
						}
					]}
				/>
				<img className="dashboardIconLarge dashboardIconLarge__adjusted" src={assemblyLarge} alt='' />
				<div className="overlay" id="overlay" onClick={() => { this.closeFlyout() }}>
				</div>
				{flyoutActive && (
					<React.Fragment>
						<Flyout
							uuid={assemblyInfo.equipment_uuid}
							newAddition={newAssembly}
							assemblyInfo={assemblyInfo}
							flyoutType='assemblies'
							flyoutPriority="primary"
							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							activeTab='assemblyDetails'

							refresh={() => this.table.current.ParentEventHandler('needs update')}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default AssembliesDashboard;
