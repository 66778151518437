/** @type {formField[]} */
export const BasicSampleReportFields = [
    {
        id: "report_configuration_name",
        label: "Report Configuration Name",
    },
    {
        id: "outfall_name",
        label: "Outfall Name",
    },
    {
        id: "assigned_to",
        label: "Assigned To",
    },
    {
        id: "enforceability",
        label: "Enforceability",
    },
    {
        id: "lab_report",
        label: "Lab Report",
    },
    {
        id: "custody_chain",
        label: "Chain of Custody",
    },
    {
        id: "due_date",
        label: "Due Date/Time",
    },
    {
        id: "sampling_configuration",
        label: "Sampling Configuration",
    },
    {
        id: "submitted_by",
        label: "Submitted By",
    },
    {
        id: "submitted_on",
        label: "Submitted On",
    },
    {
        id: "last_modified_by",
        label: "Last Updated By",
    },
    {
        id: "last_modified_on",
        label: "Last Updated On",
    },
    {
        id: "report_schedule",
        label: "Report Schedule",
    },
];
