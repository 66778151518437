export const Colorado = (year) => {
    let tables = [
        {
            key: 'survey',
            tableTitle: 'Survey',
            rows: [
                {
                    key: 'Line01',
                    label: '1. Number of service connections (NON single family - NOT including connections discovered after Oct. 31)'
                },
                {
                    key: 'Line02',
                    label: '2. Number of connections within Supplier\'s Waterworks (w/i treatment plants, pump stations, well houses, etc.'
                },
                {
                    key: 'Line03',
                    label: '3. Total Number of connections that must be surveyed (Sum Line 1 and Line 2)'
                },
                {
                    key: 'Line04',
                    label: '4. Total number of connections that have been surveyed (all years surveys through date above, include connections controlled with RPZ)'
                },
                {
                    key: 'Line05',
                    label: '5. Survey Compliance Ratio (Line4/Line3). Ranges from 0 to 1.0'
                },
            ],
            columns: [
                {
                    key: 'count',
                    label: 'Value'
                }
            ]
        },
        {
            key: 'crossconn',
            tableTitle: 'Cross Connections',
            rows: [
                {
                    key: 'Line06',
                    label: '6. Total number of identified cross connections in the distribution system within the supplier\'s waterworks.'
                },
                {
                    key: 'Line07',
                    label: '7. Number of uncontrolled cross connections identified during the calendar year.'
                },
                {
                    key: 'Line07a',
                    label: '   Number of identified uncontrolled cross connections that were controlled within 120 days of discovery.'
                },
                {
                    key: 'Line08',
                    label: '8. Number of connections where service was suspended as specified in 11.39(3) during the calendar year.'
                },
                {
                    key: 'Line09',
                    label: '9. Number of identified uncontrolled cross connections that were not controlled within 120 days of discovery.'
                },
            ],
            columns: [
                {
                    key: 'count',
                    label: 'Value'
                }
            ]
        },
        {
            key: 'assemblies',
            tableTitle: 'Assemblies',
            rows: [
                {
                    key: 'Line10',
                    label: '10. Number of backflow prevention assemblies that were used during the calendar year (installed at cross connections, corresponding to line 6)'
                },
                {
                    key: 'Line11',
                    label: '11. Number of backflow prevention assemblies used to control cross connections that were tested by a Certified Cross Connection Control Technician during the calendar year.'
                },
                {
                    key: 'Line12',
                    label: '12. Backflow prevention assembly annual testing compliance ratio.'
                },
                {
                    key: 'Line13',
                    label: '13. Beginning January 1, 2021, the number of backflow prevention assemblies not tested during the calendar year covered by the report.'
                }
            ],
            columns: [
                {
                    key: 'count',
                    label: 'Value'
                }
            ]
        },
        {
            key: 'methods',
            tableTitle: 'Methods',
            rows: [
                {
                    key: 'Line14',
                    label: '14. Number of backflow prevention methods that were used during the calendar year (installed at cross connections, corresponding to line 6)'
                },
                {
                    key: 'Line15',
                    label: '15. Number of backflow prevention methods used to control cross connections that were inspected during the calendar year.'
                },
                {
                    key: 'Line16',
                    label: '16. Backflow prevention method annual inspection compliance ratio.'
                },
                {
                    key: 'Line17',
                    label: '17. Beginning January 1, 2017, the number and location of backflow prevention methods not inspected during the calendar year covered by the report.'
                }
            ],
            columns: [
                {
                    key: 'count',
                    label: 'Value'
                }
            ]
        }
    ];

    return tables;
}
