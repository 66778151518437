import React from "react";
import { FilterListRounded } from "@mui/icons-material";
import PopUpMenuForm from "../PopUpMenuForm";
import ToolTip from "../ToolTip";

const FilterMenu = ({ filterFields, selectedFilters, onApplyFilters }) => {
    return (
        <PopUpMenuForm
            icon={
                <ToolTip title="Table Filters">
                    <FilterListRounded />
                </ToolTip>
            }
            title="Filters"
            formFields={filterFields}
            initialFormState={selectedFilters}
            onApply={onApplyFilters}
        />
    );
};

export default FilterMenu;
