import axios from "axios";
import moment from "moment";

export const getReportInfo = async (reportUUID) => {
    const url = "/api/v1/reporting/" + reportUUID;

    return await axios
        .get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const getReportInfoWithoutFilters = async (reportUUID) => {
    const url = "/api/v1/reporting/" + reportUUID;

    return await axios
        .get(url)
        .then((response) => {
            // Filter out 'Needs Review' and 'Active' inputs
            if (response.data && response.data.inputs) {
                response.data.inputs = response.data.inputs.filter(
                    (input) => input.name !== "Needs Review" && input.name !== "Active"
                );
            }
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const transformReportResponse = (response, spreadData) => {
    let transformed = spreadData ? {...response.data} : {data: response.data};
    transformed = {
        ...transformed,
        nextPage: response.headers["x-scc3-reporting-next-page"],
        requestID: response.headers["x-scc3-request-id"],
        totalRowCount: response.headers["x-scc3-reporting-total-row-count"],
    }
    return transformed  
}

const getReportInternal = (reportUUID, payload, spreadData) => {
    return axios
        .post(`/api/v1/reporting/${reportUUID}`, { ...payload })
        .then( response => transformReportResponse(response, spreadData))

}

export const getReportSync = async (reportUUID, payload, spreadData) => {
    return await getReportInternal(reportUUID, payload, spreadData)
    .catch((error) => {
        console.log("Couldn't get report", error.response.data);
        throw error;
    });
};

export const getReportAsync = (reportUUID, payload, spreadData) => {
    return getReportInternal(reportUUID, payload, spreadData);
}


export const downloadReport = async (reportUUID, payload, name) => {
    const url = "/reporting/export/" + reportUUID;

    return await axios
        .post(url, payload)
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${name}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch((error) => {
            console.log("Couldn't download report", error.response.data);
            throw error;
        });
};

export const listReports = async () => {
    return await axios
        .get("/api/v1/reporting")
        .then((response) => {
            return response.data.reports;
        })
        .catch((error) => {
            throw error;
        });
};

export const getAssemblyTypeDistributionReport = async (payload) => {
    const url = "/api/v1/reporting/f7205439-8070-4837-ae2e-d730707f0dba";

    return await axios
        .post(url, payload)
        .then((response) => {
            console.log("Got assembly type distribution report", response);
            return { data: response.data, nextPage: response.headers["x-scc3-reporting-next-page"] };
        })
        .catch((error) => {
            console.log("Couldn't get assembly type distribution report", error.response.data);
            throw error;
        });
};

export const getComplianceDistributionReport = async (payload) => {
    const url = "/api/v1/reporting/fb8cc2fb-ff47-4068-83ee-a29b83db3ab4";

    return await axios
        .post(url, payload)
        .then((response) => {
            console.log("Got compliance distribution report", response);
            return { data: response.data, nextPage: response.headers["x-scc3-reporting-next-page"] };
        })
        .catch((error) => {
            console.log("Couldn't get compliance distribution report", error.response.data);
            throw error;
        });
};

export const getComplianceDistributionByZipCodeReport = async (payload) => {
    const url = "/api/v1/reporting/03bca89f-073c-4023-a40c-876951df9531";

    return await axios
        .post(url, payload)
        .then((response) => {
            console.log("Got compliance distribution by zip code report", response);
            return { data: response.data, nextPage: response.headers["x-scc3-reporting-next-page"] };
        })
        .catch((error) => {
            console.log("Couldn't get compliance distribution by zip code report", error.response.data);
            throw error;
        });
};

export const getOrganizationCountsReport = async () => {
    const url = "/api/v1/reporting/5d5aa887-07dc-4d88-b03d-f556e28c4134";
    return await axios
        .post(url)
        .then((response) => {
            console.log("Got organization counts report", response);
            return { data: response.data, nextPage: response.headers["x-scc3-reporting-next-page"] };
        })
        .catch((error) => {
            console.log("Couldn't get organization counts report", error.response.data);
            throw error;
        });
};

export const getAssemblyComplianceDueCountReport = async (payload) => {
    const url = "/api/v1/reporting/26078f8c-8ba7-4a8b-b052-03c105e96489";
    return await axios
        .post(url, payload)
        .then((response) => {
            console.log("Got Assembly compliance due count report", response);
            return { data: response.data, nextPage: response.headers["x-scc3-reporting-next-page"] };
        })
        .catch((error) => {
            console.log("Couldn't get Assembly compliance due count report", error.response.data);
            throw error;
        });
};

export const getIncompleteAssembliesReport = async (payload) => {
    const url = "/api/v1/reporting/fa6ab0dc-d447-4998-82fd-ee9eaaba1f3e";
    return await axios
        .post(url, payload)
        .then((response) => {
            console.log("Got incomplete assemblies report", response);
            return { data: response.data, nextPage: response.headers["x-scc3-reporting-next-page"] };
        })
        .catch((error) => {
            console.log("Couldn't get incomplete assemblies report", error.response.data);
            throw error;
        });
};

export const getAssemblyComplianceRate = async (dateRange) => {
    const url = "/api/v1/reporting/20e067c5-c632-4b4e-83ff-7d679ed54470";

    let payload = {};

    if (dateRange === "month") {
        payload.inputs = {
            "Begin Date": JSON.stringify(moment().startOf("month").unix()),
            "End Date": JSON.stringify(moment().unix()),
        };
    } else if (dateRange === "year") {
        payload.inputs = {
            "Begin Date": JSON.stringify(moment().startOf("year").unix()),
            "End Date": JSON.stringify(moment().unix()),
        };
    }

    return await axios
        .post(url, payload)
        .then((response) => {
            console.log("Got assembly compliance rate", response);
            return { data: response.data, nextPage: response.headers["x-scc3-reporting-next-page"] };
        })
        .catch((error) => {
            console.log("Couldn't get assembly compliance rate", error.response.data);
            throw error;
        });
};

export const getIncompleteLocationsReport = async (payload) => {
    const url = "/api/v1/reporting/4622e3d8-dcf5-4aa9-85b0-7963e784fe8d";
    return await axios
        .post(url, payload)
        .then((response) => {
            console.log("Got incomplete locations report", response);
            return { data: response.data, nextPage: response.headers["x-scc3-reporting-next-page"] };
        })
        .catch((error) => {
            console.log("Couldn't get incomplete locations report", error.response.data);
            throw error;
        });
};
