import React from 'react';
import { getUserCertifications, createUserCertification, updateUserCertification, deleteUserCertification } from '../../api/usersAPI';
import CertificationsForm from './UserCertsForm';
import { uploadFile } from '../../api/uploadsAPI';
import { createCertificationUpload } from '../../api/usersAPI';
import { getOrganizationSetting } from '../../api/organizationAPI';
import { Tooltip } from '../Tooltip';

class UserCerts extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			certificationList: [],
			hasNewCert: false,
		};
	}

	componentDidMount() {
		this.updateCertificationList();
	}

	updateCertificationList = async () => {
		let certData = {};
		// this page is used for both admins to look up a provider, as well as a user editing their own profile so if 
		// we are looking at our own uuid, as for our own certs, if not as for a specific user
		if (SwiftComply.user.user_uuid === this.props.uuid) {
			certData = await getUserCertifications();
		} else {
			certData = await getUserCertifications(this.props.uuid);
		}

		let certifyingAgencyToType = {
			"ABPA": ['Backflow Prevention Assembly Tester Certification'],
			"ASSE": ['Backflow Prevention Certification'],
			"AWWA": ['Backflow Prevention Assembly Tester Certification']
		};
		certData.certifications.forEach(({certifying_agency, certification_type}, idx) => {
			
			// any cert the user already has we will add to our map, even if the org doesn't accept that, we still need
			// to show what it is
			const existing = certifyingAgencyToType[certifying_agency] || []
			if (!existing.includes(certification_type)) {
				certifyingAgencyToType[certifying_agency] = [...existing, certification_type];
			}
		});
		// now, grab any additional agency / types that the org supports beyond the hardcoded national ones
		let settingVal = await getOrganizationSetting("org.addl_certifications");
		if (settingVal !== null) {
			try {
				let orgAddlCerts = JSON.parse(settingVal);
				orgAddlCerts.forEach(({agency, type}) => {
					const existing = certifyingAgencyToType[agency] || []
					if (!agency || !type) {
						document.getElementById("modal-error-message").innerText = `ERROR: organization settings misconfigured for org.addl_certifications. agency: ${agency}, type: ${type}`
					} else if (!existing.includes(type)) {
						// this check prevents duplicates in the select
						certifyingAgencyToType[agency] = [...existing, type];
					}
				})
			} catch (e) {
				// very likely SyntaxError, for now just do nothing
			}
		}

		const data = certData.certifications.sort((a, b) => (a.certifying_agency > b.certifying_agency) ? 1 : -1)
		let certificationList = [];
		if (data.length) {
			certificationList = (
				data.map((cert, index) => (
					<CertificationsForm
						key={cert.user_certification_uuid}
						data={cert}
						saveCertification={this.saveCertification}
						deleteCertification={this.deleteCertification}
						newCertification={false}
						toggleNewCertification={this.toggleNewCertification}
						certifyingAgencyToType={certifyingAgencyToType}
					/>
				))
			);
		}

		if (this.state.hasNewCert) {
			certificationList.push((
				<CertificationsForm
					key={certificationList.length + 1}
					saveCertification={this.saveCertification}
					newCertification={true}
					data={{}}
					toggleNewCertification={this.toggleNewCertification}
					uploadCertImage={this.uploadCertImage}
					certifyingAgencyToType={certifyingAgencyToType}
				/>
			));
		}

		this.setState({ certificationList });
	};

	toggleNewCertification = (isNew) => {
		this.setState({ hasNewCert: isNew });
		this.updateCertificationList();
	};

	saveCertification = async (payload, certUUID) => {
		let orgAdmin;
		if (SwiftComply.user.user_uuid === this.props.uuid) {
			orgAdmin = false
		} else {
			orgAdmin = true
			payload.user_uuid = this.props.uuid
		}

		if (this.state.hasNewCert) {
			// I'm unclear why the yup shit in the form isn't preventing this from being called when we have no file
			// but it would still try to upload, so wrapping this all in an if for now
			if (payload.file) {
				const data = new FormData();
				data.set('file', payload.file);
				data.set('scope', "user");
				const cert = await createUserCertification(payload, orgAdmin);
				const upload = await uploadFile(data);
				await createCertificationUpload({
					upload_uuid: upload.uuid,
					user_certification_uuid: cert.user_certification_uuid
				});
				this.setState({ hasNewCert: false });
			}
		} else {
			await updateUserCertification(certUUID, payload, orgAdmin);
			if (payload.file) {
				const data = new FormData();
				data.set('file', payload.file);
				data.set('scope', "user");
				const upload = await uploadFile(data);
				await createCertificationUpload({
					upload_uuid: upload.uuid,
					user_certification_uuid: certUUID
				});
			}
		}

		await this.updateCertificationList(this.props.uuid);
	};

	deleteCertification = (uuid) => {
		if (confirm('Are you sure you want to remove this Certification?')) {
			deleteUserCertification(uuid).then(() => this.updateCertificationList(uuid));
		}
	};

	render() {
		const { certificationList, hasNewCert } = this.state;
		return (
			<div className="flyoutContentContainer">
				<div className="cardsContainer" id="cert">
					{certificationList.length > 0 && certificationList}
					{certificationList.length === 0 &&
						<span className="noDataMessage">This user currently has no certifications on file.</span>
					}
					{!hasNewCert &&
						<span className="flex">
							<button className="medButtonSecondary" onClick={() => { this.setState({ hasNewCert: true }); this.updateCertificationList() }}>
								Add Certification
							</button>
						</span>
					}
				</div>
			</div>
		);
	}
}

export default UserCerts;
