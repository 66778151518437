import { BasicSamplingConfigFields } from "../SamplingConfigs/BasicSamplingConfigFields";
import { BasicLimitFields } from "../Limits/LimitFields";

// Helper function to find field by ID
const getFieldById = (id) => {
    return BasicSamplingConfigFields.find(
        (f) => f.id === id || f.id.startsWith(id + "_")
    );
};

// Helper function to find field options by ID
const getOptionsById = (id) => {
    const field = getFieldById(id);
    return field?.options || [];
};

// Get unit options from BasicLimitFields
const unitOptions =
    BasicLimitFields.find((field) => field.id === "units")?.options || [];

export const sampleEventDataFields = [
    {
        id: "analyte",
        label: "Analyte",
        inputType: "text",
        editable: false,
    },
    {
        id: "sample_value",
        label: "Result Value",
        inputType: "input",
        dataType: "numeric",
    },
    {
        id: "units",
        label: "Units",
        inputType: "select",
        options: unitOptions,
    },
    {
        id: "sample_type",
        label: "Sample Type",
        inputType: "select",
        options: getOptionsById("sample_type"),
    },
    {
        id: "sampling_interval",
        label: "Sampling Interval",
        inputType: "input",
        dataType: "numeric",
    },
    {
        id: "interval_unit",
        label: "Interval Unit",
        inputType: "select",
        options: [
            { value: "daily", label: "Daily" },
            { value: "weekly", label: "Weekly" },
            { value: "monthly", label: "Monthly" },
        ],
    },
    {
        id: "pacing",
        label: "Pacing",
        inputType: "select",
        options: getOptionsById("pacing"),
    },
    {
        id: "pacing_value",
        label: "Pacing Value",
        inputType: "input",
        dataType: "numeric",
    },
    {
        id: "pacing_units",
        label: "Pacing Units",
        inputType: "select",
        options: [
            { label: "minutes", value: "minutes" },
            { label: "hours", value: "hours" },
            { label: "days", value: "days" },
        ],
    },
    {
        id: "aliquots",
        label: "Aliquots",
        inputType: "input",
        dataType: "numeric",
    },
    {
        id: "container_type",
        label: "Container Type",
        inputType: "select",
        options: getOptionsById("container_types"),
    },
    {
        id: "container_volume",
        label: "Container Volume",
        inputType: "select",
        options: getOptionsById("container_volumes"),
    },
    {
        id: "preservative",
        label: "Preservative",
        inputType: "select",
        options: getOptionsById("preservatives"),
    },
    {
        id: "hold_time",
        label: "Hold Time",
        inputType: "input",
    },
    {
        id: "lab_method",
        label: "Lab Method",
        inputType: "select",
        options: getOptionsById("lab_methods"),
    },
    {
        id: "sample_start",
        label: "Sample Start Date/Time",
        inputType: "date_time",
    },
    {
        id: "sample_end",
        label: "Sample End Date/Time",
        inputType: "date_time",
    },
    {
        id: "sampled_by",
        label: "Sampled By",
        inputType: "input",
    },
    {
        id: "sample_report_result_id",
        label: "Sample ID",
        inputType: "input",
        editable: false,
    },
];
