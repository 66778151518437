/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import LoginForm from './LoginForm';
import infinityWhite from '../../images/infinitylogowhite.svg';
import fullLogo from '../../images/SwiftComplyRetina.png';
import '../styles/styles.scss';
import { getSAMLOptions } from '../api/authAPI';
import { parseQueryStringParams } from '../utils/url';

// just kind of tossing something in here that makes the button look ok for now until we decide how to really do them
const samlButtonStyle = {
	marginTop: '2rem'
}

const Login = () => {
	const [disableStdLogin, setDisableStdLogin] = useState(false);
	const [samlLogins, setSamlLogins] = useState("");

	let qs = parseQueryStringParams(window.location.search);

	useEffect(() => {
		getSAMLOptions().then(options => {
			let samlSection = "";
			if (options.idps.length > 0) {
				let samlButtons = [];
				options.idps.forEach((option) => {
					let authQs = "?idpid=" + option.idp_uuid;
					if (qs.whereTo) {
						authQs += "&whereTo=" + encodeURIComponent(qs.whereTo);
					}

					samlButtons.push(
						<button
							key={option.idp_uuid}
							className="mainBtn"
							style={samlButtonStyle}
							type="button"
							onClick={() => { window.location.href = '/saml/auth' + authQs }}
						>{option.label}</button >);
				});

				samlSection = <div>{samlButtons}</div>
			}
			setDisableStdLogin(options.disable_standard_login);
			setSamlLogins(samlSection);
		})
	}, []);

	return (
		<div className="loginContainer">
			<div className="loginLeft">
				<img className="loginLeft__logo" src={infinityWhite} />
			</div>
			<div className="loginRight">
				<div className="loginRight__container">
					<div className="loginRight__logo-wrapper">
						<img className="loginRight__logo" src={fullLogo} />
					</div>
					{SwiftComply?.settings?.disable_login === true
						? (<div>Login is temporarily disabled due to system maintenance.</div>)
						: (
							<React.Fragment>
								{!disableStdLogin && <LoginForm />}
								{samlLogins}
								{!disableStdLogin && (
									<div className="loginRight__finePrint">
										<div><a href="/forgot-password">Forgot your password?</a></div>
									</div>
								)}
							</React.Fragment>
						)
					}
				</div>
			</div>
		</div>
	);
};

export default Login;
