import React from 'react';
import contacts from '../../../images/contactsBlue.svg';
import Flyout from '../Flyout';
import validator from 'validator';
import { updateURL } from '../../utils/url';
import contactsLarge from '../../../images/contactsBlue.svg';

import ReportTable from '../ReportTable/ReportTable';

class ContactsDashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			flyoutActive: false,
			contactInfo: {},
			newContact: false,
			contactUUID: null,
		};

		this.table = React.createRef();

		let urlParts = this.props.location.pathname.split("/");
		if (urlParts.length >= 3 && validator.isUUID(urlParts[2])) {
			this.state.contactUUID = urlParts[2];
		}
	}

	async componentDidMount() {
		if (this.state.contactUUID !== null) {
			this.openFlyout({ contact_uuid: this.state.contactUUID }, false);
		}
	}

	openFlyout = async (contactInfo, newContact) => {
		const overlay = document.getElementById('overlay');

		if (newContact) {
			this.setState({ contactInfo: {}, newContact: true, flyoutActive: true });
		} else {
			contactInfo = {
				contact_uuid: contactInfo.contact_uuid
			};
			this.setState({ contactInfo, newContact: false, flyoutActive: true });
			// update the url in the browser without causing any reloads
			updateURL('/contacts/' + contactInfo.contact_uuid, {});
		}
		overlay.classList.add('overlay__visible');
	}

	closeFlyout = () => {
		const flyout = document.getElementById('flyout');
		const overlay = document.getElementById('overlay');

		flyout.classList.remove('slideToVisible');
		overlay.classList.remove('overlay__visible');

		setTimeout(() => {
			this.setState(() => ({
				flyoutActive: false,
				contactInfo: {},
				contactUUID: null
			}));
		}, 200);

		this.table.current.ParentEventHandler('flyout closed', { path: '/contacts' });
	}

	render() {
		const { flyoutActive, contactInfo, newContact } = this.state;
		return (
			<div className="componentContainer">
				<div className="componentHeader">
					<div className="flexAlignCenter">
						<img src={contacts} alt='' />
						<h3>Contacts</h3>
					</div>
					<button onClick={() => this.openFlyout(null, true)} className="longButtonPrimary">Add Contact</button>
				</div>
				<ReportTable
					ref={this.table}
					page='contacts'
					tableType='Contacts'
					openFlyout={this.openFlyout}
					flyoutActive={flyoutActive}
					defaultReportIdx='0'
					reports={[
						{
							reportUUID: "76ab96f4-f941-45be-86f8-157f9484422a",
							name: "Contacts",
							columns: ['Name', 'Phone', 'Email', 'Address', 'City']
						}
					]}
				/>
				<img className="dashboardIconLarge dashboardIconLarge__adjusted" src={contactsLarge} alt='' />
				<div className="overlay" id="overlay" onClick={() => { this.closeFlyout() }}>
				</div>
				{flyoutActive && (
					<React.Fragment>
						<Flyout
							uuid={contactInfo.contact_uuid}
							newAddition={newContact}

							flyoutType='contacts'
							flyoutPriority="primary"
							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							activeTab='contactDetails'

							refresh={() => this.table.current.ParentEventHandler('needs update')}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default ContactsDashboard;