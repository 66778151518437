import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    getURLModule,
    getURLObjects,
    URLSectionInfo,
} from "../../../utils/url";
import { ChevronRightRounded } from "@mui/icons-material";
import { Capitalize } from "../../../utils/misc";
import styles from "../../../styles/common/breadcrumbs.module.css";
import scLogo from "../../../../images/SwiftComplyRetina.png";

const BreadCrumbs = ({}) => {
    const { module, path } = getURLModule();
    const objects = getURLObjects();
    const [crumbLabels, setCrumbLabels] = useState(
        getURLObjects().map(
            (obj) => URLSectionInfo[obj.section]?.title ?? obj.section
        )
    );

    useEffect(() => {
        objects.forEach((obj, i) => {
            // Skip if no section info or no getObject function
            if (!URLSectionInfo[obj.section]?.getObject) {
                return;
            }

            // Only proceed if we have both an ID and getObject function
            if (obj.id) {
                URLSectionInfo[obj.section]
                    .getObject(obj.id)
                    .then((data) => {
                        if (data && URLSectionInfo[obj.section].getObjectName) {
                            return URLSectionInfo[obj.section].getObjectName(
                                data
                            );
                        }
                        return null;
                    })
                    .then((name) => {
                        if (name) {
                            setCrumbLabels((prev) =>
                                prev.map((prevObj, j) =>
                                    i === j ? name : prevObj
                                )
                            );
                        }
                    })
                    .catch((error) => {
                        console.error(
                            `Error fetching object name for ${obj.section}:`,
                            error
                        );
                    });
            }
        });
    }, []);

    // TODO: Remove this hack once the root paths "/pt" and "/bf" actually go somewhere
    //  For now, just redirect to the "properties" section
    const modulePath = path === "/pt" ? "/pt/iu" : "/bf/lo";

    return (
        <div className={styles.breadcrumbs}>
            <Link to={modulePath}>
                <img
                    src={scLogo}
                    className={styles.logo}
                    alt="Swift Comply logo"
                />
            </Link>
            <ChevronRightRounded className={styles.chevron} />
            <Link to={modulePath} className={styles.crumb}>
                {Capitalize(module)}
            </Link>
            {objects.map(({ path, section, id }, i) => (
                <React.Fragment key={`crumb ${section} ${id}`}>
                    <ChevronRightRounded className={styles.chevron} />
                    <Link to={path} className={styles.crumb}>
                        {crumbLabels[i]}
                    </Link>
                </React.Fragment>
            ))}
        </div>
    );
};

export default BreadCrumbs;
