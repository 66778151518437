import React from 'react';
import LoadingSpinner from './LoadingSpinner';

// this component wraps any content and overlays a loading indicator when isLoading is true
const LoaderOverlay = ({ isLoading, showLoaderOnly, children }) => {
    const getContent = () => {
        if(isLoading) return (<>
            {isLoading && <LoadingSpinner />}
            {children}
        </>)
        return (<>
            <LoadingSpinner />
            {children}
        </>)
    }

    return (<div style={{
        position: 'relative'
    }}>
        {getContent()}
    </div>);
}

export default LoaderOverlay;