import React, { useState, useEffect } from 'react';
import { getReportSync } from '../api/reportingAPI';
import Table from './Table';
import { getReportValue } from '../utils/reporting';
import { getDefaultReportingParams, setReportingParams } from '../utils/reporting';
import LoaderOverlay from './LoaderOverlay';

const ComplianceHistory = (props) => {
	const [complianceHistory, setComplianceHistory] = useState(null);

	useEffect(() => {
		if (props.flyoutType == "locations") {
			getReportSync('fb9121ec-4796-41f5-9a34-20121f1691a8', { "inputs": { "property uuid": props.uuid }, count: "250" }).then((response) => {
				setComplianceHistory(response.data);
			});
		} else if (props.flyoutType == "assemblies") {
			getReportSync('9687b0b0-7907-4cc0-a039-f6f1ef8c2ed1', { "inputs": { "equipment uuid": props.uuid }, count: "250" }).then((response) => {
				setComplianceHistory(response.data);
			});
		}
	}, []);

	return (
		<div className="flyoutContentContainer">
			{complianceHistory !== null ? (
				<Table
					tableData={complianceHistory}
					tableType='Compliance History'
					tableSize='small'
					clickable={false}
					dateFormats={{ "Modified On": "YYYY-MM-DD hh:mm" }}
				/>
			) : <LoaderOverlay />
			}
		</div>
	);
}

export default ComplianceHistory;