import React from 'react';
import Table from '../Table';
import Flyout from '../Flyout';
import { getEquipmentReport } from '../../api/equipmentAPI';
import { getDefaultReportingParams, setReportingParams } from '../../utils/reporting';
import LoaderOverlay from '../LoaderOverlay';

class LocationAssemblies extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			locationAssembliesList: [],
			listDisplayColumns: ["Type", "Make", "Model", "Serial Number", "Last Test Date", "Compliant", "Equipment UUID"],
			flyoutActive: false,
			assemblyInfo: {},
			newAssembly: false,
			reportingParams: getDefaultReportingParams()
		};
	}

	componentDidMount() {
		this.getAssembliesList(true);
	}

	getAssembliesList = async (onload, reportingParams) => {
		const { uuid } = this.props;

		if (reportingParams === undefined) {
			reportingParams = this.state.reportingParams;
		}
		reportingParams.search = { "property uuid": uuid };
		reportingParams.inputs = { "active": 'null' };

		if (!reportingParams.order || !reportingParams.direction){
			reportingParams.order = "active, serial number";
			reportingParams.direction = "desc";
		}

		getEquipmentReport(reportingParams).then(response => {
			if (response.nextPage !== undefined && response.nextPage > reportingParams.page) {
				reportingParams.nextPage = response.nextPage;
			}
			let data = response.data;
			if (this.props.replacement) {
				response.data.rows.forEach((assembly) => {
					if (this.props.replacement === assembly.values[0]) {
						delete data.rows[data.rows.indexOf(assembly)];
					}
				});
			}
			this.setState(() => ({ locationAssembliesList: data, reportingParams }));
		});

		// since we refreshed this list, we probably updated or added something, so refresh the main table to get
		// updated device counts
		if (!onload) {
			this.props.refresh();
		}
	}

	openFlyout = (assemblyInfo, newAssembly) => {
		const overlay = document.getElementById('overlaySecondary');

		if (newAssembly) {
			this.setState(() => ({ newAssembly: true }), () => {
				this.setState(() => ({ assemblyInfo: {} }), () => {
					this.setState(() => ({ flyoutActive: true }), () => {
						overlay.classList.add('overlaySecondary__visible');
					});
				});
			});
		} else {
			if (assemblyInfo.values) {
				assemblyInfo = {
					equipment_uuid: assemblyInfo.values[0]
				};
			}

			if (this.props.flyoutPriority === 'secondary') {
				// we are already in a second flyout and that is our limit, so instead pop them open to a new tab?
				window.open("/assemblies/" + assemblyInfo.equipment_uuid, "_blank");
				return;
			}

			this.setState(() => ({ newAssembly: false }), () => {
				this.setState(() => ({ assemblyInfo }), () => {
					this.setState(() => ({ flyoutActive: true }), () => {
						overlay.classList.add('overlaySecondary__visible');
					});
				});
			});
		}
	}

	closeFlyout = () => {
		const flyoutSecondary = document.getElementById('flyoutSecondary');
		const overlay = document.getElementById('overlaySecondary');

		flyoutSecondary.classList.remove('slideToVisible');
		overlay.classList.remove('overlaySecondary__visible');
		setTimeout(() => {
			this.setState(() => ({ flyoutActive: false }));
		}, 200);
	}

	// -----------------------------
	// table / reporting functions
	// -----------------------------
	setOrdering = (column) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { column }, false);
		this.getAssembliesList(true, newReportingParams);
	}

	fetchData = (direction) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { direction }, false);
		if (newReportingParams !== undefined) {
			this.getAssembliesList(true, newReportingParams);
		}
	}
	// END table / reporting functions

	render() {
		const { flyoutActive, newAssembly, assemblyInfo } = this.state;
		const modal = this.props.flyoutType === 'modalEquipment' ? true : false;

		return (
			<div className="flyoutContentContainer">
				{this.state.locationAssembliesList.length !== 0 &&
					<React.Fragment>
						{(this.state.locationAssembliesList.rows.length === 1 && this.state.locationAssembliesList.rows[0] === undefined) ?
							<span>This location does not have any Assemblies available to be replaced.</span>
							:
							this.state.locationAssembliesList ? <Table
								tableData={this.state.locationAssembliesList}
								tableType='Assemblies'
								tableSize={modal ? null : 'small'}
								columnsToShow={this.state.listDisplayColumns}
								openFlyout={modal ? this.props.openFlyout : this.openFlyout}
								fetchData={this.fetchData}
								setOrdering={this.setOrdering}
								paginationParams={this.state.reportingParams}
								highlight={modal ? true : false}
							/> : <LoaderOverlay />}
						{this.props.flyoutPriority !== 'secondary' && (
							<div className="flexButtonContainer">
								<button className="medButtonPrimary" onClick={() => this.openFlyout(null, true)}>Add Assembly</button>
							</div>
						)}
					</React.Fragment>
				}
				{this.props.flyoutType !== "modalEquipment" && <div className="overlaySecondary" id="overlaySecondary" onClick={() => { this.closeFlyout() }}></div>}
				{flyoutActive && (assemblyInfo.equipment_uuid || newAssembly) && (
					<React.Fragment>
						<Flyout
							uuid={assemblyInfo.equipment_uuid}
							source='property'
							sourceUUID={this.props.uuid}
							newAddition={newAssembly}

							flyoutType='assemblies'
							flyoutPriority='secondary'
							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							activeTab='assemblyDetails'

							refresh={this.getAssembliesList}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default LocationAssemblies;


