import React from "react";
import TextField from "@mui/material/TextField";
import ReactTable, { ReactTableDefaults } from "../External/ReactTable";
import MessageBox from "../MessageBox";
import { getPropertiesReport } from "../../api/propertiesAPI";
import { getReportValue } from "../../utils/reporting";
import withStyles from "@mui/styles/withStyles";
import SearchIcon from "@mui/icons-material/Search";

const styles = (theme) => ({
    textFieldInput: {
        fontSize: "1.4rem",
        color: "#506788",
    },
    label: {
        "&$focused": {
            color: "#506788",
        },
    },
    focused: {},
    outlinedInput: {
        "&$focused $notchedOutline": {
            borderColor: "#506788",
            borderWidth: 1,
        },
    },
    notchedOutline: {},
});

class SurveyLocationInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locationUUID: null,
            locationSearchResults: null,
            locationSearchError: "",
            locationSearchErrorLevel: "error",
        };
    }

    // use the name and address to find out location / a small set of locations
    locationSearch = () => {
        let name = document.getElementById("location_name");
        let address = document.getElementById("location_address");
        if (address.value == "") {
            this.setState({ locationSearchError: "You must specify an address to search" });
            return;
        }

        let payload = { search: { name: name.value, address: address.value }, count: "250" };

        getPropertiesReport(payload)
            .then((response) => {
                if (response.data.rows.length == 0) {
                    this.setState({
                        locationSearchError: "No locations found, please revise your search or create a new one",
                        locationSearchErrorLevel: "info",
                        locationSearchResults: null,
                        assemblieSearchError: "",
                        assemblieSearchResults: null,
                        locationUUID: null,
                    });
                } else {
                    this.setState(
                        {
                            locationSearchError: "",
                            locationSearchErrorLevel: "error",
                            locationSearchResults: response.data,
                            assemblieSearchError: "",
                            assemblieSearchResults: null,
                            locationUUID: null,
                        },
                        () => {
                            if (response.data.rows.length === 1) {
                                this.handleLocationSelection(this.convertLocationReportRow(response.data.rows[0]));
                            }
                        }
                    );
                }
            })
            .catch((error) => {
                this.setState({
                    locationSearchError: error.message,
                    locationSearchErrorLevel: "error",
                    locationSearchResults: null,
                    assemblieSearchError: "",
                    assemblieSearchResults: null,
                    locationUUID: null,
                });
            });
    };

    convertLocationReportRow = (row) => {
        let { locationSearchResults } = this.state;

        return {
            uuid: getReportValue(locationSearchResults, row, "Property UUID"),
            name: getReportValue(locationSearchResults, row, "Name"),
            address: getReportValue(locationSearchResults, row, "Address"),
        };
    };

    // return a react table of the locations from the above report call
    getLocationsTable = () => {
        let { locationSearchResults } = this.state;

        if (locationSearchResults === null) {
            return null;
        }

        let data = [];
        locationSearchResults.rows.forEach((row) => {
            data.push(this.convertLocationReportRow(row));
        });

        let columns = [];

        [
            { key: "name", header: "Name" },
            { key: "address", header: "Address" },
        ].forEach((obj) => {
            columns.push({
                show: true,
                accessor: obj.key,
                Header: obj.header,
                Cell: ({ original }) => <div>{original[obj.key]}</div>,
            });
        });

        columns.push(<span></span>);
        columns.unshift(<span></span>);

        return (
            <React.Fragment>
                <div className="locationAssemblyInfo__header-wrapper">
                    <span className="locationAssemblyInfo__header">Choose Location</span>
                </div>
                <div className="mainTable">
                    <ReactTable
                        data={data}
                        columns={columns}
                        resizable={false}
                        sortable={true}
                        minRows={0}
                        showPagination={false}
                        getProps={(state, rowInfo, column, instance) => {
                            return {
                                style: {
                                    height: "auto",
                                },
                            };
                        }}
                        getTrProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick: () => {
                                    this.handleLocationSelection(rowInfo.original);
                                    this.setState({ selectedLocation: rowInfo.index });
                                },
                                style: {
                                    background:
                                        rowInfo.index === this.state.selectedLocation || data.length === 1
                                            ? "#b7c5d8"
                                            : null,
                                },
                            };
                        }}
                        getTheadThProps={(state, rowInfo, column, instance) => {
                            return {
                                style: {
                                    display: "flex",
                                },
                            };
                        }}
                    />
                </div>
            </React.Fragment>
        );
    };

    // once we select a location, use that uuid to look up the list of assemblies there, set that uuid in our state too
    // since this will re-render and we want to make sure we check the radio again in the table
    handleLocationSelection = (rowInfo) => {
        let uuid = rowInfo.uuid;
        this.setState({ locationUUID: uuid });
    };

    handleNewLocation = () => {
        this.props.sendDataToReport(null, true);
    };

    startReport = () => {
        this.props.sendDataToReport(this.state.locationUUID);
    };

    render() {
        const { classes } = this.props;
        return (
            <div className="locationAssemblyInfo" id="locationAssemblyInfo">
                <div className="locationAssemblyInfo__wrapper">
                    <div className="locationAssemblyInfo__header-wrapper">
                        <span className="locationAssemblyInfo__header">Search Locations</span>
                    </div>
                    <MessageBox
                        options={{ type: this.state.locationSearchErrorLevel, message: this.state.locationSearchError }}
                    />
                    <div className="locationAssemblyInfo__inputs">
                        <TextField
                            label="Location Name"
                            name="location_name"
                            id="location_name"
                            variant="outlined"
                            size="small"
                            slotProps={{
                                input: {
                                    classes: {
                                        input: classes.textFieldInput,
                                        root: classes.outlinedInput,
                                        focused: classes.focused,
                                        notchedOutline: classes.notchedOutline,
                                    },
                                },
                            }}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    this.locationSearch();
                                }
                            }}
                        />
                        <TextField
                            label="Location Address"
                            name="location_address"
                            id="location_address"
                            required
                            variant="outlined"
                            size="small"
                            slotProps={{
                                input: {
                                    classes: {
                                        input: classes.textFieldInput,
                                        root: classes.outlinedInput,
                                        focused: classes.focused,
                                        notchedOutline: classes.notchedOutline,
                                    },
                                },
                            }}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    this.locationSearch();
                                }
                            }}
                        />
                        <button
                            className="xsmallButtonPrimary"
                            onClick={this.locationSearch}
                            data-testid="new-survey-location-search-button"
                        >
                            <SearchIcon fontSize="large" />
                        </button>
                    </div>
                    {this.getLocationsTable()}
                    {!this.props.source && (
                        <span className="locationAssemblyInfo__inputs-subtext">
                            <button className="buttonSecondary" onClick={this.handleNewLocation}>
                                I'm at a brand new location.
                            </button>
                        </span>
                    )}
                    {this.state.locationUUID && (
                        <button className="longButtonPrimary" onClick={this.startReport}>
                            {this.props.surveyDoneByProvider
                                ? "Continue to Service Provider Selection"
                                : "Continue to Surveyor Selection"}
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(SurveyLocationInfo);
