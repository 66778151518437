import React from "react";
import { getURLModule, getURLBaseSection } from "../../../utils/url";
import Home from "../../Common/Home/Home";
import AdvancedReportTable from "../../Common/ReportTable/AdvancedReportTable";

const PermitsHome = () => {
    const { slug } = getURLModule();
    const baseSection = getURLBaseSection().section;

    const getRowLink = (row) => {
        if (row["Status"] === "pending" || row["Status"] === "draft") {
            return {
                pathname: `/${slug}/${baseSection}/addPermit`,
                state: {
                    templateDocumentUUID: row["Legal Control Template Document UUID"],
                    legalControlUUID: row["Legal Control UUID"],
                    status: row["Status"],
                },
            };
        } else {
            return {
                pathname: `/${slug}/${baseSection}/${row["Legal Control UUID"]}`,
                state: {
                    templateUUID: row["Template UUID"],
                },
            };
        }
    };

    return (
        <Home addNewForm="addPermit">
            <AdvancedReportTable
                reportUUID="b6f4c4aa-c52c-4210-aaf0-5ee80494857b"
                defaultColumns={[
                    "Legal Control Name",
                    "Permit Number",
                    "Status",
                    "Effective Date",
                    "Expiration Date",
                    "Template Name",
                ]}
                rowLinks={getRowLink}
            />
        </Home>
    );
};

export default PermitsHome;
