import React, { useEffect, useState } from 'react';
import { withFormik, Form, Field, FieldArray } from 'formik';
import MessageBox from '../MessageBox';
import * as Yup from 'yup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ClearIcon from '@mui/icons-material/Clear';
import DatePicker from '../DatePicker';
import CommsEditor from './CommsEditor';
import CommsModal from './CommsModal';
import CommLogContactDetailsForm from './CommLogContactDetailsForm';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const FormLayout = ({
    errors,
    touched,
    editMode,
    toggleEditMode,
    resetForm,
    newAddition,
    closeFlyout,
    setFieldValue,
    apiErrors,
    messageOptions,
    values,
    formErrors,
    setFormErrors,
    commsList,
    data,
    saveDetails,
    logContacts,
    openSecondaryFlyout,
    closeSecondaryFlyout,
    createNewContact,
    flyoutPriority,
    commData
}) => {

    const [location, setLocation] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [commUUID, setCommUUID] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState('location');
    const [editorOpen, setEditorOpen] = useState(false);
    const [additionalContentsText, setAdditionalContentsText] = useState('');
    const [flyoutActive, setFlyoutActive] = useState(false);
    const [availableMethods, setAvailableMethods] = useState([]);

    useEffect(() => {
        if (location && location.name) {
            setFieldValue('location', location.name)
        } else {
            setFieldValue('location', '')
        }
    }, [location])

    useEffect(() => {
        if (contacts && contacts.length > 0) {
            setFieldValue('contacts', contacts)
        } else {
            setFieldValue('contacts', '')
        }
    }, [contacts])

    useEffect(() => {
        if (equipment && equipment.length > 0) {
            setFieldValue('equipment', equipment)
        } else {
            setFieldValue('equipment', '')
        }
    }, [equipment])

    useEffect(() => {
        setFieldValue('method', '')
        let newAvailableMethods = ['letter', 'postcard', 'email'];
        if (commUUID !== null) {
            commsList.forEach((comm) => {
                if (comm.communication_uuid === commUUID) {
                    newAvailableMethods = newAvailableMethods.filter(x => comm.contactMethods.split(',').includes(x));
                }
            })

            setAvailableMethods(newAvailableMethods);
        }
    }, [commUUID])

    useEffect(() => {
        if (!newAddition) {
            let newAvailableMethods = ['letter', 'postcard', 'email'];
            commsList.forEach((comm) => {
                if (comm.communication_uuid === data.communication_uuid) {
                    newAvailableMethods = newAvailableMethods.filter(x => comm.contactMethods.split(',').includes(x));
                }
            })

            setAvailableMethods(newAvailableMethods);
        }
    }, [commsList])

    useEffect(() => {
        if (!newAddition && data['info']) {
            setLocation({ property_uuid: data['info'].property.property_uuid, name: data['info'].property.name });
        }
    }, [data])

    useEffect(() => {
        if (commData.serial_number &&  commData.equipment_uuid) {
            setEquipment([{serial_number: commData.serial_number, equipment_uuid: commData.equipment_uuid}]);
        }
        if (commData.property_uuid && commData.location_name) {
            setLocation({property_uuid: commData.property_uuid, name: commData.location_name});
        }
    }, [commData.property_uuid]);

    const submit = () => {

        if (newAddition) {
            const contactsList = [];
            const equipmentList = [];
            for (const contact of contacts) {
                contactsList.push(contact.contact_uuid);
            }
            for (const assembly of equipment) {
                equipmentList.push(assembly.equipment_uuid);
            }
            delete values.location;
            delete values.contacts;
            delete values.equipment;

            saveDetails(null, data.communication_uuid,
                {
                    ...values,
                    property_uuid: location.property_uuid,
                    additional_contents: additionalContentsText,
                    contact_uuids: contactsList,
                    equipment_uuids: equipmentList
                });
        } else {
            saveDetails(null, data.communication_log_uuid, {
                notes: values.notes,
                due_on: values.due_on,
                additional_contents: additionalContentsText
            })
        }
    }

    const cancelAction = () => {
        if (newAddition) {
            resetForm();
            closeFlyout();
        } else {
            toggleEditMode();
            resetForm();
        }
    };

    const getError = (fieldname) => {
        if (apiErrors && apiErrors[fieldname]) {
            return apiErrors[fieldname];
        } else if (touched[fieldname] && errors[fieldname]) {
            return errors[fieldname];
        }
    };

    const handleFormChange = (e) => {
        // this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
        // might be set so we can check it again
        if (apiErrors && apiErrors[e.target.name]) {
            delete apiErrors[e.target.name];
        }
    };

    const getFormErrors = () => {
        let errorList = [];
        messageOptions = {};
        ['communication_uuid', 'location', 'method', 'contacts'].forEach((field) => {
            let err = getError(field);
            if (err) {
                errorList.push(err);
            }
        });

        if (errorList.length !== 0) {
            messageOptions = {
                type: "error",
                message: "Please correct the following errors",
                list: errorList,
            };
            if (formErrors != errorList.join(",")) {
                document.getElementById('commTypeDetailsForm').scrollIntoView();
            }
            setFormErrors(errorList.join(","));
        }

        return messageOptions;
    };

    const getSelectOptions = (optionType) => {
        let optionsList;

        if (optionType === 'communication_uuid') {
            optionsList = commsList.map((comm, index) => {
                return (
                    <option
                        value={comm.communication_uuid}
                        key={index}
                    >
                        {comm.name.charAt(0).toUpperCase() + comm.name.slice(1)}
                    </option>
                );
            });
        } else if (optionType === 'method') {
            let list;
            if (newAddition) {
                list = availableMethods;
            } else {
                list = commData.contact_methods
            }
            optionsList = list.map((type, index) => {
                return (
                    <option
                        value={type}
                        key={index}
                    >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </option>
                );
            });
        }

        return optionsList;
    };

    const showSelections = (type) => {
        let selections = [];

        if (type === 'equipment') {
            selections = equipment.map((assembly, idx) => (
                <div key={idx} className="selection__container-element">{assembly.serial_number}</div>
            ))
        } else if (type === 'contacts') {
            selections = contacts.map((contact, idx) => (
                <div key={idx} className="selection__container-element">{contact.name}</div>
            ))

        }

        return selections;
    }

    const saveSelection = (type, payload) => {
        if (type === 'location') {
            setLocation(payload);
            setContacts([]);
            setEquipment([]);
        } else if (type === 'contacts') {
            if (newAddition) {
                setContacts(payload);
            } else {
                createNewContact(payload);
            }
        } else if (type === 'equipment') {
            setEquipment(payload);
        }

        setModalOpen(false);
    }

    const modalControls = (action, type) => {

        if (type === 'location') {
            setModalType('location');
        } else if (type === 'equipment') {
            setModalType('equipment');
        } else if (type === 'contacts') {
            setModalType('contacts')
        }

        setTimeout(() => {
            if (action === 'open') {
                setModalOpen(true);
            } else if (action === 'close') {
                setModalOpen(false);
            }
        }, [200])
    }

    const handleClear = (type) => {
        if (type === 'location') {
            setLocation(null);
            setContacts([]);
            setEquipment([]);
        }
    }

    const editorControls = (action, type, payload) => {
        if (action === 'open') {
            setEditorOpen(true);
        } else if (action === 'close') {
            setEditorOpen(false);
        } else if (action === 'save') {
            setAdditionalContentsText(payload);
            setEditorOpen(false);
        }
    }

    const showLogContacts = () => {
        let logContactsList = [];
        if (logContacts) {
            logContacts.map((contact, idx) => {
                logContactsList.push(
                    <div
                        key={idx}
                        onClick={() => routeContactSelection(contact)}
                        className="selection__container-element"
                        style={{ cursor: 'pointer' }}
                    >
                        {contact['info'].lastname !== undefined ? `${contact['info'].firstname} ${contact['info'].lastname}` : `${contact['info'].firstname}`}
                    </div>
                )
            })
        }

        return logContactsList;
    }

    const routeContactSelection = (contact) => {
        if (flyoutPriority === 'primary') {
            openSecondaryFlyout(contact);
        } else {
            window.open(`/communications/logs/${contact.communication_log_uuid}/contacts/${contact.contact_uuid}/${contact.method}/${commData.communication_type}`, '_blank');
        }
    }

    const showLogEquipment = () => {
        let logEquipment = data.info.equipment;
        let logEquipmentList = [];
        if (logEquipment) {
            logEquipment.map((equipment, idx) => {
                let identifier = equipment?.serial_number;
                if (!identifier) {
                    identifier = "unknown"
                }
                logEquipmentList.push(
                    <div
                        key={idx}
                        onClick={() => window.open(`/assemblies/${equipment.equipment_uuid}`, '_blank')}
                        className="selection__container-element"
                        style={{ cursor: 'pointer' }}
                    >
                        {`${identifier}`}
                    </div>
                )
            })
        }

        return logEquipmentList;
    }

    let multiSelect;
    let count = null;
    let methods = [];

    if (newAddition) {
        if (modalType === 'location') {
            multiSelect = false;
        } else {
            multiSelect = true;
            count = 250;
        }
    } else {
        multiSelect = false;
    }

    if (newAddition) {
        methods = availableMethods
    } else {
        if (commData && commData.contact_methods) {
            methods = commData.contact_methods
        }
    }

    return (
        <Form className="commDetailsForm" id="commTypeDetailsForm">
            <MessageBox options={getFormErrors()} />
            <fieldset disabled={!editMode}>
                <div className="detailsFieldset">
                    <div className={'inputField long ' + ((getError('communication_uuid')) ? 'formRed' : '')}>
                        <label htmlFor='communication_uuid'>Communication Template*</label>
                        <Field component="select" id='communication_uuid' disabled={!newAddition} name='communication_uuid' autoComplete="off" className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); setCommUUID(e.target.value); }}
                        >
                            <option value=""></option>
                            {getSelectOptions('communication_uuid')}
                        </Field>
                    </div>
                    <div className="flexAlignCenter" style={{ width: "100%" }}>
                        {newAddition && <button className="medButtonPrimary" type="button" style={{ marginRight: "2rem" }} onClick={() => modalControls('open', 'location')}>Select Location</button>}
                        <div className={'inputField long ' + ((getError('location')) ? 'formRed' : '')} style={{ position: 'relative' }}>
                            <label htmlFor="location">Location *</label>
                            <Field type="text" id="location" name="location" readOnly disabled={!newAddition} className="inputField__input inputField__input-first"
                                onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                            >
                            </Field>
                            {/*location && newAddition && <button onClick={() => handleClear('location')} type="button" className='clearIcon' title='Clear Location'>
                                <ClearIcon fontSize={'large'} />
                            </button>*/}
                            {location && !newAddition && <OpenInNewIcon className="newTabButton" onClick={() => window.open(`/locations/${location.property_uuid}`, '_blank')} />}
                        </div>
                    </div>
                    {!newAddition &&
                        <React.Fragment>
                            <label>Contacts</label>
                            <div className="flexAlignCenter modalSelection">
                                {logContacts && logContacts.length > 0 &&
                                    showLogContacts()
                                }
                                {(logContacts && logContacts.length === 0) && !editMode &&
                                    <span
                                        style={{ margin: '0rem 2rem', opacity: '50%' }}
                                    >
                                        No Contacts Selected
                                    </span>
                                }
                                <div
                                    key={'addContact'}
                                    onClick={() => modalControls('open', 'contacts')}
                                    className="selection__container-addition"
                                    style={{ cursor: 'pointer' }}
                                >
                                    + Add Contact
                                </div>
                            </div>
                            <label>Equipment</label>
                            <div className="flexAlignCenter modalSelection">
                                {data.info && data.info.equipment && data.info.equipment.length > 0 &&
                                    showLogEquipment()
                                }
                                {(data.info && data.info.equipment && data.info.equipment.length === 0) &&
                                    <span
                                        style={{ margin: '2rem 0rem 0rem 2rem', opacity: '50%' }}
                                    >
                                        No Equipment Selected
                                    </span>}
                            </div>
                        </React.Fragment>
                    }
                    {location && newAddition &&
                        <React.Fragment>
                            <div className="flexAlignCenter modalSelection">
                                {newAddition && <button className="medButtonPrimary" type="button" style={{ marginRight: "2rem" }} onClick={() => modalControls('open', 'contacts')}>Select Contacts</button>}
                                {contacts && contacts.length > 0 &&
                                    showSelections('contacts')
                                }
                                {contacts.length === 0 && <span style={{ opacity: '50%' }}>No Contacts Selected</span>}
                            </div>
                            <div className="flexAlignCenter modalSelection">
                                {newAddition && <button className="medButtonPrimary" type="button" style={{ marginRight: "2rem" }} onClick={() => modalControls('open', 'equipment')}>Select Equipment</button>}
                                {equipment && equipment.length > 0 &&
                                    showSelections('equipment')
                                }
                                {equipment.length === 0 && <span style={{ opacity: '50%' }}>No Equipment Selected</span>}
                            </div>
                        </React.Fragment>
                    }
                    {newAddition &&
                        <div className={'inputField medium ' + ((getError('method')) ? 'formRed' : '')}>
                            <label htmlFor='method'>Method *</label>
                            <Field component="select" id='method' name='method' autoComplete="off" className="inputField__input inputField__input-first"
                                onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                            >
                                <option value=""></option>
                                {getSelectOptions('method')}
                            </Field>
                        </div>
                    }
                    {!newAddition &&
                        <div className={'inputField medium ' + ((getError('sent_on')) ? 'formRed' : '')}>
                            <label htmlFor="sent_on">Sent On</label>
                            <Field
                                name="sent_on"
                                component={DatePicker}
                                valueType="epoch"
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                        </div>
                    }
                    <div className={'inputField medium ' + ((getError('due_on')) ? 'formRed' : '')}>
                        <label htmlFor="due_on">Due On</label>
                        <Field
                            name="due_on"
                            component={DatePicker}
                            valueType="epoch"
                            editMode={editMode}
                            values={values}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                    {!newAddition && data?.sent_by?.email !== '' &&
                        <div className={'inputField long'}>
                            <label htmlFor="sent_by">Sent By</label>
                            <Field
                                name="sent_by"
                                type="text"
                                readOnly
                                disabled={true}
                            />
                        </div>
                    }
                    <div className={'inputField long ' + ((getError('notes')) ? 'formRed' : '')}>
                        <label htmlFor="notes">Notes</label>
                        <Field component="textarea" rows="2" name="notes" autoComplete="off" className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                        />
                    </div>
                </div>
            </fieldset>
            <div className="formButtonsBank" style={{ marginTop: "0" }}>
                <button
                    type="button"
                    className="longButtonSecondary"
                    onClick={() => editorControls('open')}
                >
                    <span>{editMode ? 'Edit Additional Contents' : 'Preview Additional Contents'}</span>
                </button>
            </div>
            {
                editMode && (
                    <div className="flexButtonContainer">
                        <button className="medButtonPrimary" type="button" onClick={() => submit()}>Save</button>
                        <button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
                    </div>
                )
            }
            <CommsEditor
                editorControls={editorControls}
                open={editorOpen}
                data={data}
                textEditorContents={additionalContentsText}
                newAddition={newAddition}
                editMode={editMode}
                type='additionalContents'
            />
            <CommsModal
                modalControls={modalControls}
                modalOpen={modalOpen}
                modalType={modalType}
                saveSelection={saveSelection}
                location={location}
                multiSelect={multiSelect}
                newAddition={newAddition}
                logContacts={logContacts}
                selectedContacts={contacts}
                selectedEquipment={equipment}
                availableMethods={methods}
                count={count}
            />
        </Form>
    );
};

const CommLogDetailsForm = withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    mapPropsToValues(props) {
        let values = {};
        if (props.newAddition) {
            values = {
                location: '',
                contacts: '',
                equipment: '',
                method: '',
                due_on: null,
                notes: '',
                communication_uuid: ''
            };
        } else {
            if (props.data) {

                values = {
                    due_on: (props.data.due_on ? props.data.due_on : ''),
                    notes: (props.data.notes ? props.data.notes : ''),
                    generated_on: (props.data.generated_on ? props.data.generated_on : ''),
                    sent_on: (props.data.sent_on ? props.data.sent_on === "0" ? null : props.data.sent_on : ''),
                    sent_by: (props.data.sent_by?.email ? props.data.sent_by.email : ''),
                    communication_uuid: (props.data.communication_uuid ? props.data.communication_uuid : '')
                }
            }
        }


        return values;
    },

    validationSchema: Yup.object().shape({
        communication_uuid: Yup.string().required('Communication is required'),
        location: Yup.string().required('Location is required'),
        method: Yup.string().required('Method is required'),
        contacts: Yup.array().min(1).required('At least one contact is required')
    })

})(FormLayout);

export default CommLogDetailsForm; 
